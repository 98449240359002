import * as Sentry from '@sentry/react';
import { Replay } from '@sentry/replay';
import { BrowserTracing } from '@sentry/tracing';
import { ExtraErrorData } from '@sentry/integrations';
import { excludeGraphQLFetch } from 'apollo-link-sentry';
import config from '@src/config';
import { getUserFromLocalStorage } from '@src/localStorage/user';

const sentryDsn = config.sentryDsn;

if (sentryDsn) {
  const user = getUserFromLocalStorage();

  Sentry.init({
    beforeBreadcrumb: excludeGraphQLFetch,
    dsn: sentryDsn,
    ignoreErrors: [
      /Unauthorized/,
      'TypeError: Failed to fetch',
      'TypeError: NetworkError when attempting to fetch resource.',
      'TypeError: cancelled',
      'TypeError: Load failed',
      'AbortError: Fetch is aborted',
      'Error: cancelled',
      'Error: Load failed',
      'The network connection was lost',
      'The Internet connection appears to be offline.',
      '[GetForYouMarketInsights]: Data not populated for salaryDistribution',
      /Non-Error promise rejection captured/,
    ],
    integrations: [
      new BrowserTracing({
        tracePropagationTargets: ['localhost', /meetfrank.com/],
      }),
      new ExtraErrorData({ depth: 15 }),
      new Replay(),
    ],
    maxValueLength: 1000,
    normalizeDepth: 6,
    replaysOnErrorSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    tracesSampleRate: 0.1,
  });

  if (user) {
    Sentry.setUser({
      id: user.id,
    });
  }
}
