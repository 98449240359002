import React, { FC } from 'react';
import ArrowSvg from 'assets/images/arrow.svg';
import FrCloseSvg from 'assets/images/fr-close.svg';
import FrMoreSvg from 'assets/images/fr-more.svg';
import Svg from 'svg-inline-react';
import { useHistory } from 'react-router-dom';
import { INBOX_ROUTE } from '@src/constants/routes';
import { ChatHeaderProps } from './props';
import './styles.scss';

export const ChatHeader: FC<ChatHeaderProps> = ({
  companyName,
  isMenuOpen,
  toggleMenu,
  recruiterFullName,
  isActionsDisabled,
}) => {
  const history = useHistory();

  const goBack = () => {
    history.push(INBOX_ROUTE);
  };

  return (
    <div className="chat-header">
      {isMenuOpen ? <Svg onClick={toggleMenu} src={FrCloseSvg} /> : <Svg onClick={goBack} src={ArrowSvg} />}

      <div className="chat-header__info">
        {recruiterFullName && <div className="chat-header__title">{recruiterFullName}</div>}

        <div className="chat-header__subtitle">{companyName}</div>
      </div>

      {!isActionsDisabled ? <Svg onClick={toggleMenu} src={FrMoreSvg} /> : <div className="chat-header__placeholder" />}
    </div>
  );
};
