import { GetUserQuery } from '@src/graphql/generated';
import { hasFields } from '@src/util/object/helpers';
import { EmployedUserEmploymentType, OnboardedUserProfileType } from './currentUser.interface';

export function isEmployedUserEmploymentType(
  obj: GetUserQuery['currentUser']['profile']['currentEmployment'],
): obj is EmployedUserEmploymentType {
  return hasFields<EmployedUserEmploymentType>(obj, ['__typename']) && obj.__typename === 'EmployedUserEmploymentType';
}

export function isOnboardedUserProfileType(
  userProfile: GetUserQuery['currentUser']['profile'],
): userProfile is OnboardedUserProfileType {
  return (
    hasFields<OnboardedUserProfileType>(userProfile, ['__typename']) &&
    userProfile.__typename === 'OnboardedUserProfileType'
  );
}
