import { useEffect, useState } from 'react';
import { apolloClient } from '@src/graphql/apollo';
import {
  BookmarkTypeEnum,
  RemoveBookmarkDocument,
  RemoveBookmarkMutation,
  RemoveBookmarkMutationVariables,
  SaveBookmarkDocument,
  SaveBookmarkMutation,
  SaveBookmarkMutationVariables,
} from '@src/graphql/generated';
import { toggleBookmarkFromCache } from '../../actions/toggleBookmarkFromCache';
import { ToggleBookmarkStatusParams, UseBookmarksParams, UseBookmarksValue } from './useBookmarks.interface';

export const useBookmarks = ({
  entityId: defaultEntityId,
  hasBookmarked: hasDefaultBookmarked,
}: UseBookmarksParams = {}): UseBookmarksValue => {
  const [hasBookmarked, setIsBookmarked] = useState<boolean>(!!hasDefaultBookmarked);

  useEffect(() => {
    if (hasDefaultBookmarked !== hasBookmarked) {
      setIsBookmarked(!!hasDefaultBookmarked);
    }
  }, [hasDefaultBookmarked]);

  const toggleBookmarkStatus = async ({
    entityId = defaultEntityId,
  }: ToggleBookmarkStatusParams = {}): Promise<void> => {
    if (!entityId) {
      return;
    }

    setIsBookmarked(!hasBookmarked);

    if (!hasBookmarked) {
      await apolloClient.mutate<SaveBookmarkMutation, SaveBookmarkMutationVariables>({
        fetchPolicy: 'no-cache',
        mutation: SaveBookmarkDocument,
        variables: {
          entityId: entityId,
          type: BookmarkTypeEnum.OPENING,
        },
      });
    } else {
      await apolloClient.mutate<RemoveBookmarkMutation, RemoveBookmarkMutationVariables>({
        fetchPolicy: 'no-cache',
        mutation: RemoveBookmarkDocument,
        variables: {
          entityId: entityId,
          type: BookmarkTypeEnum.OPENING,
        },
      });
    }

    toggleBookmarkFromCache(entityId, !hasBookmarked);
  };

  return {
    hasBookmarked,
    toggleBookmarkStatus,
  };
};
