import React, { FC } from 'react';
import { get } from 'lodash';
import { Link, useHistory } from 'react-router-dom';
import { CompanyThumbnailCard, CompanyThumbnailCardSkeleton } from '@src/components/CompanyThumbnailCard';
import { handleFetchMore } from '@src/util/handleFetchMore';
import { HorizontalScroller } from '@src/components/HorizontalScroller';
import { useHorizontalFeedScroll } from '@src/customHooks/useHorizontalFeedScroll';
import { COMPANY_ROUTE, VERIFIED_USER_ROUTE } from '@src/constants/routes';
import createLink from '@src/util/createLink';
import {
  useVerifiedUserSeenByCompaniesScrollerQuery,
  VerifiedUserSeenByCompaniesScrollerQuery,
} from '@src/graphql/generated';
import dateStringFromDate from '@src/util/dateStringFromDate';
import { SectionTitle } from '../SectionTitle';
import { isPublishedUserVerificationType } from './typeGuards';
import { Props } from './props';
import './styles.scss';

const WHO_VIEWED_PROFILE_RESULT_KEY = 'currentUser.verification.viewedByCompanies.result';
const WHO_VIEWED_PROFILE_PAGINATION_KEY = 'currentUser.verification.viewedByCompanies.pagination';

export const ProfileViews: FC<Props> = ({ isPublished }) => {
  const history = useHistory();

  const {
    data,
    loading: isLoading,
    fetchMore,
  } = useVerifiedUserSeenByCompaniesScrollerQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      viewedByCompaniesPaginationOptions: {
        limit: 3,
      },
    },
  });

  const verifiedUser = data?.currentUser?.verification;

  const companies = isPublishedUserVerificationType(verifiedUser) ? verifiedUser?.viewedByCompanies?.result || [] : [];

  const companiesPagination = isPublishedUserVerificationType(verifiedUser)
    ? verifiedUser?.viewedByCompanies.pagination
    : undefined;

  const loadMore = () => {
    if (!isLoading && companiesPagination?.hasMoreResults) {
      handleFetchMore(fetchMore, {
        updateQuery: (
          prev: VerifiedUserSeenByCompaniesScrollerQuery,
          { fetchMoreResult }: { fetchMoreResult: VerifiedUserSeenByCompaniesScrollerQuery },
        ) => {
          if (!fetchMoreResult) {
            return prev;
          }

          const prevCompanies = isPublishedUserVerificationType(prev?.currentUser.verification)
            ? prev?.currentUser.verification.viewedByCompanies
            : undefined;

          const newResult = get(fetchMoreResult, WHO_VIEWED_PROFILE_RESULT_KEY) || [];
          const pagination = get(fetchMoreResult, WHO_VIEWED_PROFILE_PAGINATION_KEY) || {};

          return {
            currentUser: {
              ...prev.currentUser,
              verification: {
                ...prev.currentUser.verification,
                viewedByCompanies: {
                  ...prevCompanies,
                  pagination: {
                    ...prevCompanies?.pagination,
                    hasMoreResults: pagination.hasMoreResults,
                    limit: pagination.limit,
                    maxCursor: pagination.maxCursor,
                  },
                  result: [...(prevCompanies?.result || []), ...newResult],
                },
              },
            },
          };
        },
        variables: {
          viewedByCompaniesPaginationOptions: {
            limit: 6,
            minCursor: companiesPagination.maxCursor,
          },
        },
      });
    }
  };

  const { scrollerRef } = useHorizontalFeedScroll({ loadMore }, [isLoading]);

  return (
    <div className="frank-ta-companies">
      <div className="frank-ta-companies__title">
        <SectionTitle
          count={companiesPagination?.totalCount}
          subtitle={
            isPublished ? (
              'Companies that have seen your profile will appear here.'
            ) : (
              <>
                Make your profile visible to get discovered by companies.{' '}
                <Link className="frank-ta-companies__verify-link" to={VERIFIED_USER_ROUTE}>
                  Get started
                </Link>
              </>
            )
          }
          title="Profile views"
        />
      </div>

      <HorizontalScroller className="frank-ta-companies__scroller" scrollerRef={scrollerRef}>
        {companies.map(companyItem => {
          if (!companyItem?.company) {
            return null;
          }

          const bgImageUrl =
            companyItem.company.profile.officeImages[0]?.fullUrl || companyItem.company.profile.headerImage.fullUrl;

          return (
            <CompanyThumbnailCard
              bgImageUrl={bgImageUrl}
              key={companyItem.company.id}
              logoUrl={companyItem.company.profile.logo?.fullUrl}
              onCardClick={() =>
                history.push(
                  createLink({
                    link: COMPANY_ROUTE,
                    params: { companyId: companyItem.company.id },
                  }),
                )
              }
              subTitle={dateStringFromDate(companyItem.viewedAt, 'ago')}
              title={companyItem.company.profile.name}
              wrapperClassName="frank-ta-companies__card"
            />
          );
        })}

        {isLoading && <CompanyThumbnailCardSkeleton wrapperClassName="frank-ta-companies__card" />}
      </HorizontalScroller>
    </div>
  );
};
