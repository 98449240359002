import { captureError } from '@src/util/errors';
import { isIOSContainer, isAndroidContainer } from '@src/util/body';

export const shareLink = link => {
  try {
    if (isIOSContainer()) {
      window.webkit.messageHandlers.callbackHandler.postMessage({ type: 'share', value: link });
    }
  } catch (error) {
    captureError(error);
  }

  try {
    if (isAndroidContainer()) {
      window.Android.postMessage(JSON.stringify({ type: 'share', value: link }));
    }
  } catch (error) {
    captureError(error);
  }
};
