import React from 'react';
import PropTypes from 'prop-types';
import { get, some, isEmpty } from 'lodash';
import { SelectItem, SelectItemLoading } from '@src/components/SelectItem';
import { getPreFilledSearchTags } from '@src/util/getSearchTags';
import { useGetRelevantFilterCategoriesQuery } from '@src/graphql/generated';

export const Skills = ({ onSkillSelect, activeSkills, searchTags, searchPattern }) => {
  const activeSearchTags = getPreFilledSearchTags(searchTags);
  const { data, loading } = useGetRelevantFilterCategoriesQuery({
    variables: {
      categories: ['SKILL'],
      groupSearchTagsWithOrCondition: activeSearchTags.groupSearchTagsWithOrCondition,
      minOpeningsWithinCategoryCount: 0,
      searchPattern: searchPattern,
      searchTags: activeSearchTags.searchTagsWithAndCondition,
      searchTagsWithOrCondition: activeSearchTags.searchTagsWithOrCondition,
    },
  });
  const skills = (get(data, 'openingCategories') || []).map(skill => skill.entity);

  const handleSkillsSelect = skill => {
    const activeSkillsWithNames = skills.filter(skill => activeSkills.find(it => skill.id === it.id));
    const updatedSkills = activeSkillsWithNames.find(it => it.id === skill.id)
      ? activeSkillsWithNames.filter(it => it.id !== skill.id)
      : [...activeSkillsWithNames, skill];

    onSkillSelect(updatedSkills);
  };

  return (
    <div className="fade-in">
      {loading && <SelectItemLoading amount={5} hasCheckbox hasImage={false} />}
      {!loading && !isEmpty(skills) && (
        <div className="fade-in">
          {skills.map(skill => {
            return (
              <SelectItem
                active={some(activeSkills, { id: skill.id })}
                key={skill.id}
                onSelect={handleSkillsSelect}
                option={skill}
                optionLabel={skill.name}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

Skills.propTypes = {
  activeSkills: PropTypes.array,
  onSkillSelect: PropTypes.func.isRequired,
  searchPattern: PropTypes.string,
  searchTags: PropTypes.array.isRequired,
};
