import gql from 'graphql-tag';
import { CompanyType } from '@src/graphql/company/fragments';

export const GET_TOP_REVIEWED_COMPANIES_SEARCH = gql`
  query getTopReviewedCompaniesSearch(
    $searchTags: [CombinedSearchTagInput!]
    $searchPatterns: [CombinedSearchPatternInput!]
    $paginationOptions: PaginationOptionsInput
  ) {
    legacySearch(searchTags: $searchTags, searchPatterns: $searchPatterns, paginationOptions: $paginationOptions) {
      pagination {
        minCursor
        maxCursor
        totalCount
        limit
        hasMoreResults
      }
      result {
        type
        entity {
          id

          ... on CompanyType {
            ...ReviewBlockItemCompany
          }
        }
      }
    }
  }

  ${CompanyType.fragments.reviewBlockItemCompany}
`;

export const GET_SEARCHED_COMPANIES = gql`
  query legacySearchCompanies(
    $searchTags: [CombinedSearchTagInput!]
    $searchPatterns: [CombinedSearchPatternInput!]
    $paginationOptions: PaginationOptionsInput
  ) {
    legacySearch(searchTags: $searchTags, searchPatterns: $searchPatterns, paginationOptions: $paginationOptions) {
      pagination {
        minCursor
        maxCursor
        totalCount
        limit
        hasMoreResults
      }
      result {
        type
        entity {
          id
          ... on CompanyType {
            id
            location {
              id
              shortName
            }
            companyHiringState: hiringState
            profile {
              id
              name
              logo {
                id
                fullUrl(width: 500, height: 500)
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_SEARCH_LATEST_OPENINGS_COUNT = gql`
  query getSearchLatestOpeningsCount(
    $paginationOptions: OpeningSearchEntityPaginationOptionsInput
    $searchParams: OpeningSearchEntitySearchParamsInput
  ) {
    searchOpenings(paginationOptions: $paginationOptions, searchParams: $searchParams) {
      pagination {
        totalCount
      }
      result {
        id
      }
    }
  }
`;

export const GET_COMPLETE_OPENINGS_SEARCH_PATTERN = gql`
  query autoCompleteOpeningsSearchPattern($searchPattern: String, $paginationOptions: PaginationOptionsInput) {
    autoCompleteOpeningsSearchPattern(searchPattern: $searchPattern, paginationOptions: $paginationOptions) {
      result
    }
  }
`;
