import React from 'react';
import './styles.scss';

export const ThumbnailCardSkeleton = () => {
  return (
    <div className="thumbnail-card-skeleton">
      <div className="thumbnail-card-skeleton__item">
        <div className="thumbnail-card-skeleton__box pulse-fade" />

        <div className="thumbnail-card-skeleton__wrapper">
          <div className="thumbnail-card-skeleton__line pulse-fade" />
          <div className="thumbnail-card-skeleton__line thumbnail-card-skeleton__line--short pulse-fade" />
        </div>
      </div>

      <div className="thumbnail-card-skeleton__line thumbnail-card-skeleton__line--margin-auto pulse-fade" />
      <div className="thumbnail-card-skeleton__line thumbnail-card-skeleton__line--thick thumbnail-card-skeleton__line--margin pulse-fade" />
      <div className="thumbnail-card-skeleton__line thumbnail-card-skeleton__line--margin pulse-fade" />
    </div>
  );
};
