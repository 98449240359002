import { FieldMergeFunction } from '@apollo/client';
import { uniqBy } from 'lodash';
import { MergePaginatedResultsParam } from './interfaces/mergePaginatedResults.interface';

export const mergePaginatedResults: FieldMergeFunction<MergePaginatedResultsParam, MergePaginatedResultsParam> = (
  existing,
  incoming,
) => {
  const existingPagination = existing?.pagination || {};
  const existingResult = existing?.result || [];

  const incomingPagination = incoming?.pagination || {};
  const incomingResult = incoming?.result || [];

  const mergeResult = {
    pagination: {
      ...existingPagination,
      ...incomingPagination,
      /*
       * We should keep initial minCursor unchanged because we need it to detect
       * if new content have been added while app was running in background.
       * More details how it is used can be checked in this function ->
       * src/customHooks/useFeedUpdate (function checkNewContent).
       */
      minCursor: existing?.pagination?.minCursor || incoming?.pagination?.minCursor,
    },
    result: uniqBy([...existingResult, ...incomingResult], '__ref'),
  };

  return mergeResult;
};
