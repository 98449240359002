import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { SectionHeaderSubTitleSkeleton } from './SectionHeaderSubTitleSkeleton';
import './styles.scss';

export const SectionHeaderTitleSkeleton = () => {
  return <div className="section-header-skeleton__title pulse-fade" />;
};

export const SectionHeaderSkeleton = ({ hasBorder, subTitleCount }) => {
  return (
    <div className={classnames('section-header-skeleton', { 'section-header-skeleton--with-border': hasBorder })}>
      <div className="section-header-skeleton__title pulse-fade" />
      <SectionHeaderSubTitleSkeleton count={subTitleCount} />
    </div>
  );
};

SectionHeaderSkeleton.propTypes = {
  hasBorder: PropTypes.bool,
  subTitleCount: PropTypes.number,
};
