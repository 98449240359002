import { useMemo } from 'react';
import {
  GetOpeningSearchCategoriesQueryVariables,
  OpeningRemoteTypeEnum,
  PlaceTypeEnum,
  SearchOpeningEntityTagNameEnum,
  useGetOpeningSearchCategoriesQuery,
} from '@src/graphql/generated';
import { OPENING_STATE } from '@src/constants';
import { CATEGORIES } from '../../constants/categories';
import { Category } from '../../constants/categories/interfaces/category.interface';
import { UseCategoriesParams, UseCategoriesValue } from './interfaces/useCategories.interface';

export const useCategories = ({ searchPattern, selectedPlaces }: UseCategoriesParams): UseCategoriesValue => {
  const variables = useMemo<GetOpeningSearchCategoriesQueryVariables>(() => {
    const selectedLocations =
      selectedPlaces?.filter(selectedPlace => selectedPlace.type === PlaceTypeEnum.LOCATION) || [];
    const selectedCountries =
      selectedPlaces?.filter(selectedPlace => selectedPlace.type === PlaceTypeEnum.COUNTRY) || [];
    const isRemote = !!selectedPlaces?.some(selectedPlace => selectedPlace.type === PlaceTypeEnum.REMOTE);

    return {
      searchParams: {
        searchPattern: searchPattern,
        searchTagsWithAndCondition: [
          {
            tag: SearchOpeningEntityTagNameEnum.STATE,
            value: OPENING_STATE.ACTIVE,
          },
        ],
        searchTagsWithOrCondition: [
          ...selectedLocations.map(selectedLocation => {
            return {
              tag: SearchOpeningEntityTagNameEnum.POSITION_LOCATION,
              value: selectedLocation.id,
            };
          }),
          ...selectedCountries.map(selectedCountry => {
            return {
              tag: SearchOpeningEntityTagNameEnum.POSITION_COUNTRY,
              value: selectedCountry.id,
            };
          }),
          ...(isRemote
            ? [
                {
                  tag: SearchOpeningEntityTagNameEnum.REMOTE_TYPE,
                  value: OpeningRemoteTypeEnum.FULLY,
                },
              ]
            : []),
        ],
      },
    };
  }, [searchPattern, selectedPlaces?.map(selectedPlace => selectedPlace.id).toString()]);

  const { data: categoriesData, loading: isCategoriesDataLoading } = useGetOpeningSearchCategoriesQuery({
    variables: variables,
  });

  const mergedCategories = useMemo<Category[]>(() => {
    return (
      categoriesData?.openingSearchCategories.map(category => {
        const selectedCategory = CATEGORIES[category.type];

        return {
          ...selectedCategory,
          count: category.totalCount,
          name: category.name,
        };
      }) || Object.values(CATEGORIES)
    );
  }, [categoriesData, isCategoriesDataLoading]);

  return {
    categoriesWithCounts: mergedCategories,
    isCategoriesLoading: isCategoriesDataLoading,
  };
};
