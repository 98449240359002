import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import FadeInImage from '@src/components/FadeInImage';
import { MESSAGE_REQUESTS_ROUTE } from '@src/constants/routes';
import { MessageRequestsForInboxCardQuery } from '@src/graphql/generated';
import { PendingRequestsProps } from './props';
import './styles.scss';

export const PendingRequests: FC<PendingRequestsProps> = ({ messageRequests }) => {
  return (
    <Link className="pending-requests-card" to={MESSAGE_REQUESTS_ROUTE}>
      <div className="pending-requests-card__logos">
        {messageRequests
          .slice(0, 4)
          .map((messageRequest: MessageRequestsForInboxCardQuery['messageRequests']['result'][0]) => (
            <FadeInImage
              key={messageRequest?.id}
              className="pending-requests-card__logo"
              src={messageRequest?.company.profile.logo?.fullUrl}
              wrapperClassName="pending-requests-card__logo-wrapper"
            />
          ))}
      </div>

      <p className="pending-requests-card__view-requests">View requests</p>
    </Link>
  );
};
