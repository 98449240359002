import React, { FC, memo } from 'react';
import classnames from 'classnames';
import CSS_VARIABLES from 'assets/_vars.scss';
import FadeInImage from '@src/components/FadeInImage';
import { HEX_COLOR_OPACITY } from '@src/util/getHexColorOpacity';
import { AvatarSize } from './enums/avatarSize.enum';
import { AvatarProps } from './props';
import './styles.scss';

const { FR_BRAND_BLACK } = CSS_VARIABLES;

export const AvatarComponent: FC<AvatarProps> = ({
  size,
  src,
  className,
  avatarColor,
  initials,
  children,
  opacity,
  styles,
}) => {
  const content = children || initials;

  const classNames = classnames('fr-avatar', `fr-avatar--${size}`, className);

  return (
    <div
      className={classNames}
      style={{
        backgroundColor: `${avatarColor}${opacity}`,
        color: avatarColor,
        ...styles,
      }}
    >
      {src ? <FadeInImage alt="" className="fr-avatar__image" src={src} /> : content}
    </div>
  );
};

AvatarComponent.defaultProps = {
  avatarColor: FR_BRAND_BLACK,
  opacity: HEX_COLOR_OPACITY[10],
  size: AvatarSize.MEDIUM,
};

export const Avatar = memo(AvatarComponent);
