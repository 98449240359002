import React from 'react';
import qs from 'qs';
import { push } from 'connected-react-router';
import { sendFacebookAppEvent } from '@src/container';
import {
  ExternalUrlOpeningCandidateDestinationType,
  GetUserForVerificationEligibilityCheckDocument,
  GetUserForVerificationEligibilityCheckQuery,
  HiringStateEnum,
  MatchIntroStateEnum,
  OpeningCandidateDestinationTypeEnum,
  RecordSignalsMutationDocument,
  RecordSignalsMutationMutation,
  RecordSignalsMutationMutationVariables,
  SignalEntityTypeEnum,
  SignalTypeEnum,
  UserVerificationStateEnum,
} from '@src/graphql/generated';
import api from '@src/util/api';
import { apolloClient } from '@src/graphql/apollo';
import { getOpeningInformationalModalType } from '@src/util/getOpeningInformationalModalType';
import { setContent as setModalContent, closeModal } from '@src/redux/actions/slideUpModal';
import InformationalModal from '@src/components/InformationalModal';
import { VerifyTalentNotifyModal } from '@src/components/VerifyTalentNotifyModal';
import createLink from '@src/util/createLink';
import { VERIFIED_USER_ROUTE } from '@src/constants/routes';
import { RootAction } from '@src/store/interfaces/store.interface';
import { Context } from '@src/metrics/enums/context.enum';
import { Interaction } from '@src/metrics/enums/interaction.enum';
import { ItemType } from '@src/metrics/enums/itemType.enum';
import { trackEvent } from '@src/metrics';
import { isOnboardedUserProfileType } from './typeGuards';

export const OPENING_STATE_UPDATED = `${__dirname}/openingStateUpdated`;
export function openingStateUpdate(openingId, state) {
  return {
    openingId: openingId,
    state: state,
    type: OPENING_STATE_UPDATED,
  };
}

async function sendResponse(openingId, type, userMatchingProfileId) {
  const { url } = await api(
    `/api/app/openings/${openingId}/response${qs.stringify({ userMatchingProfileId }, { addQueryPrefix: true })}`,
    {
      json: {
        opening: {
          _id: openingId,
          type: type,
        },
      },
      method: 'POST',
    },
  );

  return url;
}

export const OPENING_POSITIVE_INTERACTION = `${__dirname}/openingPositiveInteraction`;
export function sendPositiveResponse(openingId: string, userMatchingProfileId: string) {
  sendFacebookAppEvent({ name: 'SubmitApplication' });

  return async dispatch => {
    const url = await sendResponse(openingId, 'positive', userMatchingProfileId);

    dispatch({
      openingId: openingId,
      type: OPENING_POSITIVE_INTERACTION,
    });

    return url;
  };
}

export function sendNegativeResponse(openingId, userMatchingProfileId) {
  return async dispatch => {
    const url = await sendResponse(openingId, 'archived', userMatchingProfileId);

    dispatch(openingStateUpdate(openingId, 'archived'));

    return url;
  };
}

const sendStartApplySignal = async (openingId: string): Promise<void> => {
  await apolloClient.mutate<RecordSignalsMutationMutation, RecordSignalsMutationMutationVariables>({
    mutation: RecordSignalsMutationDocument,
    variables: {
      signals: [
        {
          entityId: openingId,
          entityType: SignalEntityTypeEnum.OPENING,
          type: SignalTypeEnum.START_APPLY,
        },
      ],
    },
  });
};

export const startApply = (
  openingId: string,
  hiringState: HiringStateEnum,
  candidateDestination: Pick<ExternalUrlOpeningCandidateDestinationType, 'type'>,
  matchIntroState?: MatchIntroStateEnum,
  metricsContext: Context,
): RootAction<void | Promise<void>, unknown> => {
  return async dispatch => {
    const InformationalModalType = getOpeningInformationalModalType(hiringState);

    if (
      !InformationalModalType ||
      matchIntroState === MatchIntroStateEnum.CHAT ||
      candidateDestination.type === OpeningCandidateDestinationTypeEnum.EXTERNAL_URL
    ) {
      const url = await dispatch(sendPositiveResponse(openingId));

      const { data: userData } = await apolloClient.query<GetUserForVerificationEligibilityCheckQuery>({
        fetchPolicy: 'network-only',
        query: GetUserForVerificationEligibilityCheckDocument,
      });

      if (url && candidateDestination.type === OpeningCandidateDestinationTypeEnum.MEETFRANK) {
        dispatch(push(url));
      }

      const userVerificationState = userData.currentUser.verification?.state;
      const isCandidateDestinationExternal =
        candidateDestination.type === OpeningCandidateDestinationTypeEnum.EXTERNAL_URL;

      const isVerificationPublishedOrPending =
        userVerificationState &&
        [UserVerificationStateEnum.PUBLISHED, UserVerificationStateEnum.PENDING].includes(userVerificationState);

      const canUserCreateVerification =
        isOnboardedUserProfileType(userData.currentUser.profile) && userData.currentUser.isEligibleForVerification;

      if (isCandidateDestinationExternal && !isVerificationPublishedOrPending && canUserCreateVerification) {
        dispatch(
          setModalContent({
            content: (
              <VerifyTalentNotifyModal
                onContinue={() => dispatch(push(createLink({ link: VERIFIED_USER_ROUTE })))}
                onDismiss={() => dispatch(closeModal())}
              />
            ),
            height: 'auto',
          }),
        );
      }

      const isOpenChat = /^\/chat\//.test(url);

      trackEvent({
        context: metricsContext,
        interaction:
          candidateDestination.type === OpeningCandidateDestinationTypeEnum.EXTERNAL_URL
            ? Interaction.START_EXTERNAL_APPLICATION
            : isOpenChat
            ? Interaction.OPEN_CHAT
            : Interaction.START_APPLICATION,
        itemType: ItemType.OPENING,
        itemValue: openingId,
      });

      if (!isOpenChat) {
        await sendStartApplySignal(openingId);
      }
    } else {
      dispatch(
        setModalContent({
          content: <InformationalModal close={() => dispatch(closeModal())} type={InformationalModalType} />,
          height: 'auto',
        }),
      );
    }
  };
};
