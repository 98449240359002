import path from 'path';
import { isValidElement } from 'react';
import { CloseModal, SetContent } from './slideUpModal.interface';

export const MODAL_CLOSED = path.join(__dirname, 'slideUpModalClosed');
export const closeModal: CloseModal = () => {
  return { type: MODAL_CLOSED };
};

export const CONTENT_SET = path.join(__dirname, 'slideUpModalContentSet');
export const setContent: SetContent = data => {
  if (isValidElement(data)) {
    return {
      content: data,
      type: CONTENT_SET,
    };
  }

  return {
    ...data,
    type: CONTENT_SET,
  };
};
