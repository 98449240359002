import React, { Fragment } from 'react';
import { BasePageHeader } from '@src/containers/PageHeader/BasePageHeader';
import { BaseAppBar } from '@src/components/PrimaryAppBar';

export const PageHeader = () => {
  return (
    <BasePageHeader toggleAppBarEl={<BaseAppBar />}>
      <Fragment>
        <BaseAppBar />
      </Fragment>
    </BasePageHeader>
  );
};
