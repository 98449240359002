import React from 'react';
import { range } from 'lodash';
import './styles.scss';

export const LoadingState = () => {
  return (
    <div className="home-verified-talent-card-loading">
      {range(3).map(item => {
        return (
          <div className="home-verified-talent-card-loading__item" key={item}>
            <div className="home-verified-talent-card-loading__circle pulse-fade" />

            <div className="home-verified-talent-card-loading__wrapper">
              <div className="home-verified-talent-card-loading__line pulse-fade" />
              <div className="home-verified-talent-card-loading__line home-verified-talent-card-loading__line--short pulse-fade" />
            </div>
          </div>
        );
      })}

      <div className="home-verified-talent-card-loading__box pulse-fade" />
    </div>
  );
};
