import React, { FC } from 'react';
import { replaceMessageStringVariables } from '@shared/utils/replaceMessageStringVariables';
import { CompanyHighLights } from '@src/components/CompanyHighLights';
import { MessageHeader } from '@src/components/MessageHeader';
import FadeInImage from '@src/components/FadeInImage';
import { Markdown } from '@src/components/Markdown';
import { CardProps } from './props';
import './styles.scss';

export const Card: FC<CardProps> = ({
  companyName,
  companyDescription,
  countryFlagUrl,
  imageUrl,
  employeesAmount,
  financialDetails,
  avatarColor,
  nameInitials,
  avatarUrl,
  recruiterName,
  userName,
  headerSubtitle,
  badgeUrl,
  message,
  date,
  industries,
  openingTitle,
}) => {
  return (
    <div className="frank-message-request-page-card">
      <div className="frank-message-request-page-card__header">
        <MessageHeader
          avatarProps={{
            avatarColor: avatarColor,
            initials: nameInitials,
            src: avatarUrl,
          }}
          fadeInImageProps={{
            src: badgeUrl,
          }}
          subTitle={headerSubtitle}
          title={recruiterName}
        />

        <div className="frank-message-request-page-card__date">{date}</div>
      </div>

      <div className="frank-message-request-page-card__message">
        <Markdown
          markdown={replaceMessageStringVariables(message, {
            name: userName,
            title: openingTitle,
          })}
        />
      </div>

      <div className="frank-message-request-page-card__image-wrapper">
        <FadeInImage className="frank-message-request-page-card__image" src={imageUrl} />
      </div>

      <div className="frank-message-request-page-card__body">
        <div className="frank-message-request-page-card__title">
          <span className="frank-message-request-page-card__title-company-name">{companyName}</span>
          <span className="frank-message-request-page-card__industries">{industries}</span>
        </div>

        <div className="frank-message-request-page-card__text">
          <Markdown markdown={companyDescription} />
        </div>

        <div className="frank-message-request-page-card__details">
          <CompanyHighLights
            countryFlagUrl={countryFlagUrl}
            employeesAmount={employeesAmount}
            financialDetails={financialDetails}
          />
        </div>
      </div>
    </div>
  );
};
