import React from 'react';
import classnames from 'classnames';
import { first, find } from 'lodash';
import Svg from 'svg-inline-react';
import FrExpandSvg from 'assets/images/fr-expand.svg';
import PropTypes from 'prop-types';
import { SEARCH_TAGS, EXPLORE_FILTER } from '@src/constants';
import { useSenioritiesQuery } from '@src/graphql/generated';
import FilterModal from '../FilterModal';
import { normalizeSenioritiesForFilter } from './util';
import { ALL_SENIORITIES, QUERY_KEYS } from './constants';

const Seniorities = ({ wrapperClassName, viewType, locationSearch, setModalContent, setFilters }) => {
  const { data, loading } = useSenioritiesQuery();

  const normalizedSeniorities = normalizeSenioritiesForFilter(locationSearch);

  if (loading) {
    return <div className="filters__item filters__item--loading pulse-fade" />;
  }

  const { seniorities } = data;
  const selectedSeniority = (find(seniorities, { id: first(normalizedSeniorities).id }) || {}).name;

  return (
    <div
      className={classnames(
        'filters__item',
        `filters__${viewType}`,
        !!selectedSeniority && `filters__${viewType}--active`,
        wrapperClassName,
      )}
      onClick={() =>
        setModalContent({
          content: (
            <FilterModal
              activeFilters={normalizedSeniorities}
              customData={[ALL_SENIORITIES]}
              filter={EXPLORE_FILTER.SENIORITIES}
              onSave={filters => setFilters(filters, SEARCH_TAGS.SENIORITY)}
              queryKey={QUERY_KEYS.SENIORITIES}
              subTitle="Choose one or more seniorities"
              title="Filter by seniorities"
            />
          ),
          height: 75,
        })
      }
    >
      <div className="filters__item-label-wrapper">
        <span className="filters__item-label">{selectedSeniority || 'Seniorities'}</span>
        {normalizedSeniorities.length > 1 && (
          <span className="filters__selection-amount">{`+${normalizedSeniorities.length - 1}`}</span>
        )}
      </div>

      {viewType === 'fixed-header' && <Svg className="filters__expand-icon" src={FrExpandSvg} />}
    </div>
  );
};

Seniorities.propTypes = {
  locationSearch: PropTypes.string,
  setFilters: PropTypes.func.isRequired,
  setModalContent: PropTypes.func.isRequired,
  viewType: PropTypes.oneOf(['fixed-header', 'pill', 'label']),
  wrapperClassName: PropTypes.string,
};

export default Seniorities;
