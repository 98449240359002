import React, { FC } from 'react';
import Svg from 'svg-inline-react';
import FrCloseSvg from 'assets/images/fr-close.svg';
import { SelectItem } from '@src/components/SelectItem';
import { useSpecialitiesQuery } from '@src/graphql/generated';
import { Category } from '../../enums/category.enum';
import { SpecialitiesModalContentProps } from './props';
import './styles.scss';

export const SpecialitiesModalContent: FC<SpecialitiesModalContentProps> = ({
  onClose,
  activeSpecialityId,
  onSelect,
}) => {
  const { data: specialitiesData } = useSpecialitiesQuery();

  return (
    <div className="jobs-specialities-modal-content">
      <div className="jobs-specialities-modal-content__header">
        Categories
        <Svg className="jobs-specialities-modal-content__close" onClick={onClose} src={FrCloseSvg} />
      </div>

      <div className="jobs-specialities-modal-content__options">
        <SelectItem
          active={activeSpecialityId === Category.FOR_YOU}
          isParent
          isSingleSelect
          onSelect={() => {
            onSelect(Category.FOR_YOU);
          }}
          option={{ value: 'for-you' }}
          optionLabel="For you"
        />

        <SelectItem
          active={activeSpecialityId === Category.ALL_OFFERS}
          isParent
          isSingleSelect
          onSelect={() => {
            onSelect(Category.ALL_OFFERS);
          }}
          option={{ value: 'all-offers' }}
          optionLabel="All offers"
        />

        {specialitiesData?.specialities.map(speciality => {
          return (
            <SelectItem
              key={speciality?.id}
              active={activeSpecialityId === speciality?.id}
              isParent
              isSingleSelect
              onSelect={() => {
                if (speciality?.id) {
                  onSelect(speciality?.id);
                }
              }}
              option={{ value: speciality?.id }}
              optionLabel={speciality?.name}
            />
          );
        })}
      </div>
    </div>
  );
};
