import React, { FC } from 'react';
import { FixedHeader } from '@src/components/FixedHeader';
import { AreaList } from '@src/containers/AreaList';
import { ActionButtons } from '../ActionButtons';
import { Props } from './props';
import './styles.scss';

export const AreaSelection: FC<Props> = ({
  selectedAreas,
  goBack,
  onAreaSave,
  activeCountries,
  onSpecificCountrySelect,
  onAreasClear,
  isSpecificCountrySelected,
  excludedAreaIds,
  onAreaSelect,
  isSingleSelect,
}) => {
  return (
    <div className="place-filter-modal-area-selection">
      <FixedHeader
        disableOpacity
        onBack={goBack}
        title="Where would you like to work?"
        wrapperClassName="place-filter-modal-area-selection__header"
      />

      <div className="place-filter-modal-area-selection__body">
        <AreaList
          activeAreas={selectedAreas}
          activeCountries={activeCountries}
          excludedAreaIds={excludedAreaIds}
          isSingleSelect={isSingleSelect}
          isSpecificCountrySelected={isSpecificCountrySelected}
          onAreaSelect={onAreaSelect}
          onSpecificCountrySelect={onSpecificCountrySelect}
        />
      </div>

      <div className="place-filter-modal-area-selection__footer">
        <ActionButtons
          blueButtonLabel="Save"
          blueButtonProps={{
            onClick: () => onAreaSave(selectedAreas),
          }}
          invisibleButtonProps={{
            onClick: onAreasClear,
          }}
        />
      </div>
    </div>
  );
};
