import gql from 'graphql-tag';
import { apolloClient } from '@src/graphql/apollo';

export function toggleBookmarkFromCache(openingId: string, hasBookmarked: boolean): void {
  apolloClient.cache.writeFragment({
    data: {
      hasBookmarked: hasBookmarked,
    },
    fragment: gql`
      fragment User on OpeningType {
        hasBookmarked
      }
    `,
    id: `OpeningType:${openingId}`,
  });
}
