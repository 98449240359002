import React, { FC, Fragment } from 'react';
import { BasePageHeader } from '@src/containers/PageHeader/BasePageHeader';
import { BaseAppBar } from '@src/components/PrimaryAppBar';
import { EmailPrompt } from '@src/containers/EmailPrompt';

export const PageHeader: FC = () => {
  return (
    <BasePageHeader toggleAppBarEl={<BaseAppBar />}>
      <Fragment>
        <BaseAppBar />

        <EmailPrompt />
      </Fragment>
    </BasePageHeader>
  );
};
