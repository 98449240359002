import { getNameInitials } from '@src/util/getNameInitials';
import { LocationType, UserType } from '@src/graphql/generated';
import { relativeTime } from '../relativeTime';
import { ReviewActorInterface } from './reviewActor.interface';

export const getReviewActorData = (
  actor?: Pick<UserType, 'avatarColor' | 'name' | 'position'> & {
    location?: Pick<LocationType, 'shortName'> | null;
  },
  createdAt?: Date,
): ReviewActorInterface => {
  return {
    avatarColor: actor?.avatarColor || undefined,
    fullName: actor?.name || '',
    nameInitials: actor?.name ? getNameInitials(actor?.name) : undefined,
    subTitleWithTime: `${actor?.position} • ${createdAt ? relativeTime(createdAt) : ''}`,
    userPosition: actor?.position || undefined,
    userSubTitle: actor?.location
      ? `${actor?.position || ''}, ${actor?.location.shortName || ''}`
      : actor?.position || '',
  };
};
