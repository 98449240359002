import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Svg from 'svg-inline-react';
import { Link } from '@src/components/Link';
import { SectionHeaderSkeleton, SectionHeaderTitleSkeleton } from './Skeleton';
import { SectionHeaderSubTitleSkeleton } from './Skeleton/SectionHeaderSubTitleSkeleton';
import './styles.scss';

export const SectionHeader = ({
  title,
  titleClassName,
  subTitleClassName,
  wrapperClassName,
  titleIcon,
  titleIconClassName,
  rightIconLabel,
  rightIconLabelClassName,
  rightIcon,
  rightIconClassName,
  subTitle,
  to,
  hasBorder,
  isLoading,
  isTitleLoading,
  isSubTitleLoading,
  subTitleLoadingBarCount,
}) => {
  if (isLoading) {
    return <SectionHeaderSkeleton hasBorder={hasBorder} subTitleCount={subTitleLoadingBarCount} />;
  }

  const className = classnames('section-header', hasBorder && 'section-header--border', wrapperClassName);

  const content = (
    <Fragment>
      <div className="section-header__title-container">
        {isTitleLoading ? (
          <SectionHeaderTitleSkeleton />
        ) : (
          <Fragment>
            <div className="section-header__left-container">
              {!!titleIcon && (
                <Svg className={classnames('section-header__title-icon', titleIconClassName)} src={titleIcon} />
              )}
              <div className={classnames('section-header__title', titleClassName)}>{title}</div>
            </div>

            <div className="section-header__right-container">
              <div className={classnames('section-header__right-icon-label', rightIconLabelClassName)}>
                {rightIconLabel}
              </div>
              {!!rightIcon && (
                <Svg
                  className={classnames(
                    'section-header__right-icon',
                    { 'section-header__right-icon--with-label': !!rightIconLabel },
                    rightIconClassName,
                  )}
                  src={rightIcon}
                />
              )}
            </div>
          </Fragment>
        )}
      </div>

      {isSubTitleLoading ? (
        <SectionHeaderSubTitleSkeleton count={subTitleLoadingBarCount} />
      ) : (
        subTitle && <div className={classnames('section-header__subtitle', subTitleClassName)}>{subTitle}</div>
      )}
    </Fragment>
  );

  return to ? (
    <Link className={className} to={to}>
      {content}
    </Link>
  ) : (
    <div className={className}>{content}</div>
  );
};

SectionHeader.propTypes = {
  hasBorder: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSubTitleLoading: PropTypes.bool,
  isTitleLoading: PropTypes.bool,
  rightIcon: PropTypes.string,
  rightIconClassName: PropTypes.string,
  rightIconLabel: PropTypes.string,
  rightIconLabelClassName: PropTypes.string,
  subTitle: PropTypes.node,
  subTitleClassName: PropTypes.string,
  subTitleLoadingBarCount: PropTypes.number,
  title: PropTypes.string,
  titleClassName: PropTypes.string,
  titleIcon: PropTypes.string,
  titleIconClassName: PropTypes.string,
  to: PropTypes.string,
  wrapperClassName: PropTypes.string,
};
