import { find } from 'lodash';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import React, { PureComponent } from 'react';
import { apolloClient } from '@src/graphql/apollo';
import { GetSalaryStepsDocument, GetSalaryStepsQuery } from '@src/graphql/generated';
import Scroller from '../Scroller';
import { Select } from './Select';
import './styles.scss';

export default class Salary extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    defaultValue: PropTypes.object,
    identifier: PropTypes.string.isRequired,
    onValueSelect: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      currency: this.getDefaultCurrency(),
      period: this.getDefaultPeriod(),
      salaryOptions: undefined,
    };
  }

  componentDidMount() {
    this.props.onValueSelect(undefined);
    this.fetchSalaries();
  }

  getDefaultCurrency = () => {
    const { values, defaultValue } = this.props;
    const currency = defaultValue ? defaultValue.currency : 'EUR';

    return find(values.currencies, { key: currency });
  };

  getDefaultPeriod = () => {
    const { values, defaultValue } = this.props;
    const period = defaultValue ? defaultValue.period : 'month';

    return find(values.periods, { key: period });
  };

  fetchSalaries = async () => {
    const { currency, period } = this.state;
    const { data } = await apolloClient.query<GetSalaryStepsQuery>({
      fetchPolicy: 'no-cache',
      query: GetSalaryStepsDocument,
      variables: {
        currencyCode: currency.key,
        period: period.key.toUpperCase(),
      },
    });

    let _options = data.salarySteps.map((option, index) => {
      if (index === 0) {
        return {
          key: option.step,
          value: `${currency.symbol}${option.step} or less`,
        };
      }

      return {
        key: option.step,
        value:
          index === data.salarySteps.length - 1
            ? `${currency.symbol}${option.step} or more`
            : `${currency.symbol}${option.step}`,
      };
    });

    _options = [{ key: 'default', value: 'Slide down to select' }, ..._options];

    this.setState({
      salaryOptions: _options,
    });
  };

  setPeriod = period => this.setState({ period }, this.fetchSalaries);
  setCurrency = currency => this.setState({ currency }, this.fetchSalaries);

  onSalaryChange = _salary => {
    const { identifier, onValueSelect } = this.props;
    const { period, currency } = this.state;
    const salary = _salary[identifier] || {};

    if (salary.key === 'default') {
      onValueSelect(undefined);
    } else {
      onValueSelect({
        [identifier]: {
          currency: currency.value,
          period: period.value,
          value: salary.key,
        },
      });
    }
  };

  render() {
    const {
      identifier,
      className,
      values: { currencies, periods },
      defaultValue,
    } = this.props;
    const { currency, period, salaryOptions } = this.state;

    return (
      <div className={classnames('salary', className)}>
        <h1>
          What’s your {identifier === 'minSalary' ? 'expected' : ''} gross&nbsp;
          <Select onChange={this.setPeriod} options={periods} selectedValue={period} />
          {' salary in '}
          <Select onChange={this.setCurrency} options={currencies} selectedValue={currency} />
        </h1>

        <h2>Gross salary - your income before the taxes are taken off</h2>

        {salaryOptions && (
          <Scroller
            defaultValue={find(salaryOptions, { key: defaultValue && defaultValue.value })}
            identifier={identifier}
            onValueSelect={this.onSalaryChange}
            options={salaryOptions}
          />
        )}
      </div>
    );
  }
}
