import React, { FC } from 'react';
import { replaceMessageStringVariables } from '@shared/utils/replaceMessageStringVariables';
import classNames from 'classnames';
import twemoji from 'twemoji';
import moment from 'moment';
import { MessageAttachmentType, MessageSenderEnum, useGetUserQuery } from '@src/graphql/generated';
import FadeInImage from '@src/components/FadeInImage';
import { getNameInitials } from '@src/util/getNameInitials';
import { FrankAvatar } from '@src/components/FrankAvatar';
import { useAppSelector } from '@src/store';
import { getEmojiSupportedStatus } from '@src/redux/selectors/emoji';
import { MessageBubbleText } from './MessageBubbleText';
import { MessageBubbleOpening } from './MessageBubbleOpening';
import { MessageProps } from './props';
import './styles.scss';

const TIMESTAMP_FORMAT = 'DD MMM, HH:MM';

export const Message: FC<MessageProps> = ({
  message,
  isNextSenderDifferent,
  opening,
  selectedMessageId,
  onMessageClick,
  type,
  isFullWidth,
  openingAttachment,
}) => {
  const isEmojiSupported = useAppSelector(getEmojiSupportedStatus);

  const { data: userData } = useGetUserQuery();
  const user = userData?.currentUser;

  const messageId = message.id;

  const messageText = replaceMessageStringVariables(message?.text || '', {
    name: user?.name || '',
    title: opening?.title || '',
  });

  return (
    <div
      className={classNames(
        'inbox-message',
        `inbox-message--${message.sender.toLowerCase()}`,
        isNextSenderDifferent && 'inbox-message--last',
        messageId === selectedMessageId && 'inbox-message--selected',
        isFullWidth && 'inbox-message--full-width',
      )}
      onClick={() => onMessageClick?.(messageId)}
    >
      <div className="inbox-message__message-container">
        {message.sender === MessageSenderEnum.RECRUITER &&
          (message.recruiter?.avatarUrl ? (
            <FadeInImage
              className={classNames(
                'inbox-message__message-image',
                isNextSenderDifferent && 'inbox-message__show-image',
              )}
              src={message.recruiter.avatarUrl}
            />
          ) : (
            <div
              className={classNames(
                'inbox-message__item-initials',
                isNextSenderDifferent && 'inbox-message__show-image',
              )}
              style={{ backgroundColor: message.recruiter?.avatarColor || undefined }}
            >
              {getNameInitials(message.recruiter?.fullName)}
            </div>
          ))}

        {message.sender === MessageSenderEnum.BOT && (
          <FrankAvatar
            className={classNames('inbox-message__message-image', isNextSenderDifferent && 'inbox-message__show-image')}
          />
        )}

        {type === MessageAttachmentType.OPENING && openingAttachment && (
          <MessageBubbleOpening opening={openingAttachment} />
        )}

        {!type && (
          <MessageBubbleText
            messageText={isEmojiSupported ? twemoji.parse(messageText) : messageText}
            type={message.sender === MessageSenderEnum.USER ? 'outgoing' : 'incoming'}
          />
        )}
      </div>

      <div className="inbox-message__message-timestamp">
        {(message.sender === MessageSenderEnum.RECRUITER || message.sender === MessageSenderEnum.BOT) &&
          `${moment(message.sentAt).format(TIMESTAMP_FORMAT)}`}

        {message.sender === MessageSenderEnum.USER &&
          (message.readByUserAt ? `Seen ${moment(message.readByUserAt).format(TIMESTAMP_FORMAT)}` : 'Not seen')}
      </div>
    </div>
  );
};
