import React, { FC, ReactNode, useState } from 'react';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { Link, useHistory } from 'react-router-dom';
import { GetMessageRequestsQuery, useGetUserQuery } from '@src/graphql/generated';
import { getFinancialDetailsCopy } from '@src/util/company';
import { countryFlagUrl } from '@src/util/countryFlagImageUrl';
import { MessagesActionButtons } from '@src/components/MessagesActionButtons';
import { AcceptedMessageOverlay } from '@src/components/AcceptedMessageOverlay';
import { CHAT_ROUTE, MEETFRANK_COMPANY_ID, MESSAGE_REQUESTS_ROUTE, VERIFIED_USER_ROUTE } from '@src/constants';
import createLink from '@src/util/createLink';
import dateStringFromDate from '@src/util/dateStringFromDate';
import { useMessageRequests } from '@src/graphql/chat/hooks/useMessageRequests';
import { Context } from '@src/metrics/enums/context.enum';
import { trackEvent } from '@src/metrics';
import { Interaction } from '@src/metrics/enums/interaction.enum';
import { ItemType } from '@src/metrics/enums/itemType.enum';
import { SectionTitle } from '../SectionTitle';
import { Card } from './Card';
import { Skeleton } from './Card/Skeleton';
import { MessageRequestsProps } from './props';
import './styles.scss';

export const MessageRequests: FC<MessageRequestsProps> = ({ isPublished }) => {
  const history = useHistory();

  const { data: userData } = useGetUserQuery();

  const userName = userData?.currentUser.name;

  const [slideIndex, setSlideIndex] = useState(0);

  const [overlayData, setOverlayData] = useState<GetMessageRequestsQuery['messageRequests']['result'][0] | null>(null);

  const { handleAccept, handleDecline, isFetchMoreLoading, isInitialLoading, totalCount, messages, hasMoreResults } =
    useMessageRequests({
      context: Context.HOME,
      onAccept: messageRequest => setOverlayData(messageRequest),
      slideIndex: slideIndex,
    });

  const getSubTitle = (isPublished: boolean, isEmpty: boolean): ReactNode => {
    if (!isPublished) {
      return (
        <>
          Make your profile visible to get messages from recruiters.{' '}
          <Link className="frank-message-requests__verify-link" to={VERIFIED_USER_ROUTE}>
            Get started
          </Link>
        </>
      );
    }

    if (isEmpty) {
      return 'Messages from recruiters will appear here';
    }

    return undefined;
  };

  const pushToMessageDetails = (messageId: string): void => {
    const messageDetailsLink = createLink(MESSAGE_REQUESTS_ROUTE, {
      params: {
        messageId: messageId,
      },
    });

    history.push(messageDetailsLink);

    trackEvent({
      context: Context.HOME,
      interaction: Interaction.OPEN,
      itemType: ItemType.MESSAGE_REQUESTS,
      itemValue: messageId,
    });
  };

  const onSlideChange = (index: number): void => {
    setSlideIndex(index);
  };

  return (
    <div className="frank-message-requests">
      <div className="frank-message-requests__title">
        <SectionTitle
          count={totalCount ? `${slideIndex + 1}/${totalCount}` : ''}
          subtitle={!isInitialLoading ? getSubTitle(isPublished, !messages.length) : undefined}
          title="Message requests"
        />
      </div>

      {isInitialLoading && (
        <div className="frank-message-requests__loading-wrapper">
          <Skeleton />
        </div>
      )}

      {!isInitialLoading && !!messages.length && (
        <div className="frank-message-requests__swiper-wrapper">
          <Swiper
            allowSlidePrev={!hasMoreResults}
            loop={true}
            modules={[Pagination]}
            onSlideChange={swiper => onSlideChange(swiper.realIndex)}
            pagination={{
              dynamicBullets: totalCount > 3,
              el: '.frank-message-requests__pagination',
            }}
          >
            {messages.map(messageItem => {
              const recruiter = messageItem?.recruiter;
              const isRecruiterFromMeetFrank = recruiter?.company?.id === MEETFRANK_COMPANY_ID;
              const recruiterCompanyName = isRecruiterFromMeetFrank ? recruiter?.company?.profile.name : undefined;
              const company = messageItem?.company;
              const companyProfile = company?.profile;
              const office =
                companyProfile?.offices.find(office => office.isHeadquarter) || companyProfile?.offices?.[0];
              const recruiterTitle = recruiter?.title;
              const companyName = companyProfile?.name;

              const companyDescription =
                companyProfile?.missionStatement ||
                companyProfile?.serviceDescription ||
                companyProfile?.officeDescription;

              const financialDetails = getFinancialDetailsCopy(
                companyProfile?.latestValuation || undefined,
                companyProfile?.totalFunding,
                companyProfile?.revenue || undefined,
              );

              return (
                <SwiperSlide key={messageItem?.id}>
                  <div className="frank-message-requests__item">
                    <Card
                      avatarColor={recruiter?.avatarColor || undefined}
                      avatarUrl={recruiter?.avatarUrl || undefined}
                      badgeUrl={!isRecruiterFromMeetFrank ? company?.profile.logo?.fullUrl : undefined}
                      companyDescription={companyDescription || undefined}
                      companyName={companyName || ''}
                      countryFlagUrl={office ? countryFlagUrl(office.location.country?.shortName) : undefined}
                      date={dateStringFromDate(messageItem?.latestMessage.sentAt)}
                      employeesAmount={companyProfile?.employeesCount || 0}
                      financialDetails={financialDetails}
                      headerSubtitle={`${recruiterTitle} at ${recruiterCompanyName || companyName}`}
                      message={messageItem?.latestMessage.text || ''}
                      nameInitials={recruiter?.nameInitials || recruiter?.fullName?.[0]}
                      onClick={() => messageItem && pushToMessageDetails(messageItem?.id)}
                      opening={messageItem?.opening}
                      recruiterName={recruiter?.fullName || ''}
                      userName={userName || ''}
                    />
                  </div>
                </SwiperSlide>
              );
            })}

            {isFetchMoreLoading && (
              <SwiperSlide key="loading">
                <div className="frank-message-requests__item">
                  <Skeleton />
                </div>
              </SwiperSlide>
            )}

            <div className="frank-message-requests__footer" slot="container-end">
              <MessagesActionButtons
                onAccept={() => handleAccept(slideIndex)}
                onDecline={() => handleDecline(slideIndex)}
              />

              {!!messages.length && (
                <div className="frank-message-requests__pagination-wrapper">
                  <div className="frank-message-requests__pagination" />
                </div>
              )}
            </div>
          </Swiper>

          <AcceptedMessageOverlay
            chatLink={createLink({
              link: CHAT_ROUTE,
              params: { threadId: overlayData?.id },
            })}
            isOpen={!!overlayData}
            onCancel={() => setOverlayData(null)}
            recruiterName={overlayData?.recruiter.fullName || ''}
          />
        </div>
      )}
    </div>
  );
};
