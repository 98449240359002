import React, { FC } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

export * from './Item';

export const ItemsList: FC = ({ children }) => {
  const filteredChildren = children.filter(child => !!child);

  if (!filteredChildren.length === 0) {
    return null;
  }

  return <div className="position-items-list">{filteredChildren}</div>;
};

ItemsList.propTypes = {
  children: PropTypes.node.isRequired,
};
