import { orderBy } from 'lodash';
import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { ChatsItem } from './ChatsItem';
import './styles.scss';

export default function ChatsList({ chats, className }) {
  return (
    <div className={classnames('chats-list', className)}>
      {orderBy(chats, item => (item.lastMessageCreatedAt ? item.lastMessageCreatedAt : new Date(item.updateAt)), [
        'desc',
      ]).map((item, i) => {
        return <ChatsItem item={item} key={item._id} />;
      })}
    </div>
  );
}

ChatsList.propTypes = {
  chats: PropTypes.arrayOf(PropTypes.object).isRequired,
  className: PropTypes.string,
};
