import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

export function Video({ video }) {
  if (!video || !video.source === 'youtube' || !video.source === 'vimeo') {
    return null;
  }

  return (
    <iframe
      border="0"
      className="opening-details-video"
      src={
        video.source === 'youtube'
          ? `https://www.youtube.com/embed/${
              video.videoId || video.id
            }?modestbranding=1&showinfo=0&controls=0&iv_load_policy=3&rel=0`
          : video.embedUrl
      }
    />
  );
}

Video.propTypes = {
  video: PropTypes.shape({
    embedUrl: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,
    videoId: PropTypes.string.isRequired,
  }),
};
