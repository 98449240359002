import React, { FC, useState } from 'react';
import classnames from 'classnames';
import { ClampedMessage } from '@src/components/ClampedMessage';
import { MessageWithReadMoreProps } from './props';
import './styles.scss';

export const MessageWithReadMore: FC<MessageWithReadMoreProps> = ({
  onClick,
  message,
  limit,
  lineLimit = 2,
  wrapperClassName,
  onReadMoreClick,
  shouldAlwaysShowReadMore = false,
  readMoreClassName = 'message-wrapper__read-more--color',
  messageClassName,
  shouldForceInline,
}) => {
  const [shouldShowReadMore, setShouldShowMore] = useState<boolean>(!!(limit && limit > 0 && message.length > limit));

  const onReadMore = () => {
    if (onReadMoreClick) {
      onReadMoreClick();
    } else if (!onClick) {
      setShouldShowMore(false);
    }
  };

  if (!message) {
    return null;
  }

  return (
    <div className={classnames('message-wrapper', wrapperClassName)} onClick={() => onClick?.()}>
      <ClampedMessage
        className={messageClassName}
        shouldForceInline={shouldForceInline}
        isClamped={shouldShowReadMore}
        lineLimit={lineLimit}
        message={message}
      />

      {(shouldShowReadMore || shouldAlwaysShowReadMore) && (
        <span
          className={classnames('message-wrapper__read-more', messageClassName, readMoreClassName)}
          onClick={() => onReadMore()}
        >
          Read more
        </span>
      )}
    </div>
  );
};
