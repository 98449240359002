import { QueryOptions } from '@apollo/client';
import { apolloClient } from '@src/graphql/apollo';
import { GetUserDocument, GetUserQuery } from '@src/graphql/generated';

export const getUser = async (
  options?: Omit<QueryOptions, 'query'>,
): Promise<GetUserQuery['currentUser'] | undefined> => {
  const result = await apolloClient.query<GetUserQuery>({
    ...(options || {}),
    query: GetUserDocument,
  });

  return result?.data?.currentUser;
};
