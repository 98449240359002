import React, { FC } from 'react';
import { HorizontalScroller } from '@src/components/HorizontalScroller';
import { Card } from '@src/components/Card';
import { CardScrollerSkeleton } from './Skeleton';
import { CardScrollerProps } from './props';

export const CardScroller: FC<CardScrollerProps> = ({ isLoading, items }) => {
  if (isLoading) {
    return <CardScrollerSkeleton isAnimated />;
  }

  return (
    <HorizontalScroller>
      {!isLoading &&
        items.map((itemProps, index) => {
          return <Card key={index} {...itemProps} />;
        })}
    </HorizontalScroller>
  );
};
