import React, { FC } from 'react';
import Svg from 'svg-inline-react';
import GlobeSvg from '@shared/assets/svgs/globe.svg';
import LaptopSvg from '@shared/assets/svgs/laptop.svg';
import { getCountryFlagTemplateUrl } from '@src/util/filters';
import FadeInImage from '@src/components/FadeInImage';
import { REMOTE_PLACE } from '../constants/places';
import { PlacesProps } from './props';
import './styles.scss';

export const Places: FC<PlacesProps> = ({ places, onAllPlacesSelect, onPlaceSelect, isLoading }) => {
  return (
    <div className="jobs-search-places">
      <div
        onClick={() => {
          onAllPlacesSelect();
        }}
        className="jobs-search-places__item"
      >
        <div className="jobs-search-places__icon-wrapper jobs-search-places__icon-wrapper--all">
          <Svg className="jobs-search-places__icon" src={GlobeSvg} />
        </div>

        <span>All locations</span>
      </div>

      <div
        onClick={() => {
          onPlaceSelect(REMOTE_PLACE);
        }}
        className="jobs-search-places__item"
      >
        <div className="jobs-search-places__icon-wrapper jobs-search-places__icon-wrapper--remote">
          <Svg className="jobs-search-places__icon" src={LaptopSvg} />
        </div>

        <span>Remote</span>
      </div>

      {isLoading
        ? [1, 2, 3, 4, 5].map(place => {
            return (
              <div className="jobs-search-places__item jobs-search-places__item--loading pulse-fade" key={place}>
                <div className="jobs-search-places__flag jobs-search-places__flag--loading" />

                <div className="jobs-search-places__name jobs-search-places__name--loading" />
              </div>
            );
          })
        : places.map(place => {
            return (
              <div
                className="jobs-search-places__item"
                key={place.id}
                onClick={() => {
                  onPlaceSelect(place);
                }}
              >
                {place.countryShortName && (
                  <FadeInImage
                    className="jobs-search-places__flag"
                    src={getCountryFlagTemplateUrl(place.countryShortName)}
                  />
                )}

                <span>{place.name}</span>
              </div>
            );
          })}
    </div>
  );
};
