import React, { PureComponent } from 'react';
import { escape } from 'lodash';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Markdown } from '@src/components/Markdown';
import './styles.scss';

export default class ChatBubble extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    escaped: PropTypes.bool,
    fullTextIsShown: PropTypes.bool,
    isDark: PropTypes.bool,
    limit: PropTypes.number,
    placeholder: PropTypes.oneOf(['short', 'long']),
    setRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    value: PropTypes.string,
  };

  static defaultProps = {
    value: '',
  };

  state = {
    showMore: false,
  };

  showMore = () => this.setState(({ showMore }) => ({ showMore: !showMore }));

  componentDidMount() {
    if (this.props.fullTextIsShown) {
      this.showMore();
    }
  }

  render() {
    const { className, escaped, limit, isDark, setRef, placeholder } = this.props;
    const { showMore } = this.state;
    let { value } = this.props;

    const overLimit = value.length > limit;

    if (escaped) {
      value = escape(value);
    }

    return (
      <div
        className={classnames('bubble-wrapper', className, placeholder && `bubble-wrapper--placeholder-${placeholder}`)}
        ref={setRef}
      >
        {placeholder && (
          <div
            className={classnames(
              'chat-bubble chat-bubble__placeholder-content',
              `chat-bubble__placeholder-content--${placeholder}`,
            )}
          />
        )}

        {value && (
          <p
            className={classnames(
              'chat-bubble',
              className,
              !showMore && overLimit && 'show-less',
              isDark && 'chat-bubble--dark',
            )}
          >
            <Markdown markdown={value} wrapperClassName="bubble-wrapper__markdown" />
            {overLimit && !showMore && (
              <span className="chat-bubble-more" onClick={this.showMore}>
                Read more
              </span>
            )}
          </p>
        )}
      </div>
    );
  }
}
