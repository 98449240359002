import React, { PureComponent } from 'react';
import updeep from 'updeep';
import { mapValues, first, uniqBy, isEmpty, get } from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { isSkillSpeciality } from '@src/util/createStory';
import Button from '@src/components/ButtonOld';
import { LocationAutocomplete } from '@src/components/LocationAutocomplete';
import { idify } from '@src/util/idify';
import { Salary } from './Salary';
import { MultiSelect } from './MultiSelect';
import { Track } from './Track';
import './styles.scss';

export const DEFAULT_STEPS = {
  salary: 'linkedIn',
  seniorities: 'salary',
  specialities: 'seniorities',
  track: 'specialities',
};

const TITLES = {
  administration_field: 'Choose skills',
  company_industry_field: 'Choose the industries of your current company',
  company_revenue_field: "What's the revenue of your current company",
  company_size_field: "What's the size of your current company",
  construction_field: 'Choose skills',
  customer_support_field: 'Choose skills',
  data_science_field: 'Choose your skills',
  data_science_tool: 'Choose tools and languages',
  design_field: 'Choose skills',
  design_tool: 'Choose tools',
  engineering_field: 'Choose skills',
  finance_field: 'Choose skills',
  finance_institutions_field: 'Choose types of institutions you worked in',
  hospitality_restaurant_field: 'Choose skills',
  hr_field: 'Choose skills',
  it_admin_field: 'Choose skills',
  language: 'Choose languages you are fluent in',
  legal_field: 'Choose skills',
  management_department_field: 'Choose your department',
  management_level: 'Your managerial level',
  marketing_field: 'Choose skills',
  operations_field: 'Choose skills',
  product_field: 'Choose skills',
  programming_language: 'Choose tools and languages',
  real_estate_field: 'Choose skills',
  retail_department_field: 'Choose stores you worked in',
  retail_field: 'Choose skills',
  sales_avg_deal_value_field: 'Choose your average deal value',
  sales_experience_field: 'Choose areas of experience',
  sales_field: 'Choose skills',
  software_engineering_field: 'Choose skills',
  software_engineering_tool: 'Choose tools and languages',
  supply_chain_logistics_field: 'Choose skills',
  sysadmin_tool: 'Choose tools',
};

// By default completion is just the story field being defined, but for some field the criteria is
// more precise
const EDITING_COMPLETION_CHECKS = {
  salary: salary => !!(salary && salary.value),
};

function isEditingCompleted(story, editing) {
  const { skillTags } = story.track;

  if (skillTags.includes(editing)) {
    const key = skillTags.indexOf(editing) === 0 ? 'specialities' : 'skills';
    const item = story[key] || [];
    const storyEditing = story[editing] || [];

    if (isEmpty(item)) {
      return false;
    }

    return (
      !!storyEditing &&
      storyEditing.every(skill => {
        return item.some(s => (s || {}).tag === skill.tag);
      })
    );
  }

  if (story[editing] && isEmpty(story[editing])) {
    return false;
  }

  const value = story[editing];
  const checkFn = EDITING_COMPLETION_CHECKS[editing] || (v => !!v);

  return checkFn(value);
}

export default class EditStory extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    currencies: PropTypes.arrayOf(PropTypes.object).isRequired,
    editing: PropTypes.string,
    enableLocation: PropTypes.bool,
    onStoryChange: PropTypes.func.isRequired,
    story: PropTypes.object,
  };

  defaultState = () => {
    const editing =
      this.props.editing === 'specialities' && this.props.story && this.props.story.track
        ? first(this.props.story.track.skillTags)
        : this.props.editing;

    return {
      editing: editing,
      skillTags: this.props.story && this.props.story.track ? this.props.story.track.skillTags : undefined,
      skills: undefined,
      skillsTagIndex: 0,
      specialities: undefined,
      story: this.props.story,
      track: undefined,
    };
  };

  state = this.defaultState();

  componentDidUpdate(_, prevState) {
    const { story } = this.state;

    if (story.track !== prevState.story.track) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(({ story }) => ({
        story: {
          ...story,
          skills: undefined,
          specialities: undefined,
        },
      }));
    }
  }

  nextEditingStep = (editing, skillTags) => {
    const {
      story: { track },
    } = this.state;

    let steps = DEFAULT_STEPS;

    if (this.props.enableLocation) {
      // put location step between track and specialities
      steps = {
        ...DEFAULT_STEPS,
        location: 'specialities',
        track: 'location',
      };
    }

    if (skillTags && skillTags.length > 0) {
      // Create steps for each skill question
      const skillSteps = skillTags.reduce((acc, tag, index, tags) => {
        if (index === tags.length - 1) {
          if (tags.length > 1) {
            acc[tags[index - 1]] = tag;
          }

          acc[tag] = 'seniorities';
        } else {
          acc[tags[index - 1]] = tags[index];
        }

        return acc;
      }, {});
      const specialitiesKey = Object.keys(steps).find(key => steps[key] === 'specialities');
      steps = {
        ...steps,
        ...skillSteps,
        [specialitiesKey]: skillTags[0],
      };

      // Older availability requests already use the skill key.
      // We need to change it to the first skill tag in order to not break
      // profile changes for them.
      if (editing === 'skills') {
        editing = skillTags[0];
      }
    }

    if (track && track.key === 'top_level_management') {
      // skip seniority for top level management track
      steps = mapValues(steps, value => (value === 'seniorities' ? 'salary' : value));
    }

    return steps[editing];
  };

  handleItemSelect = item => {
    this.setState(({ editing, story, skillTags }) => {
      const tags = item && item.skillTags ? item.skillTags : skillTags;

      if (skillTags.includes(editing)) {
        if (skillTags.indexOf(editing) === 0) {
          return {
            skillTags: tags,
            story: { ...story, [editing]: item, specialities: item },
          };
        }

        const skills = (story.skills || []).concat(item);
        const uniqueSkills = uniqBy(skills, 'tag');

        return {
          skillTags: tags,
          story: { ...story, [editing]: item, skills: uniqueSkills },
        };
      }

      return {
        skillTags: tags,
        story: { ...story, [editing]: item },
      };
    });
  };

  updateItemInArray = (selectedItems = [], item) => {
    const isItemSelected = selectedItems.some(selectedItem => {
      return idify(selectedItem) === idify(item);
    });

    if (isItemSelected) {
      return selectedItems.filter(selectedItem => {
        return idify(selectedItem) !== idify(item);
      });
    }

    return [...selectedItems, item];
  };

  handleSkillSelect = item => {
    this.setState(state => {
      const { editing, story } = state;

      const isEditingSeniorities = editing === 'seniorities';

      if (!isEditingSeniorities) {
        const isSpeciality = isSkillSpeciality(item, story.track.skillTags[0]);

        if (isSpeciality) {
          return updeep(
            {
              story: {
                skills: [],
                specialities: this.updateItemInArray(story.specialities, item),
              },
            },
            state,
          );
        }

        return updeep.updateIn('story.skills', fieldData => this.updateItemInArray(fieldData, item), state);
      }

      return updeep.updateIn('story.seniorities', fieldData => this.updateItemInArray(fieldData, item), state);
    });
  };

  onNext = canSave => {
    const { story } = this.state;

    if (canSave) {
      this.props.onStoryChange(story, canSave);
    } else {
      this.setState(({ story, editing, skillsTagIndex }) => {
        const nextEdit = this.nextEditingStep(editing, story.track.skillTags);

        return {
          data: undefined,
          editing: nextEdit,
          skillsTagIndex: !Object.keys(DEFAULT_STEPS).includes(nextEdit)
            ? story.track.skillTags.indexOf(nextEdit)
            : skillsTagIndex,
        };
      });
    }
  };

  renderEditing = () => {
    const { editing, story, data, skillsTagIndex, skillTags } = this.state;
    const { currencies } = this.props;

    if (editing === 'salary') {
      return <Salary currencies={currencies} onSalaryChange={this.handleItemSelect} salary={story.salary} />;
    }

    if (editing === 'track') {
      return <Track defaultValue={story.track} onSelect={this.handleItemSelect} options={data} />;
    }

    if (editing === 'location') {
      return (
        <LocationAutocomplete
          defaultValue={get(story, 'location.description')}
          selectLocation={this.handleItemSelect}
          showClear
        />
      );
    }

    const isSeniorities = editing === 'seniorities';
    const isSpeciality = story?.track?.skillTags?.[0] === editing;

    return (
      <MultiSelect
        defaultValues={isSeniorities ? story.seniorities : isSpeciality ? story.specialities : story.skills}
        editing={editing}
        limit={isSeniorities ? 2 : undefined}
        onSelect={this.handleSkillSelect}
        skillTag={skillTags[skillsTagIndex]}
        specialityId={idify(story.track)}
      />
    );
  };

  render() {
    const { editing, story } = this.state;
    const { className } = this.props;

    const canSave = editing === 'salary';

    const title = TITLES[editing] ? TITLES[editing] : `Choose ${editing}`;

    return (
      <div className={classnames('availability-request-edit', className)}>
        {editing !== 'salary' ? <h3>{title}</h3> : null}

        <div className="availability-request-edit-content">{this.renderEditing()}</div>

        <Button disabled={!isEditingCompleted(story, editing)} onClick={() => this.onNext(canSave)} positive>
          {canSave ? 'Save' : 'Next'}
        </Button>
      </div>
    );
  }
}
