import React, { FC, forwardRef } from 'react';
import classnames from 'classnames';
import Back from '@src/components/Back';
import { FixedHeaderProps } from './props';
import './styles.scss';

export const FixedHeader: FC<FixedHeaderProps> = forwardRef(
  ({ children, title, onBack, wrapperClassName, close, backButtonSvg, disableOpacity }, ref) => {
    return (
      <div
        className={classnames('fixed-header', disableOpacity && 'fixed-header--opacity-none', wrapperClassName)}
        ref={ref}
      >
        <div className="fixed-header__top-section">
          <Back
            backButtonSvg={backButtonSvg}
            className="fixed-header__back"
            close={close}
            onBack={onBack}
            showWithAnimation={false}
          />
          <p className="fixed-header__title">{title}</p>
        </div>
        {children}
      </div>
    );
  },
);
