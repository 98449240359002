import React, { useState, useEffect, FC } from 'react';
import classnames from 'classnames';
import { isEmpty, trim } from 'lodash';
import Svg from 'svg-inline-react';
import FrCloseSvg from 'assets/images/fr-close-small.svg';
import FrSearchSvg from 'assets/images/fr-search.svg';
import { Suggestions } from './Suggestions';
import { SearchInputWithSuggestionsProps } from './props';
import './styles.scss';

export const SearchInputWithSuggestions: FC<SearchInputWithSuggestionsProps> = ({
  wrapperClassName,
  inputClassName,
  clearable,
  loading,
  suggestions,
  placeholder,
  value,
  withSearchIcon,
  isDisabled,
  onChange,
  onClear,
  onSuggestionSelect,
  suggestionsWrapperRef,
  isDarkMode,
  suggestionsClassName,
}) => {
  const [isSuggestionsOpen, setIsSuggestionsOpen] = useState(!isEmpty(suggestions) || loading);

  useEffect(() => {
    if (loading && !isSuggestionsOpen) {
      setIsSuggestionsOpen(true);
    }

    if (!loading && isEmpty(suggestions)) {
      setIsSuggestionsOpen(false);
    }
  }, [loading]);

  const handleSuggestionSelect = suggestion => {
    setIsSuggestionsOpen(false);
    onSuggestionSelect(suggestion);
  };

  const handleOnChange = value => {
    if (!trim(value).length) {
      setIsSuggestionsOpen(false);
    }

    onChange(value);
  };

  return (
    <div
      className={classnames(
        'search-input-with-suggestions',
        suggestions && suggestions.length > 0 && 'search-input-with-suggestions--with-results',
        wrapperClassName,
      )}
    >
      <div className="search-input-with-suggestions__input-wrapper">
        <input
          className={classnames(
            'search-input-with-suggestions__area',
            isSuggestionsOpen && 'search-input-with-suggestions__area--suggestions-open',
            clearable && 'search-input-with-suggestions__area--padding-right',
            inputClassName,
          )}
          disabled={isDisabled}
          onChange={e => handleOnChange(e.target.value)}
          placeholder={placeholder}
          type="text"
          value={value}
        />
        {clearable && !!value.length && (
          <Svg
            className={classnames(
              'search-input-with-suggestions__icon',
              isDarkMode && 'search-input-with-suggestions__icon--white',
            )}
            onClick={onClear}
            src={FrCloseSvg}
          />
        )}
        {!clearable && withSearchIcon && (
          <Svg
            className={classnames(
              'search-input-with-suggestions__icon',
              isDarkMode && 'search-input-with-suggestions__icon--white',
            )}
            onClick={onClear}
            src={FrSearchSvg}
          />
        )}
      </div>
      {isSuggestionsOpen && (
        <div
          className={classnames(
            'search-input-with-suggestions__container',
            isDarkMode && 'search-input-with-suggestions__container--dark',
          )}
        >
          <Suggestions
            className={suggestionsClassName}
            isDarkMode={isDarkMode}
            loading={loading}
            onSuggestionSelect={handleSuggestionSelect}
            ref={suggestionsWrapperRef}
            suggestions={suggestions || []}
          />
        </div>
      )}
    </div>
  );
};
