import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { INDEX_ROUTE } from '@src/constants/routes';
import Button from '@src/components/ButtonOld';
import { removeUserFromLocalStorage } from '@src/localStorage/user';
import './styles.scss';

export const DeletedUser: FC = () => {
  const history = useHistory();

  const createNewUser = async () => {
    removeUserFromLocalStorage();

    history.push(INDEX_ROUTE);

    // To reset redux/apollo store and initialize new user
    location.reload();
  };

  return (
    <div className="deleted-user slide-up-item">
      <span className="deleted-user-msg">This account has been deleted</span>

      <Button onClick={createNewUser} positive>
        Create a new one
      </Button>
    </div>
  );
};
