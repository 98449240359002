import React, { Fragment } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import FadeInImage from '@src/components/FadeInImage';
import { idify } from '@src/util/idify';
import './styles.scss';

export default function ImageMosaique({ wrapperClassName, images, borderRadius }) {
  return (
    <div
      className={classnames(
        'fr-image-mosaique',
        images.length > 1 && 'fr-image-mosaique--multiple-images',
        wrapperClassName,
      )}
    >
      {images.length === 1 ? (
        <FadeInImage
          className="fr-image-mosaique__image fr-image-mosaique__image--big"
          src={images[0].fullUrl}
          style={{ borderRadius }}
        />
      ) : images.length === 2 ? (
        images.map((image, i) => (
          <FadeInImage
            className="fr-image-mosaique__image fr-image-mosaique__image--medium"
            key={idify(image)}
            src={image.fullUrl}
            style={
              i === 0
                ? {
                    borderBottomLeftRadius: borderRadius,
                    borderTopLeftRadius: borderRadius,
                  }
                : {
                    borderBottomRightRadius: borderRadius,
                    borderTopRightRadius: borderRadius,
                  }
            }
          />
        ))
      ) : (
        <Fragment>
          <FadeInImage
            className="fr-image-mosaique__image fr-image-mosaique__image--small--single"
            src={images[0].fullUrl}
            style={{
              borderBottomLeftRadius: borderRadius,
              borderTopLeftRadius: borderRadius,
            }}
          />
          <div className="fr-image-mosaique__side">
            {images.slice(1, 3).map((image, i) => (
              <FadeInImage
                className="fr-image-mosaique__image fr-image-mosaique__image--small"
                key={idify(image)}
                src={image.fullUrl}
                style={
                  i === 0
                    ? {
                        borderTopRightRadius: borderRadius,
                      }
                    : {
                        borderBottomRightRadius: borderRadius,
                      }
                }
              />
            ))}
          </div>
        </Fragment>
      )}
    </div>
  );
}

ImageMosaique.propTypes = {
  borderRadius: PropTypes.string,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      fullUrl: PropTypes.string.isRequired,
      id: PropTypes.string,
    }),
  ).isRequired,
  wrapperClassName: PropTypes.string,
};
