import React, { FC, useState } from 'react';
import Button from '@src/components/ButtonOld';
import Input from '@src/components/Input';
import { EditUserProps } from './props';

const TITLES = {
  name: 'First name',
  position: 'Current job title',
};

const SUBTITLES = {
  name: 'Set your first name to have a more personalised experience when using MeetFrank.',
  position: 'Help companies get a quick idea about you by naming your current job title below.',
};

const PLACEHOLDERS = {
  name: 'Your first name...',
  position: 'Position title...',
};

export const EditUser: FC<EditUserProps> = ({ editing, onChange, user }) => {
  const [value, setValue] = useState(user[editing]);

  const title = TITLES[editing] ? TITLES[editing] : `Choose ${editing}`;

  const subtitle = SUBTITLES[editing];

  return (
    <div className="availability-request-edit">
      <h3>{title}</h3>
      <h2>{subtitle}</h2>

      <div className="availability-request-edit-content no-shrink">
        <Input
          className="text-input"
          defaultValue={value}
          onChange={value => setValue(value)}
          placeholder={PLACEHOLDERS[editing]}
        />
      </div>

      <Button disabled={!value.trim()} onClick={() => onChange(editing, value)} positive>
        Confirm
      </Button>
    </div>
  );
};
