import React, { FC, useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import FrSearchSvg from 'assets/images/fr-search.svg';
import { useQueryParams } from '@src/customHooks/useQueryParams';
import { BasePageHeader } from '@src/containers/PageHeader';
import { BaseAppBar } from '@src/components/PrimaryAppBar';
import { trackEvent } from '@src/metrics';
import { Context } from '@src/metrics/enums/context.enum';
import { Interaction } from '@src/metrics/enums/interaction.enum';
import { ItemType } from '@src/metrics/enums/itemType.enum';
import { QueryParams } from '../interfaces/queryParams.interface';
import { Feed } from '../enums/feed.enum';
import { DEFAULT_CATEGORY, DEFAULT_FEED } from '../constants/categories';
import { FilterInput } from './FilterInput';
import { Categories } from './Categories';
import { PlaceSearch } from './PlaceSearch';
import { PageHeaderProps } from './props';
import './styles.scss';

export const PageHeader: FC<PageHeaderProps> = ({
  setIsPlaceSearchActive,
  isPlaceSearchActive,
  isCategoriesHidden,
  categories,
  isCategoriesLoading,
}) => {
  const { queryParams, updateQueryParams, removeQueryParams } = useQueryParams<QueryParams>();

  const [isSearchActivated, setIsSearchActivated] = useState<boolean>(true);

  const [value, setValue] = useState<string>(queryParams.searchPattern || '');

  const [placesValue, setPlacesValue] = useState<string>(queryParams.placeSearchPattern || '');

  const isToggleHeaderDense = isSearchActivated && !isPlaceSearchActive;

  const activeFeed = queryParams.activeFeed || DEFAULT_FEED;

  const placeTags = queryParams.places?.map(place => {
    return {
      id: place.id,
      label: place.name,
    };
  });

  const handleSearch = useCallback(
    debounce(searchPattern => {
      updateQueryParams({
        activeFeed: activeFeed === Feed.FOR_YOU ? Feed.CATEGORIES : activeFeed,
        category: activeFeed === Feed.FOR_YOU ? DEFAULT_CATEGORY : queryParams.category,
        searchPattern: searchPattern,
      });
    }, 350),
    [queryParams],
  );

  const onChange = (value: string) => {
    setValue(value);

    handleSearch(value);
  };

  const handlePlacesSearch = useCallback(
    debounce(placeSearchPattern => {
      updateQueryParams({ placeSearchPattern });
    }, 350),
    [queryParams],
  );

  const onPlacesChange = (value: string) => {
    setPlacesValue(value);

    handlePlacesSearch(value);
  };

  useEffect(() => {
    if (!!queryParams.searchPattern && queryParams.searchPattern !== value) {
      setValue(queryParams.searchPattern || '');
    }
  }, [queryParams.searchPattern]);

  useEffect(() => {
    if (queryParams.searchPattern !== value) {
      setPlacesValue(queryParams.placeSearchPattern || '');
    }
  }, [queryParams.placeSearchPattern]);

  return (
    <BasePageHeader
      onScroll={() => setIsSearchActivated(true)}
      toggleAppBarEl={
        <div className="jobs-search-page-header__toggle">
          <div className="jobs-search-page-header">
            <FilterInput
              startIcon={FrSearchSvg}
              placeholder="Search by keyword"
              value={value}
              onChange={onChange}
              onClear={() => onChange('')}
              onClick={() => setIsSearchActivated(false)}
            />

            {!isToggleHeaderDense && (
              <div className="jobs-search-page-header__location">
                <PlaceSearch
                  isPlaceSearchActive={isPlaceSearchActive}
                  placesValue={placesValue}
                  onPlacesChange={onPlacesChange}
                  setIsPlaceSearchActive={setIsPlaceSearchActive}
                  onClear={() => {
                    removeQueryParams(['isPlaceSearchActive', 'placeSearchPattern']);
                  }}
                  onCancel={() => {
                    removeQueryParams(['isPlaceSearchActive']);
                  }}
                  placeTags={placeTags || []}
                />
              </div>
            )}
          </div>

          {!isCategoriesHidden ? (
            <div className="jobs-search-page-header__categories">
              <Categories
                isLoading={isCategoriesLoading}
                onForYouClick={() => {
                  trackEvent({
                    context: Context.DIGEST,
                    interaction: Interaction.SELECT_CATEGORY,
                    itemType: ItemType.CATEGORY,
                    itemValue: Feed.FOR_YOU,
                  });

                  updateQueryParams({ activeFeed: Feed.FOR_YOU });
                }}
                isForYouActive={activeFeed === Feed.FOR_YOU}
                areSvgIconsShown={!isToggleHeaderDense}
                categories={categories}
                activeCategory={queryParams.category}
                onCategoryClick={category => {
                  trackEvent({
                    context: Context.DIGEST,
                    interaction: Interaction.SELECT_CATEGORY,
                    itemType: ItemType.CATEGORY,
                    itemValue: category.type,
                  });

                  updateQueryParams({
                    activeFeed: Feed.CATEGORIES,
                    category: category.type,
                  });
                }}
              />
            </div>
          ) : (
            <div className="jobs-search-page-header__bottom-divider"></div>
          )}
        </div>
      }
    >
      <>
        <BaseAppBar />

        <div className="jobs-search-page-header">
          <FilterInput
            startIcon={FrSearchSvg}
            placeholder="Search by keyword"
            value={value}
            onChange={onChange}
            onClear={() => onChange('')}
          />

          <div className="jobs-search-page-header__location">
            <PlaceSearch
              isPlaceSearchActive={isPlaceSearchActive}
              placesValue={placesValue}
              onPlacesChange={onPlacesChange}
              setIsPlaceSearchActive={setIsPlaceSearchActive}
              placeTags={placeTags || []}
              onClear={() => {
                removeQueryParams(['isPlaceSearchActive', 'placeSearchPattern']);
              }}
              onCancel={() => {
                removeQueryParams(['isPlaceSearchActive']);
              }}
            />
          </div>
        </div>

        {!isCategoriesHidden && (
          <div className="jobs-search-page-header__categories">
            <Categories
              isLoading={isCategoriesLoading}
              categories={categories}
              activeCategory={queryParams.category}
              onForYouClick={() => updateQueryParams({ activeFeed: Feed.FOR_YOU })}
              isForYouActive={activeFeed === Feed.FOR_YOU}
              onCategoryClick={category => {
                trackEvent({
                  context: Context.DIGEST,
                  interaction: Interaction.SELECT_CATEGORY,
                  itemType: ItemType.CATEGORY,
                  itemValue: category.type,
                });

                updateQueryParams({
                  activeFeed: Feed.CATEGORIES,
                  category: category.type,
                });
              }}
            />
          </div>
        )}
      </>
    </BasePageHeader>
  );
};
