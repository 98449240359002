import React, { FC, useEffect, useState } from 'react';
import { get } from 'lodash';
import classNames from 'classnames';
import { BasePageHeader } from '@src/containers/PageHeader/BasePageHeader';
import { BaseAppBar } from '@src/components/PrimaryAppBar';
import { PlaceScroller } from '@src/containers/PlaceScroller';
import { AppBarSearchInputWithTitle } from '@src/components/AppBarSearchInputWithTitle';
import { PageHeaderWithLocationProps } from './props';
import './styles.scss';

export const PageHeaderWithLocation: FC<PageHeaderWithLocationProps> = ({
  activePlaceId,
  addPlace,
  onPlaceClick,
  places,
  providedTargetEl,
  getScrollableContainer,
  searchPattern,
  onSearch,
  title,
}) => {
  const [isAdditionalElementShown, setIsAdditionalElementShown] = useState(false);

  useEffect(() => {
    const element = getScrollableContainer && getScrollableContainer();

    const handleScroll: EventListenerOrEventListenerObject = event => {
      const breakPoint = get(providedTargetEl?.current, 'offsetTop', 0);

      const scrollTop = (event?.target as HTMLDivElement)?.scrollTop || 0;

      if (scrollTop <= breakPoint) {
        setIsAdditionalElementShown(false);
      }

      if (scrollTop > breakPoint) {
        setIsAdditionalElementShown(true);
      }
    };

    if (element) {
      element.addEventListener('scroll', handleScroll, false);

      return () => {
        element.removeEventListener('scroll', handleScroll, false);
      };
    }
  }, []);

  return (
    <BasePageHeader
      toggleAppBarEl={
        <div className="page-header-with-location__toggle-app-bar">
          <BaseAppBar isDefaultActionsHidden wrapperClassName="page-header-with-location__base-app-bar">
            <AppBarSearchInputWithTitle
              title={title}
              defaultSearchPattern={searchPattern}
              onSearch={searchPattern => {
                onSearch?.(searchPattern);
              }}
            />
          </BaseAppBar>

          <div
            className={classNames(
              'page-header-with-location__app-bar-tabs',
              isAdditionalElementShown && 'page-header-with-location__app-bar-tabs--show',
            )}
          >
            <PlaceScroller
              activePlaceId={activePlaceId}
              addPlace={addPlace}
              onPlaceClick={onPlaceClick}
              places={places}
              nameField="longName"
              canAddPlace
            />
          </div>
        </div>
      }
    >
      <>
        <div className="page-header-with-location__wrapper">
          <BaseAppBar isDefaultActionsHidden wrapperClassName="page-header-with-location__base-app-bar">
            <AppBarSearchInputWithTitle
              defaultSearchPattern={searchPattern}
              title={title}
              onSearch={searchPattern => {
                onSearch?.(searchPattern);
              }}
            />
          </BaseAppBar>
        </div>

        <div className="page-header-with-location__filters">
          <div className="page-header-with-location__filters-section-title-wrapper">
            <div className="page-header-with-location__filters-section-title">LOCATION</div>
          </div>

          <PlaceScroller
            activePlaceId={activePlaceId}
            addPlace={addPlace}
            containerClassName="page-header-with-location__filters-place-container"
            onPlaceClick={onPlaceClick}
            places={places}
            nameField="longName"
            canAddPlace
          />
        </div>
      </>
    </BasePageHeader>
  );
};
