// for those IOS versions transform: translate was bugged and animated very poorly
const TRANSLATE_BLACKLIST = ['OS 11_2', 'OS 11_2_1', 'OS 11_2_2', 'OS 11_2_3', 'OS 11_2_4'];

export function isBrokenIos() {
  const userAgent = window.navigator.userAgent;
  return TRANSLATE_BLACKLIST.some(item => userAgent.indexOf(item) > -1);
}

export default function (bottomPosition) {
  const useMargin = isBrokenIos();

  if (useMargin) {
    return { marginTop: bottomPosition || undefined };
  }

  return {
    transform: bottomPosition ? `translate(0, ${bottomPosition})` : undefined,
  };
}
