import { DEVICE_UUID_SET } from '../actions/deviceUUID';

export function deviceUUID (state = null, action) {
  switch (action.type) {
    case DEVICE_UUID_SET:
      return action.deviceUUID;
    default:
      return state;
  }
}
