import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import scroll from '@src/util/scroll';
import './styles.scss';

const indexOf = Function.call.bind(Array.prototype.indexOf);
const ELEMENT_HEIGHT = 50;

export default class Scroller extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    defaultValue: PropTypes.object,
    identifier: PropTypes.string.isRequired,
    onValueSelect: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedValue: props.defaultValue || props.options[0],
    };
  }

  componentDidMount() {
    this.setValue(this.props.options[0]);

    if (this.selectedRef) {
      this.scrollToElem(this.selectedRef);
    }
  }

  setValue = value => {
    const { identifier, onValueSelect } = this.props;
    onValueSelect({ [identifier]: value });
  };

  onScroll = el => {
    const { options } = this.props;
    const { value } = this.state;

    const elementHeight = this.elementHeight();

    let i = Math.floor((elementHeight / 2 + el.target.scrollTop) / elementHeight);
    i = Math.max(0, Math.min(i, options.length - 1));

    const _value = options[i];

    if (value !== _value) {
      this.setState({ selectedValue: _value });
      this.setValue(_value);
    }
  };

  elementHeight = () => {
    if (this.scrollableRef && this.scrollableRef.firstChild) {
      return this.scrollableRef.firstChild.clientHeight;
    }

    return ELEMENT_HEIGHT;
  };

  setRef = el => {
    this.scrollableRef = el;
  };

  setSelectedRef = el => {
    this.selectedRef = el;
  };

  onClick = evt => {
    const el = evt.target;

    if (el.tagName === 'OL') {
      return;
    }

    this.scrollToElem(el);
  };

  scrollToElem = el => {
    const parent = el.parentNode;

    scroll(parent, 0, indexOf(parent.children, el) * this.elementHeight());
  };

  render() {
    const { options, className } = this.props;
    const { selectedValue } = this.state;

    return (
      <div className={classnames('scroller', className)}>
        <ol onScroll={this.onScroll} ref={this.setRef}>
          {options.map(option => {
            return (
              <li
                className={classnames(option === selectedValue && 'selected')}
                key={option.key}
                onClick={this.onClick}
                ref={option === selectedValue && this.setSelectedRef}
              >
                {option.value}
              </li>
            );
          })}
        </ol>
      </div>
    );
  }
}
