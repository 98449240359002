export const SET_TOAST_MESSAGE_CONTENT = `${__dirname}/setToastMessageContent`;
export function setToastMessageContent(data) {
  return {
    message: data.message,
    onMessageClick: data.onMessageClick,
    type: SET_TOAST_MESSAGE_CONTENT,
  };
}

export const TOAST_MESSAGE_CLOSED = `${__dirname}/toastMessageClosed`;
export function closeToastMessage() {
  return { type: TOAST_MESSAGE_CLOSED };
}
