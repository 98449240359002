import React, { FC, useEffect, useState } from 'react';
import { get, trim, omit } from 'lodash';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { setNavigationVisibility } from '@src/redux/actions/navigation';
import ContentPush from '@src/components/ContentPush';
import createLink from '@src/util/createLink';
import { COMPANY_REVIEWS_ROUTE, CREATE_COMPANY_REVIEW_ROUTE } from '@src/constants/routes';
import {
  CreateCompanyReviewRatingsInput,
  useCompanyReviewsLazyQuery,
  useCreateCompanyReviewMutation,
  useExtendCompanyReviewMutation,
  useRemoveReviewMutation,
  useUpdateCompanyReviewMutation,
} from '@src/graphql/generated';
import { useAppDispatch } from '@src/store';
import { useQueryParams } from '@src/customHooks/useQueryParams';
import { Context } from '@src/metrics/enums/context.enum';
import { Interaction } from '@src/metrics/enums/interaction.enum';
import { ItemType } from '@src/metrics/enums/itemType.enum';
import { useFlashMessagesContext } from '@src/context/FlashMessagesContext';
import { captureError } from '@src/util/errors';
import { trackEvent } from '@src/metrics';
import { REVIEW } from './steps';
import { Ratings } from './Ratings';
import { QueryParams } from './interfaces/queryParams.interface';
import { InputState } from './interfaces/inputState.interface';
import { isCompanyRatingReviewType } from './typeGuards';
import './styles.scss';

const INITIAL_RATINGS = {
  companyCulture: 0,
  compensationBenefits: 0,
  management: 0,
  product: 0,
  total: 0,
  workLifeBalance: 0,
};

export const CreateCompanyReview: FC = () => {
  const dispatch = useAppDispatch();

  const { setErrorFlashMessage } = useFlashMessagesContext();

  const history = useHistory();

  const match = useRouteMatch<{ companyId?: string }>({
    path: CREATE_COMPANY_REVIEW_ROUTE,
    sensitive: true,
    strict: true,
  });

  const { queryParams } = useQueryParams<QueryParams>();

  const { userAssociation, overallRating } = queryParams;

  const companyId = match?.params.companyId;

  const [isRatingView, setIsRatingView] = useState(true);

  const [ratings, setRatings] = useState<CreateCompanyReviewRatingsInput>();

  const [step, setStep] = useState(0);

  const [isMainLoading, setIsMainLoading] = useState(true);

  const [input, setInput] = useState<InputState>();

  const isLastStep = step === REVIEW.length - 1;

  const activeKey = REVIEW[step].key;

  const [fetchCompanyReviews, { data: companyReviewsData, loading: isCompanyReviewsLoading }] =
    useCompanyReviewsLazyQuery();

  const [crateCompanyReview, { data: reviewData, loading: isCreateReviewLoading }] = useCreateCompanyReviewMutation({
    onError: () => {
      setErrorFlashMessage('Failed to create a review');
    },
  });

  const [updateCompanyReview, { loading: isUpdateReviewLoading }] = useUpdateCompanyReviewMutation({
    onError: () => {
      setErrorFlashMessage('Failed to update a review');
    },
  });

  const [extendCompanyReview] = useExtendCompanyReviewMutation({
    onCompleted: () => {
      setTimeout(() => {
        if (companyId) {
          history.replace(
            createLink({
              link: COMPANY_REVIEWS_ROUTE,
              params: { companyId },
            }),
          );
        }
      }, 1000);
    },
    onError: () => {
      setErrorFlashMessage('Failed to extend a review');
    },
  });

  const reviewsOverviewReviewForCurrentUser = companyReviewsData?.company.reviewsOverview.reviewForCurrentUser;
  const reviewForCurrentUser =
    reviewsOverviewReviewForCurrentUser && isCompanyRatingReviewType(reviewsOverviewReviewForCurrentUser)
      ? reviewsOverviewReviewForCurrentUser
      : undefined;
  const existingReviewId = reviewForCurrentUser?.id;
  const newlyCreatedReviewId = reviewData?.createCompanyReview.id;

  const [removeReview, { loading: isRemoveReviewLoading }] = useRemoveReviewMutation({
    onCompleted: async () => {
      if (!companyId || !userAssociation || !ratings) {
        return;
      }

      try {
        const { data: companyReviewData } = await crateCompanyReview({
          variables: {
            reviewInput: {
              companyId: companyId,
              ratings: ratings,
              userAssociation: userAssociation,
            },
          },
        });

        if (!companyReviewData) {
          return;
        }

        await extendCompanyReview({
          variables: {
            reviewId: companyReviewData?.createCompanyReview.id,
            reviewInput: {
              isAnonymous: !input?.isAnonymous,
              negativeFeedback: trim(input?.negativeFeedback),
              positiveFeedback: trim(input?.positiveFeedback),
              title: trim(input?.title),
            },
          },
        });
      } catch (error) {
        captureError(`Failed to extend a review: ${error}`);
      }
    },
    onError: () => {
      setErrorFlashMessage('Failed to remove a review');
    },
  });

  const companyLogo = companyReviewsData?.company.profile.logo?.fullUrl;
  const companyName = companyReviewsData?.company.profile.name;
  const companyLocation = companyReviewsData?.company.location?.shortName;

  const selectedValue = get(input, `${activeKey}`) || '';

  useEffect(() => {
    if (!isCompanyReviewsLoading && !companyReviewsData) {
      if (!reviewForCurrentUser?.ratings) {
        setRatings(omit(reviewForCurrentUser?.ratings, '__typename'));

        setInput({
          isAnonymous: !reviewForCurrentUser?.isAnonymous,
          negativeFeedback: reviewForCurrentUser?.negativeFeedback || '',
          positiveFeedback: reviewForCurrentUser?.positiveFeedback || '',
          title: reviewForCurrentUser?.title || '',
        });
      } else {
        setRatings({
          ...INITIAL_RATINGS,
          total: (overallRating && parseFloat(overallRating)) || 0,
        });
      }
    }
  }, [isCompanyReviewsLoading, companyReviewsData]);

  useEffect(() => {
    dispatch(setNavigationVisibility(false));

    trackEvent({
      context: Context.CREATE_COMPANY_REVIEW,
      interaction: Interaction.VIEW,
    });

    return () => {
      dispatch(setNavigationVisibility(true));
    };
  }, []);

  const handleCreateUpdateReview = () => {
    if (newlyCreatedReviewId || existingReviewId) {
      updateCompanyReview({
        variables: {
          reviewId: newlyCreatedReviewId || (existingReviewId as string),
          reviewInput: {
            ratings,
          },
        },
      });
    } else if (companyId && userAssociation && ratings) {
      crateCompanyReview({
        variables: {
          reviewInput: {
            companyId,
            ratings,
            userAssociation,
          },
        },
      });
    }

    setIsRatingView(false);

    setIsMainLoading(false);
  };

  const moveForward = () => {
    if (!isLastStep && !isRatingView) {
      setStep(step + 1);
    }

    setTimeout(() => {
      setIsMainLoading(false);
    }, 500);
  };

  const handleContinue = async () => {
    setIsMainLoading(true);

    if (isLastStep) {
      trackEvent({
        context: Context.CREATE_COMPANY_REVIEW,
        interaction: Interaction.CLICK_POST,
      });

      if (existingReviewId) {
        removeReview({
          variables: {
            reviewId: existingReviewId,
          },
        });
      } else {
        if (input && newlyCreatedReviewId) {
          extendCompanyReview({
            variables: {
              reviewId: newlyCreatedReviewId,
              reviewInput: {
                isAnonymous: !input.isAnonymous,
                negativeFeedback: trim(input.negativeFeedback),
                positiveFeedback: trim(input.positiveFeedback),
                title: trim(input.title),
              },
            },
          });
        } else if (companyId) {
          history.replace(
            createLink({
              link: COMPANY_REVIEWS_ROUTE,
              params: { companyId },
            }),
          );
        }
      }
    } else {
      moveForward();
    }

    trackEvent({
      context: Context.CREATE_COMPANY_REVIEW,
      interaction: Interaction.APPLICATION,
      itemType: ItemType.STEP,
      itemValue: activeKey,
    });
  };

  const handleBack = () => {
    setIsMainLoading(true);
    !step ? setIsRatingView(true) : setStep(step - 1);
    setTimeout(() => {
      setIsMainLoading(false);
    }, 500);
  };

  const handleInputChange = (key: keyof InputState, value: string | boolean) => {
    setInput({
      ...input,
      [key]: value,
    });
  };

  useEffect(() => {
    if (companyId) {
      fetchCompanyReviews({
        variables: {
          id: companyId,
        },
      });
    }
  }, [companyId]);

  if (isCompanyReviewsLoading || !ratings) {
    return null;
  }

  const isContentPushLoading = isMainLoading || isCreateReviewLoading || isUpdateReviewLoading || isRemoveReviewLoading;

  return (
    <div className="create-review content-enter-animation">
      {isRatingView ? (
        <Ratings
          companyLocation={companyLocation}
          companyLogo={companyLogo}
          companyName={companyName}
          handleCreateReview={handleCreateUpdateReview}
          ratings={ratings}
          setRating={(rating, key) => setRatings({ ...ratings, [key]: rating })}
        />
      ) : (
        <ContentPush
          defaultValues={input}
          keepScrollTop
          loading={isContentPushLoading}
          onClose={() => history.goBack()}
          onContinue={handleContinue}
          onInputChange={handleInputChange}
          question={REVIEW[step]}
          selectedValue={{ [activeKey]: selectedValue }}
          sendBack={handleBack}
          showHeader
        />
      )}
    </div>
  );
};
