import { LOCATION_CHANGE } from 'connected-react-router';
import { SET_TOAST_MESSAGE_CONTENT, TOAST_MESSAGE_CLOSED } from '@src/redux/actions/toastMessage';

const DEFAULT_STATE = {
  message: undefined,
  onMessageClick: undefined,
};

export function toastMessage(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
    case TOAST_MESSAGE_CLOSED:
      return DEFAULT_STATE;
    case SET_TOAST_MESSAGE_CONTENT:
      return {
        ...state,
        message: action.message,
        onMessageClick: action.onMessageClick,
      };
    default:
      return state;
  }
}
