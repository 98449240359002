import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Svg from 'svg-inline-react';
import CloseSvg from 'assets/images/fr-close-small.svg';
import './styles.scss';

export default class ChatInput extends Component {
  static propTypes = {
    className: PropTypes.string,
    clearable: PropTypes.bool,
    defaultValue: PropTypes.string,
    inputClassName: PropTypes.string,
    onChange: PropTypes.func,
    onClear: PropTypes.func,
    onFocus: PropTypes.func,
    placeholder: PropTypes.string,
    type: PropTypes.string,
  };

  static defaultProps = {
    type: 'text',
  };

  state = {
    defaultSet: false,
    value: '',
  };

  componentDidMount() {
    const { defaultValue } = this.props;
    this.setDefault(defaultValue);
  }

  componentWillReceiveProps(newProps) {
    const { defaultValue } = newProps;
    this.setDefault(defaultValue);
  }

  setDefault = defaultValue => {
    const { value, defaultSet } = this.state;

    if (!value && !defaultSet && defaultValue) {
      this.setState({ defaultSet: true, value: defaultValue });
    }
  };

  onChange = value => {
    const { onChange } = this.props;

    this.setState({ value });
    onChange(value);
  };

  handleInputClear = () => {
    const { onClear } = this.props;
    this.setState({ value: '' });
    onClear && onClear();
  };

  render() {
    const { type, className, inputClassName, clearable, placeholder, onFocus } = this.props;
    const { value } = this.state;

    return (
      <div className={classnames('frank-chat-input', className)}>
        <input
          className={classnames(
            'frank-chat-input__text-field',
            clearable && 'frank-chat-input__text-field--clearable',
            inputClassName,
          )}
          onChange={evt => this.onChange(evt.target.value)}
          onFocus={onFocus}
          placeholder={placeholder}
          type={type}
          value={value}
        />
        {clearable && value && (
          <Svg className="frank-chat-input__clear-icon" onClick={this.handleInputClear} src={CloseSvg} />
        )}
      </div>
    );
  }
}
