import moment from 'moment';
import { FetchResult } from '@apollo/client';
import { apolloClient } from '@src/graphql/apollo';
import {
  GetUserQuery,
  UpdateUserDocument,
  UpdateUserMutation,
  UpdateUserMutationVariables,
} from '@src/graphql/generated';

export const setUserTimezone = (
  user: GetUserQuery['currentUser'],
): Promise<FetchResult<UpdateUserMutation>> | undefined => {
  const timezone = moment.tz.guess();

  if (user.meta?.timezone === timezone) {
    return;
  }

  return apolloClient.mutate<UpdateUserMutation, UpdateUserMutationVariables>({
    mutation: UpdateUserDocument,
    variables: {
      updateInput: {
        meta: {
          timezone,
        },
      },
    },
  });
};
