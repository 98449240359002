import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useScrollDirection } from '@src/customHooks/useScrollDirection';
import './styles.scss';

const PLACEMENT_TYPES = {
  BOTTOM: 'bottom',
  TOP: 'top',
};

export const ScrollToggleContainer = ({
  isShownByDefault,
  wrapperClassName,
  getBreakPoint,
  placement,
  children,
  getScrollableContainerNode,
  onBreakPointReached,
}) => {
  const { scrollDirection } = useScrollDirection({
    getBreakPoint,
    getScrollableContainerNode,
    isShownByDefault,
    onBreakPointReached,
  });

  return (
    <div
      className={classnames(
        'scroll-toggle-container',
        `scroll-toggle-container--${placement}`,
        `scroll-toggle-container--${placement}-${scrollDirection}`,
        wrapperClassName,
      )}
    >
      {children}
    </div>
  );
};

ScrollToggleContainer.defaultProps = {
  getBreakPoint: () => 0,
  placement: PLACEMENT_TYPES.TOP,
};

ScrollToggleContainer.propTypes = {
  children: PropTypes.node.isRequired,
  getBreakPoint: PropTypes.func,
  getScrollableContainerNode: PropTypes.func.isRequired,
  isShownByDefault: PropTypes.bool,
  onBreakPointReached: PropTypes.func,
  placement: PropTypes.oneOf(Object.values(PLACEMENT_TYPES)),
  wrapperClassName: PropTypes.string,
};
