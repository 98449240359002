import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { getToastMessageState } from '@src/redux/selectors/toastMessage';
import { closeToastMessage } from '@src/redux/actions/toastMessage';
import { RootState } from '@src/store/interfaces/store.interface';
import './styles.scss';

function ToastMessage({ data, closeToastMessage }) {
  const { message, onMessageClick } = data;

  return (
    <div
      className={classnames('toast-message', !!message && 'toast-message--active')}
      onClick={() => {
        onMessageClick && onMessageClick();
        closeToastMessage();
      }}
    >
      {message}
    </div>
  );
}

ToastMessage.propTypes = {
  closeToastMessage: PropTypes.func.isRequired,
  data: PropTypes.shape({
    message: PropTypes.string,
    onMessageClick: PropTypes.func,
  }).isRequired,
};

export default connect(
  (state: RootState) => ({
    data: getToastMessageState(state),
  }),
  { closeToastMessage },
)(ToastMessage);
