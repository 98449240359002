import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, first } from 'lodash';
import PhotoSwipeGallery from '@src/components/PhotoSwipeGallery';
import { HorizontalScrollerAbout } from '../HorizontalScrollerAbout';
import { GalleryImage, IMAGE_TYPES } from './GalleryImage';
import './styles.scss';

export const Images = ({ images }) => {
  if (isEmpty(images)) {
    return null;
  }

  return (
    <PhotoSwipeGallery
      items={images.map(({ src, thumbnail }) => ({
        h: 225,
        src,
        thumbnail,
        w: 335,
      }))}
      preload={[1, 1]}
      renderThumbnails={(images, showPhotoSwipe, setThumbnailRef) => (
        <div className="company-profile-images__container">
          <GalleryImage
            index={0}
            setThumbnailRef={setThumbnailRef}
            showPhotoSwipe={showPhotoSwipe}
            src={first(images).src}
            type={IMAGE_TYPES.LARGE}
          />

          <HorizontalScrollerAbout className="company-profile-images__list">
            {images.slice(1).map(({ thumbnail }, i) => (
              <div className="company-profile-images__image-wrapper" key={i}>
                <GalleryImage
                  index={i + 1}
                  setThumbnailRef={setThumbnailRef}
                  showPhotoSwipe={showPhotoSwipe}
                  src={thumbnail}
                  type={IMAGE_TYPES.SMALL}
                />
              </div>
            ))}
          </HorizontalScrollerAbout>
        </div>
      )}
    />
  );
};

Images.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object),
};
