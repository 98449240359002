import classnames from 'classnames';
import React, { FC } from 'react';
import { Props } from './props';
import './styles.scss';

export const Section: FC<Props> = ({ iconSlot, topSlot, bottomSlot, wrapperClassName }) => {
  return (
    <div className={classnames('vt-card-section', wrapperClassName)}>
      {iconSlot}

      <div className="vt-card-section__details">
        {topSlot}

        {bottomSlot}
      </div>
    </div>
  );
};
