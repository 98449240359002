import React, { FC } from 'react';
import classnames from 'classnames';
import Svg from 'svg-inline-react';
import StarSvg from 'assets/images/fr-star.svg';
import { ScoreProps } from './props';
import './styles.scss';

export const Score: FC<ScoreProps> = ({
  score,
  ratings,
  onClick,
  wrapperClassName,
  iconClassName,
  countClassName,
  size = 'large',
  numberClassName,
}) => {
  const isBelowAverage = score && score < 2.5;

  return (
    <div className={classnames('score', `score--${size}`, wrapperClassName)} onClick={onClick}>
      <Svg
        className={classnames(
          'score__icon',
          isBelowAverage && 'score__icon--negative',
          !score && 'score__icon--no-score',
          iconClassName,
        )}
        src={StarSvg}
      />

      <span className={classnames('score__number', !score && 'score__number--empty-message', numberClassName)}>
        {score ? score.toFixed(1) : 'Not enough ratings'}
      </span>

      {!!ratings && <span className={classnames('score__ratings', countClassName)}>({ratings})</span>}
    </div>
  );
};
