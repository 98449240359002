import React, { FC, Fragment, ReactElement, useEffect, useState } from 'react';
import { map, toString } from 'lodash';
import Svg from 'svg-inline-react';
import FrSettingsSvg from 'assets/images/fr-settings.svg';
import { Link, useHistory } from 'react-router-dom';
import api from '@src/util/api';
import { apolloClient } from '@src/graphql/apollo';
import { trackEvent } from '@src/metrics';
import { createStory } from '@src/util/createStory';
import EditStory from '@src/components/AvailabilityRequest/EditStory';
import SendingRequest from '@src/components/AvailabilityRequest/SendingRequest';
import { sendRequest } from '@src/components/AvailabilityRequest/actions';
import { SubPageAppBar } from '@src/components/PrimaryAppBar';
import { SETTINGS_ROUTE } from '@src/constants/routes';
import { getUser } from '@src/graphql/user/actions/getUser';
import { useAppDispatch } from '@src/store';
import { closeModal, setContent as setModalContent } from '@src/redux/actions/slideUpModal';
import { setNavigationVisibility } from '@src/redux/actions/navigation';
import { updateUser } from '@src/redux/actions/user';
import { Context } from '@src/metrics/enums/context.enum';
import { Interaction } from '@src/metrics/enums/interaction.enum';
import { ItemType } from '@src/metrics/enums/itemType.enum';
import Story from '@src/components/AvailabilityRequest/Story';
import { BackContinueActions } from '@src/components/BackContinueActions';
import { GetLocationByIdDocument, GetLocationByIdQuery, useGetAvailableCurrenciesQuery } from '@src/graphql/generated';
import { CreateStoryValue } from '@src/util/createStory/interfaces/createStory.interface';
import UserData from './UserData';
import { EditUser } from './EditUser';
import { Skeleton } from './Skeleton';
import { InboxProfileState, UserUpdates } from './interfaces/inboxProfile.interface';
import './styles.scss';

const STORY_LABELS = {
  location: 'Located in',
};

export const InboxProfile: FC = () => {
  const history = useHistory();

  const dispatch = useAppDispatch();

  const [{ availabilityRequestId, hasProfileChanged, isSaving, story, userUpdates }, setState] =
    useState<InboxProfileState>({
      availabilityRequestId: undefined,
      hasProfileChanged: false,
      isSaving: false,
      story: undefined,
      userUpdates: {
        name: '',
        position: '',
      },
    });

  const { data } = useGetAvailableCurrenciesQuery();

  const currencies = data?.availableCurrencies;

  const onStoryChange = (story: CreateStoryValue): void => {
    setState(prevState => ({
      ...prevState,
      hasProfileChanged: true,
      story: story,
    }));

    dispatch(closeModal());
  };

  const editStory = (editing: string): void => {
    setState(prevState => ({
      ...prevState,
      isEditing: true,
    }));

    dispatch(
      setModalContent(
        <EditStory
          currencies={currencies}
          editing={editing}
          enableLocation
          onStoryChange={onStoryChange}
          story={story}
        />,
      ),
    );
  };

  const onUserChange = (field: keyof UserUpdates, value: string): void => {
    setState(prevState => ({
      ...prevState,
      hasProfileChanged: true,
      userUpdates: {
        ...prevState.userUpdates,
        [field]: value,
      },
    }));

    dispatch(closeModal());
  };

  const onSave = async () => {
    setState(prevState => ({
      ...prevState,
      isSaving: true,
    }));

    trackEvent({
      context: Context.PROFILE,
      interaction: Interaction.SAVE_CHANGES,
      itemType: ItemType.PROFILE,
      itemValue: 'inbox',
    });

    apolloClient.stop();
    await apolloClient.clearStore();

    await Promise.all([
      hasProfileChanged && sendRequest(story, availabilityRequestId, 'inbox'),
      hasProfileChanged && dispatch(updateUser(userUpdates)),
    ]);

    setState(prevState => ({
      ...prevState,
      hasProfileChanged: false,
    }));

    setTimeout(() => {
      setState(prevState => ({
        ...prevState,
        isSaving: false,
      }));
    }, 1250);
  };

  const editUser = (prop: 'name' | 'position') => {
    setState(prevState => ({
      ...prevState,
      isEditing: true,
    }));

    dispatch(setModalContent(<EditUser editing={prop} onChange={onUserChange} user={userUpdates} />));
  };

  const renderSubHeader = (title: string, subtitle: string): ReactElement => {
    return (
      <Fragment>
        <div className="inbox-profile__subtitle">{title}</div>
        <div className="inbox-profile__subtitle-info">{subtitle}</div>
      </Fragment>
    );
  };

  const onLoad = async (): Promise<void> => {
    const user = await getUser();

    dispatch(setNavigationVisibility(false));

    const { userMatchingProfile } = await api('/api/app/user-matching-profile?type=inbox');

    let location = user?.location;

    if (userMatchingProfile.availabilityRequest.location) {
      const { data } = await apolloClient.query<GetLocationByIdQuery>({
        fetchPolicy: 'no-cache',
        query: GetLocationByIdDocument,
        variables: {
          id: userMatchingProfile.availabilityRequest.location,
        },
      });

      location = data.location;
    }

    const { speciality, salary, seniorities } = userMatchingProfile;
    const skills = map(userMatchingProfile.skills, 'skill');

    setState(prevState => ({
      ...prevState,
      availabilityRequestId: userMatchingProfile.availabilityRequest._id,
      story: createStory({
        location: location,
        salary: salary,
        seniorities: seniorities,
        skills: skills,
        speciality: speciality,
      }),
      userUpdates: {
        name: toString(user?.name),
        position: toString(user?.position),
      },
    }));
  };

  useEffect(() => {
    onLoad();
  }, []);

  if (!story) {
    return (
      <div className="inbox-profile">
        <SubPageAppBar
          actions={[
            <Link className="inbox-profile__action-icon" key={2} to={SETTINGS_ROUTE}>
              <Svg src={FrSettingsSvg} />
            </Link>,
          ]}
          isFixed
          onBack={() => history.goBack()}
        >
          My profile
        </SubPageAppBar>
        <Skeleton />
      </div>
    );
  }

  if (isSaving) {
    return (
      <div>
        <SendingRequest sendingRequest={hasProfileChanged} sendingText="Publishing..." sentText="Published" />
      </div>
    );
  }

  return (
    <div className="inbox-profile content-enter-animation">
      <div className="inbox-profile__scrollable">
        <SubPageAppBar
          actions={[
            <Link className="inbox-profile__action-icon" key={2} to={SETTINGS_ROUTE}>
              <Svg src={FrSettingsSvg} />
            </Link>,
          ]}
          isDefaultActionsHidden
          isFixed
          onBack={() => history.goBack()}
        >
          My profile
        </SubPageAppBar>

        <div className="inbox-profile__page-subtitle">
          Keep your profile up to date. We will send you new offers based on the criteria you select.
        </div>

        {renderSubHeader('About me', 'My personal details and current experience.')}

        <UserData editUser={editUser} jobTitle={userUpdates.position} userName={userUpdates.name} />

        {renderSubHeader(
          'I’m looking for',
          'I’m open for headhunting from companies whose offers match the following criteria.',
        )}

        <Story currencies={currencies} editStory={editStory} enableLocation labels={STORY_LABELS} story={story} />
      </div>

      {hasProfileChanged && (
        <BackContinueActions className="shown delay-1" isBackHidden label="Save changes" onButtonClick={onSave} />
      )}
    </div>
  );
};
