import React, { FC } from 'react';
import { FeedItemSkeleton } from '@src/containers/Feed/Skeleton';
import { LatestReviewsProps } from '@src/views/Reviews/LatestReviews/props';
import { CompanyReviewFeedItemWithCompanyHeader } from '@src/containers/Feed/CompanyReviewFeedItemWithCompanyHeader';
import './styles.scss';

export const LatestReviews: FC<LatestReviewsProps> = ({ isLoading, reviews }) => {
  return (
    <div className="reviews-page-latest-reviews">
      {reviews.map(review => {
        return <CompanyReviewFeedItemWithCompanyHeader key={review.id} review={review} />;
      })}

      {isLoading && <FeedItemSkeleton wrapperClassName="reviews-page-latest-reviews__feed-skeleton" />}
    </div>
  );
};
