import React, { FC, Fragment, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { BlueButton } from '@src/components/Button';
import { AwardFeedItem } from '@src/containers/Feed/AwardFeedItem';
import { CompanyReviewFeedItem } from '@src/containers/Feed/CompanyReviewFeedItem';
import { FeedItemSkeleton } from '@src/containers/Feed/Skeleton';
import { EmptyState } from '@src/components/EmptyState';
import { useCompanyReview } from './useCompanyReview';
import { ReviewsFeedProps } from './props';
import './styles.scss';

export const ReviewsFeed: FC<ReviewsFeedProps> = ({
  isLoading,
  deepLinkUrl,
  companyId,
  onCreateReview,
  scrollableContainer,
  setIsFeedEmpty,
  doesCurrentUserReviewExists,
}) => {
  const { loading: isReviewFeedLoading, data: feed, pagination } = useCompanyReview(companyId, scrollableContainer);

  useEffect(() => {
    if (!isReviewFeedLoading) {
      setIsFeedEmpty(isEmpty(feed));
    }
  }, [isReviewFeedLoading]);

  return (
    <div className="awards-feed-wrapper">
      {!isLoading && !isReviewFeedLoading && !isEmpty(feed) && (
        <div className="awards-feed-wrapper__title">
          <h2>Reviews ({pagination.totalCount}) </h2>
        </div>
      )}

      {!isEmpty(feed) &&
        feed.map(review => {
          switch (review.type) {
            case 'COMPANY_RATINGS':
              return (
                <CompanyReviewFeedItem companyDeepLink={review?.company.deepLinkUrl} key={review.id} review={review} />
              );
            case 'COMPANY_AWARDS':
              return <AwardFeedItem companyDeepLink={review?.company.deepLinkUrl} key={review.id} review={review} />;
            default:
              return null;
          }
        })}

      {!isLoading && !isReviewFeedLoading && !isEmpty(feed) && !doesCurrentUserReviewExists && (
        <div className="awards-feed-wrapper__caught-up">
          <h4>You’re caught up! Anything to add? </h4>
        </div>
      )}

      {!isLoading && !isReviewFeedLoading && isEmpty(feed) && (
        <Fragment>
          <EmptyState
            imageClassName="awards-feed-wrapper__empty-state-image"
            imageUrl="/images/empty-states/award-empty-state-3x.png"
            imageWrapperClassName="awards-feed-wrapper__empty-state-image-wrapper"
            infoClassName="awards-feed-wrapper__empty-state-info-wrapper"
            subTitle={<Fragment>Share the best reasons to work for this company and help other job seekers.</Fragment>}
            title="No reviews yet"
            titleClassName="awards-feed-wrapper__empty-state-title"
            wrapperClassName="awards-feed-wrapper__empty-state-wrapper"
          />
          <BlueButton
            className="awards-feed-wrapper__give-review"
            isFilled
            onClick={() => (window.isPublicApp ? window.open(deepLinkUrl, '_blank') : onCreateReview())}
          >
            Give a review
          </BlueButton>
        </Fragment>
      )}
      {(isLoading || isReviewFeedLoading) && <FeedItemSkeleton wrapperClassName="awards-feed-wrapper__feed-skeleton" />}
    </div>
  );
};
