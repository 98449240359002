import React from 'react';
import PropTypes from 'prop-types';
import { SlideUpScroll } from '@src/components/SlideUpScroll';
import { TopSection } from './TopSection';
import { SuggestionsList } from './SuggestionsList';
import './styles.scss';

export function NoResults({ goBack, goToView, searchPattern, ...props }) {
  return (
    <div className="search-no-results">
      <SlideUpScroll
        topSectionContent={<TopSection goBack={goBack} title={searchPattern} />}
        topSectionHeight="30vh"
        wrapperClassName="search-no-results__slide-up"
      >
        <SuggestionsList goToView={goToView} searchPattern={searchPattern} {...props} />
      </SlideUpScroll>
    </div>
  );
}

NoResults.propTypes = {
  goBack: PropTypes.func.isRequired,
  goToView: PropTypes.func.isRequired,
  searchPattern: PropTypes.string.isRequired,
};
