import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { htmlifyWithEscape } from '@src/util/htmlifyMessage';
import './styles.scss';

export default function Title({ className, large, value }) {
  return (
    <h1
      className={classnames('title', large && 'large', className)}
      dangerouslySetInnerHTML={{ __html: htmlifyWithEscape(value) }}
    />
  );
}

Title.propTypes = {
  className: PropTypes.string,
  large: PropTypes.bool,
  value: PropTypes.node.isRequired,
};
