import React from 'react';
import { get } from 'lodash';
import { HorizontalScroller } from '@src/components/HorizontalScroller';
import { numberWithSpace } from '@src/util/splittedNumbers';
import { useOpeningsStatisticsQuery } from '@src/graphql/generated';
import { SectionHeader } from '../SectionHeader';
import { StatsItem, DOT_COLOR } from './StatsItem';
import './styles.scss';

export function Statistics() {
  const { data: remoteData, loading: remoteLoading } = useOpeningsStatisticsQuery();
  const remoteOpeningsCount = get(remoteData, 'openingsStatistics.remote.total', 0);
  const remoteOpeningsNewPastWeekCount = get(remoteData, 'openingsStatistics.remote.newPastWeek', 0);

  const { data: onsiteData, loading: onsiteLoading } = useOpeningsStatisticsQuery();
  const onsiteOpeningsCount = get(onsiteData, 'openingsStatistics.onSite.total', 0);
  const onsiteOpeningsNewPastWeekCount = get(onsiteData, 'openingsStatistics.onSite.newPastWeek', 0);

  const { data: hybridData, loading: hybridLoading } = useOpeningsStatisticsQuery();
  const hybridOpeningsCount = get(hybridData, 'openingsStatistics.hybrid.total', 0);
  const hybridOpeningsNewPastWeekCount = get(hybridData, 'openingsStatistics.hybrid.newPastWeek', 0);

  const loading = remoteLoading || onsiteLoading || hybridLoading;

  return (
    <div className="search-statistics fade-in">
      <SectionHeader label="JOBS AS FLEXIBLE AS YOU" />

      <HorizontalScroller>
        <StatsItem
          dotColor={DOT_COLOR.BLUE}
          label="REMOTE OFFERS"
          loading={loading}
          primaryInfo={numberWithSpace(remoteOpeningsCount)}
          secondaryInfo={remoteOpeningsNewPastWeekCount > 0 ? `${remoteOpeningsNewPastWeekCount} NEW LAST WEEK` : ''}
        />

        <StatsItem
          dotColor={DOT_COLOR.PINK}
          label="HYBRID OFFERS"
          loading={loading}
          primaryInfo={numberWithSpace(hybridOpeningsCount)}
          secondaryInfo={hybridOpeningsNewPastWeekCount > 0 ? `${hybridOpeningsNewPastWeekCount} NEW LAST WEEK` : ''}
        />

        <StatsItem
          dotColor={DOT_COLOR.GREEN}
          label="ON-SITE OFFERS"
          loading={loading}
          primaryInfo={numberWithSpace(onsiteOpeningsCount)}
          secondaryInfo={onsiteOpeningsNewPastWeekCount > 0 ? `${onsiteOpeningsNewPastWeekCount} NEW LAST WEEK` : ''}
        />
      </HorizontalScroller>
    </div>
  );
}
