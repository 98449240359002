import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Svg from 'svg-inline-react';
import classnames from 'classnames';
import { isEmpty } from 'lodash';
import AddSvg from 'assets/images/fr-add.svg';
import { getTempID } from '@src/util/random';
import { usePrevious } from '@src/customHooks';
import ChatInput from '../ChatInput';
import './styles.scss';

const getValues = inputs => {
  return inputs.map(({ url }) => url);
};

export const DynamicInput = ({ placeholder, selectedValue, onChange, wrapperClassName }) => {
  let activeValues = !isEmpty(selectedValue) ? selectedValue : [''];
  activeValues = activeValues.map(value => {
    const tmpId = getTempID();
    return { id: tmpId, url: value };
  });
  const prevActiveValues = usePrevious(activeValues);
  const [inputs, setInputs] = useState(activeValues);

  useEffect(() => {
    if (isEmpty(prevActiveValues) && !isEmpty(activeValues)) {
      setInputs(activeValues);
    }
  }, [activeValues, prevActiveValues]);

  const addEmptyInput = () => {
    setInputs([...inputs, { id: getTempID(), url: '' }]);
  };

  const handleInputChange = (value, index) => {
    const updatedValues = inputs.map(({ id, url }) => (id === index ? { id, url: value } : { id, url }));
    setInputs(updatedValues);
    onChange(getValues(updatedValues));
  };

  const handleInputClear = index => {
    if (inputs.length > 1) {
      const updatedValues = inputs.filter(({ id }) => id !== index);
      setInputs(updatedValues);
      onChange(getValues(updatedValues));
    } else {
      setInputs([{ id: getTempID(), url: '' }]);
      onChange([]);
    }
  };

  return (
    <div className={classnames('dynamic-input', wrapperClassName)}>
      {!isEmpty(inputs) &&
        inputs.map(({ id, url }) => {
          return (
            <ChatInput
              className="dynamic-input__text-field"
              clearable
              defaultValue={url}
              key={id}
              onChange={value => handleInputChange(value, id)}
              onClear={() => handleInputClear(id)}
              placeholder={placeholder}
            />
          );
        })}

      {inputs[inputs.length - 1].url.length && (
        <span className="dynamic-input__add-button" onClick={addEmptyInput}>
          <Svg className="dynamic-input__add-button-icon" src={AddSvg} />
          Add another
        </span>
      )}
    </div>
  );
};

DynamicInput.defaultProps = {
  defaultValues: [],
};

DynamicInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  selectedValue: PropTypes.arrayOf(PropTypes.string),
  wrapperClassName: PropTypes.string,
};
