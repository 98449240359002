import moment from 'moment';
import React, { Fragment, ReactNode } from 'react';
import {
  CompanyProfileFundingType,
  CompanyProfileValuationType,
  CompanyRevenueType,
  CurrencyType,
  Maybe,
} from '@src/graphql/generated';
import { ValuationRound } from '@src/enum/valutaion.enum';
import { numberToMillion } from '../numberFormatter';
import { MainOffice } from './company.interface';

export const getMessage = (
  totalFunding?: number,
  latestFunding?: Pick<CompanyProfileFundingType, 'value' | 'round' | 'fundedAt'>,
  frankMessage?: Maybe<string>,
  founded?: Maybe<number>,
  mainOffice?: Maybe<MainOffice>,
  companyName?: string,
): ReactNode => {
  if (totalFunding && latestFunding?.value && latestFunding?.round) {
    return `${numberToMillion(latestFunding.value)} ${ValuationRound[latestFunding.round]} in ${moment(
      latestFunding.fundedAt,
    ).get('year')}.  Total funding to date: ${numberToMillion(totalFunding)}.`;
  } else if (frankMessage) {
    return frankMessage;
  } else if (founded && mainOffice && mainOffice.isFoundingLocation) {
    return `${companyName} was founded in ${founded} in ${mainOffice?.location?.shortName}`;
  } else {
    return undefined;
  }
};

export const getFinancialDetailsCopy = (
  latestValuation?: Pick<CompanyProfileValuationType, 'min' | 'max'> & {
    currency?: Pick<CurrencyType, 'symbol'>;
  },
  totalFunding?: number,
  revenue?: Pick<CompanyRevenueType, 'value'>,
): ReactNode => {
  if (latestValuation?.min && latestValuation.max) {
    return (
      <>
        <h4>
          {latestValuation.currency?.symbol}
          {numberToMillion(latestValuation.min)}-{numberToMillion(latestValuation.max)}
        </h4>{' '}
        Valuation
      </>
    );
  } else if (totalFunding) {
    return (
      <>
        <h4>{numberToMillion(totalFunding)}</h4> Raised
      </>
    );
  } else if (revenue?.value) {
    return (
      <>
        <h4>${revenue.value}</h4> Revenue
      </>
    );
  } else {
    return undefined;
  }
};
