import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Photoswipe from 'photoswipe';
import PhotoswipeUIDefault from 'photoswipe/dist/photoswipe-ui-default';
import classnames from 'classnames';
import ReactDOM from 'react-dom';

const EVENTS = [
  'beforeChange',
  'afterChange',
  'imageLoadComplete',
  'resize',
  'gettingData',
  'mouseUsed',
  'initialZoomIn',
  'initialZoomInEnd',
  'initialZoomOut',
  'initialZoomOutEnd',
  'parseVerticalMargin',
  'close',
  'unbindEvents',
  'destroy',
  'updateScrollOffset',
  'preventDragEvent',
  'shareLinkClick',
];

const GALLERY_ROOT = document.getElementById('gallery-root');

export default class PhotoSwipeGallery extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
  };

  state = {
    isOpen: this.props.isOpen,
  };

  pswpElement = undefined;

  componentDidMount() {
    if (this.state.isOpen) {
      this.openPhotoSwipe(this.props);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isOpen !== this.state.isOpen) {
      if (nextProps.isOpen) {
        this.openPhotoSwipe(nextProps);
      } else {
        this.onClose();
      }
    }
  }

  componentWillUnmount = () => this.closePhotoSwipe();
  closePhotoSwipe = () => this.photoSwipe && this.photoSwipe.close();

  openPhotoSwipe = props => {
    const { items, options } = props;
    this.photoSwipe = new Photoswipe(this.pswpElement, PhotoswipeUIDefault, items, options);

    EVENTS.forEach(event => {
      const callback = props[event];
      if (callback || event === 'destroy') {
        const self = this;
        this.photoSwipe.listen(event, function (...args) {
          if (callback) {
            args.unshift(this);
            // eslint-disable-next-line standard/no-callback-literal
            callback(...args);
          }
          if (event === 'destroy') {
            self.onClose();
          }
        });
      }
    });

    this.setState({ isOpen: true }, this.photoSwipe.init);
  };

  onClose = () => {
    const { onClose } = this.props;

    this.setState({ isOpen: false }, () => onClose && onClose());
  };

  render() {
    const { className } = this.props;

    return ReactDOM.createPortal(
      <div
        aria-hidden="true"
        className={classnames('pswp', className)}
        ref={it => {
          this.pswpElement = it;
        }}
        role="dialog"
        tabIndex="-1"
      >
        <div className="pswp__bg" />
        <div className="pswp__scroll-wrap">
          <div className="pswp__container">
            <div className="pswp__item" />
            <div className="pswp__item" />
            <div className="pswp__item" />
          </div>
          <div className="pswp__ui pswp__ui--hidden">
            <div className="pswp__top-bar">
              <div className="pswp__counter" />
              <button className="pswp__button pswp__button--close" title="Close (Esc)" />
              <button className="pswp__button pswp__button--share" title="Share" />
              <button className="pswp__button pswp__button--fs" title="Toggle fullscreen" />
              <button className="pswp__button pswp__button--zoom" title="Zoom in/out" />
              <div className="pswp__preloader">
                <div className="pswp__preloader__icn">
                  <div className="pswp__preloader__cut">
                    <div className="pswp__preloader__donut" />
                  </div>
                </div>
              </div>
            </div>
            <div className="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
              <div className="pswp__share-tooltip" />
            </div>
            <button className="pswp__button pswp__button--arrow--left" title="Previous (arrow left)" />
            <button className="pswp__button pswp__button--arrow--right" title="Next (arrow right)" />
            <div className="pswp__caption">
              <div className="pswp__caption__center" />
            </div>
          </div>
        </div>
      </div>,
      GALLERY_ROOT,
    );
  }
}
