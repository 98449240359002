import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { trackEvent } from '@src/metrics';
import { history } from '@src/store';
import dateStringFromDate from '@src/util/dateStringFromDate';
import { Avatar } from '@src/components/Avatar';
import { NotificationTypeEnum, useMarkNotificationAsReadMutation } from '@src/graphql/generated';
import { Markdown } from '@src/components/Markdown';
import { useFlashMessagesContext } from '@src/context/FlashMessagesContext';
import { AvatarSize } from '@src/components/Avatar/enums/avatarSize.enum';
import Attachment from './Attachment';
import './styles.scss';

const Notification = ({ notification }) => {
  const { setErrorFlashMessage } = useFlashMessagesContext();

  const [markAsReadMutation] = useMarkNotificationAsReadMutation({
    onError: () => {
      setErrorFlashMessage('Failed to mark notification as read');
    },
  });

  const markAsRead = (notificationId: string) => {
    markAsReadMutation({ variables: { notificationId } });
  };

  const openUrl = notification => {
    markAsRead(notification.id);

    if (notification.url) {
      history.push(notification.url);
      trackEvent({
        context: 'Notification Center',
        interaction: 'Open',
        itemType: 'Notification',
        itemValue: notification.url,
      });
    }

    trackEvent({
      context: 'Notification Center',
      interaction: 'Mark As Read',
      itemType: 'Notification',
      itemValue: notification.id,
    });
  };

  const avatarUrl =
    get(notification, 'application.opening.recruiter.avatar.fullUrl') ||
    get(notification, 'opening.recruiter.avatar.fullUrl') ||
    get(notification, 'application.opening.recruiter.avatarUrl') ||
    get(notification, 'opening.recruiter.avatarUrl');
  const avatarColor =
    get(notification, 'application.opening.recruiter.avatarColor') ||
    get(notification, 'opening.recruiter.avatarColor');
  const recruiterInitials =
    get(notification, 'application.opening.recruiter.nameInitials') ||
    get(notification, 'opening.recruiter.nameInitials');
  const isNotificationWithPrimaryRecruiterAvatar = ['NEW_DIGEST_OFFER', 'APPLICATION_ACCEPTED'].includes(
    notification.type,
  );

  return (
    <div className="notification" onClick={() => openUrl(notification)}>
      <div className="notification__avatar-container">
        {notification.icons.secondary ? (
          <>
            <Avatar size={AvatarSize.MEDIUM} src={notification.icons.secondary.fullUrl} />

            <Avatar
              className="notification__avatar-small"
              avatarColor={avatarColor}
              initials={recruiterInitials}
              size={AvatarSize.X_SMALL}
              src={isNotificationWithPrimaryRecruiterAvatar ? avatarUrl : notification.icons.primary.fullUrl}
            />
          </>
        ) : (
          <>
            <Avatar
              avatarColor={avatarColor}
              initials={recruiterInitials}
              size={AvatarSize.MEDIUM}
              src={isNotificationWithPrimaryRecruiterAvatar ? avatarUrl : notification.icons.primary.fullUrl}
            />
          </>
        )}
        <p className="notification__timestamp">{dateStringFromDate(notification.sentAt)}</p>
      </div>
      <div className="notification__content">
        {notification.type === NotificationTypeEnum.BATCH && notification.title ? (
          <strong className="notification__title">{notification.title}</strong>
        ) : null}
        <p className="notification__message">
          <Markdown markdown={notification.message} />
        </p>
        <Attachment notification={notification} />
      </div>
    </div>
  );
};

Notification.propTypes = {
  notification: PropTypes.shape({
    application: PropTypes.shape({
      opening: PropTypes.shape({
        recruiter: PropTypes.shape({
          id: PropTypes.string,
          nameInitials: PropTypes.string,
        }),
      }),
    }),
    icons: PropTypes.shape({
      primary: PropTypes.shape({
        fullUrl: PropTypes.string,
        id: PropTypes.string,
      }).isRequired,
      secondary: PropTypes.shape({
        fullUrl: PropTypes.string,
        id: PropTypes.string,
      }),
    }),
    id: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    opening: PropTypes.shape({
      recruiter: PropTypes.shape({
        id: PropTypes.string,
        nameInitials: PropTypes.string,
      }),
    }),
    readAt: PropTypes.string,
    sentAt: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    url: PropTypes.string,
  }).isRequired,
};

export default Notification;
