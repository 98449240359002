import { GetUserQuery } from '@src/graphql/generated';
import { LocalStorageKeys } from '@src/localStorage/enums/localStorageKeys.enum';
import { getItemFromLocalStorage } from '@src/localStorage/utils/getItemFromLocalStorage';
import { removeItemFromLocalStorage } from '@src/localStorage/utils/removeItemFromLocalStorage';
import { setItemToLocalStorage } from '@src/localStorage/utils/setItemToLocalStorage';

export const setOrUpdateUserToLocalStorage = (user: GetUserQuery['currentUser']): void => {
  setItemToLocalStorage<GetUserQuery['currentUser']>(LocalStorageKeys.USER, user);
};

export const removeUserFromLocalStorage = (): void => {
  removeItemFromLocalStorage(LocalStorageKeys.USER);
};

export const getUserFromLocalStorage = (): GetUserQuery['currentUser'] | undefined => {
  return getItemFromLocalStorage<GetUserQuery['currentUser']>(LocalStorageKeys.USER);
};
