import React, { FC } from 'react';
import classnames from 'classnames';
import { BlueButton } from '@src/components/Button';
import { AcceptedMessageOverlayProps } from './props';
import './styles.scss';

export const AcceptedMessageOverlay: FC<AcceptedMessageOverlayProps> = ({
  wrapperClassName,
  recruiterName,
  chatLink,
  onCancel,
  isOpen,
}) => {
  return (
    <div
      className={classnames('accepted-message-overlay', wrapperClassName, isOpen && 'accepted-message-overlay--open')}
    >
      <div className="accepted-message-overlay__inner">
        <div className="accepted-message-overlay__title">You’re connected!</div>

        <div className="accepted-message-overlay__message">
          You can now Chat with {recruiterName} about possible job opportunities.
        </div>

        {!!chatLink && (
          <BlueButton className="accepted-message-overlay__open-button" isFilled isLink to={chatLink}>
            Open chat
          </BlueButton>
        )}

        <div className="accepted-message-overlay__close-button" onClick={onCancel}>
          View other requests
        </div>
      </div>
    </div>
  );
};
