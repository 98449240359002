import { useEffect, useState, useRef } from 'react';
import { ScrollDirection } from './enum/scrollDirection.enum';
import { UseScrollDirectionParams, UseScrollDirectionValue } from './interfaces/useScrollDirection.interface';

export const useScrollDirection = ({
  isShownByDefault,
  getScrollableContainerNode,
  getBreakPoint,
  onBreakPointReached,
  onScroll,
}: UseScrollDirectionParams): UseScrollDirectionValue => {
  const lastScrollTop = useRef(0);
  const isOnHidePosition = useRef(false);

  const [scrollDirection, setScrollDirection] = useState(isShownByDefault ? ScrollDirection.UP : ScrollDirection.DOWN);

  const getScrollTop = (element: EventTarget | null): number => {
    return element?.pageYOffset || element?.scrollTop;
  };

  useEffect(() => {
    const element = getScrollableContainerNode();
    const breakPoint = getBreakPoint() || 0;

    const handleScroll: EventListenerOrEventListenerObject = event => {
      if (!element.classList.contains('disable-scroll')) {
        const scrollTop = getScrollTop(event.target);

        onScroll?.(scrollTop);

        if (
          scrollTop > lastScrollTop.current ||
          // Hide element at starting point if it is not shown by default
          (!isShownByDefault && scrollTop <= breakPoint)
        ) {
          setScrollDirection(ScrollDirection.DOWN);
        } else {
          setScrollDirection(ScrollDirection.UP);
        }

        // emits a boolean whenever scroll gets past to break-point from any direction
        if (onBreakPointReached) {
          if (scrollTop <= breakPoint && isOnHidePosition.current) {
            isOnHidePosition.current = false;
            onBreakPointReached(false);
          }

          if (scrollTop > breakPoint && !isOnHidePosition.current) {
            isOnHidePosition.current = true;
            onBreakPointReached(true);
          }
        }

        /**
         * Need for mobile devices, to make sure that "pull refresh" action
         * wont break the functionality
         */
        lastScrollTop.current = scrollTop <= breakPoint ? breakPoint : scrollTop;
      }
    };

    if (element) {
      element.addEventListener('scroll', handleScroll, false);

      return () => {
        element.removeEventListener('scroll', handleScroll, false);
      };
    }
  }, []);

  return { scrollDirection };
};
