import React, { FC, useEffect, useRef, useState } from 'react';
import { find } from 'lodash';
import { matchPath } from 'react-router-dom';
import { getFlagEmoji } from '@shared/utils/getFlagEmoji';
import {
  COMPANY_ABOUT_ROUTE,
  COMPANY_JOB_OPENINGS_ROUTE,
  COMPANY_PREVIEW_ROUTE,
  COMPANY_REVIEWS_ROUTE,
  COMPANY_ROUTE,
} from '@src/constants/routes';
import { setScroll } from '@src/redux/actions/scroll';
import { setContent as setModalContent, closeModal } from '@src/redux/actions/slideUpModal';
import { Context } from '@src/metrics/enums/context.enum';
import { Interaction } from '@src/metrics/enums/interaction.enum';
import { useAppDispatch, useAppSelector } from '@src/store';
import { getPreviousLocation } from '@src/redux/selectors/navigation';
import { getScroll } from '@src/redux/selectors/scroll';
import { GLOBAL_AREA_ID } from '@src/constants';
import { PageNames } from '@src/redux/selectors/scroll/enums/pageNames.enum';
import { getScrollableContainer } from '@src/App';
import { PlaceFiltersModalContent } from '@src/containers/PlaceFiltersModalContent';
import { CountryType, PlaceTypeEnum, useGetTopReviewedCompaniesQuery, useGetUserQuery } from '@src/graphql/generated';
import { createPlaceItem, createDefaultCountryItemsFromPlaces } from '@src/util/filters';
import { LocalStorageKeys } from '@src/localStorage/enums/localStorageKeys.enum';
import { useCountryFilter } from '@src/customHooks/useCountryFilter';
import { isCountryType } from '@src/graphql/typeGuards';
import { LocationViews } from '@src/containers/PlaceFiltersModalContent/enum/locationView.enum';
import { ALL_PLACE_ID } from '@src/constants/placeIds';
import { useQueryParams } from '@src/customHooks/useQueryParams';
import { PageHeaderWithLocation } from '@src/containers/PageHeaderWithLocation';
import { useCompanyBlocksSearch } from '@src/customHooks/useCompanyBlocksSearch';
import { SearchedCompanies } from '@src/containers/SearchedCompanies';
import { trackEvent } from '@src/metrics';
import { SectionTitle } from './SectionTitle';
import { TopCompanies } from './TopCompanies';
import { LatestReviews } from './LatestReviews';
import { useLatestReviews } from './hooks/useLatestReviews';
import { QueryParams } from './interfaces/queryParams.interface';
import './styles.scss';

export const Reviews: FC = () => {
  const feedContainerRef = useRef(null);

  const previousLocation = useAppSelector(getPreviousLocation);

  const scrolls = useAppSelector(getScroll);

  const dispatch = useAppDispatch();

  const { queryParams, updateQueryParams } = useQueryParams<QueryParams>();

  const searchPattern = queryParams?.searchPattern;

  const scrollableContainer = getScrollableContainer();

  const [isSimulatedLoading, setIsSimulatedLoading] = useState(false);

  const { data: userData } = useGetUserQuery();

  const user = userData?.currentUser;
  const userLocation = user?.location;
  const userLocationCountry = userLocation?.country;

  const userCountryItem = userLocation?.country?.id
    ? createPlaceItem({
        entity: {
          __typename: 'CountryType',
          id: userLocation.country.id,
          longName: userLocationCountry?.longName || '',
          shortName: userLocationCountry?.shortName || '',
        },
        type: PlaceTypeEnum.COUNTRY,
      })
    : undefined;

  const { savedCountries, setActiveCountry, activeCountryId, addCountries, country } = useCountryFilter({
    localStorageKey: LocalStorageKeys.USER_SELECTED_REVIEWS_COUNTRY_DATA,
  });

  const activeCountryShortName = country?.shortName;

  const placeItems = createDefaultCountryItemsFromPlaces(savedCountries, userCountryItem);

  const { data: topReviewedCompaniesData, loading: isTopReviewedCompaniesLoading } = useGetTopReviewedCompaniesQuery({
    variables: {
      filterInput: {
        countryIds: activeCountryId && activeCountryId !== ALL_PLACE_ID ? [activeCountryId] : undefined,
      },
      paginationOptions: {
        limit: 5,
      },
    },
  });

  const topReviewedCompanies = topReviewedCompaniesData?.topReviewedCompanies.result || [];

  const flagEmoji = activeCountryShortName ? getFlagEmoji(activeCountryShortName) : '';

  const { reviews, isReviewsLoading } = useLatestReviews({
    activeCountryId: activeCountryId,
    scrollableContainer: scrollableContainer,
  });

  const { isSearchedCompaniesLoading, shouldDisplaySearchResults, searchedCompanies } = useCompanyBlocksSearch({
    activeCountryId: activeCountryId,
    searchPattern: searchPattern,
  });

  const simulateLoading = (): void => {
    setIsSimulatedLoading(true);

    setTimeout(() => {
      setIsSimulatedLoading(false);
    }, 350);
  };

  const handleActivePlaceSet = (placeId: string) => {
    setActiveCountry(placeId);

    simulateLoading();
  };

  const onFiltersApplied = (countries: CountryType[]) => {
    scrollableContainer.scrollTop = 0;

    const selectedCountry =
      countries.find(country => country.id === activeCountryId) || countries[countries.length - 1];

    addCountries(countries, selectedCountry?.id);
  };

  const openLocationFilter = () => {
    dispatch(
      setModalContent({
        content: (
          <PlaceFiltersModalContent
            defaultPlaces={savedCountries}
            defaultView={LocationViews.COUNTRY_SEARCH_VIEW}
            availableViews={[LocationViews.COUNTRY_SEARCH_VIEW]}
            excludedAreaIds={[GLOBAL_AREA_ID]}
            onApplyFilters={places => {
              const filteredCountries = places.filter(isCountryType);

              onFiltersApplied(filteredCountries);
            }}
            onClose={() => dispatch(closeModal())}
          />
        ),
        height: '70vh',
      }),
    );
  };

  useEffect(() => {
    const scroll = scrolls?.[PageNames.REVIEWS] || 0;

    const scrollableContainer = getScrollableContainer();

    trackEvent({
      context: Context.REVIEWS,
      interaction: Interaction.VIEW,
    });

    const isPrevRouteCompany =
      previousLocation &&
      find(
        [COMPANY_ROUTE, COMPANY_ABOUT_ROUTE, COMPANY_JOB_OPENINGS_ROUTE, COMPANY_REVIEWS_ROUTE, COMPANY_PREVIEW_ROUTE],
        route => matchPath(previousLocation, { path: route }),
      );

    if (scrollableContainer) {
      scrollableContainer.scrollTop = isPrevRouteCompany && scroll ? scroll : 0;
    }

    return () => {
      dispatch(setScroll(PageNames.REVIEWS, scrollableContainer.scrollTop));
    };
  }, []);

  return (
    <div className="reviews-view">
      <PageHeaderWithLocation
        title="Reviews"
        activePlaceId={activeCountryId}
        addPlace={openLocationFilter}
        getScrollableContainer={getScrollableContainer}
        onPlaceClick={place => handleActivePlaceSet(place.id)}
        places={placeItems}
        providedTargetEl={feedContainerRef}
        searchPattern={searchPattern}
        onSearch={searchPattern => {
          updateQueryParams({
            searchPattern: searchPattern,
          });
        }}
      />

      <div className="reviews-view__content" ref={feedContainerRef}>
        {shouldDisplaySearchResults && !!searchPattern && (
          <div className="reviews-view__search">
            <SearchedCompanies
              companies={searchedCompanies}
              isLoading={isSearchedCompaniesLoading}
              searchPattern={searchPattern}
            />
          </div>
        )}

        {!shouldDisplaySearchResults && (
          <>
            <SectionTitle
              title="Top companies"
              subtitle={flagEmoji ? `Highest rated companies in ${flagEmoji}` : undefined}
            />

            <TopCompanies
              companies={topReviewedCompanies}
              isLoading={isTopReviewedCompaniesLoading || isSimulatedLoading}
            />

            <SectionTitle
              title="Latest reviews"
              subtitle={flagEmoji ? `Recently reviewed in ${flagEmoji}` : undefined}
            />

            <LatestReviews reviews={reviews} isLoading={isReviewsLoading || isSimulatedLoading} />
          </>
        )}
      </div>
    </div>
  );
};
