import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { BaseAppBar } from '@src/components/PrimaryAppBar';
import { ScrollToggleContainer } from '@src/components/ScrollToggleContainer';
import './styles.scss';

export const ScrollToggleAppBar = ({
  isDefaultActionsHidden,
  isDark,
  wrapperClassName,
  leadingEl,
  additionalEl,
  children,
  actions,
  getScrollableContainer,
  onBreakPointReached,
}) => {
  return (
    <ScrollToggleContainer
      getScrollableContainerNode={getScrollableContainer}
      isShownByDefault
      onBreakPointReached={onBreakPointReached}
    >
      <div className="scroll-toggle-app-bar">
        <BaseAppBar
          actions={actions}
          isDark={isDark}
          isDefaultActionsHidden={isDefaultActionsHidden}
          leadingEl={leadingEl}
          wrapperClassName={classnames('scroll-toggle-app-bar__top', wrapperClassName)}
        >
          {children}
        </BaseAppBar>

        <div className="scroll-toggle-app-bar__bot">{additionalEl}</div>
      </div>
    </ScrollToggleContainer>
  );
};

ScrollToggleAppBar.propTypes = {
  actions: PropTypes.array,
  additionalEl: PropTypes.node,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  getScrollableContainer: PropTypes.func.isRequired,
  isDark: PropTypes.bool,
  isDefaultActionsHidden: PropTypes.bool,
  leadingEl: PropTypes.node,
  onBreakPointReached: PropTypes.func,
  wrapperClassName: PropTypes.string,
};
