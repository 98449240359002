import React from 'react';
import PropTypes from 'prop-types';
import { get, some } from 'lodash';
import pluralize from 'pluralize';
import { SelectItem, SelectItemLoading } from '@src/components/SelectItem';
import { getPreFilledSearchTags } from '@src/util/getSearchTags';
import { useGetOpeningsCountForSenioritiesListQuery, useSenioritiesQuery } from '@src/graphql/generated';

export const SenioritiesListWithOpenings = ({ onSenioritySelect, activeSeniorities, allSearchTags, searchPattern }) => {
  const activeSearchTags = getPreFilledSearchTags(allSearchTags);
  const { data: allSenioritiesData, loading: allSenioritiesDataLoading } = useSenioritiesQuery();
  const { data: senioritiesData, loading: senioritiesLoading } = useGetOpeningsCountForSenioritiesListQuery({
    fetchPolicy: 'network-only',
    variables: {
      categories: ['SENIORITY'],
      groupSearchTagsWithOrCondition: activeSearchTags.groupSearchTagsWithOrCondition,
      minOpeningsWithinCategoryCount: 0,
      searchPattern: searchPattern,
      searchTags: activeSearchTags.searchTagsWithAndCondition,
      searchTagsWithOrCondition: activeSearchTags.searchTagsWithOrCondition,
    },
  });
  const allSeniorities = get(allSenioritiesData, 'seniorities') || [];
  const seniorities = get(senioritiesData, 'openingCategories') || [];

  const isLoading = allSenioritiesDataLoading || senioritiesLoading;

  const senioritiesWithOpenings = allSeniorities.map(seniority => {
    const entity = seniorities.find(it => get(it, 'entity.id') === get(seniority, 'id'));
    const openingsCount = get(entity, 'openingsCount', 0);

    const secondaryName = openingsCount > 0 ? pluralize('job offer', openingsCount, true) : 'No job offers currently';

    return {
      ...seniority,
      secondaryName: isLoading ? 'Loading...' : secondaryName,
    };
  });

  const handleSenioritiesSelect = seniority => {
    const activeSenioritiesWithNames = senioritiesWithOpenings.filter(seniority =>
      activeSeniorities.find(it => seniority.id === it.id),
    );
    const updatedSeniorities = activeSenioritiesWithNames.find(it => it.id === seniority.id)
      ? activeSenioritiesWithNames.filter(it => it.id !== seniority.id)
      : [...activeSenioritiesWithNames, seniority];

    onSenioritySelect(updatedSeniorities);
  };

  return (
    <div>
      {allSenioritiesDataLoading && <SelectItemLoading amount={5} hasCheckbox hasImage={false} />}

      {!allSenioritiesDataLoading &&
        senioritiesWithOpenings?.map(seniority => {
          return (
            <SelectItem
              active={some(activeSeniorities, { id: seniority.id })}
              key={seniority.key}
              onSelect={handleSenioritiesSelect}
              option={seniority}
              optionLabel={seniority.name}
              secondaryName={seniority.secondaryName}
            />
          );
        })}
    </div>
  );
};

SenioritiesListWithOpenings.propTypes = {
  activeSeniorities: PropTypes.array,
  allSearchTags: PropTypes.array.isRequired,
  onSenioritySelect: PropTypes.func.isRequired,
  searchPattern: PropTypes.string,
};
