import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Svg from 'svg-inline-react';
import { connect } from 'react-redux';
import { isEmpty, get, head } from 'lodash';
import FrChevronLeftSvg from 'assets/images/fr-chevron-left.svg';
import { apolloClient } from '@src/graphql/apollo';
import { BasePageHeader } from '@src/containers/PageHeader/BasePageHeader';
import { SubPageAppBar } from '@src/components/PrimaryAppBar';
import { setContent as setModalContent, closeModal } from '@src/redux/actions/slideUpModal';
import { GetAreaByIdDocument, GetCountryByIdDocument, GetLocationByIdDocument } from '@src/graphql/generated';
import { Modal as FiltersModal } from '../Filters/Modal';
import { Filters, SEARCH_RESULT_FILTERS_KEYS } from '../Filters';
import { LOCATION_VIEWS } from '../Filters/Locations';
import { useFilters } from './hooks';
import './styles.scss';

const _Header = ({
  onBack,
  title,
  applyFilters,
  searchTags,
  searchPattern,
  workOptions,
  openFilter,
  onSearchPatternClick,
  setModalContent,
  closeModal,
}) => {
  const { loading, filters } = useFilters(searchTags);

  const [filterLabels, setFilterLabels] = useState({});
  useEffect(() => {
    if (!isEmpty(openFilter)) {
      handleFilterClick(openFilter, LOCATION_VIEWS.AREA_SELECTION_VIEW);
    }
  }, [openFilter]);

  const getUpdatedLabels = useCallback(async (filterKey, appliedFilters, existingFilterLabels) => {
    switch (filterKey) {
      case SEARCH_RESULT_FILTERS_KEYS.LOCATION:
        let areaData;
        let countryData;
        let locationData;

        if (get(appliedFilters, 'area.id')) {
          const { data: area } = await apolloClient.query({
            query: GetAreaByIdDocument,
            variables: {
              id: get(appliedFilters, 'area.id'),
            },
          });
          areaData = area;
        }

        if (get(appliedFilters, 'country.id')) {
          const { data: country } = await apolloClient.query({
            query: GetCountryByIdDocument,
            variables: {
              id: get(appliedFilters, 'country.id'),
            },
          });
          countryData = country;
        }

        if (get(appliedFilters, 'location.id') || get(appliedFilters, 'location[0].id')) {
          const { data: location } = await apolloClient.query({
            query: GetLocationByIdDocument,
            variables: {
              id: get(appliedFilters, 'location.id') || get(appliedFilters, 'location[0].id'),
            },
          });
          locationData = location;
        }

        return {
          ...existingFilterLabels,
          area: get(areaData, 'area'),
          country: get(countryData, 'country'),
          location: get(locationData, 'location'),
        };
      case SEARCH_RESULT_FILTERS_KEYS.SKILLS:
        return {
          ...existingFilterLabels,
          skill: head(appliedFilters),
        };
      case SEARCH_RESULT_FILTERS_KEYS.SENIORITIES:
        return {
          ...existingFilterLabels,
          seniority: head(appliedFilters),
        };
      default:
        return {};
    }
  }, []);

  const handleFilterClick = (filter, locationDefaultView) => {
    setModalContent({
      content: (
        <FiltersModal
          activeView={filter.key}
          locationDefaultView={locationDefaultView}
          onApplyFilters={async (appliedFilters, queryParams) => {
            const updatedLabels = await getUpdatedLabels(filter.key, appliedFilters, filterLabels);
            setFilterLabels(updatedLabels);

            applyFilters(filter.key, appliedFilters, queryParams);
            closeModal();
          }}
          onClose={closeModal}
          searchPattern={searchPattern}
          searchTags={searchTags}
          workOptions={workOptions}
        />
      ),
      height: '70vh',
    });
  };

  useEffect(() => {
    if (!loading) {
      setFilterLabels(filters);
    }
  }, [loading]);

  return (
    <BasePageHeader
      additionalEl={
        <Filters
          applyFilters={applyFilters}
          filterLabels={filterLabels}
          loading={loading}
          onFilterClick={handleFilterClick}
          openFilter={openFilter}
          searchPattern={searchPattern}
          searchTags={searchTags}
          workOptions={workOptions}
          wrapperClassName="search-result-header__app-bar-filters"
        />
      }
      toggleAppBarEl={
        <SubPageAppBar isDefaultActionsHidden isFrankBannerVisible={false} onBack={onBack}>
          <div className="search-result-header__app-bar-title" onClick={onSearchPatternClick}>
            {title}
          </div>
        </SubPageAppBar>
      }
      toggleState="no-toggle"
      wrapperClassName="search-result-header"
    >
      <div className="search-result-header__main-section">
        {onBack && (
          <div className="search-result-header__back" onClick={onBack}>
            <Svg src={FrChevronLeftSvg} />
          </div>
        )}
        <div
          className={classnames(
            'search-result-header__title',
            !!title && 'search-result-header__title--shown',
            !!title && title.length > 25 && 'search-result-header__title--small',
          )}
        >
          {title}
        </div>

        <div className="search-result-header__filters">
          <Filters
            applyFilters={applyFilters}
            filterLabels={filterLabels}
            loading={loading}
            onFilterClick={handleFilterClick}
            openFilter={openFilter}
            searchPattern={searchPattern}
            searchTags={searchTags}
            workOptions={workOptions}
          />
        </div>
      </div>
    </BasePageHeader>
  );
};

_Header.propTypes = {
  applyFilters: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onSearchPatternClick: PropTypes.func.isRequired,
  openFilter: PropTypes.object,
  searchPattern: PropTypes.string.isRequired,
  searchTags: PropTypes.array.isRequired,
  setModalContent: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  workOptions: PropTypes.array,
};

export const Header = connect(null, { closeModal, setModalContent })(_Header);
