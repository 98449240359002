import { GetUserForVerificationEligibilityCheckQuery } from '@src/graphql/generated';
import { hasFields } from '@src/util/object/helpers';
import { OnboardedUserProfileType } from './currentUser.interface';

export function isOnboardedUserProfileType(
  userProfile: GetUserForVerificationEligibilityCheckQuery['currentUser']['profile'],
): userProfile is OnboardedUserProfileType {
  return (
    hasFields<OnboardedUserProfileType>(userProfile, ['__typename']) &&
    userProfile.__typename === 'OnboardedUserProfileType'
  );
}
