import React from 'react';
import PropTypes from 'prop-types';
import Svg from 'svg-inline-react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import FrArrowLeftSvg from 'assets/images/fr-arrow-left.svg';
import { goBackAndPreventLeave } from '@src/redux/actions/navigation';
import './styles.scss';

const _SecondaryAppBar = ({ wrapperClassName, title, actions, onBack, goBackAndPreventLeave }) => {
  return (
    <div className={classnames('secondary-app-bar', wrapperClassName)}>
      <div className="secondary-app-bar__content">
        <div className="secondary-app-bar__left-content">
          <div className="secondary-app-bar__action" onClick={onBack || goBackAndPreventLeave}>
            <Svg className="secondary-app-bar__action-icon" src={FrArrowLeftSvg} />
          </div>
        </div>
        <div className="secondary-app-bar__title">
          <span>{title}</span>
        </div>
        <div className="secondary-app-bar__right-content">
          {actions.map(({ icon, iconClassName, onOpen, statusCount }, index) => (
            <div className="secondary-app-bar__action" key={index} onClick={onOpen}>
              <Svg className={classnames('secondary-app-bar__action-icon', iconClassName)} src={icon} />

              {!!statusCount && <span className="secondary-app-bar__action-status-number">{statusCount}</span>}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

_SecondaryAppBar.defaultProps = {
  actions: [],
};

_SecondaryAppBar.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.node.isRequired,
      iconClassName: PropTypes.string,
      onOpen: PropTypes.func.isRequired,
      statusCount: PropTypes.number,
    }),
  ).isRequired,
  goBackAndPreventLeave: PropTypes.func.isRequired,
  onBack: PropTypes.func,
  title: PropTypes.string.isRequired,
  wrapperClassName: PropTypes.string,
};

export const SecondaryAppBar = connect(null, { goBackAndPreventLeave })(_SecondaryAppBar);
