import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from '@src/components/Link';
import './styles.scss';

export const Label = ({
  text,
  className,
  type,
  indicator,
  indicatorClassName,
  isLink,
  linkProps,
  onClick,
  onTouchStart,
  onTouchEnd,
  style,
}) => {
  const indicatorEl = indicator && (
    <span className={classnames('fr-label__indicator', `fr-label__indicator--${type}`, indicatorClassName)} />
  );

  const defaultProps = {
    className: classnames('fr-label', type && `fr-label--${type}`, className),
    onClick: e => onClick && onClick(e),
    onTouchEnd: e => onTouchEnd && onTouchEnd(e),
    onTouchStart: e => onTouchStart && onTouchStart(e),
    style,
  };

  if (isLink) {
    return (
      <Link {...defaultProps} {...linkProps}>
        {indicatorEl} {text}
      </Link>
    );
  }

  return (
    <div {...defaultProps}>
      {indicatorEl} {text}
    </div>
  );
};

Label.propTypes = {
  className: PropTypes.string,
  indicator: PropTypes.bool,
  indicatorClassName: PropTypes.string,
  isLink: PropTypes.bool,
  linkProps: PropTypes.object,
  onClick: PropTypes.func,
  onTouchEnd: PropTypes.func,
  onTouchStart: PropTypes.func,
  style: PropTypes.object,
  text: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['blue', 'invisible', 'invisible-blue', 'button-grey', 'button-transparent', false]),
};
