import React, { FC, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { range } from 'lodash';
import Svg from 'svg-inline-react';
import CheckedSvg from 'assets/images/fr-checkbox-checked.svg';
import UncheckedSvg from 'assets/images/fr-checkbox-unchecked.svg';
import EllipsesSvg from 'assets/images/fr-ellipses.svg';
import FadeInImage from '@src/components/FadeInImage';
import './styles.scss';

export const SelectItem: FC = ({
  active,
  onSelect,
  option,
  isChild,
  isParent,
  disabled,
  optionLabel,
  svgSrc,
  imageClassName,
  svgClassName,
  imageWidth,
  imageHeight,
  imageFullUrl,
  onCheckBoxClick,
  onContentClick,
  secondaryName,
  isSingleSelect,
}) => {
  return (
    <div
      className={classnames(
        'select-item',
        active && 'select-item--active',
        isChild && 'select-item--child',
        isParent && 'select-item--parent',
        disabled && 'select-item--disabled',
      )}
      onClick={() => !disabled && onSelect && onSelect(option)}
    >
      <div className="select-item__left-section" onClick={() => onContentClick && onContentClick()}>
        {imageFullUrl && !svgSrc && (
          <FadeInImage
            className={classnames('select-item__image', imageClassName)}
            src={imageFullUrl}
            style={{ height: imageHeight, marginLeft: '.5rem', width: imageWidth }}
          />
        )}
        {svgSrc && !imageFullUrl && <Svg className={classnames('select-item__svg-image', svgClassName)} src={svgSrc} />}
        <div>
          <div className="select-item__name">{optionLabel || option.value}</div>
          {(option.secondaryName || secondaryName) && (
            <div className="select-item__secondary-name">{option.secondaryName || secondaryName}</div>
          )}
        </div>
      </div>

      {isSingleSelect ? (
        <div className="select-item__radio-button-wrapper" onClick={() => onCheckBoxClick && onCheckBoxClick()}>
          <div className="select-item__radio-button">
            <Svg className="select-item__radio-button-icon" src={EllipsesSvg} />
          </div>
        </div>
      ) : (
        <div className="select-item__checkbox-wrapper" onClick={() => onCheckBoxClick && onCheckBoxClick()}>
          <div className="select-item__checkbox">
            <Svg className="select-item__checkbox-unchecked" src={UncheckedSvg} />
            <Svg className="select-item__checkbox-checked" src={CheckedSvg} />
          </div>
        </div>
      )}
    </div>
  );
};

export const SelectItemLoading = ({ imageWidth, imageHeight, amount, hasImage, hasCheckbox }) => {
  return (
    <>
      {range(0, amount).map(index => (
        <div className="select-item select-item--loading" key={index}>
          {hasImage && (
            <div className="select-item__image-loading" style={{ height: imageHeight, width: imageWidth }} />
          )}

          <div style={{ flex: 1 }}>
            <div className="select-item__label-loading" />
          </div>

          {hasCheckbox && <div className="select-item__checkbox select-item__image-loading" style={{ margin: 0 }} />}
        </div>
      ))}
    </>
  );
};

SelectItem.propTypes = {
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  imageClassName: PropTypes.string,
  imageFullUrl: PropTypes.string,
  imageHeight: PropTypes.string,
  imageWidth: PropTypes.string,
  isChild: PropTypes.bool,
  isParent: PropTypes.bool,
  isSingleSelect: PropTypes.bool,
  onCheckBoxClick: PropTypes.func,
  onContentClick: PropTypes.func,
  onSelect: PropTypes.func,
  option: PropTypes.object.isRequired,
  optionLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  secondaryName: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  svgClassName: PropTypes.string,
  svgSrc: PropTypes.node,
};

SelectItem.defaultProps = {
  imageHeight: '4rem',
  imageWidth: '4rem',
  isSingleSelect: false,
  loading: false,
};

SelectItemLoading.propTypes = {
  amount: PropTypes.number,
  hasCheckbox: PropTypes.bool,
  hasImage: PropTypes.bool,
  imageHeight: PropTypes.string,
  imageWidth: PropTypes.string,
};

SelectItemLoading.defaultProps = {
  amount: 1,
  hasCheckbox: false,
  hasImage: true,
  imageHeight: '4rem',
  imageWidth: '4rem',
};
