import React from 'react';
import PropTypes from 'prop-types';
import { FixedHeader } from '@src/components/FixedHeader';
import './styles.scss';

export function TopSection({ title, goBack }) {
  return (
    <div className="search-no-results-top-section">
      <FixedHeader close onBack={goBack} title={title} wrapperClassName="search-no-results-top-section__fixed-header" />

      <div className="search-no-results-top-section__info">
        <div className="search-no-results-top-section__title">No results</div>
        <div className="search-no-results-top-section__subtitle">Try another location or change the keyword.</div>
      </div>
    </div>
  );
}

TopSection.propTypes = {
  goBack: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};
