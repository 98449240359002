import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Video } from '@src/components/Video';
import { AboutTitle } from '../AboutTitle';
import { AboutText } from '../AboutText';
import { Images } from '../Images';
import './styles.scss';

export const Office = ({ officeVideo, officeImages, officeDescription, locations }) => {
  if (!officeDescription && !officeVideo && isEmpty(officeImages) && isEmpty(locations)) {
    return null;
  }

  return (
    <div className="company-profile-office">
      <AboutTitle>Offices</AboutTitle>

      <AboutText text={officeDescription} />

      {!!officeVideo && <Video video={officeVideo} />}

      <Images images={officeImages} />

      {!isEmpty(locations) && (
        <div className="company-profile-office__locations">
          {locations.map(({ shortName }, i) => (
            <div className="company-profile-office__locations-item" key={i}>
              {shortName}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

Office.propTypes = {
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      shortName: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  officeDescription: PropTypes.string,
  officeImages: PropTypes.arrayOf(PropTypes.object),
  officeVideo: PropTypes.object,
};
