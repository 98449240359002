export enum PageNames {
  ACTIVE_COMPANIES = 'active-companies',
  ARTICLES = 'articles',
  FOR_YOU = 'for-you',
  INBOX = 'inbox',
  JOBS = 'jobs',
  JOB_SEARCH = 'job-search',
  MY_BOOKMARKS = 'my-bookmarks',
  REVIEWS = 'reviews',
  TOP_COMPANIES = 'top-companies',
}
