import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

export const OverallScore = ({ totalRatings, averageRating }) => {
  return (
    <div className="overall-score">
      <div className="overall-score__number">{parseFloat(averageRating).toFixed(1)}</div>
      <p className="overall-score__title">Overall score</p>
      <span className="overall-score__ratings">{totalRatings} ratings</span>
    </div>
  );
};

OverallScore.propTypes = {
  averageRating: PropTypes.number.isRequired,
  totalRatings: PropTypes.number.isRequired,
};
