import React from 'react';
import PropTypes from 'prop-types';
import { round } from 'lodash';
import { Score } from '@src/components/Score';
import { RATING_LABELS } from '@src/constants';
import './styles.scss';

export const ScoresTable = ({ ratings }) => {
  return (
    <div className="score-table">
      <div className="score-table__container">
        {Object.values(RATING_LABELS).map(({ key, label }) => (
          <div className="score-table__item" key={key}>
            <span className="score-table__title">{label}</span>

            <Score numberClassName="score-table__number" score={round(ratings[key], 2)} />
          </div>
        ))}
      </div>
    </div>
  );
};

ScoresTable.propTypes = {
  ratings: PropTypes.shape({
    companyCulture: PropTypes.number.isRequired,
    compensationBenefits: PropTypes.number.isRequired,
    management: PropTypes.number.isRequired,
    product: PropTypes.number.isRequired,
    workLifeBalance: PropTypes.number.isRequired,
  }).isRequired,
};
