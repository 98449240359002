import React, { FC, useEffect } from 'react';
import { OpeningCardScroller } from '@src/containers/OpeningCardScroller';
import { FILLED_ROUTE } from '@src/constants/routes';
import {
  GetSearchOpeningsLatestFeedQueryVariables,
  GetSearchRelevantOpeningsQueryVariables,
  OpeningSearchEntitySortOptionEnum,
  SearchOpeningEntityTagNameEnum,
  useGetSearchOpeningsLatestFeedLazyQuery,
  useGetSearchRelevantOpeningsLazyQuery,
} from '@src/graphql/generated';
import createLink from '@src/util/createLink';
import { isOpeningType } from '@src/graphql/typeGuards';
import { ActiveFeed } from '../enums/activeFeed.enum';
import { FilledOpeningsScrollerProps } from './props';

const BATCH_SIZE = 6;

export const FilledOpeningsScroller: FC<FilledOpeningsScrollerProps> = ({
  searchTags,
  searchTagsWithOrCondition,
  activeFeed,
  activeSpecialityId,
}) => {
  const isSearchRelevantOpeningsFeedActive = activeFeed === ActiveFeed.SEARCH_RELEVANT_OPENINGS;

  const [
    fetchSearchRelevantOpenings,
    {
      data: searchRelevantOpeningsData,
      loading: isSearchRelevantOpeningsLoading,
      refetch: searchRelevantOpeningsRefetch,
    },
  ] = useGetSearchRelevantOpeningsLazyQuery();

  const [
    fetchSearchOpeningsLatestFeed,
    { data: searchOpeningsData, loading: isSearchOpeningsLoading, refetch: searchOpeningsRefetch },
  ] = useGetSearchOpeningsLatestFeedLazyQuery();

  useEffect(() => {
    const getSearchRelevantOpeningsQueryVariables: GetSearchRelevantOpeningsQueryVariables = {
      searchTags: [
        ...(searchTags || []),
        {
          tag: SearchOpeningEntityTagNameEnum.STATE,
          value: 'inactive',
        },
      ],
      searchTagsWithOrCondition: searchTagsWithOrCondition,
      sortedPaginationOptions: {
        limit: BATCH_SIZE,
      },
    };

    const getSearchOpeningsLatestFeedQueryVariables: GetSearchOpeningsLatestFeedQueryVariables = {
      paginationOptions: {
        limit: BATCH_SIZE,
        sortBy: OpeningSearchEntitySortOptionEnum.RECENCY_AND_RELEVANCY,
      },
      searchParams: {
        searchTagsWithAndCondition: [
          ...(searchTags || []),
          ...(activeSpecialityId
            ? [
                {
                  tag: SearchOpeningEntityTagNameEnum.SPECIALITY,
                  value: activeSpecialityId,
                },
              ]
            : []),
          {
            tag: SearchOpeningEntityTagNameEnum.STATE,
            value: 'inactive',
          },
        ],
        searchTagsWithOrCondition: searchTagsWithOrCondition,
      },
    };

    if (isSearchRelevantOpeningsFeedActive) {
      if (searchRelevantOpeningsData) {
        fetchSearchRelevantOpenings({
          variables: getSearchRelevantOpeningsQueryVariables,
        });

        return;
      }

      searchRelevantOpeningsRefetch(getSearchRelevantOpeningsQueryVariables);

      return;
    }

    if (searchOpeningsData) {
      fetchSearchOpeningsLatestFeed({
        variables: getSearchOpeningsLatestFeedQueryVariables,
      });
    }

    searchOpeningsRefetch(getSearchOpeningsLatestFeedQueryVariables);
  }, [
    isSearchRelevantOpeningsFeedActive,
    activeSpecialityId,
    searchRelevantOpeningsData,
    searchOpeningsData,
    searchTags,
    searchTagsWithOrCondition,
  ]);

  const isLoading = isSearchRelevantOpeningsLoading || isSearchOpeningsLoading;

  const feed =
    (isSearchRelevantOpeningsFeedActive
      ? searchRelevantOpeningsData?.searchRelevantOpenings?.searchResults.result
      : searchOpeningsData?.searchOpenings.result) || [];

  const filteredFeed = feed.filter(isOpeningType);

  const to = createLink(FILLED_ROUTE, {
    queryParams: {
      searchTags: searchTags,
      searchTagsWithOrCondition: searchTagsWithOrCondition,
    },
  });

  return (
    <OpeningCardScroller
      isLoading={isLoading}
      items={filteredFeed}
      rightIconLabel="See all"
      title="Filled openings"
      to={to}
    />
  );
};
