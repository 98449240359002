import PropTypes from 'prop-types';
import classnames from 'classnames';
import React, { Component } from 'react';
import { isCrawler } from '@src/util/isCrawler';
import './styles.scss';

export default class FadeInImage extends Component {
  static propTypes = {
    className: PropTypes.string,
    gradientClassName: PropTypes.string,
    imageClassName: PropTypes.string,
    noBorder: PropTypes.bool,
    onClick: PropTypes.func,
    setRef: PropTypes.func,
    src: PropTypes.string,
    style: PropTypes.object,
    withGradient: PropTypes.bool,
    wrapperClassName: PropTypes.string,
  };

  state = {
    loaded: false,
  };

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.src !== nextProps.src ||
      this.props.className !== nextProps.className ||
      this.props.style !== nextProps.style ||
      this.state.loaded !== nextState.loaded
    );
  }

  setLoaded = () => {
    this.setState({ loaded: true });
  };

  render() {
    const {
      noBorder,
      src,
      className,
      style,
      setRef,
      wrapperClassName,
      imageClassName,
      gradientClassName,
      withGradient,
      onClick,
    } = this.props;
    const { loaded } = this.state;

    if ('objectFit' in document.documentElement.style === false) {
      return (
        <div
          className={classnames(
            'ui-fadein-image',
            (isCrawler() || loaded) && 'loaded', // show images on load for bots, for better SEO
            noBorder && 'no-border',
            className,
            wrapperClassName,
          )}
          onClick={onClick}
          ref={setRef}
          style={style}
        >
          <img
            className={classnames('ui-fadein-image__image', className, imageClassName)}
            onLoad={this.setLoaded}
            style={{
              backgroundImage: src ? urlify(src) : null,
              backgroundPosition: 'center center',
              backgroundSize: 'cover',
            }}
          />
          {withGradient && <span className={classnames('ui-fadein-image__gradient', gradientClassName)} />}
        </div>
      );
    } else {
      return (
        <div
          className={classnames(
            'ui-fadein-image',
            loaded && 'loaded',
            noBorder && 'no-border',
            className,
            wrapperClassName,
          )}
          onClick={onClick}
          ref={setRef}
          style={style}
        >
          <img
            className={classnames('ui-fadein-image__image', className, imageClassName)}
            onLoad={this.setLoaded}
            src={src}
          />
          {withGradient && <span className={classnames('ui-fadein-image__gradient', gradientClassName)} />}
        </div>
      );
    }
  }
}

function urlify(url) {
  return 'url("' + url + '")';
}
