import { ApolloLink } from '@apollo/client';
import { getToken } from '../utils/getToken';
import { isTokenExpired } from '../utils/isTokenExpired';
import { refreshTokenAndRetry } from '../utils/refreshTokenAndRetry';
import { doesRequireAuth } from '../utils/doesRequireAuth';

export const authMiddlewareLink: ApolloLink = new ApolloLink((operation, forward) => {
  const accessToken = getToken();

  if (!accessToken && doesRequireAuth(operation)) {
    throw new Error('Missing access token for non-public operation');
  }

  if (accessToken && isTokenExpired(accessToken)) {
    return refreshTokenAndRetry(operation, forward);
  }

  return forward(operation);
});
