import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, get } from 'lodash';
import { AboutTitle } from '../AboutTitle';
import { AboutText } from '../AboutText';
import './styles.scss';

export const Clients = ({ clients, clientsDescription }) => {
  if (!clientsDescription && isEmpty(clients)) {
    return null;
  }

  return (
    <div className="company-profile-clients">
      <AboutTitle>Clients</AboutTitle>

      <AboutText text={clientsDescription} />

      <div className="company-profile-clients__scroller-wrapper">
        <div className="company-profile-clients__scroller">
          {clients.map(client => (
            <img className="company-profile-clients__item" key={client.id} src={get(client, 'logo.fullUrl')} />
          ))}
        </div>
      </div>
    </div>
  );
};

Clients.propTypes = {
  clients: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      logo: PropTypes.shape({
        fullUrl: PropTypes.string.isRequired,
      }),
    }),
  ),
  clientsDescription: PropTypes.string,
};
