import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { AboutTitle } from '../AboutTitle';
import { AboutText } from '../AboutText';
import { Images } from '../Images';
import './styles.scss';

export const Product = ({ description, productImages }) => {
  if (!description && !isEmpty(productImages)) {
    return null;
  }

  return (
    !!description && (
      <div className="company-profile-product">
        <AboutTitle className="company-profile-product__title">Product</AboutTitle>

        <AboutText text={description} />

        <Images images={productImages} />
      </div>
    )
  );
};

Product.propTypes = {
  description: PropTypes.string,
  productImages: PropTypes.arrayOf(PropTypes.object),
};
