import React, { FC } from 'react';
import { Bookmark } from './Bookmark';
import { Apply } from './Apply';
import { OpeningActionButtonsProps } from './props';
import './styles.scss';

export const OpeningActionButtons: FC<OpeningActionButtonsProps> = ({
  openingId,
  specialityId,
  hiringState,
  candidateDestination,
  onApply,
  matchIntroState,
  hasBookmarked,
  onBookmark,
}) => {
  return (
    <div className="opening-page-action-buttons">
      <div className="opening-page-action-buttons__button">
        <Bookmark hasBookmarked={hasBookmarked} onBookmark={onBookmark} />
      </div>

      <div className="opening-page-action-buttons__button">
        <Apply
          candidateDestination={candidateDestination}
          hiringState={hiringState}
          matchIntroState={matchIntroState}
          onApply={onApply}
          openingId={openingId}
          specialityId={specialityId}
          wrapperClassName="opening-page-action-buttons__apply"
        />
      </div>
    </div>
  );
};
