import React, { FC } from 'react';
import classnames from 'classnames';
import { Avatar } from '@src/components/Avatar';
import FadeInImage from '@src/components/FadeInImage';
import { Props } from './props';
import './styles.scss';

export const MessageHeader: FC<Props> = ({ wrapperClassName, title, subTitle, avatarProps, fadeInImageProps }) => {
  return (
    <div className={classnames('message-header', wrapperClassName)}>
      <div className="message-header__images">
        <div className="message-header__avatar">
          <Avatar size="x-large" {...avatarProps} />
        </div>

        {fadeInImageProps && <FadeInImage {...fadeInImageProps} className="message-header__company-logo" />}
      </div>
      <div
        className={classnames('message-header__details', !!fadeInImageProps && 'message-header__details--with-badge')}
      >
        <p>{title}</p>
        <p>{subTitle}</p>
      </div>
    </div>
  );
};
