import React, { FC } from 'react';
import { OpeningCardScroller } from '@src/containers/OpeningCardScroller';
import { FILLED_ROUTE } from '@src/constants/routes';
import { SearchOpeningEntityTagNameEnum, useSearchOpeningsForHorizontalScrollerQuery } from '@src/graphql/generated';
import createLink from '@src/util/createLink';
import { isOpeningType } from '@src/graphql/typeGuards';
import { Props } from './props';

export const FilledOpeningsScroller: FC<Props> = ({ searchTags, companyId }) => {
  const { loading: isLoading, data } = useSearchOpeningsForHorizontalScrollerQuery({
    variables: {
      paginationOptions: {
        limit: 5,
      },
      searchParams: {
        searchTagsWithAndCondition: [
          ...(searchTags || []),
          { tag: SearchOpeningEntityTagNameEnum.STATE, value: 'inactive' },
          { tag: SearchOpeningEntityTagNameEnum.COMPANY, value: companyId },
        ],
      },
    },
  });

  const items = (data?.searchOpenings.result || []).filter(isOpeningType);

  const to = createLink(FILLED_ROUTE, {
    queryParams: {
      searchTags: [...(searchTags || []), { tag: SearchOpeningEntityTagNameEnum.COMPANY, value: companyId }],
    },
  });

  return (
    <OpeningCardScroller isLoading={isLoading} items={items} rightIconLabel="See all" title="Filled openings" to={to} />
  );
};
