import api from '@src/util/api';
import { registerForPush, requestAppStoreReview } from '@src/container';

export function nextQuestion() {
  return api('/api/app/v2/next');
}

export function sendResponse(questionId, data) {
  return api(`/api/app/${questionId}/response`, {
    json: data,
    method: 'POST',
  });
}

export function handleSpecialResponse(question, data) {
  const { key } = question;

  if (key === 'ios-enable-push') {
    const { answer } = data;
    if (answer.type === 'positive') {
      registerForPush();
    }
  } else if (key === 'rating-open-appstore') {
    const { answer } = data;
    switch (answer.action) {
      case 'rate-app-googleplay':
        window.open('https://play.google.com/store/apps/details?id=com.meetfrank.android', '_blank');
        break;
      case 'rate-app-appstore':
        requestAppStoreReview();
        break;
    }
  }
}
