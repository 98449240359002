import React, { FC } from 'react';
import { SelectProps } from './props';
import './styles.scss';

export const Select: FC<SelectProps> = ({ options, selectedValue, onChange }) => {
  return (
    <label className="frank-select">
      <select
        onChange={evt => {
          const value = evt.target.value;
          const selectedOption = options.find(option => option.value === value);

          if (selectedOption) {
            onChange(selectedOption);
          }
        }}
        value={selectedValue?.value}
      >
        {options.map(option => (
          <option key={option.key} value={option.value}>
            {option.text}
          </option>
        ))}
      </select>

      <span>{selectedValue?.text}</span>
    </label>
  );
};
