import React, { FC } from 'react';
import Skeleton from './Skeleton';
import { TitleProps } from './props';
import './styles.scss';

export const Title: FC<TitleProps> = ({ title, subTitle, isLoading }) => {
  if (isLoading) {
    return <Skeleton />;
  }

  return (
    <div className="company-profile-title">
      <h3>{title}</h3>
      <p>{subTitle}</p>
    </div>
  );
};
