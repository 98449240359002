import React, { FC } from 'react';
import { OpeningCardScroller } from '@src/containers/OpeningCardScroller';
import { FILLED_ROUTE } from '@src/constants/routes';

import { isOpeningType } from '@src/graphql/typeGuards';
import { useQueryParams } from '@src/customHooks/useQueryParams';
import { OPENING_STATE } from '@src/constants';
import { QueryParams } from '../interfaces/queryParams.interface';
import { useSearchOpenings } from '../hooks/useSearchOpenings';

export const FilledOpeningsScroll: FC = () => {
  const { queryParams } = useQueryParams<QueryParams>();

  const { openings, isOpeningsInitialLoading } = useSearchOpenings({
    categoryType: queryParams.category,
    openingState: OPENING_STATE.INACTIVE,
    places: queryParams.places,
    searchPattern: queryParams.searchPattern,
  });

  const filteredFeed = openings.filter(isOpeningType);

  return (
    <OpeningCardScroller
      isLoading={isOpeningsInitialLoading}
      items={filteredFeed}
      rightIconLabel="See all"
      title="Filled openings"
      to={FILLED_ROUTE}
    />
  );
};
