import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Notification from './Notification';
import './styles.scss';

const NotificationGroup = ({ notifications }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const isRead = notifications.some(({ readAt }) => !!readAt);

  return (
    <div className={classnames({ 'notification-group': true, 'notification-group--unread': !isRead })}>
      <Notification notification={notifications[0]} />

      {notifications.length > 1 && (
        <p className="notification-group__collapse-button" onClick={() => setIsCollapsed(!isCollapsed)}>
          {isCollapsed ? `show ${notifications.length - 1} more` : `show ${notifications.length - 1} less`}
        </p>
      )}
      {notifications.length > 1 &&
        !isCollapsed &&
        notifications.slice(1).map(notification => {
          return <Notification key={notification.id} notification={notification} />;
        })}
    </div>
  );
};

NotificationGroup.propTypes = {
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      application: PropTypes.shape({
        opening: PropTypes.shape({
          recruiter: PropTypes.shape({
            id: PropTypes.string,
            nameInitials: PropTypes.string,
          }),
        }),
      }),
      icons: PropTypes.shape({
        primary: PropTypes.shape({
          fullUrl: PropTypes.string,
          id: PropTypes.string,
        }).isRequired,
        secondary: PropTypes.shape({
          fullUrl: PropTypes.string,
          id: PropTypes.string,
        }),
      }),
      id: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
      opening: PropTypes.shape({
        recruiter: PropTypes.shape({
          id: PropTypes.string,
          nameInitials: PropTypes.string,
        }),
      }),
      readAt: PropTypes.string,
      sentAt: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      url: PropTypes.string,
    }),
  ).isRequired,
};

export default NotificationGroup;
