import React, { Component } from 'react';
import { first, find, values, trim, size, isArray, get, debounce } from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Input from '@src/components/Input';
import ChatInput from '@src/components/ChatInput';
import SearchInputWithChips from '@src/containers/SearchInputWithChips';
import SelectList from '@src/components/SelectList';
import Buttons from '@src/components/Buttons';
import { TreeSelect } from '@src/components/TreeSelect';
import Scroller from '@src/components/Scroller';
import Salary from '@src/components/Salary';
import Title from '@src/components/Title';
import Subtitle from '@src/components/Subtitle';
import ChatBubble from '@src/components/ChatBubble';
import OverviewHeader from '@src/components/OverviewHeader';
import Textarea from '@src/components/Textarea';
import ChatTextarea from '@src/components/ChatTextarea';
import ChatsList from '@src/components/ChatsList';
import { DigestPreview } from '@src/components/DigestPreview';
import { LocationAutocomplete } from '@src/components/LocationAutocomplete';
import Continue from '@src/components/Continue';
import { FrankAvatar } from '@src/components/FrankAvatar';
import { CountryHeader } from '@src/components/CountryHeader';
import PhoneNumber from '@src/components/PhoneNumber';
import { InvisibleButton } from '@src/components/Button';
import { SwitchToggle } from '@src/components/SwitchToggle';
import { PhoneCountrySelector } from '@src/containers/PhoneCountrySelector';
import { DynamicInput } from '@src/components/DynamicInput';
import { isValidEmail } from '@src/util/email';
import { MessageHeader } from '@src/components/MessageHeader';
import './styles.scss';

export default class Card extends Component {
  static propTypes = {
    action: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
    className: PropTypes.string,
    content: PropTypes.arrayOf(PropTypes.object).isRequired,
    defaultValues: PropTypes.object,
    disableActionAnimation: PropTypes.bool,
    errorMessage: PropTypes.string,
    isDarkModeOn: PropTypes.bool,
    noAvatar: PropTypes.bool,
    onClose: PropTypes.func,
    onContinue: PropTypes.func,
    onInputChange: PropTypes.func,
    onLocationSelect: PropTypes.func,
    onValueSelect: PropTypes.func,
    recruiter: PropTypes.object,
    recruiterTitle: PropTypes.string,
    selectedValue: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    sendResponse: PropTypes.func,
    setContentRef: PropTypes.func,
    setOpeningListRef: PropTypes.func,
    setScrollRef: PropTypes.func,
    showRecruiterAvatar: PropTypes.bool,
    shownChildIndex: PropTypes.number.isRequired,
    user: PropTypes.object,
    withChildrenAnimation: PropTypes.bool,
  };

  setScrollRef = it => {
    const { setScrollRef } = this.props;
    setScrollRef && setScrollRef(it);
    this.scrollRef = it;
  };

  setOpeningListRef = (it, key) => {
    const { setOpeningListRef } = this.props;
    setOpeningListRef && setOpeningListRef(it, key);
  };

  onInputFocus = () => {
    if (this.scrollRef) {
      const scrollable = this.scrollRef;
      setTimeout(function () {
        scrollable.scrollTop = scrollable.scrollHeight;
      }, 200);
    }
  };

  shouldDisableContinueButton = (selectedValue, action) => {
    if (get(action, 'selectedValueLimit')) {
      return size(trim(first(values(selectedValue)))) < action.selectedValueLimit;
    }

    return (
      !selectedValue ||
      size(trim(first(values(selectedValue)))) === 0 ||
      (selectedValue.email && !isValidEmail(selectedValue.email))
    );
  };

  handleContinueClick = debounce(() => {
    this.props.onContinue();
  }, 200);

  render() {
    const {
      content,
      action,
      selectedValue,
      className,
      onInputChange,
      sendResponse,
      onLocationSelect,
      onValueSelect,
      defaultValues = {},
      setContentRef,
      shownChildIndex,
      noAvatar,
      withChildrenAnimation,
      showRecruiterAvatar,
      recruiter,
      recruiterTitle,
      disableActionAnimation,
      isDarkModeOn,
      onClose,
      errorMessage,
      user,
    } = this.props;

    const avatarUrl = recruiter?.avatarUrl;
    const avatarColor = recruiter?.avatarColor;
    const fullName = recruiter?.fullName;
    const title = recruiter?.title;
    const userSpecialityId = user?.profile?.speciality.id;

    return (
      <div
        className={classnames('content-push-card', className, isDarkModeOn && 'content-push-card--dark-mode')}
        ref={setContentRef}
      >
        <div className="content-push-card-content" ref={this.setScrollRef}>
          {!noAvatar &&
            (showRecruiterAvatar ? (
              <MessageHeader
                avatarProps={{
                  avatarColor: avatarColor,
                  initials: first(fullName),
                  src: avatarUrl,
                }}
                subTitle={recruiterTitle || title}
                title={fullName}
                wrapperClassName="content-push-card__recruiter-avatar shown"
              />
            ) : (
              <FrankAvatar />
            ))}
          {content.map((row, i) => {
            const { type, value, key, delayClass, getPlaceHolder } = row;
            const shownClass = i < shownChildIndex ? 'shown' : '';
            const delayIndex = i + 1;

            const childrenAnimationClass = withChildrenAnimation
              ? `slide-up-item ${delayClass || `delay-${delayIndex}`}`
              : '';

            switch (type) {
              case 'title':
              case 'title-large':
                return (
                  <Title
                    className={classnames(shownClass, type === 'title-large' && 'large', childrenAnimationClass)}
                    key={i}
                    value={value}
                  />
                );
              case 'subtitle':
              case 'subtitle-light':
                return (
                  <Subtitle
                    className={classnames(shownClass, type === 'subtitle-light' && 'light', childrenAnimationClass)}
                    key={i}
                    value={value}
                  />
                );
              case 'chat-title':
              case 'chat-bubble':
              case 'chat-subtitle-light':
                return <ChatBubble className={classnames(shownClass, childrenAnimationClass)} key={i} value={value} />;
              case 'email':
              case 'chat-input':
                return (
                  <div
                    className={classnames('content-push-card-chat-input', shownClass, childrenAnimationClass)}
                    key={i}
                  >
                    <ChatInput
                      defaultValue={defaultValues[key]}
                      onChange={value => onInputChange(key, value)}
                      onFocus={this.onInputFocus}
                      placeholder={value}
                      type={row.inputType}
                    />
                    <div
                      className={classnames(
                        'content-push-card__error-message',
                        !!errorMessage && 'content-push-card__error-message--active',
                      )}
                    >
                      {errorMessage}
                    </div>
                  </div>
                );
              case 'input':
                return (
                  <div className={classnames('content-push-card-input', shownClass, childrenAnimationClass)} key={i}>
                    <Input
                      defaultValue={defaultValues[key]}
                      onChange={value => onInputChange(key, value)}
                      placeholder={value}
                    />
                  </div>
                );
              case 'phone-dial-code':
                return (
                  <div
                    className={classnames(
                      'content-push-card-chat-input',
                      'content-push-card__phone-wrapper',
                      shownClass,
                      childrenAnimationClass,
                    )}
                    key={i}
                  >
                    <PhoneCountrySelector
                      defaultSelectedOptionId={get(defaultValues[key], 'country')}
                      onChange={value => onInputChange('dialCode', value)}
                    />
                    <ChatInput
                      defaultValue={get(defaultValues[key], 'number')}
                      inputClassName="content-push-card__phone-input"
                      onChange={value => onInputChange(key, value)}
                      onFocus={this.onInputFocus}
                      placeholder={value}
                      type={row.inputType}
                    />
                  </div>
                );
              case 'location-autocomplete':
                return (
                  <LocationAutocomplete
                    className={classnames(shownClass, childrenAnimationClass)}
                    defaultValue={defaultValues[key]}
                    key={i}
                    onFocus={this.onInputFocus}
                    selectLocation={onLocationSelect}
                  />
                );
              case 'select':
                return (
                  <SelectList
                    className={classnames(shownClass, childrenAnimationClass)}
                    defaultValue={defaultValues[key]}
                    identifier={key}
                    key={i}
                    onSelect={row.autoContinue ? onValueSelect : sendResponse}
                    options={value}
                  />
                );
              case 'treeselect': {
                return (
                  <TreeSelect
                    className={classnames(shownClass, childrenAnimationClass)}
                    key={i}
                    limit={row.limit}
                    onValueSelect={newValue => onValueSelect({ [key]: [newValue] })}
                    options={value}
                    selectedOptions={first(values(selectedValue))}
                  />
                );
              }

              case 'scroller':
                return (
                  <Scroller
                    className={classnames(shownClass, childrenAnimationClass)}
                    defaultValue={defaultValues[key] ? find(value, ['key', defaultValues[key].key]) : undefined}
                    identifier={key}
                    key={i}
                    onValueSelect={onValueSelect}
                    options={value}
                  />
                );
              case 'salaryinput':
                return (
                  <Salary
                    className={classnames(shownClass, childrenAnimationClass)}
                    defaultValue={row.defaultValue || defaultValues[key]}
                    identifier={key}
                    key={i}
                    onValueSelect={onValueSelect}
                    values={value}
                  />
                );
              case 'country-header':
                return (
                  <CountryHeader className={classnames(shownClass, childrenAnimationClass)} key={i} value={value} />
                );
              case 'overview-header':
                return (
                  <OverviewHeader className={classnames(shownClass, childrenAnimationClass)} key={i} value={value} />
                );
              case 'chat-textarea': {
                const placeholder =
                  key === 'workExperience' && getPlaceHolder ? getPlaceHolder(userSpecialityId) : value;

                return (
                  <div
                    className={classnames('content-push-card-chat-input', shownClass, childrenAnimationClass)}
                    key={i}
                  >
                    <ChatTextarea
                      defaultValue={defaultValues[key] ? defaultValues[key] : ''}
                      disabled={!onInputChange}
                      minRows={4}
                      onChange={value => onInputChange(key, value)}
                      placeholder={placeholder}
                      textLimit={get(row, 'textLimit')}
                    />
                  </div>
                );
              }

              case 'textarea':
                return (
                  <div className={classnames('content-push-card-input', shownClass, childrenAnimationClass)} key={i}>
                    <Textarea
                      defaultValue={defaultValues[key] ? defaultValues[key] : ''}
                      onChange={value => onInputChange(key, value)}
                      placeholder={value}
                    />
                  </div>
                );
              case 'inbox':
                return <ChatsList chats={value} className={classnames(shownClass, childrenAnimationClass)} key={i} />;
              case 'digest':
                return (
                  <DigestPreview className={classnames(shownClass, childrenAnimationClass)} key={i} value={value} />
                );
              case 'phone':
                return (
                  <PhoneNumber
                    className={classnames(shownClass, childrenAnimationClass)}
                    key={i}
                    onChange={value => onInputChange(key, value)}
                    value={value}
                  />
                );
              case 'button-custom-action':
                return (
                  <Buttons
                    className={classnames(shownClass, childrenAnimationClass)}
                    identifier={key}
                    key={i}
                    onSelect={value.action}
                    options={[value]}
                  />
                );
              case 'buttons':
              case 'buttons-horizontal':
                return (
                  <Buttons
                    className={classnames(
                      shownClass,
                      childrenAnimationClass,
                      type === 'buttons-horizontal' && 'horizontal',
                    )}
                    hasValue={!!selectedValue}
                    identifier={key}
                    key={i}
                    onSelect={sendResponse}
                    options={value}
                  />
                );
              case 'dynamic-input':
                return (
                  <DynamicInput
                    key={i}
                    onChange={value => onInputChange(key, value)}
                    placeholder={value}
                    selectedValue={first(values(selectedValue))}
                    wrapperClassName={classnames('content-push-card-chat-input', shownClass, childrenAnimationClass)}
                  />
                );
              case 'search-input':
              case 'search-input-single': {
                const isSingleSelect = type === 'search-input-single';

                return (
                  <SearchInputWithChips
                    isDarkModeOn={isDarkModeOn}
                    isSingleSelect={isSingleSelect}
                    key={i}
                    onSelect={isSingleSelect ? sendResponse : onValueSelect}
                    searchInputWithSuggestionsProps={{
                      placeholder: 'Type to search…',
                    }}
                    selectedValue={first(values(selectedValue))}
                    wrapperClassName={classnames(
                      `search-input-with-chips--${isDarkModeOn ? 'dark' : 'light'}`,
                      'content-push-card-chat-input',
                      shownClass,
                      childrenAnimationClass,
                    )}
                  />
                );
              }

              case 'toggle': {
                return (
                  <div className={classnames('toggle-wrapper', shownClass, childrenAnimationClass)} key={i}>
                    <span className="toggle-wrapper__label">{value}</span>
                    <SwitchToggle checked={defaultValues[key]} onChange={value => onInputChange(key, value)} />
                  </div>
                );
              }
            }
          })}
        </div>

        {action && (
          <div
            className={classnames(
              'content-push-card__actions fade-in-item',
              !disableActionAnimation && (action.delayClass || `delay-${content.length + 2}`),
            )}
          >
            {isArray(action) ? (
              action.map((action, index) => {
                const { type, value, active, className, selectedValueLimit } = action;

                if (type === 'continue') {
                  const isDisabledByLimit =
                    !!selectedValueLimit &&
                    typeof selectedValue === 'string' &&
                    selectedValue.trim().length < selectedValueLimit &&
                    !active;
                  const isDisabled =
                    typeof action.isEnabled === 'boolean'
                      ? !action.isEnabled
                      : isDisabledByLimit ||
                        ((!selectedValue || size(trim(first(values(selectedValue)))) === 0) && !active);

                  return (
                    <Continue
                      action={{ active, type, value }}
                      className={className}
                      disabled={isDisabled}
                      key={index}
                      onClick={this.handleContinueClick}
                      selectedValue={selectedValue}
                    />
                  );
                }

                return (
                  <InvisibleButton className={className} disabled={!active} key={index} onClick={() => onClose(action)}>
                    {value}
                  </InvisibleButton>
                );
              })
            ) : (
              <Continue
                action={action}
                className={get(action, 'className', '')}
                disabled={this.shouldDisableContinueButton(selectedValue, action) && !action.active}
                key="continue"
                onClick={this.handleContinueClick}
                selectedValue={selectedValue}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}
