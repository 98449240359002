import React, { Fragment, forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, get } from 'lodash';
import { useLazyQuery } from '@apollo/client';
import { JobOpeningFeedItem } from '@src/containers/Feed/JobOpeningFeedItem';
import { FeedItemSkeleton } from '@src/containers/Feed/Skeleton';
import { EmptyState } from '@src/components/EmptyState';
import FadeInImage from '@src/components/FadeInImage';
import { GET_LOCATION_BY_ID } from '@src/graphql/locations/queries/location';
import { SEARCH_TAGS } from '@src/constants/opening';
import { countryFlagUrl } from '@src/util/countryFlagImageUrl';
import { OpeningRemoteTypeEnum, SearchOpeningEntityTagNameEnum } from '@src/graphql/generated';
import './styles.scss';

export const LatestOpeningsFeed = forwardRef(
  ({ feed, loading, isRefetchLoading, searchTags, onRemoteButtonClick }, ref) => {
    const [mainLoading, setMainLoading] = useState(true);
    const isRemote = searchTags.find(
      searchTag =>
        searchTag.tag === SearchOpeningEntityTagNameEnum.REMOTE_TYPE && searchTag.value === OpeningRemoteTypeEnum.FULLY,
    );

    const [fetchLocation, { data: locationData }] = useLazyQuery(GET_LOCATION_BY_ID, {
      fetchPolicy: 'network-only',
      onCompleted: () => {
        setMainLoading(false);
      },
      onError: () => {
        setMainLoading(false);
      },
    });

    useEffect(() => {
      if (isRemote) {
        setMainLoading(false);
      }
    }, [isRemote]);

    useEffect(() => {
      if (!isEmpty(searchTags)) {
        const location = searchTags.find(searchTag => searchTag.tag === SEARCH_TAGS.POSITION_LOCATION);

        if (!isEmpty(location) && location.value) {
          fetchLocation({
            variables: {
              id: location.value,
            },
          });
        }
      }
    }, [searchTags]);

    const isLoading = loading || mainLoading;

    if (!loading && isEmpty(feed)) {
      return (
        <div className="search-results-feed fade-in">
          <div className="search-results-feed__empty-state">
            <EmptyState
              imageUrl="/images/empty-states/empty-state-1.png"
              imageWrapperClassName="search-results-feed__empty-state-image"
              infoClassName="search-results-feed__empty-state-content"
              subTitle="There aren’t any offers for this location at the moment. Please try selecting other options."
              subTitleClassName="search-results-feed__empty-state-subtitle"
              title="No active offers at the moment"
              titleClassName="search-results-feed__empty-state-title"
            />
          </div>
        </div>
      );
    }

    const renderTitle = () => {
      if (isRemote) {
        return <span className="search-results-feed__title">Latest offers</span>;
      } else {
        const flagUrl = countryFlagUrl(get(locationData, 'location.country.shortName'));

        return (
          <Fragment>
            <span className="search-results-feed__title">
              Latest offers in
              <FadeInImage imageClassName="search-results-feed__flag" src={flagUrl} />
              {get(locationData, 'location.shortName')}
            </span>
            <span className="search-results-feed__remote-switch" onClick={onRemoteButtonClick}>
              🌎 View similar remote jobs →
            </span>
          </Fragment>
        );
      }
    };

    return (
      <div className="search-results-feed fade-in" ref={ref}>
        <div className="search-results-feed__title-wrapper">
          {isLoading ? (
            <Fragment>
              <span className="search-results-feed__title-skeleton pulse-fade" />
              <span className="search-results-feed__sub-title-skeleton pulse-fade" />
            </Fragment>
          ) : (
            renderTitle()
          )}
        </div>

        <div className="search-results-feed__feed feed-bg-color">
          {!isRefetchLoading &&
            feed.map(opening => (
              <JobOpeningFeedItem
                key={opening.id}
                metricsContext="Group"
                opening={opening}
                wrapperClassName="search-results-feed__item"
              />
            ))}

          {isLoading && <FeedItemSkeleton />}
        </div>
      </div>
    );
  },
);

LatestOpeningsFeed.propTypes = {
  feed: PropTypes.arrayOf(PropTypes.object),
  isRefetchLoading: PropTypes.bool,
  loading: PropTypes.bool,
  onRemoteButtonClick: PropTypes.func.isRequired,
  searchTags: PropTypes.array.isRequired,
};
