const TWITTER = '((https?://)?(www.)?twitter.com/)?(@|#!/)?([A-Za-z0-9_]{1,15})(/([-a-z]{1,20}))?';
const LINKEDIN = '((https?://)?(www.)?linkedin.com/company/)?([A-Za-z0-9-_.]{1,30})?';
const FACEBOOK = '((https?://)?(www.)?(facebook|fb).com/)?([A-Za-z0-9-_.]{1,30})?';
const INSTAGRAM = '((https?://)?(www.)?instagram.com/)?([A-Za-z0-9-_.]{1,30})?';

export default function (url, source) {
  let matched;

  switch (source) {
    case 'twitter':
      matched = url.match(TWITTER);

      return matched ? matched[5] : undefined;
    case 'linkedin':
      matched = url.match(LINKEDIN);

      return matched ? matched[4] : undefined;
    case 'facebook':
      matched = url.match(FACEBOOK);

      return matched ? matched[5] : undefined;
    case 'instagram':
      matched = url.match(INSTAGRAM);

      return matched ? matched[4] : undefined;
  }
}
