import React, { FC } from 'react';
import classnames from 'classnames';
import { Button } from './Button';
import { ButtonProps } from './props';

export const BlueButton: FC<ButtonProps> = ({ children, className, isFilled, ...props }) => {
  return (
    <Button className={classnames(className, 'fr-button--blue', isFilled && 'fr-button--blue--filled')} {...props}>
      {children}
    </Button>
  );
};
