export const views = {
  FEEDBACK: 'FEEDBACK',
  HIRED_BY_COMPANY: 'HIRED_BY_COMPANY',
  OPTIONS: 'OPTIONS',
};

export const QUESTIONS = {
  [views.OPTIONS]: {
    action: { active: false, type: 'continue', value: 'Next' },
    content: [
      { type: 'chat-bubble', value: 'Please give us feedback on your experience with Talent Agent.' },
      { type: 'chat-bubble', value: 'How did it go?' },
      {
        key: 'hiringOption',
        type: 'select',
        value: [],
      },
    ],
    key: 'hiringOption',
    type: 'select-option',
  },
  [views.HIRED_BY_COMPANY]: {
    action: { active: false, type: 'continue', value: 'Submit' },
    content: [
      { type: 'chat-bubble', value: 'Great to hear! 👍' },
      { type: 'chat-bubble', value: 'Which company did you join?' },
      {
        isDarkMode: true,
        key: 'hiredByCompany',
        type: 'search-input-single',
        value: 'Search by name...',
      },
    ],
    key: 'hiredByCompany',
    type: 'onboarding',
  },
  [views.FEEDBACK]: {
    action: [
      {
        active: false,
        className: 'verified-user-questionnaire__continue-button',
        selectedValueLimit: 20,
        type: 'continue',
        value: 'Submit',
      },
    ],
    content: [
      { type: 'chat-bubble', value: 'Is there any feedback you would like to leave us?' },
      {
        key: 'feedback',
        textLimit: 20,
        type: 'chat-textarea',
        value: 'Express your thoughts here...',
      },
    ],
    key: 'feedback',
    type: 'onboarding',
  },
};
