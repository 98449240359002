import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classnames from 'classnames';
import Button from '@src/components/ButtonOld';
import { htmlifyWithEscape } from '@src/util/htmlifyMessage';
import './styles.scss';

export default class Buttons extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    hasValue: PropTypes.bool,
    identifier: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
  };

  state = {
    item: undefined,
    selected: undefined,
  };

  onClick = item => {
    const { identifier, onSelect } = this.props;
    const { url } = item;

    this.setState({ selected: item.key });

    onSelect({ [identifier]: item, url });
  };

  render() {
    const { options, className, hasValue } = this.props;
    const { selected } = this.state;

    return (
      <div className={classnames('buttons-list', className)}>
        {options.map(option => (
          <Button
            className={classnames(option.type, selected && (selected === option.key ? 'selected' : 'unselected'))}
            disabled={option.requireValue && !hasValue}
            key={option.key}
            onClick={() => this.onClick(option)}
          >
            <span dangerouslySetInnerHTML={{ __html: htmlifyWithEscape(option.value) }} />
          </Button>
        ))}
      </div>
    );
  }
}
