import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import './styles.scss';

export function Hiring({ isHiring }) {
  return (
    <div className={classnames('search-results-company-hiring', isHiring && 'search-results-company-hiring--blue')}>
      <span className="search-results-company-hiring__dot" />
      {isHiring ? 'HIRING' : 'NOT HIRING'}
    </div>
  );
}

Hiring.propTypes = {
  isHiring: PropTypes.bool,
};
