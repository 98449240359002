import { MAIN_REFRESH_DONE, MAIN_REFRESH_TRIGGERED } from '@src/redux/actions/main';

export function mainRefresh(state = null, action) {
  switch (action.type) {
    case MAIN_REFRESH_TRIGGERED:
      return true;
    case MAIN_REFRESH_DONE:
      return false;
    default:
      return state;
  }
}
