import React, { FC } from 'react';
import { CompaniesBlocks } from '../CompaniesBlocks';
import { SearchedCompaniesProps } from './props';
import './styles.scss';

export const SearchedCompanies: FC<SearchedCompaniesProps> = ({ companies, isLoading, searchPattern }) => {
  return (
    <div className="searched-companies-list">
      <CompaniesBlocks companies={companies} isLoading={isLoading} />

      {!isLoading && companies.length === 0 && (
        <div className="searched-companies-list__empty-state">No results for {searchPattern}</div>
      )}
    </div>
  );
};
