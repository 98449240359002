import { get, size } from 'lodash';
import { useState } from 'react';
import { apolloClient } from '@src/graphql/apollo';
import { getPreFilledSearchTags } from '@src/util/getSearchTags';
import {
  GetSearchOpeningThumbnailsDocument,
  GetSearchOpeningThumbnailsQuery,
  GetSearchOpeningThumbnailsQueryVariables,
  OpeningSearchEntitySortOptionEnum,
  SearchOpeningEntityTagInput,
  SearchOpeningEntityTagNameEnum,
  useGetSearchOpeningThumbnailsQuery,
} from '@src/graphql/generated';
import { GetFeed } from './interfaces/getFeed.interface';
import { UseFeaturedOffers } from './interfaces/useFeaturedOffers.interface';

const FIRST_BATCH = 10;
const MIN_AMOUNT = 3;
const KEY = 'searchOpenings';

const getFeed: GetFeed = data => get(data, `${KEY}.result`) || [];

export const useFeaturedOffers: UseFeaturedOffers = ({ searchTags: providedSearchTags, searchPattern }) => {
  const [isReserveDataFetched, setIsReserveDataFetched] = useState(false);
  const searchTagsParams = getPreFilledSearchTags(providedSearchTags || []);

  const additionalSearchTags: SearchOpeningEntityTagInput[] = [
    { tag: SearchOpeningEntityTagNameEnum.STATE, value: 'active' },
    { tag: SearchOpeningEntityTagNameEnum.PAID_ADDON_TYPE, value: 'FEATURED_OPENING' },
  ];

  const {
    data,
    loading: isLoading,
    updateQuery,
  } = useGetSearchOpeningThumbnailsQuery({
    onCompleted: async responseData => {
      const feedSize = size(getFeed(responseData));

      // In case the feed is less than required limit, we need to fetch more data from the non-featured
      if (feedSize < MIN_AMOUNT) {
        const { data } = await apolloClient.query<
          GetSearchOpeningThumbnailsQuery,
          GetSearchOpeningThumbnailsQueryVariables
        >({
          query: GetSearchOpeningThumbnailsDocument,
          variables: {
            paginationOptions: {
              limit: MIN_AMOUNT - feedSize,
              sortBy: OpeningSearchEntitySortOptionEnum.RELEVANCY,
            },
            searchParams: {
              groupSearchTagsWithOrCondition: searchTagsParams.groupSearchTagsWithOrCondition,
              searchPattern: searchPattern,
              searchTagsWithAndCondition: [
                ...(searchTagsParams.searchTagsWithAndCondition || []),
                ...additionalSearchTags,
              ],
              searchTagsWithOrCondition: searchTagsParams.searchTagsWithOrCondition,
            },
          },
        });

        updateQuery(prevData => {
          return {
            ...prevData,
            [KEY]: {
              ...prevData[KEY],
              result: [...getFeed(prevData), ...getFeed(data)],
            },
          };
        });

        setIsReserveDataFetched(true);
      }
    },
    variables: {
      paginationOptions: {
        limit: FIRST_BATCH,
        sortBy: OpeningSearchEntitySortOptionEnum.RECENCY_AND_RELEVANCY,
      },
      searchParams: {
        groupSearchTagsWithOrCondition: searchTagsParams.groupSearchTagsWithOrCondition,
        searchPattern: searchPattern,
        searchTagsWithAndCondition: [...searchTagsParams.searchTagsWithAndCondition, ...additionalSearchTags],
        searchTagsWithOrCondition: searchTagsParams.searchTagsWithOrCondition,
      },
    },
  });

  return {
    feed: getFeed(data),
    isLoading: isLoading || (size(getFeed(data)) < MIN_AMOUNT && !isReserveDataFetched),
  };
};
