import React, { FC } from 'react';
import classnames from 'classnames';
import { first, last, map, max, round } from 'lodash';
import { Props } from './props';
import './styles.scss';

export const Chart: FC<Props> = ({
  isLoading,
  userSalaries,
  averageSalaryHighlightLocation,
  userSalaryHighlightLocation,
  currencySymbol,
}) => {
  const userSalaryPercentages = map(userSalaries, 'percentage');
  const maxSalary = max(userSalaryPercentages);
  const adjustCoefficient = maxSalary ? 100 / maxSalary : 0;

  return (
    <div className="frank-market-overview-salary-chart">
      {userSalaryHighlightLocation && (
        <div
          className="frank-market-overview-salary-chart__user-salary-highlight"
          style={{
            left: `calc(1.2rem * ${userSalaryHighlightLocation})`,
          }}
        >
          <div className="frank-market-overview-salary-chart__user-salary-label">You</div>
        </div>
      )}

      {map(userSalaries, (item, index) => {
        return (
          <div
            className={classnames(
              'frank-market-overview-salary-chart__bar',
              !!averageSalaryHighlightLocation &&
                averageSalaryHighlightLocation - 1 === index &&
                'frank-market-overview-salary-chart__bar--dark',
              isLoading && 'frank-market-overview-salary-chart__bar--loading pulse-fade',
            )}
            key={index}
            style={{
              height: `${round(item.percentage * adjustCoefficient)}%`,
            }}
          />
        );
      })}

      <div className="frank-market-overview-salary-chart__label frank-market-overview-salary-chart__label--left">
        {first(userSalaries)?.step}
        {currencySymbol}
      </div>

      {!!averageSalaryHighlightLocation && (
        <div
          className="frank-market-overview-salary-chart__average-salary-highlight"
          style={{
            left: `calc(1.2rem * ${averageSalaryHighlightLocation})`,
          }}
        >
          <div className="frank-market-overview-salary-chart__average-label">Average</div>
        </div>
      )}

      <div className="frank-market-overview-salary-chart__label frank-market-overview-salary-chart__label--right">
        {last(userSalaries)?.step}
        {currencySymbol}
      </div>
    </div>
  );
};
