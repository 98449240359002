import React, { FC, useRef, useState } from 'react';
import { get } from 'lodash';
import classnames from 'classnames';
import { getWrapperScrollableContainer } from '@src/App';
import { useScrollDirection } from '@src/customHooks/useScrollDirection';
import { ToggleState } from './enums/toggleState.enum';
import { BasePageHeaderProps } from './props';
import './styles.scss';

export const BasePageHeader: FC<BasePageHeaderProps> = ({
  toggleState,
  wrapperClassName,
  additionalEl,
  children,
  toggleAppBarEl,
  onBreakPointReached,
  containerClassName,
  providedTargetEl,
  isIosStatusBarShown,
  onScroll,
}) => {
  const targetEl = useRef(null);

  const [isBeforeBreakPoint, setIsBeforeBreakPoint] = useState(true);

  const getScrollableContainer = () => {
    const wrapperScrollableContainer = getWrapperScrollableContainer();

    return wrapperScrollableContainer[0];
  };

  const { scrollDirection } = useScrollDirection({
    getBreakPoint: () => get(providedTargetEl?.current || targetEl.current, 'offsetTop', 0),
    getScrollableContainerNode: () => getScrollableContainer(),
    onBreakPointReached: isAfterBreakPoint => {
      setIsBeforeBreakPoint(!isAfterBreakPoint);
      onBreakPointReached && onBreakPointReached(isAfterBreakPoint);
    },
    onScroll: onScroll,
  });

  return (
    <>
      <div
        className={classnames(
          'base-page-header-container__status-bar',
          isIosStatusBarShown && !isBeforeBreakPoint && 'base-page-header-container__status-bar--shown',
        )}
      />

      <div className={classnames('base-page-header-container', wrapperClassName)}>
        <div
          className={classnames(
            'base-page-header-container__app-bar',
            containerClassName,
            toggleState === 'app-bar' && `base-page-header-container__app-bar--${scrollDirection}`,
            isBeforeBreakPoint && 'base-page-header-container__app-bar--hide',
          )}
        >
          <div className="base-page-header-container__top-wrapper">{toggleAppBarEl}</div>

          <div
            className={classnames(
              'base-page-header-container__toggle-app-bar-el',
              toggleState === 'additional-el' && `base-page-header-container__toggle-app-bar-el--${scrollDirection}`,
            )}
          >
            {additionalEl}
          </div>
        </div>

        {children}

        <div ref={targetEl} />
      </div>
    </>
  );
};

BasePageHeader.defaultProps = {
  toggleState: ToggleState.APP_BAR,
};
