import { useEffect, useMemo } from 'react';
import {
  GetPlacesQueryVariables,
  PlaceTypeEnum,
  useGetCountriesQuery,
  useGetPlacesLazyQuery,
  useGetUserQuery,
} from '@src/graphql/generated';
import { Place } from '../../interfaces/place.interface';
import { usePlacesParams, usePlacesValue } from './interfaces/useCategories.interface';
import { isGetPlaceCountryFragmentType, isGetPlaceLocationFragmentType } from './typeGuards';

const COUNTRY_ORDER_BY_SHORT_NAME = ['EE', 'FI', 'LT', 'LV', 'SE', 'DE', 'UK', 'ES', 'PT'];

export const usePlaces = ({ searchPattern }: usePlacesParams): usePlacesValue => {
  const { data: userData } = useGetUserQuery();

  const userCountryId = userData?.currentUser?.location?.country?.id;

  const [fetchPlaces, { data: placesData, loading: isPlacesLoading, refetch: refetchPlaces }] = useGetPlacesLazyQuery();

  const { data: countriesData, loading: isCountriesLoading } = useGetCountriesQuery();

  const isLoading = isPlacesLoading || isCountriesLoading;

  const places = useMemo<Place[]>(() => {
    if (!searchPattern?.length) {
      const reversedCountries = [...(countriesData?.countries?.result || [])].reverse();

      const countryIndexMap = new Map(COUNTRY_ORDER_BY_SHORT_NAME.map((code, index) => [code, index]));

      return [...reversedCountries]
        .sort((a, b) => {
          if (a?.shortName === userCountryId) {
            return -1;
          }

          if (b?.shortName === userCountryId) {
            return 1;
          }

          const aIndex = countryIndexMap.get(a?.shortName) ?? Number.MAX_VALUE;
          const bIndex = countryIndexMap.get(b?.shortName) ?? Number.MAX_VALUE;

          return aIndex - bIndex;
        })
        .map(country => {
          return {
            countryShortName: country?.shortName,
            id: country?.id,
            name: country?.longName || country?.shortName || '',
            type: PlaceTypeEnum.COUNTRY,
          };
        });
    }

    return (
      placesData?.places?.result.map(place => {
        const country = isGetPlaceCountryFragmentType(place.entity) ? place.entity : null;
        const location = isGetPlaceLocationFragmentType(place.entity) ? place.entity : null;

        if (country) {
          return {
            countryShortName: country?.shortName,
            id: country?.id,
            name: country?.longName,
            type: PlaceTypeEnum.COUNTRY,
          };
        }

        return {
          countryShortName: location?.country?.shortName,
          id: location?.id,
          name: `${location?.shortName}, ${location?.country?.longName}`,
          type: PlaceTypeEnum.LOCATION,
        };
      }) || []
    );
  }, [placesData?.places?.result, countriesData?.countries?.result, searchPattern]);

  useEffect(() => {
    const placesVariables: GetPlacesQueryVariables = {
      paginationOptions: {
        limit: 25,
      },
      placeTypes: [PlaceTypeEnum.COUNTRY, PlaceTypeEnum.LOCATION],
      searchPattern: searchPattern,
    };

    if (searchPattern && searchPattern.length > 0) {
      fetchPlaces({
        variables: placesVariables,
      });

      return;
    }

    refetchPlaces(placesVariables);
  }, [searchPattern]);

  return {
    isLoading,
    places,
  };
};
