import React, { FC, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import Svg from 'svg-inline-react';
import { Link } from 'react-router-dom';
import LinkedinSvg from 'assets/images/fr-linkedin.svg';
import GlobeSvg from '@shared/assets/svgs/globe.svg';
import ClockSvg from 'assets/images/fr-clock.svg';
import MailSvg from 'assets/images/fr-mail.svg';
import PhoneSvg from 'assets/images/fr-phone.svg';
import SettingsSvg from 'assets/images/fr-settings.svg';
import InfoFilledSvg from 'assets/images/fr-info-filled.svg';
import { SETTINGS_ROUTE } from '@src/constants/routes';
import { trackEvent } from '@src/metrics';
import { VerifiedTalentGateway } from '@src/containers/VerifiedTalentGateway';
import { Container } from '@src/components/Container';
import { Chip } from '@src/components/Chip';
import { SubPageAppBar } from '@src/components/PrimaryAppBar';
import Item from '@src/components/AvailabilityRequest/Story/Item';
import { UserVerificationStateEnum } from '@src/graphql/generated';
import { Markdown } from '@src/components/Markdown';
import SectionHeaderWithEdit from '../SectionHeaderWithEdit';
import Footer from '../Footer';
import UserStory from '../UserStory';
import { MOCK_SWITCH_JOBS_PERIOD } from '../../steps';
import '../styles.scss';

export const EditProfile: FC = ({ currentUser, onPublish, onClose, onEditField, isLoading, onVerifiedUserUpdate }) => {
  const userVerificationState = get(currentUser, 'verification.state');
  const isDraft = userVerificationState && userVerificationState === UserVerificationStateEnum.DRAFT;
  const workExperience = get(currentUser, 'verification.workExperience');
  const positionExpectations = get(currentUser, 'verification.positionExpectations');
  const achievements = get(currentUser, 'verification.achievements');
  const blacklistedCompanies = get(currentUser, 'verification.blacklistedCompanies');
  const email = get(currentUser, 'email');
  const phone = get(currentUser, 'phone');
  const canStartIn = get(currentUser, 'verification.canStartIn');
  const message = get(currentUser, 'verification.message');
  const canStartInLabel = canStartIn && MOCK_SWITCH_JOBS_PERIOD.find(({ key }) => key === canStartIn).value;
  const linkedinUrl = (get(currentUser, 'profile.links') || []).find(({ type }) => type === 'LINKEDIN');
  const profileLinks = (get(currentUser, 'profile.links') || []).filter(({ type }) => type !== 'LINKEDIN');

  useEffect(() => {
    trackEvent({
      context: 'Verify Talent Overview',
      interaction: 'View',
    });
  }, []);

  return (
    <div className="user-verification-profile  content-enter-animation">
      <SubPageAppBar isDefaultActionsHidden isFixed onBack={onClose}>
        <div className="user-verification-profile__header-content">
          <h3 className="user-verification-profile__header-title">Profile</h3>

          <Link to={SETTINGS_ROUTE}>
            <Svg className="user-verification-profile__settings" src={SettingsSvg} />
          </Link>
        </div>
      </SubPageAppBar>

      <div className="user-verification-profile__preview-wrapper">
        <div className="user-verification-profile__vt-status-card">
          <VerifiedTalentGateway
            draftStateProps={{
              isActionButtonHidden: true,
            }}
            isLoading={isLoading}
            onVerifiedUserUpdate={onVerifiedUserUpdate}
            pendingStateProps={{
              isActionButtonHidden: true,
            }}
            publishedStateProps={{
              isActionButtonHidden: true,
            }}
            user={currentUser}
          />
        </div>

        <p className="user-verification-profile__main-title">View and edit your profile</p>

        {message && isDraft && (
          <div className="user-verification-profile__info-message-container">
            <Svg className="user-verification-profile__info-message-icon" src={InfoFilledSvg} />
            <h3 className="user-verification-profile__info-message-title">Adjustments needed!</h3>

            <Markdown markdown={message} wrapperClassName="user-verification-profile__info-message-sub-title" />
          </div>
        )}

        <div className="user-verification-profile__section">
          <SectionHeaderWithEdit
            onEdit={() => onEditField('workExperience')}
            title="Work experience"
            wrapperClassName="user-verification-profile__small-section-header"
          />
          <Container className="user-verification-profile__content-box" type="bordered">
            {workExperience}
          </Container>
        </div>

        <div className="user-verification-profile__section">
          <SectionHeaderWithEdit title="Open for" wrapperClassName="user-verification-profile__small-section-header" />
          <UserStory itemWithEditIcon />
        </div>

        <div className="user-verification-profile__section">
          <SectionHeaderWithEdit
            onEdit={() => onEditField('positionExpectations')}
            title="Looking for"
            wrapperClassName="user-verification-profile__small-section-header"
          />
          <Container className="user-verification-profile__content-box" type="bordered">
            {positionExpectations}
          </Container>
        </div>

        <div className="user-verification-profile__section">
          <SectionHeaderWithEdit
            title="My timeline"
            wrapperClassName="user-verification-profile__small-section-header"
          />
          <Container className="user-verification-profile__items-box" type="bordered">
            <Item
              data={<span className="user-verification-profile__item-subtitle">{canStartInLabel}</span>}
              icon={ClockSvg}
              itemWithEditIcon
              label="I can start in"
              onClick={() => onEditField('canStartIn')}
            />
          </Container>
        </div>

        <div className="user-verification-profile__section">
          <SectionHeaderWithEdit
            title="More info about me"
            wrapperClassName="user-verification-profile__small-section-header"
          />
          <Container className="user-verification-profile__items-box" type="bordered">
            <Item
              data={
                <span className="user-verification-profile__item-subtitle">
                  {linkedinUrl.value.replace('https://linkedin.com/in/', '')}
                </span>
              }
              icon={LinkedinSvg}
              itemWithEditIcon
              label="My linkedin"
              onClick={() => onEditField('linkedInUrl')}
            />
            <Item
              data={
                !isEmpty(profileLinks) &&
                profileLinks.map((link, i) => (
                  <Fragment key={i}>
                    {i !== 0 && (
                      <Fragment>
                        <br />
                        <br />
                      </Fragment>
                    )}
                    <span className="user-verification-profile__item-subtitle">{link.value}</span>
                  </Fragment>
                ))
              }
              icon={GlobeSvg}
              itemWithEditIcon
              label="My portfolio"
              onClick={() => onEditField('links')}
            />
          </Container>
        </div>

        <div className="user-verification-profile__section">
          <SectionHeaderWithEdit
            subTitle="Your contact details are only visible to recruiters you are getting in touch with."
            title="Contacts"
            wrapperClassName="user-verification-profile__small-section-header"
          />
          <Container className="user-verification-profile__items-box" type="bordered">
            <Item
              data={<span className="user-verification-profile__item-subtitle">{email}</span>}
              icon={MailSvg}
              itemWithEditIcon
              label="My email"
              onClick={() => onEditField('email')}
            />
            <Item
              data={
                <span className="user-verification-profile__item-subtitle">
                  {`${get(phone, 'country.dialCode')} ${get(phone, 'number')}`}
                </span>
              }
              icon={PhoneSvg}
              itemWithEditIcon
              label="My phone number"
              onClick={() => onEditField('phone')}
            />
          </Container>
        </div>

        {achievements && (
          <div className="user-verification-profile__section">
            <SectionHeaderWithEdit
              onEdit={() => onEditField('achievements')}
              title="Achievements"
              wrapperClassName="user-verification-profile__small-section-header"
            />
            <Container className="user-verification-profile__content-box" type="bordered">
              {achievements}
            </Container>
          </div>
        )}

        {!isEmpty(blacklistedCompanies) && (
          <div className="user-verification-profile__section">
            <SectionHeaderWithEdit
              onEdit={() => onEditField('blacklistedCompanies')}
              subTitle="These companies are not able to see your profile"
              title="Blocked companies"
              wrapperClassName="user-verification-profile__small-section-header"
            />
            <div className="user-verification-profile__blacklist-companies">
              {blacklistedCompanies.map(
                ({
                  id,
                  profile: {
                    name,
                    logo: { fullUrl: logoUrl },
                  },
                }) => (
                  <Chip
                    imageSrc={logoUrl}
                    key={id}
                    text={name}
                    wrapperClassName="user-verification-profile__company-chip"
                  />
                ),
              )}
            </div>
          </div>
        )}

        <Footer onPublish={onPublish} userVerificationState={userVerificationState} />
      </div>
    </div>
  );
};

EditProfile.propTypes = {
  currentUser: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onEditField: PropTypes.func.isRequired,
  onPublish: PropTypes.func.isRequired,
  onVerifiedUserUpdate: PropTypes.func.isRequired,
};
