import React, { Fragment } from 'react';
import { find, get } from 'lodash';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import FrBinocularSvg from '@shared/assets/svgs/binocular.svg';
import FrPinSvg from 'assets/images/fr-pin.svg';
import FrUserSvg from 'assets/images/fr-user.svg';
import DiamondSvg from '@shared/assets/svgs/diamond.svg';
import FrClipboardSvg from 'assets/images/fr-clipboard.svg';
import EuroSvg from '@shared/assets/svgs/euro.svg';
import Item from './Item';
import './styles.scss';

export default function Story({
  story,
  enableLocation,
  editStory,
  currencies,
  labels,
  wrapperClassName,
  itemWithEditIcon,
}) {
  let salarySymbol = '';

  if (story.salary) {
    salarySymbol = (find(currencies, { code: story.salary.currency }) || {}).symbol;
  }

  return (
    <div className={classnames('availability-request-story', wrapperClassName)}>
      <Item
        data={
          <Fragment>
            <span className="availability-request-story-highlight">
              {story && story.track ? `${story.track.name} positions` : 'Choose position'}
            </span>
          </Fragment>
        }
        icon={FrBinocularSvg}
        itemWithEditIcon={itemWithEditIcon}
        label="Offers for"
        onClick={() => editStory('track')}
      />

      <Item
        data={
          story && story.specialities ? (
            story.specialities.map((speciality, i) => (
              <Fragment key={i}>
                {i !== 0 && ' or '}
                <span className="availability-request-story-highlight">{speciality.name}</span>
              </Fragment>
            ))
          ) : (
            <span className="availability-request-story-highlight">Choose specialities</span>
          )
        }
        icon={FrUserSvg}
        itemWithEditIcon={itemWithEditIcon}
        label="My specialities are"
        onClick={() => editStory('specialities')}
      />

      {story && !!(story.skills || []).length && (
        <Item
          data={story.skills.map((skill, i) => (
            <Fragment key={i}>
              {i !== 0 && ', '}
              <span className="availability-request-story-highlight">{skill.name}</span>
            </Fragment>
          ))}
          icon={DiamondSvg}
          itemWithEditIcon={itemWithEditIcon}
          label="And I'm experienced in"
          onClick={() => editStory('specialities')}
        />
      )}

      {story && story.track.key !== 'top_level_management' && (
        <Item
          data={
            story && story.seniorities ? (
              story.seniorities.map((seniority, i) => (
                <Fragment key={i}>
                  {i !== 0 && ' or '}
                  <span className="availability-request-story-highlight">{seniority.name}</span>
                </Fragment>
              ))
            ) : (
              <span className="availability-request-story-highlight">Choose level</span>
            )
          }
          icon={FrClipboardSvg}
          itemWithEditIcon={itemWithEditIcon}
          label="My level is"
          onClick={() => editStory('seniorities')}
        />
      )}

      <Item
        data={
          story.salary ? (
            <span className="availability-request-story-highlight data-salary">
              {' '}
              {salarySymbol}
              {story.salary.value} / {story.salary.period}
            </span>
          ) : (
            <span className="availability-request-story-highlight data-salary"> Choose salary</span>
          )
        }
        icon={EuroSvg}
        itemWithEditIcon={itemWithEditIcon}
        label="My minimum required salary is"
        onClick={() => editStory('salary')}
      />

      {enableLocation && (
        <Item
          data={
            <span className="availability-request-story-highlight">
              {story && story.location ? story.location.longName : 'Choose location'}
            </span>
          }
          icon={FrPinSvg}
          itemWithEditIcon={itemWithEditIcon}
          label={get(labels, 'location', 'Current location')}
          onClick={() => editStory('location')}
        />
      )}
    </div>
  );
}

Story.propTypes = {
  currencies: PropTypes.arrayOf(PropTypes.object),
  editStory: PropTypes.func.isRequired,
  enableLocation: PropTypes.bool,
  itemWithEditIcon: PropTypes.bool,
  labels: PropTypes.object,
  story: PropTypes.object,
  wrapperClassName: PropTypes.string,
};
