import { isObject as isLodashObject } from 'lodash';
import { ObjectKeys } from './types';

export function isObject(obj: unknown): obj is Record<string, unknown> {
  return isLodashObject(obj);
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function hasFields<TExpectedObject extends object>(
  obj: unknown,
  fieldNames: ObjectKeys<TExpectedObject>,
): obj is TExpectedObject {
  return isObject(obj) && !fieldNames.some(fieldName => (obj as TExpectedObject)[fieldName] === undefined);
}
