export const OPENING_STATE = {
  ACTIVE: 'active',
  ADMIN_REVIEW: 'admin_review',
  ARCHIVED: 'archived',
  DELETED: 'deleted',
  DRAFT: 'draft',
  INACTIVE: 'inactive',
  PAUSED: 'paused',
  SUPPORT: 'support',
};

export const SEARCH_TAGS = {
  COMPANY_HAS_ACTIVE_SUBSCRIPTION: 'COMPANY_HAS_ACTIVE_SUBSCRIPTION',
  EMPLOYMENT_TYPE: 'EMPLOYMENT_TYPE',
  FEATURED_OFFER: 'FEATURED_OFFER',
  POSITION_AREA: 'POSITION_AREA',
  POSITION_COUNTRY: 'POSITION_COUNTRY',
  POSITION_LOCATION: 'POSITION_LOCATION',
  RELOCATION: 'RELOCATION',
  REMOTE: 'REMOTE',
  REMOTE_AREA: 'REMOTE_AREA',
  REMOTE_COUNTRY: 'REMOTE_COUNTRY',
  REMOTE_LOCATION: 'REMOTE_LOCATION',
  SENIORITY: 'SENIORITY',
  SKILL: 'SKILL',
  SPECIALITY: 'SPECIALITY',
  STATE: 'STATE',
  TARGET_COUNTRY: 'TARGET_COUNTRY',
  TARGET_LOCATION: 'TARGET_LOCATION',
  TYPE: 'TYPE',
};

export const EXPLORE_FILTER = {
  COUNTRIES: 'COUNTRIES',
  SENIORITIES: 'SENIORITIES',
  SPECIALITIES: 'SPECIALITIES',
};
