export const INDEX_ROUTE = '/';
export const ARTICLE_ROUTE = '/article/:articleId';
export const MESSAGING_ROUTE = '/messaging';
export const INBOX_ROUTE = '/inbox';
export const ARTICLES_ROUTE = '/articles';
export const CHAT_ROUTE = '/chat/:threadId';
export const NOTIFICATION_CENTER_ROUTE = '/notification-center';
export const MATCH_ROUTE = '/match/:openingId/:userMatchingProfileId';
export const PROFILE_ROUTE = '/profile';
export const PROFILE_REMOVE_ROUTE = `${PROFILE_ROUTE}/remove`;
export const INBOX_PROFILE_ROUTE = '/inbox-profile';
export const SETTINGS_ROUTE = '/settings';
export const SETTINGS_HIRE_ROUTE = `${SETTINGS_ROUTE}/hire`;
export const SETTINGS_CONTACT_ROUTE = `${SETTINGS_ROUTE}/contact`;
export const SETTINGS_FEEDBACK_ROUTE = `${SETTINGS_ROUTE}/feedback`;
export const SEARCH_ROUTE = '/search';
export const JOBS_SEARCH_ROUTE = '/jobs-search';
export const FOR_YOU_ROUTE = '/for-you';
export const SEARCH_RESULT_ROUTE = '/search-result';
export const LOADING_ROUTE = '/loading';
export const VERIFIED_USER_ROUTE = '/verified-user';
export const VERIFIED_USER_QUESTIONNAIRE_ROUTE = '/verified-user/questionnaire';
export const REVIEWS_ROUTE = '/reviews';
export const TOP_COMPANIES_ROUTE = '/top-companies';
// Company profile routes
export const COMPANY_ROUTE = '/company/:companyId';
export const COMPANY_ABOUT_ROUTE = '/company/:companyId/about';
export const COMPANY_JOB_OPENINGS_ROUTE = '/company/:companyId/job-openings';
// Job Opening
export const JOB_OPENING_ROUTE = '/job-opening/:openingId';

// your-market-overview Considered as home route because some push notifications are sent to this address
export const YOUR_MARKET_OVERVIEW_ROUTE = '/your-market-overview';
export const FILLED_ROUTE = '/filled';
export const MY_BOOKMARKS_ROUTE = '/my-bookmarks';
export const MENU_ROUTE = '/menu';
export const FRANK_ROUTE = '/frank';
export const JOBS_ROUTE = '/jobs';
export const JOBS_ROUTE_OLD = '/jobs-old';
export const MESSAGE_REQUESTS_ROUTE = '/message-requests/:messageId?';
export const ACTIVE_COMPANIES_ROUTE = '/active-companies';

// App preview routes
export const OPENING_PREVIEW_ROUTE = '/opening';
export const COMPANY_PREVIEW_ROUTE = '/company';
export const QUESTION_PREVIEW_ROUTE = '/question';
export const PITCH_PREVIEW_ROUTE = '/pitch';

export const FIND_YOUR_COMPANY_ROUTE = '/find-your-company';
export const COMPANY_REVIEWS_ROUTE = '/company-reviews/:companyId';
export const CREATE_COMPANY_REVIEW_ROUTE = '/create-company-review/:companyId';
