import React, { ChangeEvent, FC, useRef } from 'react';
import Svg from 'svg-inline-react';
import FrSearchSvg from 'assets/images/fr-search.svg';
import classNames from 'classnames';
import { AppBarSearchInputProps } from './props';
import './styles.scss';

export const AppBarSearchInput: FC<AppBarSearchInputProps> = ({ onChange, setIsActive, value, isActive }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    setIsActive?.(true);

    inputRef?.current?.focus();
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange?.(event.target.value);

    if (!event.target.value) {
      setIsActive?.(false);

      inputRef?.current?.blur();
    }
  };

  return (
    <div
      className={classNames('app-bar-search-input', isActive && 'app-bar-search-input--active')}
      onClick={() => handleClick()}
    >
      <Svg className="app-bar-search-input__icon" src={FrSearchSvg} />

      <input ref={inputRef} value={value} onChange={event => handleChange(event)} placeholder="Search" />
    </div>
  );
};
