import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { random } from 'lodash';
import { connect } from 'react-redux';
import FrBackSvg from 'assets/images/fr-back.svg';
import styleVars from 'assets/_vars.scss';
import calculateElementHeight from '@src/util/calculateElementHeight';
import contentPushStyle, { isBrokenIos } from '@src/util/contentPushStyle';
import { getVisible } from '@src/redux/selectors/navigation';
import Back from '@src/components/Back';
import { TypingLoader } from '@src/components/TypingLoader';
import { TypingLoaderType } from '../TypingLoader/enums/TypingLoaderType.enum';
import Card from './Card';
import './styles.scss';

function getCountableChildNodes(contentRef) {
  return [...contentRef.childNodes[0].childNodes, ...[...contentRef.childNodes].slice(1)];
}

const DEFAULT_CHILD_INDEX = 0;

@connect(state => ({
  navigationVisible: getVisible(state),
}))
export default class ContentPush extends React.Component {
  static propTypes = {
    errorMessage: PropTypes.string,
    isDarkModeOn: PropTypes.bool,
    keepScrollTop: PropTypes.bool,
    loading: PropTypes.bool.isRequired,
    navigationVisible: PropTypes.bool.isRequired,
    question: PropTypes.object,
    read: PropTypes.bool,
    recruiterAvatar: PropTypes.object,
    sendBack: PropTypes.func,
    showHeader: PropTypes.bool.isRequired,
    user: PropTypes.object,
  };

  typingRef = undefined;
  loaderHeight = undefined;
  shownInterval = undefined;
  mainRef = undefined;
  scrollRef = undefined;
  mainContentRef = undefined;
  topOverflow = 0;

  state = {
    contentRef: undefined,
    shownChildIndex: DEFAULT_CHILD_INDEX,
  };

  shouldComponentUpdate(nextProps) {
    return this.props.navigationVisible === nextProps.navigationVisible;
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.question !== nextProps.question) {
      this.setState({
        contentRef: undefined,
        shownChildIndex: DEFAULT_CHILD_INDEX,
      });

      this.topOverflow = 0;

      clearInterval(this.shownInterval);
      this.shownInterval = undefined;
    }
  }

  componentWillUnmount() {
    clearInterval(this.shownInterval);
  }

  componentDidUpdate(prevProps, prevState) {
    const { contentRef, shownChildIndex } = this.state;
    const { read } = this.props;

    if (contentRef && shownChildIndex === DEFAULT_CHILD_INDEX) {
      const childNodesLength = getCountableChildNodes(contentRef).length;

      if (read) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ shownChildIndex: childNodesLength });
        this.setScrollable(150);
      } else if (!this.shownInterval) {
        this.shownInterval = setInterval(() => {
          setTimeout(
            () => {
              this.setState(
                ({ shownChildIndex }) => ({ shownChildIndex: shownChildIndex + 1 }),
                () => {
                  if (this.state.shownChildIndex === childNodesLength - 1) {
                    clearInterval(this.shownInterval);
                    this.shownInterval = undefined;

                    this.setScrollable(styleVars.textRevealDuration);
                  }
                },
              );
            },
            shownChildIndex === 0 ? 0 : random(0, 200, false),
          );
        }, 700);
      }
    }
  }

  setScrollable = delay => {
    setTimeout(() => {
      if (this.topOverflow !== 0) {
        const clientHeight = Math.min(this.mainContentRef.clientHeight, this.getChildrenHeight());
        const scrollTop = clientHeight - this.mainRef.clientHeight;

        this.mainContentRef.style.height = '100%';

        if (isBrokenIos()) {
          this.mainContentRef.style.transform = `translateY(${this.topOverflow}px)`;
        } else {
          this.mainContentRef.style.marginTop = `${this.topOverflow}px`;
        }

        this.scrollRef.scrollTop = scrollTop;
      }
    }, delay || 100);
  };

  setCardLoaderHeight = it => {
    if (!this.loaderHeight && it) {
      this.loaderHeight = it.clientHeight;
    }
  };

  getChildren = () => {
    const { contentRef } = this.state;

    return getCountableChildNodes(contentRef);
  };

  getChildrenHeight = () => {
    const { contentRef } = this.state;
    const children = getCountableChildNodes(contentRef);

    return children.reduce((result, child) => result + calculateElementHeight(child), 0);
  };

  getShownChildrenHeight = () => {
    const { contentRef, shownChildIndex } = this.state;
    const children = getCountableChildNodes(contentRef);

    return children.slice(0, shownChildIndex + 1).reduce((result, child) => result + calculateElementHeight(child), 0);
  };

  setContentRef = it => this.setState({ contentRef: it });
  // eslint-disable-next-line no-return-assign
  setMainRef = it => {
    if (it) {
      this.mainRef = it;
    }
  };

  // eslint-disable-next-line no-return-assign
  setScrollRef = it => {
    if (it) {
      this.scrollRef = it;
    }
  };

  // eslint-disable-next-line no-return-assign
  setMainContentRef = it => {
    if (it) {
      this.mainContentRef = it;
    }
  };

  setContentBottom = () => {
    const { contentRef, shownChildIndex } = this.state;
    const { keepScrollTop } = this.props;

    if (!contentRef || shownChildIndex === 0) {
      return '100vh';
    }

    const clientHeight = this.mainRef.clientHeight;

    const children = this.getChildren();
    const childrenHeight = this.getChildrenHeight();
    const shownChildrenHeight = this.getShownChildrenHeight();

    const topFreeSpace = clientHeight - childrenHeight;
    let moveTop;

    if (keepScrollTop) {
      if (this.mainContentRef.clientHeight < shownChildrenHeight) {
        moveTop = 0;
      } else {
        if (this.mainContentRef.clientHeight < childrenHeight) {
          moveTop = this.mainContentRef.clientHeight - shownChildrenHeight;
        } else {
          moveTop = childrenHeight - shownChildrenHeight;
        }

        if (shownChildIndex < children.length - 1) {
          moveTop = moveTop - this.loaderHeight - 25;
        }
      }
    } else if (topFreeSpace > 0) {
      moveTop = childrenHeight - shownChildrenHeight;

      if (shownChildIndex < children.length - 1) {
        moveTop = moveTop - this.loaderHeight - 25;
      }
    } else {
      this.mainContentRef.style.height = 'auto';

      if (shownChildIndex >= children.length - 1) {
        moveTop = clientHeight - shownChildrenHeight;
      } else {
        // 25 is combination of loader shadows etc...
        moveTop = clientHeight - shownChildrenHeight - this.loaderHeight - 25;
      }

      if (moveTop < 0) {
        this.topOverflow = Math.abs(moveTop);
      }
    }

    return `${moveTop}px`;
  };

  render() {
    let { showHeader } = this.props;

    const { navigationVisible, loading, question, sendBack, isDarkModeOn, user } = this.props;

    const { shownChildIndex, contentRef } = this.state;

    const isIntroScreen = question.type === 'onboarding' && question.key === 'name';

    if (isIntroScreen) {
      showHeader = false;
    }

    return (
      <div
        className={classnames(
          'main-layout',
          !navigationVisible && 'navigation-hidden',
          isIntroScreen && 'full-height',
          isDarkModeOn && 'main-layout--dark-mode',
        )}
        ref={this.setMainRef}
      >
        {showHeader && (
          <Back
            backButtonSvg={FrBackSvg}
            className={classnames(question.key === 'name' && 'header-hidden')}
            disabled={loading}
            onBack={sendBack}
          />
        )}
        <TransitionGroup component={null}>
          <CSSTransition
            appear
            classNames="move-up"
            key={loading ? 'loading' : question.key}
            timeout={{ enter: 0, exit: 400 }}
          >
            {loading ? (
              <div />
            ) : (
              <div
                className={classnames('main-content', isBrokenIos() && 'broken-ios')}
                ref={this.setMainContentRef}
                style={contentPushStyle(this.setContentBottom())}
              >
                <Card
                  action={question.action}
                  content={question.content}
                  contentRef={contentRef}
                  setContentRef={this.setContentRef}
                  setScrollRef={this.setScrollRef}
                  shownChildIndex={shownChildIndex}
                  user={user}
                  {...this.props}
                />
              </div>
            )}
          </CSSTransition>
        </TransitionGroup>

        {loading || !contentRef || getCountableChildNodes(contentRef).length - 1 > shownChildIndex ? (
          <div className="content-push-card-loader typing" ref={this.setCardLoaderHeight}>
            <TypingLoader type={isDarkModeOn ? TypingLoaderType.LIGHT : TypingLoaderType.DARK} />
          </div>
        ) : (
          <div />
        )}
      </div>
    );
  }
}
