import React, { FC } from 'react';
import FadeInImage from '@src/components/FadeInImage';
import { CompanyHighLightsProps } from './props';
import './styles.scss';

export const CompanyHighLights: FC<CompanyHighLightsProps> = ({
  countryFlagUrl,
  employeesAmount,
  financialDetails,
}) => {
  return (
    <div className="company-highlights">
      {!!countryFlagUrl && (
        <div className="company-highlights__location">
          <FadeInImage className="company-highlights__flag" src={countryFlagUrl} />

          <span className="company-highlights__location-hq">HQ</span>
        </div>
      )}

      {!!employeesAmount && (
        <div className="company-highlights__employees">
          <h4>{employeesAmount}</h4>
          Employees
        </div>
      )}

      {!!financialDetails && <div className="company-highlights__raised">{financialDetails}</div>}
    </div>
  );
};
