import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import Subtitle from '@src/components/Subtitle';
import { MediaList } from '../MediaList';
import './styles.scss';

export const MediaListWithTitle = ({ media, name }) =>
  !isEmpty(media) ? (
    <div className="media-list-title">
      <Subtitle value={`Learn more about ${name}`} />

      <div className="media-list-title__wrapper">
        <MediaList media={media} />
      </div>
    </div>
  ) : null;

MediaListWithTitle.propTypes = {
  media: PropTypes.arrayOf(PropTypes.object),
  name: PropTypes.string,
};
