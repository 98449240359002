import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { goBack, push } from 'connected-react-router';
import ContentPush from '@src/components/ContentPush';
import { setNavigationVisibility } from '@src/redux/actions/navigation';
import { deleteUser } from '@src/redux/actions/user';
import api from '@src/util/api';

@connect(undefined, { goBack, push, setNavigationVisibility })
export default class Remove extends PureComponent {
  static propTypes = {
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    setNavigationVisibility: PropTypes.func.isRequired,
  };

  state = {
    loading: true,
  };

  componentDidMount() {
    this.props.setNavigationVisibility(false);
    this.fetchQuestion();
  }

  fetchQuestion = () => {
    api('/api/app/profile/remove', { delay: 400 }).then(this.setQuestion).catch(this.props.goBack);
  };

  setQuestion = ({ question, user }) => {
    this.setState({
      loading: false,
      question,
      user,
    });
  };

  removeUser = () => {
    this.setState({ loading: true }, () => {
      deleteUser(this.state.reason).catch(this.props.goBack);
    });
  };

  onInputChange = (key, value) => {
    this.setState({ [key]: value });
  };

  handleResponse = data => {
    const { answer } = data;

    if (answer.type === 'positive') {
      this.removeUser();
    } else {
      this.props.goBack();
    }
  };

  render() {
    const { question, loading } = this.state;

    if (!question) {
      return null;
    }

    return (
      <ContentPush
        keepScrollTop
        loading={loading}
        onInputChange={this.onInputChange}
        question={question}
        sendBack={this.props.goBack}
        sendResponse={this.handleResponse}
        showHeader
      />
    );
  }
}
