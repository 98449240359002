import { hasFields } from '@src/util/object/helpers';
import {
  AreaType,
  ArticleType,
  CompanyRatingReviewType,
  CountryType,
  LocationType,
  NonPaidOpeningType,
  OpeningType,
} from '@src/graphql/generated';

export function isArticleType(obj: unknown): obj is ArticleType {
  return hasFields<ArticleType>(obj, ['id', '__typename']) && obj.__typename === 'ArticleType';
}

export function isOpeningType(obj: unknown): obj is OpeningType {
  return hasFields<OpeningType>(obj, ['id', '__typename']) && obj.__typename === 'OpeningType';
}

export function isNonPaidOpeningType(obj: unknown): obj is NonPaidOpeningType {
  return hasFields<NonPaidOpeningType>(obj, ['id', '__typename']) && obj.__typename === 'NonPaidOpeningType';
}

export function isLocationType(obj: unknown): obj is LocationType {
  return hasFields<LocationType>(obj, ['id', '__typename']) && obj.__typename === 'LocationType';
}

export function isCountryType(obj: unknown): obj is CountryType {
  return hasFields<CountryType>(obj, ['id', '__typename']) && obj.__typename === 'CountryType';
}

export function isAreaType(obj: unknown): obj is AreaType {
  return hasFields<AreaType>(obj, ['id', '__typename']) && obj.__typename === 'AreaType';
}

export function isCompanyRatingReviewType(obj: unknown): obj is CompanyRatingReviewType {
  return hasFields<CompanyRatingReviewType>(obj, ['id', '__typename']) && obj.__typename === 'CompanyRatingReviewType';
}
