import React, { FC, useEffect, useState } from 'react';
import { get } from 'lodash';
import classNames from 'classnames';
import Svg from 'svg-inline-react';
import FrExpandSvg from 'assets/images/fr-expand.svg';
import MfTextLogoSvg from 'assets/images/mf-text-logo.svg';
import { useGetUserQuery, useSpecialitiesQuery } from '@src/graphql/generated';
import { BasePageHeader } from '@src/containers/PageHeader/BasePageHeader';
import { BaseAppBar } from '@src/components/PrimaryAppBar';
import { EmailPrompt } from '@src/containers/EmailPrompt';
import { setContent as setModalContent, closeModal } from '@src/redux/actions/slideUpModal';
import { useAppDispatch } from '@src/store';
import { trackEvent } from '@src/metrics';
import { Context } from '@src/metrics/enums/context.enum';
import { Interaction } from '@src/metrics/enums/interaction.enum';
import { ItemType } from '@src/metrics/enums/itemType.enum';
import { useQueryParams } from '@src/customHooks/useQueryParams';
import { AppBarSearchButton } from '@src/components/AppBarSearchButton';
import { HeaderLabel } from '@src/components/HeaderLabel';
import { PlaceScroller } from '@src/containers/PlaceScroller';
import { SORT_OPTION_LABEL } from '../constants/sortOptions';
import { QueryParams } from '../interfaces/queryParams.interface';
import { Category } from '../enums/category.enum';
import { DEFAULT_CATEGORY } from '../constants/categories';
import { isOnboardedUserProfileType } from './typeGuards';
import { SortOptionsModalContent } from './SortOptionsModalContent';
import { SpecialitiesModalContent } from './SpecialitiesModalContent';
import { SpecialityScroller } from './SpecialityScroller';
import { PageHeaderProps } from './props';
import './styles.scss';

export const PageHeader: FC<PageHeaderProps> = ({
  activePlaceId,
  addPlace,
  onPlaceClick,
  places,
  sortOption,
  updateSortOption,
  providedTargetEl,
  getScrollableContainer,
  onSpecialitySelect,
}) => {
  const dispatch = useAppDispatch();

  const { queryParams, updateQueryParams } = useQueryParams<QueryParams>();

  const selectedSpecialityId = queryParams?.selectedSpecialityId || DEFAULT_CATEGORY;

  const { data: userData } = useGetUserQuery();

  const { data: specialitiesData } = useSpecialitiesQuery();

  const [isAdditionalElementShown, setIsAdditionalElementShown] = useState(false);

  const user = userData?.currentUser;

  const handleSpecialitySelect = (selectedSpecialityId: string) => {
    updateQueryParams({
      selectedSpecialityId: selectedSpecialityId,
    });

    onSpecialitySelect(selectedSpecialityId);
  };

  const openSortOptionsModal = () => {
    dispatch(
      setModalContent({
        content: (
          <SortOptionsModalContent
            activeOption={sortOption}
            onClose={() => dispatch(closeModal())}
            onSelect={sortOption => {
              updateSortOption(sortOption);

              trackEvent({
                context: Context.DIGEST,
                interaction: Interaction.SORT,
                itemType: ItemType.JOBS_FEED,
                itemValue: sortOption,
              });

              dispatch(closeModal());
            }}
          />
        ),
        height: '25.6rem',
      }),
    );
  };

  const openSpecialitiesModal = () => {
    dispatch(
      setModalContent({
        content: (
          <SpecialitiesModalContent
            activeSpecialityId={selectedSpecialityId}
            onClose={() => dispatch(closeModal())}
            onSelect={selectedSpecialityId => {
              handleSpecialitySelect(selectedSpecialityId);

              dispatch(closeModal());
            }}
          />
        ),
        height: '65rem',
      }),
    );
  };

  const getActiveSpecialityName = () => {
    if (selectedSpecialityId === Category.ALL_OFFERS) {
      return 'All offers';
    }

    if (selectedSpecialityId === Category.FOR_YOU) {
      return 'For you';
    }

    const activeSpeciality = specialitiesData?.specialities?.find(
      speciality => speciality?.id === queryParams?.selectedSpecialityId,
    );

    return activeSpeciality?.name;
  };

  useEffect(() => {
    const element = getScrollableContainer?.();

    const handleScroll: EventListenerOrEventListenerObject = event => {
      const breakPoint = get(providedTargetEl?.current, 'offsetTop', 0);

      const scrollTop = (event?.target as HTMLDivElement)?.scrollTop || 0;

      if (scrollTop <= breakPoint) {
        setIsAdditionalElementShown(false);
      }

      if (scrollTop > breakPoint) {
        setIsAdditionalElementShown(true);
      }
    };

    if (element) {
      element.addEventListener('scroll', handleScroll, false);

      return () => {
        element.removeEventListener('scroll', handleScroll, false);
      };
    }
  }, []);

  const isEmailPromptVisible = user && isOnboardedUserProfileType(user.profile) && user.isEligibleForVerification;

  return (
    <BasePageHeader
      toggleAppBarEl={
        <div className="job-view-header__toggle-app-bar">
          <BaseAppBar isDefaultActionsHidden wrapperClassName="job-view-header__base-app-bar">
            <div className="job-view-header__base-app-bar-content">
              <Svg className="job-view-header__bar-logo" src={MfTextLogoSvg} />

              <AppBarSearchButton />
            </div>
          </BaseAppBar>

          <div
            className={classNames(
              'job-view-header__app-bar-tabs',
              isAdditionalElementShown && 'job-view-header__app-bar-tabs--show',
            )}
          >
            <HeaderLabel className="job-view-header__select" isActive onClick={openSpecialitiesModal}>
              {getActiveSpecialityName()}

              <Svg className="job-view-header__expand" src={FrExpandSvg} />
            </HeaderLabel>

            <div className="job-view-header__divider" />

            <PlaceScroller
              activePlaceId={activePlaceId}
              addPlace={addPlace}
              onPlaceClick={onPlaceClick}
              places={places}
            />
          </div>
        </div>
      }
    >
      <>
        <div className="job-view-header__wrapper">
          <BaseAppBar isDefaultActionsHidden wrapperClassName="job-view-header__base-app-bar">
            <div className="job-view-header__base-app-bar-content">
              <Svg className="job-view-header__bar-logo" src={MfTextLogoSvg} />

              <AppBarSearchButton />
            </div>
          </BaseAppBar>
        </div>

        {isEmailPromptVisible ? <EmailPrompt /> : null}

        <div
          className={classNames(
            'job-view-header__filters',
            !queryParams.selectedSpecialityId && 'job-view-header__filters--expand',
          )}
        >
          <div>
            <div className="job-view-header__filters-section-title-wrapper">
              <div className="job-view-header__filters-section-title">CATEGORIES</div>

              <div className="job-view-header__filters-section-button" onClick={openSpecialitiesModal}>
                View all
              </div>
            </div>

            <SpecialityScroller onSpecialitySelect={handleSpecialitySelect} />

            <div className="job-view-header__filters-section-title-wrapper">
              <div className="job-view-header__filters-section-title">LOCATION</div>
            </div>

            <PlaceScroller
              activePlaceId={activePlaceId}
              addPlace={addPlace}
              containerClassName="job-view-header__filters-place-container"
              onPlaceClick={onPlaceClick}
              places={places}
            />

            {selectedSpecialityId === Category.FOR_YOU && (
              <div className="job-view-header__filters-section-title-wrapper">
                <div className="job-view-header__filters-section-title">SORTED BY</div>

                <div className="job-view-header__filters-section-button" onClick={openSortOptionsModal}>
                  {SORT_OPTION_LABEL[sortOption]}

                  <Svg className="job-view-header__expand job-view-header__expand--blue" src={FrExpandSvg} />
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    </BasePageHeader>
  );
};
