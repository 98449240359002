import { push, getLocation } from 'connected-react-router';
import { matchPath } from 'react-router-dom';
import { apolloClient } from '@src/graphql/apollo';
import { JOB_OPENING_ROUTE, COMPANY_ROUTE, CHAT_ROUTE, INDEX_ROUTE, JOBS_SEARCH_ROUTE } from '@src/constants/routes';
import { clearDeepLinkParams, resendContainerDataItems, registerForPush } from '@src/container';
import createLink from '@src/util/createLink';
import {
  VerifyAuthCodeDocument,
  DeepLinkDocument,
  DeepLinkQueryVariables,
  DeepLinkQuery,
  DeepLinkEntityTypeEnum,
  LoginDocument,
} from '@src/graphql/generated';
import { fetchAccessToken } from '@src/redux/actions/accessToken';
import { getUser } from '@src/graphql/user/actions/getUser';
import { trackEvent } from '@src/metrics';
import { Context } from '@src/metrics/enums/context.enum';
import { Interaction } from '@src/metrics/enums/interaction.enum';
import { captureError } from '@src/util/errors';
import { RootAction } from '@src/store/interfaces/store.interface';
import { LoginUserParams } from './interfaces/loginUser.interface';

const logInUser = ({ deepLink, initialRoute }: LoginUserParams): RootAction<string, unknown> => {
  return async dispatch => {
    try {
      if (
        deepLink?.entity?.type === DeepLinkEntityTypeEnum.AUTH_CODE &&
        deepLink.entity.entity.__typename === 'AuthCodeType'
      ) {
        await apolloClient.mutate({
          mutation: VerifyAuthCodeDocument,
          variables: {
            code: deepLink.entity.entity.code.toUpperCase(),
            email: deepLink.entity.entity.user.email,
          },
        });
      } else if (
        deepLink?.entity?.type === DeepLinkEntityTypeEnum.USER_LOGIN_TOKEN &&
        deepLink.entity.entity.__typename === 'LoginTokenType'
      ) {
        await apolloClient.mutate({
          mutation: LoginDocument,
          variables: {
            loginInput: {
              loginToken: deepLink.entity.entity.token,
            },
          },
        });
      } else {
        dispatch(
          push(
            createLink({
              link: INDEX_ROUTE,
            }),
          ),
        );

        return;
      }

      await dispatch(fetchAccessToken());

      await getUser({
        fetchPolicy: 'network-only',
      });

      trackEvent({
        context: Context.ONBOARDING,
        interaction:
          deepLink.entity.type === DeepLinkEntityTypeEnum.AUTH_CODE
            ? Interaction.AUTH_CODE_LOGIN_SUCCESSFUL
            : Interaction.LOGIN_TOKEN_LOGIN_SUCCESSFUL,
        payload: {
          source: 'deeplink',
        },
      });

      clearDeepLinkParams();
      registerForPush();
      resendContainerDataItems();

      dispatch(
        push(
          createLink({
            link: initialRoute,
          }),
        ),
      );
    } catch (error) {
      captureError(error);
      dispatch(
        push(
          createLink({
            link: INDEX_ROUTE,
          }),
        ),
      );
    }
  };
};

export function setDeepLinkOpened(lookupId: string): RootAction<string, unknown> {
  return async (dispatch, getState) => {
    const location = getLocation(getState());

    const {
      data: { deepLink },
    } = await apolloClient.query<DeepLinkQuery, DeepLinkQueryVariables>({
      fetchPolicy: 'no-cache',
      query: DeepLinkDocument,
      variables: {
        lookupId,
      },
    });
    let path;

    if (deepLink?.entity?.type === DeepLinkEntityTypeEnum.OPENING) {
      path = createLink({
        link: JOB_OPENING_ROUTE,
        params: {
          openingId: deepLink.entity.entity.id,
        },
      });
    }

    if (deepLink?.entity?.type === DeepLinkEntityTypeEnum.MESSAGE_THREAD) {
      path = createLink({
        link: CHAT_ROUTE,
        params: {
          threadId: deepLink.entity.entity.id,
        },
      });
    }

    if (deepLink?.entity?.type === DeepLinkEntityTypeEnum.COMPANY) {
      path = createLink({
        link: COMPANY_ROUTE,
        params: { companyId: deepLink.entity.entity.id },
        queryParams: { request: true },
      });
    }

    if (
      (deepLink?.entity?.type === DeepLinkEntityTypeEnum.AUTH_CODE &&
        deepLink.entity.entity.__typename === 'AuthCodeType') ||
      (deepLink?.entity?.type === DeepLinkEntityTypeEnum.USER_LOGIN_TOKEN &&
        deepLink.entity.entity.__typename === 'LoginTokenType')
    ) {
      try {
        await dispatch(fetchAccessToken());

        const currentUser = await getUser({
          fetchPolicy: 'network-only',
        });

        if (currentUser?.email === deepLink.entity.entity.user.email) {
          dispatch(
            push(
              createLink({
                link: JOBS_SEARCH_ROUTE,
              }),
            ),
          );

          return;
        } else {
          dispatch(
            logInUser({
              deepLink: deepLink,
              initialRoute: JOBS_SEARCH_ROUTE,
            }),
          );
        }
      } catch (error) {
        captureError(error);
        dispatch(
          push(
            createLink({
              link: INDEX_ROUTE,
            }),
          ),
        );
      }
    }

    if (path && !matchPath(location.pathname, { path })) {
      dispatch(push(path));
    }
  };
}
