import { useLayoutEffect } from 'react';
import { size, get, throttle } from 'lodash';
import { trackEvent } from '@src/metrics';
import { handleFetchMore } from '@src/util/handleFetchMore';
import { useCompanyReviewsFeedQuery } from '@src/graphql/generated';

const FIRST_PATCH_SIZE = 4;
const NEXT_PATCH_SIZE = 6;

export const useCompanyReview = (companyId, scrollableContainer) => {
  const { data, loading, fetchMore } = useCompanyReviewsFeedQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      id: companyId,
      paginationOptions: { limit: FIRST_PATCH_SIZE },
    },
  });

  const pagination = get(data, 'company.reviews.pagination', {});
  const reviews = get(data, 'company.reviews.result', []);

  useLayoutEffect(() => {
    if (scrollableContainer.current) {
      scrollableContainer.current.addEventListener('scroll', loadMore);
    }

    return () => {
      if (scrollableContainer.current) {
        scrollableContainer.current.removeEventListener('scroll', loadMore);
      }

      loadMore.cancel();
    };
  }, [loading, pagination]);

  const loadMore = throttle(({ target: { scrollTop, scrollHeight } }) => {
    const fetchMoreMargin = scrollTop + window.innerHeight * 3;

    if (!loading && pagination.hasMoreResults && fetchMoreMargin > scrollHeight) {
      handleFetchMore(fetchMore, {
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return prev;
          }

          const prevCompanyState = prev.company;
          const prevReviews = prev.company.reviews;

          const newResult = fetchMoreResult.company.reviews.result;
          const pagination = fetchMoreResult.company.reviews.pagination;

          trackEvent({
            context: 'Company reviews',
            interaction: 'Fetch More',
            itemType: 'Company reviews feed',
            itemValue: size([...prevReviews.result, ...newResult]),
          });

          return {
            company: {
              ...prevCompanyState,
              reviews: {
                ...prevReviews,
                pagination: {
                  // Do not update minCursor, because we use it as a initial minCursor to detect feed update on page mount.
                  ...prevReviews.pagination,
                  hasMoreResults: pagination.hasMoreResults,
                  limit: pagination.limit,
                  maxCursor: pagination.maxCursor,
                },
                result: [...prevReviews.result, ...newResult],
              },
            },
          };
        },
        variables: {
          id: companyId,
          paginationOptions: {
            limit: NEXT_PATCH_SIZE,
            minCursor: pagination.maxCursor,
          },
        },
      });
    }
  }, 200);

  return {
    data: reviews,
    loadMore,
    loading,
    pagination,
  };
};
