import React, { FC, useEffect, useRef } from 'react';
import qs from 'qs';
import { useHistory, useLocation } from 'react-router-dom';
import { useCurrentUserQuery, useGetUserQuery, UserVerificationStateEnum } from '@src/graphql/generated';
import { VerifiedTalentGateway } from '@src/containers/VerifiedTalentGateway';
import { trackEvent } from '@src/metrics';
import { Context } from '@src/metrics/enums/context.enum';
import { Interaction } from '@src/metrics/enums/interaction.enum';
import createLink from '@src/util/createLink';
import { getWrapperScrollableContainer } from '@src/App';
import scroll from '@src/util/scroll';
import { PageAnchors } from '@src/enum/pageAnchors.enum';
import { RecentCompanies } from '@src/containers/RecentCompanies';
import { SalaryOverview } from './SalaryOverview';
import { PageHeader } from './PageHeader';
import { ProfileViews } from './ProfileViews';
import { isOnboardedUserProfileType } from './typeGuards';
import { MessageRequests } from './MessageRequests';
import { RecruiterPromo } from './RecruiterPromo';
import './styles.scss';
import { ForYouScroller } from '@src/containers/ForYouScroller';

export const Frank: FC = () => {
  const location = useLocation();

  const history = useHistory();

  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

  const profileViewsRef = useRef<HTMLDivElement>(null);

  const {
    data: verifiedUserData,
    loading: isVerifiedUserLoading,
    refetch,
  } = useCurrentUserQuery({
    fetchPolicy: 'network-only',
  });
  const { data: userData, loading: isUserLoading } = useGetUserQuery();

  useEffect(() => {
    const wrapperScrollableContainer = getWrapperScrollableContainer();
    let anchorTimer: ReturnType<typeof setTimeout>;

    if (queryParams?.anchor && queryParams.anchor === PageAnchors.FRANK_VT_PROFILE_VIEW_ANCHOR) {
      wrapperScrollableContainer?.[0].classList.add('disable-scroll');
    }

    if (
      queryParams?.anchor &&
      queryParams.anchor === PageAnchors.FRANK_VT_PROFILE_VIEW_ANCHOR &&
      !isVerifiedUserLoading &&
      !isUserLoading &&
      profileViewsRef.current
    ) {
      scroll(wrapperScrollableContainer?.[0], 0, profileViewsRef.current.getBoundingClientRect().top);
      anchorTimer = setTimeout(() => {
        history.replace(
          createLink({
            link: location.pathname,
          }),
        );

        wrapperScrollableContainer?.[0].classList.remove('disable-scroll');
      }, 1000);
    }

    return () => {
      if (anchorTimer) {
        clearTimeout(anchorTimer);
      }
    };
  });

  useEffect(() => {
    trackEvent({
      context: Context.HOME,
      interaction: Interaction.VIEW,
    });
  }, []);

  const shouldShowVerifiedTalentGateway =
    !!userData?.currentUser.profile &&
    isOnboardedUserProfileType(userData.currentUser.profile) &&
    userData.currentUser.isEligibleForVerification;

  const userState = verifiedUserData?.currentUser.verification?.state;

  const isPublished = userState === UserVerificationStateEnum.PUBLISHED;

  return (
    <div className="frank">
      <PageHeader />

      <RecruiterPromo />

      {shouldShowVerifiedTalentGateway && (
        <div className="frank__verified-talent">
          <VerifiedTalentGateway
            isLoading={isVerifiedUserLoading}
            onVerifiedUserUpdate={() => {
              refetch();
            }}
            user={verifiedUserData?.currentUser}
          />
        </div>
      )}

      <ForYouScroller />

      {!isPublished && <RecentCompanies userVerificationState={verifiedUserData?.currentUser.verification?.state} />}

      <MessageRequests isPublished={isPublished} />

      <div id="frank-vt-profile-views" ref={profileViewsRef}>
        <ProfileViews isPublished={isPublished} />
      </div>

      {isPublished && <RecentCompanies />}

      {!isUserLoading && <SalaryOverview user={userData?.currentUser} />}
    </div>
  );
};
