import { useEffect, useCallback, useState } from 'react';
import { get, debounce } from 'lodash';
import { NetworkStatus } from '@apollo/client';
import { CombinedSearchTagNameEnum, useLegacySearchCompaniesLazyQuery } from '@src/graphql/generated';
import { handleFetchMore } from '@src/util/handleFetchMore';

export const useSearch = (searchPattern?: string, suggestionsWrapperRef: HTMLDivElement | null): unknown => {
  const [searchedPattern, setSearchedPattern] = useState<string>();
  const [fetchCompanies, { data, loading: isLoading, networkStatus, fetchMore }] = useLegacySearchCompaniesLazyQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const isFetchMoreLoading = networkStatus === NetworkStatus.fetchMore;
  const companiesPagination = data?.legacySearch?.pagination;

  const search = useCallback(
    debounce(searchPattern => {
      fetchCompanies({
        variables: {
          paginationOptions: {
            limit: 6,
          },
          searchPatterns: [{ pattern: searchPattern, tag: CombinedSearchTagNameEnum.COMPANY_NAME }],
          searchTags: [{ tag: CombinedSearchTagNameEnum.TYPE, value: 'COMPANY' }],
        },
      });
      setSearchedPattern(searchPattern);
    }, 300),
    [],
  );

  useEffect(() => {
    if (searchPattern && searchPattern.length > 2) {
      search(searchPattern);
    }
  }, [searchPattern]);

  const loadMoreCompanies = () => {
    if (suggestionsWrapperRef?.current) {
      const { scrollHeight, scrollTop, offsetHeight } = suggestionsWrapperRef.current;

      if (!isFetchMoreLoading && companiesPagination?.hasMoreResults && scrollTop + offsetHeight > scrollHeight - 300) {
        handleFetchMore(fetchMore, {
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) {
              return prev;
            }

            const {
              legacySearch: { result, pagination },
            } = fetchMoreResult;

            return {
              legacySearch: {
                ...prev.legacySearch,
                pagination: {
                  ...prev.legacySearch.pagination,
                  hasMoreResults: pagination.hasMoreResults,
                  maxCursor: pagination.maxCursor,
                },
                result: [...prev.legacySearch.result, ...result],
              },
            };
          },
          variables: {
            paginationOptions: {
              limit: 10,
              minCursor: companiesPagination.maxCursor,
            },
          },
        });
      }
    }
  };

  useEffect(() => {
    if (suggestionsWrapperRef && suggestionsWrapperRef.current) {
      suggestionsWrapperRef.current.addEventListener('scroll', loadMoreCompanies);
    }

    return () => {
      if (suggestionsWrapperRef && suggestionsWrapperRef.current) {
        suggestionsWrapperRef.current.removeEventListener('scroll', loadMoreCompanies);
      }
    };
  }, [companiesPagination, isLoading]);

  return {
    fetchMoreCompanies: fetchMore,
    hasSearchedForPattern: searchedPattern === searchPattern,
    loading: isLoading,
    result: get(data, 'legacySearch.result') || [],
  };
};
