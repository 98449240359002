import React, { Fragment } from 'react';
import NotificationLoading from './NotificationLoading';

export default function LoadingState() {
  return (
    <Fragment>
      <NotificationLoading />
      <NotificationLoading />
      <NotificationLoading />
    </Fragment>
  );
}
