/**
 * adds "k" if number is more than thousand,
 * otherwise returns same number.
 */

export const numberFormatter = (num: number): string | number =>
  Math.abs(num) > 999 ? (Math.sign(num) * (Math.abs(num) / 1000)).toFixed(1) + 'k' : Math.sign(num) * Math.abs(num);

export const numberToMillion = (num: number): string => {
  if (num > 999 && num < 1000000) {
    return (num / 1000).toFixed() + 'K';
  } else if (num >= 1000000 && num < 1000000000) {
    return (num / 1000000).toFixed() + 'M';
  } else if (num >= 1000000000) {
    return (num / 1000000000).toFixed() + 'B';
  }

  return num.toString();
};
