/* This component represents opening apply information. */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Svg from 'svg-inline-react';
import InfoSvg from 'assets/images/fr-info-filled.svg';
import { trackEvent } from '@src/metrics';
import { InvisibleButton, BlueButton } from '@src/components/Button';
import { SETTINGS_HIRE_ROUTE } from '@src/constants/routes';
import './styles.scss';

const INFORMATION_TYPE = {
  'INACTIVE': {
    TEXT: `This position is inactive and is not accepting new applicants.
    We keep all our inactive job openings available on our platform so job seekers could get a better insight about the market.`,
    TITLE: 'Inactive job opening',
    TYPE: 'inactive',
  },
  'POSITION-FILLED': {
    TEXT: `The position was filled using MeetFrank.
    We keep all our inactive job openings available on our platform so job seekers could get a better insight about the market.`,
    TITLE: 'Position is filled and is now inactive.',
    TYPE: 'position-filled',
  },
};

const InformationalModal = ({ type, close }) => {
  return (
    <div className={classnames(`info-modal info-modal--${type}`)}>
      <div className="info-modal__information">
        <Svg className="info-modal__icon" src={InfoSvg} />
        <h3>{INFORMATION_TYPE[type.toUpperCase()].TITLE}</h3>
        <p>{INFORMATION_TYPE[type.toUpperCase()].TEXT}</p>
      </div>
      <div className="info-modal__actions">
        {type === INFORMATION_TYPE['POSITION-FILLED'].TYPE && (
          <div className="info-modal__position-filled-actions">
            <BlueButton className="info-modal__position-filled-got-it" isFilled onClick={close}>
              Got it
            </BlueButton>
            <InvisibleButton
              isLink
              onClick={() =>
                trackEvent({
                  context: 'Position Filled Modal',
                  interaction: 'Open',
                  itemType: 'Hire with MeetFrank',
                })
              }
              to={SETTINGS_HIRE_ROUTE}
            >
              Hire with MeetFrank
            </InvisibleButton>
          </div>
        )}

        {type === INFORMATION_TYPE.INACTIVE.TYPE && (
          <BlueButton className="info-modal__inactive-got-it" isFilled onClick={close}>
            Got it
          </BlueButton>
        )}
      </div>
    </div>
  );
};

InformationalModal.propTypes = {
  close: PropTypes.func.isRequired,
  onApply: PropTypes.func,
  openingId: PropTypes.string,
  type: PropTypes.oneOf([INFORMATION_TYPE['POSITION-FILLED'].TYPE, INFORMATION_TYPE.INACTIVE.TYPE]).isRequired,
};

export default InformationalModal;
