import { useEffect, useRef } from 'react';
import { throttle } from 'lodash';

export const useHorizontalFeedScroll = ({ loadMore }, deps) => {
  const scrollerRef = useRef(null);

  const onScroll = throttle(event => {
    const scrollY = event.target.offsetWidth + event.target.scrollLeft;
    const fetchMoreOffset = event.target.scrollWidth - event.target.offsetWidth * 2;

    if (scrollY > fetchMoreOffset) {
      loadMore();
    }
  }, 250);

  useEffect(() => {
    if (onScroll && scrollerRef && scrollerRef.current) {
      scrollerRef.current.addEventListener('scroll', onScroll);
    }

    return () => {
      if (onScroll && scrollerRef.current) {
        scrollerRef.current.removeEventListener('scroll', onScroll);
      }
      onScroll.cancel();
    };
  }, [onScroll, ...deps]);

  return { scrollerRef };
};
