import React, { FC } from 'react';
import Svg from 'svg-inline-react';
import FrAddSvg from 'assets/images/fr-add.svg';
import { useGetUserQuery, useSpecialitiesQuery } from '@src/graphql/generated';
import { setContent as setModalContent, closeModal } from '@src/redux/actions/slideUpModal';
import { useAppDispatch } from '@src/store';
import { HorizontalScroller } from '@src/components/HorizontalScroller';
import { useQueryParams } from '@src/customHooks/useQueryParams';
import { HeaderLabel } from '@src/components/HeaderLabel';
import { SkeletonLoading } from '@src/components/HeaderLabel/SkeletonLoading';
import { QueryParams } from '../../interfaces/queryParams.interface';
import { SPECIALITY_MAPPING } from '../constants/specialityMapping';
import { SpecialitiesModalContent } from '../SpecialitiesModalContent';
import { Category } from '../../enums/category.enum';
import { DEFAULT_CATEGORY } from '../../constants/categories';
import { SpecialityScrollerProps } from './props';
import './styles.scss';

const ADDITINAL_LABEL_COUNT_ON_TOP = 2;

export const SpecialityScroller: FC<SpecialityScrollerProps> = ({ onSpecialitySelect }) => {
  const dispatch = useAppDispatch();

  const { queryParams } = useQueryParams<QueryParams>();

  const selectedSpecialityId = queryParams?.selectedSpecialityId || DEFAULT_CATEGORY;

  const { data: userData } = useGetUserQuery();

  const { data: specialitiesData, loading: isSpecialitiesDataLoading } = useSpecialitiesQuery();

  const specialityIdToName: Record<string, string | undefined> | undefined = specialitiesData?.specialities?.reduce(
    (acc, speciality) => {
      return {
        ...acc,
        [speciality?.id || '']: speciality?.name,
      };
    },
    {},
  );

  const user = userData?.currentUser;

  const userSpecialityId = user?.inboxProfile?.speciality?.id;

  const openSpecialitiesModal = () => {
    dispatch(
      setModalContent({
        content: (
          <SpecialitiesModalContent
            activeSpecialityId={selectedSpecialityId}
            onClose={() => dispatch(closeModal())}
            onSelect={selectedSpecialityId => {
              onSpecialitySelect(selectedSpecialityId);

              dispatch(closeModal());
            }}
          />
        ),
        height: '65rem',
      }),
    );
  };

  const visibleSpecialityIds = userSpecialityId ? [...SPECIALITY_MAPPING[userSpecialityId]] : [];

  if (selectedSpecialityId && !visibleSpecialityIds.includes(selectedSpecialityId)) {
    visibleSpecialityIds.unshift(selectedSpecialityId);
  }

  const visibleSpecialityIdsTop = visibleSpecialityIds.slice(0, ADDITINAL_LABEL_COUNT_ON_TOP);
  const visibleSpecialityIdsBottom = visibleSpecialityIds.slice(
    ADDITINAL_LABEL_COUNT_ON_TOP,
    visibleSpecialityIds.length,
  );

  return (
    <HorizontalScroller
      className="job-header-speciality-scroll__specialities-scroller"
      containerClassName="job-header-speciality-scroll__container"
    >
      <div className="job-header-speciality-scroll__scroller-lines">
        <div className="job-header-speciality-scroll__scroller-line">
          <HeaderLabel
            isActive={selectedSpecialityId === Category.FOR_YOU}
            onClick={() => {
              onSpecialitySelect(Category.FOR_YOU);
            }}
          >
            For you
          </HeaderLabel>

          <HeaderLabel
            isActive={selectedSpecialityId === Category.ALL_OFFERS}
            onClick={() => {
              onSpecialitySelect(Category.ALL_OFFERS);
            }}
          >
            All offers
          </HeaderLabel>

          {isSpecialitiesDataLoading &&
            [1, 2, 3].map(index => {
              return <SkeletonLoading key={index} />;
            })}

          {!isSpecialitiesDataLoading &&
            visibleSpecialityIdsTop.map(visibleSpecialityId => {
              const specialityName = specialityIdToName?.[visibleSpecialityId];

              if (!specialityName) {
                return null;
              }

              return (
                <HeaderLabel
                  isActive={queryParams.selectedSpecialityId === visibleSpecialityId}
                  key={visibleSpecialityId}
                  onClick={() => onSpecialitySelect(visibleSpecialityId)}
                >
                  {specialityName}
                </HeaderLabel>
              );
            })}
        </div>

        {!!visibleSpecialityIdsBottom.length && (
          <div className="job-header-speciality-scroll__scroller-line">
            {isSpecialitiesDataLoading &&
              [1, 2, 3].map(index => {
                return <SkeletonLoading key={index} />;
              })}

            {!isSpecialitiesDataLoading &&
              visibleSpecialityIdsBottom.map(visibleSpecialityId => {
                const specialityName = specialityIdToName?.[visibleSpecialityId];

                if (!specialityName) {
                  return null;
                }

                return (
                  <HeaderLabel
                    isActive={queryParams.selectedSpecialityId === visibleSpecialityId}
                    key={visibleSpecialityId}
                    onClick={() => onSpecialitySelect(visibleSpecialityId)}
                  >
                    {specialityName}
                  </HeaderLabel>
                );
              })}

            <HeaderLabel onClick={openSpecialitiesModal}>
              <Svg className="job-header-speciality-scroll__add" src={FrAddSvg} />
            </HeaderLabel>
          </div>
        )}
      </div>
    </HorizontalScroller>
  );
};
