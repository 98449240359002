import React, { FC } from 'react';
import classnames from 'classnames';
import { replaceMessageStringVariables } from '@shared/utils/replaceMessageStringVariables';
import { Markdown } from '@src/components/Markdown';
import { SectionDescriptionProps } from './props';
import './styles.scss';

export const SectionDescription: FC<SectionDescriptionProps> = ({
  children,
  userName,
  messageClassName,
  openingTitle,
}) => (
  <Markdown
    markdown={replaceMessageStringVariables(children, {
      name: userName || '',
      title: openingTitle || '',
    })}
    wrapperClassName={classnames('opening-page-section-description', messageClassName)}
  />
);
