import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

export function SectionHeader({ label }) {
  return <div className="search-section-header">{label}</div>;
}

SectionHeader.propTypes = {
  label: PropTypes.string.isRequired,
};
