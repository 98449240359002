import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

export const UserRating = ({ userRating, onChange }) => {
  return (
    <div className="user-rating">
      <strong>Your rating: </strong>
      <span className="user-rating__amount">{userRating.total} </span>
      <span className="user-rating__change" onClick={onChange}>
        Change
      </span>
    </div>
  );
};

UserRating.propTypes = {
  onChange: PropTypes.func.isRequired,
  userRating: PropTypes.shape({
    total: PropTypes.number.isRequired,
  }).isRequired,
};
