import { isEmpty } from 'lodash';
import { LocationType, OpeningRemoteTypeEnum } from '@src/graphql/generated';

export const getLocations = (
  remoteType: OpeningRemoteTypeEnum,
  positionLocations: Pick<LocationType, 'shortName' | 'id'>[],
): string => {
  let locations = positionLocations.length ? positionLocations.map(location => location.shortName).join(', ') : '';

  if (remoteType === OpeningRemoteTypeEnum.FULLY) {
    locations = `${!isEmpty(positionLocations) ? locations + ' or remote' : 'Remote'}`;
  }

  return locations;
};
