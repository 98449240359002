import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

export const MarkdownUnorderedList = ({ key, children }) => {
  return (
    <ul className="markdown-unordered-list" key={key}>
      {children.map(({ key, props }) => (
        <li className={'markdown-unordered-list__item'} key={key}>
          {props.children}
        </li>
      ))}
    </ul>
  );
};

MarkdownUnorderedList.propTypes = {
  children: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      props: PropTypes.shape({
        children: PropTypes.array.isRequired,
      }).isRequired,
      type: PropTypes.string.isRequired,
    }),
  ).isRequired,
  key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
