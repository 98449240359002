import {
  GetSearchRelevantOpeningsV2QueryVariables,
  OpeningRemoteTypeEnum,
  RelevantOpeningsSortOptionEnum,
  SearchOpeningEntityTagNameEnum,
  useGetSearchRelevantOpeningsV2Query,
  useGetUserQuery,
} from '@src/graphql/generated';
import { OPENING_STATE } from '@src/constants';
import { UseRelevantOffersFeedParams, UseRelevantOffersFeedValue } from './interfaces/useRelevantOffersFeed.interface';

const FIRST_BATCH_SIZE = 16;

export const useForYouPreviewFeed = ({
  openingState = OPENING_STATE.ACTIVE,
  sortOption = RelevantOpeningsSortOptionEnum.RECENCY,
}: UseRelevantOffersFeedParams): UseRelevantOffersFeedValue => {
  const { data: userData } = useGetUserQuery({ fetchPolicy: 'cache-first' });
  const user = userData?.currentUser;

  const getSearchRelevantOpeningsQueryVariables: GetSearchRelevantOpeningsV2QueryVariables = {
    searchTags: [
      {
        tag: SearchOpeningEntityTagNameEnum.STATE,
        value: openingState,
      },
    ],
    searchTagsWithOrCondition: [
      {
        tag: SearchOpeningEntityTagNameEnum.POSITION_COUNTRY,
        value: user?.location?.country?.id,
      },
      {
        tag: SearchOpeningEntityTagNameEnum.REMOTE_TYPE,
        value: OpeningRemoteTypeEnum.FULLY,
      },
    ],
    sortedPaginationOptions: {
      limit: FIRST_BATCH_SIZE,
      sortBy: sortOption,
    },
  };

  const { data: searchRelevantOpeningsData, loading: isSearchRelevantOpeningsLoading } =
    useGetSearchRelevantOpeningsV2Query({
      notifyOnNetworkStatusChange: true,
      variables: getSearchRelevantOpeningsQueryVariables,
    });

  const pagination = searchRelevantOpeningsData?.searchRelevantOpenings?.searchResultsV2.pagination;

  const feed = searchRelevantOpeningsData?.searchRelevantOpenings?.searchResultsV2.result || [];

  return {
    feed: feed,
    isLoading: isSearchRelevantOpeningsLoading,
    pagination: pagination,
  };
};
