import React, { FC, useEffect, useState } from 'react';
import { find } from 'lodash';
import Scroller from '@src/components/Scroller';
import { Select } from '@src/components/Salary/Select';
import {
  GetSalaryStepsDocument,
  GetSalaryStepsQuery,
  SalaryPeriodEnum,
  useGetSalaryPeriodsQuery,
} from '@src/graphql/generated';
import { apolloClient } from '@src/graphql/apollo';
import { SalaryData, SalaryKeys } from './interfaces/salary.interface';
import { MapOptionValue } from './interfaces/mapOptions.interface';
import { SalaryProps } from './props';
import './styles.scss';

export const Salary: FC<SalaryProps> = ({ currencies, onSalaryChange, salary }) => {
  const [options, setOptions] = useState<MapOptionValue[]>();

  const { data: salaryPeriodsData, loading: isLoading } = useGetSalaryPeriodsQuery();

  const salaryPeriodEnumByPeriodValue: Record<string, SalaryPeriodEnum> = {
    hour: SalaryPeriodEnum.HOUR,
    month: SalaryPeriodEnum.MONTH,
    total: SalaryPeriodEnum.TOTAL,
    week: SalaryPeriodEnum.WEEK,
    year: SalaryPeriodEnum.YEAR,
  };

  const periodValueBySalaryPeriodEnum: Record<SalaryPeriodEnum, string> = {
    [SalaryPeriodEnum.HOUR]: 'hour',
    [SalaryPeriodEnum.MONTH]: 'month',
    [SalaryPeriodEnum.TOTAL]: 'total',
    [SalaryPeriodEnum.WEEK]: 'week',
    [SalaryPeriodEnum.YEAR]: 'year',
  };

  const mapOptions = (options: GetSalaryStepsQuery['salarySteps'], symbol: string): MapOptionValue[] => {
    const _options: MapOptionValue[] = options.map((option, index) => {
      if (index === 0) {
        return {
          key: option.step,
          value: `${symbol}${option.step} or less`,
        };
      }

      return {
        key: option.step,
        value: index === options.length - 1 ? `${symbol}${option.step} or more` : `${symbol}${option.step}`,
      };
    });

    return [{ key: null, value: 'Slide down to select' }, ..._options];
  };

  const mappedPeriods = (salaryPeriodsData?.salaryPeriods || []).map(period => {
    return {
      ...period,
      key: period.value,
      text: period.label,
    };
  });

  const mappedCurrencies =
    currencies?.map(currency => {
      return {
        key: currency.code,
        symbol: currency.symbol,
        text: currency.code,
        value: currency.code,
      };
    }) || [];

  const fetchData = async () => {
    const { data } = await apolloClient.query<GetSalaryStepsQuery>({
      fetchPolicy: 'no-cache',
      query: GetSalaryStepsDocument,
      variables: {
        currencyCode: salary.currency,
        period: salary.period.toUpperCase(),
      },
    });

    const selectedCurrency = find(mappedCurrencies, { value: salary.currency });
    const symbol = selectedCurrency?.symbol || '';

    setOptions(mapOptions(data.salarySteps, symbol));
  };

  const fetchSalaries = async (currency: string, period: string): Promise<void> => {
    const { data } = await apolloClient.query<GetSalaryStepsQuery>({
      fetchPolicy: 'no-cache',
      query: GetSalaryStepsDocument,
      variables: {
        currencyCode: salary.currency,
        period: period.toUpperCase(),
      },
    });

    const selectedCurrency = find(mappedCurrencies, { value: currency });
    const symbol = selectedCurrency?.symbol || '';

    setOptions(mapOptions(data.salarySteps, symbol));
  };

  const onSalaryValueChange = (key: SalaryKeys, value: string) => {
    const newSalary: SalaryData = {
      ...salary,
      [key]: value,
    };

    onSalaryChange(newSalary);

    if (['currency', 'period'].includes(key)) {
      fetchSalaries(newSalary.currency, newSalary.period);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (!options?.length || isLoading) {
    return <span className="availability-request-loading">Loading...</span>;
  }

  return (
    <div className="availability-request-salary">
      <h1>
        What’s your minimum required&nbsp;
        <Select
          onChange={({ value }) => {
            const salaryPeriod = Object.values(SalaryPeriodEnum).includes(value)
              ? periodValueBySalaryPeriodEnum[value]
              : value;

            onSalaryValueChange('period', salaryPeriod);
          }}
          options={mappedPeriods}
          selectedValue={mappedPeriods.find(
            period => period.value === salary.period || period.value === salaryPeriodEnumByPeriodValue[salary.period],
          )}
        />
        {' salary in '}
        <Select
          onChange={({ value }) => onSalaryValueChange('currency', value)}
          options={mappedCurrencies}
          selectedValue={mappedCurrencies.find(currency => currency.value === salary.currency)}
        />
      </h1>

      <h2>
        <strong style={{ color: 'black' }}>Gross Salary</strong> - salary number before the taxes are taken off.
      </h2>

      <Scroller
        className="slide-up-item"
        defaultValue={salary ? find(options, { key: salary.value }) : undefined}
        identifier="minSalary"
        onValueSelect={({ minSalary }) => onSalaryValueChange('value', minSalary.key)}
        options={options}
      />
    </div>
  );
};
