import { get, throttle } from 'lodash';
import { useLayoutEffect } from 'react';
import { handleFetchMore } from '@src/util/handleFetchMore';
import { useGetBookmarkedOpeningsQuery } from '@src/graphql/generated';
import { GetFeed } from './interfaces/getFeed.interface';
import { GetPagination } from './interfaces/getPagination.interface';
import { UseBookmarkedOpenings } from './interfaces/useBookmarkedOpenings';

const FIRST_BATCH_SIZE = 6;
const NEXT_BATCH_SIZE = 6;

const getFeed: GetFeed = data => get(data, 'bookmarkedOpenings.result') || [];
const getPagination: GetPagination = data => get(data, 'bookmarkedOpenings.pagination') || {};

export const useBookmarkedOpenings: UseBookmarkedOpenings = ({ getScrollableContainer }) => {
  const {
    data,
    loading: isLoading,
    fetchMore,
  } = useGetBookmarkedOpeningsQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      paginationOptions: {
        limit: FIRST_BATCH_SIZE,
      },
    },
  });

  const feed = getFeed(data);
  const pagination = getPagination(data);

  const onLoadMore = throttle(({ target: { scrollTop, scrollHeight } }) => {
    const fetchMoreMargin = scrollTop + window.innerHeight * 3;

    if (!isLoading && fetchMoreMargin > scrollHeight && pagination?.hasMoreResults) {
      handleFetchMore(fetchMore, {
        updateQuery: (previousValue, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return previousValue;
          }

          return {
            ...previousValue,
            bookmarkedOpenings: {
              ...previousValue.bookmarkedOpenings,
              pagination: {
                ...getPagination(previousValue),
                ...getPagination(fetchMoreResult),
              },
              result: [...getFeed(previousValue), ...getFeed(fetchMoreResult)],
            },
          };
        },
        variables: {
          paginationOptions: {
            limit: NEXT_BATCH_SIZE,
            minCursor: pagination.maxCursor,
          },
        },
      });
    }
  }, 250);

  useLayoutEffect(() => {
    getScrollableContainer().addEventListener('scroll', onLoadMore);

    return () => {
      getScrollableContainer().removeEventListener('scroll', onLoadMore);
      onLoadMore.cancel();
    };
  }, [isLoading, feed]);

  return {
    feed: feed,
    hasMoreResults: pagination?.hasMoreResults,
    isLoading: isLoading,
  };
};
