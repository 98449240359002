import React, { FC } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import FadeInImage from '@src/components/FadeInImage';
import { Score } from '@src/components/Score';
import { COMPANY_ROUTE, JOB_OPENING_ROUTE } from '@src/constants/routes';
import createLink from '@src/util/createLink';
import { CompanyWithAwardsProps } from './props';
import './styles.scss';

export const CompanyWithAwards: FC<CompanyWithAwardsProps> = ({
  isAwardsHidden,
  company,
  isDark,
  wrapperClassName,
  onRatingItemClick,
  title,
  openingId,
  subTitle,
  titleClassName,
  subTitleClassName,
}) => {
  const totalRatingsCount = company?.reviewsOverview?.totalCount || 0;
  const isRatingVisible = totalRatingsCount > 0 && !isAwardsHidden && !!onRatingItemClick;
  const totalRating = company?.reviewsOverview?.ratings.total;

  const getTrimmedSubTitle = (): string => {
    if (typeof subTitle === 'string') {
      return subTitle.trim();
    } else if (subTitle) {
      return subTitle;
    } else {
      return company?.profile?.name.trim() || '';
    }
  };

  return (
    <div className={classnames('company-with-awards', wrapperClassName)}>
      {company && (
        <Link
          className="company-with-awards__company-section"
          to={createLink({
            link: COMPANY_ROUTE,
            params: { companyId: company.id },
          })}
        >
          <FadeInImage
            className={classnames('company-with-awards__logo', isDark && 'company-with-awards__logo--no-shadow')}
            src={company.profile?.logo?.fullUrl}
          />
        </Link>
      )}

      <div className="company-with-awards__details">
        {title && (
          <Link
            className={classnames(
              'company-with-awards__details-title',
              isDark && 'company-with-awards__details-title--white',
              titleClassName,
            )}
            to={createLink({
              link: JOB_OPENING_ROUTE,
              params: { openingId: openingId },
            })}
          >
            {title}
          </Link>
        )}

        <div className="company-with-awards__details-subtitle-wrapper">
          {company && (
            <Link
              className={classnames('company-with-awards__details-subtitle', subTitleClassName)}
              to={createLink({
                link: COMPANY_ROUTE,
                params: { companyId: company.id },
              })}
            >
              {getTrimmedSubTitle()}
            </Link>
          )}

          {company && isRatingVisible && totalRating && (
            <Score
              countClassName={classnames(
                'company-with-awards__score-count',
                isDark && 'company-with-awards__score-count--white',
              )}
              onClick={e => {
                e.preventDefault();

                onRatingItemClick();
              }}
              ratings={totalRatingsCount}
              score={totalRating}
              size={'x-small'}
              wrapperClassName={classnames(
                'company-with-awards__score-wrapper',
                isDark && 'company-with-awards__score-wrapper--white',
              )}
            />
          )}
        </div>
      </div>
    </div>
  );
};
