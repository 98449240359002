import React, { PureComponent, Fragment } from 'react';
import { isArray } from 'lodash';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import FadeInImage from '@src/components/FadeInImage';
import { hideContainerStatusBar, showBlackContainerStatusBar } from '@src/container';
import './styles.scss';

// opacity appear speed relative to scroll
const GRADIENT_OPACITY_SPEED = 1.2;
const IMAGE_PARALLAX_SPEED = 0.5;

export default class ParallaxZoomImage extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    gradientClassName: PropTypes.string,
    hasGradient: PropTypes.bool,
    hideStatusBar: PropTypes.bool,
    imageClassName: PropTypes.string,
    imageUrl: PropTypes.string,
    isLoading: PropTypes.bool,
    opacityShowSpeed: PropTypes.number,
  };

  static defaultProps = {
    hideStatusBar: true,
    opacityShowSpeed: GRADIENT_OPACITY_SPEED,
  };

  state = {
    imageDefaultHeight: 0,
    imageDefaultWidth: 0,
  };

  imageRef = undefined;
  gradientRef = undefined;
  scrollRef = undefined;

  setImageRef = it => {
    this.imageRef = it;

    if (this.imageRef) {
      this.setState({
        imageDefaultHeight: this.imageRef.clientHeight,
        imageDefaultWidth: this.imageRef.clientWidth,
      });
    }
  };

  setScrollRef = it => (this.scrollRef = it); // eslint-disable-line no-return-assign
  setGradientRef = it => (this.gradientRef = it); // eslint-disable-line no-return-assign

  componentDidMount() {
    this.scrollRef.addEventListener('scroll', this.onScroll);
    if (this.props.hideStatusBar) {
      hideContainerStatusBar();
    }
  }

  componentWillUnmount() {
    this.scrollRef.removeEventListener('scroll', this.onScroll);

    if (this.props.hideStatusBar) {
      showBlackContainerStatusBar();
    }
  }

  onScroll = evt => {
    window.requestAnimationFrame(this.updatePositions);
  };

  updatePositions = () => {
    if (this.scrollRef && this.imageRef) {
      const scroll = this.scrollRef.scrollTop;
      const { imageDefaultHeight, imageDefaultWidth } = this.state;
      const { opacityShowSpeed } = this.props;

      if (scroll > 0) {
        if (imageDefaultHeight >= scroll) {
          // scroll speed relative to opacity
          this.imageRef.style.transform = `translate3d(0, -${scroll * IMAGE_PARALLAX_SPEED}px, 0)`;

          if (this.gradientRef) {
            this.gradientRef.style.opacity = (scroll / imageDefaultHeight) * opacityShowSpeed;
          }
        }
      } else if (scroll === 0) {
        this.imageRef.style.transform = 'translate3d(0, 0, 0)';

        if (this.gradientRef) {
          this.gradientRef.style.opacity = 0;
        }
      } else {
        const _scroll = Math.abs(scroll);
        const height = `${imageDefaultHeight + _scroll}px`;
        const widthAddition = _scroll * 0.7;
        const width = `${imageDefaultWidth + widthAddition}px`;
        const transform = `translate3d(-${widthAddition / 2}px, 0, 0)`;
        this.imageRef.style.transform = transform;
        this.imageRef.style.width = width;
        this.imageRef.style.height = height;
      }
    }
  };

  render() {
    const { children, imageUrl, imageClassName, hasGradient, gradientClassName, isLoading } = this.props;
    const { imageDefaultHeight } = this.state;

    const childrenWithGradient = React.cloneElement(children, {
      children: [
        hasGradient && (
          <div
            className="parallax-zoom-image-gradient"
            key="parallax-zoom"
            // 0.2rem to hide possible gap between gradient and content
            ref={this.setGradientRef}
            style={{ height: `calc(${imageDefaultHeight}px + 2rem)` }}
          />
        ),
        ...(isArray(children.props.children) ? children.props.children : [children.props.children]),
      ],
      ref: this.setScrollRef,
      style: { paddingTop: imageDefaultHeight },
    });

    return (
      <Fragment>
        {imageUrl && !isLoading ? (
          <Fragment>
            <FadeInImage
              className={classnames('parallax-zoom-image', imageClassName)}
              setRef={this.setImageRef}
              src={imageUrl}
            />
            {hasGradient && <div className={classnames('parallax-zoom-image-fixed-gradient', gradientClassName)} />}
          </Fragment>
        ) : (
          <div
            className={classnames('parallax-zoom-image-loading pulse-fade', imageClassName)}
            ref={this.setImageRef}
          />
        )}

        {childrenWithGradient}
      </Fragment>
    );
  }
}
