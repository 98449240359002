import React from 'react';
import PropTypes from 'prop-types';
import { Markdown } from '../';

export const MarkdownCode = props => {
  return <Markdown markdown={props.children} />;
};

MarkdownCode.propTypes = {
  children: PropTypes.object.isRequired,
};
