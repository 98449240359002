import React, { FC, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { toggleFixedHeader } from '@src/util/fixedHeader';
import Navigation from '@src/components/Navigation';
import { FixedHeader } from '@src/components/FixedHeader';
import { showBlackContainerStatusBar, showWhiteContainerStatusBar } from '@src/container';
import Skeleton from './Skeleton';
import './styles.scss';

export const StickyNavigation: FC = ({
  headerNavigationIsHidden,
  loading,
  title,
  navigationTabs,
  getScrollableContainer,
}) => {
  const history = useHistory();

  const navigationWrapperRef = useRef(null);

  const fixedHeaderWrapperRef = useRef(null);

  const wrapperScrollableContainer = getScrollableContainer();

  useEffect(() => {
    wrapperScrollableContainer[0].addEventListener('scroll', displayFixedHeader);

    return () => {
      wrapperScrollableContainer[0].removeEventListener('scroll', displayFixedHeader);
      showBlackContainerStatusBar();
    };
  }, []);

  const displayFixedHeader = () => {
    const headerOpacity = toggleFixedHeader(
      wrapperScrollableContainer[0].scrollTop,
      navigationWrapperRef.current,
      fixedHeaderWrapperRef.current,
      100,
    );

    if (headerOpacity === 1) {
      showBlackContainerStatusBar();
    }

    if (headerOpacity === 0) {
      showWhiteContainerStatusBar();
    }
  };

  if (loading) {
    return <Skeleton />;
  }

  return (
    <div className="sticky-navigation">
      <FixedHeader onBack={history.goBack} ref={fixedHeaderWrapperRef} title={title}>
        {!headerNavigationIsHidden && <Navigation navigationTabs={navigationTabs} ref={navigationWrapperRef} />}
      </FixedHeader>

      <Navigation
        navigationTabs={navigationTabs}
        ref={navigationWrapperRef}
        wrapperClassName="sticky-navigation__initial"
      />
    </div>
  );
};

StickyNavigation.propTypes = {
  getScrollableContainer: PropTypes.func.isRequired,
  headerNavigationIsHidden: PropTypes.bool,
  loading: PropTypes.bool,
  navigationTabs: PropTypes.array.isRequired,
  title: PropTypes.string,
};
