import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import FrBriefcaseSvg from 'assets/images/fr-briefcase.svg';
import StoryItem from '@src/components/AvailabilityRequest/Story/Item';
import NamePlaceholderSvg from './name-placeholder.svg';
import './styles.scss';

function renderNameIcon(nameInitial) {
  return (
    <div className="inbox-profile-user-data-name">
      <span>{(nameInitial || 'F').toUpperCase()}</span>
    </div>
  );
}

export default class UserData extends PureComponent {
  static propTypes = {
    editUser: PropTypes.func.isRequired,
    jobTitle: PropTypes.string,
    userName: PropTypes.string,
  };

  render() {
    const { userName, jobTitle, editUser } = this.props;
    const nameInitial = userName[0];

    return (
      <div className="inbox-profile-user-data availability-request-story">
        <StoryItem
          data={<span className="availability-request-story-highlight">{userName || 'Fill in your first name'}</span>}
          icon={NamePlaceholderSvg}
          iconElem={renderNameIcon(nameInitial)}
          label="My first name is"
          onClick={() => editUser('name')}
        />

        <StoryItem
          data={<span className="availability-request-story-highlight">{jobTitle || 'Fill your position title'}</span>}
          icon={FrBriefcaseSvg}
          label="And my current position is"
          onClick={() => editUser('position')}
        />
      </div>
    );
  }
}
