import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Svg from 'svg-inline-react';
import FrCheckedSvg from 'assets/images/fr-checkbox-checked.svg';
import FrUncheckedSvg from 'assets/images/fr-checkbox-unchecked.svg';
import './styles.scss';

export default class Checkbox extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    disableStateChange: PropTypes.bool,
    disabled: PropTypes.bool,
    invalid: PropTypes.bool,
    label: PropTypes.node,
    onChange: PropTypes.func,
    propagateEvent: PropTypes.bool,
    value: PropTypes.bool,
    wrapperClassName: PropTypes.string,
  };

  state = {
    checked: !!this.props.value,
  };

  componentWillReceiveProps(props) {
    this.setState({ checked: !!props.value });
  }

  preventEventPropagation = evt => evt.stopPropagation();

  onClick = evt => {
    const { propagateEvent, onChange, disableStateChange } = this.props;
    if (!propagateEvent) {
      this.preventEventPropagation(evt);
    }

    if (!disableStateChange) {
      this.setState(
        ({ checked }) => ({ checked: !checked }),
        () => onChange && onChange(this.state.checked),
      );
    } else {
      onChange && onChange();
    }
  };

  render() {
    const { label, wrapperClassName, className, invalid, disabled } = this.props;
    const { checked } = this.state;

    return (
      <label className={classnames('frank-checkbox', checked && 'active', wrapperClassName)}>
        <input disabled={disabled} onClick={this.onClick} type="checkbox" value={checked} />

        <Svg
          className={classnames('frank-checkbox-icon', className, invalid && 'invalid')}
          onClick={this.preventEventPropagation}
          src={checked ? FrCheckedSvg : FrUncheckedSvg}
        />

        {label && <span>{label}</span>}
      </label>
    );
  }
}
