import { ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getToken } from '../utils/getToken';
import { createAuthHeader } from '../utils/createAuthHeader';

export const authLink: ApolloLink = setContext((_, { headers }) => {
  const accessToken = getToken();

  return {
    credentials: 'include',
    headers: {
      ...headers,
      'Apollo-Require-Preflight': 'true',
      'Authorization': accessToken ? createAuthHeader(accessToken) : null,
    },
  };
});
