// Component link from figma - https://www.figma.com/file/ynSJbcEdvXdWTeDRnD3RA4/Consumer-Mobile-App%3A-Concepts?node-id=1131%3A13535
import React, { FC } from 'react';
import classnames from 'classnames';
import FadeInImage from '../FadeInImage';
import { CompanyThumbnailCardProps } from './props';
import './styles.scss';

export * from './Skeleton';

export const CompanyThumbnailCard: FC<CompanyThumbnailCardProps> = ({
  onCardClick,
  bgImageUrl,
  wrapperClassName,
  logoUrl,
  title,
  subTitle,
}) => {
  return (
    <div className={classnames('company-thumbnail-card', wrapperClassName)} onClick={() => onCardClick()}>
      <FadeInImage className="company-thumbnail-card__image" src={bgImageUrl} />

      <FadeInImage
        className="company-thumbnail-card__logo"
        size="large"
        src={logoUrl}
        wrapperClassName="company-thumbnail-card__logo-wrapper"
      />

      <div className="company-thumbnail-card__info">
        <h3>{title}</h3>
        <div className="company-thumbnail-card__info-subtitle">{subTitle}</div>
      </div>
    </div>
  );
};
