import { SET_EMOJI_SUPPORTED_STATUS } from '@src/redux/actions/emoji';

const threadInitialState = {
  isEmojiSupported: true,
};

export function emoji(state = threadInitialState, action) {
  switch (action.type) {
    case SET_EMOJI_SUPPORTED_STATUS:
      return {
        ...state,
        isEmojiSupported: action.status,
      };
    default:
      return state;
  }
}
