import { intersectionBy } from 'lodash';
import classnames from 'classnames';
import React, { FC } from 'react';
import { idify } from '@src/util/idify';
import { SelectItem } from '../SelectItem';
import { TreeSelectOption } from './interfaces/treeSelectOption.interface';
import { TreeSelectProps } from './props';
import './styles.scss';

export const TreeSelect: FC<TreeSelectProps> = ({ className, limit, onValueSelect, options, selectedOptions = [] }) => {
  const selectItem = (item: TreeSelectOption): void => {
    onValueSelect(item);
  };

  const checkIfActive = (option: TreeSelectOption): boolean => {
    return selectedOptions.some(selectedOption => {
      if (idify(selectedOption) && idify(option)) {
        return idify(selectedOption) === idify(option);
      }

      return selectedOption.key === option.key;
    });
  };

  const isLimitReached = (): boolean => {
    const mappedOptions = options.map(idify);
    const mappedSelectedOptions = selectedOptions.map(idify);

    return intersectionBy(mappedOptions, mappedSelectedOptions).length >= limit;
  };

  const hasLimitReached = isLimitReached();

  return (
    <div className={classnames('treeselect', className)}>
      {options.map(option => {
        const isActive = checkIfActive(option);
        const isDisabled = hasLimitReached && !isActive;

        if (option?.children?.length) {
          return [
            <SelectItem
              active={isActive}
              disabled={!isActive && isDisabled}
              isParent
              key={idify(option) || option.key}
              onSelect={selectItem}
              option={option}
            />,
            checkIfActive(option) &&
              option.children.map(child => {
                const isActiveChild = checkIfActive(child);

                return (
                  <SelectItem
                    active={isActiveChild}
                    disabled={!isActiveChild && isDisabled}
                    isChild
                    key={idify(child) || child.key}
                    onSelect={selectItem}
                    option={child}
                    showCheckmark
                  />
                );
              }),
          ];
        }

        return (
          <SelectItem
            active={isActive}
            disabled={!isActive && isDisabled}
            key={idify(option) || option.key}
            onSelect={selectItem}
            option={option}
            showCheckmark
          />
        );
      })}
    </div>
  );
};
