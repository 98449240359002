import React, { FC } from 'react';
import Svg from 'svg-inline-react';
import classNames from 'classnames';
import { CategoryItemProps } from './props';
import './styles.scss';

export const CategoryItem: FC<CategoryItemProps> = ({ onClick, isActive, src, isLoading, name }) => {
  return (
    <div
      className={classNames('jobs-search-category-item', {
        'jobs-search-category-item--active': isActive,
        'pulse-fade': isLoading,
      })}
      onClick={() => onClick()}
    >
      {src && <Svg className="jobs-search-category-item__icon" src={src} />}

      <span>{name}</span>
    </div>
  );
};
