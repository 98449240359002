import { STATIC_MEDIA_PREFIX } from '@src/constants';
import { CountryType, PlaceTypeEnum, SearchOpeningEntityTagNameEnum } from '@src/graphql/generated';
import { idify } from '@src/util/idify';
import { ALL_PLACE_ID, REMOTE_PLACE_ID } from '@src/constants/placeIds';
import {
  AreaTypeToCreatePlaceItem,
  CountryTypeToCreatePlaceItem,
  CreatePlaceItemArgument,
  PlaceItem,
} from './interface/createPlaceItem.interface';
import { SearchTagsToFilterItems } from './interface/searchTagsToFilterItems.interface';
import { TransformItemsToSearchTags } from './interface/transformItemsToSearchTags.interface';
import { isAreaInterface, isCountryInterface, isLocationInterface } from './typeGuards';

export const createFlagTemplateUrl = (shortName: string): string => {
  return `${STATIC_MEDIA_PREFIX}/flags/160x120/${shortName.toLowerCase()}.png`;
};

export const createPlaceItem = ({ entity, type }: CreatePlaceItemArgument): PlaceItem | undefined => {
  if (!type || !entity) {
    return undefined;
  }

  if (isLocationInterface(entity) && type === PlaceTypeEnum.LOCATION) {
    return {
      id: idify(entity),
      image: {
        templateUrl: entity.country ? createFlagTemplateUrl(entity.country.shortName) : undefined,
      },
      title: entity.shortName || entity.main,
      type: type,
    };
  }

  if (isCountryInterface(entity) && type === PlaceTypeEnum.COUNTRY) {
    return {
      id: idify(entity),
      image: {
        templateUrl: createFlagTemplateUrl(entity.shortName),
      },
      longName: entity.longName,
      shortName: entity.shortName,
      title: entity.longName,
      type: type,
    };
  }

  if (isAreaInterface(entity) && type === PlaceTypeEnum.AREA) {
    return {
      id: idify(entity),
      title: entity?.longName,
      type: type,
    };
  }

  if (type === PlaceTypeEnum.REMOTE) {
    return {
      id: 'remote',
      image: {
        templateUrl: createFlagTemplateUrl('remote'),
      },
      title: 'Remote',
      type: type,
    };
  }

  if (type === PlaceTypeEnum.RELOCATION) {
    return {
      id: 'relocation',
      image: {
        templateUrl: createFlagTemplateUrl('relocation'),
      },
      title: 'Relocation',
      type: type,
    };
  }

  return undefined;
};

export const getCountryFlagTemplateUrl = (shortName: string) => {
  return `${STATIC_MEDIA_PREFIX}/flags/160x120/${shortName.toLowerCase()}.png`;
};

export const createCountryItem = (country: Pick<CountryType, 'id' | 'shortName' | 'longName'>) => {
  return country
    ? {
        id: country.id,
        image: {
          templateUrl: getCountryFlagTemplateUrl(country.shortName),
        },
        title: country.longName,
        type: 'openingLocation',
      }
    : undefined;
};

export const searchTagsToFilterItems: SearchTagsToFilterItems = (key, searchTags = []) => {
  switch (key) {
    case SearchOpeningEntityTagNameEnum.SENIORITY:
    case SearchOpeningEntityTagNameEnum.SKILL:
    case SearchOpeningEntityTagNameEnum.POSITION_LOCATION:
    case SearchOpeningEntityTagNameEnum.REMOTE_AREA:
    case SearchOpeningEntityTagNameEnum.REMOTE_COUNTRY:
    case SearchOpeningEntityTagNameEnum.REMOTE_ALLOWED_LOCATION:
    case SearchOpeningEntityTagNameEnum.REMOTE_ALLOWED_COUNTRY:
    case SearchOpeningEntityTagNameEnum.REMOTE_ALLOWED_AREA:
      return searchTags
        .filter(filter => {
          return filter.tag === key && filter.value !== undefined && filter.value !== null;
        })
        .map(filter => ({
          id: filter.value,
        }));
    default:
      return [];
  }
};

export const transformItemsToSearchTags: TransformItemsToSearchTags = (key, items) => {
  switch (key) {
    case SearchOpeningEntityTagNameEnum.SENIORITY:
    case SearchOpeningEntityTagNameEnum.SKILL:
      return items.map(it => ({
        tag: key,
        value: it.id,
      }));
    default:
      return [];
  }
};

export const getPlaceTypeFromTypeName = (
  place: AreaTypeToCreatePlaceItem | CountryTypeToCreatePlaceItem,
): PlaceTypeEnum => {
  if (place.__typename === 'AreaType') {
    return PlaceTypeEnum.AREA;
  }

  return PlaceTypeEnum.COUNTRY;
};

export const createPlaceItemsFromPlaces = (
  places: (AreaTypeToCreatePlaceItem | CountryTypeToCreatePlaceItem)[],
): PlaceItem[] => {
  const savedPlaceItems: PlaceItem[] = [];

  if (places.length) {
    places.forEach(place => {
      const placeItem = createPlaceItem({
        entity: place,
        type: getPlaceTypeFromTypeName(place),
      });

      if (placeItem) {
        savedPlaceItems.push(placeItem);
      }
    });
  }

  return savedPlaceItems;
};

export const createDefaultCountryItemsFromPlaces = (
  countries: CountryTypeToCreatePlaceItem[],
  userCountry?: PlaceItem,
): PlaceItem[] => {
  const savedPlaceItems = createPlaceItemsFromPlaces(countries);

  const defaultPlaceItems: PlaceItem[] = [
    {
      id: ALL_PLACE_ID,
      shortName: 'All',
    },
    ...(userCountry ? [userCountry] : []),
  ];

  return [...defaultPlaceItems, ...savedPlaceItems];
};

export const createDefaultPlaceItemsFromPlaces = (
  places: (AreaTypeToCreatePlaceItem | CountryTypeToCreatePlaceItem)[],
  userPlaceItem?: PlaceItem,
): PlaceItem[] => {
  const savedPlaceItems = createPlaceItemsFromPlaces(places);

  const defaultPlaceItems: PlaceItem[] = [
    {
      id: ALL_PLACE_ID,
      shortName: 'All',
    },
    ...(userPlaceItem ? [userPlaceItem] : []),
    {
      id: REMOTE_PLACE_ID,
      shortName: 'Remote',
      type: PlaceTypeEnum.REMOTE,
    },
  ];

  return [...defaultPlaceItems, ...savedPlaceItems];
};
