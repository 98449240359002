import React from 'react';
import { map, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import Svg from 'svg-inline-react';
import classnames from 'classnames';
import FrTwitterSvg from 'assets/images/fr-twitter.svg';
import FrFacebookSvg from 'assets/images/fr-facebook.svg';
import VisaSvg from '@shared/assets/svgs/visa.svg';
import FrInstagramSvg from 'assets/images/fr-instagram.svg';
import FrLinkedInSvg from 'assets/images/fr-linkedin.svg';
import socialMediaHandle from '@src/util/socialMediaHandle';
import './styles.scss';

function svgFromSource(source) {
  switch (source) {
    case 'facebook':
      return FrFacebookSvg;
    case 'instagram':
      return FrInstagramSvg;
    case 'website':
      return VisaSvg;
    case 'linkedin':
      return FrLinkedInSvg;
    case 'twitter':
      return FrTwitterSvg;
  }
}

function url(media, full) {
  const handle = socialMediaHandle(media.url, media.source);

  switch (media.source) {
    case 'website':
      const re = new RegExp('^(http|https)://');

      if (re.test(media.url)) {
        return media.url;
      } else {
        return `${full ? 'http://' : ''}${media.url}`;
      }

    case 'facebook':
      return `${full ? 'https://' : ''}facebook.com/${handle}`;
    case 'instagram':
      return `${full ? 'https://' : ''}instagram.com/${handle}`;
    case 'linkedin':
      return `${full ? 'https://' : ''}linkedin.com/company/${handle}`;
    case 'twitter':
      return `${full ? 'https://' : ''}twitter.com/${handle}`;
  }
}

function link(media) {
  return (
    <a
      className={classnames('company-profile-social__item', media.source)}
      href={url(media, true)}
      key={media.source}
      target="_"
    >
      <Svg src={svgFromSource(media.source)} />
      <span>{url(media)}</span>
    </a>
  );
}

export const SocialMedia = ({ socialMedia }) => {
  if (isEmpty(socialMedia)) {
    return null;
  }

  return <div className="company-profile-social">{map(socialMedia, link)}</div>;
};

SocialMedia.propTypes = {
  socialMedia: PropTypes.arrayOf(
    PropTypes.shape({
      source: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
  ),
};
