import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { goBack } from 'connected-react-router';
import ContentPush from '@src/components/ContentPush';
import { setNavigationVisibility } from '@src/redux/actions/navigation';
import api from '@src/util/api';

@connect(undefined, { goBack, setNavigationVisibility })
export default class Feedback extends PureComponent {
  static propTypes = {
    goBack: PropTypes.func.isRequired,
    setNavigationVisibility: PropTypes.func.isRequired,
  };

  state = {
    loading: true,
  };

  componentDidMount() {
    this.props.setNavigationVisibility(false);
    this.fetchQuestion();
  }

  fetchQuestion = () => {
    api('/api/app/menu/feedback', { delay: 400 }).then(this.setQuestion).catch(this.props.goBack);
  };

  setQuestion = ({ question }) => {
    this.setState({
      loading: false,
      question,
    });
  };

  save = () => {
    const { selectedValue } = this.state;

    this.setState({ loading: true }, () => {
      api('/api/app/menu/feedback', {
        json: selectedValue,
        method: 'POST',
      }).then(this.props.goBack);
    });
  };

  onInputChange = (key, value) => {
    this.setState({ selectedValue: { [key]: value } });
  };

  render() {
    const { question, loading, selectedValue } = this.state;

    if (!question) {
      return null;
    }

    return (
      <ContentPush
        keepScrollTop
        loading={loading}
        onContinue={this.save}
        onInputChange={this.onInputChange}
        question={question}
        selectedValue={selectedValue}
        sendBack={this.props.goBack}
        showHeader
      />
    );
  }
}
