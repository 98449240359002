import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { goBack } from 'connected-react-router';
import { trackEvent } from '@src/metrics';
import ContentPush from '@src/components/ContentPush';
import { setNavigationVisibility } from '@src/redux/actions/navigation';
import api from '@src/util/api';

const STEPS = {
  COMPANY: 'company',
  DONE: 'done',
  EMAIL: 'email',
  PHONE: 'phone',
};

@connect(undefined, { goBack, setNavigationVisibility })
export default class Hire extends PureComponent {
  static propTypes = {
    goBack: PropTypes.func.isRequired,
    setNavigationVisibility: PropTypes.func.isRequired,
  };

  state = {
    currentStep: STEPS.EMAIL,
    loading: true,
  };

  componentDidMount() {
    this.props.setNavigationVisibility(false);
    this.fetchQuestion(STEPS.EMAIL);
  }

  componentWillUnmount() {
    this.save();
  }

  fetchQuestion = step => {
    api(`/api/app/menu/hire-${step}`, { delay: 600 })
      .then(question => this.setQuestion(step, question))
      .catch(this.props.goBack);
  };

  setQuestion = (step, { question }) => {
    this.setState({
      currentStep: step,
      loading: false,
      question,
    });
  };

  sendMetricsEvent(itemValue) {
    trackEvent({
      context: 'Questions Flow',
      interaction: 'Continue',
      itemType: 'Hire with MeetFrank',
      itemValue,
    });
  }

  next = () => {
    const { currentStep } = this.state;

    if (currentStep === STEPS.EMAIL) {
      this.setState({ loading: true, question: null });
      this.fetchQuestion(STEPS.PHONE);
      this.sendMetricsEvent('Email');
    } else if (currentStep === STEPS.PHONE) {
      this.setState({ loading: true });
      this.fetchQuestion(STEPS.COMPANY);
      this.sendMetricsEvent('Phone');
    } else if (currentStep === STEPS.COMPANY) {
      this.setState({ loading: true, question: null });
      this.fetchQuestion(STEPS.DONE);
      this.sendMetricsEvent('Company');
    } else if (currentStep === STEPS.DONE) {
      this.props.goBack();
      this.sendMetricsEvent('Done');
    }
  };

  save = () => {
    const { email, phone, company } = this.state;

    api('/api/app/menu/hire', {
      json: { company, email, phone },
      method: 'POST',
    })
      .then(this.props.goBack)
      .catch(this.props.goBack);
  };

  onInputChange = (key, value) => {
    this.setState({ [key]: value });
  };

  render() {
    const { question, loading, email, phone, company } = this.state;

    if (!question) {
      return null;
    }

    return (
      <ContentPush
        keepScrollTop
        loading={loading}
        onContinue={this.next}
        onInputChange={this.onInputChange}
        question={question}
        selectedValue={phone || email || company || null}
        sendBack={this.props.goBack}
        showHeader
      />
    );
  }
}
