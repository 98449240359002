import React, { FC, Fragment, useMemo } from 'react';
import classnames from 'classnames';
import { round } from 'lodash';
import { useMarketInsights } from '@src/graphql/marketInsights/hooks/useMarketInsights';
import { OpeningSearchTagNameEnum } from '@src/graphql/generated';
import { idify } from '@src/util/idify';
import { SectionTitle } from '../SectionTitle';
import { SkeletonLoading } from './SkeletonLoading';
import { SalaryRanking } from './SalaryRanking';
import { Props } from './props';
import './styles.scss';

export const SalaryOverview: FC<Props> = ({ wrapperClassName, user }) => {
  const userInboxProfileSalary = user?.inboxProfile?.salary;
  const userSalary = userInboxProfileSalary?.value;
  const userSalaryPeriod = userInboxProfileSalary?.period;
  const userLocation = user?.location;

  const userMonthlySalary = useMemo(() => {
    if (!userSalary || !userSalaryPeriod) {
      return undefined;
    }

    if (userSalaryPeriod === 'month') {
      return userSalary;
    }

    if (userSalaryPeriod === 'hour') {
      // ~40 hours in a week
      return round(userSalary * 160, 2);
    }

    if (userSalaryPeriod === 'year') {
      return round(userSalary / 12, 2);
    }

    return undefined;
  }, [userSalary, userSalaryPeriod]);

  if (!user?.onboardingDone) {
    return null;
  }

  const { salaryRanking, salaryStats, isLoading } = useMarketInsights([
    { tag: OpeningSearchTagNameEnum.TARGET_LOCATION, value: idify(userLocation) },
  ]);

  const averageSalaryAmount = salaryStats.averageSalary;
  const currencySymbol = salaryStats?.currency?.symbol;
  const averageSalaryPercentage = salaryStats.salaryGrowth;
  const userSalaries = salaryRanking?.salaryDistribution?.userSalaries;

  const isRankingDataAvailable = userSalaries && userSalaries?.length;

  const getSalaryExpectationText = (percentage: number): string => {
    if (percentage > 0) {
      return 'more than your expectation';
    }

    if (percentage < 0) {
      return 'less than your expectation';
    }

    if (percentage === 0) {
      return 'matches your expectation';
    }

    return '';
  };

  if (!isLoading && !averageSalaryAmount && !averageSalaryPercentage && !isRankingDataAvailable) {
    return null;
  }

  return (
    <div className={classnames('frank-salary-overview', wrapperClassName)}>
      <SectionTitle subtitle="Based on your high-match job offers" title="Salary overview" />

      {isLoading ? (
        <SkeletonLoading />
      ) : (
        <Fragment>
          {(!!averageSalaryAmount || !!averageSalaryPercentage) && (
            <div className="frank-salary-overview__average">
              {!!averageSalaryAmount && (
                <div className="frank-salary-overview__average-container">
                  <div className="frank-salary-overview__average-value">
                    {currencySymbol}
                    {averageSalaryAmount}
                  </div>

                  <div className="frank-salary-overview__average-label">Average salary offer for you</div>
                </div>
              )}

              {!!averageSalaryPercentage && (
                <div className="frank-salary-overview__average-container">
                  <div className="frank-salary-overview__average-value frank-salary-overview__average-value--blue">
                    {averageSalaryPercentage}%
                  </div>

                  <div className="frank-salary-overview__average-label">
                    {getSalaryExpectationText(averageSalaryPercentage)}
                  </div>
                </div>
              )}
            </div>
          )}

          {isRankingDataAvailable && (
            <div className="frank-salary-overview__ranking">
              <SalaryRanking
                averageSalaryAmount={averageSalaryAmount}
                currencySymbol={currencySymbol}
                userMonthlySalary={userMonthlySalary}
                userSalaries={userSalaries}
              />
            </div>
          )}
        </Fragment>
      )}
    </div>
  );
};
