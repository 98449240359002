import { HYBRID_PLACE_ID, ONSITE_PLACE_ID, REMOTE_PLACE_ID } from '@src/constants/placeIds';
import { FeedItemAllowedPlaceFragment, OpeningRemoteTypeEnum, PlaceTypeEnum } from '@src/graphql/generated';
import {
  isGetPlaceAreaFragmentType,
  isGetPlaceCountryFragmentType,
  isGetPlaceLocationFragmentType,
} from '@src/views/JobsSearch/hooks/usePlaces/typeGuards';
import { OpeningFeedItemPlace } from './interface/openingFeedItemPlace.interface';
import { OpeningFeedItemLocation } from './interface/openingFeedItemLocation.interface';

export const getOpeningFeedItemPlaces = (
  positionLocations?: OpeningFeedItemLocation[],
  allowedPlaces?: FeedItemAllowedPlaceFragment[],
  remoteType?: OpeningRemoteTypeEnum,
  userLocationId?: string,
  userCountryId?: string,
): OpeningFeedItemPlace[] => {
  const places: OpeningFeedItemPlace[] =
    ((remoteType === OpeningRemoteTypeEnum.FULLY || remoteType === OpeningRemoteTypeEnum.HYBRID) &&
    allowedPlaces?.length
      ? allowedPlaces.map(place => {
          const country = isGetPlaceCountryFragmentType(place.entity) ? place.entity : null;
          const location = isGetPlaceLocationFragmentType(place.entity) ? place.entity : null;
          const area = isGetPlaceAreaFragmentType(place.entity) ? place.entity : null;

          if (area) {
            return {
              countryShortName: area?.longName.toLowerCase(),
              id: area?.id,
              name: area?.longName,
              type: PlaceTypeEnum.AREA,
            };
          }

          if (country) {
            return {
              countryShortName: country?.shortName,
              id: country?.id,
              name: country?.longName,
              type: PlaceTypeEnum.COUNTRY,
            };
          }

          return {
            countryShortName: location?.country?.shortName,
            id: location?.id,
            name: location?.country?.shortName,
            type: PlaceTypeEnum.LOCATION,
          };
        })
      : remoteType !== OpeningRemoteTypeEnum.FULLY &&
        positionLocations?.map(location => {
          return {
            countryShortName: location?.country?.shortName,
            id: location?.id,
            name: location?.shortName,
            type: PlaceTypeEnum.LOCATION,
          };
        })) || [];

  const sortedPlaces = [...(places || [])].sort((a, b) => {
    if (a?.id === userLocationId || a?.id === userCountryId) {
      return -1;
    }

    if (b?.id === userLocationId || b?.id === userCountryId) {
      return 1;
    }

    return 0;
  });

  if (remoteType === OpeningRemoteTypeEnum.FULLY) {
    sortedPlaces.unshift({
      id: REMOTE_PLACE_ID,
      name: 'Remote',
      type: PlaceTypeEnum.REMOTE,
    });
  } else if (remoteType === OpeningRemoteTypeEnum.ONSITE) {
    sortedPlaces.unshift({
      id: ONSITE_PLACE_ID,
      name: 'On-site',
      type: PlaceTypeEnum.REMOTE,
    });
  } else if (remoteType === OpeningRemoteTypeEnum.HYBRID) {
    sortedPlaces.unshift({
      id: HYBRID_PLACE_ID,
      name: 'Hybrid-remote',
      type: PlaceTypeEnum.REMOTE,
    });
  }

  return sortedPlaces;
};
