import React, { FC } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import Svg from 'svg-inline-react';
import FrForwardSvg from 'assets/images/fr-forward.svg';

import { JOB_OPENING_ROUTE } from '@src/constants/routes';
import createLink from '@src/util/createLink';
import { Bookmark } from './Bookmark';
import { JobOpeningItemActionsProps } from './props';
import './styles.scss';

export const JobOpeningItemActions: FC<JobOpeningItemActionsProps> = ({
  hasBookmarked,
  wrapperClassName,
  metricsContext,
  openingId,
  isFeatured,
}) => {
  return (
    <div className={classnames('job-opening-item-actions', wrapperClassName)}>
      <div className="job-opening-item-actions__buttons-wrapper">
        <div className="job-opening-item-actions__button">
          <Bookmark
            hasBookmarked={hasBookmarked}
            isDisabled={window.isPublicApp}
            metricsContext={metricsContext}
            openingId={openingId}
            outlinedIcon
            wrapperClassName="job-opening-item-actions__bookmark"
          />
        </div>
      </div>
      <div className="job-opening-item-actions__buttons-wrapper">
        <div className="job-opening-item-actions__button">
          <Link
            className={'job-opening-item-actions__read-more'}
            to={createLink({
              link: JOB_OPENING_ROUTE,
              params: { openingId },
            })}
          >
            View
            <Svg className="job-opening-item-actions__read-more-icon" src={FrForwardSvg} />
          </Link>
        </div>
      </div>
    </div>
  );
};
