import React from 'react';
import './styles.scss';

export function Skeleton() {
  return (
    <div className="search-stats-item search-stats-item__skeleton pulse-fade">
      <div className="search-stats-item__dot search-stats-item__dot--grey" />

      <div className="search-stats-item__skeleton-content">
        <div className="search-stats-item__bar search-stats-item__bar--long" />
        <div className="search-stats-item__bar search-stats-item__bar--short" />
        <div className="search-stats-item__bar search-stats-item__bar--long" />
      </div>
    </div>
  );
}
