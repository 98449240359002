import * as Sentry from '@sentry/react';
import { hasFields } from './object/helpers';

const globalIgnoredErrors: (number | string)[] = [401];

export function captureError(error: unknown, ignoreErrors: (number | string)[] = []): void {
  if (
    hasFields<{ code: number | string }>(error, ['code']) &&
    error.code &&
    globalIgnoredErrors.concat(ignoreErrors).includes(error.code)
  ) {
    return;
  }

  // eslint-disable-next-line no-console
  console.error(error);

  Sentry.captureException(error);
}
