import { debounce, find } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { SelectItem } from '../SelectItem';
import './styles.scss';

export default class SelectList extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    defaultValue: PropTypes.object,
    identifier: PropTypes.string,
    onSelect: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    valueKey: PropTypes.string,
    withoutDebounce: PropTypes.bool,
  };

  state = {
    item: {},
  };

  componentDidMount() {
    const { defaultValue, options } = this.props;

    if (defaultValue) {
      this.setState({
        item: find(options, { key: defaultValue.key }),
      });
    }
  }

  onSelect = this.props.withoutDebounce ? this.props.onSelect : debounce(this.props.onSelect, 500);

  selectItem = item => {
    this.setState({ item }, () => {
      const { identifier } = this.props;

      if (identifier) {
        this.onSelect({ [identifier]: item });
      } else {
        this.onSelect(item);
      }
    });
  };

  render() {
    const { options, className, valueKey = 'key' } = this.props;
    const { item } = this.state;

    return (
      <div className={classnames('select-list', className)}>
        {options.map(option => (
          <SelectItem
            active={option[valueKey] === item[valueKey]}
            key={option[valueKey]}
            onSelect={this.selectItem}
            option={option}
            showCheckmark={false}
          />
        ))}
      </div>
    );
  }
}
