import React, { FC } from 'react';
import { get } from 'lodash';
import Svg from 'svg-inline-react';
import FrForwardSvg from 'assets/images/fr-forward.svg';
import { Link, useHistory } from 'react-router-dom';
import { trackEvent } from '@src/metrics';
import { CompanyWithAwards } from '@src/components/CompanyWithAwards';
import { COMPANY_REVIEWS_ROUTE, JOB_OPENING_ROUTE } from '@src/constants/routes';
import createLink from '@src/util/createLink';
import { HiringStateEnum, useGetUserQuery } from '@src/graphql/generated';
import { Interaction } from '@src/metrics/enums/interaction.enum';
import { JobOpeningCompanyInfoSwiper } from '../JobOpeningCompanyInfoSwiper';
import { JobOpeningFilled } from '../JobOpeningFilled';
import { JobOpeningMatch } from '../JobOpeningMatch';
import { JobOpeningItemActions } from '../JobOpeningItemActions';
import { CitiesWithSalary } from '../CitiesWithSalary';
import { getOpeningFeedItemPlaces } from '../utils/getOpeningFeedItemPlaces';
import { PrimaryOpeningItemProps } from './props';
import './styles.scss';

export const PrimaryOpeningItem: FC<PrimaryOpeningItemProps> = ({ isMatchShown, opening, metricsContext }) => {
  const history = useHistory();

  const { data: userData } = useGetUserQuery();
  const userLocationId = userData?.currentUser.location?.id;
  const userCountryId = userData?.currentUser.location?.country?.id;

  const openingId = opening.id;
  const title = opening.title;
  const hiringState = opening.hiringState;
  const remoteType = opening.remote?.type;
  const salaryRange = opening.salaryRange;
  const company = opening.company;

  const openingPlaces = getOpeningFeedItemPlaces(
    opening.positionLocations || [],
    opening.remote.allowedPlaces,
    remoteType,
    userLocationId,
    userCountryId,
  );

  const companyId = company?.id;
  const match = get(opening, 'match');
  const isHighMatch = get(match, 'matchScore.isHighMatch');
  /*
   * This field doesn't exist EW side, so even though this is required field APP side we still need to use lodash get method
   * to not break preview frame(4 step of create opening flow) in EW.
   */
  const requirements = opening.requirements || '';

  const handleRatingClick = () => {
    if (!companyId) {
      return;
    }

    trackEvent({
      context: metricsContext,
      interaction: Interaction.OPEN_COMPANY_REVIEWS,
    });

    history.push(
      createLink({
        link: COMPANY_REVIEWS_ROUTE,
        params: { companyId },
      }),
    );
  };

  return (
    <>
      {match && isMatchShown && isHighMatch && <JobOpeningMatch match={match} />}

      <div className="primary-opening-feed-item__top-section">
        <div className="primary-opening-feed-item__company-with-awards-wrapper">
          <CompanyWithAwards
            company={company}
            onRatingItemClick={handleRatingClick}
            openingId={openingId}
            title={title}
            wrapperClassName="primary-opening-feed-item__company-with-awards"
          />

          <Link
            to={createLink(JOB_OPENING_ROUTE, {
              params: { openingId },
            })}
          >
            {' '}
            <Svg className="primary-opening-feed-item__forward-icon" src={FrForwardSvg} />
          </Link>
        </div>

        <CitiesWithSalary
          salaryRange={salaryRange || undefined}
          id={openingId}
          places={openingPlaces}
          wrapperClassName="primary-opening-feed-item__locations"
        />

        {hiringState !== HiringStateEnum.OPEN && (
          <JobOpeningFilled wrapperClassName="primary-opening-feed-item__filled" />
        )}
      </div>

      {company?.hasActiveSubscription && (
        <JobOpeningCompanyInfoSwiper
          company={company}
          openingId={openingId}
          openingRequirements={requirements || ''}
          wrapperClassName="primary-opening-feed-item__company-scroller"
        />
      )}

      {company?.hasActiveSubscription && (
        <JobOpeningItemActions
          hasBookmarked={opening.hasBookmarked}
          metricsContext={metricsContext}
          openingId={opening.id}
        />
      )}
    </>
  );
};
