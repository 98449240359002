import React from 'react';
import PropTypes from 'prop-types';
import Svg from 'svg-inline-react';
import FrEditSvg from 'assets/images/fr-edit.svg';
import './styles.scss';

export default function Item({ onClick, icon, iconElem, itemWithEditIcon, label, data }) {
  return (
    <div className="availability-request-story-item" onClick={onClick && onClick}>
      <div className="availability-request-story-item__info-wrapper">
        {iconElem || <Svg className="availability-request-story-item__icon" src={icon} />}

        <div className="availability-request-story-item__data-wrapper">
          <div className="availability-request-story-item__info">{label}</div>
          {data && <div className="availability-request-story-item__data">{data}</div>}
        </div>
      </div>

      {itemWithEditIcon && <Svg className="availability-request-story-item__edit-icon" src={FrEditSvg} />}
    </div>
  );
}

Item.propTypes = {
  data: PropTypes.node,
  icon: PropTypes.string,
  iconElem: PropTypes.node,
  itemWithEditIcon: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};
