import React, { FC, useEffect, useRef } from 'react';
import { trackEvent, trackRegister, trackRegisterDatadogUser, trackSuperProperties } from '@src/metrics';
import { showBlackContainerStatusBar } from '@src/container';
import { fetchUserApiOld } from '@src/redux/actions/user';
import { isEmojiSupported } from '@src/util/isEmojiSupported';
import { captureError } from '@src/util/errors';
import { GetUserQuery, useGetUserLazyQuery, UserVerificationStateEnum } from '@src/graphql/generated';
import { Context } from '@src/metrics/enums/context.enum';
import { Interaction } from '@src/metrics/enums/interaction.enum';
import { setUserTimezone } from '@src/graphql/user/actions/setUserTimeZone';
import { DeletedUser } from '@src/views/DeletedUser';
import { setOrUpdateUserToLocalStorage } from '@src/localStorage/user';
import { useAppDispatch, useAppSelector } from '@src/store';
import { setNavigationVisibility } from '@src/redux/actions/navigation';
import { fetchRefreshToken } from '@src/redux/actions/accessToken';
import { setEmojiSupportedStatus } from '@src/redux/actions/emoji';
import { getDeviceUUID } from '@src/redux/selectors/deviceUUID';
import { isNativeContainer } from '@src/util/body';
import { TrackRegisterParamsInterface } from '@src/metrics/interfaces/trackRegisterParams.interface';
import { Props } from './props';

export const SessionValidation: FC<Props> = ({ children }) => {
  const isMounted = useRef(false);

  const dispatch = useAppDispatch();

  const [getUser, { data: userData }] = useGetUserLazyQuery();
  const user = userData?.currentUser;

  const deviceUUID = useAppSelector(getDeviceUUID);
  const isReady = !isNativeContainer() || !!deviceUUID;

  const initiateMetrics = (user: GetUserQuery['currentUser']): void => {
    const userMetricsParams: TrackRegisterParamsInterface & Record<string, unknown> = {
      id: user.id,
      joinedAt: user.joinedAt,
      registeredCountryId: user.location?.country?.id,
      registeredLocationId: user.location?.id,
      specialityId: user.inboxProfile?.speciality?.id,
    };

    trackRegister(userMetricsParams);

    trackSuperProperties({
      'Feature Flags': user.meta?.featureFlags || [],
      'Has TA Enabled': user.verification?.state === UserVerificationStateEnum.PUBLISHED,
      'Is Onboarded': user.onboardingDone,
    });

    trackEvent({
      context: Context.SESSION,
      interaction: Interaction.USER_LOADED,
    });

    trackRegisterDatadogUser(userMetricsParams);
  };

  const fetchUser = async (): Promise<void> => {
    try {
      const { data } = await getUser({
        fetchPolicy: 'network-only',
      });
      const user = data?.currentUser;

      if (!user) {
        return;
      }

      if (!user.onboardingDone) {
        dispatch(setNavigationVisibility(false));
      }

      setOrUpdateUserToLocalStorage(user);

      initiateMetrics(user);

      setUserTimezone(user);

      isMounted.current = true;
    } catch (error) {
      if (error?.code === 401) {
        setNavigationVisibility(false);
      }
    }
  };

  const fetchTokenOrCreateUser = async (): Promise<void> => {
    try {
      await dispatch(fetchRefreshToken());
    } catch (error) {
      // This is to fetch user from old api which will create new user if there is not one logged in
      await fetchUserApiOld();
      await dispatch(fetchRefreshToken());

      captureError(error);
    }
  };

  const initiateUserQuery = async (): Promise<void> => {
    await getUser({
      fetchPolicy: 'cache-only',
    });
  };

  const createSession = async (): Promise<void> => {
    await fetchTokenOrCreateUser();
    await initiateUserQuery();
    await fetchUser();
  };

  useEffect(() => {
    showBlackContainerStatusBar();
    dispatch(setEmojiSupportedStatus(isEmojiSupported()));
  }, []);

  useEffect(() => {
    if (!user && isReady) {
      createSession();
    }
  }, [user, isReady]);

  if (user?.state === 'deleted') {
    return <DeletedUser />;
  }

  return !user ? null : children;
};
