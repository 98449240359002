import React, { FC, useEffect, useState } from 'react';
import { get, findIndex } from 'lodash';
import { useHistory } from 'react-router-dom';
import { setNavigationVisibility } from '@src/redux/actions/navigation';
import ContentPush from '@src/components/ContentPush';
import { trackEvent } from '@src/metrics';
import {
  showBlackContainerStatusBar,
  showWhiteContainerStatusBar,
  setBodyBackGroundBlack,
  removeBodyBackGroundBlack,
} from '@src/container';
import { FRANK_ROUTE } from '@src/constants/routes';
import { useSaveVerifiedUserFeedbackMutation, useVerifiedUserFeedbackHiringStatesQuery } from '@src/graphql/generated';
import { useFlashMessagesContext } from '@src/context/FlashMessagesContext';
import { useAppDispatch } from '@src/store';
import { QUESTIONS, views } from './steps';
import './styles.scss';

const HIRING_STATE_TO_TEXT = {
  HIRED_OUTSIDE_MEETFRANK: 'I found a job elsewhere',
  HIRED_VIA_MEETFRANK: 'I found a job via MeetFrank',
  NOT_HIRED: "I didn't get to any interviews",
  OTHER: 'Other',
};

const HIRING_STATE_NEXT_VIEW = {
  HIRED_OUTSIDE_MEETFRANK: views.FEEDBACK,
  HIRED_VIA_MEETFRANK: views.HIRED_BY_COMPANY,
  NOT_HIRED: views.FEEDBACK,
  OTHER: views.FEEDBACK,
};

export const VerifiedUserQuestionnaire: FC = () => {
  const { setErrorFlashMessage } = useFlashMessagesContext();

  const history = useHistory();

  const dispatch = useAppDispatch();

  const [saveFeedback] = useSaveVerifiedUserFeedbackMutation({
    onError: () => {
      setErrorFlashMessage('Failed to save a feedback');
    },
  });

  const { data, loading } = useVerifiedUserFeedbackHiringStatesQuery({ fetchPolicy: 'network-only' });

  const [mainLoading, setMainLoading] = useState(false);

  const [selectedOptions, setSelectedOptions] = useState({});

  const [activeView, setActiveView] = useState(views.OPTIONS);

  useEffect(() => {
    setBodyBackGroundBlack();

    showWhiteContainerStatusBar();

    dispatch(setNavigationVisibility(false));

    trackEvent({ context: 'Verify User Questionnaire', interaction: 'View' });

    return () => {
      removeBodyBackGroundBlack();

      showBlackContainerStatusBar();

      dispatch(setNavigationVisibility(true));
    };
  }, []);

  function handleResponse(response) {
    if (activeView === views.HIRED_BY_COMPANY) {
      setSelectedOptions({
        ...selectedOptions,
        hiredByCompany: response[0],
      });
    } else {
      setSelectedOptions({
        ...selectedOptions,
        ...response,
      });
    }
  }

  function handleInputChange(key, text) {
    setSelectedOptions({
      ...selectedOptions,
      [key]: text,
    });
  }

  function handleBack() {
    setMainLoading(true);
    setTimeout(() => {
      setMainLoading(false);
    }, 500);

    if (activeView === views.OPTIONS) {
      history.goBack();
    } else {
      setSelectedOptions({
        hiringOptions: selectedOptions.hiringOptions,
      });
      setActiveView(views.OPTIONS);
    }
  }

  function handleContinue() {
    const { hiringOption, hiredByCompany, feedback } = selectedOptions;

    saveFeedback({
      variables: {
        saveInput: {
          additionalInfo: feedback,
          hiredByCompanyId: get(hiredByCompany, 'id'),
          hiringState: hiringOption.key,
        },
      },
    });

    if (activeView === views.OPTIONS) {
      setMainLoading(true);
      const { key } = selectedOptions.hiringOption;

      setActiveView(HIRING_STATE_NEXT_VIEW[key]);
      setTimeout(() => {
        setMainLoading(false);
      }, 500);
    } else {
      history.push(FRANK_ROUTE);
    }
  }

  if (loading) {
    return <div className="verified-user-questionnaire" />;
  }

  if (activeView === views.OPTIONS) {
    const feedbackOptions = data.verifiedUserFeedbackHiringStates
      .filter(state => HIRING_STATE_TO_TEXT[state])
      .map(state => ({
        key: state,
        value: HIRING_STATE_TO_TEXT[state],
      }));

    const selectIndex = findIndex(QUESTIONS[activeView].content, ({ type }) => type === 'select');
    QUESTIONS[activeView].content[selectIndex].value = feedbackOptions;
  }

  let key = 'hiringOption';

  if (activeView !== views.OPTIONS) {
    key = activeView === views.HIRED_BY_COMPANY ? 'hiredByCompany' : 'feedback';
  }

  return (
    <div className="verified-user-questionnaire">
      <ContentPush
        isDarkModeOn
        keepScrollTop
        loading={loading || mainLoading}
        onClose={handleContinue}
        onContinue={handleContinue}
        onInputChange={handleInputChange}
        question={QUESTIONS[activeView]}
        selectedValue={selectedOptions[key]}
        sendBack={handleBack}
        sendResponse={handleResponse}
      />
    </div>
  );
};
