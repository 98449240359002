import React from 'react';
import { map, isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import { MarketsMap } from '@src/components/MarketsMap';
import Labels from '@src/components/Labels';
import { AboutTitle } from '../AboutTitle';
import { AboutText } from '../AboutText';
import './styles.scss';

export const Markets = ({ marketAreas, marketCountries, marketsDescription }) => {
  if (!marketsDescription && isEmpty(marketAreas) && isEmpty(marketCountries)) {
    return null;
  }

  return (
    <div className="company-profile-markets">
      <AboutTitle>Markets</AboutTitle>

      <AboutText text={marketsDescription} />

      {(!isEmpty(marketAreas) || !isEmpty(marketCountries)) && (
        <MarketsMap marketAreas={marketAreas} marketCountries={marketCountries} />
      )}

      {(!isEmpty(marketAreas) || !isEmpty(marketCountries)) && (
        <Labels
          labels={map(marketAreas, 'longName').concat(map(marketCountries, 'longName'))}
          wrapperClassName="company-profile-markets__labels"
        />
      )}
    </div>
  );
};

Markets.propTypes = {
  marketAreas: PropTypes.arrayOf(
    PropTypes.shape({
      longName: PropTypes.string.isRequired,
    }).isRequired,
  ),
  marketCountries: PropTypes.arrayOf(
    PropTypes.shape({
      longName: PropTypes.string.isRequired,
    }).isRequired,
  ),
  marketsDescription: PropTypes.string,
};
