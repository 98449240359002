import React, { FC } from 'react';
import { BlueButton } from '@src/components/Button';
import { TaxInfoProps } from './props';
import './styles.scss';

export const TaxInfo: FC<TaxInfoProps> = ({ closeTaxInfo }) => {
  return (
    <div className="tax-info">
      <h1>Tax & Liabilities</h1>

      <p>
        Please note that in accordance with the local tax regulations, you may need to pay additional taxes on your
        freelance income.
      </p>

      <BlueButton className="tax-info__got-it" onClick={closeTaxInfo}>
        Got it
      </BlueButton>
    </div>
  );
};
