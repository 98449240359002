import { MouseEvent } from 'react';

export const preventEvents = (callback?: () => void) => {
  return (evt: MouseEvent): void => {
    evt.stopPropagation();

    evt.preventDefault();

    callback?.();
  };
};
