import { useEffect } from 'react';
import { throttle } from 'lodash';
import { GetReviewsFeedQuery, useGetReviewsFeedQuery } from '@src/graphql/generated';
import { ALL_PLACE_ID } from '@src/constants/placeIds';
import { handleFetchMore } from '@src/util/handleFetchMore';
import { trackEvent } from '@src/metrics';
import { Context } from '@src/metrics/enums/context.enum';
import { Interaction } from '@src/metrics/enums/interaction.enum';
import { ItemType } from '@src/metrics/enums/itemType.enum';
import { UseLatestReviewsParams, UseLatestReviewsValue } from './interfaces/useLatestReviews.interface';

const FIRST_PATCH_SIZE = 4;
const NEXT_PATCH_SIZE = 6;

export const useLatestReviews = ({
  activeCountryId,
  scrollableContainer,
}: UseLatestReviewsParams): UseLatestReviewsValue => {
  const {
    data: reviewsData,
    loading: isReviewsLoading,
    fetchMore: fetchMoreReviews,
  } = useGetReviewsFeedQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      filterInput: {
        countryIds: activeCountryId && activeCountryId !== ALL_PLACE_ID ? [activeCountryId] : undefined,
      },
      paginationOptions: { limit: FIRST_PATCH_SIZE },
    },
  });

  const reviews = reviewsData?.reviews?.result || [];
  const pagination = reviewsData?.reviews?.pagination;

  const loadMore = throttle(({ target: { scrollTop, scrollHeight } }) => {
    const fetchMoreMargin = scrollTop + window.innerHeight * 3;

    if (!isReviewsLoading && pagination?.hasMoreResults && fetchMoreMargin > scrollHeight) {
      handleFetchMore(fetchMoreReviews, {
        updateQuery: (prev: GetReviewsFeedQuery, { fetchMoreResult }: { fetchMoreResult: GetReviewsFeedQuery }) => {
          if (!fetchMoreResult) {
            return prev;
          }

          const prevReviews = prev.reviews;
          const newResult = fetchMoreResult.reviews.result;
          const pagination = fetchMoreResult.reviews.pagination;

          const result = [...prevReviews.result, ...newResult];

          trackEvent({
            context: Context.REVIEWS,
            interaction: Interaction.FETCH_MORE,
            itemType: ItemType.REVIEWS_FEED,
            itemValue: result.length,
          });

          return {
            ...prev,
            reviews: {
              ...prevReviews,
              pagination: {
                // Do not update minCursor, because we use it as a initial minCursor to detect feed update on page mount.
                ...prevReviews.pagination,
                hasMoreResults: pagination.hasMoreResults,
                limit: pagination.limit,
                maxCursor: pagination.maxCursor,
              },
              result: result,
            },
          };
        },
        variables: {
          paginationOptions: {
            limit: NEXT_PATCH_SIZE,
            minCursor: pagination.maxCursor,
          },
        },
      });
    }
  }, 200);

  useEffect(() => {
    if (scrollableContainer) {
      scrollableContainer.addEventListener('scroll', loadMore);
    }

    return () => {
      if (scrollableContainer) {
        scrollableContainer.removeEventListener('scroll', loadMore);
      }

      loadMore.cancel();
    };
  }, [isReviewsLoading, pagination]);

  return {
    isReviewsLoading: isReviewsLoading,
    reviews: reviews,
  };
};
