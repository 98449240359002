import { isEmpty, size, get } from 'lodash';
import { idify } from '@src/util/idify';
import { SEARCH_TAGS } from '@src/constants/opening';
import { SearchOpeningEntityTagNameEnum, OpeningRemoteTypeEnum } from '@src/graphql/generated';

export function getWorkOptionsFromSearchTags(searchTags) {
  const remoteTypes =
    searchTags && searchTags.filter(({ tag: searchTag }) => searchTag === SearchOpeningEntityTagNameEnum.REMOTE_TYPE);

  if (remoteTypes.length === 1) {
    if (remoteTypes[0].value === OpeningRemoteTypeEnum.ONSITE) {
      return [OpeningRemoteTypeEnum.ONSITE];
    } else if (remoteTypes[0].value === OpeningRemoteTypeEnum.HYBRID) {
      return [OpeningRemoteTypeEnum.HYBRID];
    } else if (remoteTypes[0].value === OpeningRemoteTypeEnum.FULLY) {
      return [OpeningRemoteTypeEnum.FULLY];
    }
  } else if (remoteTypes.length === 2) {
    return [OpeningRemoteTypeEnum.ONSITE, OpeningRemoteTypeEnum.HYBRID];
  }

  return [];
}

export function getSearchTags(workOptions, areas, countries, location) {
  if (workOptions.length === 1) {
    if (workOptions[0] === OpeningRemoteTypeEnum.ONSITE && location) {
      return {
        categories: ['POSITION_LOCATION'],
        searchTags: [
          { tag: 'POSITION_LOCATION', value: idify(location) },
          { tag: 'REMOTE_TYPE', value: OpeningRemoteTypeEnum.ONSITE },
        ],
      };
    } else if (workOptions[0] === OpeningRemoteTypeEnum.HYBRID && location) {
      return {
        categories: ['POSITION_LOCATION'],
        searchTags: [
          { tag: 'REMOTE_ALLOWED_LOCATION', value: idify(location) },
          { tag: 'REMOTE_TYPE', value: OpeningRemoteTypeEnum.HYBRID },
        ],
      };
    } else if (workOptions[0] === OpeningRemoteTypeEnum.FULLY && (areas?.length > 0 || countries?.length > 0)) {
      return {
        categories: ['REMOTE_COUNTRY'],
        searchTags: [{ tag: 'REMOTE_TYPE', value: OpeningRemoteTypeEnum.FULLY }],
        searchTagsWithOrCondition: [
          ...areas.map(area => ({ tag: 'REMOTE_ALLOWED_AREA', value: idify(area) })),
          ...countries.map(country => ({ tag: 'REMOTE_ALLOWED_COUNTRY', value: idify(country) })),
        ],
      };
    }

    return {};
  } else {
    const hasOnsiteAndHybrid =
      size(workOptions) === 2 &&
      workOptions.includes(OpeningRemoteTypeEnum.ONSITE) &&
      workOptions.includes(OpeningRemoteTypeEnum.HYBRID);

    if (hasOnsiteAndHybrid && location) {
      return {
        categories: ['POSITION_LOCATION'],
        searchTags: [{ tag: 'POSITION_LOCATION', value: idify(location) }],
        searchTagsWithOrCondition: [
          { tag: 'REMOTE_TYPE', value: OpeningRemoteTypeEnum.ONSITE },
          { tag: 'REMOTE_TYPE', value: OpeningRemoteTypeEnum.HYBRID },
        ],
      };
    }
  }
}

export const getAllSearchTags = (workOptions, activeAreas, activeCountries, location, activeSeniorities) => {
  let senioritiesSearchTags = [];
  const searchTagsForWorkOptions = getSearchTags(workOptions, activeAreas, activeCountries, location);
  const searchTags = get(searchTagsForWorkOptions, 'searchTags', []);
  const searchTagsWithOrCondition = get(searchTagsForWorkOptions, 'searchTagsWithOrCondition') || [];

  if (!isEmpty(activeSeniorities)) {
    senioritiesSearchTags = activeSeniorities.map(seniority => ({ tag: SEARCH_TAGS.SENIORITY, value: seniority.id }));
  }

  return [...searchTags, ...senioritiesSearchTags, ...searchTagsWithOrCondition];
};
