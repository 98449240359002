import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { isEmpty, get } from 'lodash';
import { Chip } from '@src/components/Chip';
import { SearchInputWithSuggestions } from '@src/components/SearchInputWithSuggestions';
import { useSearch } from './useSearch';
import './styles.scss';

const getCompanySuggestions = suggestions => {
  if (!isEmpty(suggestions)) {
    return suggestions.map(suggestion => ({
      id: get(suggestion, 'entity.id'),
      imageSrc: get(suggestion, 'entity.profile.logo.fullUrl'),
      title: get(suggestion, 'entity.profile.name'),
    }));
  }

  return [];
};

const SearchInputWithChips = ({
  isSingleSelect,
  selectedValue,
  onSelect,
  wrapperClassName,
  searchInputWithSuggestionsProps,
  isDarkModeOn,
}) => {
  const suggestionsWrapperRef = useRef<HTMLDivElement | null>();
  const [searchPattern, setSearchPattern] = useState('');
  const [selectedItems, setSelectedItems] = useState(selectedValue || []);
  const { result, loading, hasSearchedForPattern } = useSearch(searchPattern, suggestionsWrapperRef);
  const suggestions = getCompanySuggestions(result);

  const handleSuggestionSelect = item => {
    if (!selectedItems.some(({ id }) => id === item.id)) {
      if (isSingleSelect) {
        setSelectedItems([item]);
        onSelect([item]);
      } else {
        setSelectedItems([...selectedItems, item]);
        onSelect([...selectedItems, item]);
      }
    }
  };

  const handleItemRemove = id => {
    const updatedList = selectedItems.filter(item => item.id !== id);
    setSelectedItems(updatedList);
    onSelect(updatedList);
  };

  return (
    <div className={classnames('search-input-with-chips', wrapperClassName)}>
      <SearchInputWithSuggestions
        isDarkMode={isDarkModeOn}
        loading={loading}
        onChange={value => setSearchPattern(value)}
        onSuggestionSelect={handleSuggestionSelect}
        suggestions={suggestions}
        suggestionsWrapperRef={suggestionsWrapperRef}
        withSearchIcon
        {...searchInputWithSuggestionsProps}
      />
      <div className="search-input-with-chips__selected-items">
        {selectedItems.map(({ id, title, imageSrc }) => (
          <Chip
            imageSrc={imageSrc}
            isRemovable
            key={id}
            onRemove={() => handleItemRemove(id)}
            text={title}
            wrapperClassName="search-input-with-chips__chip"
          />
        ))}
      </div>

      {hasSearchedForPattern && !loading && isEmpty(suggestions) && (
        <div className="search-input-with-chips__not-found">
          {`We can't find this company at the moment, you can skip this step. Our team will check if we can add "${searchPattern}”.`}
        </div>
      )}
    </div>
  );
};

SearchInputWithChips.propTypes = {
  isDarkModeOn: PropTypes.bool,
  isSingleSelect: PropTypes.bool,
  onSelect: PropTypes.func,
  searchInputWithSuggestionsProps: PropTypes.object,
  selectedValue: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      imageSrc: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ),
  wrapperClassName: PropTypes.string,
};

export default SearchInputWithChips;
