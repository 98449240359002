import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import './styles.scss';

export default function SendingRequest({ sendingRequest, sendingText, sentText }) {
  return (
    <div className="availability-request-sending content-enter-animation">
      <div className="availability-request-sending-loader-wrapper">
        <svg className="circular-loader" viewBox="25 25 50 50">
          <circle className="loader-background" cx="50" cy="50" fill="none" r="20" />
          <circle
            className={classnames('loader-path', !sendingRequest && 'finished')}
            cx="50"
            cy="50"
            fill="none"
            r="20"
          />
        </svg>

        {!sendingRequest && (
          <svg className="request-checkmark" fill="none" height="41" viewBox="0 0 58 41" width="58">
            <path className="request-checkmark-check" d="M1.48001 18.9167L20.2 37.4167L56.08 1.95834" />
          </svg>
        )}
      </div>

      <TransitionGroup component={null}>
        <CSSTransition
          classNames="availability-request-sending-fade"
          key={sendingRequest ? 'sending' : 'sent'}
          timeout={{ enter: 0, exit: 200 }}
        >
          {sendingRequest ? (
            <div className="availability-request-sending-info">{sendingText || 'Sending request'}</div>
          ) : (
            <h3>{sentText || 'Request sent'}</h3>
          )}
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
}

SendingRequest.propTypes = {
  sendingRequest: PropTypes.bool.isRequired,
  sendingText: PropTypes.string,
  sentText: PropTypes.string,
};
