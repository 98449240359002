import { apolloClient } from '@src/graphql/apollo';
import { setScroll } from '@src/redux/actions/scroll';
import { MarkNotificationCenterAsReadDocument, NotificationCenterDocument } from '@src/graphql/generated';

export function fetchNotificationCenter() {
  return async dispatch => {
    const {
      data: { notificationCenter },
    } = await fetchNotificationCenterApi();
    dispatch(setNotificationCenter(notificationCenter));

    return { notificationCenter };
  };
}

export function fetchNotificationCenterApi() {
  return apolloClient.query({
    fetchPolicy: 'no-cache',
    query: NotificationCenterDocument,
  });
}

export function markNotificationCenterAsRead() {
  return async dispatch => {
    await markNotificationCenterAsReadApi();
    const { data } = await fetchNotificationCenterApi();
    const { notificationCenter } = data;
    dispatch(setNotificationCenter(notificationCenter));

    return { notificationCenter };
  };
}

export function markNotificationCenterAsReadApi() {
  return apolloClient.mutate({
    fetchPolicy: 'no-cache',
    mutation: MarkNotificationCenterAsReadDocument,
  });
}

export const SET_NOTIFICATION_CENTER = `${__dirname}/setNotificationCenter`;
export function setNotificationCenter(notificationCenter) {
  return { notificationCenter, type: SET_NOTIFICATION_CENTER };
}

export function saveNotificationCenterScroll(scroll) {
  return setScroll('notificationCenter', scroll);
}
