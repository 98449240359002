import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { trackEvent } from '@src/metrics';
import { Story } from './Story';
import { Product } from './Product';
import { Office } from './Office';
import { Benefits } from './Benefits';
import { Markets } from './Markets';
import { Facts } from './Facts';
import { Clients } from './Clients';
import { Articles } from './Articles';
import { SocialMedia } from './SocialMedia';
import { HeaderImage } from './HeaderImage';
import './styles.scss';

export const About = ({ className, isPreview, loading, getScrollableContainer, companyId, companyProfile }) => {
  useEffect(() => {
    if (getScrollableContainer) {
      const wrapperScrollableContainer = getScrollableContainer();
      wrapperScrollableContainer[0].scrollTop = 0;
    }

    if (!isPreview) {
      trackEvent({
        context: 'Company Profile',
        interaction: 'View About',
        itemType: 'Company',
        itemValue: companyId,
      });
    }
  }, []);

  if (loading) {
    return null;
  }

  const mapImages = images =>
    images.map(({ fullUrl }) => ({
      h: 540,
      src: fullUrl,
      thumbnail: fullUrl,
      w: 900,
    }));

  const {
    productImages,
    officeImages,
    headerImage,
    logo,
    missionStatement,
    video,
    founded,
    funding,
    companySize,
    revenue,
    benefits,
    serviceDescription,
    officeDescription,
    officeVideo,
    locations,
    marketsDescription,
    marketAreas,
    marketCountries,
    clients,
    clientsDescription,
    socialMedia,
    media,
    name: companyName,
  } = companyProfile;

  return (
    <div className={classnames('company-profile-about', className)}>
      {!!isPreview && <HeaderImage headerImage={headerImage} logo={logo} />}

      <Story description={missionStatement} video={video} />

      <Facts companySize={companySize} founded={founded} funding={funding} revenue={revenue} />

      <Product description={serviceDescription} productImages={mapImages(productImages)} />

      <Office
        locations={locations}
        officeDescription={officeDescription}
        officeImages={mapImages(officeImages)}
        officeVideo={officeVideo}
      />

      <Benefits benefits={benefits} />

      <Markets marketAreas={marketAreas} marketCountries={marketCountries} marketsDescription={marketsDescription} />

      <Clients clients={clients} clientsDescription={clientsDescription} />

      <Articles companyName={companyName} media={media} />

      <SocialMedia socialMedia={socialMedia} />
    </div>
  );
};

About.propTypes = {
  className: PropTypes.string,
  companyId: PropTypes.string,
  companyProfile: PropTypes.shape({
    benefits: PropTypes.array,
    clients: PropTypes.array,
    clientsDescription: PropTypes.string,
    companySize: PropTypes.object,
    founded: PropTypes.number,
    funding: PropTypes.object,
    headerImage: PropTypes.object.isRequired,
    locations: PropTypes.array,
    logo: PropTypes.object.isRequired,
    marketAreas: PropTypes.array,
    marketCountries: PropTypes.array,
    marketsDescription: PropTypes.string,
    media: PropTypes.array,
    missionStatement: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    officeDescription: PropTypes.string,
    officeImages: PropTypes.array,
    officeVideo: PropTypes.object,
    productImages: PropTypes.array,
    revenue: PropTypes.object,
    serviceDescription: PropTypes.string,
    socialMedia: PropTypes.array,
    video: PropTypes.object,
  }),
  getScrollableContainer: PropTypes.func,
  isPreview: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
};
