import { useEffect } from 'react';
import { get, isEmpty } from 'lodash';
import {
  useGetAreaByIdLazyQuery,
  useGetCountryByIdLazyQuery,
  useGetLocationByIdLazyQuery,
  useSeniorityLazyQuery,
  useSkillLazyQuery,
  SearchOpeningEntityTagNameEnum,
} from '@src/graphql/generated';

/**
 * To fetch initial filter labels.
 */
export const useFilters = searchTags => {
  // Seniority
  const [fetchSeniority, { data: seniorityData, loading: isSeniorityLoading }] = useSeniorityLazyQuery({
    fetchPolicy: 'network-only',
  });
  // Skill
  const [fetchSkill, { data: skillData, loading: isSkillLoading }] = useSkillLazyQuery({
    fetchPolicy: 'network-only',
  });
  // Location
  const [fetchLocation, { data: locationData, loading: isLocationLoading }] = useGetLocationByIdLazyQuery({
    fetchPolicy: 'network-only',
  });
  // Country
  const [fetchCountry, { data: countryData, loading: isCountryLoading }] = useGetCountryByIdLazyQuery({
    fetchPolicy: 'network-only',
  });
  // Area
  const [fetchArea, { data: areaData, loading: isAreaLoading }] = useGetAreaByIdLazyQuery({
    fetchPolicy: 'network-only',
  });

  const selectedSeniorities = searchTags.filter(searchTag => {
    return searchTag.tag === SearchOpeningEntityTagNameEnum.SENIORITY;
  });
  const selectedSkills = searchTags.filter(searchTag => {
    return searchTag.tag === SearchOpeningEntityTagNameEnum.SKILL;
  });
  const positionLocation = searchTags.filter(searchTag => {
    return searchTag.tag === SearchOpeningEntityTagNameEnum.POSITION_LOCATION;
  });
  const allowRemoteLocation = searchTags.filter(searchTag => {
    return searchTag.tag === SearchOpeningEntityTagNameEnum.REMOTE_ALLOWED_LOCATION;
  });
  const selectedCountries = searchTags.filter(searchTag => {
    return searchTag.tag === SearchOpeningEntityTagNameEnum.REMOTE_ALLOWED_COUNTRY;
  });
  const selectedArea = searchTags.filter(searchTag => {
    return searchTag.tag === SearchOpeningEntityTagNameEnum.REMOTE_ALLOWED_AREA;
  });
  let selectedLocation = [];

  if (positionLocation.length > 0) {
    selectedLocation = positionLocation;
  } else if (allowRemoteLocation.length > 0) {
    selectedLocation = allowRemoteLocation;
  }

  const fetchFilters = () => {
    if (!isEmpty(selectedSeniorities)) {
      fetchSeniority({
        variables: {
          id: selectedSeniorities[0].value,
        },
      });
    }

    if (!isEmpty(selectedSkills)) {
      fetchSkill({
        variables: {
          id: selectedSkills[0].value,
        },
      });
    }

    if (get(selectedLocation, '[0].value')) {
      fetchLocation({
        variables: {
          id: selectedLocation[0].value,
        },
      });
    }

    if (!isEmpty(selectedCountries)) {
      fetchCountry({
        variables: {
          id: selectedCountries[0].value,
        },
      });
    }

    if (!isEmpty(selectedArea)) {
      fetchArea({
        variables: {
          id: selectedArea[0].value,
        },
      });
    }
  };

  useEffect(() => {
    fetchFilters();
  }, []);

  const isMainLoading = isSeniorityLoading || isSkillLoading || isLocationLoading || isCountryLoading || isAreaLoading;
  return {
    filters: {
      area: get(areaData, 'area'),
      country: get(countryData, 'country'),
      location: get(locationData, 'location'),
      seniority: get(seniorityData, 'seniority'),
      skill: get(skillData, 'skill'),
    },
    loading: isMainLoading,
  };
};
