import gql from 'graphql-tag';

export const ALL_SPECIALITIES = gql`
  query specialities {
    specialities {
      id
      name
      skillTags
      key
      sort
    }
  }
`;
