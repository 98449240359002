import React, { FC } from 'react';
import classnames from 'classnames';
import Oval1 from 'assets/images/loader_oval_1.svg';
import Oval2 from 'assets/images/loader_oval_2.svg';
import Oval3 from 'assets/images/loader_oval_3.svg';
import Oval4 from 'assets/images/loader_oval_4.svg';
import FLogo from 'assets/images/loader_f.svg';
import Svg from 'svg-inline-react';
import { FrankRefreshingProps } from './props';
import './styles.scss';

export const FrankRefreshing: FC<FrankRefreshingProps> = ({ className, info }) => {
  return (
    <div className={classnames('frank-refreshing', className)}>
      <div className="loader">
        <Svg className="oval-1" src={Oval1} />
        <Svg className="oval-2" src={Oval2} />
        <Svg className="oval-3" src={Oval3} />
        <Svg className="oval-4" src={Oval4} />

        <Svg className="f-logo" src={FLogo} />
      </div>

      {info && <h5>{info}</h5>}
    </div>
  );
};
