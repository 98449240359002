import { round } from 'lodash';
import { handleRefetch } from '@src/util/chartAutoRefetch';
import { useGetForYouMarketInsightsQuery } from '@src/graphql/generated';
import { UseMarketInsights } from './interfaces/useMarketInsights.interface';

const SALARY_DISTRIBUTION_RESOLUTION = 26;

export const useMarketInsights: UseMarketInsights = searchTags => {
  const {
    data,
    refetch,
    loading: isLoading,
  } = useGetForYouMarketInsightsQuery({
    onError: error => handleRefetch(error, refetch),
    variables: {
      salariesDistributionResolution: SALARY_DISTRIBUTION_RESOLUTION,
      searchTags: searchTags,
    },
  });

  const salaryDistribution = data?.marketInsights.salaryDistribution;
  const userSalaryRank = data?.marketInsights.userSalaryRank;
  const userSalaryCurrency = userSalaryRank?.currency;
  const averageOpeningsSalary = data?.marketInsights.openingsSalaryStats.averageSalary || 0;
  const userSalary = userSalaryRank?.salary || 0;
  const salaryGrowth = averageOpeningsSalary && userSalary ? round((averageOpeningsSalary / userSalary - 1) * 100) : 0;

  return {
    isLoading: isLoading,
    salaryRanking: {
      salaryDistribution: salaryDistribution,
      userSalaryRank: userSalaryRank,
    },
    salaryStats: {
      averageSalary: averageOpeningsSalary,
      currency: userSalaryCurrency,
      salaryGrowth: salaryGrowth,
    },
  };
};
