import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FixedHeader } from '@src/components/FixedHeader';
import { SenioritiesListWithOpenings } from '@src/containers/SenioritiesListWithOpenings';
import { SEARCH_TAGS } from '@src/constants';
import { useAppDispatch } from '@src/store';
import { setNavigationVisibility } from '@src/redux/actions/navigation';
import { ActionButtons } from '../ActionButtons';
import './styles.scss';

export const SenioritiesSelection = ({
  onNext,
  onSenioritySelect,
  activeSeniorities,
  allSearchTags,
  searchPattern,
  goBack,
  onClear,
}) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setNavigationVisibility(false));

    return () => {
      dispatch(setNavigationVisibility(true));
    };
  }, []);

  return (
    <div className="seniorities-selection fade-in">
      <FixedHeader
        disableOpacity
        onBack={goBack}
        title="What's your seniority?"
        wrapperClassName="seniorities-selection__header"
      />
      <div className="seniorities-selection__body">
        <SenioritiesListWithOpenings
          activeSeniorities={activeSeniorities}
          allSearchTags={[...allSearchTags, { tag: SEARCH_TAGS.STATE, value: 'active' }]}
          onSenioritySelect={onSenioritySelect}
          searchPattern={searchPattern}
        />
      </div>
      <div className="seniorities-selection__footer">
        <ActionButtons
          blueButtonProps={{
            onClick: onNext,
          }}
          invisibleButtonProps={{
            onClick: onClear,
          }}
        />
      </div>
    </div>
  );
};

SenioritiesSelection.propTypes = {
  activeSeniorities: PropTypes.arrayOf(PropTypes.object),
  allSearchTags: PropTypes.array.isRequired,
  goBack: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onSenioritySelect: PropTypes.func.isRequired,
  searchPattern: PropTypes.string.isRequired,
};
