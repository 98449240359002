export enum UserRecoveryStepView {
  ENTER_VERIFICATION_CODE = 'ENTER_VERIFICATION_CODE',
  RECOVER_USER_BY_EMAIL = 'RECOVER_USER_BY_EMAIL',
}

export const USER_RECOVERY_STEPS: Record<UserRecoveryStepView, Record<string, unknown>> = {
  [UserRecoveryStepView.RECOVER_USER_BY_EMAIL]: {
    action: { active: false, type: 'continue', value: 'Send' },
    content: [
      {
        type: 'chat-bubble',
        value: 'Great to see you again! 😊',
      },
      {
        type: 'chat-bubble',
        value: 'Please enter your e-mail below.',
      },
      {
        type: 'chat-bubble',
        value: 'We will send you a verification code and magic link to log in.',
      },
      {
        key: 'recoverUserByEmail',
        type: 'email',
        value: 'E-mail...',
      },
    ],
    key: 'recoverUserByEmail',
    type: 'onboarding',
  },
  [UserRecoveryStepView.ENTER_VERIFICATION_CODE]: {
    action: { active: false, type: 'continue', value: 'Log in' },
    content: [
      {
        type: 'chat-bubble',
        value: 'I just sent you an email 🙂',
      },
      {
        type: 'chat-bubble',
        value: 'Open the link from the email or enter the verification code below.',
      },
      {
        type: 'chat-bubble',
        value:
          "Contact us at <a href={ 'mailto:support@meetfrank.com' }>support@meetfrank.com</a> if you can't log in.",
      },
      {
        key: 'userVerificationCode',
        type: 'chat-input',
        value: 'Verification code...',
      },
    ],
    key: 'userVerificationCode',
    type: 'onboarding',
  },
};

export enum UserAlreadyExistView {
  USER_EXIST = 'USER_EXIST',
}

export const USER_EXIST_STEPS: Record<UserAlreadyExistView, Record<string, unknown>> = {
  [UserAlreadyExistView.USER_EXIST]: {
    action: [
      {
        type: 'continue',
        value: 'Continue',
      },
      {
        active: true,
        className: 'user-has-account',
        type: 'login',
        value: 'Log in instead',
      },
    ],
    content: [
      {
        type: 'chat-bubble',
        value: 'Looks like this e-mail is already taken...',
      },
      {
        type: 'chat-bubble',
        value: 'Add a different e-mail or Log In to your existing account instead.',
      },
      {
        key: 'email',
        type: 'email',
        value: 'E-mail...',
      },
    ],
    key: 'email',
    type: 'onboarding',
  },
};

export enum OnboardingView {
  CONTINUE_ONBOARDING = 'CONTINUE_ONBOARDING'
}

export const ONBOARDING_STEPS: Record<OnboardingView, Record<string, unknown>> = {
  [OnboardingView.CONTINUE_ONBOARDING]: {
    action: [
      {
        isEnabled: true,
        type: 'continue',
        value: 'Continue',
      },
    ],
    content: [
      {
        type: 'chat-bubble',
        value: 'Great to see you again! 😊',
      },
      {
        type: 'chat-bubble',
        value: 'Let’s finish setting up your account.',
      },
      {
        type: 'chat-bubble',
        value: 'I’ll ask a few questions to get to know you better and help you discover best job opportunities.',
      },
    ],
    key: 'continue-onboarding',
    type: 'onboarding',
  },
};
