import { datadogRum } from '@datadog/browser-rum';
import config from '@src/config';

const { datadogApplicationId, datadogClientToken } = config;

if (datadogApplicationId && datadogClientToken) {
  datadogRum.init({
    allowedTracingOrigins: ['https://api.meetfrank.com'],
    applicationId: datadogApplicationId,

    clientToken: datadogClientToken,

    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sampleRate: 100,

    service: 'consumer-mobile',
    site: 'datadoghq.com',
    trackInteractions: true,
    trackViewsManually: true,
  });
}
