import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { FOR_YOU_ROUTE } from '@src/constants/routes';
import { trackEvent } from '@src/metrics';
import { Context } from '@src/metrics/enums/context.enum';
import { Interaction } from '@src/metrics/enums/interaction.enum';
import { useForYouPreviewFeed } from '@src/views/Frank/hooks/useRelevantOffersFeed';
import { OpeningCardScroller } from '../OpeningCardScroller';
import './styles.scss';

export const ForYouScroller: FC = () => {
  const { feed = [], isLoading: isFeedLoading, pagination } = useForYouPreviewFeed({});

  if (feed.length === 0 || pagination?.totalCount === 0) {
    return null;
  }

  return (
    <div className="for-you-preview-section">
      <div className="for-you-preview-section__top">
        <div className="for-you-preview-section__title">Job offers for You</div>

        <div className="for-you-preview-section__top-subtitle">
          <>Here is an overview of companies and jobs we think you&apos;d want to check and apply.</>
        </div>
      </div>

      <OpeningCardScroller isLoading={isFeedLoading} items={feed} rightIconLabel="See all" />

      <div className="for-you-preview-section__footer">
        <Link
          className="for-you-preview-section__link"
          onClick={() => {
            trackEvent({
              context: Context.HOME,
              interaction: Interaction.CLICK_VIEW_ALL_FOR_YOU,
            });
          }}
          to={FOR_YOU_ROUTE}
        >
          View all ({pagination?.totalCount})
        </Link>
      </div>
    </div>
  );
};
