import React, { FC } from 'react';
import { HorizontalListBlock } from '@src/components/HorizontalListBlock';
import createLink from '@src/util/createLink';
import { COMPANY_REVIEWS_ROUTE } from '@src/constants';
import { CompanyHiringStateEnum } from '@src/graphql/generated';
import { HorizontalListBlockSkeleton } from '@src/components/HorizontalListBlock/HorizontalListBlockSkeleton';
import { ScoreWithLocation } from '@src/components/ScoreWithLocation';
import { CompaniesBlocksProps } from './props';
import './styles.scss';

export const CompaniesBlocks: FC<CompaniesBlocksProps> = ({ companies, isLoading }) => {
  return (
    <div className="companies-blocks-list">
      {companies.map(company => {
        if (!company?.id) {
          return null;
        }

        const totalRating = company.reviewsOverview?.ratings.total;
        const logo = company.profile?.logo?.fullUrl;
        const location = company.profile.offices.find(office => office.isFoundingLocation || office.isHeadquarter)
          ?.location.shortName;

        return (
          <HorizontalListBlock
            to={createLink(COMPANY_REVIEWS_ROUTE, { params: { companyId: company.id } })}
            key={company.id}
            description={<ScoreWithLocation score={totalRating} size="small" location={location} />}
            name={company.profile?.name}
            statusColor={company.hiringState === CompanyHiringStateEnum.ACTIVE ? 'fr-sp3' : 'fr-il-red'}
            statusLabel={company.hiringState === CompanyHiringStateEnum.ACTIVE ? 'Hiring' : 'Not hiring'}
            imageSrc={logo}
          />
        );
      })}

      {isLoading &&
        [1, 2, 3, 4, 5].map(item => {
          return <HorizontalListBlockSkeleton key={item} />;
        })}
    </div>
  );
};
