/* eslint-disable import/order */
import 'babel-polyfill';
import '@src/sentry';
import '@src/datadog';
import '../container';
import '../styles.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import qs from 'qs';
import * as EventSourcePolyfill from 'eventsource';
import Visibility from 'visibilityjs';
import smoothscroll from 'smoothscroll-polyfill';
import { push } from 'connected-react-router';
import { fetchNotificationCenter } from '@src/redux/actions/notificationCenter';
import { App } from '@src/App';
import store from '@src/store';
import { JOBS_SEARCH_ROUTE } from '@src/constants/routes';
import { setUserTimezone } from '@src/graphql/user/actions/setUserTimeZone';
import { getUser } from '@src/graphql/user/actions/getUser';

// Import Swiper styles
import 'swiper/swiper.scss'; // core Swiper
import 'swiper/modules/navigation/navigation.scss'; // Navigation module
import 'swiper/modules/pagination/pagination.scss'; // Pagination module

// kick off the polyfill!
smoothscroll.polyfill();

const locationQueryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });

if (locationQueryParams.deviceClass) {
  const body = document.body;
  body.className = `${body.className} ${locationQueryParams.deviceClass}`;
}

window.EventSource = window.EventSource || EventSourcePolyfill;

let initialTime = 0;
const limit = 4 * 60 * 60; // 4 hours.
let initialRender = true;

Visibility.change(async function () {
  if (Visibility.hidden()) {
    initialRender = false;
    initialTime = new Date().getTime() / 1000;
  }

  if (Visibility.state() === 'visible') {
    const currentTime = new Date().getTime() / 1000;
    const user = await getUser();

    if (currentTime - initialTime > limit && !initialRender && !window.isPublicApp) {
      store.dispatch(push(JOBS_SEARCH_ROUTE));

      // Trigger a page refresh to reset all the currently active stores
      location.reload();
    }

    if (user?.onboardingDone) {
      store.dispatch(fetchNotificationCenter());
    }

    if (user) {
      setUserTimezone(user);
    }
  }
});

ReactDOM.render(<App />, document.getElementById('main-content'));
