import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { get, isUndefined, isEmpty, isArray, xor, mapValues, first, values } from 'lodash';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import capitalize from '@src/util/capitalize';
import { apolloClient } from '@src/graphql/apollo';
import api from '@src/util/api';
import { isValidEmail } from '@src/util/email';
import { setNavigationVisibility, setNavigationRefreshable } from '@src/redux/actions/navigation';
import { setMainRefreshDone } from '@src/redux/actions/main';
import { getMainRefresh } from '@src/redux/selectors/main';
import { registerForPush, requestAppStoreReview } from '@src/container';
import { FrankRefreshing } from '@src/components/FrankRefreshing';
import ContentPush from '@src/components/ContentPush';
import { JOBS_SEARCH_ROUTE } from '@src/constants/routes';
import { captureError } from '@src/util/errors';
import {
  RequestAuthCodeDocument,
  VerifyAuthCodeDocument,
  UpdateUserDocument,
  UpdateUserMutation,
  UpdateUserMutationVariables,
} from '@src/graphql/generated';
import { fetchAccessToken } from '@src/redux/actions/accessToken';
import { ApolloErrorCode } from '@src/enum/apolloErrorCode.enum';
import { Context } from '@src/metrics/enums/context.enum';
import { Interaction } from '@src/metrics/enums/interaction.enum';
import { trackEvent } from '@src/metrics';
import { USER_RECOVERY_STEPS, USER_EXIST_STEPS, UserRecoveryStepView, UserAlreadyExistView } from './steps';
import './styles.scss';

const API_DELAY = 500;
const REFRESH_DELAY = 2500;

@connect(
  state => ({
    refreshTriggered: getMainRefresh(state),
  }),
  {
    fetchAccessToken,
    push,
    setMainRefreshDone,
    setNavigationRefreshable,
    setNavigationVisibility,
  },
)
export default class MainView extends PureComponent {
  static propTypes = {
    onOnboardingDone: PropTypes.func,
    push: PropTypes.func.isRequired,
    refreshTriggered: PropTypes.bool,
    setMainRefreshDone: PropTypes.func.isRequired,
    setNavigationRefreshable: PropTypes.func.isRequired,
    setNavigationVisibility: PropTypes.func.isRequired,
  };

  state = {
    // Need to store email question ID for legacy api purposes.
    emailQuestionId: undefined,
    errorMessage: undefined,
    loading: true,
    nextRetry: false,
    question: {},
    selectedValue: undefined,
    selectedValues: {},
  };

  componentDidMount() {
    this._isMounted = true;
    this.fetchNext();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.refreshTriggered && nextProps.refreshTriggered !== this.props.refreshTriggered) {
      this.setState({ loading: true }, () =>
        this.fetchNext(REFRESH_DELAY, true).finally(this.props.setMainRefreshDone),
      );
    }
  }

  fetchNext = (delay = API_DELAY, refresh = false) => {
    return api('/api/app/next?refresh=' + refresh, { delay })
      .then(this.setQuestion)
      .catch(error => {
        if (this.state.nextRetry) {
          this.setState({ errorMessage: undefined, nextRetry: false }, () => this.errorHandler(error));
        } else {
          this.setState({ errorMessage: undefined, nextRetry: true }, this.fetchNext);
        }

        captureError(error);
      });
  };

  handleEmploymentResponse = (employmentType, companyId) => {
    return apolloClient.mutate<UpdateUserMutation, UpdateUserMutationVariables>({
      mutation: UpdateUserDocument,
      variables: {
        updateInput: {
          currentEmployment: {
            company: companyId,
            status: employmentType,
          },
        },
      },
    });
  };

  handleEmailResponse = email => {
    return apolloClient.mutate<UpdateUserMutation, UpdateUserMutationVariables>({
      mutation: UpdateUserDocument,
      variables: {
        updateInput: {
          email,
        },
      },
    });
  };

  sendResponse = async data => {
    const { question, emailQuestionId } = this.state;
    const { push } = this.props;

    if (!question) {
      return;
    }

    this.handleSpecialResponse(question, data);

    if (question.key === 'currentEmployment') {
      if (get(data, 'answer.key') !== 'negative') {
        await this.handleEmploymentResponse('EMPLOYED', first(data).id);
      } else {
        await this.handleEmploymentResponse('UNEMPLOYED');
      }
    }

    if (question.key === 'email') {
      try {
        await this.handleEmailResponse(data.email);
        this.setState(state => ({
          ...state,
          loading: false,
        }));
      } catch (error) {
        if (
          error.graphQLErrors &&
          error.graphQLErrors[0]?.extensions?.code === ApolloErrorCode.RESOURCE_ALREADY_EXISTS
        ) {
          this.setState(state => {
            if (!state.emailQuestionId) {
              return {
                ...state,
                emailQuestionId: question._id,
                loading: false,
                question: {
                  ...USER_EXIST_STEPS[UserAlreadyExistView.USER_EXIST],
                },
              };
            } else {
              return { ...state };
            }
          });

          return;
        }
      }
    }

    const activeQuestionId = question._id || emailQuestionId;

    this.setState(
      {
        loading: true,
        question: {
          _id: activeQuestionId,
          type: question.type,
        },
      },
      () => {
        api(`/api/app/${activeQuestionId}/response`, {
          json: data,
          method: 'POST',
        })
          .then(async response => {
            const onboardingDone = response.onboardingDone;

            if (data.url) {
              push(data.url);
            } else if (response.question) {
              this.setQuestion(response);
            } else if (response.url) {
              push(response.url);
            } else {
              if (onboardingDone) {
                this.props.onOnboardingDone();
              } else {
                this.fetchNext(API_DELAY);
              }
            }
          })
          .catch(this.errorHandler);
      },
    );
  };

  errorHandler = error => {
    let content = [
      {
        type: 'chat-bubble',
        value: 'Oh no...you have stumbled upon a bug. 😔 Our exterminators are on it. 🔥',
      },
      {
        key: 'bug',
        type: 'button-custom-action',
        value: {
          action: () => {
            this.props.push(JOBS_SEARCH_ROUTE);
          },
          key: 'overview',
          value: 'Overview',
        },
      },
    ];

    if (error.code === 0) {
      content = [
        {
          key: 'retry',
          type: 'chat-bubble',
          value: 'Seems like network connection is lost',
        },
        {
          key: 'retry',
          type: 'button-custom-action',
          value: {
            action: () => window.location.reload(),
            key: 'retry',
            value: 'Retry',
          },
        },
      ];
    }

    this.setQuestion({
      question: { content, type: 'error' },
    });
  };

  handleSpecialResponse = (question, data) => {
    const { key } = question;

    if (key === 'ios-enable-push') {
      const { answer } = data;

      if (answer.type === 'positive') {
        registerForPush();
      }
    } else if (key === 'rating-open-appstore') {
      const { answer } = data;

      switch (answer.action) {
        case 'rate-app-googleplay':
          window.open('https://play.google.com/store/apps/details?id=com.meetfrank.android', '_blank');
          break;
        case 'rate-app-appstore':
          requestAppStoreReview();
          break;
      }
    }
  };

  sendBack = () => {
    if (this.state.question.key === ('recoverUserByEmail' || 'email')) {
      this.fetchNext(API_DELAY);
    } else if (this.state.question.key === 'userVerificationCode') {
      this.setState(state => ({
        ...state,
        errorMessage: undefined,
        question: USER_RECOVERY_STEPS[UserRecoveryStepView.RECOVER_USER_BY_EMAIL],
      }));
    } else {
      this.setState({ content: [], errorMessage: undefined, loading: true }, () => {
        api('/api/app/back', { delay: API_DELAY, method: 'POST' }).then(this.setQuestion);
      });
    }
  };

  setQuestion = ({ question, url, read, refreshable }) => {
    if (!this._isMounted) {
      return;
    }

    if (question) {
      this.setState({
        emailQuestionId: undefined,
        loading: false,
        nextRetry: false,
        question: question,
        read: read,
        selectedValue: undefined,
      });

      const isOnboarding = question.type === 'onboarding';
      this.props.setNavigationVisibility(!isOnboarding);

      if (refreshable) {
        setTimeout(() => {
          this.props.setNavigationRefreshable(true);
        }, 4000);
      } else if (refreshable === false) {
        this.props.setNavigationRefreshable(false);
      }

      if (isOnboarding) {
        trackEvent({
          context: Context.ONBOARDING,
          interaction: Interaction.OPEN,
          itemType: capitalize(question.key),
          itemValue: '',
          nonInteraction: true,
        });
      }
    } else if (url) {
      this.props.push(url);
    }
  };

  handleContinue = async data => {
    const { loading, selectedValue, selectedValues } = this.state;

    this.setState(state => ({
      ...state,
      loading: true,
    }));

    if (loading) {
      return;
    }

    if (selectedValue && Object.keys(selectedValue).includes('recoverUserByEmail')) {
      try {
        await apolloClient.mutate({
          mutation: RequestAuthCodeDocument,
          variables: {
            email: selectedValue?.recoverUserByEmail,
          },
        });

        this.setState(state => ({
          ...state,
          errorMessage: undefined,
          question: USER_RECOVERY_STEPS[UserRecoveryStepView.ENTER_VERIFICATION_CODE],
          selectedValue: undefined,
        }));

        trackEvent({
          context: Context.ONBOARDING,
          interaction: Interaction.REQUEST_LOGIN_AUTH_CODE,
        });
      } catch (error) {
        captureError(error);
        this.setState(state => ({
          ...state,
          errorMessage: 'I couldn’t send your login instructions. Please check your email',
          loading: false,
        }));
      }

      this.setState(state => ({ ...state, loading: false }));
    } else if (selectedValue && Object.keys(selectedValue).includes('userVerificationCode')) {
      try {
        await apolloClient.mutate({
          mutation: VerifyAuthCodeDocument,
          variables: {
            code: selectedValues?.userVerificationCode.toUpperCase(),
            email: selectedValues?.recoverUserByEmail,
          },
        });

        await this.props.fetchAccessToken();

        await this.props.onOnboardingDone(true);

        await this.fetchNext();

        trackEvent({
          context: Context.ONBOARDING,
          interaction: Interaction.AUTH_CODE_LOGIN_SUCCESSFUL,
          payload: {
            source: 'manual',
          },
        });
      } catch (error) {
        captureError(error);
        this.setState(state => ({ ...state, errorMessage: 'Something went wrong, please try again.', loading: false }));
      }
    } else {
      this.sendResponse({ ...data, ...selectedValue });
    }
  };

  handleLocationSelect = location => {
    this.sendResponse({ location });
  };

  handleValueSelect = newValue => {
    this.setState(({ selectedValue, selectedValues }) => {
      if (!selectedValue) {
        return {
          errorMessage: undefined,
          selectedValue: newValue,
          selectedValues: { ...selectedValues, ...newValue },
        };
      }

      const newValueItem = first(values(newValue));

      if (!isArray(newValueItem)) {
        return {
          errorMessage: undefined,
          selectedValue: newValue,
          selectedValues: { ...selectedValues, ...newValue },
        };
      }

      // hurray for old data logic...
      // this adds and removes values from selectedValue array
      const mappedValues = mapValues(selectedValue, value => xor(value, newValueItem));
      const data = first(values(mappedValues));

      // we must set value to undefined if non are selected
      if (isUndefined(data) || isEmpty(data)) {
        return { errorMessage: undefined, selectedValue: undefined };
      }

      return {
        errorMessage: undefined,
        selectedValue: mappedValues,
        selectedValues: { ...selectedValues, ...newValue },
      };
    });
  };

  handleInputChange = (key, value) => {
    if (!value) {
      this.handleValueSelect(undefined);
    } else {
      this.handleValueSelect({ [key]: value });
    }
  };

  handleSecondAction = () => {
    this.setState(state => ({
      ...state,
      question: USER_RECOVERY_STEPS[UserRecoveryStepView.RECOVER_USER_BY_EMAIL],
      selectedValue: undefined,
    }));
  };

  isFieldValid = selectedValue => {
    const key = selectedValue && Object.keys(selectedValue)[0];
    const value = selectedValue && Object.values(selectedValue)[0];

    switch (key) {
      case 'email':
      case 'recoverUserByEmail':
        if (value.length && isValidEmail(value)) {
          return true;
        }

        return false;

      case 'userVerificationCode':
        if (value.length) {
          return true;
        }

        return false;
      default:
        return true;
    }
  };

  render() {
    const { loading, read, question, selectedValue, errorMessage } = this.state;
    const { refreshTriggered } = this.props;
    const showCircle = refreshTriggered;

    const isFieldValid = this.isFieldValid(selectedValue);

    return (
      <div className="main-view">
        <TransitionGroup component={null}>
          <CSSTransition
            appear
            classNames="fade-out"
            key={showCircle ? 'circle' : 'content'}
            timeout={{ enter: 0, exit: 200 }}
          >
            {showCircle ? (
              <FrankRefreshing info="Refreshing..." />
            ) : (
              <ContentPush
                defaultValues={selectedValue}
                errorMessage={errorMessage}
                keepScrollTop={question.type === 'onboarding'}
                key={question && (question._id || question.key)}
                loading={loading}
                onClose={this.handleSecondAction}
                onContinue={this.handleContinue}
                onInputChange={this.handleInputChange}
                onLocationSelect={this.handleLocationSelect}
                onValueSelect={this.handleValueSelect}
                question={question}
                read={read}
                selectedValue={isFieldValid && selectedValue}
                sendBack={this.sendBack}
                sendResponse={this.sendResponse}
                showHeader={question && question.type === 'onboarding'}
              />
            )}
          </CSSTransition>
        </TransitionGroup>
      </div>
    );
  }
}
