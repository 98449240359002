import { USER_UPDATED } from '@src/redux/actions/user';
import { SCROLL_SET } from '@src/redux/actions/scroll';

export function scroll(state = {}, action) {
  switch (action.type) {
    case USER_UPDATED: {
      return {};
    }
    case SCROLL_SET:
      return {
        ...state,
        [action.scrollKey]: action.scroll,
      };
    default:
      return state;
  }
}
