import store from '@src/store';
import { fetchAccessToken } from '@src/redux/actions/accessToken';

let isRefreshing = false;
let pendingRequests: ((accessToken?: string) => void)[] = [];

export const handleTokenRefresh = (onSuccess: (accessToken?: string) => void): void => {
  if (isRefreshing) {
    pendingRequests.push(onSuccess);

    return;
  }

  isRefreshing = true;

  store
    .dispatch(fetchAccessToken())
    .then((accessToken?: string) => {
      onSuccess(accessToken);

      pendingRequests.forEach(callBack => callBack(accessToken));

      pendingRequests = [];

      isRefreshing = false;
    })
    .catch(() => {
      isRefreshing = false;
    });
};
