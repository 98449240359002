import React, { FC } from 'react';
import { Chart } from '../SalaryRanking/Chart';
import './styles.scss';

// specific numbers for the skeleton loading
const CHART_LOADING_DATA = [
  0.178, 2.243, 1.07, 6.55, 8.385, 5.505, 8.347, 7.315, 9.252, 6.321, 8.385, 7.251, 4.804, 6.933, 3.033, 3.976, 2.702,
  2.74, 1.109, 0.739, 1.287, 0.663, 0.229, 0.548, 0.204, 0.229,
].map((percentage, index) => ({
  percentage: percentage,
  step: index + 1 * 100,
}));

export const SkeletonLoading: FC = () => {
  return (
    <div className="frank-salary-overview-loading">
      <div className="frank-salary-overview-loading__title pulse-fade" />
      <div className="frank-salary-overview-loading__sub-title pulse-fade" />
      <div className="frank-salary-overview-loading__sub-title frank-salary-overview-loading__sub-title--small pulse-fade" />

      <div className="frank-salary-overview-loading__average">
        <div className="frank-salary-overview-loading__average-container pulse-fade" />
        <div className="frank-salary-overview-loading__average-container pulse-fade" />
      </div>

      <div className="frank-salary-overview-loading__ranking">
        <Chart isLoading userSalaries={CHART_LOADING_DATA} />
      </div>
    </div>
  );
};
