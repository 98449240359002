import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './styles.scss';

export function SlideUpScroll({ wrapperClassName, topSectionContent, children }) {
  return (
    <div className={classnames('slide-up-scroll', wrapperClassName)}>
      <div className="slide-up-scroll__top-section">{topSectionContent}</div>

      <div className="slide-up-scroll__main-content-wrapper">
        <div className="slide-up-scroll__header">
          <span className="slide-up-scroll__header-stick" />
        </div>
        {children}
      </div>
    </div>
  );
}

SlideUpScroll.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  topSectionContent: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  wrapperClassName: PropTypes.string,
};
