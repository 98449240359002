import React, { FC } from 'react';
import { Props } from './props';
import './styles.scss';

export const SectionTitle: FC<Props> = ({ title, subtitle, count }) => {
  return (
    <div className="frank-section-title">
      <div className="frank-section-title__title">
        {title}
        {!!count && (
          <>
            &nbsp;<span>{count}</span>
          </>
        )}
      </div>

      {subtitle && <div className="frank-section-title__subtitle">{subtitle}</div>}
    </div>
  );
};
