import React, { FC } from 'react';
import { EmployeeCountProps } from './props';
import './styles.scss';

export const EmployeeCount: FC<EmployeeCountProps> = ({ count, companyName }) => {
  if (count < 2) {
    return null;
  }

  let text = `${count} ${companyName} employees are on MeetFrank`;

  if (count < 5) {
    text = `${companyName} employees are on MeetFrank`;
  }

  return (
    <div className="home-employee-count">
      <div className="home-employee-count__bullet" />
      <span>{text}</span>
    </div>
  );
};
