import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { captureError } from '@src/util/errors';
import { DecodedJwt } from '../interfaces/decodedJwt.interface';

export const isTokenExpired = (token: string): boolean => {
  try {
    const decodedToken = jwtDecode<DecodedJwt>(token);

    return moment.utc(decodedToken.exp * 1000).isBefore(moment().utc().add(10, 'seconds'));
  } catch (error) {
    captureError(error);

    return true;
  }
};
