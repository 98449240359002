import React, { FC, UIEvent, useEffect, useRef } from 'react';
import pluralize from 'pluralize';
import Svg from 'svg-inline-react';
import OngoingSvg from 'assets/images/ongoing.svg';
import { Link } from 'react-router-dom';
import { replaceMessageStringVariables } from '@shared/utils/replaceMessageStringVariables';
import { setScroll } from '@src/redux/actions/scroll';
import { getScroll } from '@src/redux/selectors/scroll';
import { BaseAppBar } from '@src/components/PrimaryAppBar';
import {
  InboxMessageThreadListItemFragment,
  MessageThreadTypeEnum,
  useGetInboxUnfinishedAutomatedInterviewsQuery,
  useGetUserQuery,
  useInboxAcceptApplicationMutation,
  useInboxRejectApplicationMutation,
  useMessageRequestsForInboxCardQuery,
  UserApplicationStateEnum,
} from '@src/graphql/generated';
import { useAppDispatch, useAppSelector } from '@src/store';
import { CHAT_ROUTE, MATCH_ROUTE } from '@src/constants';
import createLink from '@src/util/createLink';
import { ContactRequest } from '@src/components/ContactRequest';
import { ContactRequestSkeleton } from '@src/components/ContactRequest/ContactRequestSkeleton';
import { useFlashMessagesContext } from '@src/context/FlashMessagesContext';
import { PageNames } from '@src/redux/selectors/scroll/enums/pageNames.enum';
import { Skeleton } from './Skeleton';
import { PendingRequests } from './PendingRequests';
import { useMessageThreads } from './hooks/useMessageThreads';
import './styles.scss';

export const Inbox: FC = () => {
  const scrollRef = useRef<HTMLDivElement>(null);

  const scrollState = useAppSelector(getScroll);

  const dispatch = useAppDispatch();

  const { setErrorFlashMessage } = useFlashMessagesContext();

  const scroll = scrollState?.[PageNames.INBOX] || 0;

  const { data: userData, loading: isUserLoading } = useGetUserQuery();

  const user = userData?.currentUser;

  const { data: unfinishedInterviewsData, loading: isUnfinishedInterviewsLoading } =
    useGetInboxUnfinishedAutomatedInterviewsQuery({
      fetchPolicy: 'network-only',
    });

  const unfinishedInterviews = unfinishedInterviewsData?.unfinishedAutomatedInterviews.result || [];
  const unfinishedInterviewsPagination = unfinishedInterviewsData?.unfinishedAutomatedInterviews.pagination;

  const {
    data: messageRequestsData,
    loading: isMessageRequestsLoading,
    refetch: refetchMessageRequests,
  } = useMessageRequestsForInboxCardQuery({
    fetchPolicy: 'network-only',
    variables: {
      paginationOptions: {
        limit: 4,
      },
    },
  });

  const messageRequests = messageRequestsData?.messageRequests.result || [];

  const {
    messageThreads,
    messageThreadsPagination,
    isMessageThreadsInitialLoading,
    loadMoreMessageThreads,
    isMessageThreadsLoading,
  } = useMessageThreads({
    onNewApplicationReceived: refetchMessageRequests,
  });

  const [acceptApplication] = useInboxAcceptApplicationMutation({
    onError: () => {
      setErrorFlashMessage('Failed to accept invitation');
    },
  });

  const [rejectApplication] = useInboxRejectApplicationMutation({
    onError: () => {
      setErrorFlashMessage('Failed to reject invitation');
    },
  });

  const onAccept = async (applicationId?: string): Promise<void> => {
    if (!applicationId) {
      return;
    }

    await acceptApplication({
      variables: {
        applicationId: applicationId,
      },
    });
  };

  const onDecline = (applicationId?: string): void => {
    if (!applicationId) {
      return;
    }

    rejectApplication({
      variables: {
        applicationId: applicationId,
      },
    });
  };

  const onScroll = (event: UIEvent<HTMLDivElement>): void => {
    const target = event.target as HTMLDivElement;

    if (target.scrollTop + target.offsetHeight >= target.scrollHeight * 0.7) {
      if (messageThreadsPagination?.hasMoreResults && !isMessageThreadsLoading) {
        loadMoreMessageThreads();
      }
    }
  };

  useEffect(() => {
    const setScrollPosition = (): void => {
      if (scrollRef.current) {
        scrollRef.current.scrollTop = scroll;
      }
    };

    setScrollPosition();

    return () => {
      if (scrollRef.current) {
        dispatch(setScroll(PageNames.INBOX, scrollRef.current.scrollTop));
      }
    };
  }, []);

  const isLoading =
    isMessageRequestsLoading || isUserLoading || isMessageThreadsInitialLoading || isUnfinishedInterviewsLoading;

  const ongoingChatsCount =
    (messageThreadsPagination?.totalCount || 0) + (unfinishedInterviewsPagination?.totalCount || 0);

  if (isLoading) {
    return (
      <div className="inbox-content-wrapper">
        <BaseAppBar isFixed>Inbox</BaseAppBar>

        <Skeleton />
      </div>
    );
  }

  const isFirstMessageThreadFrankAgent = messageThreads[0]?.type === MessageThreadTypeEnum.FRANK_AGENT;

  const [firstMessageThread, remainingMessageThreads] = isFirstMessageThreadFrankAgent
    ? [messageThreads.slice(0, 1), messageThreads.slice(1)]
    : [[], messageThreads];

  const renderMessageThread = (messageThread: InboxMessageThreadListItemFragment | null) => {
    if (!messageThread) {
      return null;
    }

    const recruiter = messageThread.recruiter;
    const companyProfile = messageThread.company.profile;
    const opening = messageThread.opening;
    const application = messageThread.application;

    return (
      <Link
        className="inbox-content-wrapper__contact-request-link"
        key={messageThread.id}
        to={createLink(CHAT_ROUTE, {
          params: { threadId: messageThread.id },
        })}
      >
        <ContactRequest
          avatarColor={recruiter.avatarColor || undefined}
          avatarUrl={recruiter.avatarUrl || undefined}
          companyName={companyProfile.name}
          companyProfileLogo={
            messageThread.type === MessageThreadTypeEnum.FRANK_AGENT ? undefined : companyProfile.logo?.fullUrl
          }
          fullName={recruiter.fullName || undefined}
          isUnread={!messageThread.latestMessage.readByUserAt}
          message={
            messageThread.latestMessage.text ||
            replaceMessageStringVariables(opening?.message || '', {
              name: user?.name || '',
              title: opening?.title || '',
            })
          }
          messageCreatedAt={messageThread.latestMessage.sentAt}
          onApprove={() => onAccept(application?.id)}
          onDecline={() => onDecline(application?.id)}
          recruiterTitle={recruiter.title || undefined}
          shouldShowActions={application?.userState?.state === UserApplicationStateEnum.PENDING}
        />
      </Link>
    );
  };

  return (
    <div className="inbox-content-wrapper content-enter-animation" onScroll={onScroll} ref={scrollRef}>
      <BaseAppBar isFixed>Inbox</BaseAppBar>

      {!messageThreads.length && !unfinishedInterviews.length && (
        <div className="inbox-content-wrapper__subtitle">Your conversations with recruiters will appear here</div>
      )}

      {!!messageRequests.length && (
        <div className="inbox-content-wrapper__pending-card-wrapper">
          <p className="inbox-content-wrapper__section-title">
            {`${messageRequests.length} new ${pluralize('message', messageRequests.length)} from recruiters`}
          </p>

          <PendingRequests messageRequests={messageRequests} />
        </div>
      )}

      {ongoingChatsCount > 0 && (
        <div className="inbox-content-wrapper__subheader">
          <Svg className="inbox-content-wrapper__subheader-icon" src={OngoingSvg} />

          <span className="inbox-content-wrapper__subheader-text">Ongoing chats • {ongoingChatsCount}</span>
        </div>
      )}

      {firstMessageThread.map(renderMessageThread)}

      {unfinishedInterviews.map(unfinishedInterview => {
        const opening = unfinishedInterview.opening;
        const companyProfile = unfinishedInterview.company.profile;
        const recruiter = opening.recruiter;

        if (!unfinishedInterview.userMatchingProfile.id) {
          return null;
        }

        return (
          <Link
            className="inbox-content-wrapper__contact-request-link"
            key={unfinishedInterview.id}
            to={createLink(MATCH_ROUTE, {
              params: {
                openingId: opening.id,
                userMatchingProfileId: unfinishedInterview.userMatchingProfile.id,
              },
            })}
          >
            <ContactRequest
              avatarColor={recruiter.avatarColor || undefined}
              avatarUrl={recruiter.avatarUrl || undefined}
              companyName={companyProfile.name}
              companyProfileLogo={companyProfile.logo?.fullUrl}
              fullName={recruiter.fullName || undefined}
              isUnread
              message="Click to continue..."
              messageCreatedAt={unfinishedInterview.createdAt}
              recruiterTitle={recruiter.title || undefined}
            />
          </Link>
        );
      })}

      {remainingMessageThreads.map(renderMessageThread)}

      {isMessageThreadsLoading &&
        [0, 1, 2].map(item => {
          return (
            <div className="inbox-content-wrapper__contact-request-link" key={item}>
              <ContactRequestSkeleton key={item} />
            </div>
          );
        })}
    </div>
  );
};
