import React, { FC } from 'react';
import classNames from 'classnames';
import { Markdown } from '@src/components/Markdown';
import { MessageBubble } from '../MessageBubble';
import { MessageBubbleTextProps } from './props';
import './styles.scss';

export const MessageBubbleText: FC<MessageBubbleTextProps> = ({ messageText, type, isLoading }) => {
  return (
    <MessageBubble
      className={classNames('inbox-message-bubble-text', isLoading && 'inbox-message-bubble-text--loading pulse-fade')}
      type={type}
    >
      <Markdown markdown={messageText} />
    </MessageBubble>
  );
};
