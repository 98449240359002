import { TOP_LEVEL_DOMAINS } from 'src/constants/domainEndings';

const EXTRACT_EMAIL_REGEX =
  /([-!#$%&'*+/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-?\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+)/;
const SPACES_REGEX = /\s+/g;

export function normalizeEmail(email: string): string {
  const preprocessedEmail = email
    ?.trim()
    .replace('(at)', '@')
    .replace(new RegExp(`,(${TOP_LEVEL_DOMAINS.join('|')})$`), '.$1')
    .toLowerCase()
    .replace(SPACES_REGEX, '');

  const [_fullMatch, extractedEmail] = preprocessedEmail.match(EXTRACT_EMAIL_REGEX) || [];

  return extractedEmail;
}

// Thanks to:
// http://fightingforalostcause.net/misc/2006/compare-email-regex.php
// http://thedailywtf.com/Articles/Validating_Email_Addresses.aspx
// http://stackoverflow.com/questions/201323/what-is-the-best-regular-expression-for-validating-email-addresses/201378#201378
// tslint:disable-next-line: max-line-length
const VALID_EMAIL_REGEX =
  /^[-!#$%&'*+/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-?\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

export const isValidEmail = email => {
  const isEmailTooLong = email.length > 254;
  const emailSegments = email.split('@').flatMap(segment => segment.split('.'));
  const areEmailSegmentsTooLong = emailSegments.some(segment => segment.length > 63);
  const isValidEmail = VALID_EMAIL_REGEX.test(email);

  return !isEmailTooLong && !areEmailSegmentsTooLong && isValidEmail;
};
