import React from 'react';
import PropTypes from 'prop-types';
import Svg from 'svg-inline-react';
import { get, round } from 'lodash';
import CircularProgressbar from 'react-circular-progressbar';
import { connect } from 'react-redux';
import FrMfLogoWhiteSvg from 'assets/images/fr-mf-logo-white.svg';
import FrStarSvg from 'assets/images/fr-star.svg';
import FrSpeechBubbleSvg from 'assets/images/fr-speech-bubble.svg';
import { BlueButton } from '@src/components/Button';
import { startApply } from '@src/redux/actions/openings';
import { HiringStateEnum } from '@src/graphql/generated';
import './styles.scss';

const _OpeningAssistantModal = ({
  startApply,
  opening: {
    id: openingId,
    speciality: { id: specialityId },
    match,
    hiringState,
    candidateDestination,
  },
}) => {
  const matchScoreDisplay = get(match, 'matchScore.display');
  const onContinueLink = get(candidateDestination, 'url');
  const matchScorePercentage = round(matchScoreDisplay * 100);

  // Not to show always same value, this generates percentage between 1% - 10
  // Note: should be changed with a variable from BE at some point
  const selectedPeoplePercentage = round((1 - matchScoreDisplay) * 20);

  const list = [
    {
      icon: FrStarSvg,
      iconColor: 'yellow',
      label: 'Skill',
      value: (
        <div className="opening-assistant-modal__match-progress">
          <CircularProgressbar
            percentage={matchScoreDisplay * 100}
            strokeWidth={20}
            styles={{
              path: {
                strokeLinecap: 'butt',
              },
            }}
          />
          {round(matchScoreDisplay * 100)}% skill match
        </div>
      ),
    },
    {
      icon: FrSpeechBubbleSvg,
      iconColor: 'green',
      label: 'You’re invited to apply',
      value: 'Be the first to act',
    },
  ];

  return (
    <div className="opening-assistant-modal">
      <div className="opening-assistant-modal__line" />

      <div className="opening-assistant-modal__header">
        <Svg className="opening-assistant-modal__header-logo" src={FrMfLogoWhiteSvg} />

        <span className="opening-assistant-modal__header-label">Assistant</span>
      </div>

      <div className="opening-assistant-modal__title">{matchScorePercentage}% match score</div>

      <div className="opening-assistant-modal__text">
        You are one of {selectedPeoplePercentage}% of people who MeetFrank thinks match with this offer perfectly.
      </div>

      {list.map(
        ({ icon, iconColor, label, value, isHidden }, i) =>
          !isHidden && (
            <div className="opening-assistant-modal__list-item" key={i}>
              <Svg
                className={`opening-assistant-modal__item-icon opening-assistant-modal__item-icon--${iconColor}`}
                src={icon}
              />

              {label}

              <span className="opening-assistant-modal__item-text">{value}</span>
            </div>
          ),
      )}

      <BlueButton
        className="opening-assistant-modal__button"
        isExternalLink={!!onContinueLink}
        isFilled
        onClick={() =>
          startApply(
            openingId,
            specialityId,
            hiringState,
            candidateDestination,
            get(match, 'introState'),
            'Opening Assistant Modal',
          )
        }
        url={onContinueLink}
      >
        Apply
      </BlueButton>
    </div>
  );
};

_OpeningAssistantModal.propTypes = {
  opening: PropTypes.shape({
    candidateDestination: PropTypes.shape({
      url: PropTypes.string,
    }).isRequired,
    hiringState: PropTypes.oneOf(Object.values(HiringStateEnum)).isRequired,
    id: PropTypes.string.isRequired,
    match: PropTypes.shape({
      introState: PropTypes.string,
      matchScore: PropTypes.shape({
        display: PropTypes.number,
        total: PropTypes.number,
      }),
    }).isRequired,
    speciality: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  startApply: PropTypes.func.isRequired,
};

export const OpeningAssistantModal = connect(null, {
  startApply,
})(_OpeningAssistantModal);
