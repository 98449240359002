import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import IntlTelInput from 'react-intl-tel-input';
import { Query } from '@apollo/client/react/components';
import { GetUserDocument, GetUserQuery } from '@src/graphql/generated';
import 'react-intl-tel-input/dist/main.css';
import './styles.scss';

export default class PhoneNumber extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
  };

  onNumberChange = (status, number, selectedCountry) => {
    if (!number.startsWith('+')) {
      number = `+${selectedCountry.dialCode}${number}`;
    }

    this.props.onChange({
      number: number,
      selectedCountry: {
        iso2: selectedCountry.iso2,
        name: selectedCountry.name,
      },
    });
  };

  render() {
    const { className, value } = this.props;

    return (
      <Query<GetUserQuery> query={GetUserDocument}>
        {({ data }) => {
          const countryShortname = data?.currentUser.location?.country?.shortName || '';

          return (
            <div className={classnames('phone-number', className)}>
              <IntlTelInput
                css={['intl-tel-input phone-number-number-wrapper', 'phone-number-input']}
                defaultValue={value}
                onPhoneNumberChange={this.onNumberChange}
                placeholder="Your phone number"
                preferredCountries={[countryShortname.toLowerCase()]}
                separateDialCode
              />
            </div>
          );
        }}
      </Query>
    );
  }
}
