import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { FixedHeader } from '@src/components/FixedHeader';
import { CountriesList } from '@src/containers/CountriesList';
import { ActionButtons } from '../../ActionButtons';
import './styles.scss';

export const CountrySearch = ({
  selectedCountries,
  onCountrySave,
  goBack,
  onCountriesClear,
  changeCountriesFromParent,
}) => {
  const [activeCountries, setActiveCountries] = useState(selectedCountries);

  useEffect(() => {
    if (changeCountriesFromParent) {
      setActiveCountries(selectedCountries);
    }
  }, [selectedCountries, changeCountriesFromParent]);

  const handleCountrySelect = country => {
    const updatedCountries = activeCountries.find(it => it.id === country.id)
      ? activeCountries.filter(it => it.id !== country.id)
      : [...activeCountries, country];

    setActiveCountries(updatedCountries);
  };

  return (
    <div className="country-search-modal">
      <FixedHeader
        disableOpacity
        onBack={goBack}
        title="Select a country for remote work"
        wrapperClassName="country-search-modal__header"
      />
      <div className="country-search-modal__body">
        <CountriesList activeCountries={activeCountries} onCountrySelect={country => handleCountrySelect(country)} />
      </div>
      <div className="country-search-modal__footer">
        <ActionButtons
          blueButtonLabel="Save"
          blueButtonProps={{
            disabled: isEmpty(activeCountries),
            onClick: () => onCountrySave(activeCountries),
          }}
          invisibleButtonProps={{
            onClick: onCountriesClear,
          }}
        />
      </div>
    </div>
  );
};

CountrySearch.propTypes = {
  changeCountriesFromParent: PropTypes.bool,
  goBack: PropTypes.func.isRequired,
  onCountriesClear: PropTypes.func.isRequired,
  onCountrySave: PropTypes.func.isRequired,
  selectedCountries: PropTypes.object,
};
