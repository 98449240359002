import React, { FC, useEffect, useRef, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import classNames from 'classnames';
import { CHAT_ROUTE, INBOX_ROUTE } from '@src/constants/routes';
import { setNavigationVisibility } from '@src/redux/actions/navigation';
import createLink from '@src/util/createLink';
import { useAppDispatch } from '@src/store';
import {
  GetInboxMessageThreadQuery,
  HiringStateEnum,
  MessageSenderEnum,
  MessageThreadStateEnum,
  MessageThreadTypeEnum,
  useInboxAcceptApplicationMutation,
  useInboxRejectApplicationMutation,
  useMarkInboxMessageThreadAsReadMutation,
  UserApplicationStateEnum,
  useSendInboxMessageMutation,
  useUpdateInboxMessageThreadMutation,
} from '@src/graphql/generated';
import { TypingLoader } from '@src/components/TypingLoader';
import { useFlashMessagesContext } from '@src/context/FlashMessagesContext';
import { Context } from '@src/metrics/enums/context.enum';
import { Interaction } from '@src/metrics/enums/interaction.enum';
import { ItemType } from '@src/metrics/enums/itemType.enum';
import { trackEvent } from '@src/metrics';
import { useMessageThread } from './hooks/useMessageThread';
import { ChatHeader } from './ChatHeader';
import { ChatMenu } from './ChatMenu';
import { ChatOpening } from './ChatOpening';
import { ChatCompany } from './ChatCompany';
import { ChatActions } from './ChatActions';
import { ChatInputActions } from './ChatInputActions';
import { Message } from './Message';
import { MessageSkeleton } from './Message/MessageSkeleton';
import './styles.scss';

export const Chat: FC = () => {
  const { setErrorFlashMessage } = useFlashMessagesContext();

  const messagesWrapperRef = useRef<HTMLDivElement>(null);

  const isFirstRender = useRef(true);

  const history = useHistory();

  const match = useRouteMatch<{ threadId?: string }>({
    path: CHAT_ROUTE,
    sensitive: true,
    strict: true,
  });
  const threadId = match?.params.threadId;

  const dispatch = useAppDispatch();

  const [inputText, setInputText] = useState('');

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [selectedMessageId, setSelectedMessageId] = useState<string>();

  const setScrollBottom = () => {
    setTimeout(() => {
      if (messagesWrapperRef.current) {
        messagesWrapperRef.current.scrollTop = messagesWrapperRef.current.scrollHeight;
      }
    }, 100);
  };

  const {
    isMessagesLoading,
    isMessageThreadInitialLoading,
    messageThread,
    messages,
    refetchMessageThread,
    updateMessageThreadQuery,
  } = useMessageThread({
    messagesWrapperRef: messagesWrapperRef,
    onCompleted: () => {
      if (isFirstRender.current) {
        setScrollBottom();

        isFirstRender.current = false;
      }
    },
    onMessageReceived: () => {
      setScrollBottom();
    },
    onRefetchCompleted: () => {
      setScrollBottom();
    },
  });

  const company = messageThread?.company;
  const companyProfile = company?.profile;
  const opening = messageThread?.opening;
  const canRespondToChat = messageThread?.canRespondToChat;

  const [markMessageThreadAsRead] = useMarkInboxMessageThreadAsReadMutation({
    onError: () => {
      setErrorFlashMessage('Failed to mark message as read');
    },
  });

  const [sendInboxMessage, { loading: isSendInboxMessageLoading }] = useSendInboxMessageMutation({
    onCompleted: mutationData => {
      setInputText('');

      updateMessageThreadQuery(prevData => {
        const newMessage = mutationData?.sendMessage?.messages.result[0];

        const doesMessageExist = prevData?.messageThread?.messages.result.some(message => {
          return message?.id === newMessage?.id;
        });

        if (doesMessageExist) {
          return prevData;
        }

        return {
          ...prevData,
          messageThread: {
            ...prevData.messageThread,
            messages: {
              ...prevData.messageThread?.messages,
              result: [...(prevData.messageThread?.messages.result || []), newMessage],
            },
          },
        };
      });

      setScrollBottom();
    },
    onError: () => {
      setErrorFlashMessage('Failed to send a message');
    },
  });

  const [acceptApplication] = useInboxAcceptApplicationMutation({
    onCompleted: () => {
      refetchMessageThread();
    },
    onError: () => {
      setErrorFlashMessage('Failed to accept invitation');
    },
  });

  const [rejectApplication] = useInboxRejectApplicationMutation({
    onCompleted: () => {
      history.push(createLink(INBOX_ROUTE));
    },
    onError: () => {
      setErrorFlashMessage('Failed to reject invitation');
    },
  });

  const [updateInboxMessageThread] = useUpdateInboxMessageThreadMutation({
    onCompleted: async updateInboxMessageThreadData => {
      const updatedRecruiterState = updateInboxMessageThreadData.updateMessageThread.userState;

      if (MessageThreadStateEnum.CLOSED === updatedRecruiterState) {
        history.push(createLink(INBOX_ROUTE));
      }
    },
    onError: () => {
      setErrorFlashMessage('Failed to close chat');
    },
  });

  const toggleMenu = (): void => {
    setIsMenuOpen(isPrevMenuOpen => !isPrevMenuOpen);
  };

  const onInputChange = (inputText: string): void => {
    setInputText(inputText);
  };

  const sendMessage = async (): Promise<void> => {
    if (!threadId || !inputText.length) {
      return;
    }

    await sendInboxMessage({
      variables: {
        messageInput: {
          message: inputText,
        },
        messageThreadId: threadId,
      },
    });
  };

  const onMessageClick = (messageId: string): void => {
    setSelectedMessageId(prevMessageId => {
      return prevMessageId === messageId ? undefined : messageId;
    });
  };

  const onAccept = async (applicationId?: string): Promise<void> => {
    if (!applicationId) {
      return;
    }

    await acceptApplication({
      variables: {
        applicationId: applicationId,
      },
    });
  };

  const onDecline = (applicationId?: string): void => {
    if (!applicationId) {
      return;
    }

    rejectApplication({
      variables: {
        applicationId: applicationId,
      },
    });
  };

  const closeThread = async (): Promise<void> => {
    if (!messageThread?.id) {
      return;
    }

    await updateInboxMessageThread({
      variables: {
        messageThreadId: messageThread.id,
        messagesPaginationOptions: {
          limit: 1,
        },
        updateInput: {
          state: MessageThreadStateEnum.CLOSED,
        },
      },
    });
  };

  const getIsNextSenderDifferent = (
    currentMessage: GetInboxMessageThreadQuery['messageThread']['messages']['result'][0],
    nextMessage?: GetInboxMessageThreadQuery['messageThread']['messages']['result'][0],
  ) => {
    const currentSender = currentMessage?.sender;
    const nextSender = nextMessage?.sender;

    if (currentMessage?.sender !== nextSender) {
      return true;
    }

    return (
      currentSender === MessageSenderEnum.RECRUITER && currentMessage?.recruiter?.id !== nextMessage?.recruiter?.id
    );
  };

  const getDisabledMessage = (): string | undefined => {
    if (opening?.hiringState === HiringStateEnum.CLOSED) {
      return 'This position is not available anymore  😕';
    }

    if (messageThread?.recruiterState === MessageThreadStateEnum.ARCHIVED) {
      return 'This chat has been closed';
    }

    return undefined;
  };

  const markRead = async (threadId: string) => {
    await markMessageThreadAsRead({
      variables: {
        messageThreadId: threadId,
        messagesPaginationOptions: {
          limit: 1,
        },
      },
    });
  };

  useEffect(() => {
    dispatch(setNavigationVisibility(false));

    if (threadId) {
      markRead(threadId);
    }
  }, []);

  useEffect(() => {
    if (messageThread) {
      trackEvent({
        context: Context.CHAT,
        interaction: Interaction.OPEN_CHAT,
        itemType: ItemType.INDEX,
        itemValue: messageThread.type,
      });
    }
  }, [messageThread?.id]);

  if (isMessageThreadInitialLoading) {
    return (
      <div className="chat">
        <TypingLoader />
      </div>
    );
  }

  return (
    <div className="chat">
      <div className="chat__container content-enter-animation">
        <ChatHeader
          companyName={companyProfile?.name}
          isMenuOpen={isMenuOpen}
          recruiterFullName={messageThread?.recruiter?.fullName || undefined}
          toggleMenu={toggleMenu}
          isActionsDisabled={messageThread?.type === MessageThreadTypeEnum.FRANK_AGENT}
        />

        {isMenuOpen ? (
          <ChatMenu closeChat={closeThread} />
        ) : (
          <>
            {opening ? (
              <ChatOpening openingId={opening.id} title={opening.title} />
            ) : (
              company && <ChatCompany companyId={company?.id} title={company.profile?.name} />
            )}

            <div className="chat__wrapper">
              <div
                className={classNames(
                  'chat__messages-wrapper',
                  !canRespondToChat && 'chat__messages-wrapper--full-height',
                )}
                ref={messagesWrapperRef}
              >
                <div className="chat__messages-list">
                  {messages?.map((message, index) => {
                    if (!message) {
                      return null;
                    }

                    const isNextSenderDifferent = getIsNextSenderDifferent(message, messages[index + 1]);

                    return (
                      <div className="chat__message-wrapper" key={message.id}>
                        <Message
                          message={message}
                          isNextSenderDifferent={!message.attachments.length && isNextSenderDifferent}
                          opening={opening || undefined}
                          selectedMessageId={selectedMessageId}
                          onMessageClick={onMessageClick}
                        />

                        {message.attachments?.map((attachment, index) => {
                          const isLastItem = index === message.attachments.length - 1;

                          return (
                            <Message
                              isFullWidth
                              key={message.id + attachment.opening.id}
                              message={message}
                              isNextSenderDifferent={isLastItem && isNextSenderDifferent}
                              opening={opening || undefined}
                              selectedMessageId={selectedMessageId}
                              onMessageClick={() => onMessageClick(attachment.opening.id)}
                              openingAttachment={attachment.opening}
                              type={attachment.type}
                            />
                          );
                        })}
                      </div>
                    );
                  })}

                  {isMessagesLoading && <MessageSkeleton isNextSenderDifferent={true} isIncoming />}
                </div>
              </div>
            </div>

            {canRespondToChat &&
              (messageThread?.application?.userState?.state === UserApplicationStateEnum.PENDING ? (
                <ChatActions
                  onAccept={() => onAccept(messageThread.application?.id)}
                  onDecline={() => onDecline(messageThread.application?.id)}
                />
              ) : (
                <ChatInputActions
                  closeChat={closeThread}
                  disabledMessage={getDisabledMessage()}
                  inputText={inputText}
                  isSendDisabled={isSendInboxMessageLoading}
                  onInputChange={onInputChange}
                  sendMessage={sendMessage}
                />
              ))}
          </>
        )}
      </div>
    </div>
  );
};
