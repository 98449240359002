import React, { FC, useEffect } from 'react';
import { find } from 'lodash';
import { matchPath, useHistory } from 'react-router-dom';
import { SubPageAppBar } from '@src/components/PrimaryAppBar';
import { COMPANY_ABOUT_ROUTE, COMPANY_JOB_OPENINGS_ROUTE, COMPANY_ROUTE } from '@src/constants/routes';
import { setScroll } from '@src/redux/actions/scroll';
import { trackEvent } from '@src/metrics';
import { Context } from '@src/metrics/enums/context.enum';
import { Interaction } from '@src/metrics/enums/interaction.enum';
import { useAppDispatch, useAppSelector } from '@src/store';
import { getPreviousLocation } from '@src/redux/selectors/navigation';
import { getScroll } from '@src/redux/selectors/scroll';
import { PageNames } from '@src/redux/selectors/scroll/enums/pageNames.enum';
import { getScrollableContainer } from '@src/App';
import createLink from '@src/util/createLink';
import { relativeTimeWithShortenedNames } from '@src/util/relativeTime';
import { ItemType } from '@src/metrics/enums/itemType.enum';
import { HorizontalListBlock } from '@src/components/HorizontalListBlock';
import { HorizontalListBlockSkeleton } from '@src/components/HorizontalListBlock/HorizontalListBlockSkeleton';
import { useRecentCompanies } from './hooks/useRecentCompanies';
import './styles.scss';

export const ActiveCompanies: FC = () => {
  const previousLocation = useAppSelector(getPreviousLocation);

  const history = useHistory();

  const scrolls = useAppSelector(getScroll);

  const scroll = scrolls?.[PageNames.ACTIVE_COMPANIES] || 0;

  const dispatch = useAppDispatch();

  const { companies, isRecentlySeenCompaniesLoading } = useRecentCompanies();

  useEffect(() => {
    const scrollableContainer = getScrollableContainer();

    trackEvent({
      context: Context.RECENT_COMPANIES,
      interaction: Interaction.VIEW,
    });

    const isPrevRouteCompany =
      previousLocation &&
      find([COMPANY_ROUTE, COMPANY_ABOUT_ROUTE, COMPANY_JOB_OPENINGS_ROUTE], route =>
        matchPath(previousLocation, { path: route }),
      );

    if (scrollableContainer) {
      scrollableContainer.scrollTop = isPrevRouteCompany && scroll ? scroll : 0;
    }

    return () => {
      dispatch(setScroll(PageNames.ACTIVE_COMPANIES, scrollableContainer.scrollTop));
    };
  }, []);

  return (
    <div className="active-companies fade-in">
      <SubPageAppBar actions={[]} isFixed onBack={() => history.goBack()}>
        <div className="active-companies__app-bar-title">Companies</div>
      </SubPageAppBar>

      <div className="active-companies__list">
        {companies.map(company => {
          if (!company) {
            return null;
          }

          const mainLocation =
            company.profile.offices.find(office => office.isHeadquarter) || company.profile.offices[0];

          const activeLabel = relativeTimeWithShortenedNames(company.lastSeen);

          return (
            <HorizontalListBlock
              key={company.id}
              onClick={() => {
                trackEvent({
                  context: Context.RECENT_COMPANIES,
                  interaction: Interaction.CLICK_COMPANY_PROFILE,
                  itemType: ItemType.COMPANY,
                  itemValue: company.profile.name,
                });
              }}
              to={createLink({
                link: COMPANY_ROUTE,
                params: { companyId: company.id },
              })}
              imageSrc={company.profile.logo?.fullUrl}
              name={company.profile.name}
              description={mainLocation?.location.shortName}
              statusLabel={activeLabel}
            />
          );
        })}

        {isRecentlySeenCompaniesLoading &&
          [1, 2, 3, 4, 5].map(item => {
            return <HorizontalListBlockSkeleton key={item} />;
          })}
      </div>
    </div>
  );
};
