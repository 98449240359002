import React, { FC, useState } from 'react';
import Svg from 'svg-inline-react';
import ExpandSvg from 'assets/images/fr-expand.svg';
import { SelectProps } from './props';
import './styles.scss';

export const Select: FC<SelectProps> = ({ defaultSelectedOptionId, options, onChange }) => {
  const [selectedOption, setSelectedOption] = useState({ id: defaultSelectedOptionId, value: null });

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    const selected = JSON.parse(event.target.value);
    setSelectedOption(selected);
    onChange(selected);
  };

  return (
    <div className="default-select-wrapper">
      <select className="default-select-wrapper__select" onChange={handleSelectChange}>
        {options.map(option => {
          return (
            <option key={option.id} selected={option.id === selectedOption.id} value={JSON.stringify(option)}>
              {option.value}
            </option>
          );
        })}
      </select>
      <Svg className="default-select-wrapper__icon" src={ExpandSvg} />
    </div>
  );
};
