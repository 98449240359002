import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { NavLink } from 'react-router-dom';
import { HorizontalScroller } from '@src/components/HorizontalScroller';
import './styles.scss';

const Navigation = forwardRef(({ wrapperClassName, navigationTabs }, ref) => {
  return (
    <div className={classnames('navigation', wrapperClassName)} ref={ref}>
      <HorizontalScroller className="navigation__wrapper" containerClassName="navigation__container">
        {navigationTabs.map(({ path, label, amount }, i) => (
          <NavLink
            activeClassName="navigation__nav-item--active"
            className="navigation__nav-item"
            key={i}
            replace
            to={path}
          >
            {label} {!!amount && <span className="navigation__items-amount">&nbsp;•&nbsp;{amount}</span>}
          </NavLink>
        ))}
      </HorizontalScroller>
    </div>
  );
});

Navigation.propTypes = {
  navigationTabs: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.number,
      label: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  wrapperClassName: PropTypes.string,
};

export default Navigation;
