import React, { FC } from 'react';
import { get, round } from 'lodash';
import classNames from 'classnames';
import { AvatarWithInfo } from '@src/components/AvatarWithInfo';
import { MessageWithReadMore } from '@src/components/MessageWithReadMore';
import { CompanyReviewLike } from '@src/containers/Feed/CompanyReviewFeedItem/CompanyReviewLike';
import { relativeTime } from '@src/util/relativeTime';
import { Rating } from '@src/components/Rating';
import { AnonymousUserSvg } from '@src/components/AnonymousUserSvg';
import { AvatarSize } from '@src/components/Avatar/enums/avatarSize.enum';
import { likeReview } from '@src/graphql/reviews/actions/likeReview';
import { getReviewActorData } from '@src/util/reviews/getReviewActorData';
import { ReviewActorEnum, UserCompanyAssociationTypeEnum } from '@src/graphql/generated';
import { RatingColor } from '@src/components/Rating/enums/ratingColor.enum';
import { RatingSize } from '@src/components/Rating/enums/ratingSize.enum';
import { ActorNameWithStatus } from '../AwardFeedItem/ActorNameWithStatus';
import { RepliedComment } from '../RepliedComment';
import { CompanyReviewFeedItemProps } from './props';
import './styles.scss';

export const CompanyReviewFeedItem: FC<CompanyReviewFeedItemProps> = ({ review, isSpaced = true }) => {
  const actor =
    review.actor.type === ReviewActorEnum.USER ? getReviewActorData(review.actor.entity, review.createdAt) : undefined;
  const isAnonymous = review.__typename === 'CompanyRatingReviewType' && review.isAnonymous;
  const averageRating = round(get(review, 'ratings.total', 0));

  const avatarColor = actor?.avatarColor;
  const fullName = actor?.fullName;
  const nameInitials = actor?.nameInitials;
  const userPosition = actor?.userPosition;

  return (
    <div
      className={classNames('review-feed-item', {
        'review-feed-item--spaced': isSpaced,
      })}
    >
      <AvatarWithInfo
        avatarProps={{
          avatarColor: avatarColor,
          children: isAnonymous && <AnonymousUserSvg pathColor={avatarColor} />,
          initials: nameInitials,
          size: AvatarSize.MEDIUM_LARGE,
        }}
        name={
          <ActorNameWithStatus
            fullName={fullName}
            isAnonymous={!!isAnonymous}
            isWorking={
              review.__typename === 'CompanyReviewType'
                ? review.userAssociations.includes(UserCompanyAssociationTypeEnum.WORKING)
                : review.__typename === 'CompanyRatingReviewType'
                ? review.userAssociation === UserCompanyAssociationTypeEnum.WORKING
                : false
            }
          />
        }
        subTitle={isAnonymous ? relativeTime(review.createdAt) : `${userPosition} • ${relativeTime(review.createdAt)}`}
        wrapperClassName="review-feed-item__user-info"
      />

      <Rating
        activeColor={RatingColor.BLUE}
        activeRating={averageRating}
        isDisabled
        maxRating={5}
        shouldDisplayOnlyActiveOnes
        size={RatingSize.SMALL}
      />

      {review.__typename === 'CompanyRatingReviewType' && <h3 className="review-feed-item__title">{review.title}</h3>}

      {review.__typename === 'CompanyRatingReviewType' && review.positiveFeedback && (
        <MessageWithReadMore
          shouldForceInline
          limit={200}
          lineLimit={3}
          message={`👍 ${review.positiveFeedback}`}
          messageClassName="review-feed-item__comment"
          wrapperClassName="review-feed-item__comment-wrapper"
        />
      )}

      {review.__typename === 'CompanyRatingReviewType' && review.negativeFeedback && (
        <MessageWithReadMore
          shouldForceInline
          limit={200}
          lineLimit={3}
          message={`👎 ${review.negativeFeedback}`}
          messageClassName="review-feed-item__comment"
          wrapperClassName="review-feed-item__comment-wrapper"
        />
      )}

      <div className="review-feed-item__reaction-wrapper">
        <CompanyReviewLike
          className="review-feed-item__like-reaction"
          hasLike={review.hasReacted}
          iconClassName="review-feed-item__like-icon"
          labelClassName="review-feed-item__like-label"
          onTouchEnd={hasActiveLike => {
            if (window.isPublicApp && review?.company?.deepLinkUrl) {
              window.open(review.company.deepLinkUrl, '_blank');

              return;
            }

            likeReview(review.id, !hasActiveLike);
          }}
          reactionsCount={review.reactionsCount}
        />
      </div>

      {review.reply ? <RepliedComment reply={review?.reply} /> : null}
    </div>
  );
};
