import React, { FC } from 'react';
import { AvatarWithInfo } from '@src/components/AvatarWithInfo';
import { MessageWithReadMore } from '@src/components/MessageWithReadMore';
import { AvatarSize } from '@src/components/Avatar/enums/avatarSize.enum';
import { RepliedCommentProps } from './props';
import './styles.scss';

export const RepliedComment: FC<RepliedCommentProps> = ({ reply }) => {
  const currentCompany = reply?.repliedBy.company;

  return (
    <div className="replied-comment fade-in">
      <AvatarWithInfo
        avatarProps={{
          size: AvatarSize.X_LARGE,
          src: currentCompany?.profile.logo?.fullUrl,
        }}
        name={reply?.repliedBy.fullName}
        subTitle={reply?.repliedBy.title}
        wrapperClassName="replied-comment__user-info"
      />

      <MessageWithReadMore
        shouldForceInline
        limit={200}
        lineLimit={3}
        message={reply?.comment}
        messageClassName="replied-comment__comment"
        readMoreClassName="replied-comment__comment-read-more"
      />
    </div>
  );
};
