import React from 'react';
import './styles.scss';

export default function Skeleton() {
  return (
    <div className="company-profile-title-skeleton">
      <div className="company-profile-title-skeleton__info">
        <div className="company-profile-title-skeleton__title pulse-fade">
          <h3 />
          <p />
        </div>
      </div>
    </div>
  );
}
