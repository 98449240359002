/**
 * Example in design:
 * https://www.figma.com/file/ynSJbcEdvXdWTeDRnD3RA4/Consumer-Mobile-App%3A-Concepts?node-id=1503%3A15434
 */
import React, { FC } from 'react';
import classnames from 'classnames';
import Svg from 'svg-inline-react';
import FrPinSvg from 'assets/images/fr-pin.svg';
import FadeInImage from '@src/components/FadeInImage';
import { Score } from '@src/components/Score';
import { InvisibleButton } from '@src/components/Button';
import { ThumbnailCardWithViewButtonProps } from './props';
import './styles.scss';

export const ThumbnailCardWithViewButton: FC<ThumbnailCardWithViewButtonProps> = ({
  wrapperClassName,
  backGroundImage,
  logo,
  title,
  locationTitle,
  logoTitle,
  onClick,
  totalRating,
  totalRatingsCount,
}) => {
  return (
    <div className={classnames('thumbnail-card-view-button', wrapperClassName)} onClick={() => onClick && onClick()}>
      <FadeInImage className="thumbnail-card-view-button__bg-image" src={backGroundImage} />

      <div className="thumbnail-card-view-button__shade" />

      <div className="thumbnail-card-view-button__header">
        {logo && <FadeInImage className="thumbnail-card-view-button__logo" src={logo} />}

        <div className="thumbnail-card-view-button__logo-info">
          <div className="thumbnail-card-view-button__logo-title">{logoTitle}</div>

          {!!totalRating && !!totalRatingsCount && (
            <div className="thumbnail-card-view-button__logo-rating">
              <Score
                countClassName="thumbnail-card-view-button__score-count"
                iconClassName="thumbnail-card-view-button__score-icon"
                ratings={totalRatingsCount}
                score={totalRating}
                size={'small'}
                wrapperClassName="thumbnail-card-view-button__score-wrapper"
              />
            </div>
          )}
        </div>
      </div>

      <div className="thumbnail-card-view-button__footer">
        <div className="thumbnail-card-view-button__location-title">
          <Svg src={FrPinSvg} />
          {locationTitle}
        </div>

        <div className="thumbnail-card-view-button__title">{title}</div>

        <InvisibleButton className="thumbnail-card-view-button__button">View offer</InvisibleButton>
      </div>
    </div>
  );
};
