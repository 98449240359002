import { FetchResult } from '@apollo/client';
import { apolloClient } from '@src/graphql/apollo';
import { LikeReviewDocument, LikeReviewMutation } from '@src/graphql/generated';
import { trackEvent } from '@src/metrics';
import { Context } from '@src/metrics/enums/context.enum';
import { Interaction } from '@src/metrics/enums/interaction.enum';
import { ItemType } from '@src/metrics/enums/itemType.enum';

export async function likeReview(reviewId: string, hasReacted: boolean): Promise<FetchResult<LikeReviewMutation>> {
  const result = await apolloClient.mutate<LikeReviewMutation>({
    fetchPolicy: 'no-cache',
    mutation: LikeReviewDocument,
    variables: {
      reviewId,
    },
  });

  if (hasReacted) {
    trackEvent({
      context: Context.REVIEW,
      interaction: Interaction.LIKE_REVIEW,
      itemType: ItemType.REVIEW,
      itemValue: reviewId,
    });
  }

  return result;
}
