import { useLocalStorage } from '@src/localStorage/hooks/useLocalStorage';
import { AreaType, CountryType } from '@src/graphql/generated';
import { ALL_PLACE_ID, REMOTE_PLACE_ID } from '@src/constants/placeIds';
import { PlacesData, UsePlaceFilterParams, UsePlaceFilterValue } from './interfaces/usePlaceFilter.interface';

export const usePlaceFilter = ({ localStorageKey }: UsePlaceFilterParams): UsePlaceFilterValue => {
  const [savedPlacesData, setSavedPlacesData] = useLocalStorage<PlacesData>(localStorageKey);

  const activePlaceId = savedPlacesData?.activePlaceId || ALL_PLACE_ID;
  const savedPlaces = savedPlacesData?.places || [];
  const savedRemotePlaces = savedPlacesData?.remotePlaces || [];

  const addPlaces = (places: (AreaType | CountryType)[], activePlaceId?: string) => {
    setSavedPlacesData({
      activePlaceId: activePlaceId,
      places: places,
      remotePlaces: savedRemotePlaces,
    });
  };

  const addRemotePlaces = (remotePlaces: (AreaType | CountryType)[]) => {
    setSavedPlacesData({
      activePlaceId: REMOTE_PLACE_ID,
      places: savedPlaces,
      remotePlaces: remotePlaces,
    });
  };

  const setPlaceActive = (placeId: string) => {
    setSavedPlacesData({
      activePlaceId: placeId,
      places: savedPlaces,
      remotePlaces: savedRemotePlaces,
    });
  };

  return {
    activePlaceId: activePlaceId,
    addPlaces: addPlaces,
    addRemotePlaces: addRemotePlaces,
    savedPlaces: savedPlaces,
    savedRemotePlaces: savedRemotePlaces,
    setPlaceActive: setPlaceActive,
  };
};
