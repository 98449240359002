import React, { FC } from 'react';
import Svg from 'svg-inline-react';
import classnames from 'classnames';
import MfTextLogoWhiteSvg from 'assets/images/mf-text-logo-white.svg';
import MfTextLogoSvg from 'assets/images/mf-text-logo.svg';
import { DefaultIcons } from './DefaultIcons';
import { Props } from './props';
import './styles.scss';

export const BaseAppBar: FC<Props> = ({
  isFixed,
  isDark,
  isDefaultActionsHidden = window.isPublicApp,
  wrapperClassName,
  leadingEl,
  children,
  actions,
  isFrankBannerVisible = true,
}) => (
  <div
    className={classnames(
      'base-app-bar',
      {
        'base-app-bar--dark': isDark,
        'base-app-bar--fixed': isFixed,
      },
      wrapperClassName,
    )}
  >
    {!!leadingEl && <div className="base-app-bar__leading">{leadingEl}</div>}

    <div className="base-app-bar__title">
      <div className="base-app-bar__title-container">
        {children ||
          (isFrankBannerVisible && (
            <Svg className="base-app-bar__bar-logo" src={isDark ? MfTextLogoWhiteSvg : MfTextLogoSvg} />
          ))}
      </div>
    </div>

    <div className="base-app-bar__actions">{actions || (!isDefaultActionsHidden && <DefaultIcons />)}</div>
  </div>
);
