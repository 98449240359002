import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setNavigationVisibility } from '@src/redux/actions/navigation';
import View from './View';

@connect(undefined, { setNavigationVisibility })
export default class ErrorCatch extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    setNavigationVisibility: PropTypes.func.isRequired,
  };

  state = {
    error: undefined,
    info: undefined,
  };

  componentDidCatch(error, info) {
    this.setState({ error, info });
    this.props.setNavigationVisibility(true);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.children !== this.props.children) {
      this.setState({
        error: undefined,
        info: undefined,
      });
    }
  }

  render() {
    const { error } = this.state;

    if (error) {
      return <View />;
    }

    return this.props.children;
  }
}
