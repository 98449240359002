import React, { FC, useEffect, useRef, useState } from 'react';
import { find } from 'lodash';
import { matchPath } from 'react-router-dom';
import { COMPANY_PREVIEW_ROUTE, COMPANY_REVIEWS_ROUTE } from '@src/constants/routes';
import { setScroll } from '@src/redux/actions/scroll';
import { Context } from '@src/metrics/enums/context.enum';
import { Interaction } from '@src/metrics/enums/interaction.enum';
import { useAppDispatch, useAppSelector } from '@src/store';
import { getPreviousLocation } from '@src/redux/selectors/navigation';
import { getScroll } from '@src/redux/selectors/scroll';
import { PageNames } from '@src/redux/selectors/scroll/enums/pageNames.enum';
import { getScrollableContainer } from '@src/App';
import { useCountryFilter } from '@src/customHooks/useCountryFilter';
import { LocalStorageKeys } from '@src/localStorage/enums/localStorageKeys.enum';
import { CountryType, PlaceTypeEnum, useGetUserQuery } from '@src/graphql/generated';
import { setContent as setModalContent, closeModal } from '@src/redux/actions/slideUpModal';
import { PageHeaderWithLocation } from '@src/containers/PageHeaderWithLocation';
import { PlaceFiltersModalContent } from '@src/containers/PlaceFiltersModalContent';
import { LocationViews } from '@src/containers/PlaceFiltersModalContent/enum/locationView.enum';
import { GLOBAL_AREA_ID } from '@src/constants';
import { isCountryType } from '@src/graphql/typeGuards';
import { createDefaultCountryItemsFromPlaces, createPlaceItem } from '@src/util/filters';
import { useQueryParams } from '@src/customHooks/useQueryParams';
import { SearchedCompanies } from '@src/containers/SearchedCompanies';
import { useCompanyBlocksSearch } from '@src/customHooks/useCompanyBlocksSearch';
import { CompaniesBlocks } from '@src/containers/CompaniesBlocks';
import { trackEvent } from '@src/metrics';
import { useTopCompanies } from './hooks/useTopCompanies';
import { QueryParams } from './interfaces/queryParams.interface';
import './styles.scss';

export const TopCompanies: FC = () => {
  const feedContainerRef = useRef(null);

  const previousLocation = useAppSelector(getPreviousLocation);

  const scrolls = useAppSelector(getScroll);

  const dispatch = useAppDispatch();

  const scrollableContainer = getScrollableContainer();

  const { queryParams, updateQueryParams } = useQueryParams<QueryParams>();

  const searchPattern = queryParams?.searchPattern;

  const [isSimulatedLoading, setIsSimulatedLoading] = useState(false);

  const { data: userData } = useGetUserQuery();

  const user = userData?.currentUser;
  const userLocation = user?.location;
  const userLocationCountry = userLocation?.country;

  const userCountryItem = userLocation?.country?.id
    ? createPlaceItem({
        entity: {
          __typename: 'CountryType',
          id: userLocation.country.id,
          longName: userLocationCountry?.longName || '',
          shortName: userLocationCountry?.shortName || '',
        },
        type: PlaceTypeEnum.COUNTRY,
      })
    : undefined;

  const { savedCountries, setActiveCountry, activeCountryId, addCountries } = useCountryFilter({
    localStorageKey: LocalStorageKeys.USER_SELECTED_REVIEWS_COUNTRY_DATA,
  });

  const placeItems = createDefaultCountryItemsFromPlaces(savedCountries, userCountryItem);

  const { companies, isTopReviewedCompaniesLoading } = useTopCompanies({
    activeCountryId: activeCountryId,
    scrollableContainer: scrollableContainer,
  });

  const isLoading = isTopReviewedCompaniesLoading || isSimulatedLoading;

  const { isSearchedCompaniesLoading, shouldDisplaySearchResults, searchedCompanies } = useCompanyBlocksSearch({
    activeCountryId: activeCountryId,
    searchPattern: searchPattern,
  });

  const simulateLoading = (): void => {
    setIsSimulatedLoading(true);

    setTimeout(() => {
      setIsSimulatedLoading(false);
    }, 350);
  };

  const handleActivePlaceSet = (placeId: string) => {
    setActiveCountry(placeId);

    simulateLoading();
  };

  const onFiltersApplied = (countries: CountryType[]) => {
    scrollableContainer.scrollTop = 0;

    const selectedCountry =
      countries.find(country => country.id === activeCountryId) || countries[countries.length - 1];

    addCountries(countries, selectedCountry?.id);
  };

  const openLocationFilter = () => {
    dispatch(
      setModalContent({
        content: (
          <PlaceFiltersModalContent
            defaultPlaces={savedCountries}
            defaultView={LocationViews.COUNTRY_SEARCH_VIEW}
            availableViews={[LocationViews.COUNTRY_SEARCH_VIEW]}
            excludedAreaIds={[GLOBAL_AREA_ID]}
            onApplyFilters={places => {
              const filteredCountries = places.filter(isCountryType);

              onFiltersApplied(filteredCountries);
            }}
            onClose={() => dispatch(closeModal())}
          />
        ),
        height: '70vh',
      }),
    );
  };

  useEffect(() => {
    const scroll = scrolls?.[PageNames.TOP_COMPANIES] || 0;

    const scrollableContainer = getScrollableContainer();

    trackEvent({
      context: Context.TOP_COMPANIES,
      interaction: Interaction.VIEW,
    });

    const isPrevRouteCompany =
      previousLocation &&
      find([COMPANY_REVIEWS_ROUTE, COMPANY_PREVIEW_ROUTE], route => matchPath(previousLocation, { path: route }));

    if (scrollableContainer) {
      scrollableContainer.scrollTop = isPrevRouteCompany && scroll ? scroll : 0;
    }

    return () => {
      dispatch(setScroll(PageNames.TOP_COMPANIES, scrollableContainer.scrollTop));
    };
  }, []);

  return (
    <div className="top-companies-view">
      <PageHeaderWithLocation
        title="Top companies"
        activePlaceId={activeCountryId}
        addPlace={openLocationFilter}
        getScrollableContainer={getScrollableContainer}
        onPlaceClick={place => handleActivePlaceSet(place.id)}
        places={placeItems}
        providedTargetEl={feedContainerRef}
        searchPattern={searchPattern}
        onSearch={searchPattern => {
          updateQueryParams({
            searchPattern: searchPattern,
          });
        }}
      />

      <div className="top-companies-view__content" ref={feedContainerRef}>
        {shouldDisplaySearchResults && !!searchPattern && (
          <SearchedCompanies
            companies={searchedCompanies}
            isLoading={isSearchedCompaniesLoading}
            searchPattern={searchPattern}
          />
        )}

        {!shouldDisplaySearchResults && <CompaniesBlocks companies={companies} isLoading={isLoading} />}
      </div>
    </div>
  );
};
