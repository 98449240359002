import React, { FC } from 'react';
import { BlueButton, PinkButton } from '@src/components/Button';
import { ChatActionsProps } from './props';
import './styles.scss';

export const ChatActions: FC<ChatActionsProps> = ({ onAccept, onDecline }) => {
  return (
    <div className="chat-actions">
      <PinkButton isFilled onClick={onDecline}>
        Decline
      </PinkButton>

      <BlueButton isFilled onClick={onAccept}>
        Accept
      </BlueButton>
    </div>
  );
};
