import React, { FC, useEffect } from 'react';
import { find } from 'lodash';
import { matchPath, useHistory } from 'react-router-dom';
import { SubPageAppBar } from '@src/components/PrimaryAppBar';
import { JobOpeningFeedItem } from '@src/containers/Feed/JobOpeningFeedItem';
import { FeedItemSkeleton } from '@src/containers/Feed/Skeleton';
import { JOB_OPENING_ROUTE } from '@src/constants/routes';
import { setScroll } from '@src/redux/actions/scroll';
import { getScroll } from '@src/redux/selectors/scroll';
import { getPreviousLocation } from '@src/redux/selectors/navigation';
import { getWrapperScrollableContainer } from '@src/App';
import { setNavigationVisibility } from '@src/redux/actions/navigation';
import { trackEvent } from '@src/metrics';
import { useAppDispatch, useAppSelector } from '@src/store';
import { PageNames } from '@src/redux/selectors/scroll/enums/pageNames.enum';
import { Context } from '@src/metrics/enums/context.enum';
import { Interaction } from '@src/metrics/enums/interaction.enum';
import { useBookmarkedOpenings } from './hooks/useBookmmarkedOpenings';
import './styles.scss';

export const MyBookmarks: FC = () => {
  const history = useHistory();

  const dispatch = useAppDispatch();

  const previousLocation = useAppSelector(getPreviousLocation);

  const scrolls = useAppSelector(getScroll);

  const scroll = scrolls?.[PageNames.MY_BOOKMARKS] || 0;

  const getScrollableContainer = () => {
    const wrapperScrollableContainer = getWrapperScrollableContainer();

    return wrapperScrollableContainer[0];
  };

  const { isLoading, feed } = useBookmarkedOpenings({ getScrollableContainer });

  useEffect(() => {
    const scrollableContainer = getScrollableContainer();

    trackEvent({
      context: Context.SAVED_OFFERS,
      interaction: Interaction.VIEW,
    });

    dispatch(setNavigationVisibility(false));

    const isPrevRouteOpening =
      previousLocation && find([JOB_OPENING_ROUTE], route => matchPath(previousLocation, { path: route }));

    if (scrollableContainer) {
      scrollableContainer.scrollTop = isPrevRouteOpening && scroll ? scroll : 0;
    }

    return () => {
      dispatch(setNavigationVisibility(true));

      dispatch(setScroll(PageNames.MY_BOOKMARKS, scrollableContainer.scrollTop));
    };
  }, []);

  return (
    <div className="my-bookmarks">
      <SubPageAppBar isFixed onBack={history.goBack}>
        Saved offers
      </SubPageAppBar>

      <div className="my-bookmarks__feed feed-bg-color">
        {feed?.map(opening => {
          if (!opening) {
            return null;
          }

          return (
            <div className="feed-item-gap" key={opening?.id}>
              <JobOpeningFeedItem metricsContext={Context.SAVED_OFFERS} opening={opening} />
            </div>
          );
        })}
      </div>

      {isLoading && <FeedItemSkeleton />}
    </div>
  );
};
