import { ACCESS_TOKEN_REMOVE, ACCESS_TOKEN_SET } from '@src/redux/actions/accessToken';

export function accessToken(state = null, action) {
  switch (action.type) {
    case ACCESS_TOKEN_SET:
      return action.accessToken;
    case ACCESS_TOKEN_REMOVE:
      return null;
    default:
      return state;
  }
}
