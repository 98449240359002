import React, { FC, Fragment } from 'react';
import { partition, isEmpty } from 'lodash';
import FrPinSvg from 'assets/images/fr-pin.svg';
import FrUserSvg from 'assets/images/fr-user.svg';
import Svg from 'svg-inline-react';
import DiamondSvg from '@shared/assets/svgs/diamond.svg';
import FrClipboardSvg from 'assets/images/fr-clipboard.svg';
import EuroSvg from '@shared/assets/svgs/euro.svg';
import FrClockSvg from 'assets/images/fr-clock.svg';
import { OpeningEmploymentTypeEnum, OpeningRemoteTypeEnum, OpeningWorkloadPeriodEnum } from '@src/graphql/generated';
import Button from '@src/components/ButtonOld';
import { SalaryRange } from '@src/components/SalaryRange';
import { ItemsList, Item } from '../ItemsList';
import { PositionSkillsProps } from './props';
import './styles.scss';

export const PositionSkills: FC<PositionSkillsProps> = ({
  employmentType,
  positionLocations,
  speciality,
  salaryRange,
  workload,
  skills,
  openTaxesInfo,
  seniorities,
  remoteType,
}) => {
  const specialityTag = speciality?.skillTags?.[0];

  const [specialities, _skills] = partition(skills, skill => specialityTag && skill?.tags.includes(specialityTag));

  return (
    <div className="opening-position-skills">
      <ItemsList>
        {employmentType === OpeningEmploymentTypeEnum.PART_TIME && (
          <Item
            data={
              <span className="opening-position-skills__item--highlight">
                {workload?.period === OpeningWorkloadPeriodEnum.TOTAL ? (
                  <span>{workload?.hours} hours</span>
                ) : (
                  <span>
                    {workload?.hours} hours per {workload?.period}
                  </span>
                )}
              </span>
            }
            icon={FrClockSvg}
            info="With capacity of"
          />
        )}

        {remoteType !== OpeningRemoteTypeEnum.FULLY && !isEmpty(positionLocations) && (
          <Item
            data={positionLocations?.map((location, index) => (
              <Fragment key={index}>
                {index !== 0 && ' or '}
                <span className="opening-position-skills__item--highlight">{location?.shortName}</span>
              </Fragment>
            ))}
            icon={FrPinSvg}
            info="Interested to work in"
          />
        )}

        {!isEmpty(specialities) && (
          <Item
            data={specialities.map((speciality, i) => (
              <Fragment key={i}>
                {i !== 0 && ' or '}
                <span className="opening-position-skills__item--highlight">{speciality?.name}</span>
              </Fragment>
            ))}
            icon={FrUserSvg}
            info="Who specialize in"
          />
        )}

        {!isEmpty(_skills) && (
          <Item
            data={_skills.map((skill, i) => (
              <Fragment key={i}>
                {i !== 0 && ' or '}
                <span className="opening-position-skills__item--highlight">{skill?.name}</span>
              </Fragment>
            ))}
            icon={DiamondSvg}
            info="Have following skills"
          />
        )}

        {!isEmpty(seniorities) && employmentType !== OpeningEmploymentTypeEnum.INTERNSHIP && (
          <Item
            data={
              <>
                {seniorities?.map(({ name }, index) => (
                  <Fragment key={index}>
                    {index !== 0 && ' or '}

                    <span className="opening-position-skills__item--highlight">{name}</span>
                  </Fragment>
                ))}

                <span>&nbsp;position</span>
              </>
            }
            icon={FrClipboardSvg}
            info="We are looking to fill a"
          />
        )}

        {!window.isPublicApp && salaryRange && (salaryRange.to || salaryRange.from) && (
          <Item
            dataNode={
              <div className="opening-position-skills__item opening-position-skills__item--highlight">
                <span>
                  <SalaryRange salaryRange={salaryRange} />
                </span>

                {employmentType === OpeningEmploymentTypeEnum.PROJECT && (
                  <Button className="opening-position-skills__taxes-info" onClick={openTaxesInfo}>
                    <Svg className="opening-position-skills__taxes-info-euro-sign" src={EuroSvg} />

                    <span>Taxes may apply</span>
                  </Button>
                )}
              </div>
            }
            icon={EuroSvg}
            info="Salary range"
          />
        )}
      </ItemsList>
    </div>
  );
};
