import React, { FC } from 'react';
import classNames from 'classnames';
import { MessageBubbleText } from '../MessageBubbleText';
import { MessageSkeletonProps } from './props';
import './styles.scss';

export const MessageSkeleton: FC<MessageSkeletonProps> = ({ isNextSenderDifferent, isIncoming, messageText = ' ' }) => {
  return (
    <div
      className={classNames(
        'inbox-message-skeleton',
        isIncoming ? 'inbox-message-skeleton--incoming' : 'inbox-message-skeleton--outgoing',
        isNextSenderDifferent && 'inbox-message-skeleton--last',
        'pulse-fade',
      )}
    >
      <div className="inbox-message-skeleton__message-container">
        <div className="inbox-message-skeleton__avatar pulse-fade"></div>

        <MessageBubbleText messageText={messageText} type={isIncoming ? 'incoming' : 'outgoing'} isLoading />
      </div>
    </div>
  );
};
