import React, { FC, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import ContentPush from '@src/components/ContentPush';
import { JOBS_SEARCH_ROUTE } from '@src/constants/routes';
import { nextQuestion, handleSpecialResponse, sendResponse } from '@src/redux/actions/question';
import { useGetUserQuery } from '@src/graphql/generated';
import { sendFacebookAppEvent, resendContainerDataItems, registerForPush } from '@src/container';
import { useAppDispatch } from '@src/store';
import { setNavigationVisibility } from '@src/redux/actions/navigation';
import { trackSuperProperties } from '@src/metrics';
import { isOnboardedUserProfileType } from './typeGuards';
import { State } from './mainView.interface';
import { ONBOARDING_STEPS, OnboardingView } from './steps';
import MainView from './';

export const MainViewWrapper: FC = () => {
  const dispatch = useAppDispatch();

  const { data: userData, refetch } = useGetUserQuery();
  const user = userData?.currentUser;

  const [{ contentType, question, selectedValue }, setState] = useState<State>(
    user && !isOnboardedUserProfileType(user.profile) && !!user.email
      ? {
          contentType: 'question',
          question: ONBOARDING_STEPS[OnboardingView.CONTINUE_ONBOARDING],
          selectedValue: {},
        }
      : {
          contentType: undefined,
          question: undefined,
          selectedValue: {},
        },
  );

  const setQuestion = question => {
    if (question?.key === 'ios-enable-push') {
      dispatch(setNavigationVisibility(false));
    }

    setState(state => ({
      ...state,
      contentType: question ? 'question' : undefined,
      question: question,
    }));
  };

  const handleResponse = ({ question }) => {
    if (question) {
      setQuestion(question);

      return;
    }

    setState(state => ({ ...state, contentType: 'content' }));
  };

  const fetchNext = async () => {
    const data = await nextQuestion();
    handleResponse(data);
  };

  const onSendResponse = async data => {
    handleSpecialResponse(question, data);
    const response = await sendResponse(question._id, data);
    handleResponse(response);
  };

  const handleContinue = data => {
    if (data) {
      onSendResponse({ ...data, ...selectedValue });
    } else {
      fetchNext();
    }
  };

  const handleLocationSelect = location => {
    onSendResponse({ location });
  };

  const handleValueSelect = selectedValue => {
    setState(state => ({ ...state, selectedValue }));
  };

  const handleInputChange = (key: string, value: string): void => {
    if (!value) {
      handleValueSelect(undefined);
    } else {
      handleValueSelect({ [key]: value });
    }
  };

  const onOnboardingDone = async isRecoveryUser => {
    const { data } = await refetch();
    const refetchedUser = data.currentUser;

    sendFacebookAppEvent({
      name: 'fb_mobile_complete_registration',
      params: { speciality: refetchedUser?.inboxProfile?.speciality?.name },
    });

    trackSuperProperties({ 'Is Onboarded': true });

    if (isRecoveryUser) {
      setState(state => ({ ...state, contentType: 'content' }));
    } else {
      fetchNext();
    }

    resendContainerDataItems();
    registerForPush();
  };

  useEffect(() => {
    if (!question) {
      fetchNext();
    }
  }, []);

  if (user?.onboardingDone) {
    if (contentType === 'question') {
      return (
        <ContentPush
          loading={false}
          onContinue={handleContinue}
          onInputChange={handleInputChange}
          onLocationSelect={handleLocationSelect}
          onValueSelect={handleValueSelect}
          question={question}
          selectedValue={selectedValue}
          sendResponse={onSendResponse}
          showHeader={false}
        />
      );
    }

    if (contentType === 'content') {
      return <Redirect to={JOBS_SEARCH_ROUTE} />;
    }
  } else {
    if (contentType === 'question') {
      return (
        <ContentPush
          loading={false}
          onContinue={handleContinue}
          onInputChange={handleInputChange}
          onLocationSelect={handleLocationSelect}
          onValueSelect={handleValueSelect}
          question={question}
          selectedValue={selectedValue}
          sendResponse={onSendResponse}
          showHeader={false}
        />
      );
    }

    return <MainView onOnboardingDone={isRecoveryUser => onOnboardingDone(isRecoveryUser)} />;
  }

  return null;
};
