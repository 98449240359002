import gql from 'graphql-tag';

export const GET_GOOGLE_LOCATIONS = gql`
  query googleLocations($searchPattern: String!) {
    googleLocations(searchPattern: $searchPattern, paginationOptions: { sortOrder: ASC }) {
      result {
        id
        placeId
        longName
        shortName
        country {
          id
          shortName
        }
      }
    }
  }
`;

export const GET_LOCATION_BY_ID = gql`
  query getLocationById($id: ID!) {
    location(id: $id) {
      id
      shortName
      longName
      country {
        id
        shortName
        longName
      }
    }
  }
`;

export const GET_COUNTRY_BY_ID = gql`
  query getCountryById($id: ID!) {
    country(id: $id) {
      id
      longName
      shortName
    }
  }
`;

export const GET_AREAS = gql`
  query getAreas {
    areas {
      id
      longName
    }
  }
`;

export const GET_AREA_BY_ID = gql`
  query getAreaById($id: ID!) {
    area(id: $id) {
      id
      longName
    }
  }
`;
