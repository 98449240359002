import PinSvg from 'assets/images/fr-pin.svg';
import LaptopSvg from '@shared/assets/svgs/laptop.svg';
import GlobeSvg from '@shared/assets/svgs/globe.svg';

export const ALL_SPECIALITIES = {
  id: 'ALL_SPECIALITIES',
  key: 'ALL_SPECIALITIES',
  name: 'All specialities',
};

export const ALL_COUNTRIES = {
  id: 'ALL_COUNTRIES',
  key: 'ALL_COUNTRIES',
  name: 'All locations',
  svgSrc: PinSvg,
};

export const ALL_SENIORITIES = {
  id: 'ALL_SENIORITIES',
  key: 'ALL_SENIORITIES',
  name: 'All seniorities',
};

export const REMOTE = {
  id: 'REMOTE',
  key: 'REMOTE',
  longName: 'Remote',
  name: 'Remote',
  svgSrc: LaptopSvg,
};

export const RELOCATION = {
  id: 'RELOCATION',
  key: 'RELOCATION',
  longName: 'Relocation',
  name: 'Relocation',
  svgSrc: GlobeSvg,
};

export const QUERY_KEYS = {
  COUNTRIES: 'countries',
  SENIORITIES: 'seniorities',
  SPECIALITIES: 'specialities',
};
