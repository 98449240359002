import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect, matchPath, useLocation, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import qs from 'qs';
import { find, get, map, truncate } from 'lodash';
import { BlueButton } from '@src/components/Button';
import { PageTitleWithOgTags } from '@src/components/PageTitleWithOgTags';
import { SecondaryLogoParallaxPage } from '@src/containers/ParallaxPage';
import { StickyNavigation } from '@src/containers/StickyNavigation';
import { Score } from '@src/components/Score';
import {
  COMPANY_ROUTE,
  COMPANY_ABOUT_ROUTE,
  COMPANY_JOB_OPENINGS_ROUTE,
  JOB_OPENING_ROUTE,
  CREATE_COMPANY_REVIEW_ROUTE,
  COMPANY_REVIEWS_ROUTE,
} from '@src/constants/routes';
import { getWrapperScrollableContainer } from '@src/App';
import { UserAssociationModal } from '@src/views/CompanyReviews/UserAssociationModal';
import { setContent as setModalContent, closeModal } from '@src/redux/actions/slideUpModal';
import { setScroll } from '@src/redux/actions/scroll';
import { getScroll } from '@src/redux/selectors/scroll';
import { getPreviousLocation } from '@src/redux/selectors/navigation';
import createLink from '@src/util/createLink';
import { OpeningSearchTagNameEnum, useCompanyQuery } from '@src/graphql/generated';
import { RootState } from '@src/store/interfaces/store.interface';
import { Title } from './Title';
import { JobOpenings } from './JobOpenings';
import { About } from './About';
import './styles.scss';

const SCROLL_KEY = 'company';

const _CompanyProfile = ({ scroll, previousLocation, setModalContent, closeModal, setScroll }) => {
  const location = useLocation();

  const history = useHistory();

  const getScrollableContainer = () => {
    return document.getElementsByClassName('parallax-page__content-wrapper');
  };

  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

  const companyRouteMatch = matchPath<{ companyId: string }>(location.pathname, { path: COMPANY_ROUTE });

  const companyId = companyRouteMatch?.params.companyId;

  const { data: companyData, loading } = useCompanyQuery({
    variables: {
      id: companyId,
      searchTags: [
        {
          tag: OpeningSearchTagNameEnum.STATE,
          value: 'active',
        },
      ],
    },
  });

  useEffect(() => {
    const scrollableContainer = getScrollableContainer();
    const wrapperScrollableContainer = getWrapperScrollableContainer();

    const isOpeningFeedRoute = !!matchPath(location.pathname, { path: COMPANY_JOB_OPENINGS_ROUTE });

    const shouldUpdateScroll =
      isOpeningFeedRoute &&
      previousLocation &&
      find([JOB_OPENING_ROUTE], route => matchPath(previousLocation, { path: route }));

    wrapperScrollableContainer[0].scrollTop = 0;
    scrollableContainer[0].scrollTop = shouldUpdateScroll ? scroll || 0 : 0;

    return () => {
      setScroll(SCROLL_KEY, scrollableContainer[0].scrollTop);
    };
  }, []);

  const company = get(companyData, 'company');
  const companyProfile = get(company, 'profile');
  const companyName = get(companyProfile, 'name');
  const companyOpeningsTotalCount = get(company, 'openings.pagination.totalCount');
  const deepLinkUrl = get(company, 'deepLinkUrl');
  const headerImage = get(companyProfile, 'headerImage.fullUrl');
  const companyLogo = get(companyProfile, 'logo.fullUrl');
  const missionStatement = get(companyProfile, 'missionStatement');
  const overallRating = get(company, 'reviewsOverview.ratings.total') || 0;
  const ratingsCount = get(company, 'reviewsOverview.totalCount') || 0;
  const reviewForCurrentUser = get(company, 'reviewsOverview.reviewForCurrentUser.id');

  const locationShortNames = map(get(companyProfile, 'locations') || [], 'shortName');
  const locationsSubtitle = truncate(locationShortNames.join(', '), '...', 50);

  const handleReviewButtonClick = () => {
    if (window.isPublicApp) {
      window.open(deepLinkUrl, '_blank');
    } else if (reviewForCurrentUser) {
      history.push(
        createLink({
          link: COMPANY_REVIEWS_ROUTE,
          params: { companyId },
        }),
      );
    } else {
      setModalContent({
        content: (
          <UserAssociationModal
            onClose={closeModal}
            onStatusClick={userAssociation =>
              history.push(
                createLink({
                  link: CREATE_COMPANY_REVIEW_ROUTE,
                  params: { companyId },
                  queryParams: { userAssociation },
                }),
              )
            }
          />
        ),
        height: 'auto',
      });
    }
  };

  const navigationTabs = [
    {
      label: 'About',
      path: createLink(COMPANY_ABOUT_ROUTE, { params: { companyId } }),
    },
    {
      amount: companyOpeningsTotalCount,
      label: 'Job openings',
      path: createLink(COMPANY_JOB_OPENINGS_ROUTE, { params: { companyId } }),
    },
  ];

  return (
    <div className="company-profile-view">
      {headerImage && <PageTitleWithOgTags imageUrl={headerImage} message={missionStatement} title={companyName} />}

      <SecondaryLogoParallaxPage
        bannerImage={headerImage}
        getScrollableContainer={getScrollableContainer}
        hasContentPadding
        loading={loading}
        logoImage={companyLogo}
        topContent={
          <>
            <div className="company-profile-view__company-header">
              <div>
                <Title loading={loading} subTitle={locationsSubtitle} title={companyName} />

                {!loading && overallRating > 0 && (
                  <Score
                    onClick={() => {
                      if (window.isPublicApp) {
                        window.open(deepLinkUrl, '_blank');

                        return;
                      }

                      if (companyId) {
                        history.push(
                          createLink({
                            link: COMPANY_REVIEWS_ROUTE,
                            params: { companyId },
                          }),
                        );
                      }
                    }}
                    ratings={ratingsCount}
                    score={overallRating}
                    size={'medium'}
                  />
                )}
              </div>

              {!loading && <BlueButton onClick={handleReviewButtonClick}>Review</BlueButton>}
            </div>

            <StickyNavigation
              getScrollableContainer={getScrollableContainer}
              headerNavigationIsHidden
              loading={loading}
              navigationTabs={navigationTabs}
              title={companyName}
            />
          </>
        }
      >
        <div className="company-profile-view__content-wrapper">
          <Switch>
            <Redirect
              exact
              from={COMPANY_ROUTE}
              to={createLink({ link: COMPANY_ABOUT_ROUTE, params: { companyId }, queryParams })}
            />

            <Route exact path={COMPANY_ABOUT_ROUTE}>
              <About
                companyId={companyId}
                companyProfile={companyProfile}
                getScrollableContainer={getScrollableContainer}
                loading={loading}
              />
            </Route>

            <Route exact path={COMPANY_JOB_OPENINGS_ROUTE}>
              <JobOpenings
                company={company}
                companyId={companyId}
                getScrollableContainer={getScrollableContainer}
                isCompanyLoading={loading}
              />
            </Route>
          </Switch>
        </div>
      </SecondaryLogoParallaxPage>
    </div>
  );
};

_CompanyProfile.propTypes = {
  closeModal: PropTypes.func.isRequired,
  previousLocation: PropTypes.string.isRequired,
  scroll: PropTypes.number,
  setModalContent: PropTypes.func.isRequired,
  setScroll: PropTypes.func.isRequired,
};

export const CompanyProfile = connect(
  (state: RootState) => ({
    previousLocation: getPreviousLocation(state),
    scroll: getScroll(state)[SCROLL_KEY],
  }),
  {
    closeModal,
    setModalContent,
    setScroll,
  },
)(_CompanyProfile);
