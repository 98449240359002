import { LOCATION_CHANGE } from 'connected-react-router';
import { get } from 'lodash';
import { MODAL_CLOSED, CONTENT_SET } from '@src/redux/actions/slideUpModal';

const DEFAULT_STATE = {
  closeOnLocationChange: true,
  content: undefined,
  contentClassName: undefined,
  height: 75,
};

export function slideUpModal(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      if (state.closeOnLocationChange) {
        return DEFAULT_STATE;
      }
      return { ...state };
    case MODAL_CLOSED:
      return DEFAULT_STATE;
    case CONTENT_SET:
      return {
        ...state,
        closeOnLocationChange: get(action, 'closeOnLocationChange', true),
        content: action.content,
        contentClassName: action.contentClassName,
        height: action.height || DEFAULT_STATE.height,
        removeBorderRadius: action.removeBorderRadius,
      };
    default:
      return state;
  }
}
