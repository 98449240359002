import React, { FC, useEffect } from 'react';
import classnames from 'classnames';
import Svg from 'svg-inline-react';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import {
  InboxMessageThreadUpdatedDocument,
  InboxMessageThreadUpdatedSubscription,
  MessageThreadStateEnum,
  useGetInboxUnreadMessageThreadsCountQuery,
  useGetUserQuery,
} from '@src/graphql/generated';
import { getVisible } from '@src/redux/selectors/navigation';
import { trackEvent } from '@src/metrics';
import createLink from '@src/util/createLink';
import { getWrapperScrollableContainer } from '@src/App';
import {
  INBOX_ROUTE,
  FRANK_ROUTE,
  MENU_ROUTE,
  REVIEWS_ROUTE,
  TOP_COMPANIES_ROUTE,
  JOBS_SEARCH_ROUTE,
} from '@src/constants/routes';
import { Context } from '@src/metrics/enums/context.enum';
import { Interaction } from '@src/metrics/enums/interaction.enum';
import { ItemType } from '@src/metrics/enums/itemType.enum';
import { useAppSelector } from '@src/store';
import { QueryParams } from '@src/views/JobsSearch/interfaces/queryParams.interface';
import { useQueryParams } from '@src/customHooks/useQueryParams';
import ReviewsSvg from './active/reviews.svg';
import ReviewsInactiveSvg from './inactive/reviews-inactive.svg';
import JobsSvg from './active/jobs.svg';
import JobsInactiveSvg from './inactive/jobs-inactive.svg';
import FrankSvg from './active/frank.svg';
import FrankInactiveSvg from './inactive/frank-inactive.svg';
import MessagesSvg from './active/messages.svg';
import MessagesInactiveSvg from './inactive/messages-inactive.svg';
import MenuSvg from './active/menu.svg';
import MenuInactiveSvg from './inactive/menu-inactive.svg';
import { isOnboardedUserProfileType } from './typeGuards';
import './styles.scss';

export const Navigation: FC = () => {
  const { removeQueryParams } = useQueryParams<QueryParams>();

  const history = useHistory();

  const location = useLocation();

  const isVisible = useAppSelector(getVisible);

  const { data: messageThreadsData, subscribeToMore: subscribeToMoreMessageThreads } =
    useGetInboxUnreadMessageThreadsCountQuery({
      fetchPolicy: 'network-only',
      variables: {
        filterInput: {
          isUnread: true,
          states: [MessageThreadStateEnum.OPEN],
        },
      },
    });

  const unreadChatsCount = messageThreadsData?.messageThreads.pagination?.totalCount || 0;

  const { data: userData } = useGetUserQuery();

  const user = userData?.currentUser;

  const isFrankRouteShown = user?.profile && isOnboardedUserProfileType(user.profile) && user.isEligibleForVerification;

  const isReviewsViewActive = [REVIEWS_ROUTE, TOP_COMPANIES_ROUTE].some(route =>
    matchPath(location.pathname, { path: route }),
  );

  const hasUnreadInboxMessages = !!unreadChatsCount;

  function goToOrScrollTop(path: string, itemType: ItemType, queryParamsToClear?: (keyof QueryParams)[]) {
    if (matchPath(location.pathname, { path })) {
      const wrapperScrollableContainer = getWrapperScrollableContainer();

      wrapperScrollableContainer?.[0].scroll({ behavior: 'smooth', left: 0, top: 0 });

      if (queryParamsToClear?.length) {
        removeQueryParams(queryParamsToClear);
      }
    } else {
      history.push(createLink(path));

      trackEvent({
        context: Context.NAVIGATION,
        interaction: Interaction.TAB_SELECTED,
        itemType: itemType,
      });
    }
  }

  useEffect(() => {
    subscribeToMoreMessageThreads<InboxMessageThreadUpdatedSubscription>({
      document: InboxMessageThreadUpdatedDocument,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data || !prev?.messageThreads) {
          return prev;
        }

        return {
          ...prev,
          messageThreads: {
            ...prev.messageThreads,
            pagination: {
              ...prev.messageThreads.pagination,
              totalCount: subscriptionData.data.messageThreadUpdated.unreadCount,
            },
          },
        };
      },
    });
  }, []);

  return (
    <nav className={classnames('nav', !isVisible && 'nav--hidden')}>
      <span
        className={classnames(
          'nav__nav-link',
          matchPath(location.pathname, { path: JOBS_SEARCH_ROUTE }) && 'nav__nav-link--active',
        )}
        onClick={() => goToOrScrollTop(JOBS_SEARCH_ROUTE, ItemType.DIGEST, ['isPlaceSearchActive'])}
      >
        <span>
          <Svg className="nav__item-icon" src={JobsInactiveSvg} />
          <Svg className="nav__item-icon" src={JobsSvg} />
          <span>Jobs</span>
        </span>
      </span>

      <span
        className={classnames('nav__nav-link', isReviewsViewActive && 'nav__nav-link--active')}
        onClick={() => goToOrScrollTop(REVIEWS_ROUTE, ItemType.DIGEST)}
      >
        <span>
          <Svg className="nav__item-icon" src={ReviewsInactiveSvg} />
          <Svg className="nav__item-icon" src={ReviewsSvg} />
          <span>Reviews</span>
        </span>
      </span>

      {isFrankRouteShown && (
        <span
          className={classnames(
            'nav__nav-link',
            matchPath(location.pathname, { path: FRANK_ROUTE }) && 'nav__nav-link--active',
          )}
          onClick={() => goToOrScrollTop(FRANK_ROUTE, ItemType.HOME)}
        >
          <span>
            <Svg className="nav__item-icon" src={FrankInactiveSvg} />
            <Svg className="nav__item-icon" src={FrankSvg} />
            <span>Frank</span>
          </span>
        </span>
      )}

      <span
        className={classnames(
          'nav__nav-link',
          matchPath(location.pathname, { path: INBOX_ROUTE }) && 'nav__nav-link--active',
        )}
        onClick={() => goToOrScrollTop(INBOX_ROUTE, ItemType.INBOX)}
      >
        <span>
          <Svg className="nav__item-icon" src={MessagesInactiveSvg} />
          <Svg className="nav__item-icon" src={MessagesSvg} />

          <div className={classnames(hasUnreadInboxMessages && 'nav__unread-dot')} />
          <span>Inbox</span>
        </span>
      </span>

      <span
        className={classnames(
          'nav__nav-link',
          matchPath(location.pathname, { path: MENU_ROUTE }) && 'nav__nav-link--active',
        )}
        onClick={() => goToOrScrollTop(MENU_ROUTE, ItemType.MENU)}
      >
        <span>
          <Svg className="nav__item-icon" src={MenuInactiveSvg} />
          <Svg className="nav__item-icon" src={MenuSvg} />

          <span>Menu</span>
        </span>
      </span>
    </nav>
  );
};
