import React, { FC } from 'react';
import './styles.scss';

export const ContactRequestSkeleton: FC = () => {
  return (
    <div className="contact-request-skeleton">
      <div className="contact-request-skeleton__avatar pulse-fade" />

      <div className="contact-request-skeleton__details">
        <div className="contact-request-skeleton__line contact-request-skeleton__line--small pulse-fade" />

        <div className="contact-request-skeleton__line contact-request-skeleton__line--large pulse-fade" />

        <div className="contact-request-skeleton__line contact-request-skeleton__line--medium pulse-fade" />
      </div>
    </div>
  );
};
