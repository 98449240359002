export enum Interaction {
  ACCEPT = 'Accept',
  APPLICATION = 'Application',
  AUTH_CODE_LOGIN_SUCCESSFUL = 'Auth Code Login Successful',
  BOOKMARK = 'Bookmark',
  CLICKED_HIGHLIGHTED_COMPANY = 'Clicked Highlighted Company',
  CLICKED_SEARCHED_ITEM = 'Clicked Searched Item',
  CLICK_APPLY = 'Click Apply',
  CLICK_CHANGE_REVIEW = 'Click Change Review',
  CLICK_COMPANY_ITEM = 'Click Company Item',
  CLICK_COMPANY_PROFILE = 'Click Company Profile',
  CLICK_JOB_OPENINGS = 'Click Job Openings',
  CLICK_POST = 'Click Post',
  CLICK_USER_ASSOCIATION = 'Click User Association',
  CLICK_VIEW_ALL_COMPANIES = 'Click View All Companies',
  CLICK_VIEW_ALL_FOR_YOU = 'Click View All For You',
  CLOSE_BANNER = 'Close Banner',
  DECLINE = 'Decline',
  DELETE = 'Delete',
  DISCARD = 'DISCARD',
  DO_YOU_ENJOY_WORKING_MODAL = 'Do You Enjoy Working Modal',
  FEED_LOADED = 'Feed Loaded',
  FETCH_MORE = 'Fetch More',
  LIKE_REVIEW = 'Like Review',
  LINKEDIN_INVALID = 'LinkedIn Invalid',
  LOADED = 'Loaded',
  LOGIN_TOKEN_LOGIN_SUCCESSFUL = 'Login Token Login Successful',
  OPEN = 'Open',
  OPEN_ASSISTANT_MODAL = 'Open Assistant Modal',
  OPEN_CHAT = 'Open Chat',
  OPEN_COMPANY_REVIEWS = 'Open Company Reviews',
  OPEN_REGISTER_AS_RECRUITER = 'Open Register as Recruiter',
  OPEN_USER_ASSOCIATION_MODAL = 'Open User Association Modal',
  REQUEST_LOGIN_AUTH_CODE = 'Request Login Auth Code',
  SAVE_CHANGES = 'Save changes',
  SELECT = 'Select',
  SELECT_CATEGORY = 'Select Category',
  SHARE = 'Share',
  SORT = 'Sort',
  START_APPLICATION = 'Start Application',
  START_EXTERNAL_APPLICATION = 'Start External Application',
  SWIPE = 'Swipe',
  TAB_SELECTED = 'Tab selected',
  UNFOLLOW = 'Unfollow',
  USER_LOADED = 'User loaded',
  VIEW = 'View',
  VIEW_JOB_OPENINGS = 'View Job Openings',
  VIEW_JOB_OPENING_DETAILS = 'View Job Opening Details',
  VIEW_SIMILAR_OFFER = 'View Similar Offer',
}
