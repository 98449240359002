import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { MediaList } from '@src/components/Media';
import { AboutTitle } from '../AboutTitle';
import './styles.scss';

export const Articles = ({ companyName, media }) => {
  if (isEmpty(media)) {
    return null;
  }

  return (
    <div className="company-profile-articles">
      <AboutTitle>{`Learn more about ${companyName}`}</AboutTitle>

      <MediaList media={media} wrapperClassName="company-profile-articles__wrapper" />
    </div>
  );
};

Articles.propTypes = {
  companyName: PropTypes.string.isRequired,
  media: PropTypes.arrayOf(PropTypes.object.isRequired),
};
