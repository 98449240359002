import React, { FC } from 'react';
import { ApolloProvider } from '@apollo/client';
import { apolloClient } from '@src/graphql/apollo';
import ErrorCatch from '@src/containers/ErrorCatch';
import SlideUpModal from '@src/containers/SlideUpModal';
import ToastMessage from '@src/components/ToastMessage';
import { SessionValidation } from '@src/App/SessionValidation';
import { ProtectedComponent } from '@src/components/ProtectedComponent';
import { FlashMessagesProvider } from '@src/context/FlashMessagesContext';
import Subscriptions from '../Subscriptions';
import { Navigation } from '../Navigation';
import { Routes } from './Routes';

export const MobileApp: FC = () => {
  return (
    <FlashMessagesProvider>
      <ApolloProvider client={apolloClient}>
        <SessionValidation>
          <Subscriptions>
            <ErrorCatch>
              <Routes />

              <SlideUpModal />

              <ProtectedComponent>
                <Navigation />
              </ProtectedComponent>

              <ToastMessage />
            </ErrorCatch>
          </Subscriptions>
        </SessionValidation>
      </ApolloProvider>
    </FlashMessagesProvider>
  );
};
