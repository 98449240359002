import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { get } from 'lodash';
import { SearchOpeningEntityTagNameEnum, useGetOpeningsCountForCategoryQuery } from '@src/graphql/generated';
import { useAppDispatch } from '@src/store';
import { setNavigationVisibility } from '@src/redux/actions/navigation';
import { SearchItem } from './SearchItem';
import { SuggestionItem } from './SuggestionItem';

export function SuggestionsList({ goToView, onItemClick, searchPattern }) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setNavigationVisibility(false));

    return () => {
      dispatch(setNavigationVisibility(true));
    };
  }, []);

  const { data } = useGetOpeningsCountForCategoryQuery({
    variables: {
      categories: SearchOpeningEntityTagNameEnum.POSITION_LOCATION,
      searchPattern: searchPattern,
    },
  });

  const suggestions = get(data, 'openingCategories', []).slice(0, 20);

  return (
    <div>
      <SearchItem onClick={goToView} />

      {suggestions.map(suggestion => {
        const openingsCount = suggestion.openingsCount;

        return (
          <SuggestionItem
            key={suggestion.entity.id}
            onClick={() => onItemClick(suggestion.entity)}
            subtitle={`${openingsCount} ${pluralize('opening', openingsCount)}`}
            title={suggestion.entity.longName}
          />
        );
      })}
    </div>
  );
}

SuggestionsList.propTypes = {
  goToView: PropTypes.func.isRequired,
  onItemClick: PropTypes.func.isRequired,
  searchPattern: PropTypes.string,
};
