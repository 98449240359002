import React, { FC, useCallback, useState } from 'react';
import { debounce } from 'lodash';
import { AppBarSearchInput } from '../AppBarSearchInput';
import { AppBarSearchInputWithTitleProps } from './props';
import './styles.scss';

export const AppBarSearchInputWithTitle: FC<AppBarSearchInputWithTitleProps> = ({
  title,
  onSearch,
  defaultSearchPattern,
}) => {
  const [isSearchActive, setIsSearchActive] = useState(!!defaultSearchPattern);

  const [searchValue, setSearchValue] = useState(defaultSearchPattern || '');

  const handleSearch = useCallback(
    debounce(searchPattern => {
      onSearch(searchPattern);
    }, 350),
    [],
  );

  const onChange = (value: string) => {
    setSearchValue(value);
    handleSearch(value);
  };

  return (
    <div className="app-bar-search-input-title">
      {!isSearchActive && <span>{title}</span>}

      <AppBarSearchInput
        isActive={isSearchActive}
        onChange={onChange}
        setIsActive={isActive => setIsSearchActive(isActive)}
        value={searchValue}
      />
    </div>
  );
};
