import React, { FC, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { ARTICLE_ROUTE } from '@src/constants/routes';
import { useArticleQuery } from '@src/graphql/generated';
import { SubPageAppBar } from '@src/components/PrimaryAppBar';
import { Markdown } from '@src/components/Markdown';
import FadeInImage from '@src/components/FadeInImage';

import { formatDateLong } from '@src/util/formatDateLong';
import { readingDurationInMinutes } from '@src/util/readingDurationInMinutes';
import { trackEvent } from '@src/metrics';
import { Context } from '@src/metrics/enums/context.enum';
import { Interaction } from '@src/metrics/enums/interaction.enum';
import { ItemType } from '@src/metrics/enums/itemType.enum';
import { JobOpenings } from './JobOpenings';
import './styles.scss';

export const Article: FC = () => {
  const history = useHistory();

  const match = useRouteMatch<{ articleId?: string }>({
    path: ARTICLE_ROUTE,
    sensitive: true,
    strict: true,
  });

  const articleId = match?.params.articleId;

  useEffect(() => {
    trackEvent({
      context: Context.ARTICLE,
      interaction: Interaction.VIEW,
      itemType: ItemType.ARTICLE,
      itemValue: articleId,
    });
  }, [articleId]);

  const { data: articleData } = useArticleQuery({
    variables: {
      articleId: articleId,
    },
  });

  if (!articleData) {
    return null;
  }

  const { article } = articleData;

  return (
    <div className="article-page-view">
      <SubPageAppBar isDefaultActionsHidden isFixed onBack={history.goBack}>
        <h3 className="article__header-title">Article</h3>
      </SubPageAppBar>

      <div className="article-page-view__content">
        <h1 className="article-page-view__title">{article.title}</h1>

        <p className="article-page-view__date-info">
          {readingDurationInMinutes(article.text)} min read • {formatDateLong(article.publishedAt || article.createdAt)}
        </p>

        {article.headerImage && (
          <div className="article-page-view__image-wrapper">
            <FadeInImage className="article-page-view__image" src={article.headerImage.fullUrl} />
          </div>
        )}

        <Markdown markdown={article.text} />

        {article.companies && <JobOpenings companies={article.companies} />}
      </div>
    </div>
  );
};
