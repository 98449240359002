export const SPECIALITY_MAPPING: Record<string, string[]> = {
  '5995b157871fb3a5190aa2fe': [
    '5995b157871fb3a5190aa2fe',
    '5995b157871fb3a5190aa301',
    '5995b157871fb3a5190aa2ff',
    '5995b157871fb3a5190aa300',
    '59c172c27fd8f1c6796b5526',
  ],
  '5995b157871fb3a5190aa2ff': [
    '5995b157871fb3a5190aa2ff',
    '5995b157871fb3a5190aa2fe',
    '5995b157871fb3a5190aa301',
    '59c172c27fd8f1c6796b5526',
  ],
  '5995b157871fb3a5190aa300': [
    '5995b157871fb3a5190aa300',
    '5995b157871fb3a5190aa2fe',
    '59c172c27fd8f1c6796b5526',
    '5995b157871fb3a5190aa303',
  ],
  '5995b157871fb3a5190aa301': [
    '5995b157871fb3a5190aa301',
    '5995b157871fb3a5190aa2fe',
    '5995b157871fb3a5190aa2ff',
    '59c172c27fd8f1c6796b5526',
  ],
  '5995b157871fb3a5190aa302': [
    '5995b157871fb3a5190aa302',
    '5995b157871fb3a5190aa303',
    '5995b157871fb3a5190aa307',
    '59b8dae0d4841f1d116392cb',
  ],
  '5995b157871fb3a5190aa303': [
    '5995b157871fb3a5190aa303',
    '5995b157871fb3a5190aa300',
    '5995b157871fb3a5190aa302',
    '5995b157871fb3a5190aa307',
    '59b8dae0d4841f1d116392cb',
  ],
  '5995b157871fb3a5190aa304': [
    '5995b157871fb3a5190aa304',
    '5995b157871fb3a5190aa302',
    '5995b157871fb3a5190aa306',
    '5de2de27b8d1dc6e8a876da3',
  ],
  '5995b157871fb3a5190aa306': [
    '5995b157871fb3a5190aa306',
    '5995b157871fb3a5190aa304',
    '59b8dae0d4841f1d116392cb',
    '5de2de27b8d1dc6e8a876da3',
  ],
  '5995b157871fb3a5190aa307': [
    '5995b157871fb3a5190aa307',
    '5995b157871fb3a5190aa303',
    '59b8dae0d4841f1d116392cb',
    '5995b157871fb3a5190aa2fe',
    '5de2de27b8d1dc6e8a876da3',
  ],
  '59b8dae0d4841f1d116392cb': [
    '59b8dae0d4841f1d116392cb',
    '5995b157871fb3a5190aa303',
    '5995b157871fb3a5190aa307',
    '5de2de27b8d1dc6e8a876da2',
  ],
  '59c172c27fd8f1c6796b5526': [
    '59c172c27fd8f1c6796b5526',
    '5995b157871fb3a5190aa301',
    '5995b157871fb3a5190aa2ff',
    '5995b157871fb3a5190aa307',
    '5de2de27b8d1dc6e8a876da3',
  ],
  '5de2de27b8d1dc6e8a876da2': [
    '5de2de27b8d1dc6e8a876da2',
    '5995b157871fb3a5190aa303',
    '59b8dae0d4841f1d116392cb',
    '5de2de28b8d1dc6e8a876da5',
  ],
  '5de2de27b8d1dc6e8a876da3': [
    '5de2de27b8d1dc6e8a876da3',
    '59c172c27fd8f1c6796b5526',
    '5995b157871fb3a5190aa302',
    '5995b157871fb3a5190aa307',
  ],
  '5de2de27b8d1dc6e8a876da4': [
    '5de2de27b8d1dc6e8a876da4',
    '5de2de28b8d1dc6e8a876da5',
    '5de2de29b8d1dc6e8a876da7',
    '5de2de2ab8d1dc6e8a876da8',
  ],
  '5de2de28b8d1dc6e8a876da5': [
    '5de2de27b8d1dc6e8a876da4',
    '5de2de28b8d1dc6e8a876da5',
    '5de2de29b8d1dc6e8a876da7',
    '5de2de2ab8d1dc6e8a876da8',
  ],
  '5de2de28b8d1dc6e8a876da6': [
    '5de2de28b8d1dc6e8a876da6',
    '5995b157871fb3a5190aa2fe',
    '5995b157871fb3a5190aa301',
    '5995b157871fb3a5190aa2ff',
    '59c172c27fd8f1c6796b5526',
  ],
  '5de2de29b8d1dc6e8a876da7': [
    '5de2de29b8d1dc6e8a876da7',
    '5de2de27b8d1dc6e8a876da4',
    '5de2de28b8d1dc6e8a876da5',
    '5de2de2ab8d1dc6e8a876da8',
  ],
  '5de2de2ab8d1dc6e8a876da8': [
    '5de2de2ab8d1dc6e8a876da8',
    '59b8dae0d4841f1d116392cb',
    '5de2de28b8d1dc6e8a876da5',
    '5de2de29b8d1dc6e8a876da7',
  ],
  '5de2de2ab8d1dc6e8a876da9': [
    '5de2de2ab8d1dc6e8a876da9',
    '5de2de28b8d1dc6e8a876da6',
    '5de2de29b8d1dc6e8a876da7',
    '5de2de2ab8d1dc6e8a876da8',
  ],
};
