import { useLayoutEffect } from 'react';
import { get, throttle, size } from 'lodash';
import { NetworkStatus } from '@apollo/client';
import { OPENING_STATE } from '@src/constants';
import { getWrapperScrollableContainer } from '@src/App';
import { trackEvent } from '@src/metrics';
import { recordSignals } from '@src/graphql/signals/actions/recordSignals';
import {
  OpeningRemoteTypeEnum,
  OpeningSearchEntitySearchParamsInput,
  SearchOpeningEntityTagNameEnum,
  SignalEntityTypeEnum,
  SignalTypeEnum,
  useSimilarOffersQuery,
} from '@src/graphql/generated';
import { Context } from '@src/metrics/enums/context.enum';
import { Interaction } from '@src/metrics/enums/interaction.enum';
import { ItemType } from '@src/metrics/enums/itemType.enum';
import { isOpeningType } from '@src/graphql/typeGuards';
import { UseSimilarOffers } from './interfaces/useSimilarOffers';
import { GetFeed } from './interfaces/getFeed.interface';
import { GetPagination } from './interfaces/getPagination.interface';

const FIRST_BATCH = 10;
const NEXT_BATCH = 10;

const getFeed: GetFeed = data => get(data, 'searchOpenings.result') || [];
const getPagination: GetPagination = data => get(data, 'searchOpenings.pagination') || {};

const getScrollableContainer = () => {
  const wrapperScrollableContainer = getWrapperScrollableContainer();

  return wrapperScrollableContainer[0];
};

export const useSimilarOffers: UseSimilarOffers = ({ searchPattern, targetCountryId }) => {
  const searchTagsWithAndCondition = [{ tag: SearchOpeningEntityTagNameEnum.STATE, value: OPENING_STATE.ACTIVE }];

  const searchTagsWithOrCondition: OpeningSearchEntitySearchParamsInput['groupSearchTagsWithOrCondition'] = [
    { tag: SearchOpeningEntityTagNameEnum.REMOTE_TYPE, value: OpeningRemoteTypeEnum.FULLY },
  ];

  if (targetCountryId) {
    searchTagsWithOrCondition.push({
      tag: SearchOpeningEntityTagNameEnum.POSITION_COUNTRY,
      value: targetCountryId,
    });
  }

  const {
    data,
    fetchMore,
    loading: isLoading,
    networkStatus,
  } = useSimilarOffersQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      paginationOptions: {
        limit: FIRST_BATCH,
      },
      searchParams: {
        searchPattern: searchPattern,
        searchTagsWithAndCondition: searchTagsWithAndCondition,
        searchTagsWithOrCondition: searchTagsWithOrCondition,
      },
    },
  });

  const isFeedLoading = isLoading || networkStatus === NetworkStatus.setVariables;
  const feed = getFeed(data);
  const pagination = getPagination(data);

  const loadMore = async () => {
    const { data: fetchMoreResult } = await fetchMore({
      variables: {
        paginationOptions: {
          limit: NEXT_BATCH,
          minCursor: pagination?.maxCursor,
        },
      },
    });

    const result = [...feed, ...fetchMoreResult.searchOpenings.result];

    recordSignals({
      currentLimit: pagination?.limit,
      items: feed.filter(isOpeningType).map(opening => ({
        id: opening.id,
        type: SignalEntityTypeEnum.OPENING,
      })),
      signalType: SignalTypeEnum.VIEW_FEED_ITEM,
    });

    trackEvent({
      context: Context.OPENING,
      interaction: Interaction.FETCH_MORE,
      itemType: ItemType.SIMILAR_OFFERS_FEED,
      itemValue: size(result),
    });
  };

  const onScroll = throttle(({ target: { scrollTop, scrollHeight } }) => {
    const fetchMoreMargin = scrollTop + window.innerHeight * 3;

    if (!isFeedLoading && pagination && pagination.hasMoreResults && fetchMoreMargin > scrollHeight) {
      loadMore();
    }
  }, 250);

  useLayoutEffect(() => {
    getScrollableContainer().addEventListener('scroll', onScroll);

    return () => {
      getScrollableContainer().removeEventListener('scroll', onScroll);
      onScroll.cancel();
    };
  }, [isFeedLoading, pagination]);

  return {
    feed: feed,
    isLoading: isFeedLoading,
  };
};
