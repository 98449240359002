import React from 'react';
import classnames from 'classnames';
import Svg from 'svg-inline-react';
import PropTypes from 'prop-types';
import './styles.scss';

export const SectionTitle = ({
  hasTopDivider,
  subTitle,
  children,
  wrapperClassName,
  iconSrc,
  iconBackgroundColor,
  titleClassName,
}) => (
  <div
    className={classnames(
      'opening-page-section-title',
      hasTopDivider && 'opening-page-section-title--border-top',
      iconBackgroundColor && `opening-page-section-title--icon-bg-color-${iconBackgroundColor}`,
      wrapperClassName,
    )}
  >
    <div className="opening-page-section-title__top">
      {!!iconSrc && <Svg src={iconSrc} />}
      <p className={classnames('opening-page-section-title__title', titleClassName)}>{children}</p>
    </div>
    {!!subTitle && <span>{subTitle}</span>}
  </div>
);

SectionTitle.propTypes = {
  children: PropTypes.string.isRequired,
  hasTopDivider: PropTypes.bool,
  iconBackgroundColor: PropTypes.oneOf(['sp2', 'pink', 'yellow']),
  iconSrc: PropTypes.string,
  subTitle: PropTypes.string,
  titleClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
};
