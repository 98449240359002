import React, { FC, Fragment } from 'react';
import PropTypes from 'prop-types';
import Svg from 'svg-inline-react';
import { Link } from 'react-router-dom';
import FrOpeningSvg from 'assets/images/fr-opening.svg';
import FrPageSvg from 'assets/images/fr-page.svg';
import FrForwardSvg from 'assets/images/fr-forward.svg';
import { COMPANY_ABOUT_ROUTE, COMPANY_JOB_OPENINGS_ROUTE } from '@src/constants/routes';
import createLink from '@src/util/createLink';
import { trackEvent } from '@src/metrics';
import { Context } from '@src/metrics/enums/context.enum';
import { ItemType } from '@src/metrics/enums/itemType.enum';
import { Interaction } from '@src/metrics/enums/interaction.enum';
import { JobOpeningGatewaysProps } from './props';
import './styles.scss';

export const JobOpeningGateways: FC<JobOpeningGatewaysProps> = ({ openingId, companyId }) => {
  const createCompanyLink = (link: string): string => {
    return createLink({
      link: link,
      params: { companyId },
    });
  };

  const metricsEvent = (interaction: Interaction) =>
    trackEvent({
      context: Context.OPENING,
      interaction: interaction,
      itemType: ItemType.OPENING,
      itemValue: openingId,
    });

  const gateways = [
    {
      label: 'Job openings',
      onClick: () => metricsEvent(Interaction.CLICK_JOB_OPENINGS),
      src: FrOpeningSvg,
      to: createCompanyLink(COMPANY_JOB_OPENINGS_ROUTE),
    },
    {
      label: 'View profile',
      onClick: () => metricsEvent(Interaction.CLICK_COMPANY_PROFILE),
      src: FrPageSvg,
      to: createCompanyLink(COMPANY_ABOUT_ROUTE),
    },
  ];

  return (
    <div className="job-opening-gateways">
      {gateways.map(({ label, src, to, onClick }, i) => {
        const content = (
          <Fragment>
            <div className="job-opening-gateways__item-title">
              <Svg src={src} />
              <span>{label}</span>
            </div>
            <Svg src={FrForwardSvg} />
          </Fragment>
        );

        return to ? (
          <Link className="job-opening-gateways__item" key={i} onClick={onClick} to={to}>
            {content}
          </Link>
        ) : (
          <div className="job-opening-gateways__item" key={i} onClick={onClick}>
            {content}
          </div>
        );
      })}
    </div>
  );
};

JobOpeningGateways.propTypes = {
  companyId: PropTypes.string.isRequired,
  openingId: PropTypes.string.isRequired,
};
