import { reduce } from 'lodash';

export const transformQueryParamTypes = params => {
  return reduce(
    params,
    (result, value, key) => {
      result[key] = transformType(value);

      return result;
    },
    {},
  );
};

function transformType(value) {
  if (value === 'true') {
    return true;
  } else if (value === 'false') {
    return false;
  }

  return value;
}
