import React, {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FlashMessage } from '@src/components/FlashMessage';
import { FlashMessageType } from '@src/components/FlashMessage/enums/flashMessageType.enum';
import {
  FlashMessageFields,
  FlashMessagesContextFields,
} from './interfaces/flashMessageContext.interface';
import { FlashMessagesProviderProps } from './props';
import './styles.scss';

const FlashMessagesContext = createContext<FlashMessagesContextFields>({
  flashMessages: [],
  removeFlashMessage: () => null,
  setErrorFlashMessage: () => null,
  setFlashMessage: () => null,
  setSuccessFlashMessage: () => null,
});

export const FlashMessagesProvider: FC<FlashMessagesProviderProps> = ({
  children,
}) => {
  const [flashMessages, setFlashMessages] = useState<FlashMessageFields[]>([]);

  const setFlashMessage = useCallback(
    (message: FlashMessageFields) => {
      setFlashMessages((prevMessages) => [
        ...prevMessages,
        { ...message, id: Math.random() },
      ]);
    },
    [setFlashMessages],
  );

  const setErrorFlashMessage = useCallback((message?: string) => {
    setFlashMessage({
      title: message || 'Something went wrong',
      type: FlashMessageType.ERROR,
    });
  }, []);

  const setSuccessFlashMessage = useCallback((message: string) => {
    setFlashMessage({
      title: message,
      type: FlashMessageType.SUCCESS,
    });
  }, []);

  const removeFlashMessage = useCallback(
    (id: number) => {
      setFlashMessages((prevMessages) =>
        prevMessages.filter((message) => message.id !== id),
      );
    },
    [setFlashMessages],
  );

  const value = useMemo<FlashMessagesContextFields>(
    () => ({
      flashMessages,
      removeFlashMessage,
      setErrorFlashMessage,
      setFlashMessage,
      setSuccessFlashMessage,
    }),
    [
      flashMessages,
      setFlashMessage,
      removeFlashMessage,
      setErrorFlashMessage,
      setSuccessFlashMessage,
    ],
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setFlashMessages((prevMessages) =>
        prevMessages.filter((message) => !!message.shouldDisableAutoHide),
      );
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [flashMessages.length]);

  return (
    <FlashMessagesContext.Provider value={ value }>
      { children }

      <div className="flash-messages-container">
        { flashMessages.slice(0, 5).map((flashMessage) => {
          return (
            <FlashMessage
              close={ () =>
                flashMessage.id && removeFlashMessage(flashMessage.id)
              }
              key={ flashMessage.id }
              link={ flashMessage.link }
              linkLabel={ flashMessage.linkLabel }
              message={ flashMessage.message }
              title={ flashMessage.title }
              to={ flashMessage.to }
              type={ flashMessage.type }
            />
          );
        }) }
      </div>
    </FlashMessagesContext.Provider>
  );
};

export function useFlashMessagesContext (): FlashMessagesContextFields {
  return useContext(FlashMessagesContext);
}

export default FlashMessagesContext;
