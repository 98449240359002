import _ from 'lodash';
import 'whatwg-fetch';
import fetchDefaults from 'fetch-defaults';
import fetchJsonify from 'fetch-jsonify';
import fetchThrow from 'fetch-throw';
import FetchError from 'fetch-error';
import { push } from 'connected-react-router';
import store from '@src/store';
import { INDEX_ROUTE } from '@src/constants/routes';

const _fetch = fetchDefaults(fetch, {
  credentials: 'include',
  headers: {
    Accept: 'application/json',
    AppVersion: '2021102201',
  },
});

export default function api(url, _opts = {}) {
  const { delay, ...opts } = _opts;

  return Promise.all([
    _.flow([fetchJsonify, fetchThrow])(_fetch)(url, opts)
      .then(res => {
        if (isJsonContent(res)) {
          return res.json();
        }

        return undefined;
      })
      .catch(error => {
        if (error instanceof FetchError && error.response && isJsonContent(error.response)) {
          return error.response.json().then(res => {
            // eslint-disable-next-line no-throw-literal
            throw { body: res, code: error.code };
          });
        } else {
          throw error;
        }
      }),
    new Promise(resolve => setTimeout(resolve, delay)),
  ])
    .then(([data]) => data)
    .catch(error => {
      if (error.code === 401) {
        if (!window.isPublicApp) {
          // 401 = unauthorized
          store.dispatch(push(INDEX_ROUTE));
        }
      } else if (error.code === 419 && error.body.error === 'needs_refresh') {
        // 419 needs_refresh - JS is outdated, perform forced refresh
        window.location = `/app?deviceClass=${document.body.className}`;
      }

      throw error;
    });
}

api.get = (url, json) => api(url, { json, method: 'GET' });
api.post = (url, json) => api(url, { json, method: 'POST' });
api.put = (url, json) => api(url, { json, method: 'PUT' });
api.patch = (url, json) => api(url, { json, method: 'PATCH' });
api.delete = (url, json) => api(url, { json, method: 'DELETE' });

function isJsonContent(res) {
  const contentType = res.headers.get('content-type');

  return contentType && contentType.indexOf('application/json') !== -1;
}
