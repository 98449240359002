import React, { FC } from 'react';
import FrInfoSmall2Svg from 'assets/images/fr-Info-small-2.svg';
import { InvisibleButton } from '@src/components/Button';
import { VERIFIED_USER_ROUTE } from '@src/constants/routes';
import { RoundedIcon } from '../RoundedIcon';
import { Color } from '../RoundedIcon/roundedIcon.enum';
import { Section } from '../Section';
import { DraftStateProps } from './props';
import './styles.scss';

export const DraftState: FC<DraftStateProps> = ({
  doesUserVerificationMessageExist,
  isActionButtonHidden,
  isActionDisabled,
}) => {
  return (
    <div className="vt-draft-state">
      <Section
        bottomSlot={
          <div className="vt-draft-state__title">
            {doesUserVerificationMessageExist ? 'Adjustments needed' : 'Draft'}
          </div>
        }
        iconSlot={<RoundedIcon color={Color.PINK} src={FrInfoSmall2Svg} />}
        topSlot={<div className="vt-draft-state__name">Your profile status</div>}
        wrapperClassName="vt-draft-state__wrapper"
      />
      {!isActionButtonHidden ? (
        <InvisibleButton className="vt-draft-state__button" disabled={isActionDisabled} isLink to={VERIFIED_USER_ROUTE}>
          Continue
        </InvisibleButton>
      ) : null}
    </div>
  );
};
