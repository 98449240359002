import React, { FC, useEffect, useState } from 'react';
import { MessageHeader } from '@src/components/MessageHeader';
import ChatBubble from '@src/components/ChatBubble';
import ChatInput from '@src/components/ChatInput';
import { BlueButton } from '@src/components/Button';
import { SubscribedData } from './interfaces/subscribedData.interface';
import './styles.scss';

export const PitchPreview: FC = () => {
  const [{ recruiter, opening }, setState] = useState<SubscribedData>({
    recruiter: undefined,
  });

  useEffect(() => {
    window.parent?.dataSubject$.subscribe(({ recruiter, opening }: SubscribedData) => {
      setState({
        opening: opening,
        recruiter: recruiter,
      });
    });
  }, []);

  if (!recruiter || !opening) {
    return null;
  }

  return (
    <div className="pitch-preview-wrapper">
      <MessageHeader
        avatarProps={{
          avatarColor: recruiter?.avatarColor,
          initials: recruiter?.nameInitials,
          src: recruiter?.avatarUrl,
        }}
        subTitle={recruiter?.title || ''}
        title={recruiter?.fullName || ''}
        wrapperClassName="pitch-preview-wrapper__header"
      />

      <ChatBubble value={opening.pitch || ''} />

      <div className="pitch-preview-wrapper__bottom">
        <ChatInput inputClassName="pitch-preview-wrapper__input" placeholder="Your answer..." />

        <div className="pitch-preview-wrapper__button-wrapper">
          <BlueButton isFilled>Send</BlueButton>
        </div>
      </div>
    </div>
  );
};
