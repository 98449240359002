import { GraphQLRequest } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { IpDetailsDocument, RequestAuthCodeDocument, VerifyAuthCodeDocument } from '@src/graphql/generated';

const PUBLIC_OPERATIONS = [RequestAuthCodeDocument, VerifyAuthCodeDocument, IpDetailsDocument].map(operation =>
  getOperationName(operation),
);

export function doesRequireAuth(request: GraphQLRequest<Record<string, any>>): boolean {
  if (!request.operationName) {
    return false;
  }

  return !PUBLIC_OPERATIONS.includes(request.operationName);
}
