import { removeTrailingCharacter } from './string';
import { normalizeUrl } from './url';

// Valid LinkedIn profile url spec:
// https://www.linkedin.com/help/linkedin/answer/49315/finding-your-linkedin-public-profile-url?lang=en
export const LINKEDIN_VALID_PROFILE_URL_REGEX =
  /^(http(s)?:\/\/)?([a-zA-Z.]+)?[Ll]inked[Ii]n.com\/(mwlite\/|m\/)?in\/([^/]+)\/?/;

export const GITHUB_VALID_PROFILE_URL_REGEX = /^(http(s?):\/\/)?(www\.)?github\.([a-z])+\/([A-Za-z0-9]{1,})+\/?$/i;
export const GITLAB_VALID_PROFILE_URL_REGEX = /^(http(s?):\/\/)?(www\.)?gitlab\.com\/([A-Za-z0-9]{1,})+\/?$/i;
export const BITBUCKET_VALID_PROFILE_URL_REGEX = /^(http(s?):\/\/)?(www\.)?bitbucket\.org\/([A-Za-z0-9]{1,})+\/?$/i;
export const BEHANCE_VALID_PROFILE_URL_REGEX = /^(http(s?):\/\/)?(www\.)?behance\.([a-z])+\/([A-Za-z0-9-]{1,})+\/?$/i;
export const DRIBBBLE_VALID_PROFILE_URL_REGEX = /^(http(s?):\/\/)?(www\.)?dribbble\.([a-z])+\/([A-Za-z0-9-]{1,})+\/?$/i;

export const isValidLinkedInProfileUrl = (url: string): boolean => {
  return LINKEDIN_VALID_PROFILE_URL_REGEX.test(url.trim());
};

export const isValidGithubProfileUrl = (url: string): boolean => {
  return GITHUB_VALID_PROFILE_URL_REGEX.test(url.trim());
};

export const isValidGitlabProfileUrl = (url: string): boolean => {
  return GITLAB_VALID_PROFILE_URL_REGEX.test(url.trim());
};

export const isValidBitbucketProfileUrl = (url: string): boolean => {
  return BITBUCKET_VALID_PROFILE_URL_REGEX.test(url.trim());
};

export const isValidBehanceProfileUrl = (url: string): boolean => {
  return BEHANCE_VALID_PROFILE_URL_REGEX.test(url.trim());
};

export const isValidDribbbleProfileUrl = (url: string): boolean => {
  return DRIBBBLE_VALID_PROFILE_URL_REGEX.test(url.trim());
};

export function getLinkedInUserProfileNameFromUrl(url: string): string | undefined {
  const userProfileName = normalizeUrl(url.trim(), {
    removeQueryParameters: true,
    removeTrailingSlash: true,
    stripHash: true,
  })
    ?.trim()
    ?.split(/\s|\r|\t/)
    ?.map(input => {
      if (isValidLinkedInProfileUrl(input)) {
        const inputFragments = removeTrailingCharacter(input, '/').split('/');
        const lastFragment = inputFragments[inputFragments.length - 1];

        if (!lastFragment.startsWith('?')) {
          return lastFragment;
        }
      }

      return undefined;
    })
    .find(value => value);

  return userProfileName;
}
