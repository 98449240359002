import normalizeUrlUtil from 'normalize-url';
import { escapeRegExp } from 'lodash';
import { isValidEmail, normalizeEmail } from '../email';
import { getDomainFromUrl } from '../domain';
import { NormalizeUrlOptionParams } from './interfaces/normalizeUrlOption.params';

const DEFAULT_NORMALIZE_URL_OPTIONS: NormalizeUrlOptionParams = {
  forceHttps: true,
  normalizeProtocol: true,
  removeSingleSlash: true,
  removeTrailingSlash: true,
  sortQueryParameters: true,
  stripAuthentication: true,
  stripHash: false,
  stripProtocol: false,
};

export function toValidUrl(url: string): string {
  if (url && /^(https?:\/\/)/.test(url.trim())) {
    return url;
  }

  return `https://${url}`;
}

export function toExternalUrl(url: string): string {
  if (!url || /^(mailto|tel):/.test(url.trim())) {
    return url;
  }

  const validUrl = toValidUrl(url);

  if (validUrl.match('utm_source=meetfrank')) {
    return validUrl;
  }

  try {
    const externalUrl = new URL(validUrl);
    externalUrl.searchParams.append('utm_source', 'meetfrank');
    externalUrl.searchParams.append('utm_medium', 'app');

    return externalUrl.href;
  } catch (e) {
    return url;
  }
}

export function isAbsoluteUrl(url: string): boolean {
  const isHttpUrl = url.startsWith('http://') || url.startsWith('https://');
  const hasDomain = !isValidEmail(url) && getDomainFromUrl(url) !== undefined;

  return isHttpUrl || hasDomain;
}

export function isRelativeUrl(url: string): boolean {
  return !isAbsoluteUrl(url) && !isValidEmail(url);
}

export function normalizeUrl(url: string, options?: NormalizeUrlOptionParams): string | undefined {
  if (isValidEmail(url)) {
    return normalizeEmail(url);
  }

  if (isAbsoluteUrl(url)) {
    return decodeURI(normalizeUrlUtil(url, { ...DEFAULT_NORMALIZE_URL_OPTIONS, ...options }));
  }

  if (!options?.baseDomain) {
    return undefined;
  }

  if (isRelativeUrl(url)) {
    const normalizedBaseDomain = options?.baseDomain ? getDomainFromUrl(options?.baseDomain) : undefined;

    if (!normalizedBaseDomain) {
      return undefined;
    }

    if (!url.startsWith('/')) {
      const relativePathRegex = new RegExp(`^(https?:\/\/)?${escapeRegExp(normalizedBaseDomain)}\/?`, 'i');
      const relativePath = options?.baseDomain?.replace(relativePathRegex, '');
      const fullUrl = `${normalizedBaseDomain}/${relativePath}/${url}`;

      return decodeURI(normalizeUrlUtil(fullUrl, { ...DEFAULT_NORMALIZE_URL_OPTIONS, ...options }));
    }

    const fullUrl = `${normalizedBaseDomain}${url.replace(/\/$/, '')}`;

    return decodeURI(normalizeUrlUtil(fullUrl, { ...DEFAULT_NORMALIZE_URL_OPTIONS, ...options }));
  }

  return undefined;
}
