import React, { FC } from 'react';
import { first } from 'lodash';
import { Avatar } from '@src/components/Avatar';
import { Section } from '../Section';
import { Props } from './props';
import './styles.scss';

export const UserInfo: FC<Props> = ({ name, title, avatarColor, avatarUrl }) => {
  return (
    <Section
      bottomSlot={<div className="vt-user-info__title">{title}</div>}
      iconSlot={<Avatar avatarColor={avatarColor} initials={first(name)} size="x-large" src={avatarUrl} />}
      topSlot={<div className="vt-user-info__name">{name}</div>}
      wrapperClassName="vt-user-info"
    />
  );
};
