import { AreaType, CountryType, LocationType } from '@src/graphql/generated';
import { hasFields } from '../object/helpers';

export function isAreaInterface(obj: unknown): obj is AreaType {
  return hasFields<AreaType>(obj, ['id', 'longName', '__typename']) && obj.__typename === 'AreaType';
}

export function isCountryInterface(obj: unknown): obj is CountryType {
  return hasFields<CountryType>(obj, ['id', 'longName', 'shortName', '__typename']) && obj.__typename === 'CountryType';
}

export function isLocationInterface(obj: unknown): obj is LocationType {
  return (
    hasFields<LocationType>(obj, ['id', 'country', 'shortName', '__typename']) && obj.__typename === 'LocationType'
  );
}
