import React, { FC, ReactNode, useMemo } from 'react';
import { Link } from 'react-router-dom';
import Svg from 'svg-inline-react';
import classNames from 'classnames';
import FrInfoSvg from '@assets/images/fr-info.svg';
import FrCheckSvg from '@assets/images/fr-check.svg';
import FrExclamationSvg from '@assets/images/fr-exclamation.svg';
import CloseSvg from '@assets/images/fr-close.svg';
import { FlashMessageType } from './enums/flashMessageType.enum';
import { FlashMessageProps } from './props';
import './styles.scss';

export const FlashMessage: FC<FlashMessageProps> = ({ title, message, type, link, linkLabel, close, to }) => {
  const icon = useMemo<ReactNode>(() => {
    switch (type) {
      case FlashMessageType.SUCCESS:
        return FrCheckSvg;
      case FlashMessageType.INFO:
        return FrInfoSvg;
      case FlashMessageType.ERROR:
      case FlashMessageType.WARNING:
        return FrExclamationSvg;
      default:
        return null;
    }
  }, [type]);

  return (
    <div className="flash-message">
      {icon && <Svg className={classNames('flash-message__icon', `flash-message__icon--${type}`)} src={icon} />}

      <div className="flash-message__content">
        <div className="flash-message__title">{title}</div>

        {message && <div className="flash-message__message">{message}</div>}

        {linkLabel && !to && (
          <a
            className="fr-flash-message__link"
            href={link}
            onClick={() => linkLabel && close?.()}
            target="_blank"
            rel="noreferrer"
          >
            {linkLabel || link}
          </a>
        )}

        {to && linkLabel && (
          <Link className="fr-flash-message__link" to={to}>
            {linkLabel}
          </Link>
        )}
      </div>

      <Svg className="flash-message__close" onClick={close} src={CloseSvg} />
    </div>
  );
};
