import React from 'react';
import PropTypes from 'prop-types';
import Svg from 'svg-inline-react';
import ArrowSvg from 'assets/images/fr-chevron-left.svg';
import './styles.scss';

export function SuggestionItem({ onClick, title, subtitle }) {
  return (
    <div className="no-result-suggestion-item" onClick={onClick}>
      <div>
        <div className="no-result-suggestion-item__title">{title}</div>
        <div className="no-result-suggestion-item__subtitle">{subtitle}</div>
      </div>

      <Svg src={ArrowSvg} />
    </div>
  );
}

SuggestionItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
