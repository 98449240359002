import React, { FC } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { Pagination } from 'swiper';
import classNames from 'classnames';
import FadeInImage from '@src/components/FadeInImage';
import { ImageSwiperProps } from './props';
import './styles.scss';

export const ImageSwiper: FC<ImageSwiperProps> = ({
  isLoading,
  wrapperClassName,
  images,
  hasPagination = true,
  imageClassName,
}) => {
  if (isLoading) {
    return <div className="base-image-swiper--loading pulse-fade" />;
  }

  const shouldShowPagination = images.length > 1 && hasPagination;

  return (
    <div className={classNames('base-image-swiper', wrapperClassName)}>
      <Swiper
        className="fr-swiper"
        allowTouchMove={shouldShowPagination}
        loop={shouldShowPagination}
        modules={[Pagination]}
        pagination={{
          bulletActiveClass: 'fr-swiper__bullet--active',
          bulletClass: 'fr-swiper__bullet',
          el: '.fr-swiper__pagination',
        }}
      >
        {images.map(image => (
          <SwiperSlide key={image.id}>
            <div className="base-image-swiper__image-wrapper">
              <FadeInImage className={classNames('base-image-swiper__image', imageClassName)} src={image.src} />
            </div>
          </SwiperSlide>
        ))}

        {shouldShowPagination && <div className="test fr-swiper__pagination" />}
      </Swiper>
    </div>
  );
};
