import React, { FC, MouseEvent, useEffect, useRef, useState } from 'react';
import Svg from 'svg-inline-react';
import FrChevronRight from 'assets/images/fr-chevron-right.svg';
import classnames from 'classnames';
import { replaceMessageStringVariables } from '@shared/utils/replaceMessageStringVariables';
import { useHistory } from 'react-router-dom';
import { CompanyHighLights } from '@src/components/CompanyHighLights';
import { MessageHeader } from '@src/components/MessageHeader';
import { Markdown } from '@src/components/Markdown';
import { getLineCount } from '@src/util/getLineCount';
import { JOB_OPENING_ROUTE } from '@src/constants/routes';
import createLink from '@src/util/createLink';
import { CardProps } from './props';
import './styles.scss';

export const Card: FC<CardProps> = ({
  companyName,
  companyDescription,
  countryFlagUrl,
  employeesAmount,
  financialDetails,
  avatarColor,
  nameInitials,
  avatarUrl,
  recruiterName,
  headerSubtitle,
  badgeUrl,
  message,
  date,
  opening,
  userName,
  onClick,
}) => {
  const history = useHistory();

  const messageRef = useRef<HTMLDivElement>(null);

  const aboutTextRef = useRef<HTMLDivElement>(null);

  const [isReadMoreShown, setIsReadMoreShown] = useState(false);

  const [isAboutTextLong, setIsAboutTextLong] = useState(false);

  useEffect(() => {
    if (messageRef.current && getLineCount(messageRef.current) > 5) {
      setIsReadMoreShown(true);
    }

    if (aboutTextRef.current && getLineCount(aboutTextRef.current) > 4) {
      setIsAboutTextLong(true);
    }
  }, []);

  const onOpeningLinkClick = (event: MouseEvent): void => {
    event.stopPropagation();

    if (!opening?.id) {
      return;
    }

    history.push(
      createLink({
        link: JOB_OPENING_ROUTE,
        params: { openingId: opening?.id },
      }),
    );
  };

  return (
    <div className="frank-message-request-card" onClick={() => onClick()}>
      <div className="frank-message-request-card__header">
        <MessageHeader
          avatarProps={{
            avatarColor: avatarColor,
            initials: nameInitials,
            src: avatarUrl,
          }}
          fadeInImageProps={
            badgeUrl && {
              src: badgeUrl,
            }
          }
          subTitle={headerSubtitle}
          title={recruiterName}
        />

        <div className="frank-message-request-card__date">{date}</div>
      </div>

      <div className="frank-message-request-card__message">
        <Markdown
          markdown={replaceMessageStringVariables(message, {
            name: userName || '',
            title: opening?.title || '',
          })}
          ref={messageRef}
        />
        {isReadMoreShown && <span className="frank-message-request-card__read-more">Read More</span>}
      </div>

      {opening ? (
        <div className="frank-message-request-card__opening-link" onClick={onOpeningLinkClick}>
          <span>{opening.title}</span>
          <Svg src={FrChevronRight} />
        </div>
      ) : null}

      <div className="frank-message-request-card__body">
        <div className="frank-message-request-card__title">About {companyName}</div>

        <div
          className={classnames(
            'frank-message-request-card__text',
            isAboutTextLong && 'frank-message-request-card__text--three-lines',
          )}
        >
          <Markdown markdown={companyDescription} ref={aboutTextRef} />
        </div>

        <div className="frank-message-request-card__details">
          <CompanyHighLights
            countryFlagUrl={countryFlagUrl}
            employeesAmount={employeesAmount}
            financialDetails={financialDetails}
          />
        </div>
      </div>
    </div>
  );
};
