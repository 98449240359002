import React, { FC } from 'react';
import { Rating } from '@src/components/Rating';
import { RatingColor } from '@src/components/Rating/enums/ratingColor.enum';
import { RatingSize } from '@src/components/Rating/enums/ratingSize.enum';
import { HowRateThisCompanyProps } from './props';
import './styles.scss';

export const HowRateThisCompany: FC<HowRateThisCompanyProps> = ({ title, rating, onRatingSelect }) => {
  return (
    <div className="rate-this-company company-reviews__rate-company">
      <p className="rate-this-company__title company-reviews__rate-company-title">{title}</p>
      <Rating
        activeColor={RatingColor.BLUE}
        activeRating={rating}
        maxRating={5}
        setActiveRating={onRatingSelect}
        shouldHaveBackground
        size={RatingSize.LARGE}
      />
      <p className="rate-this-company__subtitle company-reviews__rate-company-subtitle">You can make it anonymous</p>
    </div>
  );
};
