import React, { useRef, Fragment, useEffect, useLayoutEffect, FC } from 'react';
import qs from 'qs';
import { get, throttle } from 'lodash';
import { NetworkStatus } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { Filters } from '@src/containers/Filters';
import { EmptyState } from '@src/components/EmptyState';
import { recordSignals } from '@src/graphql/signals/actions/recordSignals';
import { FeedItemSkeleton } from '@src/containers/Feed/Skeleton';
import { JobOpeningFeedItem } from '@src/containers/Feed/JobOpeningFeedItem';
import { usePrevious, useFeedUpdate } from '@src/customHooks';
import { ALL_SPECIALITIES, ALL_COUNTRIES, ALL_SENIORITIES } from '@src/containers/Filters/constants';
import { trackEvent } from '@src/metrics';
import { transformQueryParamTypes } from '@src/util/transformQueryParamTypes';
import {
  CompanyOpeningsDocument,
  OpeningSearchEntitySortOptionEnum,
  SearchOpeningEntityTagNameEnum,
  SignalEntityTypeEnum,
  SignalTypeEnum,
  useGetCompanySearchLatestOpeningsQuery,
} from '@src/graphql/generated';
import { Context } from '@src/metrics/enums/context.enum';
import { Interaction } from '@src/metrics/enums/interaction.enum';
import { ItemType } from '@src/metrics/enums/itemType.enum';
import { isOpeningType } from '@src/graphql/typeGuards';
import { FilledOpeningsScroller } from './FilledOpeningsScroller';
import { JobOpeningsProps } from './props';
import './styles.scss';

export const JobOpenings: FC<JobOpeningsProps> = ({ companyId, isCompanyLoading, getScrollableContainer }) => {
  const location = useLocation();

  const feedWrapperRef = useRef<HTMLDivElement>(null);

  const wrapperScrollableContainer = getScrollableContainer();

  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const prevSearch = usePrevious(location.search);

  const filters = Object.values(queryParams);
  const filteredTags = filters.filter(
    item => ![ALL_SPECIALITIES.id, ALL_COUNTRIES.id, ALL_SENIORITIES.id].includes(item?.value),
  );

  // filter only the items which have both tag and value to avoid mixing with other query params
  const filteredSearchTags = filteredTags.map(transformQueryParamTypes).filter(item => !!item?.tag && !!item?.value);

  const searchTags = [
    ...filteredSearchTags,
    { tag: SearchOpeningEntityTagNameEnum.STATE, value: 'active' },
    { tag: SearchOpeningEntityTagNameEnum.COMPANY, value: companyId },
  ];

  const { data, fetchMore, networkStatus, refetch } = useGetCompanySearchLatestOpeningsQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      paginationOptions: {
        limit: 6,
        sortBy: OpeningSearchEntitySortOptionEnum.RECENCY,
      },
      searchParams: {
        searchTagsWithAndCondition: searchTags,
      },
    },
  });

  const isFetchMoreLoading = networkStatus === NetworkStatus.fetchMore;
  const isRefetchLoading = networkStatus === NetworkStatus.refetch;
  const isLoading = isFetchMoreLoading || isRefetchLoading;

  const pagination = data?.searchOpenings.pagination;
  const openings = data?.searchOpenings.result || [];
  const isEmpty = !openings?.length;

  const { checkNewContent } = useFeedUpdate(
    CompanyOpeningsDocument,
    { companyId, searchTags },
    'searchOpenings.result',
    refetch,
    wrapperScrollableContainer[0],
  );

  // checks if new content is added, also fires metric event on page load
  useEffect(() => {
    checkNewContent(pagination?.minCursor, openings);

    trackEvent({
      context: Context.COMPANY_PROFILE,
      interaction: Interaction.VIEW_JOB_OPENINGS,
      itemType: ItemType.COMPANY,
      itemValue: companyId,
    });
  }, []);

  // scrolls to top on filter update and handles refetch
  useEffect(() => {
    if (!!prevSearch && location.search !== prevSearch) {
      wrapperScrollableContainer && wrapperScrollableContainer[0].scroll({ left: 0, top: 0 });
      refetch();
    }
  }, [location.search]);

  const loadMore = throttle(async () => {
    const scrollTop = get(wrapperScrollableContainer, '[0].scrollTop');
    const distanceFromBottom =
      feedWrapperRef.current && feedWrapperRef.current.offsetHeight - scrollTop - window.innerHeight;

    if (
      !isFetchMoreLoading &&
      pagination?.hasMoreResults &&
      distanceFromBottom &&
      distanceFromBottom < 2 * window.innerHeight
    ) {
      const { data: fetchMoreResult } = await fetchMore({
        variables: {
          paginationOptions: {
            limit: 6,
            minCursor: pagination.maxCursor,
          },
          searchTags: searchTags,
        },
      });

      recordSignals({
        currentLimit: pagination.limit,
        items: openings.filter(isOpeningType).map(opening => ({
          id: opening.id,
          type: SignalEntityTypeEnum.OPENING,
        })),
        signalType: SignalTypeEnum.VIEW_FEED_ITEM,
      });

      const newResult = fetchMoreResult.searchOpenings.result;

      trackEvent({
        context: Context.COMPANY_PROFILE,
        interaction: Interaction.FETCH_MORE,
        itemType: ItemType.COMPANY_OPENINGS_FEED,
        itemValue: [...openings, ...newResult].length,
      });
    }
  }, 200);

  // handles fetching more data on scroll
  useLayoutEffect(() => {
    wrapperScrollableContainer[0].addEventListener('scroll', loadMore);

    return () => {
      wrapperScrollableContainer[0].removeEventListener('scroll', loadMore);
      loadMore.cancel();
    };
  }, [pagination?.maxCursor, isFetchMoreLoading, isRefetchLoading, networkStatus]);

  return (
    <div className="company-profile-job-openings" ref={feedWrapperRef}>
      <Filters
        filtersToShow={['specialities', 'countries']}
        location={location}
        metricsContext="Company Profile"
        pageRoute={location.pathname}
        routeReplace
        type="label"
        wrapperClassName="company-profile-job-openings__filters"
      />

      <div className="company-profile-job-openings__feed-wrapper feed-bg-color">
        {networkStatus <= 2 || isRefetchLoading || isCompanyLoading ? (
          <FeedItemSkeleton />
        ) : isEmpty ? (
          <EmptyState
            imageUrl={'/images/empty-states/empty-state-1.png'}
            subTitle="This company doesn’t have any openings on MeetFrank at the moment. Check back soon!"
            title="No openings at the moment"
            wrapperClassName="company-profile-job-openings__empty-state"
          />
        ) : (
          <Fragment>
            {openings.filter(isOpeningType).map(opening => {
              return <JobOpeningFeedItem key={opening.id} metricsContext="Company Profile" opening={opening} />;
            })}

            {isFetchMoreLoading && <FeedItemSkeleton />}
          </Fragment>
        )}

        {!isLoading && !pagination?.hasMoreResults && (
          <FilledOpeningsScroller companyId={companyId} searchTags={filteredSearchTags} />
        )}
      </div>
    </div>
  );
};
