import React, { FC, useState } from 'react';
import pluralize from 'pluralize';
import Svg from 'svg-inline-react';
import classnames from 'classnames';
import LikeSvg from 'assets/images/fr-like.svg';
import { CompanyReviewLikeProps } from './props';
import './styles.scss';

export const CompanyReviewLike: FC<CompanyReviewLikeProps> = ({
  hasLike,
  reactionsCount = 0,
  className,
  iconClassName,
  labelClassName,
  onTouchEnd,
  isDisabled,
  isOnlyLikeAmountShown,
}) => {
  const [like, setLike] = useState({
    hasActiveLike: hasLike,
    reactionsCount: reactionsCount,
    shouldJumpUp: false,
  });
  const { hasActiveLike, reactionsCount: currentReactionsCount } = like;

  const getLikesLabel = () => {
    if (isOnlyLikeAmountShown) {
      return currentReactionsCount || '';
    }

    return !currentReactionsCount
      ? 'Helpful'
      : `${currentReactionsCount} ${pluralize('Helpful like', currentReactionsCount)}`;
  };

  return (
    <div
      className={classnames(
        'company-review-like',
        hasActiveLike && 'company-review-like--selected',
        className,
        isDisabled && 'company-review-like--disabled',
      )}
      onClick={() => {
        const count = hasActiveLike ? currentReactionsCount - 1 : currentReactionsCount + 1;
        setLike({
          hasActiveLike: !hasActiveLike,
          reactionsCount: count,
          shouldJumpUp: !hasActiveLike,
        });
        onTouchEnd(hasActiveLike);
      }}
    >
      <Svg className={classnames('company-review-like__icon', iconClassName)} src={LikeSvg} />

      <span className={classnames('company-review-like__label', labelClassName)}>{getLikesLabel()}</span>
    </div>
  );
};
