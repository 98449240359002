import { throttle } from 'lodash';
import { useEffect } from 'react';
import {
  GetRecentlySeenCompaniesDocument,
  GetRecentlySeenCompaniesQuery,
  useGetRecentlySeenCompaniesQuery,
} from '@src/graphql/generated';
import { trackEvent } from '@src/metrics';
import { Context } from '@src/metrics/enums/context.enum';
import { Interaction } from '@src/metrics/enums/interaction.enum';
import { ItemType } from '@src/metrics/enums/itemType.enum';
import { handleFetchMore } from '@src/util/handleFetchMore';
import { getScrollableContainer } from '@src/App';
import { useFeedUpdate } from '@src/customHooks';
import { UseRecentCompanies } from './interfaces/useRecentCompanies.interface';

const FIRST_BATCH_LIMIT = 10;
const NEXT_BATCH_LIMIT = 5;

export const useRecentCompanies = (): UseRecentCompanies => {
  const {
    data: recentlySeenCompaniesData,
    loading: isRecentlySeenCompaniesLoading,
    fetchMore: fetchMoreRecentlySeenCompanies,
    refetch: refetchRecentlySeenCompanies,
  } = useGetRecentlySeenCompaniesQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      paginationOptions: {
        limit: FIRST_BATCH_LIMIT,
      },
    },
  });

  const companies = recentlySeenCompaniesData?.recentlySeenCompanies.result || [];
  const pagination = recentlySeenCompaniesData?.recentlySeenCompanies.pagination;

  const { checkNewContent } = useFeedUpdate(
    GetRecentlySeenCompaniesDocument,
    {},
    'recentlySeenCompanies.result',
    refetchRecentlySeenCompanies,
    getScrollableContainer(),
  );

  useEffect(() => {
    if (pagination?.minCursor) {
      checkNewContent(pagination?.minCursor, companies);
    }
  }, []);

  useEffect(() => {
    const loadMore = throttle(({ target }: Event) => {
      if (!(target instanceof HTMLDivElement)) {
        return;
      }

      const { scrollTop, scrollHeight } = target;

      const fetchMoreMargin = scrollTop + window.innerHeight * 2;

      if (fetchMoreMargin > scrollHeight && !isRecentlySeenCompaniesLoading && pagination?.hasMoreResults) {
        handleFetchMore(fetchMoreRecentlySeenCompanies, {
          updateQuery: (
            previousResult: GetRecentlySeenCompaniesQuery,
            { fetchMoreResult }: { fetchMoreResult: GetRecentlySeenCompaniesQuery },
          ): GetRecentlySeenCompaniesQuery => {
            if (!fetchMoreResult) {
              return previousResult;
            }

            const previousApplicants = previousResult.recentlySeenCompanies;

            const newResult = fetchMoreResult.recentlySeenCompanies.result;
            const pagination = fetchMoreResult.recentlySeenCompanies.pagination;

            trackEvent({
              context: Context.RECENT_COMPANIES,
              interaction: Interaction.FETCH_MORE,
              itemType: ItemType.PAGINATION,
              itemValue: [...previousApplicants.result, ...newResult].length,
            });

            return {
              ...previousResult,
              recentlySeenCompanies: {
                ...previousApplicants,
                pagination: pagination,
                result: [...previousApplicants.result, ...newResult],
              },
            };
          },
          variables: {
            paginationOptions: {
              limit: NEXT_BATCH_LIMIT,
              minCursor: pagination.maxCursor,
            },
          },
        });
      }
    }, 200);

    getScrollableContainer().addEventListener('scroll', loadMore);

    return () => {
      getScrollableContainer().removeEventListener('scroll', loadMore);

      loadMore.cancel();
    };
  }, [isRecentlySeenCompaniesLoading, pagination?.hasMoreResults]);

  return {
    companies: companies,
    isRecentlySeenCompaniesLoading: isRecentlySeenCompaniesLoading,
  };
};
