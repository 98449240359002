import React from 'react';
import PropTypes from 'prop-types';
import FadeInImage from '@src/components/FadeInImage';
import './styles.scss';

export const Labels = ({ awards }) => {
  if (!awards) {
    return null;
  }

  const {
    pagination: { totalCount },
    result,
  } = awards;

  return (
    <div className="award-labels-wrapper">
      {result.map(({ id, name, logo: { fullUrl } }) => (
        <div className="award-labels-wrapper__label" key={id}>
          <FadeInImage className="award-labels-wrapper__label-image" src={fullUrl} />
          <span>{name}</span>
        </div>
      ))}
      {totalCount > awards.result.length && (
        <div className="award-labels-wrapper__label">+{totalCount - awards.result.length}</div>
      )}
    </div>
  );
};

Labels.propTypes = {
  awards: PropTypes.shape({
    pagination: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
    }).isRequired,
    result: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        logo: PropTypes.shape({
          fullUrl: PropTypes.string.isRequired,
        }).isRequired,
        name: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
};
