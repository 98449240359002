import React from 'react';
import PropTypes from 'prop-types';
import { Video } from '@src/components/Video';
import { AboutTitle } from '../AboutTitle';
import { AboutText } from '../AboutText';
import './styles.scss';

export const Story = ({ description, video }) => {
  if (!description && !video) {
    return null;
  }

  return (
    <div className="company-profile-story">
      <AboutTitle className="company-profile-story__title">The story</AboutTitle>

      <AboutText text={description} />

      {!!video && <Video video={video} />}
    </div>
  );
};

Story.propTypes = {
  description: PropTypes.string,
  video: PropTypes.object,
};
