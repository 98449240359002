import React, { FC } from 'react';
import Svg from 'svg-inline-react';
import FrCloseSvg from 'assets/images/fr-close.svg';
import { SelectItem } from '@src/components/SelectItem';
import { RelevantOpeningsSortOptionEnum } from '@src/graphql/generated';
import { SORT_OPTION_LABEL } from '../../constants/sortOptions';
import { SortOptionsModalContentProps } from './props';
import './styles.scss';

export const SortOptionsModalContent: FC<SortOptionsModalContentProps> = ({ onClose, activeOption, onSelect }) => {
  return (
    <div className="jobs-sort-options-modal-content">
      <div className="jobs-sort-options-modal-content__header">
        Sort offers by
        <Svg className="jobs-sort-options-modal-content__close" onClick={onClose} src={FrCloseSvg} />
      </div>

      <div className="jobs-sort-options-modal-content__options">
        <SelectItem
          active={activeOption === RelevantOpeningsSortOptionEnum.RELEVANCY}
          isParent
          isSingleSelect
          onSelect={() => onSelect(RelevantOpeningsSortOptionEnum.RELEVANCY)}
          option={{ value: RelevantOpeningsSortOptionEnum.RELEVANCY }}
          optionLabel={SORT_OPTION_LABEL[RelevantOpeningsSortOptionEnum.RELEVANCY]}
        />

        <SelectItem
          active={activeOption === RelevantOpeningsSortOptionEnum.RECENCY}
          isParent
          isSingleSelect
          onSelect={() => onSelect(RelevantOpeningsSortOptionEnum.RECENCY)}
          option={{ value: RelevantOpeningsSortOptionEnum.RECENCY }}
          optionLabel={SORT_OPTION_LABEL[RelevantOpeningsSortOptionEnum.RECENCY]}
        />
      </div>
    </div>
  );
};
