import React, { FC, useMemo } from 'react';
import classnames from 'classnames';
import { Chart } from './Chart';
import { GetHighlightLocation } from './interface/getHighlightLocation.interface';
import { Props } from './props';
import './styles.scss';

export const SalaryRanking: FC<Props> = ({
  wrapperClassName,
  averageSalaryAmount = 0,
  userSalaries = [],
  userMonthlySalary = 0,
  currencySymbol,
}) => {
  const getHighlightLocation: GetHighlightLocation = (amount, userSalaries) => {
    if (!amount) {
      return 0;
    }

    if (userSalaries) {
      const minUserSalaryInGraph = Math.min(...userSalaries.map(({ step }) => step));
      const maxUserSalaryInGraph = Math.max(...userSalaries.map(({ step }) => step));

      if (minUserSalaryInGraph && amount < minUserSalaryInGraph) {
        return 1;
      }

      if (maxUserSalaryInGraph && amount > maxUserSalaryInGraph) {
        return userSalaries.length;
      }

      const stepWidth = userSalaries[1].step - userSalaries[0].step;
      const stepPadding = Math.round(stepWidth / 2);

      if (userSalaries.length) {
        for (let index = 0; index < userSalaries.length - 1; index++) {
          const current = userSalaries[index];

          if (amount >= current.step - stepPadding && amount < current.step + stepPadding) {
            return index + 1;
          }
        }
      }

      return userSalaries.length;
    }

    return 1;
  };

  const averageSalaryHighlightLocation = useMemo(
    () => getHighlightLocation(averageSalaryAmount, userSalaries),
    [averageSalaryAmount, userSalaries],
  );

  const userSalaryHighlightLocation = useMemo(
    () => getHighlightLocation(userMonthlySalary, userSalaries),
    [userMonthlySalary, userSalaries],
  );

  return userSalaries && userSalaries.length > 0 ? (
    <div className={classnames('frank-market-overview-salary-ranking', wrapperClassName)}>
      <Chart
        averageSalaryHighlightLocation={averageSalaryHighlightLocation}
        currencySymbol={currencySymbol}
        userSalaries={userSalaries}
        userSalaryHighlightLocation={userSalaryHighlightLocation}
      />
    </div>
  ) : null;
};
