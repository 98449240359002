import React, { FC, useEffect } from 'react';
import { ApolloProvider } from '@apollo/client';
import { apolloClient } from '@src/graphql/apollo';
import ErrorCatch from '@src/containers/ErrorCatch';
import SlideUpModal from '@src/containers/SlideUpModal';
import { PromoModalContent } from '@src/containers/PromoModalContent';
import ToastMessage from '@src/components/ToastMessage';
import { SessionValidation } from '@src/App/SessionValidation';
import { setContent as setModalContent, closeModal } from '@src/redux/actions/slideUpModal';
import { isCrawler } from '@src/util/isCrawler';
import { useAppDispatch } from '@src/store';
import Subscriptions from '../Subscriptions';
import { Routes } from './Routes';

export const PublicApp: FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isCrawler()) {
      dispatch(
        setModalContent({
          content: <PromoModalContent onClose={() => dispatch(closeModal())} />,
          height: 'auto',
        }),
      );
    }
  }, []);

  return (
    <ApolloProvider client={apolloClient}>
      <SessionValidation>
        <Subscriptions>
          <ErrorCatch>
            <Routes />

            <SlideUpModal />

            <ToastMessage />
          </ErrorCatch>
        </Subscriptions>
      </SessionValidation>
    </ApolloProvider>
  );
};
