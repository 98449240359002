import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Markdown from 'markdown-to-jsx';
import { replaceHtmlEntities } from '@src/util/replaceHtmlEntities';
import './styles.scss';

export const AboutText = ({ className, text }) => {
  if (!text) {
    return null;
  }

  return (
    <Markdown className={classnames('company-profile-about-text', className)}>{replaceHtmlEntities(text)}</Markdown>
  );
};

AboutText.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
};
