import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { FixedHeader } from '@src/components/FixedHeader';
import { AreaList } from '@src/containers/AreaList';
import { useAppDispatch } from '@src/store';
import { setNavigationVisibility } from '@src/redux/actions/navigation';
import { ActionButtons } from '../ActionButtons';
import './styles.scss';

export const AreaSelection = ({
  selectedAreas,
  goBack,
  onAreaSave,
  onClear,
  activeCountries,
  onSpecificCountrySelect,
}) => {
  const dispatch = useAppDispatch();

  const [activeAreas, setActiveAreas] = useState(selectedAreas);

  const handleAreaSelect = area => {
    setActiveAreas([area]);
  };

  useEffect(() => {
    dispatch(setNavigationVisibility(false));

    return () => {
      dispatch(setNavigationVisibility(true));
    };
  }, []);

  return (
    <div className="area-selection fade-in">
      <FixedHeader
        disableOpacity
        onBack={goBack}
        title="Where would you like to work?"
        wrapperClassName="area-selection__header"
      />
      <div className="area-selection__body">
        <AreaList
          activeAreas={activeAreas}
          activeCountries={activeCountries}
          onAreaSelect={handleAreaSelect}
          onSpecificCountrySelect={onSpecificCountrySelect}
        />
      </div>
      <div className="area-selection__footer">
        <ActionButtons
          blueButtonLabel="Save"
          blueButtonProps={{
            disabled: isEmpty(activeAreas),
            onClick: () => onAreaSave(activeAreas),
          }}
          invisibleButtonProps={{
            onClick: onClear,
          }}
        />
      </div>
    </div>
  );
};

AreaSelection.propTypes = {
  activeCountries: PropTypes.array,
  goBack: PropTypes.func.isRequired,
  onAreaSave: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  onSpecificCountrySelect: PropTypes.func.isRequired,
  selectedAreas: PropTypes.array,
};
