import { isEmpty } from 'lodash';
import { apolloClient } from '@src/graphql/apollo';
import {
  RecordSignalsMutationDocument,
  RecordSignalsMutationMutation,
  RecordSignalsMutationMutationVariables,
} from '@src/graphql/generated';
import { RecordSignalsPayloadParams } from './interfaces/recordSignals.interface';

export const recordSignals = async ({
  items,
  signalType,
  currentLimit,
  onCompleted,
  onError,
}: RecordSignalsPayloadParams): Promise<void> => {
  const batch = currentLimit ? items?.slice(-Math.abs(currentLimit)) : items;

  const signals = batch?.map(({ type, id }) => ({
    entityId: id,
    entityType: type,
    type: signalType,
  }));

  if (signals && !isEmpty(signals)) {
    try {
      await apolloClient.mutate<RecordSignalsMutationMutation, RecordSignalsMutationMutationVariables>({
        mutation: RecordSignalsMutationDocument,
        variables: { signals: signals },
      });

      if (onCompleted) {
        onCompleted();
      }
    } catch (error) {
      if (onError) {
        onError(error);
      }
    }
  }
};
