import React, { FC } from 'react';
import classnames from 'classnames';
import Button from '@src/components/ButtonOld';
import Back from '@src/components/Back';
import { BackContinueActionsProps } from './props';
import './styles.scss';

export const BackContinueActions: FC<BackContinueActionsProps> = ({
  href,
  onBack,
  onButtonClick,
  className,
  label,
  buttonsComponent,
  isBackHidden,
  wrapperRef,
}) => {
  return (
    <React.Fragment>
      {!isBackHidden && <Back className={classnames('back-continue-actions-back', className)} onBack={onBack} />}
      {!!buttonsComponent && (
        <div className={classnames('back-continue-actions-button-wrapper', className)} ref={wrapperRef}>
          {buttonsComponent}
        </div>
      )}

      {!!(onButtonClick || href) && (
        <div className={classnames('back-continue-actions-button-wrapper', className)} ref={wrapperRef}>
          <Button className="back-continue-actions-button" isLink={!!href} onClick={onButtonClick} positive to={href}>
            {label}
          </Button>
        </div>
      )}
    </React.Fragment>
  );
};
