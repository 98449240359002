import React from 'react';
import './styles.scss';

export default function Skeleton() {
  return (
    <div className="sticky-navigation-skeleton">
      <div className="sticky-navigation-skeleton__nav pulse-fade">
        <span className="sticky-navigation-skeleton__nav-item" />
        <span className="sticky-navigation-skeleton__nav-item" />
        <span className="sticky-navigation-skeleton__nav-item" />
      </div>
    </div>
  );
}
