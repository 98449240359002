import React, { FC, Fragment } from 'react';
import { getReviewActorData } from '@src/util/reviews/getReviewActorData';
import { AvatarWithInfo } from '@src/components/AvatarWithInfo';
import { MessageWithReadMore } from '@src/components/MessageWithReadMore';
import { CompanyReviewLike } from '@src/containers/Feed/CompanyReviewFeedItem/CompanyReviewLike';
import { likeReview } from '@src/graphql/reviews/actions/likeReview';
import { UserCompanyAssociationTypeEnum, UserType } from '@src/graphql/generated';
import { AvatarSize } from '@src/components/Avatar/enums/avatarSize.enum';
import { RepliedComment } from '../RepliedComment';
import { ActorNameWithStatus } from './ActorNameWithStatus';
import { Labels } from './Labels';
import { AwardFeedItemProps } from './props';
import './styles.scss';

export const AwardFeedItem: FC<AwardFeedItemProps> = ({ review, companyDeepLink }) => {
  const { avatarColor, nameInitials, fullName, subTitleWithTime } = getReviewActorData(
    review.actor.entity as UserType,
    review.createdAt,
  );

  return (
    <div className="award-feed-item">
      <AvatarWithInfo
        avatarProps={{
          avatarColor: avatarColor,
          initials: nameInitials,
          size: AvatarSize.MEDIUM_LARGE,
        }}
        name={
          <ActorNameWithStatus
            fullName={fullName}
            isWorking={
              review.__typename === 'CompanyReviewType'
                ? review.userAssociations.includes(UserCompanyAssociationTypeEnum.WORKING)
                : review.__typename === 'CompanyRatingReviewType'
                ? review.userAssociation === UserCompanyAssociationTypeEnum.WORKING
                : false
            }
          />
        }
        subTitle={subTitleWithTime}
        wrapperClassName="award-feed-item__user-info"
      />
      {review.__typename === 'CompanyReviewType' && (
        <Fragment>
          <Labels awards={review.awards} />
          {!!review.comment && (
            <MessageWithReadMore
              shouldForceInline
              limit={300}
              lineLimit={5}
              message={review.comment}
              messageClassName="award-feed-item__comment"
              wrapperClassName="award-feed-item__comment-wrapper"
            />
          )}
        </Fragment>
      )}

      <div className="award-feed-item__reaction-wrapper">
        <CompanyReviewLike
          className="award-feed-item__like-reaction"
          hasLike={review.hasReacted}
          iconClassName="award-feed-item__like-icon"
          labelClassName="award-feed-item__like-label"
          onTouchEnd={hasActiveLike => {
            if (window.isPublicApp) {
              window.open(companyDeepLink, '_blank');

              return;
            }

            likeReview(review.id, !hasActiveLike);
          }}
          reactionsCount={review.reactionsCount}
        />
      </div>
      {review.reply ? <RepliedComment reply={review?.reply} /> : null}
    </div>
  );
};
