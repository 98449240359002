import React, { FC } from 'react';
import classnames from 'classnames';
import { Button } from './Button';
import { ButtonProps } from './props';

export const OutlinedButton: FC<ButtonProps> = ({ children, className, color, ...props }) => {
  return (
    <Button className={classnames(className, `fr-button--outlined-${color}`)} {...props}>
      {children}
    </Button>
  );
};

OutlinedButton.defaultProps = {
  color: 'primary',
};
