import moment from 'moment-timezone';

const handleWeeks = (days: number): string => {
  if (days >= 7) {
    const weeks = Math.round(days / 7);

    return weeks > 1 ? weeks + ' weeks' : weeks + ' week';
  }

  return days + ' days';
};

// change relativeTime values in moment
moment.updateLocale('en', {
  relativeTime: {
    M: '%d month',
    MM: '%d months',
    d: '%d day',
    dd: handleWeeks,
    h: '%d hour',
    hh: '%d hours',
    m: '%d min',
    mm: '%d min',
    s: 'now',
    ss: 'now',
    y: '%d year',
    yy: '%d years',
  },
});

export const relativeTime = (date: Date, hasSuffix = true): string => {
  const momentLastMessageDate = moment(date);

  // calculate difference in months
  const diffInMonths = moment().diff(momentLastMessageDate, 'months');

  // decide whether show date or moment fromNow, depending on months difference
  return diffInMonths >= 1 && diffInMonths < 12
    ? momentLastMessageDate.format('DD MMM') // format date in 'DD MMM'
    : momentLastMessageDate.fromNow(hasSuffix); // get relative time from moment
};

export const relativeTimeWithShortenedNames = (date: Date | string): string => {
  const now = moment();
  const passedTime = moment(date);

  const diffMinutes = now.diff(passedTime, 'minutes');
  const diffHours = now.diff(passedTime, 'hours');
  const diffDays = now.diff(passedTime, 'days');
  const diffMonths = now.diff(passedTime, 'months');
  const diffYears = now.diff(passedTime, 'years');

  if (diffMinutes < 1) {
    return 'Active just now';
  } else if (diffMinutes < 60) {
    return 'Active ' + diffMinutes + 'm ago';
  } else if (diffHours < 24) {
    return 'Active ' + diffHours + 'h ago';
  } else if (diffDays < 7) {
    return 'Active ' + diffDays + 'd ago';
  } else if (diffDays >= 7 && diffDays < 30) {
    return 'Active ' + Math.floor(diffDays / 7) + 'w ago';
  } else if (diffMonths >= 1 && diffMonths <= 12) {
    return 'Active ' + passedTime.format('DD MMM');
  } else {
    return 'Active ' + diffYears + 'y ago';
  }
};
