import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { FixedHeader } from '@src/components/FixedHeader';
import { CountriesList } from '@src/containers/CountriesList';
import { useAppDispatch } from '@src/store';
import { setNavigationVisibility } from '@src/redux/actions/navigation';
import { ActionButtons } from '../ActionButtons';
import './styles.scss';

export const CountrySearch = ({ selectedCountries, onClear, onCountrySave, goBack }) => {
  const dispatch = useAppDispatch();

  const [activeCountries, setActiveCountries] = useState(selectedCountries);

  const handleCountrySelect = country => {
    const updatedCountries = activeCountries.find(it => it.id === country.id)
      ? activeCountries.filter(it => it.id !== country.id)
      : [...activeCountries, country];

    setActiveCountries(updatedCountries);
  };

  useEffect(() => {
    dispatch(setNavigationVisibility(false));

    return () => {
      dispatch(setNavigationVisibility(true));
    };
  }, []);

  return (
    <div className="country-search fade-in">
      <FixedHeader
        disableOpacity
        onBack={goBack}
        title="Select a country for remote work"
        wrapperClassName="country-search__header"
      />
      <div className="country-search__body">
        <CountriesList activeCountries={activeCountries} onCountrySelect={country => handleCountrySelect(country)} />
      </div>
      <div className="country-search__footer">
        <ActionButtons
          blueButtonLabel="Save"
          blueButtonProps={{
            disabled: isEmpty(activeCountries),
            onClick: () => onCountrySave(activeCountries),
          }}
          invisibleButtonProps={{
            onClick: onClear,
          }}
        />
      </div>
    </div>
  );
};

CountrySearch.propTypes = {
  goBack: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  onCountrySave: PropTypes.func.isRequired,
  selectedCountries: PropTypes.object,
};
