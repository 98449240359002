import React, { FC } from 'react';
import { useGetUserQuery } from '@src/graphql/generated';
import { ProtectedComponentProps } from './props';

export const ProtectedComponent: FC<ProtectedComponentProps> = ({ children, fallback }) => {
  const { data: userData } = useGetUserQuery();

  const user = userData?.currentUser;

  if (user?.onboardingDone) {
    return <>{children}</>;
  }

  return <>{fallback}</>;
};
