import { Config } from './interfaces/Config.interface';

const config: Config = {
  graphqlApi: 'http://localhost:4000/app',
  graphqlSocketUri: 'ws://localhost:4000/app/subscriptions',
};

const ENV = process.env.ENV;

if (ENV === 'production') {
  config.sentryDsn = 'https://f653272e8660415fbe0ed49908d257df@sentry.io/1552576';
  config.datadogApplicationId = 'e4607174-d70a-4ca9-ad8f-9774de0d8385';
  config.datadogClientToken = 'pub4886fdf616fe721c9ed95dcbff805f74';
  config.graphqlApi = 'https://api.frankhr.com/app';
  config.graphqlSocketUri = 'wss://api.frankhr.com/app/subscriptions';
} else if (ENV === 'staging') {
  config.graphqlApi = 'https://api.mfstaging.xyz/app';
  config.graphqlSocketUri = 'wss://api.mfstaging.xyz/app/subscriptions';
} else if (ENV === 'test') {
  const SERVER_PORT = process.env.SERVER_PORT || 4200;
  config.graphqlApi = `http://localhost:${SERVER_PORT}/api/graphql`;
  config.graphqlSocketUri = `ws://localhost:${SERVER_PORT}/api/graphql/subscriptions`;
}

export default config;
