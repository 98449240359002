import React, { FC, useEffect, useRef } from 'react';
import classnames from 'classnames';
import { isEmpty, replace } from 'lodash';
import Svg from 'svg-inline-react';
import FrAddSvg from 'assets/images/fr-add.svg';
import styleVars from 'assets/_vars.scss';
import { HorizontalScroller } from '@src/components/HorizontalScroller';
import FadeInImage from '@src/components/FadeInImage';
import { HeaderLabel } from '@src/components/HeaderLabel';
import { PlaceScrollerProps } from './props';
import './styles.scss';

const CONTENT_SIDE_PADDING = Number(replace(styleVars['content-side-padding'], 'rem', '')) * 10;

export const PlaceScroller: FC<PlaceScrollerProps> = ({
  wrapperClassName,
  places,
  activePlaceId,
  onPlaceClick,
  containerClassName,
  addPlace,
  canAddPlace,
  nameField = 'shortName',
}) => {
  const scrollerRef = useRef<HTMLDivElement>(null);

  const activeIndex = places.findIndex(place => place.id === activePlaceId);

  useEffect(() => {
    if (!isEmpty(places) && scrollerRef.current) {
      const nodes = scrollerRef.current.childNodes as NodeListOf<HTMLElement>;
      const node = nodes[activeIndex];

      if (node) {
        const { x: scrollStartPoint, width: scrollWidth } = scrollerRef.current.getBoundingClientRect();
        const { x: nodeStartPoint, width: nodeWidth } = node.getBoundingClientRect();
        const nodeEndPoint = nodeStartPoint + nodeWidth;
        const scrollEndPoint = scrollStartPoint + scrollWidth;

        if (nodeStartPoint < scrollStartPoint || nodeEndPoint > scrollEndPoint) {
          scrollerRef.current.scrollTo({
            behavior: 'smooth',
            left: node?.offsetLeft - CONTENT_SIDE_PADDING,
          });
        }
      }
    }
  }, [activeIndex]);

  return (
    <HorizontalScroller
      className={classnames('place-horizontal-scroller', wrapperClassName)}
      containerClassName={classnames('place-horizontal-scroller__container', containerClassName)}
      scrollerRef={scrollerRef}
    >
      {places.map(place => {
        const title = place?.[nameField] || place.shortName || place.title;

        if (!title) {
          return null;
        }

        return (
          <HeaderLabel isActive={place.id === activePlaceId} key={place.id} onClick={() => onPlaceClick(place)}>
            <>
              {place.image?.templateUrl && (
                <FadeInImage className="place-horizontal-scroller__flag" src={place.image?.templateUrl} />
              )}

              {title}
            </>
          </HeaderLabel>
        );
      })}

      {canAddPlace && (
        <HeaderLabel
          className="place-horizontal-scroller__label place-horizontal-scroller__label--add"
          key="add-place"
          onClick={() => addPlace()}
        >
          <Svg className="place-horizontal-scroller__add" src={FrAddSvg} />
        </HeaderLabel>
      )}
    </HorizontalScroller>
  );
};
