import { FeedItemAllowedPlaceFragment, OpeningRemoteTypeEnum, PlaceTypeEnum } from '@src/graphql/generated';
import {
  isGetPlaceAreaFragmentType,
  isGetPlaceCountryFragmentType,
  isGetPlaceLocationFragmentType,
} from '@src/views/JobsSearch/hooks/usePlaces/typeGuards';
import { OpeningLocation } from './interface/openingLocation.interface';

const formatAllowedPlaces = (allowedPlaces?: FeedItemAllowedPlaceFragment[]) => {
  return allowedPlaces?.map(place => {
    const country = isGetPlaceCountryFragmentType(place.entity) ? place.entity : null;
    const location = isGetPlaceLocationFragmentType(place.entity) ? place.entity : null;
    const area = isGetPlaceAreaFragmentType(place.entity) ? place.entity : null;

    if (country) {
      return {
        countryShortName: country?.shortName,
        id: country?.id,
        name: country?.longName,
        type: PlaceTypeEnum.COUNTRY,
      };
    }

    if (area) {
      return {
        countryShortName: area?.longName,
        id: area?.id,
        name: area?.longName,
        type: PlaceTypeEnum.AREA,
      };
    }

    return {
      countryShortName: location?.country?.shortName,
      id: location?.id,
      name: location?.country?.shortName,
      type: PlaceTypeEnum.LOCATION,
    };
  });
};

export const getOpeningPlaces = (
  positionLocations?: OpeningLocation[],
  allowedPlaces?: FeedItemAllowedPlaceFragment[],
  remoteType?: OpeningRemoteTypeEnum,
): string => {
  if (remoteType === OpeningRemoteTypeEnum.FULLY) {
    const places = formatAllowedPlaces(allowedPlaces);

    if (places && places?.length > 0) {
      return `Remote in ${places?.map(place => place.name).join(', ')}`;
    } else {
      return 'Remote';
    }
  }

  if (remoteType === OpeningRemoteTypeEnum.ONSITE) {
    return `On-site in ${positionLocations?.map(location => location?.shortName).join(', ') || ''}`;
  }

  if (remoteType === OpeningRemoteTypeEnum.HYBRID) {
    if (positionLocations && positionLocations?.length > 0) {
      return `Hybrid-remote ${positionLocations?.map(location => location?.shortName).join(', ') || ''}`;
    } else {
      const places = formatAllowedPlaces(allowedPlaces);

      return `Hybrid-remote in ${places?.map(place => place.name).join(', ') || ''}`;
    }
  }

  return positionLocations?.map(location => location?.shortName).join(', ') || '';
};
