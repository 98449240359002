export const SOFTWARE_ID = '5995b157871fb3a5190aa2fe';
export const IT_SYSADMIN_ID = '5995b157871fb3a5190aa301';
export const FINANCE_ID = '5995b157871fb3a5190aa304';
export const DATA_ANALYTICS_ID = '5995b157871fb3a5190aa2ff';
export const DESIGN_ID = '5995b157871fb3a5190aa300';
export const PROJECT_MANAGEMENT_ID = '59c172c27fd8f1c6796b5526';
export const MARKETING_ID = '5995b157871fb3a5190aa303';
export const SALES_ID = '5995b157871fb3a5190aa302';
export const LEGAL_ID = '5995b157871fb3a5190aa306';
export const HR_ID = '5995b157871fb3a5190aa307';
export const CUSTOMER_SUPPORT_ID = '59b8dae0d4841f1d116392cb';
export const ADMINISTRATION_ID = '5de2de27b8d1dc6e8a876da2';
export const TOP_LEVEL_MANAGEMENT_ID = '5de2de27b8d1dc6e8a876da3';
export const ENGINEERING_ID = '5de2de28b8d1dc6e8a876da6';
export const LOGISTICS_ID = '5de2de29b8d1dc6e8a876da7';
