import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import FadeInImage from '@src/components/FadeInImage';
import partOfDay from '@src/util/partOfDay';

import './styles.scss';

export default function OverviewHeader({ className, value }) {
  const { image, title, subtitle } = value;

  return (
    <header className={classnames('overview-header', className, partOfDay())}>
      <FadeInImage noBorder src={image} />
      <div className="overview-header-text">
        <div className="overview-header-title">{title}</div>
        <div className="overview-header-subtitle">{subtitle}</div>
      </div>
    </header>
  );
}

OverviewHeader.propTypes = {
  className: PropTypes.string,
  value: PropTypes.object.isRequired,
};
