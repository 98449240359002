import { uniqBy } from 'lodash';
import { FieldMergeFunction } from '@apollo/client';
import { PaginatedMessagesType } from '@src/graphql/generated';

export const mergeMessageThreadPaginatedResults: FieldMergeFunction<PaginatedMessagesType, PaginatedMessagesType> = (
  existing,
  incoming,
  args,
) => {
  const existingPagination = existing?.pagination || {};
  const existingResult = existing?.result || [];

  const incomingPagination = incoming?.pagination || {};
  const incomingResult = incoming?.result || [];

  const mergeResult = {
    ...existing,
    pagination: {
      ...existingPagination,
      ...incomingPagination,
    },
    result: args.variables?.messagesPaginationOptions?.minCursor
      ? uniqBy([...existingResult, ...incomingResult], '__ref')
      : uniqBy([...incomingResult, ...existingResult], '__ref'),
  };

  return mergeResult;
};
