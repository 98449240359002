import { ApolloClient, ApolloLink } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { cache } from './cache';
import { authLink } from './links/authLink';
import { errorLink } from './links/errorLink';
import { uploadLink } from './links/uploadLink';
import { sentryLink } from './links/sentryLink';
import { wsLink } from './links/wsLink';
import { authMiddlewareLink } from './links/authMiddlewareLink';
import { controlLink } from './links/controlLink';

const httpLink = ApolloLink.from([controlLink, authMiddlewareLink, sentryLink, errorLink, authLink, uploadLink]);

export const apolloClient = new ApolloClient({
  cache: cache,
  link: ApolloLink.split(
    operation => {
      const definition = getMainDefinition(operation.query);

      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    httpLink,
  ),
});
