import classnames from 'classnames';
import styleVars from 'assets/_vars.scss';
const blackBackground = 'dark';
const blackHeaderBackground = 'black-header';

export function setBlackBody(): void {
  const body = document.body;
  body.className = classnames(body.className, blackBackground);
}

export function setWhiteBody(): void {
  const body = document.body;
  body.className = body.className.replace(new RegExp(`${blackBackground}|${blackHeaderBackground}`), '');
}

export function backgroundForBlackHeader(): void {
  const body = document.body;
  body.className = classnames(body.className, blackHeaderBackground);
}

export function isIOSContainer(): boolean {
  return document.body.classList.contains('ios');
}

export function isIOSIphoneXContainer(): boolean {
  return document.body.classList.contains('iphonex');
}

export function isAndroidContainer(): boolean {
  return document.body.classList.contains('android');
}

export function isNativeContainer(): boolean {
  return (
    window.location.href.includes('/container/') || isIOSContainer() || isIOSIphoneXContainer() || isAndroidContainer()
  );
}

export function removePaddingTopForIOS(): void {
  if (document.body.classList.contains('ios')) {
    document.body.style.paddingTop = '0';
  }
}

export function addPaddingTopForIOS(): void {
  if (document.body.classList.contains('ios')) {
    document.body.style.paddingTop = styleVars.iosPaddingTop;
  }
}

export function addPaddingTopForIphoneX(): void {
  if (document.body.classList.contains('iphonex')) {
    document.body.style.paddingTop = styleVars.iphonexPaddingTop;
  }
}

// For ios.
// You can check default styles for iphoneX in following folder - mobile/src/styles.scss
export function removePaddingBottomForIphoneX(): void {
  if (document.body.classList.contains('iphonex')) {
    document.body.style.paddingBottom = '0';
  }
}

export function addDefaultPaddingBottomForIphoneX(): void {
  if (document.body.classList.contains('iphonex')) {
    document.body.style.paddingBottom = styleVars.iphonexPaddingBottom; // According to mobile/src/styles.scss styles.
  }
}
