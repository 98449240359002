import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import NotificationGroup from '../NotificationGroup';
import './styles.scss';

export default function Feed({ notificationGroups }) {
  const getDateForNotification = notification => moment.utc(notification.sentAt).format('YYYY-MM-DD');

  const today = moment.utc().format('YYYY-MM-DD');

  return (
    <div className="notification-center-feed">
      {notificationGroups.map(({ notifications }, index) => {
        const previousDate =
          index > 0 ? getDateForNotification(notificationGroups[index - 1].notifications[0]) : undefined;
        const currentDate = getDateForNotification(notifications[0]);

        const shouldShowTodayTitle = index === 0 && today === currentDate;
        const shouldShowEarlierTitle =
          (index === 0 && today !== currentDate) || (today === previousDate && previousDate !== currentDate);

        return (
          <Fragment key={notifications[0].id}>
            {shouldShowTodayTitle || shouldShowEarlierTitle ? (
              today === currentDate ? (
                <Fragment>
                  <h4 className="notification-center-feed__title">Today</h4>
                </Fragment>
              ) : (
                <Fragment>
                  <h4 className="notification-center-feed__title">Earlier</h4>
                </Fragment>
              )
            ) : null}

            <NotificationGroup notifications={notifications} />
          </Fragment>
        );
      })}
    </div>
  );
}

Feed.propTypes = {
  notificationGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
};
