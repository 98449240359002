import React, { FC } from 'react';
import classnames from 'classnames';
import FadeInImage from '@src/components/FadeInImage';
import partOfDay from '@src/util/partOfDay';
import { CountryHeaderProps } from './props';
import './styles.scss';

export const CountryHeader: FC<CountryHeaderProps> = ({ className, value }) => {
  const { image, country, subtitle } = value;

  return (
    <header className={classnames('overview-header', className, partOfDay())}>
      <FadeInImage noBorder src={image} />
      <div className="overview-header-text">
        <div className="overview-header-title">{country}</div>
        <div className="overview-header-subtitle">{subtitle}</div>
      </div>
    </header>
  );
};
