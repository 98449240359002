import React, { PureComponent } from 'react';
import ContentPush from '@src/components/ContentPush';

export default class Question extends PureComponent {
  state = {
    loading: false,
    question: undefined,
    recruiter: undefined,
    recruiterTitle: undefined,
  };

  componentDidMount() {
    window.parent.dataSubject$.subscribe(({ loading = false, question, recruiter, recruiterTitle }) => {
      this.setState({
        loading,
        question,
        recruiter,
        recruiterTitle,
      });
    });
  }

  sendResponse = () => {};

  render() {
    const { loading, question, recruiter, recruiterTitle } = this.state;

    if (!question) {
      return null;
    }

    return (
      <div className="app-transition-group navigation-hidden">
        <ContentPush
          loading={loading}
          question={question}
          recruiter={recruiter}
          recruiterTitle={recruiterTitle}
          sendResponse={this.sendResponse}
          showHeader={false}
          showRecruiterAvatar
        />
      </div>
    );
  }
}
