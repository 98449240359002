import React, { FC } from 'react';
import classnames from 'classnames';
import { BlueButton, OutlinedButton } from '@src/components/Button';
import { Props } from './props';
import './styles.scss';

export const MessagesActionButtons: FC<Props> = ({ wrapperClassName, onAccept, onDecline }) => {
  return (
    <div className={classnames('messages-action-buttons', wrapperClassName)}>
      <OutlinedButton
        className="messages-action-buttons__button messages-action-buttons__button--decline"
        onClick={onDecline}
      >
        Decline
      </OutlinedButton>

      <BlueButton className="messages-action-buttons__button" isFilled onClick={onAccept}>
        Accept chat
      </BlueButton>
    </div>
  );
};
