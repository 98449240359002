import React, { FC, forwardRef } from 'react';
import classnames from 'classnames';
import { ContainerType } from './enums/containerType.enum';
import { ContainerProps } from './props';
import './styles.scss';

export const Container: FC<ContainerProps> = forwardRef(
  ({ className, type = ContainerType.PRIMARY, children, onClick }, ref) => {
    const containerClassName = classnames('fr-container', `fr-container--${type}`, className);

    return (
      <div className={containerClassName} onClick={onClick && onClick} ref={ref}>
        {children}
      </div>
    );
  },
);
