const requestAnimationFrame = window.requestAnimationFrame;
const cancelAnimationFrame = window.cancelAnimationFrame;
const DURATION = 250;

export default function scroll(el, scrollLeft, scrollTop) {
  let started;
  const x = scrollLeft - el.scrollLeft;
  const y = scrollTop - el.scrollTop;
  let xScrolled = 0;
  let yScrolled = 0;

  if (el.scrolling) {
    cancelAnimationFrame(el.scrolling);
  }

  el.scrolling = requestAnimationFrame(function scroll(now) {
    if (started == null) {
      started = now;
    }

    const progress = Math.min((now - started) / DURATION, 1);
    const coefficient = easeInOut(progress);

    let byX = Math.round(coefficient * x);
    let byY = Math.round(coefficient * y);

    if ((byX -= xScrolled)) {
      el.scrollLeft += byX;
      xScrolled += byX;
    }

    if ((byY -= yScrolled)) {
      el.scrollTop += byY;
      yScrolled += byY;
    }

    if (progress < 1) {
      el.scrolling = requestAnimationFrame(scroll);
    } else {
      delete el.scrolling;
    }
  });
}

function easeInOut(k) {
  return 0.5 * (1 - Math.cos(Math.PI * k));
}
