import { useEffect, useLayoutEffect } from 'react';
import { NetworkStatus } from '@apollo/client';
import { throttle, size } from 'lodash';
import { recordSignals } from '@src/graphql/signals/actions/recordSignals';
import { useFeedUpdate } from '@src/customHooks';
import { trackEvent } from '@src/metrics';
import {
  GetSearchLatestOpeningsQuery,
  GetSearchOpeningsLatestFeedDocument,
  SignalEntityTypeEnum,
  SignalTypeEnum,
  useGetSearchLatestOpeningsQuery,
} from '@src/graphql/generated';
import { isOpeningType } from '@src/graphql/typeGuards';
import { Interaction } from '@src/metrics/enums/interaction.enum';
import { Context } from '@src/metrics/enums/context.enum';
import { ItemType } from '@src/metrics/enums/itemType.enum';
import { UseSpaceFilledOpenings } from './interface/useSpaceFilledOpenings.interface';

const FIRST_BATCH_SIZE = 6;
const NEXT_PATCH_SIZE = 6;

const getFeed = (data?: GetSearchLatestOpeningsQuery) => data?.searchOpenings.result || [];
const getPagination = (data?: GetSearchLatestOpeningsQuery) => data?.searchOpenings.pagination;

export const useSpaceFilledOpenings: UseSpaceFilledOpenings = ({
  getScrollableContainer,
  searchPattern,
  searchTags,
  searchTagsWithOrCondition,
}) => {
  const {
    data,
    loading: isLoading,
    fetchMore,
    refetch,
    networkStatus,
  } = useGetSearchLatestOpeningsQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      paginationOptions: {
        limit: FIRST_BATCH_SIZE,
      },
      searchParams: {
        searchPattern: searchPattern,
        searchTagsWithAndCondition: searchTags,
        searchTagsWithOrCondition: searchTagsWithOrCondition,
      },
    },
  });

  const pagination = getPagination(data);
  const feed = getFeed(data);

  const isFeedLoading = isLoading || networkStatus === NetworkStatus.setVariables;
  const isRefetchLoading = networkStatus === NetworkStatus.refetch;

  const { checkNewContent } = useFeedUpdate(
    GetSearchOpeningsLatestFeedDocument,
    { searchPattern, searchTags },
    'searchOpenings.result',
    refetch,
    getScrollableContainer(),
  );

  useEffect(() => {
    checkNewContent(pagination?.minCursor, feed);
  }, []);

  const loadMore = throttle(async ({ target: { scrollTop, scrollHeight } }) => {
    const fetchMoreMargin = scrollTop + window.innerHeight * 3;

    if (!isLoading && pagination?.hasMoreResults && fetchMoreMargin > scrollHeight) {
      const { data: fetchMoreResult } = await fetchMore({
        variables: {
          paginationOptions: {
            limit: NEXT_PATCH_SIZE,
            minCursor: pagination.maxCursor,
          },
        },
      });

      recordSignals({
        currentLimit: pagination.limit,
        items: feed.filter(isOpeningType).map(opening => ({
          id: opening.id,
          type: SignalEntityTypeEnum.OPENING,
        })),
        signalType: SignalTypeEnum.VIEW_FEED_ITEM,
      });

      const newResult = getFeed(fetchMoreResult);

      trackEvent({
        context: Context.FILLED,
        interaction: Interaction.FETCH_MORE,
        itemType: ItemType.FILLED_FEED,
        itemValue: size([...feed, ...newResult]),
      });
    }
  }, 200);

  useLayoutEffect(() => {
    getScrollableContainer().addEventListener('scroll', loadMore);

    return () => {
      getScrollableContainer().removeEventListener('scroll', loadMore);
      loadMore.cancel();
    };
  }, [isLoading, pagination]);

  return {
    feed: feed,
    isLoading: isFeedLoading,
    isRefetchLoading: isRefetchLoading,
  };
};
