import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import FadeInImage from '@src/components/FadeInImage';
import './styles.scss';

export const HeaderImage = ({ headerImage, logo }) => (
  <div className="opening-details-header-image">
    <div className="opening-details-header-picture">
      <FadeInImage src={get(headerImage, 'fullUrl')} />
    </div>

    <div className="opening-details-header-logo">
      <FadeInImage src={get(logo, 'fullUrl')} />
    </div>
  </div>
);

HeaderImage.propTypes = {
  headerImage: PropTypes.shape({
    fullUrl: PropTypes.string.isRequired,
  }),
  logo: PropTypes.shape({
    fullUrl: PropTypes.string.isRequired,
  }),
};
