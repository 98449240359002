import { ApolloLink } from '@apollo/client';
import { isPreviewMode } from '@src/util/mode';

export const controlLink: ApolloLink = new ApolloLink((operation, forward) => {
  if (isPreviewMode()) {
    return null;
  }

  return forward(operation);
});
