import React from 'react';
import classnames from 'classnames';
import { first, find } from 'lodash';
import Svg from 'svg-inline-react';
import FrExpandSvg from 'assets/images/fr-expand.svg';
import PropTypes from 'prop-types';
import { EXPLORE_FILTER, SEARCH_TAGS } from '@src/constants';
import { useSpecialitiesQuery } from '@src/graphql/generated';
import FilterModal from '../FilterModal';
import { normalizeSpecialitiesForFilter } from './util';
import { ALL_SPECIALITIES, QUERY_KEYS } from './constants';

const Specialities = ({ wrapperClassName, viewType, locationSearch, setModalContent, setFilters }) => {
  const { data, loading } = useSpecialitiesQuery();

  const normalizedSpecialities = normalizeSpecialitiesForFilter(locationSearch);

  if (loading) {
    return <div className="filters__item filters__item--loading pulse-fade" />;
  }

  const { specialities } = data;
  const selectedSpeciality = (find(specialities, { id: first(normalizedSpecialities).id }) || {}).name;

  return (
    <div
      className={classnames(
        'filters__item',
        `filters__${viewType}`,
        !!selectedSpeciality && `filters__${viewType}--active`,
        wrapperClassName,
      )}
      onClick={() =>
        setModalContent({
          content: (
            <FilterModal
              activeFilters={normalizedSpecialities}
              customData={[ALL_SPECIALITIES]}
              filter={EXPLORE_FILTER.SPECIALITIES}
              onSave={filters => setFilters(filters, SEARCH_TAGS.SPECIALITY)}
              queryKey={QUERY_KEYS.SPECIALITIES}
              subTitle="Choose one or more specialities"
              title="Filter by specialities"
            />
          ),
          height: 75,
        })
      }
    >
      <div className="filters__item-label-wrapper">
        <span className="filters__item-label">{selectedSpeciality || 'Specialities'}</span>
        {normalizedSpecialities.length > 1 && (
          <span className="filters__selection-amount">{`+${normalizedSpecialities.length - 1}`}</span>
        )}
      </div>

      {viewType === 'fixed-header' && <Svg className="filters__expand-icon" src={FrExpandSvg} />}
    </div>
  );
};

Specialities.propTypes = {
  locationSearch: PropTypes.string,
  setFilters: PropTypes.func.isRequired,
  setModalContent: PropTypes.func.isRequired,
  viewType: PropTypes.oneOf(['fixed-header', 'pill', 'label']),
  wrapperClassName: PropTypes.string,
};

export default Specialities;
