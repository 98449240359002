import React, { FC } from 'react';
import FrInfoSmall2Svg from 'assets/images/fr-Info-small-2.svg';
import { InvisibleButton } from '@src/components/Button';
import { VERIFIED_USER_ROUTE } from '@src/constants/routes';
import { RoundedIcon } from '../RoundedIcon';
import { Color } from '../RoundedIcon/roundedIcon.enum';
import { Section } from '../Section';
import { RejectedStateProps } from './props';
import './styles.scss';

export const RejectedState: FC<RejectedStateProps> = ({ isActionDisabled }) => {
  return (
    <div className="vt-rejected-state">
      <Section
        bottomSlot={<div className="vt-rejected-state__title">Not approved</div>}
        iconSlot={<RoundedIcon color={Color.PINK} src={FrInfoSmall2Svg} />}
        topSlot={<div className="vt-rejected-state__name">Your profile status</div>}
        wrapperClassName="vt-rejected-state__wrapper"
      />

      <InvisibleButton
        className="vt-rejected-state__button"
        disabled={isActionDisabled}
        isLink
        to={VERIFIED_USER_ROUTE}
      >
        See why
      </InvisibleButton>
    </div>
  );
};
