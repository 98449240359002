import React, { FC } from 'react';
import HalfArrowSvg from 'assets/images/half_arrow.svg';
import Svg from 'svg-inline-react';
import { Link } from 'react-router-dom';
import { COMPANY_ROUTE } from '@src/constants/routes';
import createLink from '@src/util/createLink';
import { ChatCompanyProps } from './props';
import './styles.scss';

export const ChatCompany: FC<ChatCompanyProps> = ({ title, companyId }) => {
  return (
    <Link
      className="chat-company-info"
      to={createLink(COMPANY_ROUTE, {
        params: { companyId },
      })}
    >
      <span>{title}</span>

      <Svg src={HalfArrowSvg} />
    </Link>
  );
};
