import { hasFields } from '@src/util/object/helpers';
import {
  GetPlaceAreaFragment,
  GetPlaceCountryFragment,
  GetPlaceLocationFragment,
  GetPlacesQuery,
} from '@src/graphql/generated';

export function isGetPlaceLocationFragmentType(
  obj: GetPlacesQuery['places']['result'][0]['entity'],
): obj is GetPlaceLocationFragment {
  return hasFields<GetPlaceLocationFragment>(obj, ['id', '__typename']) && obj.__typename === 'LocationType';
}

export function isGetPlaceCountryFragmentType(
  obj: GetPlacesQuery['places']['result'][0]['entity'],
): obj is GetPlaceCountryFragment {
  return hasFields<GetPlaceCountryFragment>(obj, ['id', '__typename']) && obj.__typename === 'CountryType';
}

export function isGetPlaceAreaFragmentType(
  obj: GetPlacesQuery['places']['result'][0]['entity'],
): obj is GetPlaceAreaFragment {
  return hasFields<GetPlaceAreaFragment>(obj, ['id', '__typename']) && obj.__typename === 'AreaType';
}
