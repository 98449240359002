import { HiringStateEnum } from '@src/graphql/generated';

export const getOpeningInformationalModalType = (hiringState: HiringStateEnum): string | undefined => {
  switch (hiringState) {
    case HiringStateEnum.CLOSED: {
      return 'inactive';
    }

    case HiringStateEnum.FILLED: {
      return 'position-filled';
    }
  }
};
