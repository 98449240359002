import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { isEmpty } from 'lodash';
import { SlideUpScroll } from '@src/components/SlideUpScroll';
import { HowWouldYouLikeToWorkList } from '@src/containers/HowWouldYouLikeToWorkList';
import { FixedHeader } from '@src/components/FixedHeader';
import { useAppDispatch } from '@src/store';
import { setNavigationVisibility } from '@src/redux/actions/navigation';
import { ActionButtons } from '../ActionButtons';
import './styles.scss';

function getJobOffersAmountText(jobOffersAmount) {
  const suffix = pluralize('job offer', jobOffersAmount);

  if (!jobOffersAmount) {
    return '';
  }

  if (jobOffersAmount > 1000) {
    return `1k+ ${suffix}`;
  }

  return `${jobOffersAmount} ${suffix}`;
}

export const HowWouldLikeWork = ({
  goBack,
  onNext,
  jobOffersAmount,
  loadingJobOffersAmount,
  title,
  onClear,
  selectedWorkOptions,
  activeLocation,
  activeCountries,
  activeAreas,
  onContentClick,
  onCheckBoxClick,
}) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setNavigationVisibility(false));

    return () => {
      dispatch(setNavigationVisibility(true));
    };
  }, []);

  return (
    <div className="how-would-like-work fade-in">
      <SlideUpScroll
        topSectionContent={
          <div className="how-would-like-work__top-section">
            <div className="how-would-like-work__title">
              <h1>{title}</h1>

              {loadingJobOffersAmount ? (
                <div className="how-would-like-work__subtitle-skeleton-wrapper pulse-fade">
                  <div className="how-would-like-work__subtitle-skeleton-item how-would-like-work__subtitle-skeleton-item--short" />
                  <div className="how-would-like-work__subtitle-skeleton-item how-would-like-work__subtitle-skeleton-item--long" />
                </div>
              ) : (
                <h4>{getJobOffersAmountText(jobOffersAmount)}</h4>
              )}
            </div>
          </div>
        }
      >
        <div>
          <FixedHeader
            disableOpacity
            onBack={goBack}
            title="How would you like to work?"
            wrapperClassName="how-would-like-work__header"
          />
          <div className="how-would-like-work__options-section">
            <HowWouldYouLikeToWorkList
              activeAreas={activeAreas}
              activeCountries={activeCountries}
              activeLocation={activeLocation ? [activeLocation] : []}
              activeOptionKeys={selectedWorkOptions}
              onCheckBoxClick={onCheckBoxClick}
              onContentClick={onContentClick}
            />
          </div>
          <div className="search-suggestions__footer">
            <ActionButtons
              blueButtonProps={{
                disabled: isEmpty(selectedWorkOptions),
                onClick: onNext,
              }}
              invisibleButtonProps={{
                onClick: onClear,
              }}
            />
          </div>
        </div>
      </SlideUpScroll>
    </div>
  );
};

HowWouldLikeWork.propTypes = {
  activeAreas: PropTypes.array,
  activeCountries: PropTypes.array,
  activeLocation: PropTypes.object,
  goBack: PropTypes.func.isRequired,
  jobOffersAmount: PropTypes.number.isRequired,
  loadingJobOffersAmount: PropTypes.bool.isRequired,
  onCheckBoxClick: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  onContentClick: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  selectedWorkOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired,
};
