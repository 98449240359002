import React, { Fragment, FC } from 'react';
import { throttle } from 'lodash';
import { NetworkStatus } from '@apollo/client';
import { FeedItemSkeleton } from '@src/containers/Feed/Skeleton';
import { JobOpeningFeedItem } from '@src/containers/Feed/JobOpeningFeedItem';
import {
  OpeningSearchEntitySortOptionEnum,
  SearchOpeningEntityTagNameEnum,
  useGetCompanySearchLatestOpeningsQuery,
} from '@src/graphql/generated';
import { Context } from '@src/metrics/enums/context.enum';
import { isOpeningType } from '@src/graphql/typeGuards';
import { JobOpeningsProps } from './props';
import './styles.scss';

export const JobOpenings: FC<JobOpeningsProps> = ({ companies }) => {
  const searchTags = {
    searchTagsWithAndCondition: [{ tag: SearchOpeningEntityTagNameEnum.STATE, value: 'active' }],
    searchTagsWithOrCondition: companies.map(({ id }) => ({ tag: SearchOpeningEntityTagNameEnum.COMPANY, value: id })),
  };

  const { data, fetchMore, networkStatus } = useGetCompanySearchLatestOpeningsQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      paginationOptions: {
        limit: 2,
        sortBy: OpeningSearchEntitySortOptionEnum.RECENCY,
      },
      searchParams: searchTags,
    },
  });

  const isFetchMoreLoading = networkStatus === NetworkStatus.fetchMore;

  const pagination = data?.searchOpenings.pagination;
  const openings = data?.searchOpenings.result || [];

  const loadMore = throttle(async () => {
    if (!isFetchMoreLoading && pagination?.hasMoreResults) {
      await fetchMore({
        variables: {
          paginationOptions: {
            limit: 6,
            minCursor: pagination.maxCursor,
          },
          searchTags: searchTags,
        },
      });
    }
  }, 200);

  return (
    <div className="article-job-openings">
      <h3 className={'article-job-openings__title'}>
        {companies?.map(({ profile: { name: companyName } }) => companyName).join(', ')}{' '}
        {openings.length > 0
          ? `${companies?.length > 1 ? 'are' : 'is'} hiring!`
          : `${companies?.length > 1 ? 'are' : 'is'} not hiring on MeetFrank at the moment…`}
      </h3>

      <Fragment>
        {openings.filter(isOpeningType).map(opening => {
          return <JobOpeningFeedItem key={opening.id} metricsContext={Context.ARTICLE} opening={opening} />;
        })}

        {isFetchMoreLoading && <FeedItemSkeleton />}
      </Fragment>

      {pagination?.hasMoreResults && (
        <div className="article-job-openings__load-more" onClick={loadMore}>
          Load more
        </div>
      )}
    </div>
  );
};
