import { ApolloLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/react';
import { ApolloErrorCode } from '@src/enum/apolloErrorCode.enum';
import { captureError } from '@src/util/errors';
import { refreshTokenAndRetry } from '../utils/refreshTokenAndRetry';
import { getIsAuthError } from '../utils/getIsAuthError';

export const errorLink: ApolloLink = onError(({ graphQLErrors, operation, forward }) => {
  if (graphQLErrors) {
    const isAuthError = getIsAuthError(graphQLErrors);

    if (isAuthError) {
      graphQLErrors?.forEach(error => {
        if (['jwt expired', 'jwt must be provided'].includes(error.message)) {
          captureError(error);
        }
      });

      return refreshTokenAndRetry(operation, forward);
    } else if (graphQLErrors.length) {
      if (
        (operation.operationName === 'updateUser' &&
          graphQLErrors[0]?.extensions?.code === ApolloErrorCode.RESOURCE_ALREADY_EXISTS) ||
        (operation.operationName === 'requestAuthCode' &&
          graphQLErrors[0]?.extensions?.code === ApolloErrorCode.FORBIDDEN_RESOURCE) ||
        (operation.operationName === 'verifyAuthCode' &&
          graphQLErrors[0]?.extensions?.code === ApolloErrorCode.FORBIDDEN_RESOURCE) ||
        (operation.operationName === 'marketInsights' &&
          graphQLErrors[0]?.extensions?.code === ApolloErrorCode.DATA_NOT_POPULATED)
      ) {
        return;
      }

      Sentry.withScope(scope => {
        scope.setTransactionName(operation.operationName);
        scope.setContext('apolloGraphQLOperation', {
          extensions: operation.extensions,
          operationName: operation.operationName,
          query: operation.query,
          variables: operation.variables,
        });

        graphQLErrors?.forEach(error => {
          Sentry.captureMessage(
            `[${operation.operationName}]: ${error.message}` || `[${operation.operationName}]: Missing error message`,
            {
              contexts: {
                apolloGraphQLError: {
                  error: error,
                },
              },
              level: 'error',
            },
          );
        });
      });
    }
  }
});
