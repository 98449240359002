import React, { FC, useEffect, useState } from 'react';
import { get, pick, isEmpty, isArray, debounce, size } from 'lodash';
import { isValidPhoneNumber } from 'libphonenumber-js';
import classNames from 'classnames';
import { Redirect, useHistory } from 'react-router-dom';
import { trackEvent } from '@src/metrics';
import { setNavigationVisibility } from '@src/redux/actions/navigation';
import {
  showBlackContainerStatusBar,
  showWhiteContainerStatusBar,
  setBodyBackGroundBlack,
  removeBodyBackGroundBlack,
} from '@src/container';
import { TypingLoader } from '@src/components/TypingLoader';
import ContentPush from '@src/components/ContentPush';
import {
  isValidLinkedInProfileUrl,
  isValidGithubProfileUrl,
  isValidGitlabProfileUrl,
  isValidBitbucketProfileUrl,
  isValidBehanceProfileUrl,
  isValidDribbbleProfileUrl,
  getLinkedInUserProfileNameFromUrl,
} from '@src/util/links';
import { isValidEmail } from '@src/util/email';
import { FRANK_ROUTE, VERIFIED_USER_QUESTIONNAIRE_ROUTE } from '@src/constants/routes';
import {
  CurrentUserQuery,
  useCountriesQuery,
  useCurrentUserQuery,
  UserProfileLinkTypeEnum,
  UserVerificationStateEnum,
  useSaveUserVerificationMutation,
  useSubmitUserVerificationMutation,
  useUpdateUserVerificationMutation,
  useUserVerificationStatusQuery,
} from '@src/graphql/generated';
import { useAppDispatch } from '@src/store';
import { Context } from '@src/metrics/enums/context.enum';
import { Interaction } from '@src/metrics/enums/interaction.enum';
import { ItemType } from '@src/metrics/enums/itemType.enum';
import { ApolloErrorCode } from '@src/enum/apolloErrorCode.enum';
import { useFlashMessagesContext } from '@src/context/FlashMessagesContext';
import { EditProfile } from './Profile/EditProfile';
import UserStatus from './UserStatus';
import { QUESTIONS } from './steps';
import {
  GetSaveUserVerificationValue,
  GetSaveUserVerificationValueParam,
} from './interfaces/getSaveUserVerificationParams.interface';
import './styles.scss';

const UPDATE_USER_INPUT_FIELDS = ['linkedInUrl', 'links', 'email', 'jobTypes'];

const REMOVE_DIAL_CODE_REGEX = /^\+[0-9]+\s?/;

const sendInvalidLinkedInMetric = debounce(value => {
  trackEvent({
    context: Context.VERIFY_USER,
    interaction: Interaction.LINKEDIN_INVALID,
    itemType: ItemType.URL,
    itemValue: value,
  });
}, 1000);

const getValidationMessage = (key, value, countries) => {
  switch (key) {
    case 'linkedInUrl':
      if (!getLinkedInUserProfileNameFromUrl(value) && value.length) {
        sendInvalidLinkedInMetric(value);

        return 'LinkedIn profile is invalid.';
      }

      break;
    case 'phone':
      const activeCountry = countries.find(({ id }) => id === value.country);
      const numberWithoutDialCode = value?.number?.trim().replace(REMOVE_DIAL_CODE_REGEX, '');
      const countryShortName = get(activeCountry, 'shortName', '');

      if (!countryShortName || countryShortName.length === 0) {
        return 'Dial code is missing';
      }

      if (
        !numberWithoutDialCode ||
        numberWithoutDialCode.length === 0 ||
        !isValidPhoneNumber(numberWithoutDialCode, countryShortName)
      ) {
        return 'Phone number is invalid';
      }

      break;
    case 'email':
      if (value.length && !isValidEmail(value)) {
        return 'Email is invalid.';
      }

      break;
  }
};

const getLinkType = (url: string): UserProfileLinkTypeEnum => {
  if (isValidLinkedInProfileUrl(url)) {
    return UserProfileLinkTypeEnum.LINKEDIN;
  } else if (isValidGithubProfileUrl(url)) {
    return UserProfileLinkTypeEnum.GITHUB;
  } else if (isValidGitlabProfileUrl(url)) {
    return UserProfileLinkTypeEnum.GITLAB;
  } else if (isValidBitbucketProfileUrl(url)) {
    return UserProfileLinkTypeEnum.BITBUCKET;
  } else if (isValidBehanceProfileUrl(url)) {
    return UserProfileLinkTypeEnum.BEHANCE;
  } else if (isValidDribbbleProfileUrl(url)) {
    return UserProfileLinkTypeEnum.DRIBBBLE;
  } else {
    return UserProfileLinkTypeEnum.OTHER;
  }
};

const getLinksForUserInput = (value = []) => {
  if (isArray(value)) {
    return value
      .filter(url => url && size(url))
      .map(url => {
        return {
          type: getLinkType(url),
          value: url,
        };
      });
  }

  return {
    type: getLinkType(value),
    value: value,
  };
};

const getJobTypes = value => {
  return {
    isLocal: !!value.find(it => it.key === 'local'),
    isRelocation: !!value.find(it => it.key === 'relocation'),
    isRemote: !!value.find(it => it.key === 'remote'),
  };
};

const getUserUpdateParams = (key, value) => {
  const activeKey = key === 'linkedInUrl' ? 'links' : key;
  let params = {};

  switch (activeKey) {
    case 'links':
      params = { [activeKey]: getLinksForUserInput(value) };
      break;
    case 'jobTypes':
      params = getJobTypes(value);
      break;

    default:
      params = { [activeKey]: value };
      break;
  }

  return params;
};

const getSaveUserVerificationParams = (
  userVerificationId: string,
  userVerificationState: UserVerificationStateEnum,
  key: string,
  value: GetSaveUserVerificationValueParam,
): GetSaveUserVerificationValue => {
  let activeValue: GetSaveUserVerificationValueParam | string = value;

  if (key === 'blacklistedCompanies') {
    activeValue = value.map(it => it.id);
  }

  if (key === 'canStartIn') {
    activeValue = value.key;
  }

  if (key === 'phone') {
    activeValue = {
      ...activeValue,
      number: activeValue.number.replace(REMOVE_DIAL_CODE_REGEX, ''),
    };
  }

  return !userVerificationId || userVerificationState === UserVerificationStateEnum.CANCELLED
    ? {
        verificationInput: {
          [key]: activeValue,
        },
      }
    : {
        verificationId: userVerificationId,
        verificationInput: {
          [key]: activeValue,
        },
      };
};

const areRequiredFieldsFilled = (user: CurrentUserQuery['currentUser']) => {
  const isLocal = user.profile.isLocal;
  const isRemote = user.profile.isRemote;
  const isRelocation = user.profile.isRelocation;
  const workExperience = user.verification?.workExperience;
  const positionExpectations = user.verification?.positionExpectations;
  const canStartIn = user.verification?.canStartIn;
  const phone = user?.phone?.number;
  const email = user.email;

  const linkedIn = user.profile.links.find(link => link.type === UserProfileLinkTypeEnum.LINKEDIN);

  if (
    (isLocal || isRemote || isRelocation) &&
    !!workExperience &&
    !!positionExpectations &&
    canStartIn &&
    phone &&
    email &&
    linkedIn &&
    linkedIn.value
  ) {
    return true;
  }

  return false;
};

export const VerifiedUser: FC = () => {
  const dispatch = useAppDispatch();

  const history = useHistory();

  const { setErrorFlashMessage } = useFlashMessagesContext();

  const [step, setStep] = useState(0);

  const [isMainLoading, setIsMainLoading] = useState(true);

  const [isEditMode, setIsEditMode] = useState(false);

  const [isProfileVisible, setIsProfileVisible] = useState(false);

  const [input, setInput] = useState();

  const [errorMessage, setErrorMessage] = useState<string | null>();

  const isLastStep = step === QUESTIONS.length - 1;
  const isFirstStep = step === 0;
  const activeKey = QUESTIONS[step].key;

  const { data: userVerificationStatusData } = useUserVerificationStatusQuery({
    fetchPolicy: 'network-only',
  });

  const canStartVerification = userVerificationStatusData?.userVerificationStatus.canStartVerification;

  const { data: countriesData, loading: isCountriesLoading } = useCountriesQuery();

  const {
    data: userData,
    loading: isUserLoading,
    refetch: refetchUser,
  } = useCurrentUserQuery({
    fetchPolicy: 'network-only',
  });

  const userVerificationId = userData?.currentUser?.verification?.id;
  const userCountryId = userData?.currentUser?.location?.country?.id;
  const userVerificationState = userData?.currentUser?.verification?.state;

  const moveForward = () => {
    if (!isLastStep && !isEditMode) {
      setStep(step + 1);
    } else {
      setIsProfileVisible(true);
      setIsEditMode(false);
    }

    setTimeout(() => {
      setIsMainLoading(false);
    }, 500);
  };

  const handleBack = () => {
    setIsMainLoading(true);

    !step ? history.goBack() : setStep(step - 1);

    setTimeout(() => {
      setIsMainLoading(false);
    }, 500);
  };

  const {
    data: verificationStatusData,
    loading: isVerificationStatusLoading,
    refetch: refetchVerificationStatus,
  } = useUserVerificationStatusQuery({
    fetchPolicy: 'network-only',
  });
  const [submitUserVerification] = useSubmitUserVerificationMutation({
    onCompleted: () => {
      refetchUser();

      refetchVerificationStatus();
    },
    onError: () => {
      setIsMainLoading(false);

      setErrorFlashMessage('Failed to submit verification');
    },
  });

  const [saveUserVerification, { loading: isSaveVerificationLoading }] = useSaveUserVerificationMutation({
    onCompleted: () => {
      refetchUser();

      moveForward();
    },
    onError: () => {
      setIsMainLoading(false);

      setErrorFlashMessage('Failed to save verification');
    },
  });

  const [updateUserVerification, { loading: isUpdateUserVerificationLoading }] = useUpdateUserVerificationMutation({
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      refetchUser();
      moveForward();
    },
    onError: error => {
      if (error?.graphQLErrors[0]?.extensions?.code === ApolloErrorCode.RESOURCE_ALREADY_EXISTS) {
        setIsMainLoading(false);

        setErrorMessage('Email is taken.');

        return;
      }

      setIsMainLoading(false);
    },
  });

  const handleContinue = async () => {
    setIsMainLoading(true);
    setErrorMessage(null);

    if (input && step >= 1) {
      let value = get(input, `${activeKey}`);

      if (activeKey === 'linkedInUrl') {
        value = [get(input, `${activeKey}`), ...get(input, 'links', [])];
      } else if (activeKey === 'links') {
        value = [get(input, 'linkedInUrl'), ...value];
      }

      let params = {};

      if (UPDATE_USER_INPUT_FIELDS.includes(activeKey)) {
        params = getUserUpdateParams(activeKey, value);

        if (!isEmpty(params)) {
          updateUserVerification({
            variables: {
              updateInput: params,
            },
          });
        } else {
          moveForward();
        }
      } else {
        params = getSaveUserVerificationParams(userVerificationId, userVerificationState, activeKey, value);

        // To save default value before move to canStartIn section.
        if (activeKey === 'achievements') {
          params.verificationInput.canStartIn = 'ONE_MONTH';
        }

        if (!isEmpty(params)) {
          saveUserVerification({
            variables: {
              ...params,
            },
          });
        } else {
          moveForward();
        }
      }
    } else {
      moveForward();
    }

    trackEvent({
      context: Context.VERIFY_USER,
      interaction: Interaction.APPLICATION,
      itemType: ItemType.STEP,
      itemValue: activeKey,
    });
  };

  const handleInputChange = (key, value) => {
    let newParam = {};

    switch (key) {
      case 'phone':
        newParam = {
          phone: {
            ...input.phone,
            number: value,
          },
        };
        break;
      case 'dialCode':
        newParam = {
          phone: {
            ...input.phone,
            country: value.id,
          },
        };
        break;
      default:
        newParam = { [key]: value };
        break;
    }

    setInput({ ...input, ...newParam });
  };

  const handleValueSelect = (key, value) => {
    if (key === 'jobTypes') {
      const selectedItem = value[key][0];
      const existingJobTypes = get(input, 'jobTypes') || [];
      const isItemExists = existingJobTypes.find(it => it.key === selectedItem.key);
      let result = [];

      if (isItemExists) {
        result = existingJobTypes.filter(it => it.key !== selectedItem.key);
      } else {
        result = [...existingJobTypes, selectedItem];
      }

      setInput({ ...input, jobTypes: result });
    } else if (key === 'canStartIn') {
      setInput({ ...input, [key]: value[key] });
    } else {
      setInput({ ...input, [key]: value });
    }
  };

  const publishUserVerification = () => {
    submitUserVerification({ variables: { verificationId: userVerificationId } });
  };

  const onEditField = key => {
    const activeStepIndex = QUESTIONS.findIndex(step => step.key === key);
    setIsEditMode(true);
    setIsProfileVisible(false);
    setStep(activeStepIndex);
  };

  const daysBetweenVerifications = get(verificationStatusData, 'userVerificationStatus.daysBetweenVerifications', 0);
  const countries = get(countriesData, 'countries.result', []);

  const isQueriesLoading = isUserLoading || isCountriesLoading || isVerificationStatusLoading;
  const isLoading =
    isMainLoading ||
    isUserLoading ||
    isVerificationStatusLoading ||
    isCountriesLoading ||
    isUpdateUserVerificationLoading ||
    isSaveVerificationLoading;

  const isAllRequiredFieldsFilled = !!userData && areRequiredFieldsFilled(userData?.currentUser);
  const isRejected = UserVerificationStateEnum.REJECTED === userVerificationState && !canStartVerification;

  const isEditProfileVisible =
    userVerificationState &&
    [UserVerificationStateEnum.DRAFT, UserVerificationStateEnum.PUBLISHED, UserVerificationStateEnum.PENDING].includes(
      userVerificationState,
    ) &&
    isProfileVisible &&
    isAllRequiredFieldsFilled &&
    !isQueriesLoading;

  const isVerificationFlowVisible =
    (isEditMode || !isProfileVisible || userVerificationState === UserVerificationStateEnum.CANCELLED) &&
    !isQueriesLoading &&
    !isRejected;

  useEffect(() => {
    setBodyBackGroundBlack();
    showWhiteContainerStatusBar();
    dispatch(setNavigationVisibility(false));

    trackEvent({
      context: Context.VERIFY_USER,
      interaction: Interaction.VIEW,
    });

    return () => {
      removeBodyBackGroundBlack();
      showBlackContainerStatusBar();
      dispatch(setNavigationVisibility(true));
    };
  }, []);

  useEffect(() => {
    const userVerificationState = get(userData, 'currentUser.verification.state');
    const userVerification = get(userData, 'currentUser.verification') || {};
    const userProfile = get(userData, 'currentUser.profile') || {};
    const filteredInput = pick(userVerification, [
      'phone',
      'workExperience',
      'positionExpectations',
      'jobSeekingActivity',
      'achievements',
    ]);

    filteredInput.workExperience = get(userVerification, 'workExperience') || null;

    // Attach default values.
    if (!filteredInput.phone) {
      filteredInput.phone = { country: userCountryId };
    } else {
      filteredInput.phone = {
        country: filteredInput.phone.country?.id,
        number: filteredInput.phone.number,
      };
    }

    const selectedJobTypes = [];

    if (userProfile.isRemote) {
      selectedJobTypes.push({ key: 'remote' });
    }

    if (userProfile.isRelocation) {
      selectedJobTypes.push({ key: 'relocation' });
    }

    if (userProfile.isLocal) {
      selectedJobTypes.push({ key: 'local' });
    }

    filteredInput.jobTypes = selectedJobTypes;

    filteredInput.blacklistedCompanies = [];

    if (!isEmpty(userVerification.blacklistedCompanies)) {
      filteredInput.blacklistedCompanies = userVerification.blacklistedCompanies.map(company => ({
        id: company.id,
        imageSrc: get(company, 'profile.logo.fullUrl'),
        title: get(company, 'profile.name'),
      }));
    }

    filteredInput.canStartIn = { key: userVerification.canStartIn };
    const linkedInUrl = (userProfile.links || []).find(link => link.type === 'LINKEDIN');
    filteredInput.linkedInUrl = linkedInUrl ? linkedInUrl.value : '';
    filteredInput.links = (userProfile.links || []).filter(link => link.type !== 'LINKEDIN').map(link => link.value);

    filteredInput.email = get(userData, 'currentUser.email');

    if (isEmpty(userVerification)) {
      setInput(filteredInput);
      setIsMainLoading(false);
    } else {
      setInput(filteredInput);
    }

    if (
      [
        UserVerificationStateEnum.DRAFT,
        UserVerificationStateEnum.PUBLISHED,
        UserVerificationStateEnum.PENDING,
      ].includes(userVerificationState) &&
      !!userData &&
      areRequiredFieldsFilled(userData.currentUser) &&
      isFirstStep
    ) {
      setIsProfileVisible(true);
    }
  }, [userData]);

  useEffect(() => {
    if (!isVerificationFlowVisible) {
      showBlackContainerStatusBar();
      removeBodyBackGroundBlack();
    } else {
      setBodyBackGroundBlack();
      showWhiteContainerStatusBar();
    }
  }, [isVerificationFlowVisible]);

  const shouldShowQuestionnaire = get(verificationStatusData, 'userVerificationStatus.shouldShowQuestionnaire', false);

  const message = get(userData, 'currentUser.verification.message');
  const selectedValue = get(input, `${activeKey}`) || '';

  const userSpecialityKey = userData?.currentUser.profile.speciality?.key;
  const doesUserHaveMidLevelOrUp = userData?.currentUser.profile.seniorities.some(({ key }) =>
    ['midlevel', 'senior', 'lead', 'executive'].includes(key),
  );
  const question = QUESTIONS[step];

  if (userSpecialityKey === 'design' && activeKey === 'links' && doesUserHaveMidLevelOrUp) {
    question.action.skippable = false;
    question.action.active = false;
    question.action.value = 'Continue';
    question.content = question.content.map(item => ({
      ...item,
      value: item.value.replace('(Optional)', ''),
    }));
  }

  const validationMessage = getValidationMessage(activeKey, selectedValue, countries);

  const hasErrorMessage = !!validationMessage;

  if (shouldShowQuestionnaire) {
    return <Redirect to={VERIFIED_USER_QUESTIONNAIRE_ROUTE} />;
  }

  return (
    <div
      className={classNames('verified-user', !isVerificationFlowVisible && !isRejected && 'verified-user--white-bg')}
    >
      {isQueriesLoading && <TypingLoader className="verified-user__main-loading" />}

      {isVerificationFlowVisible && (
        <ContentPush
          defaultValues={input}
          errorMessage={errorMessage || validationMessage}
          isDarkModeOn
          keepScrollTop
          loading={isLoading}
          onClose={() => history.goBack()}
          onContinue={handleContinue}
          onInputChange={handleInputChange}
          onValueSelect={value => handleValueSelect(activeKey, value)}
          question={QUESTIONS[step]}
          selectedValue={!hasErrorMessage && { [activeKey]: selectedValue }}
          sendBack={() => handleBack()}
          showHeader
          user={userData?.currentUser}
        />
      )}

      {isEditProfileVisible && (
        <EditProfile
          currentUser={get(userData, 'currentUser')}
          isLoading={isLoading}
          onClose={() => history.goBack()}
          onEditField={onEditField}
          onPublish={publishUserVerification}
          onVerifiedUserUpdate={prevState => {
            if (prevState === UserVerificationStateEnum.PENDING) {
              history.push(FRANK_ROUTE);
            } else {
              refetchUser();
            }
          }}
        />
      )}

      {isRejected && !isQueriesLoading && (
        <UserStatus
          daysBetweenVerifications={daysBetweenVerifications}
          goBack={() => history.goBack()}
          message={message}
          status={userVerificationState}
        />
      )}
    </div>
  );
};
