import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { trackEvent } from '@src/metrics';
import { FrankAvatar } from '@src/components/FrankAvatar';
import { BlueButton, InvisibleButton } from '@src/components/Button';
import './styles.scss';

export const VerifyTalentNotifyModal = ({ onContinue, onDismiss }) => {
  useEffect(() => {
    trackEvent({
      context: 'Verify talent modal',
      interaction: 'View',
    });
  }, []);

  return (
    <div className="verify-talent-notify-modal">
      <div className="verify-talent-notify-modal__content">
        <FrankAvatar className="verify-talent-notify-modal__frank-avatar" />
        <h2 className="verify-talent-notify-modal__title">Looking to get hired?</h2>
        <p className="verify-talent-notify-modal__subtitle">
          Increase your chances of getting a new job by letting companies apply to you. Extend your profile to activate
          MeetFrank Agent.
        </p>
      </div>

      <div className="verify-talent-notify-modal__actions">
        <BlueButton
          className="verify-talent-notify-modal__continue"
          isFilled
          onClick={() => {
            trackEvent({
              context: 'Verify Talent CTA Popup',
              interaction: 'Open Verify Talent',
            });
            onContinue();
          }}
        >
          Let's do this
        </BlueButton>
        <InvisibleButton
          onClick={() => {
            trackEvent({
              context: 'Verify Talent CTA Popup',
              interaction: 'Dismiss Verify Talent',
            });
            onDismiss();
          }}
        >
          Not now
        </InvisibleButton>
      </div>
    </div>
  );
};

VerifyTalentNotifyModal.propTypes = {
  onContinue: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
};
