import React, { FC, useEffect, useState } from 'react';
import SwiperInstance, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { GetMessageRequestsQuery, useGetUserQuery } from '@src/graphql/generated';
import { getFinancialDetailsCopy } from '@src/util/company';
import { countryFlagUrl } from '@src/util/countryFlagImageUrl';
import { MessagesActionButtons } from '@src/components/MessagesActionButtons';
import { AcceptedMessageOverlay } from '@src/components/AcceptedMessageOverlay';
import { CHAT_ROUTE, MESSAGE_REQUESTS_ROUTE } from '@src/constants';
import createLink from '@src/util/createLink';
import { useAppDispatch } from '@src/store';
import { setNavigationVisibility } from '@src/redux/actions/navigation';
import { SubPageAppBar } from '@src/components/PrimaryAppBar';
import dateStringFromDate from '@src/util/dateStringFromDate';
import { getWrapperScrollableContainer } from '@src/App';
import { Context } from '@src/metrics/enums/context.enum';
import { useMessageRequests } from '@src/graphql/chat/hooks/useMessageRequests';
import { Card } from './Card';
import { Skeleton } from './Card/Skeleton';
import './styles.scss';

export const MessageRequests: FC = () => {
  const dispatch = useAppDispatch();

  const history = useHistory();

  const { data: userData } = useGetUserQuery();

  const userName = userData?.currentUser.name;

  const match = useRouteMatch<{ messageId?: string }>({
    path: MESSAGE_REQUESTS_ROUTE,
    sensitive: true,
    strict: true,
  });

  const [slideIndex, setSlideIndex] = useState(0);

  const [swiperInstance, setSwiperInstance] = useState<SwiperInstance>();

  const [overlayData, setOverlayData] = useState<GetMessageRequestsQuery['messageRequests']['result'][0] | null>(null);

  const { handleAccept, handleDecline, isFetchMoreLoading, isInitialLoading, totalCount, messages, hasMoreResults } =
    useMessageRequests({
      context: Context.MESSAGE_REQUESTS,
      onAccept: messageRequest => setOverlayData(messageRequest),
      slideIndex: slideIndex,
    });

  const selectedMessageId = match?.params?.messageId;

  const selectedSlideIndex = messages.findIndex(message => message?.id === selectedMessageId);

  const onSlideChange = (index: number): void => {
    setSlideIndex(index);
  };

  useEffect(() => {
    if (selectedSlideIndex !== undefined && swiperInstance && !isInitialLoading) {
      swiperInstance.slideTo(selectedSlideIndex, 0);
    }
  }, [selectedSlideIndex, swiperInstance, isInitialLoading]);

  useEffect(() => {
    const getScrollableContainer = (): Element => {
      const wrapperScrollableContainer = getWrapperScrollableContainer();

      return wrapperScrollableContainer[0];
    };

    const scrollableContainer = getScrollableContainer();

    dispatch(setNavigationVisibility(false));

    if (scrollableContainer) {
      scrollableContainer.scrollTop = 0;
    }

    if (selectedSlideIndex > 0) {
      setSlideIndex(selectedSlideIndex);
    }

    return () => {
      dispatch(setNavigationVisibility(true));
    };
  }, []);

  return (
    <div className="message-requests">
      <SubPageAppBar
        isDefaultActionsHidden
        onBack={() => history.goBack()}
        wrapperClassName="message-requests__app-bar"
      >
        <div className="message-requests__app-bar-inner">
          <div className="message-requests__app-bar-title">Message requests</div>

          <div className="message-requests__app-bar-count">{totalCount ? `${slideIndex + 1}/${totalCount}` : ''}</div>
        </div>
      </SubPageAppBar>

      {isInitialLoading && (
        <div className="message-requests__loading-wrapper">
          <Skeleton />
        </div>
      )}

      {!isInitialLoading && !!messages.length && (
        <div className="message-requests__swiper-wrapper">
          <Swiper
            allowSlidePrev={!hasMoreResults}
            initialSlide={selectedSlideIndex}
            loop={true}
            modules={[Pagination]}
            onSlideChange={swiper => onSlideChange(swiper.realIndex)}
            pagination={{
              dynamicBullets: totalCount > 3,
              el: '.message-requests__pagination',
            }}
            onSwiper={swiper => {
              setSwiperInstance(swiper);
            }}
          >
            {messages.map(messageItem => {
              const recruiter = messageItem?.recruiter;
              const company = messageItem?.company;
              const recruiterTitle = recruiter?.title;
              const companyProfile = company?.profile;
              const industries = company?.industries;
              const office =
                companyProfile?.offices.find(office => office.isHeadquarter) || companyProfile?.offices?.[0];
              const companyName = companyProfile?.name;
              const officeImages = companyProfile?.officeImages;
              const headerImage = companyProfile?.headerImage;
              const images = officeImages?.length ? officeImages : [headerImage];

              const companyDescription =
                companyProfile?.missionStatement ||
                companyProfile?.serviceDescription ||
                companyProfile?.officeDescription;

              const financialDetails = getFinancialDetailsCopy(
                companyProfile?.latestValuation || undefined,
                companyProfile?.totalFunding,
                companyProfile?.revenue || undefined,
              );

              return (
                <SwiperSlide key={messageItem?.id}>
                  <div className="message-requests__item">
                    <Card
                      avatarColor={recruiter?.avatarColor || ''}
                      avatarUrl={recruiter?.avatarUrl || undefined}
                      badgeUrl={company?.profile.logo?.fullUrl}
                      companyDescription={companyDescription || undefined}
                      companyName={companyName || ''}
                      countryFlagUrl={office ? countryFlagUrl(office.location.country?.shortName) : undefined}
                      date={dateStringFromDate(messageItem?.latestMessage.sentAt)}
                      employeesAmount={companyProfile?.employeesCount || 0}
                      financialDetails={financialDetails}
                      headerSubtitle={`${recruiterTitle} at ${companyName}`}
                      imageUrl={images?.[0]?.fullUrl || ''}
                      industries={industries?.map(industry => industry.name).join(', ')}
                      message={messageItem?.latestMessage.text || ''}
                      nameInitials={recruiter?.nameInitials || recruiter?.fullName?.[0]}
                      openingTitle={messageItem?.opening?.title || ''}
                      recruiterName={recruiter?.fullName || ''}
                      userName={userName || ''}
                    />
                  </div>
                </SwiperSlide>
              );
            })}

            {isFetchMoreLoading && (
              <SwiperSlide key="loading">
                <div className="message-requests__item">
                  <Skeleton />
                </div>
              </SwiperSlide>
            )}
          </Swiper>
        </div>
      )}

      <AcceptedMessageOverlay
        chatLink={
          overlayData?.id
            ? createLink({
                link: CHAT_ROUTE,
                params: { threadId: overlayData?.id },
              })
            : undefined
        }
        isOpen={!!overlayData}
        onCancel={() => setOverlayData(null)}
        recruiterName={overlayData?.recruiter.fullName || ''}
        wrapperClassName="message-requests__overlay"
      />

      <div className="message-requests__footer">
        <MessagesActionButtons
          onAccept={async () => {
            if (messages?.length === 1) {
              await handleAccept(slideIndex);

              history.goBack();
            } else {
              await handleAccept(slideIndex);
            }
          }}
          onDecline={() => {
            if (messages?.length === 1) {
              handleDecline(slideIndex);
              history.goBack();
            } else {
              handleDecline(slideIndex);
            }
          }}
        />

        {!!messages.length && (
          <div className="message-requests__pagination-wrapper">
            <div className="message-requests__pagination" />
          </div>
        )}
      </div>
    </div>
  );
};
