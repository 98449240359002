import React, { useEffect, useRef, useState, Fragment, FC } from 'react';
import { get, isEmpty } from 'lodash';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { FixedHeader } from '@src/components/FixedHeader';
import { EmployeeCount } from '@src/components/EmployeeCount';
import { setNavigationVisibility } from '@src/redux/actions/navigation';
import FadeInImage from '@src/components/FadeInImage';
import { TypingLoader } from '@src/components/TypingLoader';
import { OutlinedButton } from '@src/components/Button';
import createLink from '@src/util/createLink';
import { setContent as setModalContent, closeModal } from '@src/redux/actions/slideUpModal';
import { trackEvent } from '@src/metrics';
import { COMPANY_ABOUT_ROUTE, COMPANY_REVIEWS_ROUTE, CREATE_COMPANY_REVIEW_ROUTE } from '@src/constants/routes';
import { useCompanyReviewsLazyQuery, useGetUserQuery } from '@src/graphql/generated';
import { useAppDispatch } from '@src/store';
import { Context } from '@src/metrics/enums/context.enum';
import { Interaction } from '@src/metrics/enums/interaction.enum';
import { ItemType } from '@src/metrics/enums/itemType.enum';
import { UserRating } from './UserRating';
import { ReviewsFeed } from './ReviewsFeed';
import { OverallScore } from './OverallScore';
import { ScoresTable } from './ScoresTable';
import { HowRateThisCompany } from './HowRateThisCompany';
import { Footer } from './Footer';
import { UserAssociationModal } from './UserAssociationModal';
import './styles.scss';

export const CompanyReviews: FC = () => {
  const dispatch = useAppDispatch();

  const match = useRouteMatch<{ companyId?: string }>({
    path: COMPANY_REVIEWS_ROUTE,
    sensitive: true,
    strict: true,
  });

  const history = useHistory();

  const companyId = match?.params.companyId;

  const [fetchCompanyReviews, { data, loading: isLoading }] = useCompanyReviewsLazyQuery();

  const verticalScroller = useRef(null);

  const [rating, setRating] = useState(0);

  const [isFeedEmpty, setIsFeedEmpty] = useState(true);

  const userAssociation = get(data, 'company.reviewsOverview.reviewForCurrentUser.userAssociation');

  const { data: userData } = useGetUserQuery();

  const user = userData?.currentUser;

  useEffect(() => {
    dispatch(setNavigationVisibility(false));

    trackEvent({
      context: Context.COMPANY_REVIEWS,
      interaction: Interaction.VIEW,
    });

    return () => {
      dispatch(setNavigationVisibility(true));
    };
  }, []);

  const handleCreateReview = (rating: number) => {
    trackEvent({
      context: Context.COMPANY_REVIEWS,
      interaction: Interaction.OPEN_USER_ASSOCIATION_MODAL,
    });

    dispatch(
      setModalContent({
        content: (
          <UserAssociationModal
            onClose={() => {
              dispatch(closeModal());

              setRating(0);
            }}
            onStatusClick={userAssociation => {
              if (!companyId) {
                return;
              }

              trackEvent({
                context: Context.COMPANY_REVIEWS,
                interaction: Interaction.CLICK_USER_ASSOCIATION,
                itemType: ItemType.USER_ASSOCIATION_MODAL,
                itemValue: userAssociation,
              });

              history.push(
                createLink({
                  link: CREATE_COMPANY_REVIEW_ROUTE,
                  params: { companyId },
                  queryParams: {
                    overallRating: rating.toString(),
                    userAssociation: userAssociation,
                  },
                }),
              );
            }}
          />
        ),
        height: 'auto',
      }),
    );
  };

  const handleUserReviewChange = () => {
    if (!companyId) {
      return;
    }

    trackEvent({
      context: Context.COMPANY_REVIEWS,
      interaction: Interaction.CLICK_CHANGE_REVIEW,
    });

    history.push(
      createLink({
        link: CREATE_COMPANY_REVIEW_ROUTE,
        params: { companyId: companyId },
        queryParams: { userAssociation },
      }),
    );
  };

  useEffect(() => {
    if (companyId) {
      fetchCompanyReviews({
        fetchPolicy: 'network-only',
        variables: { id: companyId },
      });
    }
  }, [companyId]);

  const currentEmploymentCompanyId = user?.profile?.currentEmployment?.company?.id;
  const companyLogo = get(data, 'company.profile.logo.fullUrl');
  const companyName = get(data, 'company.profile.name');
  const companyLocation = get(data, 'company.location.shortName');
  const companyRatings = get(data, 'company.reviewsOverview.ratings');
  const totalRating = get(data, 'company.reviewsOverview.ratings.total');
  const totalRatingCount = get(data, 'company.reviewsOverview.totalCount');
  const registeredEmployeesCount = get(data, 'company.registeredEmployeesCount') || 0;
  const currentUserReview = get(data, 'company.reviewsOverview.reviewForCurrentUser');
  const positiveFeedback = get(currentUserReview, 'positiveFeedback');
  const negativeFeedback = get(currentUserReview, 'negativeFeedback');
  const title = get(currentUserReview, 'title');

  const isCurrentEmployer = currentEmploymentCompanyId === companyId;
  const hasAddedReview = !!positiveFeedback || !!negativeFeedback || !!title;

  return (
    <div className="company-reviews content-enter-animation">
      <FixedHeader title={isCurrentEmployer ? 'Your employer' : 'Reviews'} wrapperClassName="company-reviews__header" />

      {isLoading ? (
        <TypingLoader />
      ) : (
        <>
          <div className="company-reviews__company">
            <FadeInImage
              className="company-reviews__company-logo"
              src={companyLogo}
              wrapperClassName="company-reviews__company-logo-wrapper"
            />

            <div className="company-reviews__company-details">
              <h3>{companyName}</h3>

              <p>{companyLocation}</p>
            </div>

            {companyId && (
              <Link
                to={createLink(COMPANY_ABOUT_ROUTE, {
                  params: { companyId },
                })}
                className="company-reviews__view-button"
              >
                View profile
              </Link>
            )}
          </div>

          <EmployeeCount companyName={companyName} count={registeredEmployeesCount} />

          <div className="company-reviews__body" ref={verticalScroller}>
            {isLoading ? (
              <TypingLoader />
            ) : (
              <>
                {totalRatingCount > 0 && (
                  <>
                    <OverallScore averageRating={totalRating} totalRatings={totalRatingCount} />

                    <ScoresTable ratings={companyRatings} />
                  </>
                )}
              </>
            )}

            {isEmpty(currentUserReview) && !window.isPublicApp && (
              <HowRateThisCompany
                onRatingSelect={rating => handleCreateReview(rating)}
                rating={rating}
                title={isCurrentEmployer ? 'How do you enjoy working there?' : 'How do you rate this company?'}
              />
            )}

            {!isEmpty(currentUserReview) && !window.isPublicApp && (
              <UserRating onChange={handleUserReviewChange} userRating={currentUserReview.ratings} />
            )}

            {!isEmpty(currentUserReview) && !window.isPublicApp && !hasAddedReview && (
              <div className="company-reviews__review-button-wrapper">
                <OutlinedButton
                  className="company-reviews__review-button"
                  color="gray"
                  onClick={() => handleCreateReview(rating)}
                >
                  Give a review
                </OutlinedButton>
              </div>
            )}

            <ReviewsFeed
              companyId={companyId}
              deepLinkUrl={'deepLinkUrl'}
              doesCurrentUserReviewExists={!isEmpty(currentUserReview)}
              isLoading={false}
              onCreateReview={handleCreateReview}
              scrollableContainer={verticalScroller}
              setIsFeedEmpty={setIsFeedEmpty}
            />
          </div>
        </>
      )}

      {!isFeedEmpty && isEmpty(currentUserReview) && !window.isPublicApp && (
        <Footer onLeaveAReview={handleCreateReview} />
      )}
    </div>
  );
};
