import qs from 'qs';
import { map, values } from 'lodash';

// Search tags for legacy search query.
const TYPE_TO_TAG = {
  companyLocation: 'COMPANY_OFFICE_COUNTRY',
  companyType: 'COMPANY_TYPE',
  industry: 'COMPANY_INDUSTRY',
  openingLocation: 'OPENING_POSITION_COUNTRY',
  openingState: 'OPENING_STATE',
  relocation: 'OPENING_RELOCATION',
  remote: 'OPENING_REMOTE',
  seniority: 'OPENING_SENIORITY',
  skill: 'OPENING_SKILL',
  track: 'OPENING_SPECIALITY',
};

// Search tags for allOffersFeedThreads query.
const EXPLORE_ALL_OFFERS_TYPE_TO_TAG = {
  companyLocation: 'COMPANY_OFFICE_COUNTRY',
  companyType: 'COMPANY_TYPE',
  industry: 'COMPANY_INDUSTRY',
  openingLocation: 'POSITION_COUNTRY',
  openingState: 'STATE',
  relocation: 'RELOCATION',
  remote: 'REMOTE',
  seniority: 'SENIORITY',
  skill: 'SKILL',
  track: 'SPECIALITY',
};

export const SEARCH_TAGS_FOR = {
  allOffersFeed: 'allOffersFeed',
  search: 'search',
};

export function getSearchTags(filters, tagsFor) {
  if (!filters || filters.length === 0) {
    return [];
  }

  let tag;

  switch (tagsFor) {
    case SEARCH_TAGS_FOR.search:
      tag = TYPE_TO_TAG;
      break;
    case SEARCH_TAGS_FOR.allOffersFeed:
      tag = EXPLORE_ALL_OFFERS_TYPE_TO_TAG;
      break;
    default:
      tag = TYPE_TO_TAG;
  }

  return map(filters, filter => {
    if (filter.type === 'relocation' || filter.id === 'relocation') {
      return {
        tag: tag.relocation,
        value: true,
      };
    }

    if (filter.type === 'remote' || filter.id === 'remote') {
      return {
        tag: tag.remote,
        value: true,
      };
    }

    return {
      tag: tag[filter.type],
      value: filter.id,
    };
  });
}

export function getFilters(location) {
  const filtersString = qs.parse(location.search, { ignoreQueryPrefix: true }).filters;
  return values(qs.parse(filtersString));
}
