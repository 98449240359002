import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import PhotoSwipe from './PhotoSwipe';
import 'photoswipe/dist/photoswipe.css';
import 'photoswipe/dist/default-skin/default-skin.css';
import './styles.scss';

const getThumbBoundsFn = (thumbnails, index) => {
  const thumbnail = thumbnails[index];

  if (!thumbnail) {
    return {};
  }

  const img = thumbnail.getElementsByTagName('img')[0];
  const pageYScroll = window.pageYOffset || document.documentElement.scrollTop;
  const rect = img.getBoundingClientRect();
  return { w: rect.width, x: rect.left, y: rect.top + pageYScroll };
};

const DEFAULT_OPTIONS = {
  arrowEl: false,
  captionEl: false,
  fullscreenEl: false,
  history: false,
  modal: true,
  shareEl: false,
  tapToClose: true,
};

export default class PhotoSwipeGallery extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    isOpen: PropTypes.bool,
    items: PropTypes.array.isRequired,
    onClose: PropTypes.func,
    options: PropTypes.object,
    renderThumbnails: PropTypes.func,
    thumbnailContent: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      options: {
        ...DEFAULT_OPTIONS,
        ...props.options,
      },
    };
  }

  static defaultProps = {
    isOpen: false,
    thumbnailContent: item => <img alt="" height="100" src={item.src} width="100" />,
  };

  thumbnails = [];

  componentWillReceiveProps(nextProps) {
    if (nextProps.options !== this.state.options) {
      this.setState({ options: { ...DEFAULT_OPTIONS, ...nextProps.options } });
    }
  }

  showPhotoSwipe = itemIndex => evt => {
    evt.preventDefault();

    const { options } = this.state;
    options.index = itemIndex;
    options.getThumbBoundsFn = getThumbBoundsFn.bind(getThumbBoundsFn, this.thumbnails);

    this.setState({ isOpen: true });
  };

  onClose = () => {
    const { onClose } = this.props;

    this.setState({ isOpen: false }, () => onClose && onClose());
  };

  setThumbnailRef = (index, ref) => {
    this.thumbnails[index] = ref;
  };

  render() {
    const { items, thumbnailContent, className, renderThumbnails, ...props } = this.props;
    const { isOpen, options } = this.state;

    return (
      <div className={classnames('pswp-gallery', className)}>
        {renderThumbnails ? (
          renderThumbnails(items, this.showPhotoSwipe, this.setThumbnailRef)
        ) : (
          <div className="pswp-thumbnails">
            {items.map((item, index) => (
              <div
                className="pswp-thumbnail"
                key={index}
                onClick={this.showPhotoSwipe(index)}
                ref={ref => this.setThumbnailRef(index, ref)}
              >
                {thumbnailContent(item)}
              </div>
            ))}
          </div>
        )}

        <PhotoSwipe {...props} isOpen={isOpen} items={items} onClose={this.onClose} options={options} />
      </div>
    );
  }
}
