import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { range } from 'lodash';

export const Skeleton = ({ imageSize, loadingCardsAmount }) => {
  return (
    <Fragment>
      {range(0, loadingCardsAmount).map(item => (
        <div className="horizontal-card horizontal-card--loading pulse-fade" key={item}>
          <div
            className={classnames(
              'horizontal-card__image horizontal-card__image--skeleton',
              imageSize && `horizontal-card__image--${imageSize}`,
            )}
          />
          <div className="horizontal-card__info">
            <h3 />
            <p />
          </div>
        </div>
      ))}
    </Fragment>
  );
};

Skeleton.defaultProps = {
  imageSize: 'small',
  loadingCardsAmount: 1,
};

Skeleton.propTypes = {
  imageSize: PropTypes.oneOf(['small', 'medium']),
  loadingCardsAmount: PropTypes.number,
};
