import { useEffect } from 'react';
import { throttle } from 'lodash';
import { GetTopReviewedCompaniesQuery, useGetTopReviewedCompaniesQuery } from '@src/graphql/generated';
import { ALL_PLACE_ID } from '@src/constants/placeIds';
import { handleFetchMore } from '@src/util/handleFetchMore';
import { trackEvent } from '@src/metrics';
import { Context } from '@src/metrics/enums/context.enum';
import { Interaction } from '@src/metrics/enums/interaction.enum';
import { ItemType } from '@src/metrics/enums/itemType.enum';
import { UseTopCompaniesParams, UseTopCompaniesValue } from './interfaces/useTopCompanies.interface';

const FIRST_PATCH_SIZE = 12;
const NEXT_PATCH_SIZE = 6;

export const useTopCompanies = ({
  activeCountryId,
  scrollableContainer,
}: UseTopCompaniesParams): UseTopCompaniesValue => {
  const {
    data: topReviewedCompaniesData,
    loading: isTopReviewedCompaniesLoading,
    fetchMore: fetchMoreReviewedCompanies,
  } = useGetTopReviewedCompaniesQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      filterInput: {
        countryIds: activeCountryId && activeCountryId !== ALL_PLACE_ID ? [activeCountryId] : undefined,
      },
      paginationOptions: {
        limit: FIRST_PATCH_SIZE,
      },
    },
  });

  const companies =
    topReviewedCompaniesData?.topReviewedCompanies?.result.filter(
      (
        company,
      ): company is Extract<
        GetTopReviewedCompaniesQuery['topReviewedCompanies']['result'][0],
        { __typename?: 'CompanyType' }
      > => company !== null,
    ) || [];
  const pagination = topReviewedCompaniesData?.topReviewedCompanies?.pagination;

  const loadMore = throttle(({ target: { scrollTop, scrollHeight } }) => {
    const fetchMoreMargin = scrollTop + window.innerHeight * 3;

    if (!isTopReviewedCompaniesLoading && pagination?.hasMoreResults && fetchMoreMargin > scrollHeight) {
      handleFetchMore(fetchMoreReviewedCompanies, {
        updateQuery: (
          prev: GetTopReviewedCompaniesQuery,
          { fetchMoreResult }: { fetchMoreResult: GetTopReviewedCompaniesQuery },
        ) => {
          if (!fetchMoreResult) {
            return prev;
          }

          const prevReviews = prev.topReviewedCompanies;
          const newResult = fetchMoreResult.topReviewedCompanies.result;
          const pagination = fetchMoreResult.topReviewedCompanies.pagination;

          const result = [...prevReviews.result, ...newResult];

          trackEvent({
            context: Context.TOP_COMPANIES,
            interaction: Interaction.FETCH_MORE,
            itemType: ItemType.TOP_COMPANIES_FEED,
            itemValue: result.length,
          });

          return {
            ...prev,
            topReviewedCompanies: {
              ...prevReviews,
              pagination: {
                // Do not update minCursor, because we use it as a initial minCursor to detect feed update on page mount.
                ...prevReviews.pagination,
                hasMoreResults: pagination.hasMoreResults,
                limit: pagination.limit,
                maxCursor: pagination.maxCursor,
              },
              result: result,
            },
          };
        },
        variables: {
          paginationOptions: {
            limit: NEXT_PATCH_SIZE,
            minCursor: pagination.maxCursor,
          },
        },
      });
    }
  }, 200);

  useEffect(() => {
    if (scrollableContainer) {
      scrollableContainer.addEventListener('scroll', loadMore);
    }

    return () => {
      if (scrollableContainer) {
        scrollableContainer.removeEventListener('scroll', loadMore);
      }

      loadMore.cancel();
    };
  }, [isTopReviewedCompaniesLoading, pagination]);

  return {
    companies: companies,
    isTopReviewedCompaniesLoading: isTopReviewedCompaniesLoading,
  };
};
