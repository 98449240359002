import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

export const PageTitleWithOgTags = ({ title, message, imageUrl }) => {
  return (
    <Helmet>
      <title>{`${title} | MeetFrank`}</title>
      <meta content={`${title} | MeetFrank`} property="og:title" />
      <meta content="website" property="og:type" />
      <meta content={window.location.href} property="og:url" />
      <meta content={message} property="og:description" />
      <meta content={imageUrl || 'https://static-media.meetfrank.com/images/og/feature.jpg'} property="og:image" />
      <meta content="1200" property="og:image:width" />
      <meta content="630" property="og:image:height" />
    </Helmet>
  );
};

PageTitleWithOgTags.propTypes = {
  imageUrl: PropTypes.string,
  message: PropTypes.string,
  title: PropTypes.string,
};
