export const fetchData = (url, options = {}) =>
  fetch(url, {
    body: JSON.stringify(options.json),
    headers: {
      'Content-Type': 'application/json',
    },
    method: options.method,
  }).then(response => response.json());

fetchData.get = (url, json) => fetchData(url, { json, method: 'GET' });
fetchData.post = (url, json) => fetchData(url, { json, method: 'POST' });
fetchData.put = (url, json) => fetchData(url, { json, method: 'PUT' });
fetchData.patch = (url, json) => fetchData(url, { json, method: 'PATCH' });
fetchData.delete = (url, json) => fetchData(url, { json, method: 'DELETE' });
