import React from 'react';
import './styles.scss';

export default function OpeningFeedItemLoading() {
  return (
    <div className="notification-loading">
      <div className="notification-loading__avatar pulse-fade" />
      <div className="notification__content">
        <div className="notification-loading__content-line pulse-fade" />
        <div className="notification-loading__content-line pulse-fade" />
        <div className="notification-loading__content-line pulse-fade" />
      </div>
    </div>
  );
}
