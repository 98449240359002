import React, { PureComponent } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { trim } from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import './styles.scss';

export default class ChatTextarea extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    defaultValue: PropTypes.string,
    disabled: PropTypes.bool,
    maxRows: PropTypes.number,
    minRows: PropTypes.number,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    textLimit: PropTypes.number,
  };

  state = {
    limit: 0,
    value: this.props.defaultValue || '',
  };

  onChange = evt => {
    const value = evt.target.value;
    this.setState({ value }, () => this.props.onChange(value));
  };

  setValue = value => {
    this.setState({ value }, () => this.props.onChange(value));
  };

  render() {
    const { className, placeholder, maxRows, minRows, disabled, textLimit } = this.props;
    const { value } = this.state;
    const valueLength = value ? trim(value).length : 0;

    return (
      <div className="chat-textarea-wrapper">
        <TextareaAutosize
          className={classnames('chat-textarea-wrapper__input', className)}
          disabled={disabled}
          maxRows={maxRows}
          minRows={minRows}
          onChange={this.onChange}
          placeholder={placeholder}
          value={value}
        />
        {textLimit && (
          <div className="chat-textarea-wrapper__text-limit-message">{`Minimum ${textLimit} characters. ${valueLength}/${textLimit}`}</div>
        )}
      </div>
    );
  }
}
