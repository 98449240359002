import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export const CompanyThumbnailCardSkeleton = ({ wrapperClassName }) => {
  return (
    <div className={classnames('company-thumbnail-card company-thumbnail-card--skeleton pulse-fade', wrapperClassName)}>
      <div className="company-thumbnail-card__logo" />
    </div>
  );
};

CompanyThumbnailCardSkeleton.propTypes = {
  wrapperClassName: PropTypes.string,
};
