import React, { FC } from 'react';
import './styles.scss';

export const Title: FC = () => {
  return (
    <div className="frank-vt-title">
      <div className="frank-vt-title__top">Your personal</div>
      <div className="frank-vt-title__cindie">
        care<span>e</span>r <br />
        assi<span>s</span>tant
      </div>
    </div>
  );
};
