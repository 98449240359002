import { CompanyReviewsQuery } from '@src/graphql/generated';
import { hasFields } from '@src/util/object/helpers';

type CompanyReviewsQueryRatingReviewType = Extract<
  CompanyReviewsQuery['company']['reviewsOverview']['reviewForCurrentUser'],
  {__typename?: 'CompanyRatingReviewType'}
>

export function isCompanyRatingReviewType (
  obj: CompanyReviewsQuery['company']['reviewsOverview']['reviewForCurrentUser'],
): obj is CompanyReviewsQueryRatingReviewType {
  return hasFields<CompanyReviewsQueryRatingReviewType>(obj, ['__typename']) &&
    obj.__typename === 'CompanyRatingReviewType';
}
