import React from 'react';
import PropTypes from 'prop-types';
import { BlueButton, InvisibleButton } from '@src/components/Button';
import './styles.scss';

export const ActionButtons = ({ invisibleButtonProps, invisibleButtonLabel, blueButtonProps, blueButtonLabel }) => {
  return (
    <div className="search-action-buttons">
      <InvisibleButton className="search-action-buttons__invisible-button" {...invisibleButtonProps}>
        {invisibleButtonLabel}
      </InvisibleButton>

      <BlueButton className="search-action-buttons__blue-button" isFilled {...blueButtonProps}>
        {blueButtonLabel}
      </BlueButton>
    </div>
  );
};

ActionButtons.defaultProps = {
  blueButtonLabel: 'Next',
  invisibleButtonLabel: 'Clear',
};

ActionButtons.propTypes = {
  blueButtonLabel: PropTypes.string.isRequired,
  blueButtonProps: PropTypes.object.isRequired,
  invisibleButtonLabel: PropTypes.string.isRequired,
  invisibleButtonProps: PropTypes.object.isRequired,
};
