import { useEffect, useRef } from 'react';
import { NetworkStatus } from '@apollo/client';
import {
  GetMessageRequestsQuery,
  UserApplicationStateEnum,
  useGetMessageRequestsQuery,
  useInboxAcceptApplicationMutation,
  useInboxMessageThreadUpdatedSubscription,
  useInboxRejectApplicationMutation,
} from '@src/graphql/generated';
import { trackEvent } from '@src/metrics';
import { Interaction } from '@src/metrics/enums/interaction.enum';
import { ItemType } from '@src/metrics/enums/itemType.enum';
import { usePrevious } from '@src/customHooks';
import { useFlashMessagesContext } from '@src/context/FlashMessagesContext';
import { UseMessageRequestsParams, UseMessageRequestsValue } from './useMessageRequests.interface';

const FIRST_BATCH_SIZE = 5;
const NEXT_BATCH_SIZE = 6;

export const useMessageRequests = ({
  slideIndex,
  context,
  onAccept,
}: UseMessageRequestsParams): UseMessageRequestsValue => {
  const { setErrorFlashMessage } = useFlashMessagesContext();

  const prevSlideIndex = usePrevious(slideIndex);

  const isDataLoaded = useRef<boolean>(false);

  const {
    data,
    loading: isLoading,
    fetchMore,
    refetch: refetchMessageRequests,
    networkStatus,
    updateQuery,
  } = useGetMessageRequestsQuery({
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      if (!isDataLoaded.current) {
        trackEvent({
          context: context,
          interaction: Interaction.LOADED,
          itemType: ItemType.MESSAGE_REQUESTS,
          itemValue: data.messageRequests.pagination.totalCount,
        });

        isDataLoaded.current = true;
      }
    },
    variables: {
      paginationOptions: {
        limit: FIRST_BATCH_SIZE,
      },
    },
  });

  const isInitialLoading = [NetworkStatus.loading, NetworkStatus.setVariables].includes(networkStatus);
  const isFetchMoreLoading = NetworkStatus.fetchMore === networkStatus;
  const pagination = data?.messageRequests.pagination;
  const hasMoreResults = !!pagination?.hasMoreResults;
  const messages = data?.messageRequests.result || [];
  const totalCount = pagination?.totalCount || 0;

  const [acceptApplication] = useInboxAcceptApplicationMutation({
    onError: () => {
      setErrorFlashMessage('Failed to accept invitation');
    },
  });

  const [rejectApplication] = useInboxRejectApplicationMutation({
    onError: () => {
      setErrorFlashMessage('Failed to reject invitation');
    },
  });

  const removeMessage = (id: string) => {
    updateQuery(data => {
      return {
        ...data,
        messageRequests: {
          ...data.messageRequests,
          pagination: {
            ...data.messageRequests.pagination,
            totalCount: data.messageRequests.pagination.totalCount - 1,
          },
          result: data.messageRequests.result.filter(message => message?.id !== id),
        },
      };
    });
  };

  const handleAccept = async (index: number): Promise<void> => {
    const messageRequest: GetMessageRequestsQuery['messageRequests']['result'][0] = messages[index];

    if (!messageRequest?.application?.id || !messageRequest.id) {
      return;
    }

    await acceptApplication({
      variables: {
        applicationId: messageRequest.application.id,
      },
    });

    removeMessage(messageRequest.id);

    onAccept(messageRequest);

    trackEvent({
      context: context,
      interaction: Interaction.ACCEPT,
      itemType: ItemType.MESSAGE_REQUEST,
      itemValue: messageRequest?.id,
      payload: {
        'Request Sent At': messageRequest.latestMessage.sentAt,
        'Request Sent By Company': messageRequest.company.id,
      },
    });
  };

  const handleDecline = async (index: number): Promise<void> => {
    const messageRequest: GetMessageRequestsQuery['messageRequests']['result'][0] = messages[index];

    if (!messageRequest?.application?.id || !messageRequest.id) {
      return;
    }

    await rejectApplication({
      variables: {
        applicationId: messageRequest.application.id,
      },
    });

    removeMessage(messageRequest.id);

    trackEvent({
      context: context,
      interaction: Interaction.DECLINE,
      itemType: ItemType.MESSAGE_REQUEST,
      itemValue: messageRequest?.id,
      payload: {
        'Request Sent At': messageRequest.latestMessage.sentAt,
        'Request Sent By Company': messageRequest.company.id,
      },
    });
  };

  useInboxMessageThreadUpdatedSubscription({
    onSubscriptionData: ({ subscriptionData }) => {
      const userState = subscriptionData.data?.messageThreadUpdated.updatedMessageThread.application?.userState;

      if (userState?.state === UserApplicationStateEnum.PENDING) {
        refetchMessageRequests();
      }
    },
  });

  useEffect(() => {
    const loadMore = async () => {
      await fetchMore({
        variables: {
          paginationOptions: {
            limit: NEXT_BATCH_SIZE,
            minCursor: pagination?.maxCursor,
          },
        },
      });
    };

    if (!isLoading && messages.length - 5 < slideIndex && pagination?.hasMoreResults) {
      loadMore();
    }
  }, [slideIndex, messages, pagination, isLoading]);

  useEffect(() => {
    if (isDataLoaded.current && typeof prevSlideIndex === 'number' && prevSlideIndex !== slideIndex) {
      trackEvent({
        context: context,
        interaction: Interaction.SWIPE,
        itemType: ItemType.INDEX,
        itemValue: slideIndex,
      });
    }
  }, [slideIndex, prevSlideIndex]);

  return {
    handleAccept: handleAccept,
    handleDecline: handleDecline,
    hasMoreResults: hasMoreResults,
    isFetchMoreLoading: isFetchMoreLoading,
    isInitialLoading: isInitialLoading,
    messages: messages,
    totalCount: totalCount,
  };
};
