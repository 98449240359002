import React, { FC, useEffect, useState, useRef, useMemo } from 'react';
import { isEmpty, size, trim } from 'lodash';
import CloseSvg from 'assets/images/fr-close.svg';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';
import { trackEvent } from '@src/metrics';
import { Rating } from '@src/components/Rating';
import { FixedHeader } from '@src/components/FixedHeader';
import { SearchInputWithSuggestions } from '@src/components/SearchInputWithSuggestions';
import createLink from '@src/util/createLink';
import { CREATE_COMPANY_REVIEW_ROUTE } from '@src/constants/routes';
import { useAppDispatch } from '@src/store';
import {
  UserCompanyAssociationTypeEnum,
  useUpdateUserMutation,
  useGetUserCurrentEmploymentQuery,
  UserEmploymentStatusEnum,
} from '@src/graphql/generated';
import { RatingColor } from '@src/components/Rating/enums/ratingColor.enum';
import { RatingSize } from '@src/components/Rating/enums/ratingSize.enum';
import { Context } from '@src/metrics/enums/context.enum';
import { Interaction } from '@src/metrics/enums/interaction.enum';
import { setContent as setModalContent, closeModal } from '@src/redux/actions/slideUpModal';
import { ConfirmationModal } from '@src/components/ConfirmationModal';
import { useQueryParams } from '@src/customHooks/useQueryParams';
import { useSearch } from './hooks/useSearch';
import { QueryParams } from './interfaces/queryParams.interface';
import './styles.scss';

export const FindYourCompany: FC = () => {
  const dispatch = useAppDispatch();

  const history = useHistory();

  const suggestionsWrapperRef = useRef<HTMLDivElement | null>(null);

  const { queryParams } = useQueryParams<QueryParams>();

  const { overallRating } = queryParams;

  const [activeRating, setActiveRating] = useState(overallRating ? parseInt(overallRating) : undefined);

  const [searchPattern, setSearchPattern] = useState('');

  const { result, loading: isLoading } = useSearch(searchPattern, suggestionsWrapperRef, {
    limit: 15,
  });

  const { data: userData, loading: isUserDataLoading } = useGetUserCurrentEmploymentQuery({
    fetchPolicy: 'network-only',
  });

  const [updateUser, { loading: isUpdateUserLoading }] = useUpdateUserMutation();

  const suggestions = useMemo(() => {
    if (!isEmpty(result)) {
      return result.map(suggestion => ({
        id: suggestion?.entity.id,
        imageSrc: suggestion?.entity.profile.logo?.fullUrl,
        title: suggestion?.entity.profile.name,
      }));
    }

    return [];
  }, [result]);

  useEffect(() => {
    trackEvent({
      context: Context.FIND_YOUR_COMPANY,
      interaction: Interaction.VIEW,
    });
  }, []);

  const handleSuggestionSelect = item => {
    trackEvent({
      context: Context.FIND_YOUR_COMPANY,
      interaction: Interaction.CLICK_COMPANY_ITEM,
      itemValue: item.name,
    });

    history.push(
      createLink({
        link: CREATE_COMPANY_REVIEW_ROUTE,
        params: { companyId: item.id },
        queryParams: {
          overallRating: activeRating,
          userAssociation: UserCompanyAssociationTypeEnum.WORKING,
        },
      }),
    );
  };

  const onUserUpdate = () => {
    updateUser({
      variables: {
        updateInput: {
          currentEmployment: {
            status: UserEmploymentStatusEnum.UNEMPLOYED,
          },
        },
      },
    });

    dispatch(closeModal());
  };

  const onCurrentEmployerRemove = () => {
    dispatch(
      setModalContent({
        content: (
          <ConfirmationModal
            cancelLabel="Cancel"
            confirmLabel="Delete"
            onCancel={() => dispatch(closeModal())}
            onConfirm={onUserUpdate}
            title="Are you sure?"
          />
        ),
        height: 'auto',
      }),
    );
  };

  const isSearchPatternSet = size(trim(searchPattern)) > 3;
  const isSearchResultEmpty = !isLoading && isEmpty(result) && isSearchPatternSet;

  const isUnemployed = userData?.currentUser.profile?.currentEmployment?.status === UserEmploymentStatusEnum.UNEMPLOYED;

  return (
    <div className="find-your-company content-enter-animation">
      <FixedHeader
        backButtonSvg={CloseSvg}
        close
        onBack={() => history.goBack()}
        title="Find your employer"
        wrapperClassName="find-your-company__header"
      />

      <div className="find-your-company__body">
        <div className="find-your-company__top-wrapper">
          <Rating
            activeColor={RatingColor.BLUE}
            activeRating={activeRating || 0}
            maxRating={5}
            setActiveRating={rating => setActiveRating(rating.rating)}
            shouldHaveBackground
            size={RatingSize.LARGE}
            wrapperClassName="find-your-company__rating"
          />

          <p className="find-your-company__message">You can make your rating anonymous</p>

          <div className="find-your-company__search-wrapper">
            <SearchInputWithSuggestions
              inputClassName="find-your-company__input"
              loading={isLoading}
              onChange={value => setSearchPattern(value)}
              onSuggestionSelect={handleSuggestionSelect}
              placeholder="Search companies"
              suggestions={suggestions}
              suggestionsWrapperRef={suggestionsWrapperRef}
              withSearchIcon
              wrapperClassName="find-your-company__input-wrapper"
            />

            {isSearchResultEmpty && (
              <div className="find-your-company__no-result-wrapper">
                <p className="find-your-company__search-message">
                  We can't find this company at the moment. Our team will check if we can add {searchPattern}.
                  Meanwhile, try searching for another company.
                </p>
              </div>
            )}
          </div>
        </div>

        {!isUnemployed && !isUserDataLoading && searchPattern.trim().length === 0 && (
          <div className="find-your-company__current-employer-wrapper fade-in">
            <p className="find-your-company__current-employer">
              Your current employer is <span>{userData?.currentUser.profile?.currentEmployment?.company?.name} </span>
            </p>
            <h3
              className={classnames(
                'find-your-company__remove-current-employer',
                isUpdateUserLoading && 'find-your-company__current-employer-wrapper--disable',
              )}
              onClick={onCurrentEmployerRemove}
            >
              Remove current employer
            </h3>
          </div>
        )}
      </div>
    </div>
  );
};
