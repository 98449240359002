import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import './styles.scss';

export default function Textarea({
  className,
  placeholder,
  onChange,
  onBlur,
  onFocus,
  onClick,
  onTouchStart,
  onTouchEnd,
  defaultValue,
  value,
  maxRows,
  inputRef,
}) {
  return (
    <TextareaAutosize
      className={classnames('frank-textarea', className)}
      defaultValue={defaultValue}
      inputRef={tag => {
        if (inputRef) {
          inputRef.current = tag;
        }
      }}
      maxRows={maxRows}
      onBlur={evt => onBlur && onBlur(evt)}
      onChange={evt => onChange && onChange(evt)}
      onClick={evt => onClick && onClick(evt)}
      onFocus={evt => onFocus && onFocus(evt)}
      onTouchEnd={evt => onTouchEnd && onTouchEnd(evt)}
      onTouchStart={evt => onTouchStart && onTouchStart(evt)}
      placeholder={placeholder}
      value={value}
    />
  );
}

Textarea.defaultProps = {
  autoFocus: false,
};

Textarea.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  inputRef: PropTypes.object,
  maxRows: PropTypes.number,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onTouchEnd: PropTypes.func,
  onTouchStart: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};
