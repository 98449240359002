import moment from 'moment-timezone';

export default function partOfDay() {
  const splitAfternoon = 12;
  const splitEvening = 17;
  const splitNight = 21;
  const splitMorning = 8;
  const currentHour = parseFloat(moment().format('HH'));

  if (currentHour >= splitAfternoon && currentHour <= splitEvening) {
    return 'afternoon';
  } else if (currentHour >= splitEvening && currentHour <= splitNight) {
    return 'evening';
  } else if (currentHour >= splitNight || currentHour <= splitMorning) {
    return 'night';
  } else if (currentHour >= splitMorning) {
    return 'sunrise';
  } else {
    return 'morning';
  }
}
