import { orderBy, some, isEmpty } from 'lodash';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import Input from '@src/components/Input';
import { RenderOnScroll } from '@src/components/RenderOnScroll';
import { SelectItem, SelectItemLoading } from '@src/components/SelectItem';
import { createCountryItem } from '@src/util/filters';
import { CountryType, useCountriesQuery } from '@src/graphql/generated';
import { ApplySearchFilter } from './interfaces/applySearchFilter.interface';
import { Props } from './props';
import './styles.scss';

export const CountriesList: FC<Props> = ({ activeCountries, onCountrySelect }) => {
  const [searchInput, setSearchInput] = useState('');
  const [countries, setCountries] = useState<CountryType[]>();
  const scrollableRef = useRef<HTMLDivElement>(null);
  const isMountedRef = useRef(false);

  const { data, loading: isLoading } = useCountriesQuery();
  const countriesData = data?.countries.result || [];

  useEffect(() => {
    if (!isEmpty(countriesData)) {
      setCountries(orderBy(countriesData, ['longName'], ['asc']));
    }
  }, [countriesData]);

  useEffect(() => {
    isMountedRef.current = true;
  }, []);

  const filteredCountriesData = useMemo(() => {
    const applySearchFilter: ApplySearchFilter = country => {
      if (!searchInput) {
        return true;
      }

      return new RegExp(searchInput, 'i').test(country.longName);
    };

    if (!isLoading && !!countries) {
      if (isMountedRef.current) {
        return orderBy(countries.filter(applySearchFilter), item => some(activeCountries, { id: item.id }), ['desc']);
      } else {
        return countries.filter(applySearchFilter);
      }
    }
  }, [isLoading, countries, searchInput]);

  return (
    <div className="countries-list">
      <Input
        clearable
        iconRight
        onChange={setSearchInput}
        onClear={() => setSearchInput('')}
        placeholder="Search"
        searchInput
        withBorder
        wrapperClassName="countries-list__search-input"
      />

      <div className="countries-list__content-scrollable" ref={scrollableRef}>
        {isLoading && <SelectItemLoading />}
        {!!filteredCountriesData && (
          <RenderOnScroll renderLimit={20} scrollRef={scrollableRef}>
            {filteredCountriesData.map(country => {
              const countryItem = createCountryItem(country);

              return (
                <SelectItem
                  active={some(activeCountries, { id: country.id })}
                  imageFullUrl={countryItem?.image.templateUrl}
                  key={country.id}
                  onSelect={onCountrySelect}
                  option={country}
                  optionLabel={countryItem?.title}
                />
              );
            })}
          </RenderOnScroll>
        )}
      </div>
    </div>
  );
};
