import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './styles.scss';

export const CardSkeleton = ({ isAnimated, wrapperClassName }) => {
  return (
    <div className={classnames('card-skeleton', { 'pulse-fade': isAnimated }, wrapperClassName)}>
      <div className="card-skeleton__header-image">
        <div className="card-skeleton__logo" />
      </div>
      <div className="card-skeleton__content">
        <div className="card-skeleton__content-wrapper">
          <div className="card-skeleton__title" />
          <div className="card-skeleton__subtitle" />
        </div>
      </div>
    </div>
  );
};

CardSkeleton.propTypes = {
  isAnimated: PropTypes.bool,
  wrapperClassName: PropTypes.string,
};
