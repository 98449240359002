import React, { useState, Fragment, useEffect, useRef, FC } from 'react';
import classnames from 'classnames';
import { get, isEmpty } from 'lodash';
import Svg from 'svg-inline-react';
import FrExportSvg from 'assets/images/fr-export.svg';
import FrChevronLeftSvg from 'assets/images/fr-chevron-left.svg';
import FrBookmarkOutlinedSvg from 'assets/images/fr-bookmark-outlined.svg';
import FrBookmarkSvg from 'assets/images/fr-bookmark.svg';
import FrPinSvg from 'assets/images/fr-pin.svg';
import Maybe from 'graphql/tsutils/Maybe';
import { SalaryInfo } from '@src/components/SalaryInfo';
import { CompanyWithAwards } from '@src/components/CompanyWithAwards';
import { PageTitleWithOgTags } from '@src/components/PageTitleWithOgTags';
import { SubPageAppBar } from '@src/components/PrimaryAppBar';
import { BasePageHeader } from '@src/containers/PageHeader';
import { CompanyInfoScroller } from '@src/containers/CompanyInfoScroller';
import { FrankMessage } from '@src/containers/Feed/JobOpeningFeedItem/FrankMessage';
import {
  ExternalUrlOpeningCandidateDestinationType,
  HiringStateEnum,
  MatchIntroStateEnum,
  OpeningCandidateDestinationTypeEnum,
} from '@src/graphql/generated';
import { SwiperImage } from '@src/containers/ImageSwiper/interfaces/swiperImage.interface';
import { ImageSwiper } from '@src/containers/ImageSwiper';
import { toExternalUrl } from '@src/util/url';
import { hasFields } from '@src/util/object/helpers';
import { SectionTitle } from '../SectionTitle';
import { SectionDescription } from '../SectionDescription';
import { PositionSkills } from '../PositionSkills';
import { ReviewsSection } from '../ReviewsSection';
import { RelocationPackages } from '../RelocationPackages';
import { OpeningActionButtons } from '../OpeningActionButtons';
import { JobOpeningGateways } from '../JobOpeningGateways';
import { SimilarOffers } from '../SimilarOffers';
import { InformationalBanner } from '../InformationalBanner';
import { Footer } from '../Footer';
import { JobOpeningSkeletonLoading } from '../JobOpeningSkeletonLoading';
import { AISnippets } from '../AISnippets';
import { openingViewBanner } from './interfaces/openingViewBanner.interface';
import { getOpeningPlaces } from './utils/getOpeningPlaces';
import { JobOpeningViewProps } from './props';
import './styles.scss';

const OPENING_VIEW_BANNER: openingViewBanner = {
  [HiringStateEnum.CLOSED]: {
    subTitle: 'Company is not accepting new applicants.',
    title: 'Inactive job opening',
  },
  [HiringStateEnum.FILLED]: {
    subTitle: 'The position is now filled and inactive.',
    title: 'Position filled',
  },
};

export const JobOpeningView: FC<JobOpeningViewProps> = ({
  isLoading,
  isPreview,
  opening,
  user,
  shareOpening,
  openTaxesInfo,
  onApply,
  openAssistantModal,
  openLearnMoreAboutHiringStateModal,
  onBack,
  handleRatingClick,
  onDiscard,
  hasBookmarked,
  onBookmark,
  onReviewsCompleted,
}) => {
  const [isFullOpeningShown, setIsFullOpeningShown] = useState(false);

  const isFrankMessageOpen = useRef(false);

  const frankMessageHeight = useRef(0);

  // opening
  const openingId = opening?.id;
  const title = opening?.title;
  const message = opening?.message;
  const candidateDestination = opening?.candidateDestination;
  const speciality = opening?.speciality;
  const specialityId = speciality?.id;
  const hiringState = opening?.hiringState;
  const match = opening?.match;
  const matchIntroState = match?.introState;
  const relocationBenefits = opening?.relocationBenefits;
  const salaryRange = opening?.salaryRange;
  const remoteType = opening?.remote?.type;
  const positionLocations = opening?.positionLocations;
  const employmentType = opening?.employmentType;
  const workload = opening?.workload;
  const skills = opening?.skills;
  const seniorities = opening?.seniorities;
  const deepLinkUrl = opening?.deepLinkUrl;
  const hasDiscarded = opening?.hasDiscarded;
  const comment = opening?.comment;
  const snippets = opening?.snippets;

  // company
  const company = opening?.company;
  const companyId = company?.id;
  const companyProfile = company?.profile;
  const frankMessage = company?.frankMessage;
  const headerImage = companyProfile?.headerImage;
  const companyName = companyProfile?.name;
  const missionStatement = companyProfile?.missionStatement;
  const officeImages = companyProfile?.officeImages;

  // recruiter
  const recruiterTitle = opening?.recruiter?.title;
  const recruiterFullName = opening?.recruiter?.fullName;

  // user
  const userName = user?.name;
  const userLocationCountryId = user?.location?.country?.id;

  const externalUrl =
    hasFields<ExternalUrlOpeningCandidateDestinationType>(opening?.candidateDestination, ['url']) &&
    opening?.candidateDestination.url
      ? toExternalUrl(opening.candidateDestination.url)
      : undefined;

  const images = officeImages && officeImages.length > 0 ? officeImages : headerImage ? [headerImage] : [];

  let continueLabel = '';

  if (matchIntroState === MatchIntroStateEnum.CHAT) {
    continueLabel = 'Chat';
  } else {
    continueLabel = 'Apply';
  }

  const getPostedBy = (recruiterTitle?: Maybe<string>, recruiterFullName?: Maybe<string>): string => {
    if (opening?.recruiter?.email?.includes('@meetfrank.com')) {
      return 'Posted by MeetFrank';
    }

    if (recruiterTitle && recruiterFullName) {
      return `Posted by ${recruiterFullName}, ${recruiterTitle}`;
    }

    if (recruiterFullName) {
      return `Posted by ${recruiterFullName}`;
    }

    return '';
  };

  const filteredImages: SwiperImage[] = images
    .map(image => {
      return {
        id: image?.id,
        src: image?.fullUrl,
      };
    })
    .filter((image): image is SwiperImage => {
      return !!image.id && !!image.src;
    });

  useEffect(() => {
    setIsFullOpeningShown(false);
  }, [openingId]);

  return (
    <div className="opening-page-view">
      <PageTitleWithOgTags imageUrl={get(images, '0.fullUrl')} message={comment} title={title} />

      <BasePageHeader
        containerClassName="opening-page-view__app-bar-wrapper"
        isIosStatusBarShown
        toggleAppBarEl={
          <SubPageAppBar
            actions={
              !window.isPublicApp
                ? [
                    <Svg
                      className="opening-page-view__swiper-header-icon opening-page-view__swiper-header-icon--no-shadow"
                      key="bookmark"
                      onClick={onBookmark}
                      src={hasBookmarked ? FrBookmarkSvg : FrBookmarkOutlinedSvg}
                    />,
                  ]
                : []
            }
            isDefaultActionsHidden
            onBack={onBack}
          >
            <div className="opening-page-view__app-bar-title">{title}</div>
          </SubPageAppBar>
        }
      >
        <div className="opening-page-view__swiper-wrapper">
          <ImageSwiper images={filteredImages} isLoading={isLoading} />

          <div className="opening-page-view__swiper-header">
            <Svg className="opening-page-view__swiper-header-icon" onClick={onBack} src={FrChevronLeftSvg} />

            {!window.isPublicApp && (
              <Svg
                className="opening-page-view__swiper-header-icon"
                onClick={onBookmark}
                src={hasBookmarked ? FrBookmarkSvg : FrBookmarkOutlinedSvg}
              />
            )}
          </div>
        </div>
      </BasePageHeader>

      {isLoading ? (
        <JobOpeningSkeletonLoading />
      ) : (
        <div className="opening-page-view__container">
          {!!hiringState && hiringState !== HiringStateEnum.OPEN && (
            <div className="opening-view-wrapper__banner-wrapper">
              <InformationalBanner
                learnMore={openLearnMoreAboutHiringStateModal}
                subtitle={OPENING_VIEW_BANNER?.[hiringState]?.subTitle}
                title={OPENING_VIEW_BANNER?.[hiringState]?.title}
              />
            </div>
          )}

          <div className="opening-page-view__company-share">
            {company && openingId && (
              <CompanyWithAwards
                company={company}
                openingId={openingId}
                subTitle={getPostedBy(recruiterTitle, recruiterFullName)}
                subTitleClassName="opening-page-view__sub-title"
                title={company.profile?.name}
                titleClassName="opening-page-view__title"
                wrapperClassName="opening-page-view__title-wrapper"
              />
            )}

            <Svg className="opening-page-view__share-icon" onClick={shareOpening} src={FrExportSvg} />
          </div>

          <div className="opening-page-view__opening-section">
            <div
              className={classnames(
                'opening-page-view__opening-section-content',
                !isFullOpeningShown && 'opening-page-view__opening-section-content--less',
              )}
            >
              {title && (
                <SectionTitle titleClassName="opening-page-view__opening-section-content-title">{title}</SectionTitle>
              )}

              <div className="opening-page-view__location">
                <Svg className="opening-page-view__pin" src={FrPinSvg} />

                {opening &&
                  getOpeningPlaces(positionLocations || [], opening.remote?.allowedPlaces || [], opening.remote?.type)}
              </div>

              {comment && <SectionDescription>{comment}</SectionDescription>}

              <SectionTitle>{`${companyName} is open for`}</SectionTitle>

              <PositionSkills
                openTaxesInfo={openTaxesInfo}
                salaryRange={salaryRange || undefined}
                employmentType={employmentType || undefined}
                positionLocations={positionLocations}
                seniorities={seniorities}
                skills={skills}
                speciality={speciality}
                workload={workload}
                remoteType={remoteType}
              />

              {!isEmpty(relocationBenefits) && (
                <>
                  <SectionTitle>Relocation packages</SectionTitle>
                  <RelocationPackages relocationBenefits={relocationBenefits} />
                </>
              )}

              {message && comment !== message && (
                <>
                  <SectionTitle>Job role description</SectionTitle>

                  <SectionDescription openingTitle={title || undefined} userName={userName || undefined}>
                    {message}
                  </SectionDescription>
                </>
              )}
            </div>

            {!isFullOpeningShown && (
              <div className="opening-page-view__opening-section-read-more" onClick={() => setIsFullOpeningShown(true)}>
                Read more about the job offer
              </div>
            )}
          </div>

          <div className="opening-page-view__compact-section">
            {!isFullOpeningShown && user?.inboxProfile?.salary && salaryRange?.to && (
              <SalaryInfo
                salaryRange={salaryRange}
                userSalary={user.inboxProfile.salary}
                wrapperClassName="opening-page-view__salary-info"
              />
            )}

            {frankMessage && (
              <FrankMessage
                isFrankMessageOpen={isFrankMessageOpen.current}
                message={frankMessage}
                messageHeight={frankMessageHeight.current}
                setFrankMessageHeight={height => {
                  frankMessageHeight.current = height;
                }}
                setFrankMessageState={state => {
                  isFrankMessageOpen.current = state;
                }}
                wrapperClassName="opening-page-view__frank-message"
              />
            )}

            {candidateDestination && onBookmark && openingId && specialityId && (
              <div
                className={classnames(
                  'opening-page-view__actions-wrapper',
                  isPreview && 'opening-page-view--no-events',
                )}
              >
                <OpeningActionButtons
                  candidateDestination={candidateDestination}
                  hasBookmarked={!!hasBookmarked}
                  hiringState={hiringState}
                  matchIntroState={matchIntroState}
                  onApply={onApply}
                  onBookmark={onBookmark}
                  openingId={openingId}
                  specialityId={specialityId}
                />
              </div>
            )}
          </div>

          {!!snippets?.length && <AISnippets snippets={snippets} />}

          {!isPreview && (
            <>
              <SectionTitle
                hasTopDivider={!snippets?.length}
                titleClassName="opening-page-view__section-company-title"
                wrapperClassName="opening-page-view__section-company-wrapper"
              >
                About the company
              </SectionTitle>

              {openingId && company && (
                <div className="opening-page-view__compact-section">
                  <CompanyWithAwards
                    company={company}
                    onRatingItemClick={handleRatingClick}
                    openingId={openingId}
                    subTitle={
                      opening &&
                      getOpeningPlaces(
                        positionLocations || [],
                        opening.remote?.allowedPlaces || [],
                        opening.remote?.type,
                      )
                    }
                    subTitleClassName="opening-page-view__sub-title"
                    title={get(company, 'profile.name')}
                    titleClassName="opening-page-view__title"
                    wrapperClassName="opening-page-view__company-with-awards"
                  />
                </div>
              )}

              {missionStatement && (
                <SectionDescription messageClassName="opening-page-view__company-info">
                  {missionStatement}
                </SectionDescription>
              )}

              <div className="opening-page-view__compact-section">
                {company && (
                  <CompanyInfoScroller
                    company={company}
                    images={{ current: images }}
                    message={comment}
                    noMessage
                    openingId={openingId}
                  />
                )}

                {openingId && companyId && <JobOpeningGateways companyId={companyId} openingId={openingId} />}
              </div>
            </>
          )}

          {!isPreview && company?.id && <ReviewsSection companyId={company?.id} onCompleted={onReviewsCompleted} />}

          {!isPreview && userLocationCountryId && opening && (
            <SimilarOffers opening={opening} userCountryId={userLocationCountryId} />
          )}
        </div>
      )}

      {!isPreview && !isLoading && candidateDestination && (
        <Footer
          candidateDestination={candidateDestination}
          continueLabel={continueLabel}
          deepLinkUrl={deepLinkUrl || undefined}
          hasDiscarded={!!hasDiscarded}
          hiringState={hiringState}
          match={match}
          onApply={onApply}
          onDiscard={onDiscard}
          openAssistantModal={openAssistantModal}
        />
      )}

      {candidateDestination?.type === OpeningCandidateDestinationTypeEnum.EXTERNAL_URL && externalUrl && (
        <iframe
          style={{
            display: 'none',
          }}
          src={externalUrl}
        ></iframe>
      )}
    </div>
  );
};
