import React, { FC, Fragment } from 'react';
import classnames from 'classnames';
import Svg from 'svg-inline-react';
import ChatSvg from 'assets/images/fr-chat.svg';
import ApplySvg from 'assets/images/fr-apply.svg';
import { trackEvent } from '@src/metrics';
import { toExternalUrl } from '@src/util/url';
import { preventEvents } from '@src/util/preventEvents';
import {
  ExternalUrlOpeningCandidateDestinationType,
  HiringStateEnum,
  MatchIntroStateEnum,
  OpeningCandidateDestinationTypeEnum,
} from '@src/graphql/generated';
import { hasFields } from '@src/util/object/helpers';
import { Interaction } from '@src/metrics/enums/interaction.enum';
import { Context } from '@src/metrics/enums/context.enum';
import { ApplyProps } from './props';
import './styles.scss';

export const Apply: FC<ApplyProps> = ({
  matchIntroState,
  candidateDestination,
  hiringState,
  openingId,
  specialityId,
  onApply,
  wrapperClassName,
}) => {
  const isExternalApply =
    candidateDestination && candidateDestination.type === OpeningCandidateDestinationTypeEnum.EXTERNAL_URL;
  const isDisabled =
    (hiringState && [HiringStateEnum.FILLED, HiringStateEnum.CLOSED].includes(hiringState)) || window.isPublicApp;

  return (
    <>
      {openingId && specialityId && hiringState && (
        <a
          className={classnames(
            'opening-action__item pressed-animation',
            wrapperClassName,
            isDisabled && 'opening-action__item--disabled',
          )}
          href={
            hasFields<ExternalUrlOpeningCandidateDestinationType>(candidateDestination, ['url'])
              ? toExternalUrl(candidateDestination.url)
              : null
          }
          onClick={
            isExternalApply
              ? () => {
                  trackEvent({
                    context: Context.OPENING_ACTIONS,
                    interaction: Interaction.CLICK_APPLY,
                  });
                  onApply(openingId, hiringState);
                }
              : preventEvents(() => {
                  trackEvent({
                    context: Context.OPENING_ACTIONS,
                    interaction: Interaction.CLICK_APPLY,
                  });
                  onApply(openingId, hiringState);
                })
          }
          target={isExternalApply ? '_blank' : undefined}
          rel="noreferrer"
        >
          {matchIntroState === MatchIntroStateEnum.CHAT ? (
            <>
              <Svg className="opening-action__icon" src={ChatSvg} />

              <span className="opening-action__label">Chat</span>
            </>
          ) : (
            <>
              <Svg className="opening-action__icon" src={ApplySvg} />

              <span className="opening-action__label">Apply</span>
            </>
          )}
        </a>
      )}
    </>
  );
};
