import React, { FC, forwardRef } from 'react';
import { isEmpty } from 'lodash';
import classnames from 'classnames';
import { SuggestionItem, SuggestionItemSkeleton } from '../SuggestionItem';
import { SuggestionsProps } from './props';
import './styles.scss';

export const Suggestions: FC<SuggestionsProps> = forwardRef(
  ({ className, loading, suggestions, onSuggestionSelect, isDarkMode }, ref) => {
    return (
      <div className={classnames('suggestions', isDarkMode && 'suggestions--dark-mode', className)} ref={ref}>
        {loading && <SuggestionItemSkeleton />}
        {!loading && isEmpty(suggestions) && <div className="suggestions__empty-state">No results found</div>}
        {suggestions.map((suggestion, index) => (
          <SuggestionItem
            imageSrc={suggestion.imageSrc}
            key={index}
            onClick={() => onSuggestionSelect(suggestion)}
            title={suggestion.title}
          />
        ))}
      </div>
    );
  },
);

Suggestions.displayName = 'Suggestions';
