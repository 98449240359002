import { SalaryPeriodEnum } from '@src/graphql/generated';

export const getPeriodLabelByType = (period: SalaryPeriodEnum): string => {
  switch (period) {
    case SalaryPeriodEnum.HOUR:
      return 'hr';
    case SalaryPeriodEnum.WEEK:
      return 'wk';
    case SalaryPeriodEnum.MONTH:
      return 'mo';
    case SalaryPeriodEnum.YEAR:
      return 'yr';
    default:
      return '';
  }
};
