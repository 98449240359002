import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { goBack } from 'connected-react-router';
import ContentPush from '@src/components/ContentPush';
import { setNavigationVisibility } from '@src/redux/actions/navigation';
import api from '@src/util/api';

@connect(undefined, { goBack, setNavigationVisibility })
export default class Contact extends PureComponent {
  static propTypes = {
    goBack: PropTypes.func.isRequired,
    setNavigationVisibility: PropTypes.func.isRequired,
  };

  state = {
    loading: true,
  };

  componentDidMount() {
    this.props.setNavigationVisibility(false);
    this.fetchQuestion();
  }

  fetchQuestion = () => {
    api('/api/app/menu/contact', { delay: 400 }).then(this.setQuestion).catch(this.props.goBack);
  };

  setQuestion = ({ question }) => {
    this.setState({
      loading: false,
      question,
    });
  };

  render() {
    const { question, loading } = this.state;

    if (!question) {
      return null;
    }

    return (
      <ContentPush
        loading={loading}
        question={question}
        sendBack={this.props.goBack}
        sendResponse={this.props.goBack}
        showHeader
      />
    );
  }
}
