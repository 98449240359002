import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import FadeInImage from '@src/components/FadeInImage';
import './styles.scss';

export const Card = ({
  title,
  subTitle,
  headerImage,
  logo,
  wrapperClassName,
  containerClassName,
  subTitleClassName,
  logoClassName,
  titleClassName,
  rightElement,
  to,
}) => {
  return (
    <Link className={classnames('card', wrapperClassName)} to={to}>
      <FadeInImage className="card__image" src={headerImage} wrapperClassName="card__image-wrapper" />

      <div className={classnames('card__container', containerClassName)}>
        {logo && (
          <FadeInImage
            className={classnames('card__logo', logoClassName)}
            src={logo}
            wrapperClassName="card__logo-wrapper"
          />
        )}

        {!!rightElement && <div className="card__right-el">{rightElement}</div>}

        <h4 className={classnames('card__title', titleClassName)}>{title}</h4>

        {subTitle && <p className={classnames('card__subtitle', subTitleClassName)}>{subTitle}</p>}
      </div>
    </Link>
  );
};

Card.propTypes = {
  containerClassName: PropTypes.string,
  headerImage: PropTypes.string.isRequired,
  logo: PropTypes.string,
  logoClassName: PropTypes.string,
  rightElement: PropTypes.node,
  subTitle: PropTypes.string,
  subTitleClassName: PropTypes.string,
  title: PropTypes.string.isRequired,
  titleClassName: PropTypes.string,
  to: PropTypes.string.isRequired,
  wrapperClassName: PropTypes.string,
};
