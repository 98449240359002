import React, { FC } from 'react';
import { get, isEmpty } from 'lodash';
import { CardScroller } from '@src/components/CardScroller';
import { SectionHeader } from '@src/components/SectionHeader';
import { JOB_OPENING_ROUTE } from '@src/constants/routes';
import createLink from '@src/util/createLink';
import { OpeningType } from '@src/graphql/generated';
import { OpeningCardScrollerProps } from './props';
import './styles.scss';

const mapFilledOpeningToCardProps = (opening: OpeningType) => ({
  headerImage: get(opening, 'company.profile.headerImage.fullUrl'),
  logo: get(opening, 'company.profile.logo.fullUrl'),
  subTitle: get(opening, 'company.profile.name', ''),
  title: get(opening, 'title', ''),
  to: createLink({
    link: JOB_OPENING_ROUTE,
    params: { openingId: get(opening, 'id') },
  }),
});

export const OpeningCardScroller: FC<OpeningCardScrollerProps> = ({ isLoading, to, title, rightIconLabel, items }) => {
  if (!isLoading && isEmpty(items)) {
    return null;
  }

  return (
    <div className="openings-horizontal-scroller">
      {to ? (
        <SectionHeader
          isLoading={isLoading}
          rightIconLabel={rightIconLabel}
          rightIconLabelClassName="openings-horizontal-scroller__section-title"
          title={title}
          to={to}
        />
      ) : undefined}
      <CardScroller isLoading={isLoading} items={items.map(mapFilledOpeningToCardProps)} />
    </div>
  );
};
