import React, { FC } from 'react';
import { BlueButton, InvisibleButton } from '@src/components/Button';
import { Props } from './props';
import './styles.scss';

export const ActionButtons: FC<Props> = ({
  invisibleButtonProps,
  invisibleButtonLabel,
  blueButtonProps,
  blueButtonLabel,
}) => {
  return (
    <div className="place-filter-modal-actions">
      <InvisibleButton className="place-filter-modal-actions__invisible-button" {...invisibleButtonProps}>
        {invisibleButtonLabel}
      </InvisibleButton>

      <BlueButton className="place-filter-modal-actions__blue-button" isFilled {...blueButtonProps}>
        {blueButtonLabel}
      </BlueButton>
    </div>
  );
};

ActionButtons.defaultProps = {
  blueButtonLabel: 'Apply',
  invisibleButtonLabel: 'Clear',
};
