import { detect } from 'detect-browser';
import ChromeLogo from 'assets/images/chrome-logo.svg';
import OperaLogo from 'assets/images/opera-logo.svg';
import SafariLogo from 'assets/images/safari-logo.svg';
import FirefoxLogo from 'assets/images/firefox-logo.svg';

const browser = detect();

export const detectBrowser = () => {
  const browserType = browser && browser.name;

  switch (browserType) {
    case 'chrome':
    case 'samsung':
    case 'android':
    case 'chromium-webview':
      return {
        logo: ChromeLogo,
        name: 'Chrome',
      };
    case 'opera':
    case 'opera-mini':
      return {
        logo: OperaLogo,
        name: 'Opera',
      };
    case 'ios':
    case 'ios-webview':
    case 'safari':
      return {
        logo: SafariLogo,
        name: 'Safari',
      };
    case 'firefox':
      return {
        logo: FirefoxLogo,
        name: 'Firefox',
      };
    default:
      return null;
  }
};
