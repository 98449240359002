import React, { PureComponent } from 'react';
import { About } from '@src/views/CompanyProfile/About';
import './styles.scss';

export default class CompanyProfileDataPush extends PureComponent {
  state = {
    companyProfile: undefined,
  };

  componentDidMount() {
    window.parent.dataSubject$.subscribe(({ companyProfile }) => {
      this.setState({ companyProfile });
    });
  }

  render() {
    const { companyProfile } = this.state;

    if (!companyProfile) {
      return null;
    }

    return (
      <About
        className="company-details-data-push"
        companyProfile={companyProfile}
        isPreview
        setNavigationVisibility={() => null}
      />
    );
  }
}
