import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import './styles.scss';

export function ContentCard({ children, onClick }) {
  return (
    <div className="opening-feed-item-content-card" onClick={onClick}>
      {children}
    </div>
  );
}

export function ContentCardContent({ title, children, theme }) {
  return (
    <div
      className={classnames('opening-feed-item-content-card__info', `opening-feed-item-content-card__info--${theme}`)}
    >
      <div className="opening-feed-item-content-card__info-title">{title}</div>

      {children}
    </div>
  );
}

ContentCardContent.propTypes = ContentCard.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
};

ContentCardContent.propTypes = {
  theme: PropTypes.oneOf(['grey', 'yellow', 'pink']).isRequired,
  title: PropTypes.string.isRequired,
};
