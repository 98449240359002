import React, { useMemo, useState, useEffect, useCallback, useRef, FC } from 'react';
import qs from 'qs';
import { get, debounce, isEmpty, reject } from 'lodash';
import { useLazyQuery } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';
import { apolloClient } from '@src/graphql/apollo';
import { GET_SEARCH_LATEST_OPENINGS_COUNT } from '@src/graphql/search/queries';
import { JOBS_SEARCH_ROUTE, SEARCH_RESULT_ROUTE } from '@src/constants/routes';
import { RECENT_SEARCH_ITEM_TYPE } from '@src/containers/RecentSearches/constants';
import { setRecentSearchItem } from '@src/containers/RecentSearches/utils';
import createLink from '@src/util/createLink';
import { SEARCH_TAGS, GLOBAL_AREA_ID } from '@src/constants';
import { getPreFilledSearchTags } from '@src/util/getSearchTags';
import { LocalStorageKeys } from '@src/localStorage/enums/localStorageKeys.enum';
import {
  GetSearchLatestOpeningsCountDocument,
  GetUserQuery,
  OpeningRemoteTypeEnum,
  useGetUserQuery,
} from '@src/graphql/generated';
import { setItemToLocalStorage } from '@src/localStorage/utils/setItemToLocalStorage';
import { getItemFromLocalStorage } from '@src/localStorage/utils/getItemFromLocalStorage';
import { trackEvent } from '@src/metrics';
import { getAllSearchTags } from './utils';
import { SenioritiesSelection } from './SenioritiesSelection';
import { NoResults } from './NoResults';
import { CountrySearch } from './CountrySearch';
import { AreaSelection } from './AreaSelection';
import { LocationSearch } from './LocationSearch';
import { Suggestions } from './Suggestions';
import { HowWouldLikeWork } from './HowWouldLikeWork';

const SEARCH_VIEWS = {
  AREA_SELECTION_VIEW: 'AREA_SELECTION_VIEW',
  COUNTRY_SEARCH_VIEW: 'COUNTRY_SEARCH_VIEW',
  HOW_WOULD_LIKE_WORK_VIEW: 'HOW_WOULD_LIKE_WORK_VIEW',
  LOCATION_SEARCH_VIEW: 'LOCATION_SEARCH_VIEW',
  NO_RESULTS_VIEW: 'NO_RESULTS_VIEW',
  SENIORITIES_VIEW: 'SENIORITIES_VIEW',
  SUGGESTIONS_VIEW: 'SUGGESTIONS_VIEW',
};

const searchViewsByViewOrder = [
  SEARCH_VIEWS.SUGGESTIONS_VIEW,
  SEARCH_VIEWS.HOW_WOULD_LIKE_WORK_VIEW,
  SEARCH_VIEWS.LOCATION_SEARCH_VIEW,
  SEARCH_VIEWS.AREA_SELECTION_VIEW,
  SEARCH_VIEWS.COUNTRY_SEARCH_VIEW,
  SEARCH_VIEWS.SENIORITIES_VIEW,
  SEARCH_VIEWS.NO_RESULTS_VIEW,
];

const HOW_WOULD_LIKE_WORK_ACTIONS = {
  EDIT: 'EDIT',
  SELECT: 'SELECT',
};

export const Search: FC = () => {
  const urlLocation = useLocation();

  const history = useHistory();

  const queryParams = useMemo(() => qs.parse(urlLocation.search, { ignoreQueryPrefix: true }), [urlLocation.search]);

  const whereDoYouLikeWorkActiveKey = useRef(null);

  const didMount = useRef(false);

  const [activeView, setActiveView] = useState(SEARCH_VIEWS.SUGGESTIONS_VIEW);

  const [searchPattern, setSearchPattern] = useState('');

  const [workOptions, setWorkOptions] = useState([]);

  const [activeSeniorities, setActiveSeniorities] = useState([]);

  const [activeAreas, setActiveAreas] = useState(getItemFromLocalStorage(LocalStorageKeys.SEARCH_REMOTE_AREA_V1) || []);

  const [activeCountries, setActiveCountries] = useState(
    getItemFromLocalStorage(LocalStorageKeys.SEARCH_REMOTE_COUNTRY_V1) || [],
  );

  const [fetchOpeningsAmount, { data: openingsData, loading: isLoadingOpeningsAmount }] = useLazyQuery(
    GET_SEARCH_LATEST_OPENINGS_COUNT,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    },
  );

  const { data: userData } = useGetUserQuery();

  const user = userData?.currentUser;
  const userLocation = user?.location;

  const [location, setLocation] = useState<GetUserQuery['currentUser']['location']>(userLocation);

  useEffect(() => {
    if (didMount.current) {
      setItemToLocalStorage(LocalStorageKeys.SEARCH_REMOTE_COUNTRY_V1, activeCountries);
      setItemToLocalStorage(LocalStorageKeys.SEARCH_REMOTE_AREA_V1, activeAreas);
    } else {
      didMount.current = true;
    }
  }, [activeAreas, activeCountries]);

  useEffect(() => {
    trackEvent({ context: 'Search', interaction: 'View' });
  }, []);

  useEffect(() => {
    if (activeView === SEARCH_VIEWS.HOW_WOULD_LIKE_WORK_VIEW) {
      fetchOpeningsCount(searchPattern, location);
    }
  }, [activeView, workOptions]);

  async function verifyOpeningsCount(activeView) {
    const allSearchTags = getAllSearchTags(workOptions, activeAreas, activeCountries, location, activeSeniorities);
    const searchTags = getPreFilledSearchTags(allSearchTags);
    const { data } = await apolloClient.query({
      query: GetSearchLatestOpeningsCountDocument,
      variables: {
        paginationOptions: {
          limit: 1,
        },
        searchParams: {
          groupSearchTagsWithOrCondition: searchTags.groupSearchTagsWithOrCondition,
          searchPattern: searchPattern,
          searchTagsWithAndCondition: searchTags.searchTagsWithAndCondition,
          searchTagsWithOrCondition: searchTags.searchTagsWithOrCondition,
        },
      },
    });

    if (get(data, 'searchOpenings.pagination.totalCount', 0) > 0) {
      setActiveView(activeView);
    } else {
      setActiveView(SEARCH_VIEWS.NO_RESULTS_VIEW);
    }
  }

  const fetchOpeningsCount = (searchPatternValue, location) => {
    const allSearchTags = getAllSearchTags(workOptions, activeAreas, activeCountries, location, activeSeniorities);
    const searchTags = getPreFilledSearchTags(allSearchTags);

    fetchOpeningsAmount({
      variables: {
        paginationOptions: {
          limit: 1,
        },
        searchParams: {
          groupSearchTagsWithOrCondition: searchTags.groupSearchTagsWithOrCondition,
          searchPattern: searchPatternValue,
          searchTagsWithAndCondition: searchTags.searchTagsWithAndCondition,
          searchTagsWithOrCondition: searchTags.searchTagsWithOrCondition,
        },
      },
    });
  };

  const handleClear = () => {
    if (activeView === SEARCH_VIEWS.SUGGESTIONS_VIEW) {
      setSearchPattern('');

      fetchOpeningsCount(searchPattern, location);
    }

    if (activeView === SEARCH_VIEWS.HOW_WOULD_LIKE_WORK_VIEW) {
      setLocation(userLocation);
      setWorkOptions([]);
      setActiveAreas([]);
      setActiveCountries([]);

      fetchOpeningsCount(searchPattern, userLocation);
    }

    if (activeView === SEARCH_VIEWS.SENIORITIES_VIEW) {
      setActiveSeniorities([]);
    }

    if (activeView === SEARCH_VIEWS.LOCATION_SEARCH_VIEW) {
      setActiveSeniorities([]);
    }

    if (activeView === SEARCH_VIEWS.AREA_SELECTION_VIEW) {
      setActiveAreas([]);
      setActiveCountries([]);
    }

    if (activeView === SEARCH_VIEWS.COUNTRY_SEARCH_VIEW) {
      setActiveCountries([]);
    }
  };

  const moveToPrevView = () => {
    const activeViewIndex = searchViewsByViewOrder.findIndex(it => it === activeView);
    setActiveView(searchViewsByViewOrder[activeViewIndex - 1]);
  };

  const moveToNextView = suggestionValue => {
    /* If queryParams exists it means we opened this page from search
       results page to change search suggestion and we need to redirect back
       to search results page instead moving forward to next view
      */
    if (!isEmpty(queryParams)) {
      history.replace(
        createLink({
          link: SEARCH_RESULT_ROUTE,
          queryParams: {
            ...queryParams,
            searchPattern: suggestionValue || searchPattern,
          },
        }),
      );
    }

    const activeViewIndex = searchViewsByViewOrder.findIndex(it => it === activeView);
    setActiveView(searchViewsByViewOrder[activeViewIndex + 1]);
  };

  const handleLocationSelect = location => {
    setLocation(location);

    if (whereDoYouLikeWorkActiveKey.current && !workOptions.includes(whereDoYouLikeWorkActiveKey.current)) {
      const updatedWorkOptions = workOptions.filter(it => it !== OpeningRemoteTypeEnum.FULLY);
      setWorkOptions([...updatedWorkOptions, whereDoYouLikeWorkActiveKey.current]);
    }
  };

  const handleBackFromLocationView = () => {
    whereDoYouLikeWorkActiveKey.current = null;
    moveToPrevView();
  };

  const handleSenioritiesSelect = seniorities => {
    setActiveSeniorities(seniorities);
  };

  const handleWhereDoYouLikeWorkSelect = (action, option) => {
    if ([OpeningRemoteTypeEnum.ONSITE, OpeningRemoteTypeEnum.HYBRID].includes(option.key) && !location) {
      whereDoYouLikeWorkActiveKey.current = option.key;
      setActiveView(SEARCH_VIEWS.LOCATION_SEARCH_VIEW);

      return;
    } else if (option.key === OpeningRemoteTypeEnum.FULLY && isEmpty(activeCountries) && isEmpty(activeAreas)) {
      setActiveView(SEARCH_VIEWS.AREA_SELECTION_VIEW);

      return;
    }

    if (action === HOW_WOULD_LIKE_WORK_ACTIONS.EDIT) {
      if ([OpeningRemoteTypeEnum.ONSITE, OpeningRemoteTypeEnum.HYBRID].includes(option.key)) {
        whereDoYouLikeWorkActiveKey.current = option.key;
        setActiveView(SEARCH_VIEWS.LOCATION_SEARCH_VIEW);

        return;
      } else if (option.key === OpeningRemoteTypeEnum.FULLY) {
        setActiveView(SEARCH_VIEWS.AREA_SELECTION_VIEW);

        return;
      }
    }

    // Deselect HYBRID and ON_SITE in case remote is selected
    if (action === HOW_WOULD_LIKE_WORK_ACTIONS.SELECT) {
      if ([OpeningRemoteTypeEnum.ONSITE, OpeningRemoteTypeEnum.HYBRID].includes(option.key)) {
        const filteredWorkOption = workOptions.filter(key => key !== OpeningRemoteTypeEnum.FULLY);
        const activeFilters = filteredWorkOption.includes(option.key)
          ? filteredWorkOption.filter(key => key !== option.key)
          : [...filteredWorkOption, option.key];

        setWorkOptions(activeFilters);
      } else {
        const activeFilters = workOptions.includes(option.key) ? [] : [option.key];
        setWorkOptions(activeFilters);
      }
    }
  };

  const handleAreaSave = areas => {
    if (areas.length) {
      setWorkOptions([OpeningRemoteTypeEnum.FULLY]);
    }

    setActiveAreas(areas);

    if (!isEmpty(activeCountries)) {
      setActiveCountries([]);
    }

    setActiveView(SEARCH_VIEWS.HOW_WOULD_LIKE_WORK_VIEW);
  };

  const handleCountrySave = countries => {
    if (countries.length) {
      setWorkOptions([OpeningRemoteTypeEnum.FULLY]);
    }

    setActiveCountries(countries);

    if (!isEmpty(activeAreas)) {
      setActiveAreas([]);
    }

    setActiveView(SEARCH_VIEWS.HOW_WOULD_LIKE_WORK_VIEW);
  };

  const handleNoResultItemSelect = item => {
    if (item.__typename === 'LocationType') {
      setLocation(item);
      setActiveCountries([]);
      setActiveAreas([]);
      setWorkOptions(reject(workOptions, option => option === OpeningRemoteTypeEnum.FULLY));
    }

    if (item.__typename === 'CountryType') {
      setLocation(undefined);
      setActiveCountries([item]);
      setActiveAreas([]);
      setWorkOptions([OpeningRemoteTypeEnum.FULLY]);
    }

    setActiveView(SEARCH_VIEWS.HOW_WOULD_LIKE_WORK_VIEW);
  };

  const handleSuggestionClick = suggestionValue => {
    setSearchPattern(suggestionValue);
    moveToNextView(suggestionValue);
  };

  const handleSuggestionChange = useCallback(
    debounce(value => {
      setSearchPattern(value);
    }, 300),
    [],
  );

  const redirectToResultPage = () => {
    setRecentSearchItem({
      areas: activeAreas,
      countries: activeCountries,
      location: location,
      searchPattern: searchPattern,
      seniorities: activeSeniorities,
      type: RECENT_SEARCH_ITEM_TYPE.SEARCH,
      version: 1,
      workOptions: workOptions,
    });

    const allSearchTags = getAllSearchTags(workOptions, activeAreas, activeCountries, location, activeSeniorities);
    const isGlobalAreaSelected =
      workOptions.includes(OpeningRemoteTypeEnum.FULLY) && activeAreas.find(it => it.id === GLOBAL_AREA_ID);

    history.replace(
      createLink({
        link: SEARCH_RESULT_ROUTE,
        queryParams: {
          searchPattern: searchPattern,
          searchTags: isGlobalAreaSelected
            ? [...allSearchTags, { tag: SEARCH_TAGS.REMOTE_AREA, value: GLOBAL_AREA_ID }]
            : allSearchTags,
        },
      }),
    );
  };

  switch (activeView) {
    case SEARCH_VIEWS.SUGGESTIONS_VIEW:
      return (
        <Suggestions
          closeSearch={() => history.push(JOBS_SEARCH_ROUTE)}
          onClear={handleClear}
          onNext={moveToNextView}
          onSuggestionChange={handleSuggestionChange}
          onSuggestionClick={handleSuggestionClick}
          searchPattern={searchPattern}
        />
      );
    case SEARCH_VIEWS.NO_RESULTS_VIEW: {
      return (
        <NoResults
          goBack={() => history.goBack()}
          goToView={() => setActiveView(SEARCH_VIEWS.HOW_WOULD_LIKE_WORK_VIEW)}
          onItemClick={handleNoResultItemSelect}
          searchPattern={searchPattern}
        />
      );
    }

    case SEARCH_VIEWS.HOW_WOULD_LIKE_WORK_VIEW:
      return (
        <HowWouldLikeWork
          activeAreas={activeAreas}
          activeCountries={activeCountries}
          activeLocation={location}
          goBack={moveToPrevView}
          jobOffersAmount={get(openingsData, 'searchOpenings.pagination.totalCount', 0)}
          loadingJobOffersAmount={isLoadingOpeningsAmount}
          onCheckBoxClick={option => {
            handleWhereDoYouLikeWorkSelect(HOW_WOULD_LIKE_WORK_ACTIONS.SELECT, option);
          }}
          onClear={handleClear}
          onContentClick={option => {
            handleWhereDoYouLikeWorkSelect(HOW_WOULD_LIKE_WORK_ACTIONS.EDIT, option);
          }}
          onNext={() => verifyOpeningsCount(SEARCH_VIEWS.SENIORITIES_VIEW)}
          selectedWorkOptions={workOptions}
          title={searchPattern}
        />
      );
    case SEARCH_VIEWS.LOCATION_SEARCH_VIEW:
      return (
        <LocationSearch
          defaultLocation={location}
          goBack={handleBackFromLocationView}
          onClear={handleClear}
          onLocationSelect={handleLocationSelect}
          onNext={() => setActiveView(SEARCH_VIEWS.HOW_WOULD_LIKE_WORK_VIEW)}
        />
      );
    case SEARCH_VIEWS.AREA_SELECTION_VIEW:
      return (
        <AreaSelection
          activeCountries={activeCountries}
          goBack={() => setActiveView(SEARCH_VIEWS.HOW_WOULD_LIKE_WORK_VIEW)}
          onAreaSave={handleAreaSave}
          onClear={handleClear}
          onSpecificCountrySelect={() => setActiveView(SEARCH_VIEWS.COUNTRY_SEARCH_VIEW)}
          selectedAreas={activeAreas}
        />
      );
    case SEARCH_VIEWS.COUNTRY_SEARCH_VIEW:
      return (
        <CountrySearch
          goBack={() => setActiveView(SEARCH_VIEWS.AREA_SELECTION_VIEW)}
          onClear={handleClear}
          onCountrySave={handleCountrySave}
          selectedCountries={activeCountries}
        />
      );
    case SEARCH_VIEWS.SENIORITIES_VIEW:
      return (
        <SenioritiesSelection
          activeSeniorities={activeSeniorities}
          allSearchTags={getAllSearchTags(workOptions, activeAreas, activeCountries, location, activeSeniorities)}
          goBack={() => setActiveView(SEARCH_VIEWS.HOW_WOULD_LIKE_WORK_VIEW)}
          onClear={handleClear}
          onNext={redirectToResultPage}
          onSenioritySelect={handleSenioritiesSelect}
          searchPattern={searchPattern}
        />
      );
    default:
      return null;
  }
};
