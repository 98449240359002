import React, { FC } from 'react';
import classnames from 'classnames';
import Svg from 'svg-inline-react';
import { Link } from 'react-router-dom';
import PinSvg from 'assets/images/fr-pin.svg';
import LaptopSvg from '@shared/assets/svgs/laptop.svg';
import FadeInImage from '@src/components/FadeInImage';
import { getCountryFlagTemplateUrl } from '@src/util/filters';
import { JOB_OPENING_ROUTE } from '@src/constants/routes';
import createLink from '@src/util/createLink';
import { SalaryRange } from '@src/components/SalaryRange';
import { HYBRID_PLACE_ID, ONSITE_PLACE_ID, REMOTE_PLACE_ID } from '@src/constants/placeIds';
import { PlaceTypeEnum } from '@src/graphql/generated';
import { CitiesWithSalaryProps } from './props';
import './styles.scss';

export const CitiesWithSalary: FC<CitiesWithSalaryProps> = ({ wrapperClassName, id, places, salaryRange }) => {
  const placesSize = places?.length || 0;
  const isMoreThanTwo = placesSize > 2;

  const slicedPlaces = (isMoreThanTwo ? places?.slice(0, 2) : places) || [];

  return (
    <Link
      className={classnames('job-opening-feed-item-cities-flags', wrapperClassName)}
      to={createLink({
        link: JOB_OPENING_ROUTE,
        params: { openingId: id },
      })}
    >
      {slicedPlaces?.map(place => {
        return (
          <div
            className={classnames({
              'job-opening-feed-item-cities-flags__item': true,
              'job-opening-feed-item-cities-flags__item-area': place?.type === PlaceTypeEnum.AREA,
            })}
            key={place.id}
          >
            {place.id === REMOTE_PLACE_ID && (
              <Svg className="job-opening-feed-item-cities-flags__icon" src={LaptopSvg} />
            )}

            {place.id === ONSITE_PLACE_ID && <Svg className="job-opening-feed-item-cities-flags__icon" src={PinSvg} />}

            {place.id === HYBRID_PLACE_ID && (
              <div className="job-opening-feed-item-cities-flags__icons-wrapper">
                <Svg className="job-opening-feed-item-cities-flags__pin-icon" src={PinSvg} />

                <Svg className="job-opening-feed-item-cities-flags__laptop-icon" src={LaptopSvg} />
              </div>
            )}

            {place.id &&
              ![REMOTE_PLACE_ID, ONSITE_PLACE_ID, HYBRID_PLACE_ID].includes(place.id) &&
              place?.type !== PlaceTypeEnum.AREA &&
              place?.countryShortName && (
                <FadeInImage
                  className="job-opening-feed-item-cities-flags__flag"
                  src={getCountryFlagTemplateUrl(place?.countryShortName)}
                />
              )}

            {place.name === 'Remote' && slicedPlaces.length > 1 ? 'Remote in' : place.name}
          </div>
        );
      })}

      {isMoreThanTwo && <div className="job-opening-feed-item-cities-flags__count">(+{placesSize - 2})</div>}

      {salaryRange && (
        <div className="job-opening-feed-item-cities-flags__salary-info">
          <SalaryRange salaryRange={salaryRange} />
        </div>
      )}
    </Link>
  );
};
