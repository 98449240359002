import { useEffect, useMemo } from 'react';
import { throttle } from 'lodash';
import { NetworkStatus } from '@apollo/client';
import {
  PlaceTypeEnum,
  SignalEntityTypeEnum,
  SignalTypeEnum,
  SearchOpeningEntityTagNameEnum,
  useGetSearchOpeningsV2Query,
  OpeningRemoteTypeEnum,
  GetSearchOpeningsV2Document,
  GetSearchOpeningsV2QueryVariables,
} from '@src/graphql/generated';
import { recordSignals } from '@src/graphql/signals/actions/recordSignals';
import { Context } from '@src/metrics/enums/context.enum';
import { Interaction } from '@src/metrics/enums/interaction.enum';
import { ItemType } from '@src/metrics/enums/itemType.enum';
import { useFeedUpdate } from '@src/customHooks';
import { getScrollableContainer } from '@src/App';
import { OPENING_STATE } from '@src/constants';
import { SignalItem } from '@src/graphql/signals/actions/recordSignals/interfaces/recordSignals.interface';
import { trackEvent } from '@src/metrics';
import { DEFAULT_CATEGORY } from '../../constants/categories';
import { Place } from '../../interfaces/place.interface';
import { UseSearchOpeningsParams, UseSearchOpeningsValue } from './interfaces/useSearchOpenings.interface';

const FIRST_BATCH_SIZE = 6;
const NEXT_BATCH_SIZE = 6;

export const useSearchOpenings = ({
  searchPattern,
  categoryType,
  places,
  shouldSkip,
  openingState = OPENING_STATE.ACTIVE,
}: UseSearchOpeningsParams): UseSearchOpeningsValue => {
  const [selectedLocations, selectedCountries, isRemote] = useMemo<[Place[], Place[], boolean]>(() => {
    const selectedLocations = places?.filter(selectedPlace => selectedPlace.type === PlaceTypeEnum.LOCATION) || [];
    const selectedCountries = places?.filter(selectedPlace => selectedPlace.type === PlaceTypeEnum.COUNTRY) || [];
    const isRemote = !!places?.some(selectedPlace => selectedPlace.type === PlaceTypeEnum.REMOTE);

    return [selectedLocations, selectedCountries, isRemote];
  }, [places?.map(place => place.id).toString()]);

  const variables: GetSearchOpeningsV2QueryVariables = useMemo(() => {
    return {
      categoryType: categoryType || DEFAULT_CATEGORY,
      paginationOptions: {
        limit: FIRST_BATCH_SIZE,
      },
      searchParams: {
        searchPattern: searchPattern,
        searchTagsWithAndCondition: [
          {
            tag: SearchOpeningEntityTagNameEnum.STATE,
            value: openingState,
          },
        ],
        searchTagsWithOrCondition: [
          ...selectedLocations.map(selectedLocation => {
            return {
              tag: SearchOpeningEntityTagNameEnum.POSITION_LOCATION,
              value: selectedLocation.id,
            };
          }),
          ...selectedCountries.map(selectedCountry => {
            return {
              tag: SearchOpeningEntityTagNameEnum.POSITION_COUNTRY,
              value: selectedCountry.id,
            };
          }),
          ...(isRemote
            ? [
                {
                  tag: SearchOpeningEntityTagNameEnum.REMOTE_TYPE,
                  value: OpeningRemoteTypeEnum.FULLY,
                },
              ]
            : []),
        ],
      },
    };
  }, [categoryType, searchPattern, selectedLocations, selectedCountries, isRemote, openingState]);

  const {
    data: openingsData,
    loading: isOpeningsLoading,
    refetch: openingsRefetch,
    fetchMore: openingsFetchMore,
    networkStatus: openingsNetworkStatus,
  } = useGetSearchOpeningsV2Query({
    notifyOnNetworkStatusChange: true,
    skip: shouldSkip,
    variables: variables,
  });

  const openings = openingsData?.searchOpeningsV2.result || [];
  const pagination = openingsData?.searchOpeningsV2.pagination;
  const initialLoadingStates = [NetworkStatus.loading, NetworkStatus.setVariables, NetworkStatus.refetch];

  const isOpeningsInitialLoading = initialLoadingStates.includes(openingsNetworkStatus);

  const { checkNewContent } = useFeedUpdate(
    GetSearchOpeningsV2Document,
    variables,
    'searchOpeningsV2.result',
    openingsRefetch,
    getScrollableContainer(),
  );

  useEffect(() => {
    if (pagination?.minCursor) {
      checkNewContent(pagination.minCursor, openings);
    }
  }, []);

  useEffect(() => {
    const sendMetricAndSignal = (currentLimit: number, feedLength: number) => {
      recordSignals({
        currentLimit: currentLimit,
        items: openings
          .map(item => ({
            id: item?.id,
            type: SignalEntityTypeEnum.OPENING,
          }))
          .filter((signalItem): signalItem is SignalItem => !!signalItem.id),
        signalType: SignalTypeEnum.VIEW_FEED_ITEM,
      });

      trackEvent({
        context: Context.DIGEST,
        interaction: Interaction.FETCH_MORE,
        itemType: ItemType.DIGEST_FEED,
        itemValue: feedLength,
      });
    };

    const fetchMoreSearchRelevantOpenings = async () => {
      const { data: fetchMoreResult } = await openingsFetchMore({
        variables: {
          paginationOptions: {
            limit: NEXT_BATCH_SIZE,
            minCursor: pagination?.maxCursor,
          },
        },
      });

      const result = [...openings, ...(fetchMoreResult?.searchOpeningsV2.result || [])];

      sendMetricAndSignal(fetchMoreResult.searchOpeningsV2.pagination.limit || 0, result.length);
    };

    const loadMore = throttle(async ({ target: { scrollTop, scrollHeight } }) => {
      const fetchMoreMargin = scrollTop + window.innerHeight * 3;

      if (!isOpeningsLoading && pagination?.hasMoreResults && fetchMoreMargin > scrollHeight) {
        await fetchMoreSearchRelevantOpenings();
      }
    }, 200);

    getScrollableContainer().addEventListener('scroll', loadMore);

    return () => {
      getScrollableContainer().removeEventListener('scroll', loadMore);
      loadMore.cancel();
    };
  }, [isOpeningsLoading, pagination]);

  return {
    isOpeningsInitialLoading,
    isOpeningsLoading,
    openings,
    pagination,
  };
};
