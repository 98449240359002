import React, { FC, useRef, useState } from 'react';
import classnames from 'classnames';
import VisibilitySensor from 'react-visibility-sensor';
import { PrimaryOpeningItem } from './PrimaryOpeningItem';
import { FeedItem } from './PrimaryOpeningItem/interfaces/feedItem.interface';
import { SnippetOpeningItem } from './SnippetOpeningItem';
import { JobOpeningFeedItemProps } from './props';
import './styles.scss';

export const JobOpeningFeedItem: FC<JobOpeningFeedItemProps> = ({
  isMatchShown,
  onClick,
  wrapperClassName,
  opening,
  metricsContext,
}) => {
  const feedItemHeight = useRef<HTMLDivElement | null>(null);
  const [feedItem, setFeedItem] = useState<FeedItem>({ height: 'inherit', isVisible: true });

  const renderOpeningItem = () => {
    if (opening.__typename === 'PaidOpeningWithSnippetsType' || opening.latestArticle !== null) {
      return <SnippetOpeningItem isMatchShown={isMatchShown} metricsContext={metricsContext} opening={opening} />;
    }

    return <PrimaryOpeningItem isMatchShown={isMatchShown} metricsContext={metricsContext} opening={opening} />;
  };

  return (
    <VisibilitySensor
      offset={{ bottom: -500, top: -500 }}
      onChange={isVisible => {
        if (feedItemHeight.current && feedItemHeight.current.clientHeight) {
          if (isVisible) {
            setFeedItem({
              height: 'inherit',
              isVisible: isVisible,
            });
          } else {
            setFeedItem({
              height: feedItemHeight.current.clientHeight,
              isVisible: isVisible,
            });
          }
        }
      }}
      partialVisibility
    >
      <div
        className={classnames('job-opening-feed-item feed-item-gap content-enter-animation', wrapperClassName)}
        onClick={onClick}
        ref={feedItemHeight}
        style={{ height: feedItem.height }}
      >
        {feedItem.isVisible && renderOpeningItem()}
      </div>
    </VisibilitySensor>
  );
};
