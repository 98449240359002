import { FieldMergeFunction } from '@apollo/client';
import { MergePaginatedResultsParam } from './interfaces/mergeUpdatablePaginatedResults.interface';

export const mergeUpdatablePaginatedResults: FieldMergeFunction<
  MergePaginatedResultsParam,
  MergePaginatedResultsParam
> = (existing, incoming, args) => {
  const existingPagination = existing?.pagination || {};
  const existingResult = existing?.result || [];

  const incomingPagination = incoming?.pagination || {};
  const incomingResult = incoming?.result || [];

  const mergeResult = {
    pagination: {
      ...existingPagination,
      ...incomingPagination,
      /*
       * We should keep initial minCursor unchanged because we need it to detect
       * if new content have been added while app was running in background.
       * More details how it is used can be checked in this function ->
       * src/customHooks/useFeedUpdate (function checkNewContent).
       */
      minCursor: existing?.pagination?.minCursor || incoming?.pagination?.minCursor,
    },
    // We use minCursor to detect wether user is fetching more data or updating the cache locally.
    // For initial fetch we need only incoming data because if there is existing data, feed will show duplicate results.
    // During pagination we need both existing and incoming results to not loose existing results from the feed.
    result: args.variables?.paginationOptions?.minCursor ? [...existingResult, ...incomingResult] : [...incomingResult],
  };

  return mergeResult;
};
