import React, { FC } from 'react';
import Svg from 'svg-inline-react';
import FrSearchSvg from 'assets/images/fr-search.svg';
import { Link } from 'react-router-dom';
import { SEARCH_ROUTE } from '@src/constants/routes';
import './styles.scss';

export const AppBarSearchButton: FC = () => {
  return (
    <Link className="app-bar-search" to={SEARCH_ROUTE}>
      <Svg className="app-bar-search__icon" src={FrSearchSvg} />
      <span className="app-bar-search__title">Search</span>
    </Link>
  );
};
