import qs from 'qs';
import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export const useQueryParams = <T>(): {
  queryParams: T;
  removeQueryParams: (queryParams: (keyof T)[]) => void;
  setQueryParams: (queryParams: T) => void;
  updateQueryParams: (queryParams: T) => void;
} => {
  const history = useHistory();

  const location = useLocation();

  const queryParams = useMemo<T>(
    () => qs.parse(location.search, { ignoreQueryPrefix: true }) as unknown as T,
    [location.search],
  );

  const updateQueryParams = (appliedQueryParams: T) => {
    history.replace({
      pathname: location.pathname,
      search: qs.stringify(
        {
          ...queryParams,
          ...appliedQueryParams,
        },
        { addQueryPrefix: false },
      ),
    });
  };

  const setQueryParams = (appliedQueryParams: T) => {
    history.replace({
      pathname: location.pathname,
      search: qs.stringify(
        {
          ...appliedQueryParams,
        },
        { addQueryPrefix: false },
      ),
    });
  };

  const removeQueryParams = (keys: (keyof T)[]) => {
    const newQueryParams = { ...queryParams };

    keys.forEach(key => {
      delete newQueryParams[key];
    });

    history.replace({
      pathname: location.pathname,
      search: qs.stringify(newQueryParams, { addQueryPrefix: false }),
    });
  };

  return {
    queryParams: queryParams,
    removeQueryParams: removeQueryParams,
    setQueryParams: setQueryParams,
    updateQueryParams: updateQueryParams,
  };
};
