import React from 'react';
import PropTypes from 'prop-types';
import FadeInImage from '@src/components/FadeInImage';
import './styles.scss';

export const SuggestionItem = ({ title, imageSrc, onClick }) => {
  return (
    <div className="suggestion-item" onClick={onClick}>
      <FadeInImage className="suggestion-item__image" src={imageSrc} />
      <h3>{title}</h3>
    </div>
  );
};

export const SuggestionItemSkeleton = () => {
  return (
    <div className="suggestion-item suggestion-item--skeleton pulse-fade">
      <div className="suggestion-item__image" />
      <h3 />
    </div>
  );
};

SuggestionItem.propTypes = {
  imageSrc: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};
