import React, { FC } from 'react';
import classnames from 'classnames';
import { Avatar } from '@src/components/Avatar';
import FadeInImage from '@src/components/FadeInImage';
import { AvatarWithInfoProps } from './props';
import './styles.scss';

export const AvatarWithInfo: FC<AvatarWithInfoProps> = ({
  isDark,
  wrapperClassName,
  name,
  subTitle,
  avatarProps,
  fadeInImageProps,
  detailsClassName,
}) => {
  return (
    <div className={classnames('user-info', wrapperClassName)}>
      <div className="user-info__images">
        <div className="user-info__avatar">
          <Avatar {...avatarProps} />
        </div>

        {fadeInImageProps && <FadeInImage {...fadeInImageProps} className="user-info__company-logo" />}
      </div>
      <div className={classnames('user-info__details', isDark && 'user-info__details--white', detailsClassName)}>
        <p>{name}</p>
        <p>{subTitle}</p>
      </div>
    </div>
  );
};
