import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import createLink from '@src/util/createLink';

const LinkBase = Component =>
  function Link({ children, to, params, queryParams, innerRef, ...props }) {
    Link.propTypes = {
      children: PropTypes.node,
      innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
      params: PropTypes.object,
      queryParams: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
      to: PropTypes.string.isRequired,
    };

    return (
      <Component
        {...props}
        ref={innerRef}
        to={createLink(to, {
          params,
          queryParams,
        })}
      >
        {children}
      </Component>
    );
  };

export const Link = LinkBase(RouterLink);
