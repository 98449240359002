import { useEffect, useLayoutEffect } from 'react';
import { get, throttle, size } from 'lodash';
import { NetworkStatus } from '@apollo/client';
import moment from 'moment';
import { useFeedUpdate, usePrevious } from '@src/customHooks';

import { trackEvent } from '@src/metrics';
import { isOpeningType } from '@src/graphql/typeGuards';
import { recordSignals } from '@src/graphql/signals/actions/recordSignals';
import {
  GetSearchOpeningsLatestFeedDocument,
  OpeningRemoteTypeEnum,
  OpeningSearchEntitySortOptionEnum,
  SignalEntityTypeEnum,
  SignalTypeEnum,
  useGetSearchOpeningsLatestFeedQuery,
} from '@src/graphql/generated';
import { Context } from '@src/metrics/enums/context.enum';
import { Interaction } from '@src/metrics/enums/interaction.enum';
import { ItemType } from '@src/metrics/enums/itemType.enum';
import { GetFeed } from './interfaces/getFeed.interface';
import { GetPagination } from './interfaces/getPagination.interface';
import { UseFeed } from './interfaces/useFeed.interface';

const FIRST_BATCH_SIZE = 6;
const NEXT_BATCH_SIZE = 6;
const FEED_KEY = 'searchOpenings';

const getFeed: GetFeed = data => get(data, `${FEED_KEY}.result`) || [];
const getPagination: GetPagination = data => get(data, `${FEED_KEY}.pagination`) || {};

const getWorkOptionLabel = (workOptions: OpeningRemoteTypeEnum[]): string => {
  const workOption = workOptions
    .map((workOption?: OpeningRemoteTypeEnum) => {
      switch (workOption) {
        case OpeningRemoteTypeEnum.ONSITE:
          return 'On-site';
        case OpeningRemoteTypeEnum.FULLY:
          return 'Remote';
        case OpeningRemoteTypeEnum.HYBRID:
          return 'Hybrid';
        default:
          return '';
      }
    })
    .sort()
    .join(',');

  return workOption;
};

export const useFeed: UseFeed = ({
  getScrollableContainer,
  searchTagsWithAndCondition,
  groupSearchTagsWithOrCondition,
  searchTagsWithOrCondition,
  searchPattern,
  workOptions,
}) => {
  const activeSearchTagsWithAndCondition = [...searchTagsWithAndCondition, { tag: 'STATE', value: 'active' }];

  const {
    data,
    loading: isFeedLoading,
    fetchMore,
    refetch,
    networkStatus,
  } = useGetSearchOpeningsLatestFeedQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    variables: {
      paginationOptions: {
        limit: FIRST_BATCH_SIZE,
        sortBy: OpeningSearchEntitySortOptionEnum.RECENCY_AND_RELEVANCY,
      },
      searchParams: {
        groupSearchTagsWithOrCondition: groupSearchTagsWithOrCondition,
        searchPattern: searchPattern,
        searchTagsWithAndCondition: activeSearchTagsWithAndCondition,
        searchTagsWithOrCondition: searchTagsWithOrCondition,
      },
    },
  });

  const pagination = getPagination(data);
  const feed = getFeed(data);

  const isRefetchLoading = [NetworkStatus.refetch, NetworkStatus.setVariables].includes(networkStatus);
  const previousFeedLoading = usePrevious(isFeedLoading);

  useEffect(() => {
    if (previousFeedLoading && !isFeedLoading && feed && feed?.length <= FIRST_BATCH_SIZE) {
      const firstItem = feed[0];
      const hasAdditionalFilters = groupSearchTagsWithOrCondition && groupSearchTagsWithOrCondition.length > 0;

      if (firstItem && isOpeningType(firstItem)) {
        const secondDate = firstItem.publishedAt;
        trackEvent({
          context: Context.SEARCH_RESULTS,
          interaction: Interaction.FEED_LOADED,
          itemType: ItemType.TYPE,
          itemValue: getWorkOptionLabel(workOptions),
          payload: {
            firstOfferAgeInDays: moment().diff(secondDate, 'days'),
            hasAdditionalFilters: hasAdditionalFilters,
            searchPattern: searchPattern,
            totalCount: pagination?.totalCount,
          },
        });
      } else {
        trackEvent({
          context: Context.SEARCH_RESULTS,
          interaction: Interaction.FEED_LOADED,
          itemType: ItemType.TYPE,
          itemValue: getWorkOptionLabel(workOptions),
          payload: {
            hasAdditionalFilters: hasAdditionalFilters,
            searchPattern: searchPattern,
            totalCount: pagination?.totalCount || 0,
          },
        });
      }
    }
  }, [previousFeedLoading, isFeedLoading, feed, pagination, groupSearchTagsWithOrCondition]);

  const { checkNewContent } = useFeedUpdate(
    GetSearchOpeningsLatestFeedDocument,
    {
      searchParams: {
        groupSearchTagsWithOrCondition: groupSearchTagsWithOrCondition,
        searchPattern: searchPattern,
        searchTagsWithAndCondition: activeSearchTagsWithAndCondition,
        searchTagsWithOrCondition: searchTagsWithOrCondition,
      },
    },
    `${FEED_KEY}.result`,
    refetch,
    getScrollableContainer(),
  );

  useEffect(() => {
    checkNewContent(pagination?.minCursor, feed);
  }, []);

  const loadMore = throttle(async ({ target: { scrollTop, scrollHeight } }) => {
    const fetchMoreMargin = scrollTop + window.innerHeight * 3;

    if (!isFeedLoading && pagination && pagination.hasMoreResults && fetchMoreMargin > scrollHeight) {
      const { data: fetchMoreResult } = await fetchMore({
        variables: {
          paginationOptions: {
            limit: NEXT_BATCH_SIZE,
            minCursor: pagination.maxCursor,
            sortBy: OpeningSearchEntitySortOptionEnum.RECENCY_AND_RELEVANCY,
          },
          searchParams: {
            groupSearchTagsWithOrCondition: groupSearchTagsWithOrCondition,
            searchPattern: searchPattern,
            searchTagsWithAndCondition: activeSearchTagsWithAndCondition,
            searchTagsWithOrCondition: searchTagsWithOrCondition,
          },
        },
      });

      recordSignals({
        currentLimit: pagination.limit,
        items: feed.filter(isOpeningType).map(item => ({
          id: item?.id,
          type: SignalEntityTypeEnum.OPENING,
        })),
        signalType: SignalTypeEnum.VIEW_FEED_ITEM,
      });

      const newResult = fetchMoreResult[FEED_KEY].result;

      trackEvent({
        context: Context.SEARCH_RESULTS,
        interaction: Interaction.FETCH_MORE,
        itemType: ItemType.LATEST_OFFERS_FEED,
        itemValue: size([...feed, ...newResult]),
      });
    }
  }, 200);

  useLayoutEffect(() => {
    getScrollableContainer().addEventListener('scroll', loadMore);

    return () => {
      getScrollableContainer().removeEventListener('scroll', loadMore);
      loadMore.cancel();
    };
  }, [isFeedLoading, pagination]);

  return {
    feed: feed,
    hasMoreResults: !!pagination?.hasMoreResults,
    isLoading: isFeedLoading,
    isRefetchLoading: isRefetchLoading,
    loadMore: loadMore,
    totalCount: pagination?.totalCount,
  };
};
