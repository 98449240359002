import React from 'react';
import PropTypes from 'prop-types';
import Svg from 'svg-inline-react';
import classnames from 'classnames';
import CheckSvg from 'assets/images/check.svg';
import FadeInImage from '@src/components/FadeInImage';
import '../styles.scss';

export const ImageSize = {
  MEDIUM: 'medium',
  SMALL: 'small',
};

export const HorizontalCard = ({
  imageUrl,
  title,
  subTitle,
  imageSize,
  svgSrc,
  imageClassName,
  sent,
  wrapperClassName,
  infoClassName,
  onClick,
  additionalElement,
}) => {
  return (
    <div className={classnames('horizontal-card', wrapperClassName)} onClick={e => onClick && onClick(e)}>
      <div className="horizontal-card__content">
        {imageUrl ? (
          <FadeInImage
            className={classnames(
              'horizontal-card__image',
              imageSize && `horizontal-card__image--${imageSize}`,
              imageClassName,
            )}
            src={imageUrl}
          />
        ) : (
          <Svg
            className={classnames(
              'horizontal-card__image',
              imageSize && `horizontal-card__image--${imageSize}`,
              imageClassName,
            )}
            src={svgSrc}
          />
        )}
        <div className={classnames('horizontal-card__info', infoClassName)}>
          <h3>{title}</h3>
          <p>{subTitle}</p>
        </div>
      </div>

      {additionalElement}

      {sent && <Svg className="horizontal-card__check-icon" src={CheckSvg} />}
    </div>
  );
};

HorizontalCard.defaultProps = {
  imageSize: ImageSize.SMALL,
};

const requiredPropsCheck = (props, propName, componentName) => {
  if (!props.svgSrc && !props.imageUrl) {
    return new Error(`One of 'svgSrc' or 'imageUrl' is required by '${componentName}' component.`);
  }
};

HorizontalCard.propTypes = {
  additionalElement: PropTypes.node,
  imageClassName: PropTypes.string,
  imageSize: PropTypes.oneOf(Object.values(ImageSize)),
  imageUrl: requiredPropsCheck,
  infoClassName: PropTypes.string,
  onClick: PropTypes.func,
  sent: PropTypes.bool,
  subTitle: PropTypes.string,
  svgSrc: requiredPropsCheck,
  title: PropTypes.string.isRequired,
  wrapperClassName: PropTypes.string,
};
