import React, { FC } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Svg from 'svg-inline-react';
import FrChevronRight from 'assets/images/fr-chevron-right.svg';
import { trackEvent } from '@src/metrics';
import { REGISTER_EMPLOYER_URL } from '@src/constants/links';
import { shareLink } from '@src/util/shareLink';
import { SubPageAppBar } from '@src/components/PrimaryAppBar';
import { registerForPush } from '@src/container';
import { INVITE_LINK } from '@src/constants';
import { useGetUserQuery, UserMetaPlatformEnum } from '@src/graphql/generated';
import { Context } from '@src/metrics/enums/context.enum';
import { Interaction } from '@src/metrics/enums/interaction.enum';
import { ItemType } from '@src/metrics/enums/itemType.enum';
import { INBOX_PROFILE_ROUTE, PROFILE_REMOVE_ROUTE, SETTINGS_FEEDBACK_ROUTE } from '@src/constants/routes';
import './styles.scss';

export const Settings: FC = () => {
  const history = useHistory();

  const { data: userData } = useGetUserQuery();

  const user = userData?.currentUser;
  const platform = user?.meta?.platform;

  return (
    <div className="settings content-enter-animation">
      <SubPageAppBar isDefaultActionsHidden isFixed onBack={history.goBack}>
        <h3 className="settings__header-title">Settings</h3>
      </SubPageAppBar>
      <div className="settings__body">
        {!user?.pushRegistered && platform !== UserMetaPlatformEnum.WEB && (
          <a className="settings__link" onClick={registerForPush}>
            <span>Enable notifications</span>
            <Svg className="settings__link-icon" src={FrChevronRight} />
          </a>
        )}

        <Link className="settings__link" to={INBOX_PROFILE_ROUTE}>
          <span>Change my profile</span>
          <Svg className="settings__link-icon" src={FrChevronRight} />
        </Link>

        <a
          className="settings__link"
          href={`${REGISTER_EMPLOYER_URL}?promo=cm-profile-menu`}
          onClick={() =>
            trackEvent({
              context: Context.EXPLORE,
              interaction: Interaction.OPEN,
              itemType: ItemType.HIRE_WITH_MEETFRANK,
              itemValue: 'Profile menu',
            })
          }
          target="_"
        >
          <span>Hire with MeetFrank</span>
          <Svg className="settings__link-icon" src={FrChevronRight} />
        </a>

        <a
          className="settings__link"
          onClick={() => {
            shareLink(INVITE_LINK);

            trackEvent({
              context: Context.INVITE,
              interaction: Interaction.SHARE,
              itemType: ItemType.LINK,
              itemValue: INVITE_LINK,
            });
          }}
        >
          <span>Invite friends</span>
          <Svg className="settings__link-icon" src={FrChevronRight} />
        </a>

        <Link className="settings__link" to={SETTINGS_FEEDBACK_ROUTE}>
          <span>Send feedback</span>
          <Svg className="settings__link-icon" src={FrChevronRight} />
        </Link>

        <Link className="settings__link" to={PROFILE_REMOVE_ROUTE}>
          <span>Delete my account</span>
          <Svg className="settings__link-icon" src={FrChevronRight} />
        </Link>
      </div>
    </div>
  );
};
