import React, { FC } from 'react';
import { HorizontalScroller } from '@src/components/HorizontalScroller';
import { FOR_YOU } from '../../constants/feedTypes';
import { CategoryItem } from './CategoryItem';
import { CategoriesProps } from './props';
import './styles.scss';

export const Categories: FC<CategoriesProps> = ({
  onCategoryClick,
  onForYouClick,
  isForYouActive,
  activeCategory,
  categories,
  areSvgIconsShown = true,
  isLoading,
}) => {
  return (
    <HorizontalScroller className="jobs-search-categories" containerClassName="jobs-search-categories__container">
      <CategoryItem
        key={FOR_YOU.name}
        onClick={() => onForYouClick()}
        isActive={isForYouActive}
        src={areSvgIconsShown ? FOR_YOU.svg : undefined}
        isLoading={isLoading}
        name={FOR_YOU.label}
      />

      {categories.map(category => {
        return (
          <CategoryItem
            key={category.name}
            onClick={() => onCategoryClick(category)}
            isActive={!isForYouActive && activeCategory === category.type}
            src={areSvgIconsShown ? category.svg : undefined}
            isLoading={isLoading}
            name={category.name}
          />
        );
      })}

      <div className="jobs-search-categories__blur-right"></div>
    </HorizontalScroller>
  );
};
