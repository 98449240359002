import gql from 'graphql-tag';

export const CompanyType = {
  fragments: {
    openingFeedItemCompany: gql`
      fragment OpeningFeedItemCompany on CompanyType {
        id
        deepLinkUrl
        registeredEmployeesCount
        hasActiveSubscription
        location {
          id
          country {
            id
            shortName
          }
        }
        types {
          id
          key
          name
        }
        industries {
          id
          name
        }
        reviewsOverview {
          totalCount
          ratings {
            workLifeBalance
            compensationBenefits
            companyCulture
            management
            product
            total
          }
        }
        reviews(paginationOptions: { limit: 3 }) {
          pagination {
            totalCount
          }
          result {
            id
            type

            ... on CompanyRatingReviewType {
              id
              title
              positiveFeedback
              negativeFeedback
              ratings {
                workLifeBalance
                compensationBenefits
                companyCulture
                management
                product
                total
              }
            }
          }
        }
        frankMessage
        profile {
          id
          name
          serviceDescription
          missionStatement
          latestValuation {
            min
            max
            currency {
              id
              symbol
            }
          }
          latestFunding {
            fundedAt
            value
            round
            currency {
              id
              symbol
            }
          }
          employeesCount
          totalFunding
          founded
          offices {
            isFoundingLocation
            isHeadquarter
            location {
              id
              shortName
              country {
                id
                shortName
              }
            }
          }
          companySize {
            id
            name
          }
          revenue {
            id
            key
            value
          }
          logo {
            id
            fullUrl(width: 500, height: 500)
          }
          headerImage {
            id
            fullUrl(width: 1000, height: 500)
          }
          # Deprecated: Use offices instead
          locations {
            id
            shortName
            country {
              id
              shortName
            }
          }
          officeDescription
          officeImages {
            id
            fullUrl(width: 1000, height: 500)
          }
          benefits {
            id
            key
            value
          }
        }
      }
    `,
    reviewBlockItemCompany: gql`
      fragment ReviewBlockItemCompany on CompanyType {
        id
        hiringState
        deepLinkUrl
        reviewsOverview {
          totalCount
          reviewForCurrentUser {
            id
          }
          ratings {
            total
          }
        }
        profile {
          id
          name
          logo {
            id
            fullUrl(width: 500, height: 500)
          }
          offices {
            isFoundingLocation
            isHeadquarter
            location {
              id
              shortName
            }
          }
        }
      }
    `,
  },
};
