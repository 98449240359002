import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import pluralize from 'pluralize';
import { OverallScore } from '@src/views/CompanyReviews/OverallScore';
import { ScoresTable } from '@src/views/CompanyReviews/ScoresTable';
import { COMPANY_REVIEWS_ROUTE } from '@src/constants/routes';
import createLink from '@src/util/createLink';
import { isCompanyRatingReviewType } from '@src/graphql/typeGuards';
import { ReviewItem } from './ReviewItem';
import { ReviewsProps } from './props';
import './styles.scss';

export const Reviews: FC<ReviewsProps> = ({
  companyId,
  companyName,
  registeredEmployeesCount,
  reviews,
  reviewsOverview,
}) => {
  const totalRating = reviewsOverview?.ratings.total;
  const totalRatingCount = reviewsOverview?.totalCount;
  const reviewsData = reviews?.result || [];
  const totalReviews = reviews?.pagination.totalCount;
  const hasCommentedReviews =
    reviewsData?.[0]?.positiveFeedback || reviewsData?.[0]?.negativeFeedback || reviewsData?.[0]?.title;

  return (
    <div className="opening-feed-item-reviews">
      {!hasCommentedReviews ? (
        <Link
          className="opening-feed-item-reviews__ratings-card"
          to={createLink({
            link: COMPANY_REVIEWS_ROUTE,
            params: { companyId },
          })}
        >
          <div className="opening-feed-item-reviews__ratings">
            {totalRatingCount && (
              <div className="opening-feed-item-reviews__score-wrapper">
                <OverallScore averageRating={totalRating || 0} totalRatings={totalRatingCount} />
              </div>
            )}

            {reviewsOverview && (
              <div className="opening-feed-item-reviews__scores-wrapper">
                <ScoresTable ratings={reviewsOverview?.ratings} />
              </div>
            )}
          </div>
        </Link>
      ) : (
        <div className="opening-feed-item-reviews__comments">
          {registeredEmployeesCount > 0 && (
            <div className="opening-feed-item-reviews__comments-header">
              {registeredEmployeesCount} {companyName} {pluralize('employees', registeredEmployeesCount)}
              &nbsp;are on <span>MeetFrank </span>
            </div>
          )}

          <div className="opening-feed-item-reviews__list">
            {reviewsData
              .filter(isCompanyRatingReviewType)
              .slice(0, 3)
              .map(review => (
                <ReviewItem key={review.id} review={review} />
              ))}
          </div>
          <Link
            className="opening-feed-item-reviews__view-all-reviews"
            to={createLink({
              link: COMPANY_REVIEWS_ROUTE,
              params: { companyId },
            })}
          >
            View All ({totalReviews})
          </Link>
        </div>
      )}
    </div>
  );
};
