import React, { FC } from 'react';
import classnames from 'classnames';
import Svg from 'svg-inline-react';
import FrRemoveSvg from 'assets/images/fr-close-small.svg';
import FadeInImage from '@src/components/FadeInImage';
import { ChipProps } from './props';
import './styles.scss';

export const Chip: FC<ChipProps> = ({ wrapperClassName, imageSrc, text, isRemovable, onRemove }) => {
  return (
    <div className={classnames('chip', !isRemovable && 'chip--padding-right', wrapperClassName)}>
      {imageSrc && <FadeInImage className="chip__image" src={imageSrc} />}
      <span className="chip__text">{text}</span>
      {isRemovable && (
        <div className="chip__icon-wrapper">
          <Svg className="chip__icon" onClick={onRemove && onRemove} src={FrRemoveSvg} />
        </div>
      )}
    </div>
  );
};
