import { useEffect } from 'react';
import { get, some } from 'lodash';
import Visibility from 'visibilityjs';
import { DocumentNode } from 'graphql';
import { TypedDocumentNode } from '@apollo/client';
import store from '@src/store';
import { apolloClient } from '@src/graphql/apollo';
import { setToastMessageContent } from '@src/redux/actions/toastMessage';
import { UseFeedUpdateValue } from './interfaces/useFeedUpdate.interface';
import { FeedItem } from './interfaces/feedItem.interface';

export const useFeedUpdate = <T>(
  query: DocumentNode | TypedDocumentNode<any, any>,
  variables: T,
  key: string,
  refetch: () => void,
  scrollableContainer: HTMLDivElement,
): UseFeedUpdateValue => {
  useEffect(() => {
    let initialTime = 0;
    const limit = 5 * 60; // 5 min.

    const listener = Visibility.change(function () {
      if (Visibility.hidden()) {
        initialTime = new Date().getTime() / 1000;
      } else {
        const currentTime = new Date().getTime() / 1000;

        if (currentTime - initialTime > limit) {
          if (scrollableContainer) {
            scrollableContainer.scrollTop = 0;
          }

          refetch?.();
        }
      }
    });

    return () => {
      Visibility.unbind(listener);
    };
  }, [scrollableContainer, refetch]);

  const checkNewContent = async (initialMinCursor: string | number, feedItems: (FeedItem | null)[]) => {
    if (initialMinCursor && feedItems && feedItems.length > 25) {
      const { data } = await apolloClient.query({
        fetchPolicy: 'no-cache',
        query: query,
        variables: {
          ...variables,
          paginationOptions: {
            limit: 1,
            maxCursor: initialMinCursor,
          },
        },
      });

      const doesItemAlreadyExists = some(feedItems, opening => {
        return opening?.id === get(data, `${key}[0].id`);
      });

      if (!!get(data, key) && !!get(data, key).length && !doesItemAlreadyExists) {
        store.dispatch(
          setToastMessageContent({
            message: 'New content',
            onMessageClick: refetch,
          }),
        );
      }
    }
  };

  return { checkNewContent };
};
