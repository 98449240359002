import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { values, isEmpty } from 'lodash';
import classnames from 'classnames';
import { htmlifyWithEscape } from '@src/util/htmlifyMessage';
import { BlueButton } from '@src/components/Button';
import './styles.scss';

export default class Continue extends PureComponent {
  static propTypes = {
    action: PropTypes.object.isRequired,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    selectedValue: PropTypes.object,
  };

  response = () => {
    const { action, onClick } = this.props;

    if (action) {
      onClick({ action });
    } else {
      onClick();
    }
  };

  render() {
    const { className, disabled, action, selectedValue } = this.props;
    let { value } = action;

    if (action.skippable && !isEmpty(values(selectedValue)) && values(selectedValue)[0] !== '') {
      value = 'Continue';
    }

    const children =
      !value || value === '' || value === 'Continue' ? (
        <span>Continue</span>
      ) : (
        <span
          className="frank-continue__button-children"
          dangerouslySetInnerHTML={{ __html: htmlifyWithEscape(value) }}
        />
      );

    return (
      <div className={classnames('frank-continue', className)}>
        <BlueButton className="frank-continue__button" disabled={disabled} isFilled onClick={this.response}>
          {children}
        </BlueButton>
      </div>
    );
  }
}
