import React, { FC } from 'react';
import pluralize from 'pluralize';
import { Props } from './props';
import './styles.scss';

export const OffersCount: FC<Props> = ({ count }) => {
  return (
    <div className="search-result-count">
      <div className="search-result-count__info">
        {!!count && (
          <div className="search-result-count__count">
            {count > 1000 ? '1k+ job offers' : pluralize('job offers', count, true)}
          </div>
        )}
      </div>
    </div>
  );
};
