import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { ApolloLink } from '@apollo/client';
import config from '@src/config';
import { getToken } from '../utils/getToken';
import { createAuthHeader } from '../utils/createAuthHeader';
import { handleTokenRefresh } from '../utils/handleTokenRefresh';
import { isTokenExpired } from '../utils/isTokenExpired';

const checkAndUpdateToken = () => {
  return new Promise<string | undefined>(resolve => {
    const accessToken = getToken();

    if (accessToken && isTokenExpired(accessToken)) {
      handleTokenRefresh(newAccessToken => {
        resolve(newAccessToken);
      });

      return;
    }

    resolve(accessToken);
  });
};

export const wsLink: ApolloLink = new GraphQLWsLink(
  createClient({
    connectionParams: async () => {
      const accessToken = await checkAndUpdateToken();

      return {
        authorization: accessToken ? createAuthHeader(accessToken) : null,
      };
    },
    retryAttempts: 100,
    shouldRetry: () => true,
    url: config.graphqlSocketUri,
  }),
);
