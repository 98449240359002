import { NetworkStatus } from '@apollo/client';
import {
  GetInboxMessageThreadsQuery,
  MessageThreadStateEnum,
  UserApplicationStateEnum,
  useGetInboxMessageThreadsQuery,
  useInboxMessageThreadUpdatedSubscription,
} from '@src/graphql/generated';
import { handleFetchMore } from '@src/util/handleFetchMore';
import { UseMessageThreadsParams, UseMessageThreadsValue } from './interfaces/useMessageThreads.interface';

const FIRST_BATCH_LIMIT = 9;
const FETCH_MORE_LIMIT = 5;

export const useMessageThreads = ({ onNewApplicationReceived }: UseMessageThreadsParams): UseMessageThreadsValue => {
  const {
    data: messageThreadsData,
    loading: isMessageThreadsLoading,
    networkStatus: messageThreadsNetworkStatus,
    fetchMore: fetchMoreMessageThreads,
    updateQuery: updateMessageThreadsQuery,
  } = useGetInboxMessageThreadsQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      filterInput: {
        states: [MessageThreadStateEnum.OPEN],
      },
      paginationOptions: {
        limit: FIRST_BATCH_LIMIT,
      },
    },
  });

  const messageThreads = messageThreadsData?.messageThreads.result || [];
  const messageThreadsPagination = messageThreadsData?.messageThreads.pagination;

  const isMessageThreadsInitialLoading = [
    NetworkStatus.loading,
    NetworkStatus.setVariables,
    NetworkStatus.refetch,
  ].includes(messageThreadsNetworkStatus);

  const loadMoreMessageThreads = () => {
    const hasPagination = messageThreadsPagination?.hasMoreResults && messageThreadsPagination?.maxCursor;

    if (!isMessageThreadsLoading && hasPagination) {
      handleFetchMore(fetchMoreMessageThreads, {
        updateQuery: (
          prev: GetInboxMessageThreadsQuery,
          { fetchMoreResult }: { fetchMoreResult: GetInboxMessageThreadsQuery },
        ) => {
          if (!fetchMoreResult) {
            return prev;
          }

          return {
            messageThreads: {
              ...prev.messageThreads,
              pagination: {
                ...prev.messageThreads.pagination,
                hasMoreResults: fetchMoreResult.messageThreads.pagination.hasMoreResults,
                maxCursor: fetchMoreResult.messageThreads.pagination.maxCursor,
              },
              result: [...prev.messageThreads.result, ...fetchMoreResult.messageThreads.result],
            },
          };
        },
        variables: {
          paginationOptions: {
            limit: FETCH_MORE_LIMIT,
            minCursor: messageThreadsPagination.maxCursor,
          },
        },
      });
    }
  };

  useInboxMessageThreadUpdatedSubscription({
    onSubscriptionData: ({ subscriptionData }) => {
      const userState = subscriptionData.data?.messageThreadUpdated.updatedMessageThread.application?.userState;

      if (userState?.state === UserApplicationStateEnum.PENDING) {
        onNewApplicationReceived?.();

        return;
      }

      updateMessageThreadsQuery(prev => {
        if (!subscriptionData.data?.messageThreadUpdated.updatedMessageThread || !prev?.messageThreads) {
          return prev;
        }

        const updatedThreadId = subscriptionData.data.messageThreadUpdated.updatedMessageThread.id;

        const isMessageInList = prev.messageThreads.result.some(messageThread => {
          return messageThread?.id === subscriptionData.data?.messageThreadUpdated.updatedMessageThread.id;
        });

        if (!isMessageInList) {
          return {
            ...prev,
            messageThreads: {
              ...prev.messageThreads,
              pagination: {
                ...prev.messageThreads.pagination,
                totalCount: prev.messageThreads.pagination.totalCount + 1,
              },
              result: [
                ...(subscriptionData.data?.messageThreadUpdated.updatedMessageThread
                  ? [
                      {
                        ...subscriptionData.data?.messageThreadUpdated.updatedMessageThread,
                      },
                    ]
                  : []),
                ...prev.messageThreads.result,
              ],
            },
          };
        }

        return {
          ...prev,
          messageThreads: {
            ...prev.messageThreads,
            result: prev.messageThreads.result.map(messageThread => {
              if (messageThread?.id === updatedThreadId) {
                return {
                  ...messageThread,
                  ...subscriptionData.data?.messageThreadUpdated,
                  __typename: messageThread?.__typename,
                  id: messageThread?.id || updatedThreadId,
                };
              }

              return messageThread;
            }),
          },
        };
      });
    },
  });

  return {
    isMessageThreadsInitialLoading: isMessageThreadsInitialLoading,
    isMessageThreadsLoading: isMessageThreadsLoading,
    loadMoreMessageThreads: loadMoreMessageThreads,
    messageThreads: messageThreads,
    messageThreadsPagination: messageThreadsPagination,
  };
};
