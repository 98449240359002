import { useEffect } from 'react';
import { useLocalStorage } from '@src/localStorage/hooks/useLocalStorage';
import { CountryType, useGetCountryByIdLazyQuery } from '@src/graphql/generated';
import { ALL_PLACE_ID } from '@src/constants/placeIds';
import { CountriesData, UseCountryFilterParams, UseCountryFilterValue } from './interfaces/useCountryFilter.interface';

export const useCountryFilter = ({ localStorageKey }: UseCountryFilterParams): UseCountryFilterValue => {
  const [savedCountriesData, setSavedCountriesData] = useLocalStorage<CountriesData>(localStorageKey);

  const activeCountryId = savedCountriesData?.activeCountryId || ALL_PLACE_ID;
  const savedCountries = savedCountriesData?.countries || [];

  const addCountries = (countries: CountryType[], activeCountryId?: string) => {
    setSavedCountriesData({
      activeCountryId: activeCountryId,
      countries: countries,
    });
  };

  const setActiveCountry = (countryId: string) => {
    setSavedCountriesData({
      activeCountryId: countryId,
      countries: savedCountries,
    });
  };

  const [fetchCountryById, { data }] = useGetCountryByIdLazyQuery();

  const country = data?.country;

  useEffect(() => {
    if (activeCountryId !== ALL_PLACE_ID) {
      fetchCountryById({ variables: { id: activeCountryId } });
    }
  }, [activeCountryId]);

  return {
    activeCountryId: activeCountryId,
    addCountries: addCountries,
    country: activeCountryId !== ALL_PLACE_ID ? country : undefined,
    savedCountries: savedCountries,
    setActiveCountry: setActiveCountry,
  };
};
