import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Svg from 'svg-inline-react';
import EditSvg from 'assets/images/fr-edit.svg';
import './styles.scss';

function SectionHeaderWithEdit({ title, subTitle, onEdit, wrapperClassName }) {
  return (
    <div className={classNames('section-header-with-edit', wrapperClassName)}>
      <div className="section-header-with-edit__content">
        <h3>{title}</h3>
        <p>{subTitle}</p>
      </div>
      {onEdit && (
        <div className="section-header-with-edit__action" onClick={onEdit}>
          <span>Edit</span>
          <Svg className="section-header-with-edit__icon" src={EditSvg} />
        </div>
      )}
    </div>
  );
}

SectionHeaderWithEdit.propTypes = {
  onEdit: PropTypes.func,
  subTitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  wrapperClassName: PropTypes.string,
};

export default SectionHeaderWithEdit;
