import { useCallback, useEffect, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import Visibility from 'visibilityjs';
import { NetworkStatus } from '@apollo/client';
import { throttle } from 'lodash';
import {
  GetInboxMessageThreadQueryVariables,
  InboxMessageAddedDocument,
  InboxMessageAddedSubscription,
  PaginationSortOrderEnum,
  useGetInboxMessageThreadQuery,
} from '@src/graphql/generated';
import { CHAT_ROUTE } from '@src/constants';
import { Context } from '@src/metrics/enums/context.enum';
import { Interaction } from '@src/metrics/enums/interaction.enum';
import { ItemType } from '@src/metrics/enums/itemType.enum';
import { trackEvent } from '@src/metrics';
import { UseMessageThreadParams, UseMessageThreadValue } from './interfaces/useMessageThread.interface';

const FIRST_BATCH_SIZE = 14;
const NEXT_BATCH_SIZE = 8;

export const useMessageThread = ({
  onCompleted,
  onRefetchCompleted,
  onMessageReceived,
  messagesWrapperRef,
}: UseMessageThreadParams | undefined = {}): UseMessageThreadValue => {
  const match = useRouteMatch<{ threadId?: string }>({
    path: CHAT_ROUTE,
    sensitive: true,
    strict: true,
  });

  const threadId = match?.params.threadId;

  const messageThreadVariables = useMemo<GetInboxMessageThreadQueryVariables | undefined>(() => {
    if (!threadId) {
      return undefined;
    }

    return {
      messageThreadId: threadId,
      messagesPaginationOptions: {
        limit: FIRST_BATCH_SIZE,
        sortOrder: PaginationSortOrderEnum.DESC,
      },
    };
  }, [threadId]);

  const {
    loading: isMessagesLoading,
    data: messageThreadData,
    refetch: refetchMessageThread,
    subscribeToMore: subscribeToMoreMessageThread,
    updateQuery: updateMessageThreadQuery,
    networkStatus: messageThreadNetworkStatus,
    fetchMore: messagesFetchMore,
  } = useGetInboxMessageThreadQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: onCompleted,
    skip: !messageThreadVariables,
    variables: messageThreadVariables,
  });

  const initialLoadingStates = [NetworkStatus.loading, NetworkStatus.setVariables, NetworkStatus.refetch];

  const isMessageThreadInitialLoading = initialLoadingStates.includes(messageThreadNetworkStatus);

  const pagination = messageThreadData?.messageThread?.messages.pagination;
  const messages = messageThreadData?.messageThread?.messages.result || [];

  const handleRefetchMessageThread = useCallback(async () => {
    if (!messageThreadVariables) {
      return;
    }

    await refetchMessageThread(messageThreadVariables);

    onRefetchCompleted?.();
  }, [messageThreadVariables]);

  useEffect(() => {
    if (!messageThreadVariables) {
      return;
    }

    const unsubscribeToMoreMessageThread = subscribeToMoreMessageThread<InboxMessageAddedSubscription>({
      document: InboxMessageAddedDocument,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) {
          return prev;
        }

        const newMessage = subscriptionData.data.messageAdded;

        const doesMessageExist = prev?.messageThread?.messages.result.some(message => {
          return message?.id === newMessage.id;
        });

        if (doesMessageExist) {
          return prev;
        }

        onMessageReceived?.();

        return {
          ...prev,
          messageThread: {
            ...prev.messageThread,
            messages: {
              ...prev.messageThread.messages,
              result: [newMessage, ...prev.messageThread.messages.result],
            },
          },
        };
      },
      variables: {
        messageThreadId: messageThreadVariables.messageThreadId,
      },
    });

    return () => {
      unsubscribeToMoreMessageThread();
    };
  }, [messageThreadVariables]);

  useEffect(() => {
    const fetchMoreMessages = async () => {
      const { data: fetchMoreResult } = await messagesFetchMore({
        variables: {
          messagesPaginationOptions: {
            limit: NEXT_BATCH_SIZE,
            minCursor: pagination?.maxCursor,
            sortOrder: PaginationSortOrderEnum.DESC,
          },
        },
      });

      const result = [...messages, ...(fetchMoreResult?.messageThread.messages.result || [])];

      trackEvent({
        context: Context.CHAT,
        interaction: Interaction.FETCH_MORE,
        itemType: ItemType.MESSAGES,
        itemValue: result.length,
      });
    };

    const loadMore = throttle(async (scrollTop: number) => {
      if (!isMessagesLoading && pagination?.hasMoreResults && scrollTop <= window.innerHeight * 2) {
        await fetchMoreMessages();
      }
    }, 300);

    const handleScroll = async (event: Event) => {
      const scrollTop = (event?.target as HTMLDivElement)?.scrollTop;

      // do not allow to scroll to the 0px if loading is in progress to prevent missing messages
      if (messagesWrapperRef?.current && scrollTop === 0) {
        messagesWrapperRef.current.scrollTo(0, 1);
      }

      await loadMore(scrollTop);
    };

    messagesWrapperRef?.current?.addEventListener('scroll', handleScroll);

    return () => {
      messagesWrapperRef?.current?.removeEventListener('scroll', handleScroll);

      loadMore.cancel();
    };
  }, [isMessagesLoading, pagination]);

  useEffect(() => {
    const listener = Visibility.change(() => {
      if (Visibility.state() === 'visible') {
        handleRefetchMessageThread();
      }
    });

    return () => {
      Visibility.unbind(listener);
    };
  }, [messageThreadVariables, handleRefetchMessageThread]);

  return {
    isMessageThreadInitialLoading: isMessageThreadInitialLoading,
    isMessagesLoading: isMessagesLoading,
    messageThread: messageThreadData?.messageThread || undefined,
    messages: messages,
    pagination: pagination,
    refetchMessageThread: handleRefetchMessageThread,
    updateMessageThreadQuery: updateMessageThreadQuery,
  };
};
