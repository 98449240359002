import React, { FC } from 'react';
import Svg from 'svg-inline-react';
import { isEmpty } from 'lodash';
import FrKeySvg from 'assets/images/fr-key-big.svg';
import FrPinSvg from 'assets/images/fr-pin.svg';
import { CompanyLocationsProps } from './props';
import './styles.scss';

export const CompanyLocations: FC<CompanyLocationsProps> = ({ offices }) => {
  const headquarter = offices.filter(office => office.isHeadquarter);

  return (
    <div className="company-locations">
      <div className="company-locations__section">
        <Svg className="company-locations__section-icon" src={FrKeySvg} />
        <div className="company-locations__section-content">
          <h4 className="company-locations__section-title">Offices in</h4>
          <div className="company-locations__section-subtitle">
            {offices.map(office => office.location?.shortName).join(', ')}
          </div>
        </div>
      </div>
      {!isEmpty(headquarter) && (
        <div className="company-locations__section">
          <Svg className="company-locations__section-icon" src={FrPinSvg} />
          <div className="company-locations__section-content">
            <h4 className="company-locations__section-title">Headquartered in</h4>
            <div className="company-locations__section-subtitle">
              {headquarter.map(office => office.location?.shortName).join(', ')}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
