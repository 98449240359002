import PropTypes from 'prop-types';
import classnames from 'classnames';
import React, { Component } from 'react';
import Svg from 'svg-inline-react';
import FrSearchGraySvg from 'assets/images/fr-search-grey.svg';
import FrCloseSvg from 'assets/images/fr-close-small.svg';
import './styles.scss';

export default class Input extends Component {
  static propTypes = {
    className: PropTypes.string,
    clearable: PropTypes.bool,
    defaultValue: PropTypes.string,
    iconRight: PropTypes.bool,
    onChange: PropTypes.func,
    onClear: PropTypes.func,
    onFocus: PropTypes.func,
    placeholder: PropTypes.string,
    searchInput: PropTypes.bool,
    setValue: PropTypes.string,
    type: PropTypes.string,
    withBorder: PropTypes.bool,
    wrapperClassName: PropTypes.string,
  };

  state = {
    defaultSet: false,
    value: '',
  };

  componentDidMount() {
    const { defaultValue } = this.props;
    this.setDefault(defaultValue);
  }

  componentWillReceiveProps(nextProps) {
    const { defaultValue } = nextProps;
    this.setDefault(defaultValue);

    if (nextProps.setValue !== this.props.setValue) {
      this.setState({ value: nextProps.setValue });
    }
  }

  setDefault = defaultValue => {
    const { value, defaultSet } = this.state;

    if (!value && !defaultSet && defaultValue) {
      this.setState({ defaultSet: true, value: defaultValue });
    }
  };

  onChange = value => {
    const { onChange } = this.props;

    this.setState({ value });
    onChange(value);
  };

  handleClear = () => {
    this.setState({ value: '' });
    this.props.onClear();
  };

  render() {
    const { type, className, placeholder, onFocus, clearable, withBorder, searchInput, iconRight, wrapperClassName } =
      this.props;
    const { value } = this.state;

    return (
      <div
        className={classnames(
          'frank-input-wrapper',
          withBorder && 'frank-input-wrapper--bordered',
          iconRight && 'frank-input-wrapper--icon-right',
          !!searchInput && 'frank-input-wrapper--icon-left',
          wrapperClassName,
        )}
      >
        {searchInput && (
          <Svg
            className={classnames(
              'frank-input-wrapper__search-icon',
              !!value && 'frank-input-wrapper__search-icon--bold',
            )}
            src={FrSearchGraySvg}
          />
        )}

        <input
          className={classnames('frank-input-wrapper__input', className)}
          onChange={evt => this.onChange(evt.target.value)}
          onFocus={onFocus}
          placeholder={placeholder}
          type={type}
          value={value}
        />

        {iconRight && (
          <div className="frank-input-wrapper__icon-wrapper">
            {value && clearable && <Svg onClick={this.handleClear} src={FrCloseSvg} />}
          </div>
        )}
      </div>
    );
  }
}
