import React from 'react';
import PropTypes from 'prop-types';
import Svg from 'svg-inline-react';
import FrChevronRight from 'assets/images/fr-chevron-right.svg';
import FadeInImage from '@src/components/FadeInImage';
import { SalaryRange } from '@src/components/SalaryRange';
import './styles.scss';

function hasAttachment(notification) {
  return ['NEW_DIGEST_OFFER', 'NEW_DIGEST_OFFER_SCRAPED'].includes(notification.type) && notification.opening;
}

function selectCompanyImage(company) {
  const imageUrls =
    company.profile.officeImages
      ?.map(image => image?.fullUrl)
      .filter((imageUrl): imageUrl is string => typeof imageUrl === 'string') || [];

  if (imageUrls.length > 0) {
    return imageUrls[0];
  }

  return company.profile.headerImage.fullUrl;
}

const Attachment = ({ notification }) => {
  if (!hasAttachment(notification)) {
    return null;
  }

  const { opening } = notification;
  const { company } = opening;
  const imageUrl = selectCompanyImage(opening.company);
  const logoUrl = company.profile.logo.fullUrl;

  if (notification.type === 'NEW_DIGEST_OFFER') {
    return (
      <div className="notification-attachment">
        <div className="notification-attachment__opening">
          {imageUrl && <FadeInImage className="notification-attachment__opening-image" src={imageUrl} />}
          {logoUrl && <FadeInImage className="notification-attachment__opening-logo-floating" src={logoUrl} />}
          <div className="notification-attachment__opening-details-wrapper">
            <div className="notification-attachment__opening-details">
              <span className="notification-attachment__opening-title">{opening.title}</span>
              <span className="notification-attachment__opening-subtitle">
                {opening.salaryRange ? <SalaryRange salaryRange={opening.salaryRange} /> : opening.company.profile.name}
              </span>
            </div>
            <Svg src={FrChevronRight} />
          </div>
        </div>
      </div>
    );
  } else if (notification.type === 'NEW_DIGEST_OFFER_SCRAPED') {
    return (
      <div className="notification-attachment">
        <div className="notification-attachment__opening">
          <div className="notification-attachment__opening-details-wrapper-small">
            {logoUrl && <FadeInImage className="notification-attachment__opening-logo" src={logoUrl} />}
            <div className="notification-attachment__opening-details">
              <span className="notification-attachment__opening-title">{opening.title}</span>
              <span className="notification-attachment__opening-subtitle">
                {opening.salaryRange ? <SalaryRange salaryRange={opening.salaryRange} /> : opening.company.profile.name}
              </span>
            </div>
            <Svg src={FrChevronRight} />
          </div>
        </div>
      </div>
    );
  }
};

Attachment.propTypes = {
  notification: PropTypes.shape({
    opening: PropTypes.shape({
      recruiter: PropTypes.shape({
        id: PropTypes.string,
        nameInitials: PropTypes.string,
      }),
    }),
  }).isRequired,
  type: PropTypes.string.isRequired,
};

export default Attachment;
