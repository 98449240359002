import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { debounce, get } from 'lodash';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Svg from 'svg-inline-react';
import classnames from 'classnames';
import escapeRegexp from 'escape-string-regexp';
import FrSearchGraySvg from 'assets/images/fr-search-grey.svg';
import FrCloseSvg from 'assets/images/fr-close-small.svg';
import Input from '@src/components/Input';
import { useGoogleLocationsLazyQuery } from '@src/graphql/generated';
import './styles.scss';

const MIN_SEARCH_PATTERN_LENGTH = 3;

export const LocationAutocomplete = ({
  className,
  defaultValue,
  onFocus,
  showClear,
  selectLocation,
  inputPlaceHolder,
  forceChangeFromParent,
}) => {
  const [searchPattern, setSearchPattern] = useState(defaultValue || '');
  const [isLocationsHidden, setIsLocationsHidden] = useState(true);

  const [fetchLocations, { data, loading }] = useGoogleLocationsLazyQuery({
    fetchPolicy: 'network-only',
  });
  const locations = get(data, 'googleLocations.result', []);

  useEffect(() => {
    if (forceChangeFromParent) {
      setSearchPattern(defaultValue);
    }
  }, [defaultValue, forceChangeFromParent]);

  const onSelectLocation = value => {
    setIsLocationsHidden(true);
    setSearchPattern(value.longName);
    selectLocation(value);
  };

  const onClearSearchPattern = () => {
    setSearchPattern('');
    selectLocation();
  };

  const debounceSuggestions = useCallback(
    debounce(value => {
      fetchLocations({
        variables: {
          searchPattern: value,
        },
      });
    }, 300),
    [],
  );

  const handleInputChange = value => {
    if (isLocationsHidden) {
      setIsLocationsHidden(false);
    }

    if (value.length >= MIN_SEARCH_PATTERN_LENGTH) {
      setSearchPattern(value);
      debounceSuggestions(value);
    }
  };

  return (
    <div className={classnames('location-autocomplete', className)}>
      <Input
        defaultValue={searchPattern}
        onChange={handleInputChange}
        onFocus={onFocus}
        placeholder={inputPlaceHolder || 'Type your city...'}
        setValue={searchPattern}
      />

      {searchPattern && showClear ? (
        <Svg onClick={onClearSearchPattern} src={FrCloseSvg} />
      ) : (
        <Svg src={FrSearchGraySvg} />
      )}

      <menu className={classnames('location-list-wrapper', locations.length && 'fade-in')}>
        <TransitionGroup component={null}>
          <CSSTransition className="location-list" classNames="fade" timeout={0}>
            {loading ? (
              <div>
                <div className="location-item" key="loading">
                  <span>Loading...</span>
                </div>
              </div>
            ) : locations.length && !isLocationsHidden ? (
              <div>
                {locations.map(location => (
                  <div className="location-item" key={location.placeId} onClick={() => onSelectLocation(location)}>
                    {highlight(searchPattern, location.longName)}
                  </div>
                ))}
              </div>
            ) : (
              <div />
            )}
          </CSSTransition>
        </TransitionGroup>
      </menu>
    </div>
  );
};

LocationAutocomplete.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  forceChangeFromParent: PropTypes.bool,
  inputPlaceHolder: PropTypes.string,
  onFocus: PropTypes.func,
  selectLocation: PropTypes.func.isRequired,
  showClear: PropTypes.bool,
};

function highlight(needle, haystack) {
  const matcher = new RegExp('(.*?)(' + escapeRegexp(needle) + ')(.*)', 'i');
  const matches = haystack.match(matcher);

  if (matches === null) {
    return <span>{haystack}</span>;
  }

  return (
    <span>
      {matches[1]}
      <strong>{matches[2]}</strong>
      {matches[3]}
    </span>
  );
}
