import React, { FC } from 'react';
import { isEmpty } from 'lodash';
import { trackEvent } from '@src/metrics';
import { JobOpeningFeedItem } from '@src/containers/Feed/JobOpeningFeedItem';
import { FeedItemSkeleton } from '@src/containers/Feed/Skeleton';
import { isOpeningType } from '@src/graphql/typeGuards';
import { Context } from '@src/metrics/enums/context.enum';
import { Interaction } from '@src/metrics/enums/interaction.enum';
import { ItemType } from '@src/metrics/enums/itemType.enum';
import { LocationType } from '@src/graphql/generated';
import { SectionTitle } from '../SectionTitle';
import { useSimilarOffers } from './hooks/useSimilarOffers';
import { SimilarOffersProps } from './props';
import './styles.scss';

export const SimilarOffers: FC<SimilarOffersProps> = ({ opening, userCountryId }) => {
  const searchPattern = opening.title;
  const openingTargetCountryIds = opening.targetLocations
    ?.filter((location): location is LocationType => !!location)
    .map(({ country }) => country?.id)
    .filter((countryId): countryId is string => !!countryId);
  const targetCountryId = openingTargetCountryIds?.length === 1 ? openingTargetCountryIds[0] : userCountryId;

  const { feed, isLoading } = useSimilarOffers({
    searchPattern,
    targetCountryId,
  });

  if (!isLoading && isEmpty(feed)) {
    return null;
  }

  return (
    <div className="job-opening-similar-offers">
      <SectionTitle
        hasTopDivider
        titleClassName="job-opening-similar-offers__title"
        wrapperClassName="job-opening-similar-offers__title-wrapper"
      >
        Similar offers
      </SectionTitle>

      <div className="feed-bg-color">
        {feed?.filter(isOpeningType).map(opening => (
          <JobOpeningFeedItem
            key={opening.id}
            metricsContext={Context.OPENING}
            onClick={() => {
              trackEvent({
                context: Context.OPENING,
                interaction: Interaction.VIEW_SIMILAR_OFFER,
                itemType: ItemType.OPENING,
                itemValue: opening.id,
              });
            }}
            opening={opening}
          />
        ))}
      </div>

      {isLoading && <FeedItemSkeleton />}
    </div>
  );
};
