import React, { FC } from 'react';
import { Score } from '../Score';
import { ScoreWithLocationProps } from './props';
import './styles.scss';

export const ScoreWithLocation: FC<ScoreWithLocationProps> = ({
  score,
  ratings,
  onClick,
  wrapperClassName,
  iconClassName,
  countClassName,
  size,
  numberClassName,
  location,
}) => {
  return (
    <div className="score-with-location" onClick={onClick}>
      <Score
        score={score}
        ratings={ratings}
        wrapperClassName={wrapperClassName}
        iconClassName={iconClassName}
        countClassName={countClassName}
        size={size}
        numberClassName={numberClassName}
      />

      {!!location && <span className="score-with-location__location">&nbsp;•&nbsp;{location}</span>}
    </div>
  );
};
