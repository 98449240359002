import { useEffect, useRef } from 'react';
import { showWhiteContainerStatusBar, showBlackContainerStatusBar } from '@src/container';
import {
  removePaddingTopForIOS,
  addPaddingTopForIphoneX,
  isIOSIphoneXContainer,
  isIOSContainer,
  addPaddingTopForIOS,
} from '@src/util/body';
import { UseTransparentizeStatusBarParams } from './interfaces/useTransparentizeStatusBar.interface';

export const useTransparentizeStatusBar = ({
  scrollableContainer,
  shouldToggleStatusBarOnScroll,
  onStatusBarToggle,
  isBlackContainerInitially,
}: UseTransparentizeStatusBarParams): void => {
  const hasScrolled = useRef(false);

  useEffect(() => {
    const showContainerStatusBar = () =>
      isBlackContainerInitially ? showBlackContainerStatusBar() : showWhiteContainerStatusBar();

    showContainerStatusBar();
    removePaddingTopForIOS();

    const handleScroll = e => {
      if (e.target.scrollTop > 0 && !hasScrolled.current) {
        hasScrolled.current = true;
        showBlackContainerStatusBar();
        onStatusBarToggle && onStatusBarToggle(true);
      } else if (e.target.scrollTop <= 0 && hasScrolled.current) {
        hasScrolled.current = false;
        showContainerStatusBar();
        onStatusBarToggle && onStatusBarToggle(false);
      }
    };

    // We need to set main container scrollTop = 0 in case we redirect on this page from page which scrollable container is
    // main wrapper scrollable container.
    if (scrollableContainer) {
      scrollableContainer.scrollTop = 0;

      if (shouldToggleStatusBarOnScroll) {
        scrollableContainer.addEventListener('scroll', handleScroll);
      }
    }

    return () => {
      if (shouldToggleStatusBarOnScroll && scrollableContainer) {
        scrollableContainer.removeEventListener('scroll', handleScroll);
      }

      showBlackContainerStatusBar();

      if (isIOSIphoneXContainer()) {
        addPaddingTopForIphoneX();
      } else if (isIOSContainer() && !isIOSIphoneXContainer()) {
        addPaddingTopForIOS();
      }
    };
  }, []);
};
