import { trim } from 'lodash';
import React, { FC } from 'react';
import { htmlifyWithEscape } from '@src/util/htmlifyMessage';
import Textarea from '@src/components/Textarea';
import { ChatInputActionsProps } from './props';
import './styles.scss';

export const ChatInputActions: FC<ChatInputActionsProps> = ({
  inputText,
  onInputChange,
  sendMessage,
  closeChat,
  disabledMessage,
  isSendDisabled,
}) => {
  return (
    <div className="chat-input-actions">
      {disabledMessage ? (
        <div>
          <em dangerouslySetInnerHTML={{ __html: htmlifyWithEscape(disabledMessage) }} />

          <button
            className="chat-input-actions__send chat-input-actions__send--archive"
            key="button"
            onClick={closeChat}
          >
            Archive
          </button>
        </div>
      ) : (
        <>
          <Textarea
            className="chat-input-actions__input"
            key="text"
            maxRows={6}
            onChange={evt => onInputChange(evt.target.value)}
            placeholder="Your message"
            value={inputText}
          />

          <button
            className="chat-input-actions__send"
            disabled={!trim(inputText) || isSendDisabled}
            key="button"
            onClick={sendMessage}
          >
            Send
          </button>
        </>
      )}
    </div>
  );
};
