import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import './styles.scss';

export default function Button({ isLink, positive, className, children, theme, style = 'pill', ...props }) {
  return React.createElement(
    isLink ? Link : 'button',
    {
      className: classnames('frank-button', positive && 'positive', className, theme, style),
      ...props,
    },
    children,
  );
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  isLink: PropTypes.bool,
  positive: PropTypes.bool,
  style: PropTypes.oneOf(['pill', 'circle']),
  theme: PropTypes.oneOf(['transparent-purple', 'purple', 'green', 'pink']),
};
