import React, { PureComponent } from 'react';
import { JobOpeningView } from '@src/views/JobOpening/JobOpeningView';
import './styles.scss';

export default class OpeningDetailsDataPush extends PureComponent {
  state = {
    opening: undefined,
  };

  componentDidMount() {
    window.parent.dataSubject$.subscribe(({ opening }) => {
      this.setState({ opening });
    });
  }

  render() {
    const { opening } = this.state;

    if (!opening) {
      return null;
    }

    return (
      <div className="opening-details-data-push">
        <JobOpeningView
          handleRatingClick={() => null}
          isLoading={!opening}
          isPreview
          onApply={() => null}
          onBack={() => null}
          onBookmark={() => null}
          onDiscard={() => null}
          openAssistantModal={() => null}
          openLearnMoreAboutHiringStateModal={() => null}
          openTaxesInfo={() => null}
          opening={opening}
          shareOpening={() => null}
        />
      </div>
    );
  }
}
