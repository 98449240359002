import React, { FC, useState } from 'react';
import Svg from 'svg-inline-react';
import LogoFrankSvg from '@assets/images/logo_frank.svg';
import { AISnippetsProps } from './props';
import './styles.scss';
import { replaceHtmlEntities } from '@src/util/replaceHtmlEntities';

const LIMIT = 3;

export const AISnippets: FC<AISnippetsProps> = ({ snippets }) => {
  const [isAllSnippetsShown, setIsAllSnippetsShown] = useState(false);

  const slicedSnippets = isAllSnippetsShown ? snippets : snippets.slice(0, LIMIT);

  return (
    <div className="opening-details-snippets">
      <div className="opening-details-snippets__title">
        <Svg className="opening-details-snippets__logo" src={LogoFrankSvg} />
        frank ai
      </div>

      <div className="opening-details-snippets__list">
        {slicedSnippets.map(snippet => {
          return (
            <div key={snippet.title} className="opening-details-snippets__snippet">
              <div className="opening-details-snippets__snippet-title">
                <span className="opening-details-snippets__ball"></span>

                {snippet.title}
              </div>

              <div className="opening-details-snippets__snippet-text">{replaceHtmlEntities(snippet.text, true)}</div>
            </div>
          );
        })}

        {!isAllSnippetsShown && snippets.length > LIMIT && (
          <div className="opening-details-snippets__read-more" onClick={() => setIsAllSnippetsShown(true)}>
            Read more
          </div>
        )}
      </div>
    </div>
  );
};
