import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import Svg from 'svg-inline-react';
import LogoFrankSvg from '@assets/images/logo_frank.svg';
import { JOB_OPENING_ROUTE } from '@src/constants/routes';
import createLink from '@src/util/createLink';
import { ClampedMessage } from '@src/components/ClampedMessage';
import { RoleProps } from './props';
import './styles.scss';

export const Role: FC<RoleProps> = ({ text, openingId }) => {
  return (
    <Link
      className="opening-role"
      to={createLink({
        link: JOB_OPENING_ROUTE,
        params: {
          openingId,
        },
      })}
    >
      <div className="opening-role__title">
        <Svg className="opening-role__logo" src={LogoFrankSvg} />
        About the role
      </div>

      <ClampedMessage lineLimit={8} message={text} className="opening-role__message-text" />
    </Link>
  );
};
