import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { isEmpty } from 'lodash';
import { HorizontalScroller } from '@src/components/HorizontalScroller';
import { backgroundGradient } from './styles.scss';

export const MediaList = ({ wrapperClassName, media }) =>
  !isEmpty(media) ? (
    <div className={classnames('media-list', wrapperClassName)}>
      <HorizontalScroller className="media-list__articles">
        {media.map(({ url, image, title, siteName, domain }, i) => (
          <div className="media-list__article-wrapper" key={i}>
            <a
              className="media-list__article"
              href={url}
              style={{ backgroundImage: `${backgroundGradient}, url('${(image || {}).url}')` }}
              target="_blank"
            >
              <div className="media-list__article-important">{title}</div>

              <div className="media-list__article-aside">{siteName || domain}</div>
            </a>
          </div>
        ))}
      </HorizontalScroller>
    </div>
  ) : null;

MediaList.propTypes = {
  media: PropTypes.arrayOf(
    PropTypes.shape({
      domain: PropTypes.string,
      image: PropTypes.shape({
        url: PropTypes.string.isRequired,
      }).isRequired,
      siteName: PropTypes.string,
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
  ),
  wrapperClassName: PropTypes.string,
};
