import React, { FC } from 'react';
import HalfArrowSvg from 'assets/images/half_arrow.svg';
import Svg from 'svg-inline-react';
import { Link } from 'react-router-dom';
import createLink from '@src/util/createLink';
import { JOB_OPENING_ROUTE } from '@src/constants/routes';
import { ChatOpeningProps } from './props';
import './styles.scss';

export const ChatOpening: FC<ChatOpeningProps> = ({ title, openingId }) => {
  return (
    <Link
      className="chat-opening-info"
      to={createLink({
        link: JOB_OPENING_ROUTE,
        params: { openingId },
      })}
    >
      <span>{title}</span>

      <Svg src={HalfArrowSvg} />
    </Link>
  );
};
