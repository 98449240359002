import path from 'path';
import { goBack, push } from 'connected-react-router';
import { getPreviousLocation } from '@src/redux/selectors/navigation';
import { INDEX_ROUTE } from '@src/constants/routes';
import { RootAction } from '@src/store/interfaces/store.interface';
import { SetNavigationVisibilityReturnValue } from './interface/setNavigationVisibility.interface';

export const NAVIGATION_VISIBILITY = path.join(__dirname, '/navigationVisibility');
export function setNavigationVisibility(isVisible: boolean): SetNavigationVisibilityReturnValue {
  return {
    type: NAVIGATION_VISIBILITY,
    visible: isVisible,
  };
}

export const NAVIGATION_REFRESHABLE = path.join(__dirname, '/navigationRefreshable');
export function setNavigationRefreshable(refreshable) {
  return {
    refreshable: refreshable,
    type: NAVIGATION_REFRESHABLE,
  };
}

export function goBackAndPreventLeave(): RootAction<void, string> {
  return async (dispatch, getState) => {
    const state = getState();
    const previousLocation = getPreviousLocation(state);

    !previousLocation ? dispatch(push(INDEX_ROUTE)) : dispatch(goBack());
  };
}
