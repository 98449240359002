import React, { useState, FC, ReactNode, useEffect } from 'react';
import moment from 'moment';
import { debounce } from 'lodash';
import { useHistory } from 'react-router-dom';
import { VERIFIED_USER_QUESTIONNAIRE_ROUTE, VERIFIED_USER_ROUTE } from '@src/constants/routes';
import { useAppDispatch } from '@src/store';
import {
  useCancelUserVerificationMutation,
  UserVerificationStateEnum,
  useSaveUserVerificationMutation,
  useSubmitUserVerificationMutation,
  useUserVerificationStatusQuery,
} from '@src/graphql/generated';
import { setContent as setModalContent, closeModal } from '@src/redux/actions/slideUpModal';
import { ConfirmationModal } from '@src/components/ConfirmationModal';
import { useFlashMessagesContext } from '@src/context/FlashMessagesContext';
import { LoadingState } from './LoadingState';
import { Title } from './Title';
import { UserInfo } from './UserInfo';
import { AssistantStatus } from './AssistantStatus';
import { InactiveState } from './InactiveState';
import { DraftState } from './DraftState';
import { PendingState } from './PendingState';
import { RejectedState } from './RejectedState';
import { PublishedState } from './PublishedState';
import { isCompletedUserVerificationTypeType, isEmployedUserEmploymentType } from './typeGuards';
import { Props } from './props';
import './styles.scss';

export const VerifiedTalentGateway: FC<Props> = ({
  user,
  isLoading: isProvidedDataLoading,
  pendingStateProps,
  draftStateProps,
  publishedStateProps,
  onVerifiedUserUpdate,
}) => {
  const dispatch = useAppDispatch();

  const history = useHistory();

  const { setErrorFlashMessage } = useFlashMessagesContext();

  const {
    data: userVerificationStatusData,
    refetch: userVerificationStatusRefetch,
    loading: isUserVerificationStatusLoading,
  } = useUserVerificationStatusQuery({
    fetchPolicy: 'network-only',
  });
  const canStartVerification = userVerificationStatusData?.userVerificationStatus.canStartVerification;
  const daysBetweenVerifications = userVerificationStatusData?.userVerificationStatus.daysBetweenVerifications;

  const userVerification = user?.verification;
  const userVerificationState = userVerification?.state;

  const [isSwitchOn, setIsSwitchOn] = useState(
    !!(
      userVerificationState &&
      [UserVerificationStateEnum.PENDING, UserVerificationStateEnum.PUBLISHED].includes(userVerificationState)
    ),
  );

  const companyName = isEmployedUserEmploymentType(user?.profile.currentEmployment)
    ? user?.profile.currentEmployment?.company?.profile?.name
    : '';

  const userVerificationId = userVerification?.id;
  const phoneNumber = userVerification?.phone?.number;
  const phoneCountryCode = userVerification?.phone?.country?.id;
  const positionExpectations = userVerification?.positionExpectations;
  const workExperience = userVerification?.workExperience;
  const achievements = userVerification?.achievements;
  const canStartIn = userVerification?.canStartIn;
  const userVerificationMessage = userVerification?.message;
  const blacklistedCompanies = userVerification?.blacklistedCompanies || [];

  const reactivationDate =
    isCompletedUserVerificationTypeType(user?.verification) &&
    userVerificationState === UserVerificationStateEnum.REJECTED
      ? moment(user?.verification?.publishedAt)
          .add(daysBetweenVerifications, 'days')
          .format('MMM DD')
      : undefined;

  const isDividerHidden =
    userVerificationState === UserVerificationStateEnum.PUBLISHED &&
    publishedStateProps &&
    publishedStateProps.isActionButtonHidden;

  useEffect(() => {
    if (
      userVerificationState &&
      [UserVerificationStateEnum.PENDING, UserVerificationStateEnum.PUBLISHED].includes(userVerificationState)
    ) {
      setIsSwitchOn(true);
    }
  }, [userVerificationState]);

  const [submitUserVerification, { loading: isSubmitUserVerificationLoading }] = useSubmitUserVerificationMutation({
    onCompleted: () => {
      if (onVerifiedUserUpdate) {
        onVerifiedUserUpdate(userVerificationState);
      }
    },
    onError: () => {
      setErrorFlashMessage('Failed to submit verification');
    },
  });

  const [saveUserVerification, { loading: isSaveUserVerificationLoading }] = useSaveUserVerificationMutation({
    onCompleted: data => {
      submitUserVerification({
        variables: {
          verificationId: data.saveUserVerification.id,
        },
      });
    },
    onError: () => {
      setErrorFlashMessage('Failed to save verification');
    },
  });

  const [cancelUserVerification, { loading: isCancelUserVerificationLoading }] = useCancelUserVerificationMutation({
    onCompleted: () => {
      if (onVerifiedUserUpdate) {
        onVerifiedUserUpdate(userVerificationState);
      }

      if (userVerificationState !== UserVerificationStateEnum.PENDING) {
        history.push(VERIFIED_USER_QUESTIONNAIRE_ROUTE);
      }
    },
    onError: () => {
      setErrorFlashMessage('Failed to cancel verification');
    },
  });

  const isLoading =
    isUserVerificationStatusLoading ||
    isSubmitUserVerificationLoading ||
    isSaveUserVerificationLoading ||
    isCancelUserVerificationLoading ||
    isProvidedDataLoading;

  const isSwitchButtonDisabled =
    (!canStartVerification && userVerificationState === UserVerificationStateEnum.REJECTED) || isLoading;

  const saveOrStartVerification = debounce(async (): Promise<void> => {
    if (isLoading) {
      return;
    }

    const refetchedData = await userVerificationStatusRefetch();

    const canStartVerification = refetchedData.data?.userVerificationStatus.canStartVerification;

    if (canStartVerification && phoneCountryCode && phoneNumber && workExperience && positionExpectations) {
      setIsSwitchOn(true);

      await saveUserVerification({
        variables: {
          verificationInput: {
            achievements: achievements,
            blacklistedCompanies: blacklistedCompanies.length ? blacklistedCompanies.map(({ id }) => id) : undefined,
            canStartIn: canStartIn,
            phone: {
              country: phoneCountryCode,
              number: phoneNumber,
            },
            positionExpectations: positionExpectations,
            workExperience: workExperience,
          },
        },
      });

      return;
    }

    history.push(VERIFIED_USER_ROUTE);
  }, 250);

  const onCancelVerification = () => {
    if (!userVerificationId) {
      return;
    }

    setIsSwitchOn(false);

    cancelUserVerification({
      variables: {
        verificationId: userVerificationId,
      },
    });
  };

  const onAssistantStatusToggle = () => {
    if (userVerificationState === UserVerificationStateEnum.DRAFT) {
      history.push(VERIFIED_USER_ROUTE);

      return;
    }

    if (isSwitchOn) {
      dispatch(
        setModalContent({
          content: (
            <ConfirmationModal
              cancelLabel="Close"
              confirmLabel="Yes"
              onCancel={() => dispatch(closeModal())}
              onConfirm={onCancelVerification}
              title="Are you sure?"
            />
          ),
          height: 'auto',
        }),
      );
    } else {
      saveOrStartVerification();
    }
  };

  const getSectionByState = (): ReactNode => {
    if (UserVerificationStateEnum.PUBLISHED === userVerificationState) {
      return <PublishedState isActionDisabled={isLoading} {...publishedStateProps} />;
    }

    if (UserVerificationStateEnum.REJECTED === userVerificationState && !canStartVerification) {
      return <RejectedState isActionDisabled={isLoading} />;
    }

    if (UserVerificationStateEnum.DRAFT === userVerificationState) {
      return (
        <DraftState
          doesUserVerificationMessageExist={!!userVerificationMessage}
          isActionDisabled={isLoading}
          {...draftStateProps}
        />
      );
    }

    if (UserVerificationStateEnum.PENDING === userVerificationState) {
      return <PendingState {...pendingStateProps} isActionDisabled={isLoading} />;
    }

    return (
      <InactiveState
        isDisabled={
          (!canStartVerification && userVerificationState === UserVerificationStateEnum.REJECTED) || isLoading
        }
        onClick={saveOrStartVerification}
      />
    );
  };

  return isUserVerificationStatusLoading || isProvidedDataLoading ? (
    <LoadingState />
  ) : (
    <div className="frank-verified-talent-gateway">
      <div className="frank-verified-talent-gateway__card">
        <div className="frank-verified-talent-gateway__container">
          <Title />

          <UserInfo
            avatarColor={user?.avatarColor || ''}
            avatarUrl={user?.avatar?.fullUrl}
            name={user?.name || ''}
            title={user?.position + (companyName ? ` • ${companyName}` : '')}
          />

          <AssistantStatus
            isDisabled={isSwitchButtonDisabled}
            isOn={isSwitchOn}
            onToggle={onAssistantStatusToggle}
            reactivationDate={reactivationDate}
          />
        </div>

        {!isDividerHidden ? <div className="frank-verified-talent-gateway__divider" /> : null}

        <div className="frank-verified-talent-gateway__container">{getSectionByState()}</div>
      </div>
    </div>
  );
};
