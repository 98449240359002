import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { BlueButton } from '@src/components/Button';
import Checkbox from '@src/components/Checkbox';
import { UserVerificationStateEnum } from '@src/graphql/generated';
import { TERMS_URL, PRIVACY_URL } from '@src/constants';
import './styles.scss';

function Footer({ onPublish, userVerificationState }) {
  const [isTermsAndConditionSelected, setIsTermsAndConditionsSelected] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  if (
    userVerificationState &&
    [UserVerificationStateEnum.PUBLISHED, UserVerificationStateEnum.PENDING].includes(userVerificationState)
  ) {
    return null;
  }

  return (
    <div className="user-verification-footer">
      <div className="user-verification-footer__terms-conditions">
        <Checkbox
          label={
            <Fragment>
              I agree to the{' '}
              <a href={TERMS_URL} target="_blank">
                terms of service
              </a>{' '}
              and{' '}
              <a href={PRIVACY_URL} target="_blank">
                privacy policy
              </a>
              .
            </Fragment>
          }
          onChange={() => setIsTermsAndConditionsSelected(!isTermsAndConditionSelected)}
          value={isTermsAndConditionSelected}
          wrapperClassName="user-verification-footer__checkbox"
        />
      </div>
      <div className="user-verification-footer__action-wrapper">
        <BlueButton
          className="user-verification-footer__publish"
          disabled={!isTermsAndConditionSelected || isButtonDisabled}
          isFilled
          onClick={() => {
            setIsButtonDisabled(true);
            onPublish();
          }}
        >
          Publish profile
        </BlueButton>
      </div>
    </div>
  );
}

Footer.propTypes = {
  onPublish: PropTypes.func.isRequired,
};

export default Footer;
