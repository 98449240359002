import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { some, times } from 'lodash';
import { useQuery } from '@apollo/client';
import { BlueButton } from '@src/components/Button';
import { SelectItem, SelectItemLoading } from '@src/components/SelectItem';
import { EXPLORE_FILTER } from '@src/constants';
import { countryFlagUrl } from '@src/util/countryFlagImageUrl';
import { ALL_SPECIALITIES as ALL_SPECIALITIES_QUERY } from '@src/graphql/filters/queries/specialities';
import { CountriesDocument, SenioritiesDocument } from '@src/graphql/generated';
import { normalizeFetchedCountriesForFilter } from '../Filters/util';
import { ALL_SPECIALITIES, ALL_COUNTRIES, ALL_SENIORITIES } from '../Filters/constants';
import './styles.scss';

const FilterModal = ({ title, subTitle, filter, loadingPlaceHolder, customData, queryKey, activeFilters, onSave }) => {
  let activeQuery;
  let normalizedData = [];
  const { SPECIALITIES, COUNTRIES, SENIORITIES } = EXPLORE_FILTER;

  if (filter === SPECIALITIES) {
    activeQuery = ALL_SPECIALITIES_QUERY;
  } else if (filter === COUNTRIES) {
    activeQuery = CountriesDocument;
  } else if (filter === SENIORITIES) {
    activeQuery = SenioritiesDocument;
  }

  const listContainerRef = useRef(null);
  const [filters, setFilters] = useState([...activeFilters]);
  const [renderLimit, setRenderLimit] = useState(10);
  const { data, loading } = useQuery(activeQuery);

  if (!loading && !!data) {
    if ([SPECIALITIES, SENIORITIES].includes(filter)) {
      normalizedData = data[queryKey];
    }

    // Normalize fetched data for display.
    if (filter === COUNTRIES) {
      normalizedData = normalizeFetchedCountriesForFilter(data[queryKey].result);
    }
  }

  return (
    <div className="filter-wrapper">
      <div className="filter-wrapper__header">
        <h3>{title}</h3>
        <p>{subTitle}</p>
      </div>
      <div
        className="filter-wrapper__list"
        onScroll={event => {
          if (listContainerRef && listContainerRef.current && event.target) {
            const distanceFromBottom =
              listContainerRef.current.offsetHeight - event.target.scrollTop - event.target.offsetHeight;

            if (distanceFromBottom <= 120 && [...customData, ...normalizedData].length > renderLimit) {
              setRenderLimit(renderLimit + 10);
            }
          }
        }}
      >
        <div ref={listContainerRef}>
          {loading
            ? times(loadingPlaceHolder, Number).map(i => {
                return <SelectItemLoading key={i} />;
              })
            : [...customData, ...normalizedData].slice(0, renderLimit).map(({ id, name, countryShortName, svgSrc }) => {
                return (
                  <SelectItem
                    active={some(filters, { id })}
                    imageClassName="filter-wrapper__list-image"
                    imageFullUrl={countryFlagUrl(countryShortName)}
                    key={id}
                    label={name}
                    onSelect={option =>
                      setFilters(
                        [ALL_SPECIALITIES.id, ALL_COUNTRIES.id, ALL_SENIORITIES.id].includes(option.id)
                          ? [option]
                          : some(filters, { id: option.id })
                          ? filters.filter(({ id: filterId }) => filterId !== id)
                          : [
                              ...filters.filter(
                                ({ id: filterId }) =>
                                  ![ALL_SPECIALITIES.id, ALL_COUNTRIES.id, ALL_SENIORITIES.id].includes(filterId),
                              ),
                              option,
                            ],
                      )
                    }
                    option={{ id, value: name }}
                    svgSrc={svgSrc}
                  />
                );
              })}
        </div>
      </div>
      <div className="filter-wrapper__footer">
        <BlueButton className="filter-wrapper__save-filter" isFilled onClick={() => onSave(filters)}>
          Save
        </BlueButton>
      </div>
    </div>
  );
};

FilterModal.propTypes = {
  activeFilters: PropTypes.array.isRequired,

  customData: PropTypes.array,

  // Filter key, to detect which filter should we fetch.'
  filter: PropTypes.oneOf(Object.values(EXPLORE_FILTER)).isRequired,
  loadingPlaceHolder: PropTypes.number,
  onSave: PropTypes.func.isRequired,
  queryKey: PropTypes.oneOf(['specialities', 'countries']),
  subTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

FilterModal.defaultProps = {
  loadingPlaceHolder: 6,
};

export default FilterModal;
