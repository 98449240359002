export const getLineCount = (divEl: HTMLDivElement): number => {
  const temp = document.createElement(divEl.nodeName);

  temp.setAttribute(
    'style',
    'margin:0; padding:0; ' +
      'font-family:' +
      (divEl.style.fontFamily || 'inherit') +
      '; ' +
      'font-size:' +
      (divEl.style.fontSize || 'inherit'),
  );
  temp.innerHTML = 'A';

  divEl.parentNode?.appendChild(temp);

  const lineHeight: number = temp.clientHeight;

  temp.parentNode?.removeChild(temp);

  const offsetHeight: number = divEl.offsetHeight;

  const lineCount = offsetHeight / lineHeight;

  return lineCount;
};
