import React, { FC } from 'react';
import { Rating } from '@src/components/Rating';
import { ClampedMessage } from '@src/components/ClampedMessage';
import { RatingColor } from '@src/components/Rating/enums/ratingColor.enum';
import { RatingSize } from '@src/components/Rating/enums/ratingSize.enum';
import { ReviewItemProps } from './props';
import './styles.scss';

export const ReviewItem: FC<ReviewItemProps> = ({ review }) => {
  if (!review.title && !review.positiveFeedback && !review.negativeFeedback) {
    return null;
  }

  return (
    <div className="review-item">
      <Rating
        activeColor={RatingColor.BLUE}
        activeRating={review.ratings.total}
        isDisabled
        maxRating={5}
        shouldDisplayOnlyActiveOnes
        size={RatingSize.SMALL}
        wrapperClassName="review-item__rating"
      />

      {review.title && <h3 className="review-item__title">{review.title}</h3>}

      {!review.title && review.positiveFeedback && (
        <ClampedMessage
          className="review-item__comment"
          shouldForceInline
          isClamped
          lineLimit={2}
          message={`👍 ${review.positiveFeedback}`}
        />
      )}

      {!review.title && !review.positiveFeedback && review.negativeFeedback && (
        <ClampedMessage
          className="review-item__comment"
          shouldForceInline
          isClamped
          lineLimit={2}
          message={`👎 ${review.negativeFeedback}`}
        />
      )}
    </div>
  );
};
