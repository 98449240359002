const EXTRACT_DOMAIN_REGEX =
  /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?(([\p{L}0-9-_]+\.){1,}((?!php|html|htm|cfm)[a-z]+))(?:\/?)/iu;

export function getDomainFromUrl(url: string): string | undefined {
  const urlParts = url.toLowerCase().trim().match(EXTRACT_DOMAIN_REGEX);

  if (!urlParts) {
    return undefined;
  }

  return urlParts[1];
}
