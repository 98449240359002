import React from 'react';
import { map, reduce } from 'lodash';
import PropTypes from 'prop-types';
import Svg from 'svg-inline-react';
import WorldSvg from 'assets/images/map/world.svg';
import EuropeSvg from 'assets/images/map/europe.svg';
import AfricaSvg from 'assets/images/map/africa.svg';
import AsiaSvg from 'assets/images/map/asia.svg';
import AustraliaSvg from 'assets/images/map/australia.svg';
import ChinaSvg from 'assets/images/map/china.svg';
// import MiddleEastSvg from 'assets/images/map/middle-east.svg'
import NorthAmericaSvg from 'assets/images/map/north-america.svg';
import SouthAmericaSvg from 'assets/images/map/south-america.svg';
import RussiaSvg from 'assets/images/map/russia.svg';
import UsaSvg from 'assets/images/map/usa.svg';
import GlobalSvg from 'assets/images/map/global.svg';
import './styles.scss';

const SVGS = {
  'Africa': AfricaSvg,
  'Asia': AsiaSvg,
  'Australia': AustraliaSvg,
  'China': ChinaSvg,
  'Europe': EuropeSvg,
  'Global': GlobalSvg,
  // 'MiddleEast': MiddleEastSvg,
  'North America': NorthAmericaSvg,

  'Russia': RussiaSvg,
  'South America': SouthAmericaSvg,
  'United States': UsaSvg,
};

function buildPieces(marketAreas = [], marketCountries = []) {
  let pieces = reduce(
    marketAreas,
    (result, item) => {
      result[item.longName] = SVGS[item.longName];
      return result;
    },
    {},
  );

  pieces = reduce(
    marketCountries,
    (result, country) => {
      let svg = SVGS[country.longName];

      if (!svg) {
        svg = SVGS[(country.continent || {}).longName];
      }

      result[country.longName] = svg;
      return result;
    },
    pieces,
  );

  return pieces;
}

export const MarketsMap = ({ marketAreas, marketCountries }) => {
  const pieces = buildPieces(marketAreas, marketCountries);

  if (!marketAreas.length && !marketCountries.length) {
    return null;
  }

  return (
    <div className="markets-map">
      <Svg src={WorldSvg} />

      {map(pieces, (piece, key) => (
        <Svg className="markets-map__piece" key={key} src={piece} />
      ))}
    </div>
  );
};

MarketsMap.propTypes = {
  marketAreas: PropTypes.arrayOf(
    PropTypes.shape({
      longName: PropTypes.string.isRequired,
    }).isRequired,
  ),
  marketCountries: PropTypes.arrayOf(
    PropTypes.shape({
      longName: PropTypes.string.isRequired,
    }).isRequired,
  ),
};

MarketsMap.defaultProps = {
  marketAreas: [],
  marketCountries: [],
};
