import React, { forwardRef, useState } from 'react';
import Svg from 'svg-inline-react';
import CloseSvg from 'assets/images/fr-close.svg';
import { FilterItem } from '../FilterItem';
import { FilterInputProps } from './props';
import './styles.scss';

export const FilterInput = forwardRef<HTMLInputElement, FilterInputProps>(
  ({ onClear, onChange, value, placeholder, startIcon, className, onBlur, onFocus, onClick, endElement }, ref) => {
    const [isFocused, setIsFocused] = useState<boolean>(false);

    return (
      <FilterItem isActive={isFocused || !!value?.length} startIcon={startIcon} className={className}>
        <div className="jobs-search-input">
          <input
            ref={ref}
            type="text"
            placeholder={placeholder}
            value={value}
            onChange={e => onChange(e.target.value)}
            onClick={onClick}
            onFocus={() => {
              onFocus?.();

              setIsFocused(true);
            }}
            onBlur={() => {
              onBlur?.();

              setIsFocused(false);
            }}
          />

          <div className="jobs-search-input__end-element">
            {endElement ||
              (!!value?.length && (
                <Svg
                  onClick={(event: MouseEvent) => {
                    event.stopPropagation();
                    onClear?.();
                  }}
                  className="jobs-search-input__icon"
                  src={CloseSvg}
                />
              ))}
          </div>
        </div>
      </FilterItem>
    );
  },
);

FilterInput.displayName = 'FilterInput';
