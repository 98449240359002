import React, { FC } from 'react';
import Svg from 'svg-inline-react';
import FrChevronRight from 'assets/images/fr-chevron-right.svg';
import SwiperType, { Pagination, EffectCoverflow } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import FadeInImage from '@src/components/FadeInImage';
import { ARTICLE_ROUTE, JOB_OPENING_ROUTE } from '@src/constants';
import createLink from '@src/util/createLink';
import { trackEvent } from '@src/metrics';
import { Context } from '@src/metrics/enums/context.enum';
import { Interaction } from '@src/metrics/enums/interaction.enum';
import { replaceHtmlEntities } from '@src/util/replaceHtmlEntities';
import { formatDateLong } from '@src/util/formatDateLong';
import { CardWrapper } from '../CardWrapper';
import { SnippetSwiperProps } from './props';
import './styles.scss';

export const SnippetSwiper: FC<SnippetSwiperProps> = ({
  article,
  snippets = [],
  images,
  wrapperClassName,
  openingId,
}) => {
  const handleSlideChange = (swiper: SwiperType) => {
    trackEvent({
      context: Context.OPENING_FEED_ITEM,
      interaction: Interaction.SWIPE,
      payload: {
        'Opening Id': openingId,
        'Slider Page': swiper.activeIndex + 1,
      },
    });
  };

  const modules = snippets.length > 0 ? [Pagination, EffectCoverflow] : [EffectCoverflow];

  return (
    <div className={classnames('job-opening-snippet-slider', wrapperClassName)}>
      <Swiper
        centeredSlides={true}
        coverflowEffect={{
          depth: 200,
          modifier: 1,
          rotate: 0,
          slideShadows: true,
          stretch: 0,
        }}
        effect={'coverflow'}
        loop={false}
        modules={modules}
        pagination={{
          bulletActiveClass: 'fr-swiper__bullet--active',
          bulletClass: 'fr-swiper__bullet',
          el: '.fr-swiper__pagination',
        }}
        slidesPerView={'auto'}
        spaceBetween={20}
        onSlideChange={handleSlideChange}
      >
        {article ? (
          <SwiperSlide key={article.id}>
            <CardWrapper>
              <Link
                className="job-opening-snippet-slider__item"
                to={createLink(ARTICLE_ROUTE, {
                  params: { articleId: article.id },
                })}
              >
                {article.headerImage && (
                  <FadeInImage className="job-opening-snippet-slider__image" src={article.headerImage.fullUrl} />
                )}

                <div className="job-opening-snippet-slider__item-bottom">
                  <div className="job-opening-snippet-slider__article-header">
                    <span className="job-opening-snippet-slider__article-title">
                      {replaceHtmlEntities(article.title, true)}
                    </span>

                    <div className="job-opening-snippet-slider__article-info">
                      <span className="job-opening-snippet-slider__article-label">
                        {formatDateLong(article.publishedAt || article.createdAt)}
                      </span>
                      <div className="job-opening-snippet-slider__article-chevron">
                        <span>Read the article</span>
                        <Svg src={FrChevronRight} />
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </CardWrapper>
          </SwiperSlide>
        ) : undefined}

        {snippets.map((snippet, index) => {
          return (
            <SwiperSlide key={snippet.title}>
              <CardWrapper>
                <Link
                  className="job-opening-snippet-slider__item"
                  to={createLink(JOB_OPENING_ROUTE, {
                    params: { openingId },
                  })}
                >
                  <div className="job-opening-snippet-slider__item-top">
                    <div className="job-opening-snippet-slider__header">
                      <span className="job-opening-snippet-slider__title">
                        {replaceHtmlEntities(snippet.title, true)}
                      </span>

                      <span className="job-opening-snippet-slider__label">frank ai</span>
                    </div>

                    <div className="job-opening-snippet-slider__text">{replaceHtmlEntities(snippet.text, true)}</div>
                  </div>

                  {images[index] && <FadeInImage className="job-opening-snippet-slider__image" src={images[index]} />}

                  {index === 0 ? <span className="job-opening-snippet-slider__hint">swipe for more</span> : null}
                </Link>
              </CardWrapper>
            </SwiperSlide>
          );
        })}

        <div className="fr-swiper__pagination" />
      </Swiper>
    </div>
  );
};
