import React, { FC } from 'react';
import classnames from 'classnames';
import { range } from 'lodash';
import { TypingLoaderType } from './enums/TypingLoaderType.enum';
import { TypingLoaderProps } from './props';
import './styles.scss';

export const TypingLoader: FC<TypingLoaderProps> = ({ className, itemClassName, type = TypingLoaderType.DARK }) => {
  return (
    <div className={classnames('typing-loader', 'thinking', className)}>
      {range(0, 3).map(item => (
        <div className={classnames('typing-loader__item', `typing-loader__item--${type}`, itemClassName)} key={item} />
      ))}
    </div>
  );
};
