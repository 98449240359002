import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, get } from 'lodash';
import { getAllSearchTags } from '@src/views/Search/utils';
import { OpeningRemoteTypeEnum } from '@src/graphql/generated';
import { HowWouldLikeWork } from './HowWouldLikeWork';
import { LocationSearch } from './LocationSearch';
import { AreaSelection } from './AreaSelection';
import { CountrySearch } from './CountrySearch';
import './styles.scss';

export const LOCATION_VIEWS = {
  AREA_SELECTION_VIEW: 'AREA_SELECTION_VIEW',
  COUNTRY_SEARCH_VIEW: 'COUNTRY_SEARCH_VIEW',
  HOW_WOULD_LIKE_WORK_VIEW: 'HOW_WOULD_LIKE_WORK_VIEW',
  LOCATION_SEARCH_VIEW: 'LOCATION_SEARCH_VIEW',
};

const HOW_WOULD_LIKE_WORK_ACTIONS = {
  EDIT: 'EDIT',
  SELECT: 'SELECT',
};

export const Locations = ({
  defaultLocation,
  defaultAreas,
  defaultCountries,
  defaultView,
  onClose,
  onApply,
  workOptions,
}) => {
  const whereDoYouLikeWorkActiveKey = useRef(null);
  const [activeView, setActiveView] = useState(defaultView || LOCATION_VIEWS.HOW_WOULD_LIKE_WORK_VIEW);
  const [location, setLocation] = useState(defaultLocation);
  const [activeAreas, setActiveAreas] = useState(defaultAreas);
  const [activeCountries, setActiveCountries] = useState(defaultCountries);
  const [activeWorkOptions, setActiveWorkOptions] = useState(workOptions);

  const handleAreaSave = areas => {
    setActiveAreas(areas);

    if (!isEmpty(activeCountries)) {
      setActiveCountries([]);
    }

    if (!activeWorkOptions.includes(OpeningRemoteTypeEnum.FULLY)) {
      setActiveWorkOptions([OpeningRemoteTypeEnum.FULLY]);
    }

    setActiveView(LOCATION_VIEWS.HOW_WOULD_LIKE_WORK_VIEW);
  };

  const handleCountrySave = countries => {
    setActiveCountries(countries);

    if (!isEmpty(activeAreas)) {
      setActiveAreas([]);
    }

    if (!activeWorkOptions.includes(OpeningRemoteTypeEnum.FULLY)) {
      setActiveWorkOptions([OpeningRemoteTypeEnum.FULLY]);
    }

    setActiveView(LOCATION_VIEWS.HOW_WOULD_LIKE_WORK_VIEW);
  };

  const handleLocationSelect = location => {
    setLocation(location);

    if (whereDoYouLikeWorkActiveKey.current && !activeWorkOptions.includes(whereDoYouLikeWorkActiveKey.current)) {
      setActiveWorkOptions([
        ...activeWorkOptions.filter(key => key !== OpeningRemoteTypeEnum.FULLY),
        whereDoYouLikeWorkActiveKey.current,
      ]);
    }
  };

  const handleBackFromLocationView = () => {
    whereDoYouLikeWorkActiveKey.current = null;
    setActiveView(LOCATION_VIEWS.HOW_WOULD_LIKE_WORK_VIEW);
  };

  const handleWhereDoYouLikeWorkSelect = (action, option) => {
    const isLocationIdExist = get(location, '[0].id');

    if ([OpeningRemoteTypeEnum.ONSITE, OpeningRemoteTypeEnum.HYBRID].includes(option.key) && !isLocationIdExist) {
      whereDoYouLikeWorkActiveKey.current = option.key;
      setActiveView(LOCATION_VIEWS.LOCATION_SEARCH_VIEW);

      return;
    } else if (option.key === OpeningRemoteTypeEnum.FULLY && isEmpty(activeCountries) && isEmpty(activeAreas)) {
      setActiveView(LOCATION_VIEWS.AREA_SELECTION_VIEW);

      return;
    }

    if (action === HOW_WOULD_LIKE_WORK_ACTIONS.EDIT) {
      if ([OpeningRemoteTypeEnum.ONSITE, OpeningRemoteTypeEnum.HYBRID].includes(option.key)) {
        whereDoYouLikeWorkActiveKey.current = option.key;
        setActiveView(LOCATION_VIEWS.LOCATION_SEARCH_VIEW);

        return;
      } else if (option.key === OpeningRemoteTypeEnum.FULLY) {
        setActiveView(LOCATION_VIEWS.AREA_SELECTION_VIEW);

        return;
      }
    }

    // Deselect HYBRID and ON_SITE in case remote is selected
    if (action === HOW_WOULD_LIKE_WORK_ACTIONS.SELECT) {
      if ([OpeningRemoteTypeEnum.ONSITE, OpeningRemoteTypeEnum.HYBRID].includes(option.key)) {
        const filteredWorkOption = activeWorkOptions.filter(key => key !== OpeningRemoteTypeEnum.FULLY);
        const activeFilters = filteredWorkOption.includes(option.key)
          ? filteredWorkOption.filter(key => key !== option.key)
          : [...filteredWorkOption, option.key];

        setActiveWorkOptions(activeFilters);
      } else {
        const activeFilters = activeWorkOptions.includes(option.key) ? [] : [option.key];
        setActiveWorkOptions(activeFilters);
      }
    }
  };

  const handleApply = () => {
    const searchTagsForWorkOptions = getAllSearchTags(activeWorkOptions, activeAreas, activeCountries, location[0]);

    onApply(
      {
        area: get(activeAreas, '[0]'),
        country: get(activeCountries, '[0]'),
        location,
      },
      searchTagsForWorkOptions,
    );
  };

  const renderView = () => {
    switch (activeView) {
      case LOCATION_VIEWS.HOW_WOULD_LIKE_WORK_VIEW:
        return (
          <HowWouldLikeWork
            activeAreas={activeAreas}
            activeCountries={activeCountries}
            activeLocation={location}
            activeOptionKeys={activeWorkOptions}
            goBack={onClose}
            onCheckBoxClick={option => {
              handleWhereDoYouLikeWorkSelect(HOW_WOULD_LIKE_WORK_ACTIONS.SELECT, option);
            }}
            onContentClick={option => {
              handleWhereDoYouLikeWorkSelect(HOW_WOULD_LIKE_WORK_ACTIONS.EDIT, option);
            }}
            onNext={handleApply}
            onWorkOptionsClear={() => setActiveWorkOptions([])}
          />
        );
      case LOCATION_VIEWS.LOCATION_SEARCH_VIEW:
        return (
          <LocationSearch
            defaultLocation={location}
            goBack={handleBackFromLocationView}
            onLocationClear={() => {
              setLocation({});
              setActiveWorkOptions([]);
            }}
            onLocationSelect={location => handleLocationSelect([location])}
            onNext={() => setActiveView(LOCATION_VIEWS.HOW_WOULD_LIKE_WORK_VIEW)}
          />
        );
      case LOCATION_VIEWS.AREA_SELECTION_VIEW:
        return (
          <AreaSelection
            activeCountries={activeCountries}
            changeAreasListFromParent
            goBack={() => setActiveView(LOCATION_VIEWS.HOW_WOULD_LIKE_WORK_VIEW)}
            onAreaSave={handleAreaSave}
            onAreasClear={() => {
              setActiveAreas([]);
              setActiveWorkOptions([]);
            }}
            onSpecificCountrySelect={() => setActiveView(LOCATION_VIEWS.COUNTRY_SEARCH_VIEW)}
            selectedAreas={activeAreas}
          />
        );
      case LOCATION_VIEWS.COUNTRY_SEARCH_VIEW:
        return (
          <CountrySearch
            changeCountriesFromParent
            goBack={() => setActiveView(LOCATION_VIEWS.AREA_SELECTION_VIEW)}
            onCountriesClear={() => {
              setActiveCountries([]);
              setActiveWorkOptions([]);
            }}
            onCountrySave={handleCountrySave}
            selectedCountries={activeCountries}
          />
        );

      default:
        return null;
    }
  };

  return <div className="location-modal fade-in">{renderView()}</div>;
};

Locations.propTypes = {
  defaultAreas: PropTypes.array,
  defaultCountries: PropTypes.array,
  defaultLocation: PropTypes.array,
  defaultView: PropTypes.oneOf(Object.values(LOCATION_VIEWS)),
  onApply: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  workOptions: PropTypes.arrayOf(
    PropTypes.oneOf([OpeningRemoteTypeEnum.FULLY, OpeningRemoteTypeEnum.HYBRID, OpeningRemoteTypeEnum.ONSITE]),
  ),
};
