import { GLOBAL_AREA_ID } from '@src/constants';
import {
  OpeningSearchEntitySearchParamsInput,
  SearchOpeningEntityTagInput,
  SearchOpeningEntityTagNameEnum,
} from '@src/graphql/generated';

export const getSearchTags = (
  allSearchTags: SearchOpeningEntityTagInput[] = [],
  searchTagsWithAndCondition: SearchOpeningEntityTagNameEnum[] = [],
  searchTagsWithOrCondition: SearchOpeningEntityTagNameEnum[] = [],
  groupSearchTagsWithOrCondition: SearchOpeningEntityTagNameEnum[] = [],
): OpeningSearchEntitySearchParamsInput => {
  const filteredSearchTags = allSearchTags.filter(searchTag => {
    return searchTag.value !== GLOBAL_AREA_ID && searchTag.value !== undefined && searchTag.value !== null;
  });

  const result: OpeningSearchEntitySearchParamsInput = {
    groupSearchTagsWithOrCondition: [],
    searchTagsWithAndCondition: [],
    searchTagsWithOrCondition: [],
  };

  for (const searchTag of filteredSearchTags) {
    if (searchTagsWithAndCondition.includes(searchTag.tag) && typeof searchTag.value !== 'undefined') {
      result.searchTagsWithAndCondition?.push(searchTag);
    }

    if (searchTagsWithOrCondition.includes(searchTag.tag) && typeof searchTag.value !== 'undefined') {
      result.searchTagsWithOrCondition?.push(searchTag);
    }

    if (groupSearchTagsWithOrCondition.includes(searchTag.tag) && typeof searchTag.value !== 'undefined') {
      result.groupSearchTagsWithOrCondition?.push(searchTag);
    }
  }

  return result;
};

// Get pre-filled search tags for searchOpeningFeedThreads query.
export const getPreFilledSearchTags = (
  allSearchTags: SearchOpeningEntityTagInput[],
): OpeningSearchEntitySearchParamsInput => {
  const searchTagsWithOnsiteAndHybridSelected = allSearchTags.filter(({ tag }) => {
    return tag === SearchOpeningEntityTagNameEnum.REMOTE_TYPE;
  });

  const activeSearchTags =
    searchTagsWithOnsiteAndHybridSelected.length === 2
      ? getSearchTags(
          allSearchTags,
          [SearchOpeningEntityTagNameEnum.POSITION_LOCATION, SearchOpeningEntityTagNameEnum.STATE],
          [
            SearchOpeningEntityTagNameEnum.REMOTE_ALLOWED_LOCATION,
            SearchOpeningEntityTagNameEnum.REMOTE_ALLOWED_COUNTRY,
            SearchOpeningEntityTagNameEnum.REMOTE_ALLOWED_AREA,
            SearchOpeningEntityTagNameEnum.REMOTE_TYPE,
          ],
          [SearchOpeningEntityTagNameEnum.SENIORITY, SearchOpeningEntityTagNameEnum.SKILL],
        )
      : getSearchTags(
          allSearchTags,
          [
            SearchOpeningEntityTagNameEnum.POSITION_LOCATION,
            SearchOpeningEntityTagNameEnum.STATE,
            SearchOpeningEntityTagNameEnum.REMOTE_TYPE,
          ],
          [
            SearchOpeningEntityTagNameEnum.REMOTE_ALLOWED_LOCATION,
            SearchOpeningEntityTagNameEnum.REMOTE_ALLOWED_COUNTRY,
            SearchOpeningEntityTagNameEnum.REMOTE_ALLOWED_AREA,
          ],
          [SearchOpeningEntityTagNameEnum.SENIORITY, SearchOpeningEntityTagNameEnum.SKILL],
        );

  return activeSearchTags;
};
