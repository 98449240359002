import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { HorizontalScroller } from '@src/components/HorizontalScroller';
import './styles.scss';

export const HorizontalScrollerAbout = ({ className, children }) => (
  <HorizontalScroller className={classnames('company-profile-about-scroller', className)}>
    {children}
  </HorizontalScroller>
);

HorizontalScrollerAbout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
