import React, { MutableRefObject, useRef } from 'react';
import PropTypes from 'prop-types';
import { FixedHeader } from '@src/components/FixedHeader';
import { AutoSuggestionsSearch } from '@src/containers/AutoSuggestionsSearch';
import { Statistics } from '../Statistics';
import { HighlightedCompanies } from '../HighlightedCompanies';
import './styles.scss';

export const Suggestions = ({ searchPattern, closeSearch, onSuggestionChange, onSuggestionClick }) => {
  const bodyRef = useRef<HTMLDivElement | null>(null);

  const getBodyRef = (): MutableRefObject<HTMLDivElement | null> => {
    return bodyRef;
  };

  return (
    <div className="search-suggestions fade-in">
      <FixedHeader
        disableOpacity
        onBack={closeSearch}
        title="Search jobs or companies"
        wrapperClassName="search-suggestions__header"
      />

      <div className="search-suggestions__body" ref={bodyRef}>
        <div className="search-suggestions__auto-suggestions">
          <AutoSuggestionsSearch
            getBodyRef={getBodyRef}
            onSuggestionChange={onSuggestionChange}
            onSuggestionClick={onSuggestionClick}
            searchPattern={searchPattern}
          />
        </div>

        {!searchPattern && (
          <>
            <Statistics />

            <HighlightedCompanies getBodyRef={getBodyRef} />
          </>
        )}
      </div>
    </div>
  );
};

Suggestions.propTypes = {
  closeSearch: PropTypes.func.isRequired,
  onSuggestionChange: PropTypes.func.isRequired,
  onSuggestionClick: PropTypes.func.isRequired,
  searchPattern: PropTypes.string,
};
