import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import Svg from 'svg-inline-react';
import FrMfLogoWhiteSvg from 'assets/images/fr-mf-logo-white.svg';
import { BlueButton, OutlinedButton } from '@src/components/Button';
import { DOWNLOAD_APP_URL, PUBLIC_APP_URL } from '@src/constants';
import { fetchData } from '@src/util/fetchData';
import { detectBrowser } from '@src/util/detectBrowser';
import { trackEvent } from '@src/metrics';
import './styles.scss';

export const PromoModalContent = ({ onClose }) => {
  const [redirectUrl, setRedirectUrl] = useState(DOWNLOAD_APP_URL);

  const getRedirectUrl = () => {
    fetchData
      .post('https://api2.branch.io/v1/url', {
        branch_key: 'key_live_lhBVen1kBOWREdW00HeLclblFxku6UYv',
        data: {
          $desktop_url: PUBLIC_APP_URL,
          redirectPath: window.location.pathname + window.location.search,
        },
      })
      .then(({ url }) => setRedirectUrl(url));
  };

  const browser = detectBrowser();

  useEffect(() => {
    getRedirectUrl();

    return () => {
      trackEvent({
        context: 'Public App Install CTA Modal',
        interaction: 'Continue In Browser',
      });
    };
  }, []);

  return (
    <div className="promo-modal-content">
      <div className="promo-modal-content__header">Use MeetFrank in...</div>

      <div className="promo-modal-content__body">
        <div className="promo-modal-content__item">
          <div className="promo-modal-content__icon-wrapper">
            <Svg className="promo-modal-content__frank-icon" src={FrMfLogoWhiteSvg} />
          </div>

          <span>MeetFrank app</span>

          <BlueButton
            className="promo-modal-content__button"
            isExternalLink
            isFilled
            onClick={() =>
              trackEvent({
                context: 'Public App Install CTA Modal',
                interaction: 'Open In App',
              })
            }
            url={redirectUrl}
          >
            Open
          </BlueButton>
        </div>

        <div className="promo-modal-content__item">
          {browser ? (
            <Fragment>
              <div className="promo-modal-content__icon-wrapper">
                <Svg className="promo-modal-content__icon" src={browser.logo} />
              </div>

              <span>{browser.name}</span>

              <OutlinedButton className="promo-modal-content__button" color="gray" onClick={onClose}>
                Continue
              </OutlinedButton>
            </Fragment>
          ) : (
            <OutlinedButton
              className="promo-modal-content__button promo-modal-content__button--full"
              color="gray"
              onClick={onClose}
            >
              Continue in the browser
            </OutlinedButton>
          )}
        </div>
      </div>
    </div>
  );
};

PromoModalContent.propTypes = {
  onClose: PropTypes.func.isRequired,
};
