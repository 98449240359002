export const replaceMessageStringVariables = (
  message: string,
  variables: { [key: string]: string }
): string => {
  let newMessage = message

  Object.keys(variables).forEach((key) => {
    newMessage = newMessage.replace(new RegExp(`(:${key}:)`, 'g'), variables[key])
  })

  return newMessage
}
