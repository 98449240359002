import path from 'path';
import { SetEmojiSupportedStatus } from './interfaces/setEmojiSupportedStatus.interface';

export const SET_EMOJI_SUPPORTED_STATUS = path.join(__dirname, 'setEmojiSupportedStatus');
export const setEmojiSupportedStatus: SetEmojiSupportedStatus = isEmojiSupported => {
  return {
    status: isEmojiSupported,
    type: SET_EMOJI_SUPPORTED_STATUS,
  };
};
