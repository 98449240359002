/**
 * We can add more opetions from following list
 * SRC: https://stackoverflow.com/questions/15852122/hex-transparency-in-colors
 *
 * 10% opacity = HEX_COLOR_OPACITY[10].
 * Usage, e.i: `#000000${HEX_COLOR_OPACITY[10]}`
 */
export const HEX_COLOR_OPACITY = {
  0: '',
  10: '1A',
  100: 'FF',
  15: '26',
  20: '33',
  25: '40',
  30: '4D',
  35: '59',
  40: '66',
  45: '73',
  5: '0D',
  50: '80',
  55: '8C',
  60: '99',
  65: 'A6',
  70: 'B3',
  75: 'BF',
  80: 'CC',
  85: 'D9',
  90: 'E6',
  95: 'F2',
};
