import React, { FC, Fragment } from 'react';
import { get, isEmpty } from 'lodash';
import { HorizontalScroller } from '@src/components/HorizontalScroller';
import { ThumbnailCardWithViewButton } from '@src/components/ThumbnailCard/ThumbnailCardWithViewButton';
import { ThumbnailCardSkeleton } from '@src/components/ThumbnailCard/ThumbnailCardSkeleton';
import { JOB_OPENING_ROUTE } from '@src/constants/routes';
import createLink from '@src/util/createLink';
import { getImageBasedOnIndex } from '@src/util/getImageBasedOnIndex';
import { getLocations } from '@src/util/getLocations';
import { isOpeningType } from '@src/graphql/typeGuards';
import { LocationType } from '@src/graphql/generated';
import { useFeaturedOffers } from './hooks/useFeaturedOffers';
import { CategoryFeaturedOffersProps } from './props';
import './styles.scss';

export const CategoryFeaturedOffers: FC<CategoryFeaturedOffersProps> = ({ searchPattern, searchTags, push }) => {
  const { isLoading, feed } = useFeaturedOffers({ searchPattern, searchTags });

  if (!isLoading && isEmpty(feed)) {
    return null;
  }

  return (
    <div className="category-featured">
      <div className="category-featured__title">Featured offers</div>

      <HorizontalScroller className="category-featured__scroller">
        {!isLoading &&
          feed?.filter(isOpeningType).map((opening, index) => {
            const companyProfile = opening.company.profile;

            const locations = isEmpty(opening.positionLocations) ? opening.targetLocations : opening.positionLocations;
            const locationTitle = getLocations(opening.remote.type, (locations || []) as LocationType[]);
            const totalRatingsCount = get(opening.company, 'reviewsOverview.totalCount');
            const totalRating = get(opening.company, 'reviewsOverview.ratings.total');

            return (
              <ThumbnailCardWithViewButton
                backGroundImage={getImageBasedOnIndex(companyProfile.officeImages, companyProfile.headerImage, index)}
                locationTitle={locationTitle}
                logo={companyProfile.logo?.fullUrl}
                logoTitle={companyProfile.name}
                onClick={() => push(createLink({ link: JOB_OPENING_ROUTE, params: { openingId: opening.id } }))}
                title={opening.title}
                totalRating={totalRating}
                totalRatingsCount={totalRatingsCount}
                wrapperClassName="category-featured__item"
              />
            );
          })}

        {isLoading && (
          <Fragment>
            <ThumbnailCardSkeleton />
            <ThumbnailCardSkeleton />
          </Fragment>
        )}
      </HorizontalScroller>
    </div>
  );
};
