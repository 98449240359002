import React, { FC } from 'react';
import { AnonymousUserSvgProps } from './props';

export const AnonymousUserSvg: FC<AnonymousUserSvgProps> = ({ pathColor = 'black' }) => {
  return (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.5 13.3333C17.1593 13.3333 17.8037 13.5288 18.3519 13.8951C18.9001 14.2614 19.3273 14.782 19.5796 15.3911C19.8319 16.0001 19.8979 16.6704 19.7693 17.317C19.6407 17.9636 19.3232 18.5575 18.857 19.0237C18.3908 19.4899 17.7969 19.8073 17.1503 19.936C16.5037 20.0646 15.8335 19.9986 15.2244 19.7463C14.6153 19.494 14.0947 19.0667 13.7284 18.5186C13.3622 17.9704 13.1667 17.3259 13.1667 16.6667H11.5C11.4997 17.4754 11.2054 18.2564 10.6719 18.8642C10.1385 19.472 9.40225 19.8651 8.60042 19.9704C7.79859 20.0756 6.98585 19.8857 6.31363 19.4362C5.6414 18.9866 5.15553 18.308 4.94655 17.5268C4.73757 16.7455 4.81973 15.915 5.17772 15.1898C5.53571 14.4646 6.14511 13.8944 6.89239 13.5852C7.63967 13.2761 8.47386 13.2491 9.23953 13.5094C10.0052 13.7697 10.6501 14.2995 11.0542 15H13.6125C13.905 14.493 14.3259 14.0721 14.8328 13.7795C15.3397 13.4869 15.9147 13.333 16.5 13.3333ZM8.16667 15C7.72464 15 7.30072 15.1756 6.98816 15.4882C6.67559 15.8007 6.5 16.2246 6.5 16.6667C6.5 17.1087 6.67559 17.5326 6.98816 17.8452C7.30072 18.1577 7.72464 18.3333 8.16667 18.3333C8.60869 18.3333 9.03262 18.1577 9.34518 17.8452C9.65774 17.5326 9.83333 17.1087 9.83333 16.6667C9.83333 16.2246 9.65774 15.8007 9.34518 15.4882C9.03262 15.1756 8.60869 15 8.16667 15ZM16.5 15C16.058 15 15.6341 15.1756 15.3215 15.4882C15.0089 15.8007 14.8333 16.2246 14.8333 16.6667C14.8333 17.1087 15.0089 17.5326 15.3215 17.8452C15.6341 18.1577 16.058 18.3333 16.5 18.3333C16.942 18.3333 17.366 18.1577 17.6785 17.8452C17.9911 17.5326 18.1667 17.1087 18.1667 16.6667C18.1667 16.2246 17.9911 15.8007 17.6785 15.4882C17.366 15.1756 16.942 15 16.5 15ZM15.6667 5C16.5507 5 17.3986 5.35119 18.0237 5.97631C18.6488 6.60143 19 7.44928 19 8.33333V10.8333H20.6667V12.5H4V10.8333H5.66667V8.33333C5.66667 7.44928 6.01786 6.60143 6.64298 5.97631C7.2681 5.35119 8.11595 5 9 5H15.6667ZM15.6667 6.66667H9C8.12167 6.66667 7.33333 7.45833 7.33333 8.33333V10.8333H17.3333V8.33333C17.3333 7.455 16.5417 6.66667 15.6667 6.66667Z"
        fill={pathColor}
      />
    </svg>
  );
};
