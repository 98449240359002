import React, { FC } from 'react';
import classnames from 'classnames';
import Svg from 'svg-inline-react';
import FrNotificationsSvg from 'assets/images/fr-notifications.svg';
import FrUserSvg from 'assets/images/fr-user.svg';
import { Link } from '@src/components/Link';
import { INBOX_PROFILE_ROUTE, NOTIFICATION_CENTER_ROUTE } from '@src/constants/routes';
import { getNotificationCenterUnreadCount } from '@src/redux/selectors/notificationCenter';
import { useAppSelector } from '@src/store';
import './styles.scss';

export const DefaultIcons: FC = () => {
  const notificationCenterUnreadCount = useAppSelector(getNotificationCenterUnreadCount);

  const defaultIcons = [
    {
      isUnreadShown: !!notificationCenterUnreadCount,
      svg: FrNotificationsSvg,
      to: NOTIFICATION_CENTER_ROUTE,
    },
    {
      svg: FrUserSvg,
      to: INBOX_PROFILE_ROUTE,
    },
  ];

  return (
    <>
      {defaultIcons.map(({ svg, to, isUnreadShown }, index) => (
        <Link
          className={classnames(
            'base-app-bar-default-icons__item',
            isUnreadShown && 'base-app-bar-default-icons__item--unread',
          )}
          key={index}
          to={to}
        >
          <Svg src={svg} />
        </Link>
      ))}
    </>
  );
};
