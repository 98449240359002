import React, { FC } from 'react';
import { InvisibleButton } from '@src/components/Button';
import { ChatMenuProps } from './props';
import './styles.scss';

export const ChatMenu: FC<ChatMenuProps> = ({ closeChat }) => {
  return (
    <div className="chat-menu content-enter-animation">
      <InvisibleButton onClick={closeChat}>Stop conversation</InvisibleButton>
    </div>
  );
};
