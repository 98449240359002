import { RootState } from '@src/store/interfaces/store.interface';
import { PageNames } from './enums/pageNames.enum';

export const getScroll = (state: RootState): Record<PageNames, number | undefined> | undefined => {
  return state?.scroll;
};

export const getNotificationCenterScroll = ({ scroll }) => scroll.notificationCenter;
export const getExploreTrendingPostsScroll = ({ scroll }) => scroll.exploreTrendingPosts;
export const getCompanyJobOpeningsScroll = ({ scroll }) => scroll.companyJobOpenings;
