import React, { FC } from 'react';
import Svg from 'svg-inline-react';
import ArrowRightSvg from 'assets/images/fr-arrow-right.svg';
import { BlueButton } from '@src/components/Button';
import { JOB_OPENING_ROUTE } from '@src/constants/routes';
import createLink from '@src/util/createLink';
import { FindOutMoreProps } from './props';
import './styles.scss';

export const FindOutMore: FC<FindOutMoreProps> = ({ openingId }) => {
  return (
    <div className="opening-find-out-more">
      <div className="opening-find-out-more__content">
        <h4 className="opening-find-out-more__title">
          Find out more about <br /> the position
        </h4>
        <BlueButton
          className="opening-find-out-more__read-more"
          isFilled
          isLink
          to={createLink({
            link: JOB_OPENING_ROUTE,
            params: { openingId },
          })}
        >
          Read more
          <Svg src={ArrowRightSvg} />
        </BlueButton>
      </div>
    </div>
  );
};
