import React, { FC, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import qs from 'qs';
import { isEmpty, includes } from 'lodash';
import { useHistory } from 'react-router-dom';
import { trackEvent } from '@src/metrics';
import { HorizontalScroller } from '@src/components/HorizontalScroller';
import { SEARCH_TAGS, COUNTRIES_WITHOUT_GLOBAL_ACCESS } from '@src/constants';
import createLink from '@src/util/createLink';
import { useGetUserQuery } from '@src/graphql/generated';
import { closeModal, setContent as setModalContent } from '@src/redux/actions/slideUpModal';
import { useAppDispatch } from '@src/store';
import Specialities from './Specialities';
import Countries from './Countries';
import Seniorities from './Seniorities';
import './styles.scss';

const normalizeFilterAsSearchTags = (filters, tag, locationSearch) => {
  const prevFilters = qs.parse(locationSearch, { ignoreQueryPrefix: true });

  const { POSITION_COUNTRY, REMOTE, RELOCATION, SPECIALITY, SENIORITY } = SEARCH_TAGS;
  let prevFiltersFilteredByTag = [];
  let newFilters = [];

  if ([POSITION_COUNTRY, REMOTE, RELOCATION].includes(tag)) {
    prevFiltersFilteredByTag = Object.values(prevFilters).filter(
      item => ![POSITION_COUNTRY, REMOTE, RELOCATION].includes(item.tag),
    );
  } else if ([SPECIALITY, SENIORITY].includes(tag)) {
    prevFiltersFilteredByTag = Object.values(prevFilters).filter(item => item.tag !== tag);
  }

  if (!isEmpty(filters)) {
    newFilters = filters.map(item => {
      return {
        tag: [REMOTE, RELOCATION].includes(item.id) ? item.id : tag,
        value: [REMOTE, RELOCATION].includes(item.id) ? true : item.id,
      };
    });
  }

  return [...newFilters, ...prevFiltersFilteredByTag];
};

export const Filters: FC = ({
  metricsContext,
  pageRoute,
  routeReplace,
  location,
  type,
  filtersToShow,
  wrapperClassName,
}) => {
  const locationSearch = location && location.search;

  const history = useHistory();

  const dispatch = useAppDispatch();

  const { data: userData } = useGetUserQuery();

  const userCountryId = userData?.currentUser.location?.country?.id;

  const setFilters = (filters, searchTag) => {
    routeReplace
      ? history.replace(
          createLink(pageRoute, { queryParams: normalizeFilterAsSearchTags(filters, searchTag, locationSearch) }),
        )
      : history.push(
          createLink(pageRoute, { queryParams: normalizeFilterAsSearchTags(filters, searchTag, locationSearch) }),
        );

    dispatch(closeModal());

    trackEvent({ context: metricsContext, interaction: `Update ${searchTag.toLowerCase()} filter` });
  };

  return (
    <HorizontalScroller className={classnames('filters', type && `filters--${type}`, wrapperClassName)}>
      <>
        {includes(filtersToShow, 'specialities') && (
          <Specialities
            locationSearch={locationSearch}
            setFilters={setFilters}
            setModalContent={data => dispatch(setModalContent(data))}
            viewType={type}
          />
        )}

        {!includes(COUNTRIES_WITHOUT_GLOBAL_ACCESS, userCountryId) && includes(filtersToShow, 'countries') && (
          <Countries
            locationSearch={locationSearch}
            setFilters={setFilters}
            setModalContent={data => dispatch(setModalContent(data))}
            viewType={type}
          />
        )}

        {includes(filtersToShow, 'seniorities') && (
          <Seniorities
            locationSearch={locationSearch}
            setFilters={setFilters}
            setModalContent={data => dispatch(setModalContent(data))}
            viewType={type}
          />
        )}
      </>
    </HorizontalScroller>
  );
};

Filters.defaultProps = {
  routeReplace: false,
};

Filters.propTypes = {
  filtersToShow: PropTypes.arrayOf(PropTypes.oneOf(['specialities', 'countries', 'seniorities'])),
  location: PropTypes.object.isRequired,
  metricsContext: PropTypes.string,
  pageRoute: PropTypes.string.isRequired,
  routeReplace: PropTypes.bool,
  type: PropTypes.oneOf(['fixed-header', 'pill', 'label']),
  wrapperClassName: PropTypes.string,
};
