import React, { FC } from 'react';
import { getPeriodLabelByType } from '@src/util/salaryPeriod';
import { SalaryRangeProps } from './props';

export const SalaryRange: FC<SalaryRangeProps> = ({ salaryRange }) => {
  if (!salaryRange.from && !salaryRange.to) {
    return null;
  }

  return (
    <>
      {salaryRange.from}
      {salaryRange.from && salaryRange.to && <>&nbsp;-&nbsp;</>}
      {salaryRange.to}
      {salaryRange.currency?.symbol}
      {salaryRange.period && '/'}
      {salaryRange.period && getPeriodLabelByType(salaryRange.period)}{' '}
    </>
  );
};
