import React, { FC, useEffect, useState } from 'react';
import { FixedHeader } from '@src/components/FixedHeader';
import { CountriesList } from '@src/containers/CountriesList';
import { CountryType } from '@src/graphql/generated';
import { ActionButtons } from '../ActionButtons';
import { CountrySearchProps } from './props';
import './styles.scss';

export const CountrySearch: FC<CountrySearchProps> = ({
  selectedCountries,
  onCountrySave,
  goBack,
  onCountriesClear,
}) => {
  const [activeCountries, setActiveCountries] = useState(selectedCountries);

  useEffect(() => {
    setActiveCountries(selectedCountries);
  }, [selectedCountries]);

  const handleCountrySelect = (country: CountryType) => {
    const updatedCountries = activeCountries.find(it => it.id === country.id)
      ? activeCountries.filter(it => it.id !== country.id)
      : [...activeCountries, country];

    setActiveCountries(updatedCountries);
  };

  return (
    <div className="place-filter-modal-country-search">
      <FixedHeader
        disableOpacity
        onBack={goBack}
        title="Select a country for remote work"
        wrapperClassName="place-filter-modal-country-search__header"
      />

      <div className="place-filter-modal-country-search__body">
        <CountriesList activeCountries={activeCountries} onCountrySelect={country => handleCountrySelect(country)} />
      </div>

      <div className="place-filter-modal-country-search__footer">
        <ActionButtons
          blueButtonLabel="Apply"
          blueButtonProps={{
            onClick: () => onCountrySave(activeCountries),
          }}
          invisibleButtonProps={{
            onClick: onCountriesClear,
          }}
        />
      </div>
    </div>
  );
};
