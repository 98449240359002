import React, { FC } from 'react';
import { SectionTitleProps } from './props';
import './styles.scss';

export const SectionTitle: FC<SectionTitleProps> = ({ title, subtitle }) => {
  return (
    <div className="reviews-page-section-title">
      <div className="reviews-page-section-title__title">{title}</div>

      {subtitle && (
        <div className="reviews-page-section-title__text-wrapper">
          <span className="reviews-page-section-title__text">{subtitle}</span>
        </div>
      )}
    </div>
  );
};
