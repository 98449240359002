import React, { FC } from 'react';
import { HorizontalListBlock } from '@src/components/HorizontalListBlock';
import createLink from '@src/util/createLink';
import { COMPANY_REVIEWS_ROUTE } from '@src/constants';
import { CompanyHiringStateEnum } from '@src/graphql/generated';
import { ScoreWithLocation } from '@src/components/ScoreWithLocation';
import { CompanyReviewFeedItem } from '../CompanyReviewFeedItem';
import { CompanyReviewFeedItemWithCompanyHeaderProps } from './props';
import './styles.scss';

export const CompanyReviewFeedItemWithCompanyHeader: FC<CompanyReviewFeedItemWithCompanyHeaderProps> = ({ review }) => {
  const company = review.company;

  const totalRating = company.reviewsOverview?.ratings.total;
  const logo = company.profile?.logo?.fullUrl;
  const location = company.profile.offices.find(office => office.isFoundingLocation || office.isHeadquarter)?.location
    .shortName;

  return (
    <div className="review-feed-item-with-header">
      <div className="review-feed-item-with-header__header">
        <HorizontalListBlock
          to={createLink(COMPANY_REVIEWS_ROUTE, { params: { companyId: company.id } })}
          key={company.id}
          description={<ScoreWithLocation score={totalRating} size="small" location={location} />}
          name={company.profile?.name}
          statusColor={company.hiringState === CompanyHiringStateEnum.ACTIVE ? 'fr-sp3' : 'fr-il-red'}
          statusLabel={company.hiringState === CompanyHiringStateEnum.ACTIVE ? 'Hiring' : 'Not hiring'}
          imageSrc={logo}
        />
      </div>

      <CompanyReviewFeedItem review={review} isSpaced={false} />
    </div>
  );
};
