import React, { FC, ReactNode } from 'react';
import classnames from 'classnames';
import Svg from 'svg-inline-react';
import ArrowSvg from 'assets/images/fr-arrow-half-up.svg';
import FrCheckSvg from 'assets/images/fr-check.svg';
import { SalaryRange } from '../SalaryRange';
import { SalaryInfoProps } from './props';
import './styles.scss';

export const SalaryInfo: FC<SalaryInfoProps> = ({ isDark, wrapperClassName, userSalary, salaryRange }) => {
  const userSalaryValue = userSalary.value || 0;
  const rangeFrom = salaryRange?.from || 0;
  const rangeTo = salaryRange?.to || 0;

  const getIcon = (): ReactNode => {
    if (userSalaryValue < rangeFrom) {
      return (
        <div className="opening-feed-item-salary-info__bubble opening-feed-item-salary-info__bubble--green">
          <Svg src={ArrowSvg} />
        </div>
      );
    }

    if (userSalaryValue > rangeTo) {
      return (
        <div className="opening-feed-item-salary-info__bubble opening-feed-item-salary-info__bubble--lower">
          <Svg src={ArrowSvg} />
        </div>
      );
    }

    return (
      <div className="opening-feed-item-salary-info__bubble opening-feed-item-salary-info__bubble--same">
        <Svg src={FrCheckSvg} />
      </div>
    );
  };

  return (
    <div className={classnames('opening-feed-item-salary-info', wrapperClassName)}>
      {getIcon()}

      <div
        className={classnames(
          'opening-feed-item-salary-info__text',
          'opening-feed-item-salary-info__text--strong',
          isDark && 'opening-feed-item-salary-info__text--white',
        )}
      >
        Salary
      </div>

      <div
        className={classnames(
          'opening-feed-item-salary-info__text',
          'opening-feed-item-salary-info__text--right',
          isDark && 'opening-feed-item-salary-info__text--white',
        )}
      >
        <SalaryRange salaryRange={salaryRange} />
      </div>
    </div>
  );
};
