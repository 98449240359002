import { get } from 'lodash';
import React, { useLayoutEffect, useEffect, useRef, FC } from 'react';
import { NetworkStatus } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { trackEvent } from '@src/metrics';
import { SubPageAppBar } from '@src/components/PrimaryAppBar';
import { getWrapperScrollableContainer } from '@src/App';
import { markNotificationCenterAsRead, saveNotificationCenterScroll } from '@src/redux/actions/notificationCenter';
import { getNotificationCenterScroll } from '@src/redux/selectors/scroll';
import { handleFetchMore } from '@src/util/handleFetchMore';
import { useAppDispatch, useAppSelector } from '@src/store';
import { useNotificationGroupsQuery } from '@src/graphql/generated';
import RefetchButton from './RefetchButton';
import EmptyState from './EmptyState';
import LoadingState from './LoadingState';
import Feed from './Feed';
import './styles.scss';

export const NotificationCenter: FC = () => {
  const notificationCenterWrapperRef = useRef<HTMLDivElement>(null);

  const wrapperScrollableContainer = getWrapperScrollableContainer();

  const scroll = useAppSelector(getNotificationCenterScroll);

  const history = useHistory();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(markNotificationCenterAsRead());
  }, []);

  const { data, fetchMore, networkStatus } = useNotificationGroupsQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      paginationOptions: {
        limit: 10,
      },
    },
  });

  const pagination = get(data, 'notificationGroups.pagination', {});
  const isFetchMoreLoading = networkStatus === NetworkStatus.fetchMore;
  const notificationGroups = get(data, 'notificationGroups.result', []);
  const isEmpty = !notificationGroups.length;

  useEffect(() => {
    trackEvent({ context: 'Notification Center', interaction: 'View' });

    if (scroll) {
      wrapperScrollableContainer[0].scrollTop = scroll;
    }

    return () => {
      dispatch(saveNotificationCenterScroll(wrapperScrollableContainer[0].scrollTop));
    };
  }, []);

  function loadOlderNotificationGroups() {
    const scrollTop = get(wrapperScrollableContainer, '[0].scrollTop');

    if (
      scrollTop &&
      !isFetchMoreLoading &&
      pagination.hasMoreResults &&
      notificationCenterWrapperRef.current &&
      scrollTop > notificationCenterWrapperRef.current.offsetHeight - window.innerHeight - 150
    ) {
      handleFetchMore(fetchMore, {
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return prev;
          }

          const prevNotifications = prev.notificationGroups;

          const {
            notificationGroups: { result, pagination },
          } = fetchMoreResult;

          return {
            notificationGroups: {
              ...prevNotifications,
              __typename: prevNotifications.__typename,
              pagination: {
                ...prevNotifications.pagination,
                hasMoreResults: pagination.hasMoreResults,
                maxCursor: pagination.maxCursor,
              },
              result: [...prevNotifications.result, ...result],
            },
          };
        },
        variables: {
          paginationOptions: {
            limit: 10,
            minCursor: pagination.maxCursor,
          },
        },
      });
    }
  }

  function loadNewerNotificationGroups() {
    handleFetchMore(fetchMore, {
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }

        const prevNotifications = prev.notificationGroups;

        const {
          notificationGroups: { result, pagination },
        } = fetchMoreResult;

        return {
          notificationGroups: {
            ...prevNotifications,
            __typename: prevNotifications.__typename,
            pagination: {
              ...prevNotifications.pagination,
              minCursor: pagination.minCursor,
            },
            result: [...result, ...prevNotifications.result],
          },
        };
      },
      variables: {
        paginationOptions: {
          limit: 10,
          maxCursor: pagination.minCursor,
        },
      },
    });
  }

  useLayoutEffect(() => {
    wrapperScrollableContainer[0].addEventListener('scroll', loadOlderNotificationGroups);

    return () => {
      wrapperScrollableContainer[0].removeEventListener('scroll', loadOlderNotificationGroups);
    };
  }, [pagination.maxCursor, isFetchMoreLoading]);

  return (
    <div className="notification-center-wrapper content-enter-animation" ref={notificationCenterWrapperRef}>
      <SubPageAppBar isFixed onBack={() => history.goBack()}>
        Notifications
      </SubPageAppBar>

      <RefetchButton onRefetchClick={() => loadNewerNotificationGroups()} />

      <div className="notification-center-wrapper__subtitle">
        Never miss an opportunity. Keep track of all your notifications in one place.
      </div>

      <div className="notification-center-wrapper__notifications">
        {networkStatus <= 2 ? (
          <LoadingState />
        ) : isEmpty ? (
          <EmptyState />
        ) : (
          <Feed notificationGroups={notificationGroups} />
        )}
      </div>
    </div>
  );
};
