import React, { FC } from 'react';
import { BlueButton, GreyButton } from '../Button';
import { ConfirmationModalProps } from './props';
import './styles.scss';

export const ConfirmationModal: FC<ConfirmationModalProps> = ({
  title,
  confirmLabel,
  cancelLabel,
  onCancel,
  onConfirm,
}) => {
  return (
    <div className="confirmation-modal">
      <h3 className="confirmation-modal__title">{title}</h3>
      <div className="confirmation-modal__actions">
        <BlueButton isFilled onClick={onConfirm}>
          {confirmLabel}
        </BlueButton>
        <GreyButton className="confirmation-modal__cancel" onClick={onCancel}>
          {cancelLabel}
        </GreyButton>
      </div>
    </div>
  );
};
