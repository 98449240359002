import React, { FC, useEffect, useRef } from 'react';
import { get, find } from 'lodash';
import { matchPath, useHistory, Redirect, Route, useLocation } from 'react-router-dom';
import { apolloClient } from '@src/graphql/apollo';
import createLink from '@src/util/createLink';
import { setContent as setModalContent, closeModal } from '@src/redux/actions/slideUpModal';
import { UserCompanyReviewModal } from '@src/components/UserCompanyReviewModal';
import { UserEmailPopupModal } from '@src/components/UserEmailPopupModal';
import { INDEX_ROUTE, FIND_YOUR_COMPANY_ROUTE } from '@src/constants/routes';
import { ShouldShowPopupFlagsDocument, ShouldShowPopupFlagsQuery, useGetUserQuery } from '@src/graphql/generated';
import { useAppDispatch } from '@src/store';
import { FinishedOnboardingProps } from './props';

export const FinishedOnboarding: FC<FinishedOnboardingProps> = ({ children }) => {
  const dispatch = useAppDispatch();

  const history = useHistory();

  const location = useLocation();

  const { pathname } = location;

  const shouldDisplayCompanyReviewModal = useRef(true);

  const { data: userData } = useGetUserQuery();

  const user = userData?.currentUser;

  useEffect(() => {
    const shouldAskUserToReviewEmployer = (shouldAskUserToReviewEmployer: boolean): void => {
      if (shouldAskUserToReviewEmployer) {
        shouldDisplayCompanyReviewModal.current = false;

        dispatch(
          setModalContent({
            className: 'review-own-company-modal',
            closeOnLocationChange: false,
            content: (
              <UserCompanyReviewModal
                onNotInterestedClick={() => dispatch(closeModal())}
                onRatingSet={ratingData => {
                  history.push(
                    createLink({
                      link: FIND_YOUR_COMPANY_ROUTE,
                      queryParams: { overallRating: ratingData.rating.toString() },
                    }),
                  );

                  dispatch(closeModal());
                }}
              />
            ),
            height: 'auto',
          }),
        );
      }
    };

    const shouldAskUserEmail = (shouldAskUserEmail: boolean): void => {
      if (shouldAskUserEmail) {
        dispatch(
          setModalContent({
            className: 'ask-email',
            closeOnLocationChange: false,
            content: <UserEmailPopupModal />,
            height: 'auto',
          }),
        );
      }
    };

    async function shouldAskPopups() {
      const { data } = await apolloClient.query<ShouldShowPopupFlagsQuery>({
        fetchPolicy: 'no-cache',
        query: ShouldShowPopupFlagsDocument,
      });

      shouldAskUserEmail(get(data, 'currentUser.meta.shouldAskUserEmail'));
      shouldAskUserToReviewEmployer(get(data, 'currentUser.meta.shouldAskUserToReviewEmployer'));
    }

    const isFindYourCompanyRoute =
      pathname && find([FIND_YOUR_COMPANY_ROUTE], route => matchPath(pathname, { path: route }));

    if (
      user?.onboardingDone &&
      pathname === INDEX_ROUTE &&
      shouldDisplayCompanyReviewModal.current &&
      !isFindYourCompanyRoute
    ) {
      shouldAskPopups();
    }
  }, [user, pathname]);

  if (user) {
    if (user.onboardingDone || pathname === INDEX_ROUTE) {
      return children;
    }

    return <Route render={() => <Redirect to={INDEX_ROUTE} />} />;
  }

  return null;
};
