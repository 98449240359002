import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import FadeInImage from '../FadeInImage';
import { HorizontalListBlockProps } from './props';
import './styles.scss';

export const HorizontalListBlock: FC<HorizontalListBlockProps> = ({
  description,
  imageSrc,
  name,
  onClick,
  statusColor = 'fr-sp3',
  statusLabel,
  to,
  wrapperClassName,
}) => {
  return (
    <Link className={classNames('horizontal-list-block', wrapperClassName)} onClick={() => onClick?.()} to={to}>
      {imageSrc && <FadeInImage className="horizontal-list-block__item-logo" src={imageSrc} />}

      <div className="horizontal-list-block__item-info">
        <div className="horizontal-list-block__item-name">{name}</div>

        {description && <div className="horizontal-list-block__item-location">{description}</div>}
      </div>

      {statusLabel && (
        <div className="horizontal-list-block__last-activity">
          <div
            className={classNames(
              'horizontal-list-block__ball',
              !!statusColor && `horizontal-list-block__ball--${statusColor}`,
            )}
          />

          <div
            className={classNames(
              'horizontal-list-block__last-activity-label',
              !!statusColor && `horizontal-list-block__last-activity-label--${statusColor}`,
            )}
          >
            {statusLabel}
          </div>
        </div>
      )}
    </Link>
  );
};
