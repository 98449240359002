import { LocalStorageKeys } from '@src/localStorage/enums/localStorageKeys.enum';
import { getItemFromLocalStorage } from '@src/localStorage/utils/getItemFromLocalStorage';
import { removeAccessToken } from '@src/redux/actions/accessToken';
import { getAccessToken } from '@src/redux/selectors/accessToken';
import store from '@src/store';
import { captureError } from '@src/util/errors';

export const getToken = (): string | undefined => {
  try {
    return getAccessToken(store.getState()) || getItemFromLocalStorage(LocalStorageKeys.TOKEN_STORAGE_KEY);
  } catch (error) {
    captureError(error);

    store.dispatch(removeAccessToken());

    return undefined;
  }
};
