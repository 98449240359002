import moment from 'moment-timezone';
import { ceil } from 'lodash';

export default function dateStringFromDate(date: Date, diffAddition?: string): string {
  const minutesSinceDate = ceil(moment.utc().diff(moment(date), 'minute', true), 0);
  const hoursSinceDate = moment.utc().diff(moment(date), 'hour') || 1;
  const daysSinceDate = moment.utc().diff(moment(date), 'day') || 1;
  const monthsSinceDate = moment.utc().diff(moment(date), 'month') || 1;

  const diffAdditionWithSpace = diffAddition ? ` ${diffAddition}` : '';

  if (minutesSinceDate < 59) {
    return `${minutesSinceDate}min${diffAdditionWithSpace}`;
  }

  if (hoursSinceDate < 24) {
    return `${hoursSinceDate}h${diffAdditionWithSpace}`;
  }

  if (daysSinceDate < 7) {
    return `${daysSinceDate}d${diffAdditionWithSpace}`;
  }

  if (monthsSinceDate < 12) {
    return moment(date).format('DD/MM');
  }

  return moment(date).format('DD/MM/YY');
}
