import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { HowWouldYouLikeToWorkList } from '@src/containers/HowWouldYouLikeToWorkList';
import { FixedHeader } from '@src/components/FixedHeader';
import { ActionButtons } from '../../ActionButtons';
import './styles.scss';

export const HowWouldLikeWork = ({
  goBack,
  onNext,
  activeOptionKeys,
  activeLocation,
  activeCountries,
  activeAreas,
  onContentClick,
  onCheckBoxClick,
  onWorkOptionsClear,
}) => {
  return (
    <div className="how-would-like-work-modal">
      <FixedHeader
        close
        disableOpacity
        onBack={goBack}
        title="How would you like to work?"
        wrapperClassName="how-would-like-work-modal__header"
      />
      <div className="how-would-like-work-modal__options-section">
        <HowWouldYouLikeToWorkList
          activeAreas={activeAreas}
          activeCountries={activeCountries}
          activeLocation={activeLocation}
          activeOptionKeys={activeOptionKeys}
          onCheckBoxClick={onCheckBoxClick}
          onContentClick={onContentClick}
        />
      </div>
      <div className="how-would-like-work-modal__footer">
        <ActionButtons
          blueButtonProps={{
            disabled: isEmpty(activeOptionKeys),
            onClick: onNext,
          }}
          invisibleButtonProps={{
            disabled: isEmpty(activeOptionKeys),
            onClick: onWorkOptionsClear,
          }}
        />
      </div>
    </div>
  );
};

HowWouldLikeWork.propTypes = {
  activeAreas: PropTypes.array,
  activeCountries: PropTypes.array,
  activeLocation: PropTypes.array,
  activeOptionKeys: PropTypes.array,
  goBack: PropTypes.func.isRequired,
  onCheckBoxClick: PropTypes.func.isRequired,
  onContentClick: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onWorkOptionsClear: PropTypes.func.isRequired,
};
