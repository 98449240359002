import React, { FC } from 'react';
import { UserEmailPopupModal } from '@src/components/UserEmailPopupModal';
import { useAppDispatch } from '@src/store';
import { useGetUserQuery } from '@src/graphql/generated';
import { setContent as setModalContent } from '@src/redux/actions/slideUpModal';
import './styles.scss';

export const EmailPrompt: FC = () => {
  const dispatch = useAppDispatch();

  const { data: userData, loading: isLoading } = useGetUserQuery({
    fetchPolicy: 'network-only',
  });

  const openEmailPopup = () => {
    dispatch(
      setModalContent({
        className: 'ask-email',
        closeOnLocationChange: false,
        content: <UserEmailPopupModal />,
        height: 'auto',
      }),
    );
  };

  if (isLoading || userData?.currentUser.email) {
    return null;
  }

  return (
    <div className="frank-email-prompt" onClick={openEmailPopup}>
      ⚙️ Click to add an email and secure your account
    </div>
  );
};
