import React, { FC } from 'react';
import classnames from 'classnames';
import Svg from 'svg-inline-react';
import { get, round } from 'lodash';
import CircularProgressbar from 'react-circular-progressbar';
import FrStarSvg from 'assets/images/fr-star.svg';
import { JobOpeningMatchProps } from './props';
import './styles.scss';

export const JobOpeningMatch: FC<JobOpeningMatchProps> = ({ wrapperClassName, match }) => {
  const matchScoreDisplay = get(match, 'matchScore.display');

  return (
    <div className={classnames('job-opening-match', wrapperClassName)}>
      <div className={classnames('job-opening-match__container')}>
        <Svg src={FrStarSvg} />
        <span>High match offer</span>

        <div className={classnames('job-opening-match__match-score')}>
          <CircularProgressbar percentage={matchScoreDisplay * 100} strokeWidth={20} />
          {round(matchScoreDisplay * 100)}% match
        </div>
      </div>
    </div>
  );
};
