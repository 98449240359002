import { map } from 'lodash';
import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Label } from '@src/components/Label';
import './styles.scss';

export default function Labels({ wrapperClassName, labels, maxCount }) {
  if (maxCount) {
    const slicedLabels = labels.slice(0, maxCount);

    if (labels.length > slicedLabels.length) {
      labels = [...slicedLabels, `+${labels.length - slicedLabels.length}`];
    } else {
      labels = slicedLabels;
    }
  }

  return (
    <div className={classnames('labels-list', wrapperClassName)}>
      {map(labels, (label, i) => (
        <Label className="labels-list__item" key={i} text={label} type="blue" />
      ))}
    </div>
  );
}

Labels.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  maxCount: PropTypes.number,
  wrapperClassName: PropTypes.string,
};
