import React from 'react';
import FadeInImage from '@src/components/FadeInImage';
import { STATIC_MEDIA_PREFIX } from '@src/constants';
import './styles.scss';

export default function EmptyState() {
  return (
    <div className="notification-center-empty-wrapper">
      <FadeInImage
        imageClassName="notification-center-empty-wrapper__image-container__image"
        src={`${STATIC_MEDIA_PREFIX}/images/empty-notification-center.png`}
        wrapperClassName="notification-center-empty-wrapper__image-container"
      />

      <div className="notification-center-empty-wrapper__title">No new notifications!</div>

      <div className="notification-center-empty-wrapper__text">
        Notifications about discussions, job offers and other interesting activities will appear here.
      </div>
    </div>
  );
}
