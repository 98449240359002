import updeep from 'updeep';
import { mapValues } from 'lodash';
import { USER_UPDATED } from '@src/redux/actions/user';
import { OPENING_STATE_UPDATED } from '@src/redux/actions/openings';

const INITIAL_DIGEST = {
  expandedStates: {},
  lastDigest: {},
  openings: {},
};

export function digest(state = INITIAL_DIGEST, action) {
  switch (action.type) {
    case USER_UPDATED: {
      return updeep.updateIn(`expandedStates.${action.itemKey}`, action.isExpanded, state);
    }
    case OPENING_STATE_UPDATED: {
      return {
        ...state,
        openings: mapValues(state.openings, opening => {
          if (opening._id === action.openingId) {
            return {
              ...opening,
              matchState: action.state,
            };
          }

          return opening;
        }),
      };
    }
    default:
      return state;
  }
}
