import React from 'react';
import PropTypes from 'prop-types';
import FadeInImage from '@src/components/FadeInImage';
import './styles.scss';

export const IMAGE_TYPES = {
  LARGE: 'large',
  SMALL: 'small',
};

export const GalleryImage = ({ src, index, type, setThumbnailRef, showPhotoSwipe }) => (
  <span onClick={showPhotoSwipe(index)} ref={it => setThumbnailRef(index, it)}>
    <FadeInImage className={`location-about-gallery-image location-about-gallery-image--${type}`} src={src} />
  </span>
);

GalleryImage.propTypes = {
  index: PropTypes.number.isRequired,
  setThumbnailRef: PropTypes.func.isRequired,
  showPhotoSwipe: PropTypes.func.isRequired,
  src: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(IMAGE_TYPES)).isRequired,
};
