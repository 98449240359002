import PropTypes from 'prop-types';
import React, { useState } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { getNotificationCenterUnreadCount } from '@src/redux/selectors/notificationCenter';
import { RootState } from '@src/store/interfaces/store.interface';
import './styles.scss';

export function RefetchButton({ notificationUnreadCount, onRefetchClick }) {
  const [hasUpdatedNotificationCenter, setHasUpdatedNotificationCenter] = useState(notificationUnreadCount === 0);

  return (
    <div
      className={classnames('notification-center-refetch-button', {
        'notification-center-refetch-button--hidden': notificationUnreadCount === 0 && hasUpdatedNotificationCenter,
      })}
    >
      <div
        className="notification-center-refetch-button__text"
        onClick={() => {
          setHasUpdatedNotificationCenter(false);
          onRefetchClick();
          setHasUpdatedNotificationCenter(true);
        }}
      >
        New notifications
      </div>
    </div>
  );
}

RefetchButton.propTypes = {
  notificationUnreadCount: PropTypes.number,
  onRefetchClick: PropTypes.func.isRequired,
};

export default connect((state: RootState) => ({
  notificationUnreadCount: getNotificationCenterUnreadCount(state),
}))(RefetchButton);
