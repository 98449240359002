import React, { FC } from 'react';
import classnames from 'classnames';
import { JobOpeningFeedItem } from '@src/containers/Feed/JobOpeningFeedItem';
import { FeedItemSkeleton } from '@src/containers/Feed/Skeleton';
import { EmptyState } from '@src/components/EmptyState';
import { Context } from '@src/metrics/enums/context.enum';
import { FilledOpeningsScroll } from '../FilledOpeningsScroll';
import { OpeningFeedProps } from './props';
import './styles.scss';

export const OpeningFeed: FC<OpeningFeedProps> = ({ feed, isInitialLoading, isLoading, hasMoreResults }) => {
  if (!isLoading && !feed?.length) {
    return (
      <div className="job-search-feed">
        <div className="job-search-feed__empty-state">
          <EmptyState
            imageUrl="/images/empty-states/empty-state-1.png"
            imageWrapperClassName="job-search-feed__empty-state-image"
            infoClassName="job-search-feed__empty-state-content"
            subTitle="There aren’t any offers for this location at the moment. Please try selecting other options."
            subTitleClassName="job-search-feed__empty-state-subtitle"
            title="No active offers at the moment"
            titleClassName="job-search-feed__empty-state-title"
          />
        </div>
      </div>
    );
  }

  return (
    <div className={classnames('job-search-feed feed-bg-color', isInitialLoading && 'job-search-feed--padding')}>
      {!isInitialLoading &&
        feed?.map((opening, index) => {
          if (!opening) {
            return null;
          }

          return (
            <JobOpeningFeedItem
              key={opening.id}
              metricsContext={Context.DIGEST}
              opening={opening}
              wrapperClassName={classnames('job-search-feed__item', index === 0 && 'job-search-feed__item--top-margin')}
            />
          );
        })}

      {isLoading && <FeedItemSkeleton amount={2} wrapperClassName="job-search-feed__item--top-margin" />}

      {hasMoreResults === false && <FilledOpeningsScroll />}
    </div>
  );
};
