import React from 'react';
import Switch from 'react-switch';
import { frGrey300, frPrBlue } from 'assets/_vars.scss';

export const SwitchToggle = props => {
  return <Switch {...props} />;
};

SwitchToggle.defaultProps = {
  checkedIcon: false,
  height: 24,
  offColor: frGrey300,
  onColor: frPrBlue,
  uncheckedIcon: false,
  width: 40,
};
