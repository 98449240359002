import { useEffect } from 'react';
import { throttle } from 'lodash';
import { ArticlesQuery, LanguageCodeEnum, useArticlesQuery } from '@src/graphql/generated';
import { handleFetchMore } from '@src/util/handleFetchMore';
import { trackEvent } from '@src/metrics';
import { Context } from '@src/metrics/enums/context.enum';
import { Interaction } from '@src/metrics/enums/interaction.enum';
import { ItemType } from '@src/metrics/enums/itemType.enum';

const FIRST_BATCH_SIZE = 8;
const NEXT_BATCH_SIZE = 6;

export interface UseArticlesParams {
  scrollableContainer?: HTMLDivElement;
}

export const useArticles = ({ scrollableContainer }: UseArticlesParams) => {
  const {
    data: articlesData,
    loading: isArticlesLoading,
    fetchMore: fetchMoreArticles,
  } = useArticlesQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      filterInput: {
        language: LanguageCodeEnum.EN,
      },
      paginationOptions: {
        limit: FIRST_BATCH_SIZE,
      },
    },
  });

  const articles =
    articlesData?.articles?.result.filter(
      (article): article is Extract<ArticlesQuery['articles']['result'][0], { __typename?: 'ArticleType' }> =>
        article !== null,
    ) || [];
  const pagination = articlesData?.articles?.pagination;

  const loadMore = throttle(({ target: { scrollTop, scrollHeight } }) => {
    const fetchMoreMargin = scrollTop + window.innerHeight * 3;

    if (!isArticlesLoading && pagination?.hasMoreResults && fetchMoreMargin > scrollHeight) {
      handleFetchMore(fetchMoreArticles, {
        updateQuery: (prev: ArticlesQuery, { fetchMoreResult }: { fetchMoreResult: ArticlesQuery }) => {
          if (!fetchMoreResult) {
            return prev;
          }

          const prevReviews = prev.articles;
          const newResult = fetchMoreResult.articles.result;
          const pagination = fetchMoreResult.articles.pagination;

          const result = [...prevReviews.result, ...newResult];

          trackEvent({
            context: Context.ARTICLES,
            interaction: Interaction.FETCH_MORE,
            itemType: ItemType.ARTICLES_FEED,
            itemValue: result.length,
          });

          return {
            ...prev,
            articles: {
              ...prevReviews,
              pagination: {
                // Do not update minCursor, because we use it as a initial minCursor to detect feed update on page mount.
                ...prevReviews.pagination,
                hasMoreResults: pagination.hasMoreResults,
                limit: pagination.limit,
                maxCursor: pagination.maxCursor,
              },
              result: result,
            },
          };
        },
        variables: {
          filterInput: {
            language: LanguageCodeEnum.EN,
          },
          paginationOptions: {
            limit: NEXT_BATCH_SIZE,
            minCursor: pagination.maxCursor,
          },
        },
      });
    }
  }, 200);

  useEffect(() => {
    if (scrollableContainer) {
      scrollableContainer.addEventListener('scroll', loadMore);
    }

    return () => {
      if (scrollableContainer) {
        scrollableContainer.removeEventListener('scroll', loadMore);
      }

      loadMore.cancel();
    };
  }, [isArticlesLoading, pagination]);

  return {
    articles: articles,
    isArticlesLoading: isArticlesLoading,
  };
};
