import { InMemoryCacheConfig } from '@apollo/client';
import { mergeUpdatablePaginatedResults } from '@src/graphql/utils/mergeUpdateablePaginatedResults';
import { mergeUpdatableSortedPaginatedResults } from '@src/graphql/utils/mergeUpdateableSortedPaginatedResults';
import { mergeMessageThreadPaginatedResults } from '@src/graphql/utils/mergeMessageThreadPaginatedResults';
import { mergePaginatedResults } from '../../utils/mergePaginatedResults';
import { mergeUniqueArrayItems } from '../utils/mergeUniqueArrayItems';

export const typePolicies: InMemoryCacheConfig['typePolicies'] = {
  CompanyProfileType: {
    fields: {
      benefits: {
        merge: mergeUniqueArrayItems,
      },
      officeImages: {
        merge: mergeUniqueArrayItems,
      },
    },
  },
  CompanyReviewsOverviewType: { merge: true },
  CompanyType: {
    fields: {
      locations: {
        merge: mergeUniqueArrayItems,
      },
      types: {
        merge: mergeUniqueArrayItems,
      },
    },
  },
  MessageThreadType: {
    fields: {
      messages: {
        keyArgs: false,
        merge: mergeMessageThreadPaginatedResults,
      },
    },
  },
  OnboardedUserProfileType: { merge: true },
  OpeningRemoteType: { merge: true },
  Query: {
    fields: {
      messageRequests: {
        keyArgs: false,
        merge: mergeUpdatablePaginatedResults,
      },
      searchOpenings: {
        keyArgs: ['searchParams'],
        merge: mergePaginatedResults,
      },
      searchOpeningsV2: {
        keyArgs: ['searchParams', 'categoryType'],
        merge: mergePaginatedResults,
      },
    },
  },
  RelevantOpeningsType: {
    fields: {
      searchResults: {
        keyArgs: ['sortedPaginationOptions', ['sortBy']],
        merge: mergeUpdatableSortedPaginatedResults,
      },
      searchResultsV2: {
        keyArgs: ['sortedPaginationOptions', ['sortBy']],
        merge: mergeUpdatableSortedPaginatedResults,
      },
    },
    merge: true,
  },
  UserMetaType: { merge: true },
};
