import React, { FC, Fragment } from 'react';
import PropTypes from 'prop-types';
import Svg from 'svg-inline-react';
import { get, isEmpty, map, size } from 'lodash';
import PinSvg from 'assets/images/fr-pin.svg';
import ForwardSvg from 'assets/images/fr-forward.svg';
import { useHistory } from 'react-router-dom';
import { HorizontalScroller } from '@src/components/HorizontalScroller';
import ImageMosaique from '@src/components/ImageMosaique';
import { MessageWithReadMore } from '@src/components/MessageWithReadMore';
import Labels from '@src/components/Labels';
import { COMPANY_ABOUT_ROUTE, JOB_OPENING_ROUTE } from '@src/constants/routes';
import createLink from '@src/util/createLink';
import { ContentCard, ContentCardContent } from './ContentCard';
import './styles.scss';

const MAX_BENEFITS_SHOWN = 3;

export const CompanyInfoScroller: FC = ({ noMessage, noMessageLimit, message, company, images, openingId }) => {
  const history = useHistory();

  const { locations, benefits } = company.profile;

  let perksAndBenefits = map(benefits, 'value').slice(0, MAX_BENEFITS_SHOWN);

  if (size(benefits) > MAX_BENEFITS_SHOWN) {
    perksAndBenefits = [...perksAndBenefits, `+${size(benefits.slice(3, size(benefits)))}`];
  }

  function redirectToProfile(evt) {
    evt.preventDefault();

    history.push(
      createLink({
        link: COMPANY_ABOUT_ROUTE,
        params: { companyId: company.id },
      }),
    );
  }

  const [firstLocation, ...otherLocations] = locations;

  return (
    <div className="company-info-scroller">
      {!noMessage && (
        <MessageWithReadMore
          shouldForceInline
          limit={noMessageLimit ? undefined : 225}
          lineLimit={noMessageLimit ? undefined : 3}
          message={message}
          messageClassName="company-info-scroller__mission-text"
          onReadMoreClick={() =>
            history.push(
              createLink({
                link: JOB_OPENING_ROUTE,
                params: { openingId },
              }),
            )
          }
          wrapperClassName="company-info-scroller__mission"
        />
      )}

      <HorizontalScroller>
        <ContentCard>
          <ImageMosaique borderRadius="1.5rem" images={images.current} />
        </ContentCard>

        <ContentCard>
          <ContentCardContent theme="grey" title="FACTS ABOUT THE COMPANY">
            <div className="opening-feed-item-content-card__columns">
              <div className="opening-feed-item-content-card__columns-item">
                <div className="opening-feed-item-content-card__columns-item-title">Company Size</div>
                <div className="opening-feed-item-content-card__columns-item-info">
                  {get(company, 'profile.companySize.name', '–')}
                </div>
              </div>
              <div className="opening-feed-item-content-card__columns-item">
                <div className="opening-feed-item-content-card__columns-item-title">Annual revenue</div>
                <div className="opening-feed-item-content-card__columns-item-info">
                  {get(company, 'profile.revenue.value', '–')}
                </div>
              </div>
            </div>

            <div className="opening-feed-item-content-card__footer">
              <Labels
                labels={map([...(get(company, 'types') || []), ...(get(company, 'industries') || [])], 'name')}
                maxCount={3}
              />
            </div>
          </ContentCardContent>
        </ContentCard>

        <ContentCard onClick={evt => evt.preventDefault()}>
          <ContentCardContent theme="grey" title="THE STORY">
            <div className="opening-feed-item-content-card__text">{company.profile.missionStatement}</div>

            <div
              className="opening-feed-item-content-card__footer company-info-scroller__product"
              onClick={redirectToProfile}
            >
              Learn more
            </div>
          </ContentCardContent>
        </ContentCard>

        {!!company.profile.serviceDescription && (
          <ContentCard onClick={evt => evt.preventDefault()}>
            <ContentCardContent theme="pink" title="PRODUCT & SERVICES">
              <div className="opening-feed-item-content-card__text">{company.profile.serviceDescription}</div>

              <div
                className="opening-feed-item-content-card__footer company-info-scroller__product"
                onClick={redirectToProfile}
              >
                Learn more
              </div>
            </ContentCardContent>
          </ContentCard>
        )}

        {!!company.profile.officeDescription && (
          <ContentCard>
            <ContentCardContent theme="yellow" title="OFFICES">
              <div className="opening-feed-item-content-card__text">{company.profile.officeDescription}</div>
              <div className="opening-feed-item-content-card__footer company-info-scroller__offices">
                <Svg src={PinSvg} />
                <div>
                  {firstLocation.shortName}
                  {otherLocations.length > 0 && (
                    <Fragment>
                      &nbsp;
                      <strong>{`+ ${otherLocations.length}`}</strong>
                    </Fragment>
                  )}
                </div>
              </div>
            </ContentCardContent>
          </ContentCard>
        )}

        {!isEmpty(company.profile.benefits) && (
          <ContentCard>
            <ContentCardContent theme="grey" title="PERKS & BENEFITS">
              <div className="opening-feed-item-content-card__columns-item-title">
                Join {company.profile.name} and get all this
              </div>

              <div className="opening-feed-item-content-card__footer">
                <Labels labels={perksAndBenefits} />
              </div>
            </ContentCardContent>
          </ContentCard>
        )}

        <ContentCard onClick={evt => evt.preventDefault()}>
          <ContentCardContent theme="grey" title="DISCOVER MORE">
            <div className="opening-feed-item-content-card__text">
              Be up to date with the latest activity and job openings from {company.profile.name}.
            </div>

            <div
              className="opening-feed-item-content-card__footer company-info-scroller__discover"
              onClick={redirectToProfile}
            >
              <span>View profile</span>
              <Svg src={ForwardSvg} />
            </div>
          </ContentCardContent>
        </ContentCard>
      </HorizontalScroller>
    </div>
  );
};

CompanyInfoScroller.propTypes = {
  company: PropTypes.object.isRequired,

  // for useRef
  images: PropTypes.shape({
    current: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,

  message: PropTypes.string.isRequired,
  noMessage: PropTypes.bool,
  noMessageLimit: PropTypes.bool,
  openingId: PropTypes.string.isRequired,
};
