import React, { FC } from 'react';
import classnames from 'classnames';
import { Markdown } from '@src/components/Markdown';
import { ClampedMessageProps } from './props';
import './styles.scss';

export const ClampedMessage: FC<ClampedMessageProps> = ({
  isClamped,
  className,
  message,
  lineLimit = 2,
  shouldForceInline = false,
}) => {
  return (
    <div
      className={classnames('clamped-message', isClamped && 'clamped-message--less', className)}
      style={lineLimit && isClamped ? { WebkitLineClamp: lineLimit } : {}}
    >
      <Markdown isInlineForced={shouldForceInline} markdown={message} />
    </div>
  );
};
