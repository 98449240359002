import React from 'react';
import Svg from 'svg-inline-react';
import PropTypes from 'prop-types';
import './styles.scss';

export function Item({ icon, info, data, dataNode }) {
  return (
    <div className="openings-position-item">
      <div className="openings-position-item__icon">
        <Svg src={icon} />
      </div>

      <div className="openings-position-item__wrapper">
        {info && <div className="openings-position-item__info">{info}</div>}
        {dataNode || (data && <div className="openings-position-item__data">{data}</div>)}
      </div>
    </div>
  );
}

Item.propTypes = {
  data: PropTypes.node,
  dataNode: PropTypes.node,
  icon: PropTypes.string.isRequired,
  info: PropTypes.string,
};
