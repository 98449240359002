import React, { FC } from 'react';
import classnames from 'classnames';
import FadeInImage from '@src/components/FadeInImage';
import { Container } from '@src/components/Container';
import { STATIC_MEDIA_PREFIX } from '@src/constants';
import { ContainerType } from '@src/components/Container/enums/containerType.enum';
import { InformationalBannerProps } from './props';
import './styles.scss';

export const InformationalBanner: FC<InformationalBannerProps> = ({ wrapperClassName, title, subtitle, learnMore }) => {
  return (
    <Container className={classnames('job-opening-state-banner', wrapperClassName)} type={ContainerType.INFO}>
      <div className="job-opening-state-banner__left-section">
        <h3>{title}</h3>

        <p>{subtitle}</p>

        <div className="job-opening-state-banner__learn-more" onClick={learnMore}>
          Learn more
        </div>
      </div>

      <div className="job-opening-state-banner__right-section">
        <FadeInImage
          src={`${STATIC_MEDIA_PREFIX}/images/app-banner-frame-one.png`}
          wrapperClassName="job-opening-state-banner__image"
        />
      </div>
    </Container>
  );
};
