import React, { FC, useEffect, useRef } from 'react';
import Svg from 'svg-inline-react';
import FrChevronRight from 'assets/images/fr-chevron-right.svg';
import { matchPath } from 'react-router-dom';
import { BaseAppBar } from '@src/components/PrimaryAppBar';
import { Link } from '@src/components/Link';
import FadeInImage from '@src/components/FadeInImage';
import { ARTICLE_ROUTE } from '@src/constants';
import createLink from '@src/util/createLink';
import { isArticleType } from '@src/graphql/typeGuards';
import { replaceHtmlEntities } from '@src/util/replaceHtmlEntities';
import { formatDateLong } from '@src/util/formatDateLong';
import { getScrollableContainer } from '@src/App';
import { useAppDispatch, useAppSelector } from '@src/store';
import { getScroll } from '@src/redux/selectors/scroll';
import { PageNames } from '@src/redux/selectors/scroll/enums/pageNames.enum';
import { Context } from '@src/metrics/enums/context.enum';
import { Interaction } from '@src/metrics/enums/interaction.enum';
import { trackEvent } from '@src/metrics';
import { getPreviousLocation } from '@src/redux/selectors/navigation';
import { setScroll } from '@src/redux/actions/scroll';
import { useArticles } from './hooks/useArticles';
import './styles.scss';

export const Articles: FC = () => {
  const feedContainerRef = useRef(null);
  const scrollableContainer = getScrollableContainer();
  const scrolls = useAppSelector(getScroll);
  const previousLocation = useAppSelector(getPreviousLocation);
  const dispatch = useAppDispatch();

  const { articles } = useArticles({
    scrollableContainer: scrollableContainer,
  });

  useEffect(() => {
    const scroll = scrolls?.[PageNames.ARTICLES] || 0;

    trackEvent({
      context: Context.ARTICLES,
      interaction: Interaction.VIEW,
    });

    const isPrevRouteCompany = previousLocation && matchPath(previousLocation, { path: ARTICLE_ROUTE });

    if (scrollableContainer) {
      scrollableContainer.scrollTop = isPrevRouteCompany && scroll ? scroll : 0;
    }

    return () => {
      dispatch(setScroll(PageNames.ARTICLES, scrollableContainer.scrollTop));
    };
  }, []);

  return (
    <div className="articles-view">
      <BaseAppBar isFixed>Articles</BaseAppBar>

      <div className="articles-view__content" ref={feedContainerRef}>
        {articles.filter(isArticleType).map(article => (
          <Link
            key={article.id}
            className="articles-view__item"
            to={createLink(ARTICLE_ROUTE, {
              params: { articleId: article.id },
            })}
          >
            {article.headerImage && <FadeInImage className="articles-view__image" src={article.headerImage.fullUrl} />}

            <div className="articles-view__item-bottom">
              <div className="articles-view__item-header">
                <span className="articles-view__item-title">{replaceHtmlEntities(article.title, true)}</span>

                <div className="articles-view__item-info">
                  <span className="articles-view__item-label">
                    {formatDateLong(article.publishedAt || article.createdAt)}
                  </span>
                  <div className="articles-view__item-chevron">
                    <span>Read the article</span>
                    <Svg src={FrChevronRight} />
                  </div>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};
