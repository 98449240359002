// those functions are meant to be called from native containers and not from app

import classnames from 'classnames';
import { push, goBack } from 'connected-react-router';
import api from '@src/util/api';
import { apolloClient } from '@src/graphql/apollo';
import { setDeepLinkOpened } from '@src/redux/actions/deepLink';
import { closeModal } from '@src/redux/actions/slideUpModal';
import { getContent } from '@src/redux/selectors/slideUpModal';
import { INDEX_ROUTE } from '@src/constants/routes';
import store from '@src/store';
import { getUser } from '@src/graphql/user/actions/getUser';
import {
  GetUserQuery,
  OpenPushNotificationDocument,
  OpenPushNotificationMutation,
  OpenPushNotificationMutationVariables,
  UpdateUserDocument,
  UpdateUserMutation,
  UpdateUserMutationVariables,
} from '@src/graphql/generated';
import { captureError } from '@src/util/errors';
import { setDeviceUUID } from '@src/redux/actions/deviceUUID';
import { isNativeContainer } from '@src/util/body';
import { ContainerData } from './interfaces/containerData.interface';

declare global {
  interface Window {
    Android: {
      postMessage: (params: string) => void;
    };
    __defineSetter__: (key: string, setter: (params: unknown) => void) => void;
    branchDeepLinkParams: Record<string, unknown>;
    containerMethods: Record<string, unknown>;
    onHardwareBack: () => void;
    openedPush: (pushInfo: ContainerData['pushInfo']) => void;
    pendingContainerData: ContainerData;
    registerPushToken: (pushToken: ContainerData['pushToken']) => void;
    sentContainerData: ContainerData;
    setAppVersion: (appVersion: ContainerData['appVersion']) => void;
    setDeepLinkParams: (deepLinkParams: ContainerData['deepLinkParams']) => void;
    setDetection: (deviceIdentifier: ContainerData['deviceIdentifier']) => void;
    setDevice: (device: string) => void;
    setDeviceUUID: (deviceUUID: ContainerData['deviceUUID']) => void;
    setMetaInfo: (key: string, value: Record<string, unknown>) => void;
    setPlatform: (device: ContainerData['platform']) => void;
    webkit: {
      messageHandlers: {
        callbackHandler: {
          postMessage: (params: string | Record<string, unknown>) => void;
        };
      };
    };
  }
}

window.pendingContainerData = window.pendingContainerData || {};
window.sentContainerData = window.sentContainerData || {};
window.containerMethods = {};

let isUserSynced = false;
let haveFbEventsBeenSent = false;
let shouldRefreshPushToken = true;
let canHandleDataItems = !isNativeContainer();

store.subscribe(async function () {
  const user = await getUser({
    fetchPolicy: 'cache-only',
  });

  if (user && !isUserSynced) {
    isUserSynced = true;

    await sendContainerDataItems();
  }

  if (user && user.onboardingDone) {
    await sendDeepLinkParams();
  }

  if (user && shouldRefreshPushToken) {
    if (user.pushRegistered) {
      registerForPush();
    }

    shouldRefreshPushToken = false;
  }

  if (user && !haveFbEventsBeenSent) {
    if (user.inboxProfile?.speciality?.name) {
      sendFacebookAppEvent({
        name: 'open_app',
        params: { speciality: user.inboxProfile.speciality.name },
      });
    }
  }
});

async function syncUser(): Promise<GetUserQuery['currentUser'] | undefined> {
  const user = await getUser();

  if (user) {
    return user;
  } else {
    isUserSynced = false;

    return undefined;
  }
}

async function setPendingDataItem<TKey extends keyof ContainerData>(key: TKey, value: ContainerData[TKey]) {
  window.pendingContainerData[key] = value;

  if (key === 'deviceUUID') {
    await sendContainerDataItems();
  } else if (canHandleDataItems) {
    await sendPendingDataItem(key);
  }
}

async function sendPendingDataItem(key: keyof ContainerData) {
  if (!(key in window.pendingContainerData)) {
    return;
  }

  switch (key) {
    case 'deviceUUID':
      await registerDeviceUUID();
      await sendPendingMetaItem(key);
      await sendContainerDataItems();
      break;
    case 'pushToken':
      await sendPushToken();
      break;
    case 'deepLinkParams':
      await sendDeepLinkParams();
      break;
    case 'pushInfo':
      await handleOpenedPush();
      break;
    default:
      await sendPendingMetaItem(key);
      break;
  }
}

async function sendPendingMetaItem(key: keyof ContainerData) {
  const rawValue = window.pendingContainerData[key];

  if (!key || !rawValue) {
    return;
  }

  const user = await syncUser();

  if (!user) {
    return;
  }

  const value = key === 'platform' && typeof rawValue === 'string' ? (rawValue as string).toUpperCase() : rawValue;

  await apolloClient.mutate<UpdateUserMutation, UpdateUserMutationVariables>({
    mutation: UpdateUserDocument,
    variables: {
      updateInput: {
        meta: {
          [key]: value,
        },
      },
    },
  });

  markAsSent(key);
}

function markAsSent(key: keyof ContainerData) {
  if (window.pendingContainerData[key]) {
    Object.assign(window.sentContainerData, { [key]: window.pendingContainerData[key] });
  }

  delete window.pendingContainerData[key];
}

async function sendPushToken() {
  if (!window.pendingContainerData.pushToken) {
    return;
  }

  const user = await syncUser();

  if (!user) {
    return;
  }

  await api.post('/api/app/notifications/register', window.pendingContainerData.pushToken);

  markAsSent('pushToken');

  await getUser({
    fetchPolicy: 'network-only',
  });
}

async function registerDeviceUUID() {
  if (!window.pendingContainerData.deviceUUID) {
    return;
  }

  const result = await api.post('/api/app/profile/register-device-uuid', {
    deviceUUID: window.pendingContainerData.deviceUUID,
  });

  store.dispatch(setDeviceUUID(window.pendingContainerData.deviceUUID));

  if (result?.refresh) {
    await sendContainerDataItems();

    window.location.href = `/app?deviceClass=${document.body.className}`;
  }

  canHandleDataItems = true;
}

async function handleOpenedPush() {
  if (!window.pendingContainerData.pushInfo || !window.pendingContainerData.pushInfo.id) {
    captureError(new Error(`Received invalid push info: ${JSON.stringify(window.pendingContainerData.pushInfo)}`));

    return;
  }

  const user = await syncUser();

  if (!user) {
    return;
  }

  try {
    const { data: openPushNotificationData } = await apolloClient.mutate<
      OpenPushNotificationMutation,
      OpenPushNotificationMutationVariables
    >({
      mutation: OpenPushNotificationDocument,
      variables: {
        pushNotificationId: window.pendingContainerData.pushInfo.id,
      },
    });

    markAsSent('pushInfo');

    // Timeout is important to open the notification correctly on cold-start
    // and avoid app loading overwriting the intended url from app loading
    setTimeout(function () {
      if (openPushNotificationData?.openPushNotification.url) {
        store.dispatch(push(openPushNotificationData.openPushNotification.url));
      } else {
        captureError(new Error(`Cannot open url for push notification: ${JSON.stringify(openPushNotificationData)}`));
        store.dispatch(push(INDEX_ROUTE));
      }
    }, 1000);
  } catch (error) {
    store.dispatch(push(INDEX_ROUTE));
    captureError(error);
  }
}

async function sendDeepLinkParams() {
  if (!window.pendingContainerData.deepLinkParams) {
    return;
  }

  const user = await syncUser();

  if (!user) {
    return;
  }

  const { deepLinkParams } = window.pendingContainerData;

  if (deepLinkParams.source === 'frank-app') {
    sendLegacyDeepLinkParams();
  }

  if (deepLinkParams.lookupId) {
    store.dispatch(setDeepLinkOpened(deepLinkParams.lookupId));
  }

  if (typeof deepLinkParams.redirectPath === 'string') {
    /**
     * Do not redirect user if they are already same route, also this check fixes
     * the issue when push gets stuck in loop
     */

    if (!window.location.href.includes(deepLinkParams.redirectPath)) {
      store.dispatch(push(deepLinkParams.redirectPath));
    }
  }

  if (!deepLinkParams.lookupId && !deepLinkParams.redirectPath && deepLinkParams.$custom_meta_tags) {
    const customMetaTags = parseDeepLinkParamsString(deepLinkParams.$custom_meta_tags);

    if (customMetaTags.lookupId) {
      store.dispatch(setDeepLinkOpened(customMetaTags.lookupId));
    }
  }

  // If user is not on-boarded, we keep deepLinkParams to be handled after onboarding
  if (user.onboardingDone) {
    clearDeepLinkParams();
  }
}

export function clearDeepLinkParams() {
  window.pendingContainerData.deepLinkParams = undefined;
}

async function sendLegacyDeepLinkParams() {
  const { deepLinkParams } = window.pendingContainerData;

  // Legacy deep link from hr-app to restore the user from SMS
  const result = await api.post('/api/app/deeplink', deepLinkParams);

  window.pendingContainerData.deepLinkParams = undefined;

  if (result.reloadUser) {
    window.location.reload();
  }
}

window.onHardwareBack = function () {
  const isModalOpen = !!getContent(store.getState());

  if (isModalOpen) {
    store.dispatch(closeModal());
  } else {
    store.dispatch(goBack());
  }
};

window.setDevice = function (device) {
  document.body.classList.add(device);
};

window.setPlatform = function (platform) {
  if (platform) {
    document.body.classList.add(platform);
  }

  setPendingDataItem('platform', platform);
};

window.setAppVersion = function (appVersion) {
  setPendingDataItem('appVersion', appVersion);
};

window.setDetection = function (deviceIdentifier) {
  setPendingDataItem('deviceIdentifier', deviceIdentifier);
};

window.setDeviceUUID = function (uuid) {
  store.dispatch(setDeviceUUID(uuid));
  setPendingDataItem('deviceUUID', uuid);
};

window.registerPushToken = function (pushToken) {
  setPendingDataItem('pushToken', pushToken);
};

window.setMetaInfo = function (key, value) {
  setPendingDataItem(key, value);
};

window.openedPush = function (pushInfo) {
  setPendingDataItem('pushInfo', pushInfo);
};

window.setDeepLinkParams = function (deepLinkParams) {
  if (typeof deepLinkParams === 'string') {
    deepLinkParams = parseDeepLinkParamsString(deepLinkParams);
  }

  setPendingDataItem('deepLinkParams', deepLinkParams);
};

window.__defineSetter__('branchDeepLinkParams', function (params) {
  window.setDeepLinkParams(params as ContainerData['deepLinkParams']);
});

if (window.branchDeepLinkParams) {
  window.setDeepLinkParams(window.branchDeepLinkParams);
}

function callContainerIOS(message: string | Record<string, unknown>) {
  try {
    window.webkit.messageHandlers.callbackHandler.postMessage(message);
  } catch (error) {
    // We expect the error here as it can occure when being called from an Android container
  }
}

function callContainerAndroid(message: Record<string, unknown>) {
  try {
    window.Android.postMessage(JSON.stringify(message));
  } catch (error) {
    // We expect the error here as it can occure when being called from an iOS container
  }
}

export async function sendContainerDataItems() {
  const keys = Object.keys(window.pendingContainerData).sort((keyA, keyB) => {
    if (keyA === 'deviceUUID') {
      return -1;
    }

    if (keyB === 'deviceUUID') {
      return 1;
    }

    return 0;
  });

  for (const key of keys) {
    await sendPendingDataItem(key);
  }
}

export async function resendContainerDataItems() {
  Object.assign(window.pendingContainerData, window.sentContainerData);
  await sendContainerDataItems();
}

export function sendFacebookAppEvent(event: Record<string, unknown>) {
  callContainerIOS({ event: event, type: 'appEvent' });
  callContainerAndroid({ event: event, type: 'appEvent' });

  haveFbEventsBeenSent = true;
}

// unused?
const openExternalUrl = (url: string) => {
  callContainerIOS({
    type: 'externalUrl',
    url: url,
  });
};

window.containerMethods.openExternalUrl = openExternalUrl;

export function registerForPush() {
  callContainerIOS('registerPush');
}

export function requestAppStoreReview() {
  callContainerIOS('requestReview');
}

const noTopPaddingClass = 'no-top-padding';
export function hideContainerStatusBar() {
  try {
    const bodyTag = document.body;
    bodyTag.className = classnames(bodyTag.className, noTopPaddingClass);
    window.webkit.messageHandlers.callbackHandler.postMessage({ style: 'hidden', type: 'updateContainerStatusBar' });
  } catch (error) {
    // We expect the error here as it can occure when being called from an Android container
  }
}

function showContainerStatusBar(color: string) {
  try {
    const bodyTag = document.body;
    bodyTag.className = bodyTag.className.replace(noTopPaddingClass, '');
    window.webkit.messageHandlers.callbackHandler.postMessage({
      style: color,
      type: 'updateContainerStatusBar',
    });
  } catch (error) {
    // We expect the error here as it can occure when being called from an Android container
  }
}

export function showWhiteContainerStatusBar() {
  showContainerStatusBar('white');
}

export function showBlackContainerStatusBar() {
  showContainerStatusBar('black');
}

function parseDeepLinkParamsString(deepLinkParams: string) {
  if (deepLinkParams.match(/lookupId/)) {
    const lookupIdPart = deepLinkParams.match(/"lookupId":"(.*?)"/g);
    // Temporary workaround for parsing incorrect JSON set by the old version of native container

    return JSON.parse('{' + lookupIdPart + ', "+clicked_branch_link": true}');
  }

  return JSON.parse(deepLinkParams);
}

export function setBodyBackGroundBlack() {
  document.body.classList.add('body-black');
}

export function removeBodyBackGroundBlack() {
  document.body.classList.remove('body-black');
}

export function setBodyTopBackGroundBlack() {
  document.body.classList.add('body-top-black');
}

export function removeBodyTopBackGroundBlack() {
  document.body.classList.remove('body-top-black');
}

export function setBodyTopBackGroundGrey300() {
  document.body.classList.add('body-top-grey-300');
}

export function removeBodyTopBackGroundGrey300() {
  document.body.classList.remove('body-top-grey-300');
}
