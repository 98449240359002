import React, { FC, useState } from 'react';
import { some } from 'lodash';
import { AreaType, CountryType } from '@src/graphql/generated';
import { isAreaType, isCountryType } from '@src/graphql/typeGuards';
import { CountrySearch } from './CountrySearch';
import { AreaSelection } from './AreaSelection';
import { LocationViews } from './enum/locationView.enum';
import { HandlePlaceClear } from './interfaces/handlePlaceClear.interface';
import { PlaceFiltersModalContentProps } from './props';
import './styles.scss';

export const PlaceFiltersModalContent: FC<PlaceFiltersModalContentProps> = ({
  onApplyFilters,
  defaultPlaces,
  excludedAreaIds,
  onClose,
  defaultView = LocationViews.AREA_SELECTION_VIEW,
  isSingleSelect,
  availableViews = [LocationViews.AREA_SELECTION_VIEW, LocationViews.COUNTRY_SEARCH_VIEW],
}) => {
  const [activeView, setActiveView] = useState(defaultView);

  const [activePlaces, setActivePlaces] = useState(defaultPlaces);

  const [isSpecificCountrySelected, setIsSpecificCountrySelected] = useState(
    !!defaultPlaces.filter(isCountryType).length,
  );

  const activeAreas = activePlaces.filter(isAreaType);

  const activeCountries = activePlaces.filter(isCountryType);

  const handleAreaSelect = (area: AreaType) => {
    setActivePlaces(prevPlaces => {
      if (isSingleSelect) {
        setIsSpecificCountrySelected(false);

        return [...prevPlaces.filter(isCountryType), area];
      }

      const isSelected = some(prevPlaces, { id: area.id });

      if (isSelected) {
        return prevPlaces.filter(place => place.id !== area.id);
      }

      return [...prevPlaces, area];
    });
  };

  const handlePlacesSave = (activePlaces: (AreaType | CountryType)[]) => {
    onClose();

    if (isSingleSelect && isSpecificCountrySelected) {
      return onApplyFilters(activePlaces.filter(isCountryType));
    }

    if (isSingleSelect && !isSpecificCountrySelected) {
      return onApplyFilters(activePlaces.filter(isAreaType));
    }

    return onApplyFilters(activePlaces);
  };

  const handlePlaceClear: HandlePlaceClear = typename => {
    setActivePlaces(prevPlaces => prevPlaces.filter(place => place.__typename !== typename));
  };

  const handleCountrySave = (countries: CountryType[]) => {
    setActivePlaces([...activeAreas, ...countries]);

    setIsSpecificCountrySelected(true);

    if (availableViews.includes(LocationViews.AREA_SELECTION_VIEW)) {
      setActiveView(LocationViews.AREA_SELECTION_VIEW);

      return;
    }

    handlePlacesSave(countries);

    onClose();
  };

  const handleCountriesGoBack = () => {
    if (availableViews.includes(LocationViews.AREA_SELECTION_VIEW)) {
      setActiveView(LocationViews.COUNTRY_SEARCH_VIEW);

      return;
    }

    onClose();
  };

  const renderView = () => {
    switch (activeView) {
      case LocationViews.AREA_SELECTION_VIEW:
        return (
          <AreaSelection
            activeCountries={activeCountries}
            excludedAreaIds={excludedAreaIds}
            goBack={onClose}
            isSingleSelect={!!isSingleSelect}
            isSpecificCountrySelected={isSpecificCountrySelected}
            onAreaSave={handlePlacesSave}
            onAreaSelect={handleAreaSelect}
            onAreasClear={() => {
              handlePlaceClear('AreaType');
              setIsSpecificCountrySelected(false);
            }}
            onSpecificCountrySelect={() => {
              if (isSingleSelect) {
                handlePlaceClear('AreaType');
              }

              if (!isSpecificCountrySelected && activeCountries.length) {
                return setIsSpecificCountrySelected(true);
              }

              return setActiveView(LocationViews.COUNTRY_SEARCH_VIEW);
            }}
            selectedAreas={activeAreas}
          />
        );
      case LocationViews.COUNTRY_SEARCH_VIEW:
        return (
          <CountrySearch
            goBack={() => handleCountriesGoBack()}
            onCountriesClear={() => handlePlaceClear('CountryType')}
            onCountrySave={handleCountrySave}
            selectedCountries={activeCountries}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="place-filters-modal-content">
      <div className="place-filters-modal-content__body">{renderView()}</div>
    </div>
  );
};
