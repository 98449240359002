import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Svg from 'svg-inline-react';
import { get } from 'lodash';
import VisibilitySensor from 'react-visibility-sensor';
import classnames from 'classnames';
import FrankLogoSvg from 'assets/images/logo_frank_active.svg';
import { TypingLoader } from '@src/components/TypingLoader';
import { TypingLoaderType } from '@src/components/TypingLoader/enums/TypingLoaderType.enum';
import './styles.scss';

export const FrankMessage = ({
  message,
  isFrankMessageOpen,
  setFrankMessageState,
  wrapperClassName,
  messageHeight,
  setFrankMessageHeight,
  isDarkMode,
}) => {
  const [loading, setLoading] = useState(false);
  const messageRef = useRef(null);
  const isMessageOpen = useRef(isFrankMessageOpen);
  const messageHeightAfterSet = get(messageRef, 'current.clientHeight', 0);
  const messageFullHeight = Math.max(messageHeightAfterSet, messageHeight);

  useEffect(() => {
    let loaderTimer;

    if (message && loading && !isMessageOpen.current) {
      loaderTimer = setTimeout(() => {
        setFrankMessageState(true);
        isMessageOpen.current = true;
        setFrankMessageHeight(get(messageRef, 'current.clientHeight', 0));
        setLoading(false);
      }, 500);
    }

    return () => {
      if (loaderTimer) {
        clearTimeout(loaderTimer);
      }
    };
  }, [message, loading]);

  const isOpen = !loading && isMessageOpen.current;

  return (
    <VisibilitySensor
      onChange={isVisible => {
        if (isVisible) {
          if (!isMessageOpen.current) {
            setLoading(true);
          }
        }
      }}
    >
      <div className={classnames('frank-message', wrapperClassName, isDarkMode && 'frank-message--dark-mode')}>
        <Svg className="frank-message__logo" src={FrankLogoSvg} />
        <div
          // paddingTop + paddingBottom = 30
          className={classnames('frank-message__content-wrapper', isOpen && 'frank-message__content-wrapper--active')}
          style={isOpen ? { maxHeight: messageFullHeight + 30, width: '100%' } : {}}
        >
          {loading && (
            <TypingLoader
              className="frank-message__typing-wrapper"
              itemClassName="frank-message__typing-dot"
              type={TypingLoaderType.LIGHT}
            />
          )}

          <p
            className={classnames('frank-message__text', isOpen && 'frank-message__text--active')}
            dangerouslySetInnerHTML={{ __html: message }}
            ref={messageRef}
          />
        </div>
      </div>
    </VisibilitySensor>
  );
};

FrankMessage.propTypes = {
  isDarkMode: PropTypes.bool,
  isFrankMessageOpen: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  messageHeight: PropTypes.number.isRequired,
  setFrankMessageHeight: PropTypes.func.isRequired,
  setFrankMessageState: PropTypes.func.isRequired,
  wrapperClassName: PropTypes.string,
};
