import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ArrowSvg from 'assets/images/fr-chevron-left.svg';
import CloseSvg from 'assets/images/fr-close.svg';
import Svg from 'svg-inline-react';
import { connect } from 'react-redux';
import { goBackAndPreventLeave } from '@src/redux/actions/navigation';
import './styles.scss';

@connect(undefined, { goBackAndPreventLeave })
export default class Back extends PureComponent {
  static propTypes = {
    backButtonSvg: PropTypes.string,
    cancelLabel: PropTypes.string,
    className: PropTypes.string,
    close: PropTypes.bool,
    disabled: PropTypes.bool,
    goBackAndPreventLeave: PropTypes.func.isRequired,
    onBack: PropTypes.func,
    showWithAnimation: PropTypes.bool,
  };

  static defaultProps = {
    showWithAnimation: true,
  };

  render() {
    const { className, close, disabled, onBack, goBackAndPreventLeave, showWithAnimation, cancelLabel, backButtonSvg } =
      this.props;

    return (
      <div className={classnames('back', showWithAnimation && 'back--fade-in', className)}>
        {cancelLabel ? (
          <span className="back__label" onClick={!disabled ? onBack || goBackAndPreventLeave : undefined}>
            {cancelLabel}
          </span>
        ) : (
          <Svg
            onClick={!disabled ? onBack || goBackAndPreventLeave : undefined}
            src={backButtonSvg || (close ? CloseSvg : ArrowSvg)}
          />
        )}
      </div>
    );
  }
}
