import React, { FC } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { CompanyThumbnailCard, CompanyThumbnailCardSkeleton } from '@src/components/CompanyThumbnailCard';
import { HorizontalScroller } from '@src/components/HorizontalScroller';
import { ACTIVE_COMPANIES_ROUTE, COMPANY_ROUTE } from '@src/constants/routes';
import createLink from '@src/util/createLink';
import {
  GetRecentlySeenCompanyImagesQuery,
  UserVerificationStateEnum,
  useGetRecentlySeenCompanyImagesQuery,
} from '@src/graphql/generated';
import { relativeTimeWithShortenedNames } from '@src/util/relativeTime';
import { trackEvent } from '@src/metrics';
import { Context } from '@src/metrics/enums/context.enum';
import { Interaction } from '@src/metrics/enums/interaction.enum';
import { ItemType } from '@src/metrics/enums/itemType.enum';
import { RecentCompaniesProps } from './props';
import './styles.scss';

export const RecentCompanies: FC<RecentCompaniesProps> = ({ userVerificationState }) => {
  const history = useHistory();

  const { data: recentCompaniesData, loading: isRecentCompaniesLoading } = useGetRecentlySeenCompanyImagesQuery({
    variables: {
      paginationOptions: {
        limit: 5,
      },
    },
  });

  const handleCardClick = (company: GetRecentlySeenCompanyImagesQuery['recentlySeenCompanies']['result'][0]): void => {
    if (!company) {
      return;
    }

    history.push(
      createLink({
        link: COMPANY_ROUTE,
        params: { companyId: company.id },
      }),
    );

    trackEvent({
      context: Context.HOME,
      interaction: Interaction.CLICK_COMPANY_PROFILE,
      itemType: ItemType.COMPANY,
      itemValue: company.profile.name,
    });
  };

  const companies = recentCompaniesData?.recentlySeenCompanies?.result || [];
  const isUserVerificationEnabled = !!(
    userVerificationState &&
    [UserVerificationStateEnum.PENDING, UserVerificationStateEnum.PUBLISHED].includes(userVerificationState)
  );

  return (
    <div className="recent-companies-section">
      <div className="recent-companies-section__top">
        <div className="recent-companies-section__title">Who’s hiring on MeetFrank</div>

        <div className="recent-companies-section__top-subtitle">
          {isUserVerificationEnabled ? (
            'Companies who are hiring on MeetFrank and have been active recently.'
          ) : (
            <>
              <span>Make yourself visible</span> and get seen by top companies on the platform.
            </>
          )}
        </div>
      </div>

      <HorizontalScroller className="recent-companies-section__scroller">
        {!isRecentCompaniesLoading &&
          companies.map(company => {
            if (!company) {
              return null;
            }

            const bgImageUrl = company.profile.officeImages[0]?.fullUrl || company.profile.headerImage.fullUrl;
            const activeLabel = relativeTimeWithShortenedNames(company.lastSeen);

            return (
              <CompanyThumbnailCard
                bgImageUrl={bgImageUrl}
                key={company.id}
                logoUrl={company.profile.logo?.fullUrl}
                onCardClick={() => handleCardClick(company)}
                subTitle={
                  <div className="recent-companies-section__subtitle">
                    <div className="recent-companies-section__ball" />

                    <div className="recent-companies-section__last-activity-label">{activeLabel}</div>
                  </div>
                }
                title={company.profile.name}
                wrapperClassName="recent-companies-section__card"
              />
            );
          })}

        {isRecentCompaniesLoading && <CompanyThumbnailCardSkeleton wrapperClassName="recent-companies-section__card" />}
      </HorizontalScroller>

      <div className="recent-companies-section__footer">
        <Link
          className="recent-companies-section__link"
          onClick={() => {
            trackEvent({
              context: Context.HOME,
              interaction: Interaction.CLICK_VIEW_ALL_COMPANIES,
            });
          }}
          to={ACTIVE_COMPANIES_ROUTE}
        >
          View all
        </Link>
      </div>
    </div>
  );
};
