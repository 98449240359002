import React, { FC, useEffect, useMemo } from 'react';
import { find, get } from 'lodash';
import qs from 'qs';
import { connect } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';
import { SubPageAppBar } from '@src/components/PrimaryAppBar';
import { PageTitleWithOgTags } from '@src/components/PageTitleWithOgTags';
import { JobOpeningFeedItem } from '@src/containers/Feed/JobOpeningFeedItem';
import { FeedItemSkeleton } from '@src/containers/Feed/Skeleton';
import { JOB_OPENING_ROUTE } from '@src/constants/routes';
import { setScroll } from '@src/redux/actions/scroll';
import { getScroll } from '@src/redux/selectors/scroll';
import { getPreviousLocation } from '@src/redux/selectors/navigation';
import { getWrapperScrollableContainer } from '@src/App';
import { setNavigationVisibility, goBackAndPreventLeave } from '@src/redux/actions/navigation';
import { trackEvent } from '@src/metrics';
import { SearchOpeningEntityTagInput, SearchOpeningEntityTagNameEnum } from '@src/graphql/generated';
import { RootState } from '@src/store/interfaces/store.interface';
import { isOpeningType } from '@src/graphql/typeGuards';
import { Context } from '@src/metrics/enums/context.enum';
import { Interaction } from '@src/metrics/enums/interaction.enum';
import { getSearchTags, getFilters, SEARCH_TAGS_FOR } from './utils';
import { useSpaceFilledOpenings } from './useSpaceFilledOpenings';
import { PrepareSearchTags } from './interface/prepareSearchTags.interface';
import { FilledProps } from './props';
import './styles.scss';

const PAGE_TITLE = 'Filled';

const prepareSearchTags: PrepareSearchTags = (spaceId: string, filters) => {
  return spaceId
    ? [...getSearchTags(filters, SEARCH_TAGS_FOR.allOffersFeed), { tag: 'GROUP', value: spaceId }]
    : getSearchTags(filters, SEARCH_TAGS_FOR.allOffersFeed);
};

const SCROLL_KEY = 'filled';

const Filled: FC<FilledProps> = ({
  goBackAndPreventLeave,
  setScroll,
  scroll,
  setNavigationVisibility,
  previousLocation,
}) => {
  const location = useLocation();

  const filters = getFilters(location);

  const queryParams = useMemo(() => qs.parse(location.search, { ignoreQueryPrefix: true }), [location.search]);

  const spaceId = get(queryParams, 'spaceId');
  const searchPattern = get(queryParams, 'searchPattern') as string | undefined;
  const passedSearchTags = (get(queryParams, 'searchTags') as SearchOpeningEntityTagInput[]) || [];
  const passedSearchTagsWithOrCondition =
    (get(queryParams, 'searchTagsWithOrCondition') as SearchOpeningEntityTagInput[]) || [];

  const searchTags: SearchOpeningEntityTagInput[] = useMemo(
    () => [
      ...prepareSearchTags(spaceId, filters),
      ...passedSearchTags,
      { tag: SearchOpeningEntityTagNameEnum.STATE, value: 'inactive' },
    ],
    [queryParams],
  );

  const getScrollableContainer = () => {
    const wrapperScrollableContainer = getWrapperScrollableContainer();

    return wrapperScrollableContainer[0];
  };

  const { isLoading, isRefetchLoading, feed } = useSpaceFilledOpenings({
    getScrollableContainer: getScrollableContainer,
    searchPattern: searchPattern,
    searchTags: searchTags,
    searchTagsWithOrCondition: passedSearchTagsWithOrCondition,
  });

  useEffect(() => {
    const scrollableContainer = getScrollableContainer();

    trackEvent({
      context: Context.FILLED,
      interaction: Interaction.VIEW,
    });

    setNavigationVisibility(false);

    const isPrevRouteOpening =
      previousLocation && find([JOB_OPENING_ROUTE], route => matchPath(previousLocation, { path: route }));

    if (scrollableContainer) {
      scrollableContainer.scrollTop = isPrevRouteOpening && scroll ? scroll : 0;
    }

    return () => {
      setNavigationVisibility(true);

      setScroll(SCROLL_KEY, scrollableContainer.scrollTop);
    };
  }, []);

  return (
    <div className="filled fade-in">
      <PageTitleWithOgTags message="Find out which job offers have been recently filled." title={PAGE_TITLE} />

      <SubPageAppBar isFixed onBack={goBackAndPreventLeave}>
        Filled
      </SubPageAppBar>

      <div className="feed-bg-color">
        {!isRefetchLoading &&
          feed.filter(isOpeningType).map(opening => {
            return <JobOpeningFeedItem isMatchShown key={opening.id} metricsContext="Filled" opening={opening} />;
          })}

        {isLoading && <FeedItemSkeleton />}
      </div>
    </div>
  );
};

export default connect(
  (state: RootState) => ({
    previousLocation: getPreviousLocation(state),
    scroll: getScroll(state)[SCROLL_KEY],
  }),
  {
    goBackAndPreventLeave,
    setNavigationVisibility,
    setScroll,
  },
)(Filled);
