import React, { FC } from 'react';
import classnames from 'classnames';
import FrankLogoNewSvg from 'assets/images/frank_logo_new.svg';
import Svg from 'svg-inline-react';
import { FrankAvatarProps } from './props';
import './styles.scss';

export const FrankAvatar: FC<FrankAvatarProps> = ({ placeholder, className, setRef }) => {
  return (
    <div className={classnames('avatar-column', className, placeholder && 'avatar-column--placeholder')} ref={setRef}>
      <Svg className="avatar" src={FrankLogoNewSvg} />
    </div>
  );
};
