import React from 'react';
import PropTypes from 'prop-types';
import Svg from 'svg-inline-react';
import ArrowSvg from 'assets/images/fr-chevron-left.svg';
import SearchSvg from 'assets/images/fr-search.svg';
import './styles.scss';

export function SearchItem({ onClick }) {
  return (
    <div className="no-result-search-item" onClick={onClick}>
      <div className="no-result-search-item__title">
        <Svg src={SearchSvg} />
        Search again
      </div>

      <Svg src={ArrowSvg} />
    </div>
  );
}

SearchItem.propTypes = {
  onClick: PropTypes.func.isRequired,
};
