import React, { FC } from 'react';
import classnames from 'classnames';
import { Label } from '@src/components/Label';
import { HeaderLabelProps } from './props';
import './styles.scss';

export const HeaderLabel: FC<HeaderLabelProps> = ({ className, children, onClick, isActive }) => {
  return (
    <Label
      className={classnames('header-label', className, isActive && 'header-label--active')}
      onClick={() => onClick()}
      text={<>{children}</>}
    />
  );
};
