import React from 'react';
import { map, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import Labels from '@src/components/Labels';
import { AboutTitle } from '../AboutTitle';

export const Benefits = ({ benefits }) => {
  if (isEmpty(benefits)) {
    return null;
  }

  return (
    <div className="company-profile-benefits">
      <AboutTitle>Perks and benefits</AboutTitle>

      <Labels labels={map(benefits, 'value')} />
    </div>
  );
};

Benefits.propTypes = {
  benefits: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
    }).isRequired,
  ),
};
