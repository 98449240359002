import { identity, get } from 'lodash';
import { useState, useCallback } from 'react';
import { LocalStorageKeys } from '@src/localStorage/enums/localStorageKeys.enum';
import { Options } from './interfaces/option.interface';

export const useLocalStorage = <T>(
  key: LocalStorageKeys,
  initialValue?: T,
  options?: Options,
): [storedValue: T | undefined, setItem: (item: T) => void, removeItem: () => void] => {
  if (!key) {
    throw new Error('useLocalStorage key may not be falsy!');
  }

  const serialize = get(options, 'raw') ? String : JSON.stringify;
  const deserialize = get(options, 'raw') ? identity : JSON.parse;

  const [storedValue, setStoredValue] = useState<T | undefined>(() => {
    try {
      const localStorageValue = window.localStorage.getItem(key);

      // Restore value if it was stored previously
      if (localStorageValue !== null) {
        return deserialize(localStorageValue);
      }

      // Set a new value
      window.localStorage.setItem(key, serialize(initialValue));

      return initialValue;
    } catch (_error) {
      // If user is in private mode or has storage restriction
      // localStorage can throw. JSON.parse and JSON.stringify
      // can throw, too.
      return initialValue;
    }
  });

  const setItem = (item: T): void => {
    try {
      const valueToStore = item instanceof Function ? item(storedValue) : item;

      setStoredValue(valueToStore);

      window.localStorage.setItem(key, serialize(item));
    } catch (_error) {
      // If user is in private mode or has storage restriction localStorage can throw
    }
  };

  const removeItem = useCallback((): void => {
    try {
      window.localStorage.removeItem(key);
      setStoredValue(undefined);
    } catch (_error) {
      // If user is in private mode or has storage restriction localStorage can throw
    }
  }, [key, setStoredValue]);

  return [storedValue, setItem, removeItem];
};
