export enum ApolloErrorCode {
  BAD_REQUEST = 'BAD_REQUEST',
  BAD_USER_INPUT = 'BAD_USER_INPUT',
  DATA_NOT_POPULATED = 'DATA_NOT_POPULATED',
  FORBIDDEN_RESOURCE = 'FORBIDDEN_RESOURCE',
  GRAPHQL_PARSE_FAILED = 'GRAPHQL_PARSE_FAILED',
  GRAPHQL_VALIDATION_FAILED = 'GRAPHQL_VALIDATION_FAILED',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  OPERATION_RESOLUTION_FAILURE = 'OPERATION_RESOLUTION_FAILURE',
  PERSISTED_QUERY_NOT_FOUND = 'PERSISTED_QUERY_NOT_FOUND',
  PERSISTED_QUERY_NOT_SUPPORTED = 'PERSISTED_QUERY_NOT_SUPPORTED',
  RESOURCE_ALREADY_EXISTS = 'RESOURCE_ALREADY_EXISTS',
  RESOURCE_NOT_FOUND = 'RESOURCE_NOT_FOUND',
  UNAUTHENTICATED = 'UNAUTHENTICATED',
}
