import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './styles.scss';

export const Facts = ({ funding, founded, revenue, companySize }) => {
  if (!funding && !founded && !revenue && !companySize) {
    return null;
  }

  return (
    <div className="company-profile-facts">
      <div className="company-profile-facts__items-wrapper">
        <div className="company-profile-facts__items">
          {companySize && (
            <div className="company-profile-facts__items-item company-profile-facts__items-item--primary">
              <div className="company-profile-facts__item-aside">There are</div>
              <div className="company-profile-facts__item-important">{companySize.name}</div>
              <div className="company-profile-facts__item-aside">employees in the company</div>
            </div>
          )}

          {revenue && revenue.id && revenue.value && (
            <div className="company-profile-facts__items-item company-profile-facts__items-item--secondary">
              <div className="company-profile-facts__item-important">{revenue.value}</div>
              <div className="company-profile-facts__item-aside">annual revenue</div>
            </div>
          )}

          {funding && funding.id && funding.value && (
            <div
              className={classNames('company-profile-facts__items-item', {
                'company-profile-facts__items-item--funding-small': funding.value.length > 15,
              })}
            >
              <div className="company-profile-facts__item-important">{funding.value}</div>
              <div className="company-profile-facts__item-aside">Raised money</div>
            </div>
          )}

          {founded && (
            <div className="company-profile-facts__items-item">
              <div className="company-profile-facts__item-important">{founded}</div>
              <div className="company-profile-facts__item-aside">Company was founded in</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Facts.propTypes = {
  companySize: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  founded: PropTypes.number,
  funding: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
  revenue: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
};
