import { useEffect } from 'react';
import { CombinedSearchTagNameEnum, useGetTopReviewedCompaniesSearchLazyQuery } from '@src/graphql/generated';
import { ALL_PLACE_ID } from '@src/constants/placeIds';
import {
  UseCompanyBlocksSearchParams,
  UseCompanyBlocksSearchValue,
} from './interfaces/useCompanyBlocksSearch.interface';

export const useCompanyBlocksSearch = ({
  searchPattern,
  activeCountryId,
}: UseCompanyBlocksSearchParams): UseCompanyBlocksSearchValue => {
  const shouldDisplaySearchResults = !!searchPattern && searchPattern?.length > 1;

  const [searchCompanies, { data: searchedCompaniesData, loading: isSearchedCompaniesLoading }] =
    useGetTopReviewedCompaniesSearchLazyQuery();

  const searchedCompanies = searchedCompaniesData?.legacySearch?.result.map(item => item.entity) || [];

  useEffect(() => {
    if (searchPattern && shouldDisplaySearchResults) {
      searchCompanies({
        variables: {
          paginationOptions: {
            limit: 30,
          },
          searchPatterns: [
            {
              pattern: searchPattern,
              tag: CombinedSearchTagNameEnum.COMPANY_NAME,
            },
          ],
          searchTags: [
            {
              tag: CombinedSearchTagNameEnum.TYPE,
              value: 'COMPANY',
            },
            ...(activeCountryId && activeCountryId !== ALL_PLACE_ID
              ? [
                  {
                    tag: CombinedSearchTagNameEnum.COMPANY_COUNTRY,
                    value: activeCountryId,
                  },
                ]
              : []),
          ],
        },
      });
    }
  }, [searchPattern, shouldDisplaySearchResults]);

  return {
    isSearchedCompaniesLoading: isSearchedCompaniesLoading,
    searchedCompanies: searchedCompanies,
    shouldDisplaySearchResults: shouldDisplaySearchResults,
  };
};
