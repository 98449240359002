import React from 'react';
import PropTypes from 'prop-types';
import { toExternalUrl } from '@src/util/url';
import './styles.scss';

export const MarkdownLink = ({ key, href, children }) => {
  return (
    <a
      className="markdown-link"
      href={toExternalUrl(href)}
      key={key}
      target={href?.includes(`${window.location.origin}/app`) ? '_self' : '_blank'}
    >
      {children}
    </a>
  );
};

MarkdownLink.propTypes = {
  children: PropTypes.array.isRequired,
  href: PropTypes.string.isRequired,
  key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
