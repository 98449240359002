export enum ValuationRound {
  ACQUISITION = 'acquisition',
  ANGEL = 'angel round',
  BUYOUT = 'buyout',
  CONVERTIBLE = 'convertible note',
  CORPORATE_SPINOUT = 'corporate spinout',
  DEBT = 'debt financing',
  EARLY_VC = 'early VC round',
  GRANT = 'grant',
  GROWTH_EQUITY_NON_VC = 'growth equity',
  GROWTH_EQUITY_VC = 'growth equity',
  ICO = 'ICO',
  IPO = 'IPO',
  LATE_VC = 'late VC round',
  LENDING_CAPITAL = 'lending capital',
  MEDIA_FOR_EQUITY = 'media for equity',
  MERGER = 'merger',
  POST_IPO_CONVERTIBLE = 'post-IPO convertible note',
  POST_IPO_DEBT = 'post-IPO debt',
  POST_IPO_EQUITY = 'post-IPO equity',
  POST_IPO_SECONDARY = 'post-IPO secondary',
  PRIVATE_PLACEMENT_NON_VC = 'private placement',
  PRIVATE_PLACEMENT_VC = 'private placement',
  PROJECT_AND_REAL_ESTATE_AND_INFRASTRUCTURE_FINANCE = 'grant',
  SECONDARY = 'secondary market transaction',
  SEED = 'Seed',
  SERIES_A = 'Series A',
  SERIES_B = 'Series B',
  SERIES_C = 'Series C',
  SERIES_D = 'Series D',
  SERIES_E = 'Series E',
  SERIES_F = 'Series F',
  SERIES_G = 'Series G',
  SERIES_H = 'Series H',
  SERIES_I = 'Series I',
  SPAC_IPO = 'SPAC merger',
  SPAC_PRIVATE_PLACEMENT = 'SPAC private placement',
  SPINOUT = 'spinout',
  SUPPORT_PROGRAM = 'support program funding',
}
