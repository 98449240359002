import React, { FC } from 'react';
import classNames from 'classnames';
import Svg from 'svg-inline-react';
import { FilterItemProps } from './props';
import './styles.scss';

export const FilterItem: FC<FilterItemProps> = ({ children, onClick, startIcon, className, isActive }) => {
  return (
    <div
      className={classNames('jobs-search-filter-item', { 'jobs-search-filter-item--active': isActive }, className)}
      onClick={onClick}
    >
      <Svg className="jobs-search-filter-item__icon" src={startIcon} />

      <div className="jobs-search-filter-item__container">{children}</div>
    </div>
  );
};
