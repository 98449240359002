import React, { FC } from 'react';
import classnames from 'classnames';
import { Link } from '@src/components/Link';
import { ExternalLink } from '../ExternalLink';
import { ButtonProps } from './props';
import './styles.scss';

export const Button: FC<ButtonProps> = ({ isLink, isExternalLink, className, children, ...props }) => {
  return React.createElement(
    isLink ? Link : isExternalLink ? ExternalLink : 'button',
    {
      className: classnames('fr-button', className),
      ...props,
    },
    children,
  );
};
