import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { truncate } from 'lodash';
import { HorizontalListBlock } from '@src/components/HorizontalListBlock';
import createLink from '@src/util/createLink';
import { COMPANY_ROUTE, JOB_OPENING_ROUTE } from '@src/constants';
import { ImageSwiper } from '@src/containers/ImageSwiper';
import { SwiperImage } from '@src/containers/ImageSwiper/interfaces/swiperImage.interface';
import { hasFields } from '@src/util/object/helpers';
import {
  ExternalUrlOpeningCandidateDestinationType,
  LocationType,
  MatchIntroStateEnum,
  OpeningCandidateDestinationTypeEnum,
} from '@src/graphql/generated';
import { toExternalUrl } from '@src/util/url';
import { Context } from '@src/metrics/enums/context.enum';
import { trackEvent } from '@src/metrics';
import { Interaction } from '@src/metrics/enums/interaction.enum';
import { useAppDispatch } from '@src/store';
import { startApply } from '@src/redux/actions/openings';
import { getLocations } from '@src/util/getLocations';
import { ClampedMessage } from '@src/components/ClampedMessage';
import { ItemType } from '@src/metrics/enums/itemType.enum';
import { MessageBubble } from '../MessageBubble';
import { MessageBubbleOpeningProps } from './props';
import './styles.scss';

export const MessageBubbleOpening: FC<MessageBubbleOpeningProps> = ({ opening, type }) => {
  const history = useHistory();

  const dispatch = useAppDispatch();

  const logo = opening.company.profile?.logo?.fullUrl;
  const headerImage = opening.company.profile?.headerImage;
  const officeImages = opening.company.profile?.officeImages;
  const images = officeImages?.length ? officeImages : (headerImage && [headerImage]) || [];
  const matchIntroState = opening.match?.introState;
  const isExternalApply = opening.candidateDestination.type === OpeningCandidateDestinationTypeEnum.EXTERNAL_URL;

  const positionLocations = opening?.positionLocations;
  const filteredPositionLocations = positionLocations?.filter(
    (location): location is Pick<LocationType, 'shortName' | 'id'> => !!location,
  );

  const locationShortNames =
    opening?.remote?.type &&
    filteredPositionLocations &&
    getLocations(opening?.remote?.type, filteredPositionLocations);

  const location = truncate(locationShortNames, {
    length: 25,
    omission: '...',
  });

  const filteredImages: SwiperImage[] = images
    .map(image => {
      return {
        id: image?.id,
        src: image?.fullUrl,
      };
    })
    .filter((image): image is SwiperImage => {
      return !!image.id && !!image.src;
    });

  const externalUrl = hasFields<ExternalUrlOpeningCandidateDestinationType>(opening.candidateDestination, ['url'])
    ? toExternalUrl(opening.candidateDestination.url)
    : null;

  const label = matchIntroState === MatchIntroStateEnum.CHAT ? 'Chat' : 'Apply';

  const viewOpening = () => {
    history.push(
      createLink({
        link: JOB_OPENING_ROUTE,
        params: {
          openingId: opening.id,
        },
      }),
    );
    trackEvent({
      context: Context.CHAT,
      interaction: Interaction.VIEW_JOB_OPENING_DETAILS,
      itemType: ItemType.OPENING,
      itemValue: opening.id,
    });
  };

  const metricEvent = () => {
    trackEvent({
      context: Context.CHAT,
      interaction: Interaction.CLICK_APPLY,
      itemType: ItemType.OPENING,
      itemValue: opening.id,
    });
  };

  const onApply = () => {
    dispatch(startApply(opening.id, opening?.hiringState, opening.candidateDestination, matchIntroState, Context.CHAT));
    metricEvent();
  };

  return (
    <MessageBubble className="inbox-message-bubble-opening" type={type}>
      <>
        <div className="inbox-message-bubble-opening__content">
          <HorizontalListBlock
            to={createLink(COMPANY_ROUTE, { params: { companyId: opening.company.id } })}
            description={location}
            name={opening.company.profile?.name}
            imageSrc={logo}
            wrapperClassName="inbox-message-bubble-opening__company"
          />

          <div className="inbox-message-bubble-opening__title" onClick={() => viewOpening()}>
            {opening.title}
          </div>

          <div className="inbox-message-bubble-opening__read-more-wrapper" onClick={() => viewOpening()}>
            <ClampedMessage isClamped={true} lineLimit={4} message={opening.message.substring(0, 200) + '…'} />
          </div>
        </div>

        {!!filteredImages.length && (
          <ImageSwiper images={filteredImages} imageClassName="inbox-message-bubble-opening__image" />
        )}

        <div className="inbox-message-bubble-opening__buttons">
          <div className="inbox-message-bubble-opening__button" onClick={() => viewOpening()}>
            View details
          </div>

          {isExternalApply && externalUrl ? (
            <a
              className="inbox-message-bubble-opening__button inbox-message-bubble-opening__button--apply"
              href={externalUrl}
              onClick={() => metricEvent()}
              target="_blank"
              rel="noreferrer"
            >
              {label}
            </a>
          ) : (
            <div
              className="inbox-message-bubble-opening__button inbox-message-bubble-opening__button--apply"
              onClick={() => onApply()}
            >
              {label}
            </div>
          )}
        </div>
      </>
    </MessageBubble>
  );
};
