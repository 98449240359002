import React, { FC } from 'react';
import { range } from 'lodash';
import classnames from 'classnames';
import { FeedItemSkeletonProps } from './props';
import './styles.scss';

export const FeedItemSkeleton: FC<FeedItemSkeletonProps> = ({ amount = 1, wrapperClassName }) => {
  return (
    <>
      {range(amount).map(it => (
        <div className={classnames('feed-item-skeleton', wrapperClassName)} key={it}>
          <div className="feed-item-skeleton__header">
            <div className="feed-item-skeleton__avatar pulse-fade" />
            <div className="feed-item-skeleton__user-lines">
              <div className="feed-item-skeleton__line pulse-fade" />
              <div className="feed-item-skeleton__line pulse-fade" />
            </div>
          </div>
          <div className="feed-item-skeleton__content">
            <div className="feed-item-skeleton__line pulse-fade" />
            <div className="feed-item-skeleton__line pulse-fade" />
            <div className="feed-item-skeleton__line pulse-fade" />
          </div>
        </div>
      ))}
    </>
  );
};
