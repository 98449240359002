import React, { FC } from 'react';
import FrCloseSvg from 'assets/images/fr-close.svg';
import Svg from 'svg-inline-react';
import { useLocalStorage } from '@src/localStorage/hooks/useLocalStorage';
import { LocalStorageKeys } from '@src/localStorage/enums/localStorageKeys.enum';
import { REGISTER_EMPLOYER_URL } from '@src/constants';
import { trackEvent } from '@src/metrics';
import { Context } from '@src/metrics/enums/context.enum';
import { Interaction } from '@src/metrics/enums/interaction.enum';
import { useGetUserQuery } from '@src/graphql/generated';
import { EXECUTIVE_ID, LEAD_ID, SENIOR_ID } from '@src/constants/seniorities';
import { HR_ID } from '@src/constants/specialities';
import './styles.scss';

const ALLOWED_SENIORITIES: Record<string, string> = {
  [EXECUTIVE_ID]: EXECUTIVE_ID,
  [LEAD_ID]: LEAD_ID,
  [SENIOR_ID]: SENIOR_ID,
};

export const RecruiterPromo: FC = () => {
  const [hasSeenRecruiterPromo, setHasSeenRecruiterPromo] = useLocalStorage<boolean>(
    LocalStorageKeys.HAS_SEEN_RECRUITER_PROMO,
    false,
  );

  const { data: userData } = useGetUserQuery();

  const userInboxProfile = userData?.currentUser.inboxProfile;

  const isAllowedSeniority = userInboxProfile?.seniorities?.some(seniority => {
    return seniority?.id && ALLOWED_SENIORITIES[seniority.id];
  });

  const isHrUserSpeciality = userInboxProfile?.speciality?.id === HR_ID;

  if (hasSeenRecruiterPromo || (!isAllowedSeniority && !isHrUserSpeciality)) {
    return null;
  }

  return (
    <div className="frank-recruiter-promo">
      <div className="frank-recruiter-promo__title">Are you hiring?</div>

      <a
        className="frank-recruiter-promo__subtitle"
        href={`${REGISTER_EMPLOYER_URL}?promo=cm-recruiter-signup-banner`}
        onClick={() =>
          trackEvent({
            context: Context.RECRUITER_SIGNUP_BANNER,
            interaction: Interaction.OPEN_REGISTER_AS_RECRUITER,
          })
        }
        target="_"
      >
        Try MeetFrank for Business
      </a>

      <div
        className="frank-recruiter-promo__icon-wrapper"
        onClick={() => {
          setHasSeenRecruiterPromo(true);

          trackEvent({
            context: Context.RECRUITER_SIGNUP_BANNER,
            interaction: Interaction.CLOSE_BANNER,
          });
        }}
      >
        <Svg className="frank-recruiter-promo__close" src={FrCloseSvg} />
      </div>
    </div>
  );
};
