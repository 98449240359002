import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import qs from 'qs';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import { find } from 'lodash';
import { PageTitleWithOgTags } from '@src/components/PageTitleWithOgTags';
import { getScroll } from '@src/redux/selectors/scroll';
import { getPreviousLocation } from '@src/redux/selectors/navigation';
import { getContent } from '@src/redux/selectors/slideUpModal';
import createLink from '@src/util/createLink';
import { getWrapperScrollableContainer } from '@src/App';
import { SEARCH_TAGS } from '@src/constants';
import { transformItemsToSearchTags } from '@src/util/filters';
import { SearchOpeningEntityTagInput, SearchOpeningEntityTagNameEnum } from '@src/graphql/generated';
import { TransformItemsToSearchTagsArgumentItem } from '@src/util/filters/interface/transformItemsToSearchTags.interface';
import {
  COMPANY_ROUTE,
  COMPANY_ABOUT_ROUTE,
  JOB_OPENING_ROUTE,
  COMPANY_REVIEWS_ROUTE,
  CREATE_COMPANY_REVIEW_ROUTE,
  SEARCH_ROUTE,
} from '@src/constants/routes';
import { trackEvent } from '@src/metrics';
import { getWorkOptionsFromSearchTags } from '@src/views/Search/utils';
import { getPreFilledSearchTags } from '@src/util/getSearchTags';
import { useAppDispatch, useAppSelector } from '@src/store';
import { setScroll } from '@src/redux/actions/scroll';
import { Context } from '@src/metrics/enums/context.enum';
import { Interaction } from '@src/metrics/enums/interaction.enum';
import { SearchResultFilters } from './enums/filters';
import { SEARCH_RESULT_FILTERS, SEARCH_RESULT_FILTERS_KEYS } from './Filters';
import { Header } from './Header';
import { LatestOpeningsFeed } from './LatestOpeningsFeed';
import { CategoryFeaturedOffers } from './CategoryFeaturedOffers';
import { OffersCount } from './OffersCount';
import { useFeed } from './hooks/useFeed';
import { SearchResultFilter } from './Filters/interfaces/searchResultFilter.interface';
import './styles.scss';

const SCROLL_KEY = 'searchResults';

export const SearchResult: FC = () => {
  const dispatch = useAppDispatch();

  const modalContent = useAppSelector(getContent);

  const location = useLocation();

  const history = useHistory();

  const previousLocation = useAppSelector(getPreviousLocation);

  const scrollState = useAppSelector(getScroll);

  const isModalOpen = !!modalContent;
  const scroll = scrollState[SCROLL_KEY];

  const feedContainerEl = useRef(null);
  const isMounted = useRef(false);
  const [activeFilter, setActiveFilter] = useState<SearchResultFilter | null>(null);
  const queryParams = useMemo(() => qs.parse(location.search, { ignoreQueryPrefix: true }), [location.search]);
  const searchPattern = queryParams?.searchPattern as string | undefined;
  const searchTags: SearchOpeningEntityTagInput[] = (queryParams?.searchTags as SearchOpeningEntityTagInput[]) || [];
  const searchTagsParams = getPreFilledSearchTags(searchTags);

  const workOptions = getWorkOptionsFromSearchTags(searchTags);
  const headerTitle = searchPattern || '';

  const getScrollableContainer = () => {
    const wrapperScrollableContainer = getWrapperScrollableContainer();

    return wrapperScrollableContainer[0];
  };

  const {
    feed,
    isLoading: isFeedLoading,
    isRefetchLoading: isFeedRefetchLoading,
    totalCount: activeOpeningsTotalCount,
  } = useFeed({
    getScrollableContainer: getScrollableContainer,
    groupSearchTagsWithOrCondition: searchTagsParams.groupSearchTagsWithOrCondition,
    searchPattern: searchPattern?.toString(),
    searchTagsWithAndCondition: searchTagsParams.searchTagsWithAndCondition,
    searchTagsWithOrCondition: searchTagsParams.searchTagsWithOrCondition,
    workOptions: workOptions,
  });

  useEffect(() => {
    const shouldUpdateScroll =
      !isMounted.current &&
      previousLocation &&
      find(
        [COMPANY_ROUTE, COMPANY_ABOUT_ROUTE, JOB_OPENING_ROUTE, COMPANY_REVIEWS_ROUTE, CREATE_COMPANY_REVIEW_ROUTE],
        route => matchPath(previousLocation, { path: route }),
      );

    const scrollableContainer = getScrollableContainer();

    scrollableContainer.scrollTop = shouldUpdateScroll ? scroll || 0 : 0;

    trackEvent({
      context: Context.SEARCH_RESULTS,
      interaction: Interaction.VIEW,
      itemValue: searchPattern?.toString(),
    });

    isMounted.current = true;

    return () => {
      dispatch(setScroll(SCROLL_KEY, scrollableContainer.scrollTop));
    };
  }, [searchPattern]);

  useEffect(() => {
    if (!isModalOpen) {
      setActiveFilter(null);
    }
  }, [isModalOpen]);

  const handleRemoteSwitchButtonClick = () => {
    const locationFilter = SEARCH_RESULT_FILTERS.find(filter => {
      return filter.key === SEARCH_RESULT_FILTERS_KEYS.LOCATION;
    });

    setActiveFilter(locationFilter || null);
  };

  const applyFilters = (
    key: SearchResultFilters,
    filters: TransformItemsToSearchTagsArgumentItem[],
    queryParamsForLocationFilter: SearchOpeningEntityTagNameEnum[],
  ) => {
    const scrollableContainer = getScrollableContainer();
    let updatedParams = {};

    switch (key) {
      case SearchResultFilters.SENIORITIES:
        updatedParams = {
          ...queryParams,
          searchTags: [
            ...searchTags.filter(
              (searchTag: SearchOpeningEntityTagInput) => searchTag.tag !== SearchOpeningEntityTagNameEnum.SENIORITY,
            ),
            ...transformItemsToSearchTags(SearchOpeningEntityTagNameEnum.SENIORITY, filters),
          ],
        };
        break;
      case SearchResultFilters.SKILLS:
        updatedParams = {
          ...queryParams,
          searchTags: [
            ...searchTags.filter((searchTag: SearchOpeningEntityTagInput) => searchTag.tag !== SEARCH_TAGS.SKILL),
            ...transformItemsToSearchTags(SearchOpeningEntityTagNameEnum.SKILL, filters),
          ],
        };
        break;
      case SearchResultFilters.LOCATION:
        updatedParams = {
          ...queryParams,
          searchTags: [
            ...searchTags.filter(
              (searchTag: SearchOpeningEntityTagInput) =>
                ![
                  SearchOpeningEntityTagNameEnum.REMOTE_ALLOWED_COUNTRY,
                  SearchOpeningEntityTagNameEnum.REMOTE_ALLOWED_LOCATION,
                  SearchOpeningEntityTagNameEnum.REMOTE_ALLOWED_AREA,
                  SearchOpeningEntityTagNameEnum.REMOTE_TYPE,
                  SearchOpeningEntityTagNameEnum.POSITION_LOCATION,
                ].includes(searchTag.tag),
            ),
            ...queryParamsForLocationFilter,
          ],
        };
        break;
      default:
        updatedParams = {};
        break;
    }

    history.replace(
      createLink({
        link: location.pathname,
        queryParams: updatedParams,
      }),
    );

    scrollableContainer.scrollTop = 0;
  };

  const handleSearchPatternClick = () => {
    history.push(
      createLink({
        link: SEARCH_ROUTE,
        queryParams: queryParams,
      }),
    );
  };

  const handleBack = () => {
    history.push(SEARCH_ROUTE);
  };

  return (
    <div className="search-result content-enter-animation">
      <PageTitleWithOgTags message={`Explore ${headerTitle} on MeetFrank.`} title={headerTitle} />

      <Header
        applyFilters={applyFilters}
        onBack={handleBack}
        onSearchPatternClick={handleSearchPatternClick}
        openFilter={activeFilter}
        searchPattern={searchPattern}
        searchTags={searchTags}
        title={headerTitle}
        workOptions={workOptions}
      />

      <OffersCount count={activeOpeningsTotalCount} />

      <CategoryFeaturedOffers
        push={params => history.push(params)}
        searchPattern={searchPattern}
        searchTags={searchTags}
      />

      <LatestOpeningsFeed
        feed={feed}
        isRefetchLoading={isFeedRefetchLoading}
        loading={isFeedLoading}
        onRemoteButtonClick={handleRemoteSwitchButtonClick}
        ref={feedContainerEl}
        searchTags={searchTags}
      />
    </div>
  );
};
