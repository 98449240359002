import { useEffect, useLayoutEffect, useMemo } from 'react';
import { NetworkStatus } from '@apollo/client';
import { throttle } from 'lodash';
import moment from 'moment';
import { recordSignals } from '@src/graphql/signals/actions/recordSignals';
import { useFeedUpdate, usePrevious } from '@src/customHooks';
import {
  GetSearchOpeningsLatestFeedDocument,
  GetSearchRelevantOpeningsV2QueryVariables,
  OpeningRemoteTypeEnum,
  PlaceTypeEnum,
  RelevantOpeningsSortOptionEnum,
  SearchOpeningEntityTagNameEnum,
  SignalEntityTypeEnum,
  SignalTypeEnum,
  useGetSearchRelevantOpeningsV2Query,
} from '@src/graphql/generated';
import { isOpeningType } from '@src/graphql/typeGuards';
import { Context } from '@src/metrics/enums/context.enum';
import { Interaction } from '@src/metrics/enums/interaction.enum';
import { ItemType } from '@src/metrics/enums/itemType.enum';
import { OPENING_STATE } from '@src/constants';
import { getScrollableContainer } from '@src/App';
import { trackEvent } from '@src/metrics';
import { Place } from '../../interfaces/place.interface';
import { UseRelevantOffersFeedParams, UseRelevantOffersFeedValue } from './interfaces/useRelevantOffersFeed.interface';

const FIRST_BATCH_SIZE = 6;
const NEXT_BATCH_SIZE = 6;

export const useRelevantOffersFeed = ({
  searchPattern,
  places,
  openingState = OPENING_STATE.ACTIVE,
  sortOption = RelevantOpeningsSortOptionEnum.RECENCY,
  activePlaceItem,
  shouldSkip,
}: UseRelevantOffersFeedParams): UseRelevantOffersFeedValue => {
  const [selectedLocations, selectedCountries, isRemote] = useMemo<[Place[], Place[], boolean]>(() => {
    const selectedLocations = places?.filter(selectedPlace => selectedPlace.type === PlaceTypeEnum.LOCATION) || [];
    const selectedCountries = places?.filter(selectedPlace => selectedPlace.type === PlaceTypeEnum.COUNTRY) || [];
    const isRemote = !!places?.some(selectedPlace => selectedPlace.type === PlaceTypeEnum.REMOTE);

    return [selectedLocations, selectedCountries, isRemote];
  }, [places?.map(place => place.id).toString()]);

  const getSearchRelevantOpeningsQueryVariables: GetSearchRelevantOpeningsV2QueryVariables = {
    searchPattern: searchPattern,
    searchTags: [
      {
        tag: SearchOpeningEntityTagNameEnum.STATE,
        value: openingState,
      },
    ],
    searchTagsWithOrCondition: [
      ...selectedLocations.map(selectedLocation => {
        return {
          tag: SearchOpeningEntityTagNameEnum.POSITION_LOCATION,
          value: selectedLocation.id,
        };
      }),
      ...selectedCountries.map(selectedCountry => {
        return {
          tag: SearchOpeningEntityTagNameEnum.POSITION_COUNTRY,
          value: selectedCountry.id,
        };
      }),
      ...(isRemote
        ? [
            {
              tag: SearchOpeningEntityTagNameEnum.REMOTE_TYPE,
              value: OpeningRemoteTypeEnum.FULLY,
            },
          ]
        : []),
    ],
    sortedPaginationOptions: {
      limit: FIRST_BATCH_SIZE,
      sortBy: sortOption,
    },
  };

  const {
    data: searchRelevantOpeningsData,
    loading: isSearchRelevantOpeningsLoading,
    fetchMore: searchRelevantOpeningsFetchMore,
    refetch: searchRelevantOpeningsRefetch,
    networkStatus: searchRelevantOpeningsNetworkStatus,
  } = useGetSearchRelevantOpeningsV2Query({
    notifyOnNetworkStatusChange: true,
    skip: shouldSkip,
    variables: getSearchRelevantOpeningsQueryVariables,
  });

  const pagination = searchRelevantOpeningsData?.searchRelevantOpenings?.searchResultsV2.pagination;

  const feed = searchRelevantOpeningsData?.searchRelevantOpenings?.searchResultsV2.result || [];

  const initialLoadingStates = [NetworkStatus.loading, NetworkStatus.setVariables, NetworkStatus.refetch];

  const isInitialLoading = initialLoadingStates.includes(searchRelevantOpeningsNetworkStatus);

  const isLoading = isSearchRelevantOpeningsLoading;

  const previousLoading = usePrevious(isInitialLoading);

  const { checkNewContent } = useFeedUpdate(
    GetSearchOpeningsLatestFeedDocument,
    getSearchRelevantOpeningsQueryVariables,
    'searchRelevantOpenings.searchResults.result',
    searchRelevantOpeningsRefetch,
    getScrollableContainer(),
  );

  useEffect(() => {
    if (previousLoading && !isInitialLoading && feed && feed?.length <= FIRST_BATCH_SIZE) {
      const firstItem = feed[0];

      const defaultEventFields = {
        context: Context.DIGEST,
        interaction: Interaction.FEED_LOADED,
        itemType: ItemType.PLACE,
        itemValue: activePlaceItem?.id,
      };

      if (firstItem && isOpeningType(firstItem)) {
        const secondDate = firstItem.publishedAt;
        trackEvent({
          ...defaultEventFields,
          payload: {
            firstOfferAgeInDays: moment().diff(secondDate, 'days'),
            sortedBy: sortOption,
            totalCount: pagination?.totalCount,
          },
        });
      } else {
        trackEvent({
          ...defaultEventFields,
          payload: {
            sortedBy: sortOption,
            totalCount: pagination?.totalCount || 0,
          },
        });
      }
    }
  }, [previousLoading, isInitialLoading, feed, pagination]);

  useEffect(() => {
    if (pagination?.minCursor) {
      checkNewContent(pagination.minCursor, feed);
    }
  }, []);

  useLayoutEffect(() => {
    const sendMetricAndSignal = (currentLimit: number, feedLength: number) => {
      recordSignals({
        currentLimit: currentLimit,
        items: feed
          ?.filter(opening => !!opening?.id && isOpeningType(opening))
          .map(item => ({
            id: item?.id as string,
            type: SignalEntityTypeEnum.OPENING,
          })),
        signalType: SignalTypeEnum.VIEW_FEED_ITEM,
      });

      trackEvent({
        context: Context.DIGEST,
        interaction: Interaction.FETCH_MORE,
        itemType: ItemType.DIGEST_FEED,
        itemValue: feedLength,
      });
    };

    const fetchMoreSearchRelevantOpenings = async () => {
      const { data: fetchMoreResult } = await searchRelevantOpeningsFetchMore({
        variables: {
          sortedPaginationOptions: {
            limit: NEXT_BATCH_SIZE,
            minCursor: pagination?.maxCursor,
            sortBy: sortOption,
          },
        },
      });

      const result = [...feed, ...(fetchMoreResult?.searchRelevantOpenings?.searchResultsV2.result || [])];

      sendMetricAndSignal(
        fetchMoreResult?.searchRelevantOpenings?.searchResultsV2.pagination.limit || 0,
        result.length,
      );
    };

    const loadMore = throttle(async ({ target: { scrollTop, scrollHeight } }) => {
      const fetchMoreMargin = scrollTop + window.innerHeight * 3;

      if (!isSearchRelevantOpeningsLoading && pagination?.hasMoreResults && fetchMoreMargin > scrollHeight) {
        await fetchMoreSearchRelevantOpenings();
      }
    }, 200);

    getScrollableContainer().addEventListener('scroll', loadMore);

    return () => {
      getScrollableContainer().removeEventListener('scroll', loadMore);
      loadMore.cancel();
    };
  }, [isLoading, pagination]);

  return {
    feed: feed,
    isInitialLoading: isInitialLoading,
    isLoading: isSearchRelevantOpeningsLoading,
    pagination: pagination,
  };
};
