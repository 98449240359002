import React, { FC } from 'react';
import { InvisibleButton } from '@src/components/Button';
import { VERIFIED_USER_ROUTE } from '@src/constants/routes';
import { PublishedStateProps } from './props';
import './styles.scss';

export const PublishedState: FC<PublishedStateProps> = ({ isActionButtonHidden, isActionDisabled }) => {
  if (isActionButtonHidden) {
    return null;
  }

  return (
    <div className="vt-published-state">
      {!isActionButtonHidden ? (
        <InvisibleButton
          className="vt-published-state__button"
          disabled={isActionDisabled}
          isLink
          to={VERIFIED_USER_ROUTE}
        >
          Edit profile
        </InvisibleButton>
      ) : null}
    </div>
  );
};
