import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './styles.scss';

export const JobOpeningFilled = ({ isFeatured, wrapperClassName }) => {
  return (
    <div className={classnames('opening-status-filled', wrapperClassName)}>
      <div
        className={classnames(
          'opening-status-filled__label-wrapper',
          isFeatured && 'opening-status-filled__label-wrapper--white',
        )}
      >
        <div className="opening-status-filled__label">FILLED</div>
      </div>
    </div>
  );
};

JobOpeningFilled.propTypes = {
  isFeatured: PropTypes.bool,
  wrapperClassName: PropTypes.string,
};
