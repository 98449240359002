import React, { FC } from 'react';
import { get } from 'lodash';
import { useHistory } from 'react-router-dom';
import { RECENT_SEARCH_ITEM_TYPE } from '@src/containers/RecentSearches/constants';
import { setRecentSearchItem } from '@src/containers/RecentSearches/utils';
import { HorizontalCard, ImageSize } from '@src/components/HorizontalCard/Card';
import createLink from '@src/util/createLink';
import { COMPANY_ROUTE } from '@src/constants/routes';
import { trackEvent } from '@src/metrics';
import { CompanyHiringStateEnum } from '@src/graphql/generated';
import { Context } from '@src/metrics/enums/context.enum';
import { Interaction } from '@src/metrics/enums/interaction.enum';
import { ItemType } from '@src/metrics/enums/itemType.enum';
import { Hiring } from './Hiring';
import { CompanyProps } from './props';
import './styles.scss';

export const Company: FC<CompanyProps> = ({ company, searchInput }) => {
  const history = useHistory();

  return (
    <HorizontalCard
      additionalElement={
        company.companyHiringState && <Hiring isHiring={company.companyHiringState === CompanyHiringStateEnum.ACTIVE} />
      }
      imageSize={ImageSize.MEDIUM}
      imageUrl={company.profile.logo.fullUrl}
      onClick={() => {
        trackEvent({
          context: Context.SEARCH,
          interaction: Interaction.CLICKED_SEARCHED_ITEM,
          itemType: ItemType.COMPANY,
          itemValue: searchInput,
        });

        setRecentSearchItem({
          company: company,
          type: RECENT_SEARCH_ITEM_TYPE.COMPANY,
          version: 1,
        });

        history.push(createLink(COMPANY_ROUTE, { params: { companyId: company.id } }));
      }}
      subTitle={get(company, 'location.shortName') || ''}
      title={company.profile.name}
      wrapperClassName="auto-suggestions-company fade-in"
    />
  );
};
