import React, { useState, useEffect, FC } from 'react';
import { get, isEmpty, some, size } from 'lodash';
import { useLazyQuery } from '@apollo/client';
import Svg from 'svg-inline-react';
import EditSvg from 'assets/images/fr-edit.svg';
import { SelectItem, SelectItemLoading } from '@src/components/SelectItem';
import { GET_COUNTRY_BY_ID } from '@src/graphql/locations/queries/location';
import FadeInImage from '@src/components/FadeInImage';
import { countryFlagUrl } from '@src/util/countryFlagImageUrl';
import { ANTARCTICA_AREA_ID, GLOBAL_AREA_ID } from '@src/constants';
import { useGetAreasQuery } from '@src/graphql/generated';
import { Props } from './props';
import './styles.scss';

const ADDITIONAL_OPTIONS = [{ key: 'specific_country', value: 'Select a country' }];

export const AreaList: FC<Props> = ({
  activeCountries,
  activeAreas,
  onAreaSelect,
  onSpecificCountrySelect,
  excludedAreaIds = [],
  isSpecificCountrySelected,
  isSingleSelect,
}) => {
  const [isAreasLoading, setIsAreasLoading] = useState(true);
  const [isCountriesLoading, setIsCountriesLoading] = useState(true);

  const { data } = useGetAreasQuery({
    onCompleted: () => {
      setIsAreasLoading(false);
    },
    onError: () => {
      setIsAreasLoading(false);
    },
  });
  const [fetchCountry, { data: countryData }] = useLazyQuery(GET_COUNTRY_BY_ID, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      setIsCountriesLoading(false);
    },
    onError: () => {
      setIsCountriesLoading(false);
    },
  });

  useEffect(() => {
    if (!isEmpty(activeCountries)) {
      fetchCountry({
        variables: {
          id: get(activeCountries, '[0].id'),
        },
      });
    } else {
      setIsCountriesLoading(false);
    }
  }, []);

  const isLoading = isAreasLoading || isCountriesLoading;

  const areas = get(data, 'areas') || [];
  const country = get(countryData, 'country') || [];
  let label = '';
  let flagUrl: string | undefined;

  if (!isLoading && !isEmpty(activeCountries)) {
    label = size(activeCountries) > 1 ? `${country.longName} + ${size(activeCountries) - 1}` : `${country.longName}`;
    flagUrl = countryFlagUrl(get(country, 'shortName'));
  }

  return (
    <div className="area-list">
      {isLoading && <SelectItemLoading amount={5} hasCheckbox hasImage={false} />}
      {!isLoading &&
        !isEmpty(areas) &&
        areas.map(area => {
          if ([ANTARCTICA_AREA_ID, ...excludedAreaIds].includes(area.id)) {
            return null;
          }

          const label = area.id === GLOBAL_AREA_ID ? 'Anywhere' : area.longName;

          return (
            <SelectItem
              active={some(activeAreas, { id: area.id })}
              isSingleSelect={isSingleSelect}
              key={area.id}
              onSelect={onAreaSelect}
              option={area}
              optionLabel={label}
            />
          );
        })}
      {!isLoading &&
        ADDITIONAL_OPTIONS.map(item => (
          <SelectItem
            active={(!isEmpty(activeCountries) && isEmpty(activeAreas)) || isSpecificCountrySelected}
            isSingleSelect={isSingleSelect}
            key={item.key}
            onSelect={onSpecificCountrySelect}
            option={item}
            optionLabel={item.value}
            secondaryName={
              !isEmpty(activeCountries) ? (
                <div className="area-list__sub-title">
                  <FadeInImage imageClassName="area-list__flag" src={flagUrl} />

                  {label}
                  <Svg className="area-list__edit-icon" src={EditSvg} />
                </div>
              ) : (
                ''
              )
            }
          />
        ))}
    </div>
  );
};
