import React, { FC } from 'react';
import './styles.scss';

export const HorizontalListBlockSkeleton: FC = () => {
  return (
    <div className="horizontal-list-block-skeleton">
      <div className="horizontal-list-block-skeleton__item-loading-logo pulse-fade" />
      <div className="horizontal-list-block-skeleton__item-loading-info">
        <div className="horizontal-list-block-skeleton__item-loading-line pulse-fade" />
        <div className="horizontal-list-block-skeleton__item-loading-line pulse-fade" />
      </div>
    </div>
  );
};
