import { partition, map } from 'lodash';
import { CreateStoryParams, CreateStoryValue, Skill } from './interfaces/createStory.interface';

export const isSkillSpeciality = (item: Skill, specialityTag: string): boolean => {
  return item.tags.includes(specialityTag);
};

export const skillsToSpecialities = (skills: Skill[], specialityTag: string): [Skill[], Skill[]] => {
  return partition(skills, skill => isSkillSpeciality(skill, specialityTag));
};

export const mapItem = item => {
  return {
    ...item,
    children: map(item.children, mapItem),
    key: item.tag || item.key,
    value: item.name,
  };
};

export const createStory = ({
  speciality,
  skills: _skills,
  salary,
  seniorities,
  location,
}: CreateStoryParams): CreateStoryValue => {
  const specialityTag = speciality?.skillTags?.[0] || '';
  const mappedSkills = _skills.map(mapItem);

  const [specialities, skills] = skillsToSpecialities(mappedSkills, specialityTag);

  return {
    location: location,
    salary: salary,
    seniorities: seniorities,
    skills: skills,
    specialities: specialities,
    track: speciality,
  };
};
