import classnames from 'classnames';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import FadeInImage from '@src/components/FadeInImage';
import partOfDay from '@src/util/partOfDay';
import { JOBS_SEARCH_ROUTE } from '@src/constants';
import { Props } from './props';
import './styles.scss';

export const DigestPreview: FC<Props> = ({ value, className }) => {
  const { image, title, subtitle, short } = value;

  return (
    <div className={classnames('digest-preview', className)}>
      <Link to={JOBS_SEARCH_ROUTE}>
        <header className={classnames('overview-header', className, partOfDay())}>
          <FadeInImage noBorder src={image} />

          <div className="overview-header-text">
            <div className="overview-header-title">{title}</div>
            <div className="overview-header-subtitle">{subtitle}</div>
            <div className="overview-header-subtitle">{short}</div>
          </div>
        </header>

        <div className="digest-actions">Open the digest</div>
      </Link>
    </div>
  );
};
