import { hasFields } from '@src/util/object/helpers';
import {
  ExtractCompletedUserVerificationType,
  ExtractEmployedUserEmploymentType,
  ExtractPublishedUserVerificationType,
} from './currentUser.interface';

export function isEmployedUserEmploymentType(obj: unknown): obj is ExtractEmployedUserEmploymentType {
  return (
    hasFields<ExtractEmployedUserEmploymentType>(obj, ['__typename']) && obj.__typename === 'EmployedUserEmploymentType'
  );
}

export function isPublishedUserVerificationType(obj: unknown): obj is ExtractPublishedUserVerificationType {
  return (
    hasFields<ExtractPublishedUserVerificationType>(obj, ['__typename']) &&
    obj.__typename === 'PublishedUserVerificationType'
  );
}

export function isCompletedUserVerificationTypeType(obj: unknown): obj is ExtractCompletedUserVerificationType {
  return (
    hasFields<ExtractCompletedUserVerificationType>(obj, ['__typename']) &&
    obj.__typename === 'CompletedUserVerificationType'
  );
}
