import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Svg from 'svg-inline-react';
import FrBookmarkSvg from 'assets/images/fr-bookmark.svg';
import FrBookmarkOutlinedSvg from 'assets/images/fr-bookmark-outlined.svg';
import { useBookmarks } from '@src/graphql/bookmarks/hooks/useBookmarks';
import { trackEvent } from '@src/metrics';
import './styles.scss';

export const Bookmark = ({
  hasBookmarked: defaultIsBookmarked,
  openingId,
  metricsContext,
  onBookmarkCompleted,
  onBookmark,
  isDisabled,
  withLabel,
  outlinedIcon,
  wrapperClassName,
}) => {
  const { hasBookmarked, toggleBookmarkStatus } = useBookmarks({
    entityId: openingId,
    hasBookmarked: defaultIsBookmarked,
  });

  return (
    <div
      className={classnames(
        'opening-action__item',
        wrapperClassName,
        outlinedIcon && 'opening-action__item--outlined',
        hasBookmarked && 'opening-action__item--outlined-blue',
      )}
      onClick={() => {
        if (!isDisabled) {
          if (onBookmark) {
            onBookmark(!defaultIsBookmarked);
          } else {
            toggleBookmarkStatus({
              entityId: openingId,
            });
          }

          if (onBookmarkCompleted) {
            onBookmarkCompleted();
          }

          trackEvent({
            context: metricsContext,
            interaction: 'Bookmark',
            itemType: 'Opening',
            itemValue: openingId,
          });
        }
      }}
    >
      <Svg className="opening-action__icon" src={outlinedIcon ? FrBookmarkOutlinedSvg : FrBookmarkSvg} />
      {withLabel && (
        <span className="opening-action__label opening-action__label--blue">{hasBookmarked ? 'Saved' : 'Save'}</span>
      )}
    </div>
  );
};

Bookmark.defaultProps = {
  outlinedIcon: false,
  withLabel: true,
};

Bookmark.propTypes = {
  hasBookmarked: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,
  metricsContext: PropTypes.string.isRequired,
  onBookmark: PropTypes.func,
  onBookmarkCompleted: PropTypes.func,
  openingId: PropTypes.string.isRequired,
  outlinedIcon: PropTypes.bool,
  withLabel: PropTypes.bool,
  wrapperClassName: PropTypes.string,
};
