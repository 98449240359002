import { random, get } from 'lodash';
import { ApolloError, ApolloQueryResult } from '@apollo/client';
import { MIN_TIMEOUT, MAX_TIMEOUT, MAX_RETRY_TIMES } from '@src/constants';

export const isDataNotPopulatedError = (error: ApolloError): boolean => {
  return error.graphQLErrors.some(({ extensions }) => get(extensions, 'exception.status') === 503);
};

export function handleRefetch<TData>(
  error: ApolloError,
  refetch: () => Promise<ApolloQueryResult<TData>>,
  count = 0,
): void {
  if (error && isDataNotPopulatedError(error) && count < MAX_RETRY_TIMES) {
    setTimeout(
      async () => {
        try {
          await refetch();
        } catch (error) {
          count++;
          handleRefetch(error, refetch, count);
        }
      },
      random(MIN_TIMEOUT, MAX_TIMEOUT),
    );
  }
}
