const IGNORED_ERRORS = ['Invariant Violation'];

/**
 * Wrapper for fetchMore() function, used for muting specific errors
 *
 * @param {function} fetchMore - fetchMore(), provided by apollo useQuery
 * @param {object} fetchMoreOptions - the argument of fetchMore() function
 */
export const handleFetchMore = async (fetchMore, fetchMoreOptions) => {
  try {
    await fetchMore(fetchMoreOptions);
  } catch (error) {
    if (!IGNORED_ERRORS.includes(error.name)) {
      throw error;
    }
  }
};
