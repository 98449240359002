import React, { FC, useEffect, useRef } from 'react';
import MapMarker1Svg from '@shared/assets/svgs/map-marker-1.svg';
import { useQueryParams } from '@src/customHooks/useQueryParams';
import { FilterInput } from '../FilterInput';
import { TagsFilter } from '../TagsFilter';
import { QueryParams } from '../../interfaces/queryParams.interface';
import { PlaceSearchProps } from './props';
import './styles.scss';

export const PlaceSearch: FC<PlaceSearchProps> = ({
  isPlaceSearchActive,
  placesValue,
  onPlacesChange,
  setIsPlaceSearchActive,
  placeTags,
  onCancel,
  onClear,
}) => {
  const locationInputRef = useRef<HTMLInputElement>(null);

  const { queryParams, updateQueryParams } = useQueryParams<QueryParams>();

  useEffect(() => {
    if (isPlaceSearchActive) {
      locationInputRef.current?.focus();
    }
  }, [isPlaceSearchActive]);

  return isPlaceSearchActive ? (
    <FilterInput
      value={placesValue}
      onChange={onPlacesChange}
      onClear={() => onClear?.()}
      ref={locationInputRef}
      startIcon={MapMarker1Svg}
      endElement={
        !placesValue.length && (
          <span onClick={() => onCancel?.()} className="jobs-search-page-header-place-search__cancel">
            Cancel
          </span>
        )
      }
    />
  ) : (
    <TagsFilter
      startIcon={MapMarker1Svg}
      onClick={() => {
        setIsPlaceSearchActive(true);
      }}
      onRemove={place => {
        updateQueryParams({
          places: queryParams.places?.filter(selectedPlace => selectedPlace.id !== place.id),
        });
      }}
      content={
        !placeTags?.length ? (
          <div className="jobs-search-page-header-place-search__location-content">All locations</div>
        ) : undefined
      }
      tags={placeTags}
    />
  );
};
