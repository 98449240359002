import { escape } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Markdown from 'markdown-to-jsx';
import { htmlifyMessage } from '@src/util/htmlifyMessage';
import { replaceHtmlEntities } from '@src/util/replaceHtmlEntities';
import './styles.scss';

export default function Subtitle({ className, value, escaped }) {
  if (escaped) {
    value = escape(value);
  }

  return (
    <p className={classnames('subtitle', className)}>
      <Markdown>{replaceHtmlEntities(htmlifyMessage(value))}</Markdown>
    </p>
  );
}

Subtitle.propTypes = {
  className: PropTypes.string,
  escaped: PropTypes.bool,
  value: PropTypes.string.isRequired,
};
