import { LocalStorageKeys } from '@src/localStorage/enums/localStorageKeys.enum';
import { removeItemFromLocalStorage } from '@src/localStorage/utils/removeItemFromLocalStorage';
import { setItemToLocalStorage } from '@src/localStorage/utils/setItemToLocalStorage';
import { RootAction } from '@src/store/interfaces/store.interface';
import api from '@src/util/api';

export const ACCESS_TOKEN_SET = `${__dirname}/accessTokenSet`;
export function setAccessToken(accessToken: string): RootAction<string, unknown> {
  setItemToLocalStorage(LocalStorageKeys.TOKEN_STORAGE_KEY, accessToken);

  return {
    accessToken: accessToken,
    type: ACCESS_TOKEN_SET,
  };
}

export const ACCESS_TOKEN_REMOVE = `${__dirname}/accessTokenRemove`;
export function removeAccessToken(): RootAction<string, unknown> {
  removeItemFromLocalStorage(LocalStorageKeys.TOKEN_STORAGE_KEY);

  return {
    type: ACCESS_TOKEN_SET,
  };
}

export const fetchRefreshToken = (): RootAction<string, unknown> => {
  return async dispatch => {
    const { accessToken } = await api('/api/app/token/refresh');
    dispatch(setAccessToken(accessToken));

    return accessToken;
  };
};

export const fetchAccessToken = (): RootAction<string, unknown> => {
  return async dispatch => {
    try {
      const { accessToken } = await api('/api/app/token');

      dispatch(setAccessToken(accessToken));

      return accessToken;
    } catch (error) {
      if (error.code === 401) {
        return dispatch(fetchRefreshToken());
      }

      throw error;
    }
  };
};
