import React, { FC } from 'react';
import { ActiveFeed } from '../enums/activeFeed.enum';
import { useRelevantOffersFeed } from '../hooks/useRelevantOffersFeed';
import { OpeningFeed } from '../OpeningFeed';
import { RelevantOffersFeedProps } from './props';

export const RelevantOffersFeed: FC<RelevantOffersFeedProps> = ({
  placeItem,
  searchTags,
  searchTagWithOrCondition,
  sortOption,
  isSimulatedLoading,
}) => {
  const { feed, isInitialLoading, isLoading, hasMoreResults } = useRelevantOffersFeed({
    activePlaceItem: placeItem,
    searchTags: searchTags,
    searchTagsWithOrCondition: searchTagWithOrCondition,
    sortOption: sortOption,
  });

  return (
    <OpeningFeed
      activeFeed={ActiveFeed.SEARCH_RELEVANT_OPENINGS}
      feed={feed}
      hasMoreResults={hasMoreResults}
      isInitialLoading={isInitialLoading}
      isLoading={isLoading || isSimulatedLoading}
      openingSearchTags={searchTags}
      searchTagWithOrCondition={searchTagWithOrCondition}
    />
  );
};
