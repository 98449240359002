import React, { FC } from 'react';
import { toExternalUrl } from '../../util/url';
import { ExternalLinkProps } from './props';

export const ExternalLink: FC<ExternalLinkProps> = ({ children, url, ...props }) => {
  return (
    <a {...props} href={toExternalUrl(url)} target="_blank">
      {children}
    </a>
  );
};
