import React, { Fragment, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty, size } from 'lodash';
import Svg from 'svg-inline-react';
import EditSvg from 'assets/images/fr-edit.svg';
import FadeInImage from '@src/components/FadeInImage';
import { SelectItem, SelectItemLoading } from '@src/components/SelectItem';
import { countryFlagUrl } from '@src/util/countryFlagImageUrl';
import { idify } from '@src/util/idify';
import { GLOBAL_AREA_ID } from '@src/constants';
import {
  OpeningRemoteTypeEnum,
  useGetAreaByIdLazyQuery,
  useGetCountryByIdLazyQuery,
  useGetLocationByIdLazyQuery,
} from '@src/graphql/generated';
import './styles.scss';

const LIST_OPTIONS = [
  {
    key: OpeningRemoteTypeEnum.ONSITE,
    secondaryName: 'Work in the office, most of the time',
    value: 'Find on-site jobs',
  },
  {
    key: OpeningRemoteTypeEnum.HYBRID,
    secondaryName: 'Switch between the office and remote',
    value: 'Find hybrid jobs',
  },
  { key: OpeningRemoteTypeEnum.FULLY, secondaryName: 'Work remotely, all of the time', value: 'Find remote jobs' },
];

export const HowWouldYouLikeToWorkList = ({
  activeOptionKeys,
  onContentClick,
  onCheckBoxClick,
  activeLocation,
  activeCountries,
  activeAreas,
}) => {
  const didMount = useRef(false);

  const [loading, setLoading] = useState(true);

  const [fetchLocation, { data: locationData }] = useGetLocationByIdLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      setLoading(false);
    },
  });

  const [fetchCountry, { data: countryData }] = useGetCountryByIdLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      setLoading(false);
    },
  });

  const [fetchArea, { data: areaData }] = useGetAreaByIdLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      setLoading(false);
    },
  });

  const area = get(areaData, 'area') || {};
  const country = get(countryData, 'country') || {};
  const location = get(locationData, 'location') || {};

  const fetchAdditionalFields = () => {
    if (!isEmpty(activeCountries)) {
      fetchCountry({
        variables: {
          id: activeCountries[0].id,
        },
      });
    }

    if (!isEmpty(activeAreas)) {
      fetchArea({
        variables: {
          id: activeAreas[0].id,
        },
      });
    }

    if (idify(activeLocation[0])) {
      fetchLocation({
        variables: {
          id: idify(activeLocation[0]),
        },
      });
    }
  };

  useEffect(() => {
    if (!isEmpty(activeCountries) || !isEmpty(activeAreas) || !isEmpty(activeLocation)) {
      fetchAdditionalFields();
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (didMount.current) {
      if (!isEmpty(activeLocation)) {
        fetchAdditionalFields();
      }
    } else {
      didMount.current = true;
    }
  }, [idify(activeLocation[0])]);

  const getUpdatedOption = option => {
    let flagUrl;
    let label;
    let isEditIconVisible = false;

    if ([OpeningRemoteTypeEnum.ONSITE, OpeningRemoteTypeEnum.HYBRID].includes(option.key)) {
      flagUrl = countryFlagUrl(get(location, 'country.shortName'));
      label = get(location, 'shortName') || option.secondaryName;
      isEditIconVisible = !!idify(activeLocation[0]);
    } else if (option.key === OpeningRemoteTypeEnum.FULLY) {
      flagUrl = countryFlagUrl(get(country, 'shortName'));

      if (!isEmpty(activeCountries)) {
        label =
          size(activeCountries) > 1 ? `${country.longName} + ${size(activeCountries) - 1}` : `${country.longName}`;
      } else if (!isEmpty(activeAreas)) {
        label = area.id === GLOBAL_AREA_ID ? 'Anywhere' : area.longName;
      } else {
        label = option.secondaryName;
      }

      isEditIconVisible = !isEmpty(activeAreas) || !isEmpty(activeCountries);
    }

    return {
      ...option,
      secondaryName: (
        <div className="where-do-like-work-options__sub-title">
          {flagUrl && <FadeInImage imageClassName="where-do-like-work-options__flag" src={flagUrl} />}

          {label}

          {isEditIconVisible && <Svg className="where-do-like-work-options__edit-icon" src={EditSvg} />}
        </div>
      ),
    };
  };

  return (
    <div className="where-do-like-work-options">
      {loading ? (
        <Fragment>
          <SelectItemLoading amount={3} hasCheckbox hasImage={false} />
        </Fragment>
      ) : (
        LIST_OPTIONS.map(option => {
          const updatedOption = getUpdatedOption(option);

          return (
            <SelectItem
              active={!!activeOptionKeys.find(key => key === option.key)}
              key={option.key}
              onCheckBoxClick={() => onCheckBoxClick(option)}
              onContentClick={() => onContentClick(option)}
              option={updatedOption}
            />
          );
        })
      )}
    </div>
  );
};

HowWouldYouLikeToWorkList.propTypes = {
  activeAreas: PropTypes.array,
  activeCountries: PropTypes.array,
  activeLocation: PropTypes.array,
  activeOptionKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  onCheckBoxClick: PropTypes.func.isRequired,
  onContentClick: PropTypes.func.isRequired,
};
