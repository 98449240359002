import React, { FC, useEffect, useRef } from 'react';
import { matchPath, useHistory, useRouteMatch } from 'react-router-dom';
import InformationalModal from '@src/components/InformationalModal';
import { OpeningAssistantModal } from '@src/containers/OpeningAssistantModal';
import { trackEvent } from '@src/metrics';
import { shareLink } from '@src/util/shareLink';
import { PUBLIC_APP_URL } from '@src/constants';
import { JOB_OPENING_ROUTE, COMPANY_REVIEWS_ROUTE, COMPANY_ROUTE } from '@src/constants/routes';
import { getOpeningInformationalModalType } from '@src/util/getOpeningInformationalModalType';
import { setContent as setModalContent, closeModal } from '@src/redux/actions/slideUpModal';
import createLink from '@src/util/createLink';
import { startApply } from '@src/redux/actions/openings';
import { useBookmarks } from '@src/graphql/bookmarks/hooks/useBookmarks';
import { recordSignals } from '@src/graphql/signals/actions/recordSignals';
import { setNavigationVisibility, goBackAndPreventLeave } from '@src/redux/actions/navigation';
import { getScrollableContainer } from '@src/App';
import { useTransparentizeStatusBar } from '@src/customHooks/useTransparentizeStatusBar';
import { setBodyTopBackGroundGrey300, removeBodyTopBackGroundGrey300 } from '@src/container';
import { SignalEntityTypeEnum, SignalTypeEnum, useGetUserQuery } from '@src/graphql/generated';
import { Context } from '@src/metrics/enums/context.enum';
import { Interaction } from '@src/metrics/enums/interaction.enum';
import { ItemType } from '@src/metrics/enums/itemType.enum';
import { useAppSelector, useAppDispatch } from '@src/store';
import { getPreviousLocation } from '@src/redux/selectors/navigation';
import { getScroll } from '@src/redux/selectors/scroll';
import { setScroll } from '@src/redux/actions/scroll';
import { TaxInfo } from './TaxInfo';
import { JobOpeningView } from './JobOpeningView';
import { useOpening } from './hooks/useOpening';

const SCROLL_KEY = 'job-opening';

export const JobOpening: FC = () => {
  const history = useHistory();

  const match = useRouteMatch<{ openingId?: string }>({
    path: JOB_OPENING_ROUTE,
    sensitive: true,
    strict: true,
  });

  const openingId = match?.params.openingId;

  const scrollableContainer = getScrollableContainer();

  const previousLocation = useAppSelector(getPreviousLocation);

  const scrolls = useAppSelector(getScroll);

  const scroll = scrolls?.[SCROLL_KEY];

  const openingViewScrolls = useRef<Record<string, number>>({});

  const setScrollPosition = () => {
    const shouldUpdateScroll =
      previousLocation &&
      [COMPANY_ROUTE, COMPANY_REVIEWS_ROUTE].some(route => matchPath(previousLocation, { path: route }));

    const shouldUpdateScrollFromOpening =
      previousLocation && [JOB_OPENING_ROUTE].some(route => matchPath(previousLocation, { path: route }));

    if (shouldUpdateScrollFromOpening && openingId && openingViewScrolls.current[openingId]) {
      scrollableContainer.scrollTop = openingViewScrolls.current[openingId];

      return;
    }

    if (shouldUpdateScroll && scroll) {
      scrollableContainer.scrollTop = scroll;

      return;
    }

    scrollableContainer.scrollTop = 0;
  };

  const { opening, isLoading } = useOpening({
    openingId: openingId,
  });

  const dispatch = useAppDispatch();

  const { data: userData } = useGetUserQuery();
  const user = userData?.currentUser;

  // Opening
  const hiringState = opening?.hiringState;
  const candidateDestination = opening?.candidateDestination;
  const matchIntroState = opening?.match?.introState;
  const isDefaultBookmarked = opening?.hasBookmarked;

  // company
  const company = opening?.company;
  const companyId = company?.id;

  const shareOpening = () => {
    if (!openingId) {
      return;
    }

    shareLink(`${PUBLIC_APP_URL}${JOB_OPENING_ROUTE.replace(':openingId', openingId)}`);

    trackEvent({
      context: Context.OPENING,
      interaction: Interaction.SHARE,
      itemType: ItemType.OPENING,
      itemValue: openingId,
    });
  };

  const openTaxesInfo = () => {
    dispatch(
      setModalContent({
        content: <TaxInfo closeTaxInfo={() => dispatch(closeModal())} />,
        height: 'auto',
      }),
    );
  };

  const openAssistantModal = () => {
    dispatch(
      setModalContent({
        content: <OpeningAssistantModal opening={{ ...opening, candidateDestination }} />,
        height: 'auto',
      }),
    );
  };

  const openLearnMoreAboutHiringStateModal = () => {
    if (hiringState) {
      dispatch(
        setModalContent({
          content: (
            <InformationalModal
              close={() => dispatch(closeModal())}
              type={getOpeningInformationalModalType(hiringState)}
            />
          ),
          height: 'auto',
        }),
      );
    }
  };

  const onApply = () => {
    dispatch(startApply(openingId, hiringState, candidateDestination, matchIntroState, 'Opening'));
  };

  const onDiscard = () => {
    if (openingId) {
      recordSignals({
        items: [
          {
            id: openingId,
            type: SignalEntityTypeEnum.OPENING,
          },
        ],
        signalType: SignalTypeEnum.DISCARD_OPENING,
      });
    }

    trackEvent({
      context: Context.OPENING,
      interaction: Interaction.DISCARD,
      itemType: ItemType.OPENING,
      itemValue: openingId,
    });

    history.goBack();
  };

  useTransparentizeStatusBar({
    scrollableContainer: scrollableContainer,
    shouldToggleStatusBarOnScroll: true,
  });

  const { hasBookmarked, toggleBookmarkStatus } = useBookmarks({
    hasBookmarked: isDefaultBookmarked,
  });

  const handleBookMark = () => {
    if (window.isPublicApp && opening?.deepLinkUrl) {
      window.open(opening?.deepLinkUrl, '_blank');

      return;
    }

    trackEvent({
      context: Context.OPENING,
      interaction: Interaction.BOOKMARK,
      itemType: ItemType.OPENING,
      itemValue: openingId,
    });
    toggleBookmarkStatus({
      entityId: openingId,
    });
  };

  const handleRatingClick = () => {
    if (!companyId) {
      return;
    }

    trackEvent({
      context: Context.OPENING,
      interaction: Interaction.OPEN_COMPANY_REVIEWS,
    });

    history.push(
      createLink({
        link: COMPANY_REVIEWS_ROUTE,
        params: { companyId: companyId },
      }),
    );
  };

  useEffect(() => {
    setBodyTopBackGroundGrey300();

    return () => {
      removeBodyTopBackGroundGrey300();
      dispatch(setNavigationVisibility(true));
    };
  }, []);

  useEffect(() => {
    trackEvent({
      context: Context.OPENING,
      interaction: Interaction.VIEW,
      itemType: ItemType.OPENING,
      itemValue: openingId,
    });

    dispatch(setNavigationVisibility(false));

    return () => {
      if (openingId) {
        openingViewScrolls.current[openingId] = scrollableContainer.scrollTop;
      }

      dispatch(setScroll(SCROLL_KEY, scrollableContainer.scrollTop));
    };
  }, [openingId]);

  return (
    <JobOpeningView
      handleRatingClick={handleRatingClick}
      hasBookmarked={hasBookmarked}
      isLoading={isLoading}
      onApply={onApply}
      onBack={() => dispatch(goBackAndPreventLeave())}
      onBookmark={handleBookMark}
      onDiscard={onDiscard}
      onReviewsCompleted={() => {
        setScrollPosition();
      }}
      openAssistantModal={openAssistantModal}
      openLearnMoreAboutHiringStateModal={openLearnMoreAboutHiringStateModal}
      openTaxesInfo={openTaxesInfo}
      opening={opening}
      shareOpening={shareOpening}
      user={user}
    />
  );
};
