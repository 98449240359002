import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { LocationAutocomplete } from '@src/components/LocationAutocomplete';
import { FixedHeader } from '@src/components/FixedHeader';
import { ActionButtons } from '../../ActionButtons';
import './styles.scss';

export const LocationSearch = ({ onNext, onLocationSelect, defaultLocation, goBack, onLocationClear }) => {
  return (
    <div className="location-search-modal">
      <FixedHeader
        disableOpacity
        onBack={goBack}
        title="Where would you like to work?"
        wrapperClassName="location-search-modal__header"
      />
      <div className="location-search-modal__body">
        <LocationAutocomplete
          defaultValue={get(defaultLocation, '[0].longName', '')}
          forceChangeFromParent
          selectLocation={onLocationSelect}
          showClear
        />
      </div>
      <div className="location-search-modal__footer">
        <ActionButtons
          blueButtonLabel="Save"
          blueButtonProps={{
            disabled: !get(defaultLocation, '[0].longName'),
            onClick: onNext,
          }}
          invisibleButtonProps={{
            onClick: onLocationClear,
          }}
        />
      </div>
    </div>
  );
};

LocationSearch.propTypes = {
  defaultLocation: PropTypes.string,
  goBack: PropTypes.func.isRequired,
  onLocationClear: PropTypes.func.isRequired,
  onLocationSelect: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};
