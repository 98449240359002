import React, { FC } from 'react';
import classNames from 'classnames';
import { MessageBubbleProps } from './props';
import './styles.scss';

export const MessageBubble: FC<MessageBubbleProps> = ({ children, type = 'incoming', className }) => {
  return (
    <div className={classNames('inbox-message-bubble', `inbox-message-bubble--${type}`, className)}>{children}</div>
  );
};
