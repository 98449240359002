import moment from 'moment';
import { useEffect } from 'react';
import { useQueryParams } from '@src/customHooks/useQueryParams';
import { useLocalStorage } from '@src/localStorage/hooks/useLocalStorage';
import { LocalStorageKeys } from '@src/localStorage/enums/localStorageKeys.enum';
import { RelevantOpeningsSortOptionEnum } from '@src/graphql/generated';
import { QueryParams } from '../../interfaces/queryParams.interface';
import { useSortOptionsValue } from './interfaces/useSortOptions.interface';

export const useSortOptions = (): useSortOptionsValue => {
  const { queryParams } = useQueryParams<QueryParams>();

  const [lastSeenJobsFeed, setLastSeenJobsFeed] = useLocalStorage<string>(LocalStorageKeys.LAST_SEEN_JOBS_FEED);

  const [localStorageSortOption, setLocalStorageSortOption] = useLocalStorage<RelevantOpeningsSortOptionEnum>(
    LocalStorageKeys.JOBS_FEED_SORT_OPTION,
    RelevantOpeningsSortOptionEnum.RECENCY,
  );

  const sortOption = localStorageSortOption || RelevantOpeningsSortOptionEnum.RECENCY;

  const updateSortOption = (sortOption: RelevantOpeningsSortOptionEnum) => {
    setLocalStorageSortOption(sortOption);
  };

  useEffect(() => {
    setLastSeenJobsFeed(moment().toISOString());

    if (!lastSeenJobsFeed || moment(lastSeenJobsFeed).isBefore(moment().subtract(1, 'month'))) {
      setLocalStorageSortOption(RelevantOpeningsSortOptionEnum.RELEVANCY);
    } else if (moment(lastSeenJobsFeed).isBefore(moment().subtract(12, 'hours'))) {
      setLocalStorageSortOption(RelevantOpeningsSortOptionEnum.RECENCY);
    }
  }, []);

  useEffect(() => {
    if (queryParams.sortOption) {
      setLocalStorageSortOption(queryParams.sortOption);
    }
  }, [queryParams.sortOption]);

  return {
    sortOption,
    updateSortOption,
  };
};
