/**
 * @param {string} str - String in which to replace html codes  unicode characters
 * @param {boolean} shouldSkipLineBreaks - Wether to skip line breaks while converting
 */
export function replaceHtmlEntities(str, shouldSkipLineBreaks) {
  const result = String(str)
    .replace(/&nbsp;|&#160;/g, String.fromCharCode(160))
    .replace(/&amp;|&#38;/g, '&')
    .replace(/&lt;|&#60;/g, '<')
    .replace(/&gt;|&#62;/g, '>')
    .replace(/&quot;|&#34;/g, '"')
    .replace(/&apos;|&#39;/g, "'");

  if (!shouldSkipLineBreaks) {
    return result.replace(/\n/g, '<br/>');
  }

  return result;
}
