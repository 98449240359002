import React, { createElement, DetailedReactHTMLElement, forwardRef, ImgHTMLAttributes } from 'react';
import ReactMarkdown from 'markdown-to-jsx';
import classNames from 'classnames';
import { MarkdownLink } from './Link';
import { MarkdownParagraph } from './Paragraph';
import { MarkdownHeadline } from './Headline';
import { MarkdownPre } from './MarkdownPre';
import { MarkdownCode } from './MarkdownCode';
import { MarkdownStrong } from './MarkdownStrong';
import { MarkdownItalic } from './MarkdownItalic';
import { MarkdownUnorderedList } from './UnorderedList';
import { MarkdownOrderedList } from './OrderedList';
import { MarkdownProps } from './props';
import './styles.scss';

export const Markdown = forwardRef<HTMLDivElement, MarkdownProps>(
  ({ wrapperClassName, isInlineForced = false, markdown }, ref) => {
    if (!markdown) {
      return null;
    }

    const HideMarkdownElement = (
      imgAttributes: ImgHTMLAttributes<HTMLImageElement>,
    ): DetailedReactHTMLElement<ImgHTMLAttributes<HTMLImageElement>, HTMLElement> | null => {
      if (imgAttributes.className === 'emoji') {
        return createElement('img', imgAttributes);
      }

      return null;
    };

    return (
      <div className={classNames('markdown', wrapperClassName, isInlineForced && 'markdown--inline')} ref={ref}>
        <ReactMarkdown
          options={{
            forceInline: isInlineForced,
            overrides: {
              a: MarkdownLink,
              blockquote: MarkdownParagraph,
              code: MarkdownCode,
              em: MarkdownItalic,
              h1: MarkdownHeadline,
              h2: MarkdownHeadline,
              h3: MarkdownHeadline,
              h4: MarkdownHeadline,
              h5: MarkdownHeadline,
              h6: MarkdownHeadline,
              img: HideMarkdownElement,
              ol: MarkdownOrderedList,
              p: MarkdownParagraph,
              pre: MarkdownPre,
              strong: MarkdownStrong,
              ul: MarkdownUnorderedList,
            },
          }}
        >
          {markdown}
        </ReactMarkdown>
      </div>
    );
  },
);

Markdown.displayName = 'Markdown';
