import React, { FC, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get } from 'lodash';
import Svg from 'svg-inline-react';
import FrSearchSvg from 'assets/images/fr-search.svg';
import FrCoseSvg from 'assets/images/fr-close-small.svg';
import { useHistory } from 'react-router-dom';
import createLink from '@src/util/createLink';
import { COMPANY_ROUTE, SEARCH_RESULT_ROUTE } from '@src/constants/routes';
import { HorizontalCard, ImageSize } from '@src/components/HorizontalCard/Card';
import { getRecentSearchItems, removeRecentSearchItem } from '@src/containers/RecentSearches/utils';
import { RECENT_SEARCH_ITEM_TYPE } from '@src/containers/RecentSearches/constants';
import { getAllSearchTags } from '@src/views/Search/utils';
import { GLOBAL_AREA_ID } from '@src/constants/places';
import { SEARCH_TAGS } from '@src/constants';
import { OpeningRemoteTypeEnum } from '@src/graphql/generated';
import { RecentSearchesProps } from './props';
import './styles.scss';

export const RecentSearches: FC<RecentSearchesProps> = ({ wrapperClassName }) => {
  const [recentItems, setRecentItems] = useState(getRecentSearchItems());

  const history = useHistory();

  function createSubtitle(item) {
    if (item.type === RECENT_SEARCH_ITEM_TYPE.SEARCH) {
      const senioritiesTitle =
        item.seniorities.length === 0 ? '' : ` • ${item.seniorities.map(({ name }) => name).join(', ')}`;
      let locationText = get(item, 'location.shortName', '');

      if (item.workOptions.includes(OpeningRemoteTypeEnum.FULLY)) {
        if (item.countries.length > 0) {
          locationText = item.countries.map(({ longName }) => longName).join(', ');
        }

        if (item.areas.length > 0) {
          locationText = item.areas.map(({ longName }) => longName).join(', ');
        }
      }

      return `${locationText}${senioritiesTitle}`;
    }

    if (item.type === RECENT_SEARCH_ITEM_TYPE.COMPANY) {
      return get(item, 'company.location.shortName') || '';
    }

    return '';
  }

  function createTitle(item) {
    if (item.type === RECENT_SEARCH_ITEM_TYPE.SEARCH) {
      return item.searchPattern;
    }

    if (item.type === RECENT_SEARCH_ITEM_TYPE.COMPANY) {
      return item.company.profile.name;
    }

    return '';
  }

  function routeToItem(item) {
    if (item.type === RECENT_SEARCH_ITEM_TYPE.SEARCH) {
      const allSearchTags = getAllSearchTags(
        item.workOptions,
        item.areas,
        item.countries,
        item.location,
        item.seniorities,
      );
      const isGlobalAreaSelected =
        item.workOptions.includes(OpeningRemoteTypeEnum.FULLY) && item.areas.find(it => it.id === GLOBAL_AREA_ID);

      history.push(
        createLink({
          link: SEARCH_RESULT_ROUTE,
          queryParams: {
            searchPattern: item.searchPattern,
            searchTags: isGlobalAreaSelected
              ? [...allSearchTags, { tag: SEARCH_TAGS.REMOTE_AREA, value: GLOBAL_AREA_ID }]
              : allSearchTags,
          },
        }),
      );
    }

    if (item.type === RECENT_SEARCH_ITEM_TYPE.COMPANY) {
      history.push(
        createLink(COMPANY_ROUTE, {
          params: {
            companyId: item.company.id,
          },
        }),
      );
    }
  }

  if (!recentItems?.length) {
    return null;
  }

  return (
    <div className={classnames('recent-searches', wrapperClassName)}>
      <h4 className="recent-searches__title">Recent searches</h4>

      <div className="recent-searches__list">
        {recentItems?.map(item => (
          <HorizontalCard
            additionalElement={
              <Svg
                className="recent-searches__card-remove-icon"
                onClick={event => {
                  event.stopPropagation();

                  setRecentItems(removeRecentSearchItem(item));
                }}
                src={FrCoseSvg}
              />
            }
            imageClassName={item.type === RECENT_SEARCH_ITEM_TYPE.SEARCH ? 'recent-searches__card-icon' : ''}
            imageSize={ImageSize.MEDIUM}
            imageUrl={get(item, 'company.profile.logo.fullUrl')}
            key={item.storageId}
            onClick={() => {
              routeToItem(item);
            }}
            subTitle={createSubtitle(item)}
            svgSrc={FrSearchSvg}
            title={createTitle(item)}
            wrapperClassName="recent-searches__search-card"
          />
        ))}
      </div>
    </div>
  );
};

RecentSearches.propTypes = {
  wrapperClassName: PropTypes.string,
};
