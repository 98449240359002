import React, { FC, useRef } from 'react';
import SwiperType, { Pagination, EffectCoverflow } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import classnames from 'classnames';
import { shuffle } from 'lodash';
import { getFinancialDetailsCopy } from '@src/util/company';
import { trackEvent } from '@src/metrics';
import { Context } from '@src/metrics/enums/context.enum';
import { Interaction } from '@src/metrics/enums/interaction.enum';
import { CardWrapper } from '../CardWrapper';
import { MainCard } from './MainCard';
import { Role } from './Role';
import { CompanyLocations } from './CompanyLocations';
import { Reviews } from './Reviews';
import { FindOutMore } from './FindOutMore';
import { JobOpeningCompanyInfoSwiperProps } from './props';
import './styles.scss';

export const JobOpeningCompanyInfoSwiper: FC<JobOpeningCompanyInfoSwiperProps> = ({
  wrapperClassName,
  company,
  openingRequirements,
  openingId,
  isFeatured,
}) => {
  const companyProfile = company?.profile;
  const companyName = companyProfile?.name;

  const images = useRef(
    shuffle(!companyProfile?.officeImages?.length ? [companyProfile?.headerImage] : companyProfile.officeImages),
  );
  const office = companyProfile?.offices?.find(office => office.isHeadquarter) || companyProfile?.offices?.[0];
  const companyIntro =
    companyProfile?.missionStatement || companyProfile?.serviceDescription || companyProfile?.officeDescription;
  const employeesAmount = companyProfile?.employeesCount || companyProfile?.size?.name;

  const imageOne = images.current?.[0]?.fullUrl;

  const handleSlideChange = (swiper: SwiperType) => {
    trackEvent({
      context: Context.OPENING_FEED_ITEM,
      interaction: Interaction.SWIPE,
      payload: {
        'Opening Id': openingId,
        'Slider Page': swiper.activeIndex + 1,
      },
    });
  };

  return (
    <div
      className={classnames(
        'job-opening-company-info-scroller',
        wrapperClassName,
        isFeatured && 'job-opening-company-info-scroller--dark-mode',
      )}
    >
      <Swiper
        centeredSlides={true}
        coverflowEffect={{
          depth: 200,
          modifier: 1,
          rotate: 0,
          slideShadows: true,
          stretch: 0,
        }}
        effect={'coverflow'}
        loop={false}
        modules={[Pagination, EffectCoverflow]}
        pagination={{
          bulletActiveClass: 'fr-swiper__bullet--active',
          bulletClass: 'fr-swiper__bullet',
          el: '.fr-swiper__pagination',
        }}
        slidesPerView={'auto'}
        spaceBetween={20}
        onSlideChange={handleSlideChange}
      >
        {company && (
          <SwiperSlide>
            <CardWrapper isFeatured={isFeatured}>
              <MainCard
                employeesAmount={employeesAmount}
                financialDetails={getFinancialDetailsCopy(
                  companyProfile?.latestValuation,
                  companyProfile?.totalFunding,
                  companyProfile?.revenue,
                )}
                image={imageOne}
                office={office}
                openingId={openingId}
                text={companyIntro || undefined}
              />
            </CardWrapper>
          </SwiperSlide>
        )}

        {openingRequirements.trim().length > 0 && (
          <SwiperSlide>
            <CardWrapper isFeatured={isFeatured}>
              <Role openingId={openingId} text={openingRequirements} />
            </CardWrapper>
          </SwiperSlide>
        )}

        {!!company?.reviews?.result && (
          <SwiperSlide>
            <CardWrapper isFeatured={isFeatured}>
              <Reviews
                companyId={company.id}
                companyName={companyName}
                registeredEmployeesCount={company.registeredEmployeesCount}
                reviews={company.reviews}
                reviewsOverview={company.reviewsOverview}
              />
            </CardWrapper>
          </SwiperSlide>
        )}

        {company && companyProfile?.offices && (
          <SwiperSlide>
            <CardWrapper isFeatured={isFeatured}>
              <CompanyLocations offices={companyProfile.offices} />
            </CardWrapper>
          </SwiperSlide>
        )}

        <SwiperSlide>
          <CardWrapper isFeatured={isFeatured}>
            <FindOutMore openingId={openingId} />
          </CardWrapper>
        </SwiperSlide>

        <div className="fr-swiper__pagination" />
      </Swiper>
    </div>
  );
};
