import ThunderSvg from '@shared/assets/svgs/thunder.svg';
import ThunderMoveSvg from '@shared/assets/svgs/thunder-move.svg';
import BonusSvg from '@shared/assets/svgs/bonus.svg';
import BuildingSvg from '@shared/assets/svgs/building.svg';
import CoffeeSvg from '@shared/assets/svgs/coffee.svg';
import DiamondSvg from '@shared/assets/svgs/diamond.svg';
import VisaSvg from '@shared/assets/svgs/visa.svg';
import LaptopSvg from '@shared/assets/svgs/laptop.svg';
import GraphSvg from '@shared/assets/svgs/graph.svg';
import CloudSvg from '@shared/assets/svgs/cloud.svg';
import AISvg from '@shared/assets/svgs/AI.svg';
import DiscountSvg from '@shared/assets/svgs/discount.svg';
import GlobeSvg from '@shared/assets/svgs/globe.svg';
import GamingSvg from '@shared/assets/svgs/gaming.svg';
import CryptoSvg from '@shared/assets/svgs/crypto.svg';
import EuroSvg from '@shared/assets/svgs/euro.svg';
import { OpeningSearchCategoryTypeEnum } from '@src/graphql/generated';
import { Feed } from '../../enums/feed.enum';
import { Category } from './interfaces/category.interface';

export const DEFAULT_CATEGORY = OpeningSearchCategoryTypeEnum.OPENINGS_LATEST;
export const DEFAULT_FEED = Feed.CATEGORIES;

export const CATEGORIES: Record<OpeningSearchCategoryTypeEnum, Category> = {
  [OpeningSearchCategoryTypeEnum.OPENINGS_LATEST]: {
    name: 'New offers',
    svg: BonusSvg,
    type: OpeningSearchCategoryTypeEnum.OPENINGS_LATEST,
  },
  [OpeningSearchCategoryTypeEnum.COMPANY_TYPE_START_UPS]: {
    name: 'Startups',
    svg: ThunderSvg,
    type: OpeningSearchCategoryTypeEnum.COMPANY_TYPE_START_UPS,
  },
  [OpeningSearchCategoryTypeEnum.COMPANY_TYPE_SCALE_UPS]: {
    name: 'Scaleups',
    svg: ThunderMoveSvg,
    type: OpeningSearchCategoryTypeEnum.COMPANY_TYPE_SCALE_UPS,
  },
  [OpeningSearchCategoryTypeEnum.COMPANY_TYPE_CORPORATE]: {
    name: 'Corporate',
    svg: BuildingSvg,
    type: OpeningSearchCategoryTypeEnum.COMPANY_TYPE_CORPORATE,
  },
  [OpeningSearchCategoryTypeEnum.COMPANY_TYPE_SMB]: {
    name: 'SMB',
    svg: CoffeeSvg,
    type: OpeningSearchCategoryTypeEnum.COMPANY_TYPE_SMB,
  },
  [OpeningSearchCategoryTypeEnum.BENEFIT_EQUITY]: {
    name: 'Equity (Stock options)',
    svg: DiamondSvg,
    type: OpeningSearchCategoryTypeEnum.BENEFIT_EQUITY,
  },
  [OpeningSearchCategoryTypeEnum.FULLY_REMOTE]: {
    name: 'Remote',
    svg: VisaSvg,
    type: OpeningSearchCategoryTypeEnum.FULLY_REMOTE,
  },
  [OpeningSearchCategoryTypeEnum.HYBRID_REMOTE]: {
    name: 'Hybrid',
    svg: LaptopSvg,
    type: OpeningSearchCategoryTypeEnum.HYBRID_REMOTE,
  },
  [OpeningSearchCategoryTypeEnum.INDUSTRY_FINTECH]: {
    name: 'Fintech',
    svg: GraphSvg,
    type: OpeningSearchCategoryTypeEnum.INDUSTRY_FINTECH,
  },
  [OpeningSearchCategoryTypeEnum.INDUSTRY_SAAS]: {
    name: 'SaaS',
    svg: CloudSvg,
    type: OpeningSearchCategoryTypeEnum.INDUSTRY_SAAS,
  },
  [OpeningSearchCategoryTypeEnum.INDUSTRY_AI]: {
    name: 'AI',
    svg: AISvg,
    type: OpeningSearchCategoryTypeEnum.INDUSTRY_AI,
  },
  [OpeningSearchCategoryTypeEnum.INDUSTRY_ECOMMERCE]: {
    name: 'E-commerce',
    svg: DiscountSvg,
    type: OpeningSearchCategoryTypeEnum.INDUSTRY_ECOMMERCE,
  },
  [OpeningSearchCategoryTypeEnum.INDUSTRY_CLEAN_TECH]: {
    name: 'CleanTech',
    svg: GlobeSvg,
    type: OpeningSearchCategoryTypeEnum.INDUSTRY_CLEAN_TECH,
  },
  [OpeningSearchCategoryTypeEnum.INDUSTRY_GAMING]: {
    name: 'Gaming',
    svg: GamingSvg,
    type: OpeningSearchCategoryTypeEnum.INDUSTRY_GAMING,
  },
  [OpeningSearchCategoryTypeEnum.INDUSTRY_CRYPTO_BLOCKCHAIN]: {
    name: 'Crypto & Blockchain',
    svg: CryptoSvg,
    type: OpeningSearchCategoryTypeEnum.INDUSTRY_CRYPTO_BLOCKCHAIN,
  },
  [OpeningSearchCategoryTypeEnum.OPENINGS_WITH_SALARY]: {
    name: 'Jobs with salaries',
    svg: EuroSvg,
    type: OpeningSearchCategoryTypeEnum.OPENINGS_WITH_SALARY,
  },
};
