export enum ItemType {
  ACTIVE_TAB = 'Active Tab',
  ALL = 'All',
  ARTICLE = 'Article',
  ARTICLES_FEED = 'Articles Feed',
  CATEGORY = 'Category',
  CHAT_TYPE = 'Chat Type',
  COMPANY = 'Company',
  COMPANY_OPENINGS_FEED = 'Company Openings Feed',
  CUSTOM = 'Custom',
  DIGEST = 'Digest',
  DIGEST_FEED = 'Digest Feed',
  EXPLORE = 'Explore',
  FILLED_FEED = 'Filled Feed',
  FOLLOWINGS = 'Followings',
  HIRE_WITH_MEETFRANK = 'Hire with MeetFrank',
  HOME = 'Home',
  INBOX = 'Inbox',
  INDEX = 'Index',
  JOBS_FEED = 'Jobs Feed',
  LATEST_OFFERS_FEED = 'Latest Offers Feed',
  LINK = 'Link',
  LOCAL = 'Local',
  MENU = 'Menu',
  MESSAGES = 'Messages',
  MESSAGE_REQUEST = 'Message Request',
  MESSAGE_REQUESTS = 'Message Requests',
  OPENING = 'OPENING',
  PAGINATION = 'Pagination',
  PLACE = 'Place',
  PROFILE = 'Profile',
  REMOTE = 'Remote',
  REVIEW = 'Review',
  REVIEWS_FEED = 'Reviews Feed',
  SEE_MORE_REVIEWS = 'See More Reviews',
  SIMILAR_OFFERS_FEED = 'Similar Offers Feed',
  SPECIALITY = 'Speciality',
  STEP = 'Step',
  TITLE = 'Title',
  TOP_COMPANIES_FEED = 'Top Companies Feed',
  TYPE = 'Type',
  URL = 'URL',
  USER = 'User',
  USER_ASSOCIATION_MODAL = 'User Association Modal',
  WRITE_A_REVIEW = 'Write A Review',
  YOU = 'You',
}
