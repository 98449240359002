import React, { FC } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import FadeInImage from '@src/components/FadeInImage';
import { CHAT_ROUTE, MATCH_ROUTE } from '@src/constants/routes';
import createLink from '@src/util/createLink';
import { htmlifyWithEscape } from '@src/util/htmlifyMessage';
import './styles.scss';

export const ChatsItem: FC = ({ item, className }) => {
  const isThread = item.chatType === 'thread';
  const url = createLink({
    link: isThread ? CHAT_ROUTE : MATCH_ROUTE,
    params: isThread ? { threadId: item._id } : { openingId: item.openingId },
  });

  return (
    <Link className={classnames('chats-item', item.unread && 'unread', className)} to={url}>
      {item.recruiter.avatarUrl ? (
        <FadeInImage className="chats-item-image" src={item.recruiter.avatarUrl} />
      ) : (
        <div className="chats-item-logo" style={{ backgroundColor: item.recruiter.avatarColor }}>
          {item.recruiter.nameInitials}
        </div>
      )}

      <div className="chats-item-content">
        <div className="chats-item-content-title">
          {item.companyName} - {item.position}
        </div>
        <div
          className="chats-item-content-message"
          dangerouslySetInnerHTML={{ __html: htmlifyWithEscape(item.lastMessage) }}
        />
        <div className="chats-item-content-recruiter">{item.recruiter.fullName}</div>
      </div>
    </Link>
  );
};

ChatsItem.propTypes = {
  className: PropTypes.string,
  item: PropTypes.object.isRequired,
};
