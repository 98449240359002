import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { JOB_OPENING_ROUTE } from '@src/constants/routes';
import FadeInImage from '@src/components/FadeInImage';
import { countryFlagUrl } from '@src/util/countryFlagImageUrl';
import { ClampedMessage } from '@src/components/ClampedMessage';
import { CompanyHighLights } from '@src/components/CompanyHighLights';
import createLink from '@src/util/createLink';
import { MainCardProps } from './props';
import './styles.scss';

export const MainCard: FC<MainCardProps> = ({ image, text, office, employeesAmount, financialDetails, openingId }) => {
  return (
    <Link
      to={createLink(JOB_OPENING_ROUTE, {
        params: { openingId },
      })}
      className="main-card"
    >
      <div className="main-card__body">
        <ClampedMessage className="main-card__text" shouldForceInline isClamped lineLimit={4} message={text || ''} />

        <CompanyHighLights
          countryFlagUrl={office ? countryFlagUrl(office.location?.country?.shortName) : undefined}
          employeesAmount={employeesAmount}
          financialDetails={financialDetails}
        />
      </div>

      <FadeInImage className="main-card__image" src={image} />
    </Link>
  );
};
