import React, { FC } from 'react';
import Svg from 'svg-inline-react';
import classnames from 'classnames';
import pluralize from 'pluralize';
import FrApprovedSvg from 'assets/images/fr-approved.svg';
import RejectedSvg from '@shared/assets/svgs/rejected.svg';
import FrReviewSvg from 'assets/images/fr-review.svg';
import { BlueButton } from '@src/components/Button';
import { Markdown } from '@src/components/Markdown';
import { UserVerificationStateEnum } from '@src/graphql/generated';
import { UserStatusDictionary } from './userStatus.interface';
import { Props } from './props';
import './styles.scss';

const USER_STATUS: UserStatusDictionary = {
  [UserVerificationStateEnum.PENDING]: {
    icon: FrReviewSvg,
    message:
      "We will review your profile in 72 hours. You will receive a notification when it's published.<br/><br/>Once published, your profile will be visible until you hide it.",
    statusTitle: 'In review',
  },
  [UserVerificationStateEnum.PUBLISHED]: {
    icon: FrApprovedSvg,
    message: 'Message from MeetFrank',
    statusTitle: 'Approved',
  },
  [UserVerificationStateEnum.REJECTED]: {
    icon: RejectedSvg,
    message: 'Message from MeetFrank',
    statusTitle: 'We couldn’t approve your profile',
  },
};

const UserStatus: FC<Props> = ({
  goBack,
  status = UserVerificationStateEnum.REJECTED,
  daysBetweenVerifications,
  message,
}) => {
  if (status === UserVerificationStateEnum.DRAFT) {
    return null;
  }

  const activeMessage = [UserVerificationStateEnum.PUBLISHED, UserVerificationStateEnum.REJECTED].includes(status)
    ? message
    : USER_STATUS[status]?.message || '';

  return (
    <div className="verified-user-status content-enter-animation">
      <div className="verified-user-status__content">
        <div className="verified-user-status__icon-wrapper">
          <div className="verified-user-status__icon-white-section">
            <div className="verified-user-status__icon-content">
              <Svg
                className={classnames(
                  'verified-user-status__icon',
                  `verified-user-status__icon--${status.toLowerCase()}`,
                )}
                src={USER_STATUS[status]?.icon}
              />
              <h3 className="verified-user-status__status-message">{USER_STATUS[status]?.statusTitle}</h3>
            </div>
          </div>
        </div>
        <div className="verified-user-status__info">
          <h2 className="verified-user-status__title">Thank you!</h2>
          <Markdown markdown={activeMessage} wrapperClassName="verified-user-status__message" />
        </div>
      </div>

      <div className="verified-user-status__action-wrapper">
        {status === UserVerificationStateEnum.REJECTED && (
          <span className="verified-user-status__reapply-message">
            You can reapply after {pluralize('day', daysBetweenVerifications, true)}.
          </span>
        )}
        <BlueButton className="verified-user-status__done" isFilled onClick={goBack}>
          Done
        </BlueButton>
      </div>
    </div>
  );
};

export default UserStatus;
