import React, { useEffect } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { LocationAutocomplete } from '@src/components/LocationAutocomplete';
import { FixedHeader } from '@src/components/FixedHeader';
import { useAppDispatch } from '@src/store';
import { setNavigationVisibility } from '@src/redux/actions/navigation';
import { ActionButtons } from '../ActionButtons';
import './styles.scss';

export const LocationSearch = ({ onNext, onClear, onLocationSelect, defaultLocation, goBack }) => {
  const dispatch = useAppDispatch();

  function handleLocationSelect(location) {
    if (!location) {
      onLocationSelect(defaultLocation);
    } else {
      onLocationSelect(location);
    }
  }

  useEffect(() => {
    dispatch(setNavigationVisibility(false));

    return () => {
      dispatch(setNavigationVisibility(true));
    };
  }, []);

  return (
    <div className="location-search fade-in">
      <FixedHeader
        disableOpacity
        onBack={goBack}
        title="Where would you like to work?"
        wrapperClassName="location-search__header"
      />

      <div className="location-search__body">
        <LocationAutocomplete
          defaultValue={get(defaultLocation, 'longName', '')}
          inputPlaceHolder="Search cities"
          selectLocation={handleLocationSelect}
          showClear
        />
      </div>

      <div className="location-search__footer">
        <ActionButtons
          blueButtonProps={{
            onClick: onNext,
          }}
          invisibleButtonProps={{
            onClick: onClear,
          }}
        />
      </div>
    </div>
  );
};

LocationSearch.propTypes = {
  defaultLocation: PropTypes.object,
  goBack: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  onLocationSelect: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};
