import React, { FC } from 'react';
import Svg from 'svg-inline-react';
import UserSvg from 'assets/images/fr-user.svg';
import BriefcaseSvg from 'assets/images/fr-briefcase.svg';
import ForwardSvg from 'assets/images/fr-forward.svg';
import { GreyButton } from '@src/components/Button';
import { UserCompanyAssociationTypeEnum } from '@src/graphql/generated';
import { UserAssociationModalProps } from './props';
import './styles.scss';

export const UserAssociationModal: FC<UserAssociationModalProps> = ({ onClose, onStatusClick }) => {
  return (
    <div className="user-association-modal">
      <div className="user-association-modal__header">
        <h3>Do you work here?</h3>
        <p>We try to keep reviews accurate by limiting it to employees only</p>
      </div>
      <div className="user-association-modal__actions">
        <div
          className="user-association-modal__action-item"
          onClick={() => onStatusClick(UserCompanyAssociationTypeEnum.WORKED)}
        >
          <div className="user-association-modal__left-section">
            <Svg src={UserSvg} />
            <span>I am a former employee</span>
          </div>
          <Svg src={ForwardSvg} />
        </div>

        <div
          className="user-association-modal__action-item"
          onClick={() => onStatusClick(UserCompanyAssociationTypeEnum.WORKING)}
        >
          <div className="user-association-modal__left-section">
            <Svg src={BriefcaseSvg} />
            <span>I currently work here </span>
          </div>
          <Svg src={ForwardSvg} />
        </div>
      </div>
      <p className="user-association-modal__message">No visible link to your profile will be created.</p>
      <GreyButton onClick={onClose}>Cancel</GreyButton>
    </div>
  );
};
