import React, { Fragment } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { get, isEmpty, size } from 'lodash';
import Svg from 'svg-inline-react';
import ExpandSvg from 'assets/images/fr-expand.svg';
import { HorizontalScroller } from '@src/components/HorizontalScroller';
import { Label } from '@src/components/Label';
import { SEARCH_TAGS, GLOBAL_AREA_ID } from '@src/constants';
import { OpeningRemoteTypeEnum, SearchOpeningEntityTagNameEnum } from '@src/graphql/generated';
import { SearchResultFilters } from '../enums/filters';
import { SearchResultFilter } from './interfaces/searchResultFilter.interface';
import './styles.scss';

export const SEARCH_RESULT_FILTERS_KEYS = {
  LOCATION: 'LOCATION',
  SENIORITIES: 'SENIORITIES',
  SKILLS: 'SKILLS',
};

export const SEARCH_RESULT_FILTERS: SearchResultFilter[] = [
  {
    key: SEARCH_RESULT_FILTERS_KEYS.LOCATION,
    label: 'Location',
  },
  {
    key: SEARCH_RESULT_FILTERS_KEYS.SENIORITIES,
    label: 'Seniorities',
  },
  {
    key: SEARCH_RESULT_FILTERS_KEYS.SKILLS,
    label: 'Skills',
  },
];

const getCorrectLabel = (filterKey, selectedFilters, searchTags) => {
  switch (filterKey) {
    case SEARCH_RESULT_FILTERS_KEYS.LOCATION:
    case SearchResultFilters.LOCATION:
      const isRemote = searchTags.find(searchTag =>
        [
          SearchOpeningEntityTagNameEnum.REMOTE_ALLOWED_COUNTRY,
          SearchOpeningEntityTagNameEnum.REMOTE_ALLOWED_AREA,
        ].includes(searchTag.tag),
      );

      if (isRemote) {
        const area = get(selectedFilters, 'area');
        const country = get(selectedFilters, 'country');

        if (area) {
          return {
            isActive: true,
            label: area.id === GLOBAL_AREA_ID ? 'Remote in Anywhere' : `Remote in ${area.longName}`,
          };
        } else if (country) {
          return {
            isActive: true,
            label: `Remote in ${country.shortName}`,
          };
        }
      }

      const location = get(selectedFilters, 'location');
      const remoteTag = searchTags.filter(searchTag => searchTag.tag === SearchOpeningEntityTagNameEnum.REMOTE_TYPE);

      // In case Hybrid is selected
      if (get(remoteTag, '[0].value') === OpeningRemoteTypeEnum.HYBRID && location) {
        return {
          isActive: true,
          label: `Remote and ${location.shortName}`,
        };
      } else if ((isEmpty(remoteTag) || get(remoteTag, '[0].value') === OpeningRemoteTypeEnum.ONSITE) && location) {
        return {
          isActive: true,
          label: location.shortName,
        };
      }

      return {
        label: SEARCH_RESULT_FILTERS.find(filter => filter.key === SearchResultFilters.LOCATION).label,
      };
    case SEARCH_RESULT_FILTERS_KEYS.SKILLS:
      const selectedSkillName = get(selectedFilters, 'skill.name');
      const selectedSkills = searchTags.filter(filter => filter.tag === SEARCH_TAGS.SKILL);

      if (!isEmpty(selectedSkillName)) {
        return {
          isActive: true,
          label:
            size(selectedSkills) > 1 ? `${selectedSkillName} +${size(selectedSkills) - 1}` : `${selectedSkillName}`,
        };
      }

      return {
        label: SEARCH_RESULT_FILTERS.find(filter => filter.key === SEARCH_RESULT_FILTERS_KEYS.SKILLS).label,
      };
    case SEARCH_RESULT_FILTERS_KEYS.SENIORITIES:
      const selectedSeniorityName = get(selectedFilters, 'seniority.name');
      const selectedSeniorities = searchTags.filter(filter => filter.tag === SEARCH_TAGS.SENIORITY);

      if (!isEmpty(selectedSeniorityName)) {
        return {
          isActive: true,
          label:
            size(selectedSeniorities) > 1
              ? `${selectedSeniorityName} +${size(selectedSeniorities) - 1}`
              : `${selectedSeniorityName}`,
        };
      }

      return {
        label: SEARCH_RESULT_FILTERS.find(filter => filter.key === SEARCH_RESULT_FILTERS_KEYS.SENIORITIES).label,
      };
    default:
      return { isActive: false, label: '' };
  }
};

export const Filters = ({ wrapperClassName, searchTags, loading, filterLabels, onFilterClick }) => {
  return (
    <HorizontalScroller
      className={classnames('search-result-filters', wrapperClassName)}
      containerClassName="search-result-filters__container"
    >
      {loading && (
        <Fragment>
          <Label className="search-result-filters__label search-result-filters__label--loading pulse-fade" />
          <Label className="search-result-filters__label search-result-filters__label--loading pulse-fade" />
          <Label className="search-result-filters__label search-result-filters__label--loading pulse-fade" />
        </Fragment>
      )}
      {!loading &&
        Object.values(SEARCH_RESULT_FILTERS).map(filter => {
          const currentFilter = getCorrectLabel(filter.key, filterLabels, searchTags);

          return (
            <Label
              className={classnames(
                'search-result-filters__label',
                currentFilter.isActive && 'search-result-filters__label--active',
              )}
              key={filter.key}
              onClick={() => onFilterClick(filter)}
              text={
                <Fragment>
                  {get(currentFilter, 'label')}
                  <Svg className="search-result-filters__label-icon" src={ExpandSvg} />
                </Fragment>
              }
            />
          );
        })}
    </HorizontalScroller>
  );
};

Filters.propTypes = {
  filterLabels: PropTypes.shape({
    area: PropTypes.object,
    country: PropTypes.object,
    interest: PropTypes.object,
    location: PropTypes.object,
    seniority: PropTypes.object,
    skill: PropTypes.object,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  onFilterClick: PropTypes.func.isRequired,
  openFilter: PropTypes.shape({
    key: PropTypes.oneOf(Object.values(SEARCH_RESULT_FILTERS_KEYS)).isRequired,
    value: PropTypes.string,
  }).isRequired,
  searchTags: PropTypes.array,
  wrapperClassName: PropTypes.string,
};
