import React, { FC, useEffect } from 'react';
import { find } from 'lodash';
import { matchPath, useHistory } from 'react-router-dom';
import { SubPageAppBar } from '@src/components/PrimaryAppBar';
import { COMPANY_ABOUT_ROUTE, COMPANY_ROUTE, JOB_OPENING_ROUTE } from '@src/constants/routes';
import { setScroll } from '@src/redux/actions/scroll';
import { useAppDispatch, useAppSelector } from '@src/store';
import { getPreviousLocation } from '@src/redux/selectors/navigation';
import { getScroll } from '@src/redux/selectors/scroll';
import { PageNames } from '@src/redux/selectors/scroll/enums/pageNames.enum';
import { getWrapperScrollableContainer } from '@src/App';
import { JobOpeningFeedItem } from '@src/containers/Feed/JobOpeningFeedItem';
import { FeedItemSkeleton } from '@src/containers/Feed/Skeleton';
import { Context } from '@src/metrics/enums/context.enum';
import { EmptyState } from '@src/components/EmptyState';
import { useForYouFeed } from './hooks/useRelevantOffersFeed';
import './styles.scss';

export const ForYou: FC = () => {
  const previousLocation = useAppSelector(getPreviousLocation);
  const history = useHistory();
  const scrolls = useAppSelector(getScroll);
  const dispatch = useAppDispatch();

  const getScrollableContainer = () => {
    const wrapperScrollableContainer = getWrapperScrollableContainer();

    return wrapperScrollableContainer[0];
  };

  const {
    feed = [],
    isLoading: isFeedLoading,
    isInitialLoading,
  } = useForYouFeed({
    getScrollableContainer: getScrollableContainer,
  });

  const scroll = scrolls?.[PageNames.FOR_YOU] || 0;

  useEffect(() => {
    const scrollableContainer = getScrollableContainer();

    const isPrevRouteCompany =
      previousLocation &&
      find([COMPANY_ROUTE, COMPANY_ABOUT_ROUTE, JOB_OPENING_ROUTE], route =>
        matchPath(previousLocation, { path: route }),
      );

    if (scrollableContainer) {
      scrollableContainer.scrollTop = isPrevRouteCompany && scroll ? scroll : 0;
    }

    return () => {
      dispatch(setScroll(PageNames.FOR_YOU, scrollableContainer.scrollTop));
    };
  }, []);

  return (
    <div className="for-you fade-in">
      <SubPageAppBar actions={[]} isFixed onBack={() => history.goBack()}>
        <div className="for-you__app-bar-title">For You</div>
      </SubPageAppBar>

      {!isInitialLoading && feed.length <= 0 ? (
        <div className="job-feed">
          <div className="job-feed__empty-state">
            <EmptyState
              imageUrl="/images/empty-states/empty-state-1.png"
              subTitle="There aren’t any offers that match perfectly at the moment. Explore more in the jobs feed."
              subTitleClassName="job-feed__empty-state-subtitle"
              title="No offers to suggest"
              titleClassName="job-feed__empty-state-title"
            />
          </div>
        </div>
      ) : (
        <div className="for-you__list">
          <div className="feed-bg-color">
            {!isInitialLoading &&
              feed.map(opening => {
                return (
                  <JobOpeningFeedItem
                    isMatchShown
                    key={opening.id}
                    metricsContext={Context.FOR_YOU}
                    opening={opening}
                  />
                );
              })}

            {isFeedLoading && <FeedItemSkeleton />}
          </div>
        </div>
      )}
    </div>
  );
};
