import React, { FC } from 'react';
import classnames from 'classnames';
import Svg from 'svg-inline-react';
import FrBookmarkSvg from 'assets/images/fr-bookmark.svg';
import { BookmarkProps } from './props';
import './styles.scss';

export const Bookmark: FC<BookmarkProps> = ({ hasBookmarked, onBookmark }) => {
  return (
    <div
      className={classnames('opening-view-bookmark', hasBookmarked && 'opening-view-bookmark--blue')}
      onClick={onBookmark}
    >
      <Svg className="opening-view-bookmark__icon" src={FrBookmarkSvg} />
      <span className="opening-view-bookmark__label">{hasBookmarked ? 'Saved' : 'Save'}</span>
    </div>
  );
};
