import React, { FC } from 'react';
import classnames from 'classnames';
import FadeInImage from '@src/components/FadeInImage';
import { STATIC_MEDIA_PREFIX } from '@src/constants';
import { EmptyStateProps } from './props';
import './styles.scss';

export const EmptyState: FC<EmptyStateProps> = ({
  title,
  subTitle,
  imageUrl,
  wrapperClassName,
  imageWrapperClassName,
  imageClassName,
  infoClassName,
  titleClassName,
  subTitleClassName,
}) => {
  return (
    <div className={classnames('empty-state', wrapperClassName)}>
      <FadeInImage
        imageClassName={classnames('empty-state__image', imageClassName)}
        src={`${STATIC_MEDIA_PREFIX}${imageUrl}`}
        wrapperClassName={classnames('empty-state__image-wrapper', imageWrapperClassName)}
      />
      <div className={classnames('empty-state__info', infoClassName)}>
        <h3 className={classnames('empty-state__title', titleClassName)}>{title}</h3>
        <p className={classnames('empty-state__sub-title', subTitleClassName)}>{subTitle}</p>
      </div>
    </div>
  );
};
