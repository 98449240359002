export const REVIEW = [
  {
    action: { active: true, skippable: true, type: 'continue', value: 'Skip' },
    content: [
      { type: 'chat-bubble', value: '👍 The Good?' },
      { type: 'chat-bubble', value: 'Tell us more: what do you like about the company?' },
      {
        key: 'positiveFeedback',
        type: 'chat-textarea',
        value: 'Write your review...',
      },
    ],
    key: 'positiveFeedback',
    type: 'onboarding',
  },
  {
    action: { active: true, skippable: true, type: 'continue', value: 'Skip' },
    content: [
      { type: 'chat-bubble', value: '👎 The Challenging?' },
      { type: 'chat-bubble', value: 'Share anything that bothers you...' },
      {
        key: 'negativeFeedback',
        type: 'chat-textarea',
        value: 'Write your review...',
      },
    ],
    key: 'negativeFeedback',
    type: 'onboarding',
  },
  {
    action: { active: true, skippable: true, type: 'continue', value: 'Post' },
    content: [
      { type: 'chat-bubble', value: 'Add a title for your Review' },
      { inputType: 'text', key: 'title', type: 'chat-input', value: 'Summarize your review in a few words' },
      { key: 'isAnonymous', type: 'toggle', value: 'Show your name' },
    ],
    key: 'title',
    type: 'onboarding',
  },
];
