import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import FadeInImage from '@src/components/FadeInImage';
import Back from '@src/components/Back';
import { SecondaryAppBar } from '@src/components/SecondaryAppBar';
import { BaseParallaxPage } from '../BaseParallaxPage';
import './styles.scss';

export function SecondaryLogoParallaxPage({
  isBackDisabled,
  loading,
  bannerImage,
  logoImage,
  children,
  topContent,
  hasContentPadding,
  getScrollableContainer,
  withBackButtonOnly,
  title,
  actions,
}) {
  return (
    <BaseParallaxPage
      bannerImage={bannerImage}
      children={children}
      getScrollableContainer={getScrollableContainer}
      hasBannerImageGradient
      hasContentPadding={hasContentPadding}
      isLoading={loading}
      topContent={
        <Fragment>
          {!loading && !isBackDisabled && withBackButtonOnly && (
            <Back className="secondary-logo-parallax-page__back" showWithAnimation={false} />
          )}

          {!loading && !isBackDisabled && !withBackButtonOnly && <SecondaryAppBar actions={actions} title={title} />}

          <div className="secondary-logo-parallax-page__logo-container">
            {loading ? (
              <div className="secondary-logo-parallax-page__logo-wrapper secondary-logo-parallax-page__logo-wrapper--skeleton" />
            ) : (
              <FadeInImage
                imageClassName="secondary-logo-parallax-page__logo"
                src={logoImage}
                wrapperClassName="secondary-logo-parallax-page__logo-wrapper"
              />
            )}
          </div>
          {topContent}
        </Fragment>
      }
    />
  );
}

SecondaryLogoParallaxPage.defaultProps = {
  withBackButtonOnly: true,
};

SecondaryLogoParallaxPage.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.node.isRequired,
      iconClassName: PropTypes.string,
      onOpen: PropTypes.func.isRequired,
    }),
  ),
  bannerImage: PropTypes.string,
  children: PropTypes.node.isRequired,
  getScrollableContainer: PropTypes.func,
  hasContentPadding: PropTypes.bool,
  isBackDisabled: PropTypes.bool,
  loading: PropTypes.bool,

  logoImage: PropTypes.string,

  // Title, Actions are required in case withBackButtonOnly is false.
  title: PropTypes.string,

  topContent: PropTypes.node,
  withBackButtonOnly: PropTypes.bool.isRequired,
};
