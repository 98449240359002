import { datadogRum } from '@datadog/browser-rum';
import { apolloClient } from '@src/graphql/apollo';
import { IpDetailsDocument, IpDetailsQuery } from '@src/graphql/generated';
import { isPreviewMode } from '@src/util/mode';
import { captureError } from '@src/util/errors';
import { TrackEventParamsInterface } from './interfaces/trackEventParams.interface';
import { TrackPageViewParamsInterface } from './interfaces/trackPageViewParams.interface';
import { TrackRegisterParamsInterface } from './interfaces/trackRegisterParams.interface';

declare global {
  interface Window {
    dataLayer: unknown[];
  }
}

const BUILD_VERSION = process.env.BUILD_VERSION;
const BUILD_TIMESTAMP = process.env.BUILD_TIMESTAMP;

async function areMetricsEnabled(): Promise<boolean> {
  try {
    const result = await apolloClient.query<IpDetailsQuery>({
      fetchPolicy: 'cache-first',
      query: IpDetailsDocument,
    });

    if (!result) {
      return false;
    }

    const {
      data: { ipDetails },
    } = result;

    return !!ipDetails.isRealUser && process.env.ENV === 'production' && !isPreviewMode();
  } catch (error) {
    captureError(error);

    return process.env.ENV === 'production' && !isPreviewMode();
  }
}

function pushToDataLayer(value: unknown): void {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(value);
}

export async function trackEvent(params: TrackEventParamsInterface): Promise<void> {
  if (await areMetricsEnabled()) {
    pushToDataLayer({ event: 'app-universal-event', ...params });

    return;
  }

  // eslint-disable-next-line no-console
  console.log('metrics.trackEvent', params);
}

export async function trackPageView(params: TrackPageViewParamsInterface): Promise<void> {
  if (await areMetricsEnabled()) {
    pushToDataLayer({ event: 'app-page-view', ...params });
    datadogRum.startView(params.pagePath);

    return;
  }

  // eslint-disable-next-line no-console
  console.log('metrics.trackPageView', params);
}

export async function trackRegister(params: TrackRegisterParamsInterface): Promise<void> {
  if (await areMetricsEnabled()) {
    pushToDataLayer({ event: 'app-universal-register', ...params });

    return;
  }

  // eslint-disable-next-line no-console
  console.log('metrics.trackRegister', params);
}

export async function trackRegisterDatadogUser(
  params: TrackRegisterParamsInterface & Record<string, unknown>,
): Promise<void> {
  if (await areMetricsEnabled()) {
    datadogRum.setUser(params);

    return;
  }

  // eslint-disable-next-line no-console
  console.log('metrics.registerDatadogUser', params);
}

export async function trackSuperProperties(params: Record<string, unknown>): Promise<void> {
  params.payload = {
    ...params,
    BUILD_TIMESTAMP,
    BUILD_VERSION,
  };

  if (await areMetricsEnabled()) {
    pushToDataLayer({ event: 'app-super-properties', ...params });

    return;
  }

  // eslint-disable-next-line no-console
  console.log('metrics.trackSuperProperties', params);
}
