import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { range } from 'lodash';
import './styles.scss';

export const SectionHeaderSubTitleSkeleton = ({ count }) => {
  return (
    <Fragment>
      {range(count).map(i => (
        <div className="section-header-skeleton-subtitle pulse-fade" key={i} />
      ))}
    </Fragment>
  );
};

SectionHeaderSubTitleSkeleton.defaultProps = {
  count: 1,
};

SectionHeaderSubTitleSkeleton.propTypes = {
  count: PropTypes.number,
};
