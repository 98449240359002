/**
 * Caluculate value progress between given boundries
 * Examples o fusage
 * rangePercent(0.75, [ 0.5, 1 ]) = 0.5
 * rangePercent(0.9, [ 0.5, 1 ]) = 0.8
 *
 * @returns {number} - range value percent
 */
export function rangePercent(value, valueRangeBoundries) {
  const [minValue, maxValue] = valueRangeBoundries;

  if (minValue > value) {
    return 0;
  }

  return (value - minValue) / (maxValue - minValue);
}
