export const RATING_LABELS = {
  companyCulture: {
    key: 'companyCulture',
    label: 'Company culture',
  },
  compensationBenefits: {
    key: 'compensationBenefits',
    label: 'Compensation benefits',
  },
  management: {
    key: 'management',
    label: 'Management',
  },
  product: {
    key: 'product',
    label: 'Product/service',
  },
  workLifeBalance: {
    key: 'workLifeBalance',
    label: 'Work-life balance',
  },
};
