import React, { FC } from 'react';
import FrPeopleSvg from 'assets/images/fr-people.svg';
import { SwitchToggle } from '@src/components/SwitchToggle';
import { RoundedIcon } from '../RoundedIcon';
import { AssistantStatusProps } from './props';
import './styles.scss';

export const AssistantStatus: FC<AssistantStatusProps> = ({ isOn, isDisabled, onToggle, reactivationDate }) => {
  return (
    <div className="vt-assistant-status">
      <RoundedIcon src={FrPeopleSvg} />

      <div className="vt-assistant-status__details">
        <div>
          Profile status: <strong>{isOn ? 'Visible' : 'Anonymous'}</strong>
        </div>

        {!!reactivationDate && (
          <div>
            Apply again from <strong>{reactivationDate}</strong>
          </div>
        )}
      </div>

      <div className="vt-assistant-status__switch">
        <SwitchToggle checked={isOn} disabled={isDisabled} onChange={() => onToggle()} />
      </div>
    </div>
  );
};
