import { useEffect, useLayoutEffect } from 'react';
import { NetworkStatus } from '@apollo/client';
import { throttle } from 'lodash';
import { recordSignals } from '@src/graphql/signals/actions/recordSignals';
import { useFeedUpdate } from '@src/customHooks';
import {
  GetSearchOpeningsLatestFeedDocument,
  GetSearchRelevantOpeningsV2QueryVariables,
  OpeningRemoteTypeEnum,
  RelevantOpeningsSortOptionEnum,
  SearchOpeningEntityTagNameEnum,
  SignalEntityTypeEnum,
  SignalTypeEnum,
  useGetSearchRelevantOpeningsV2Query,
  useGetUserQuery,
} from '@src/graphql/generated';
import { isOpeningType } from '@src/graphql/typeGuards';
import { Context } from '@src/metrics/enums/context.enum';
import { Interaction } from '@src/metrics/enums/interaction.enum';
import { ItemType } from '@src/metrics/enums/itemType.enum';
import { OPENING_STATE } from '@src/constants';
import { trackEvent } from '@src/metrics';
import { UseRelevantOffersFeedParams, UseRelevantOffersFeedValue } from './interfaces/useRelevantOffersFeed.interface';

const FIRST_BATCH_SIZE = 12;
const NEXT_BATCH_SIZE = 6;

export const useForYouFeed = ({
  getScrollableContainer,
  openingState = OPENING_STATE.ACTIVE,
  sortOption = RelevantOpeningsSortOptionEnum.RECENCY,
}: UseRelevantOffersFeedParams): UseRelevantOffersFeedValue => {
  const { data: userData } = useGetUserQuery({ fetchPolicy: 'cache-first' });
  const user = userData?.currentUser;

  const getSearchRelevantOpeningsQueryVariables: GetSearchRelevantOpeningsV2QueryVariables = {
    searchTags: [
      {
        tag: SearchOpeningEntityTagNameEnum.STATE,
        value: openingState,
      },
    ],
    searchTagsWithOrCondition: [
      {
        tag: SearchOpeningEntityTagNameEnum.POSITION_COUNTRY,
        value: user?.location?.country?.id,
      },
      {
        tag: SearchOpeningEntityTagNameEnum.REMOTE_TYPE,
        value: OpeningRemoteTypeEnum.FULLY,
      },
    ],
    sortedPaginationOptions: {
      limit: FIRST_BATCH_SIZE,
      sortBy: sortOption,
    },
  };

  const {
    data: searchRelevantOpeningsData,
    loading: isSearchRelevantOpeningsLoading,
    fetchMore: searchRelevantOpeningsFetchMore,
    refetch: searchRelevantOpeningsRefetch,
    networkStatus: searchRelevantOpeningsNetworkStatus,
  } = useGetSearchRelevantOpeningsV2Query({
    notifyOnNetworkStatusChange: true,
    variables: getSearchRelevantOpeningsQueryVariables,
  });

  const pagination = searchRelevantOpeningsData?.searchRelevantOpenings?.searchResultsV2.pagination;

  const feed = searchRelevantOpeningsData?.searchRelevantOpenings?.searchResultsV2.result || [];

  const initialLoadingStates = [NetworkStatus.loading, NetworkStatus.setVariables, NetworkStatus.refetch];

  const isInitialLoading = initialLoadingStates.includes(searchRelevantOpeningsNetworkStatus);

  const isLoading = isSearchRelevantOpeningsLoading;

  const { checkNewContent } = useFeedUpdate(
    GetSearchOpeningsLatestFeedDocument,
    getSearchRelevantOpeningsQueryVariables,
    'searchRelevantOpenings.searchResults.result',
    searchRelevantOpeningsRefetch,
    getScrollableContainer(),
  );

  useEffect(() => {
    if (pagination?.minCursor) {
      checkNewContent(pagination.minCursor, feed);
    }
  }, []);

  useLayoutEffect(() => {
    const sendMetricAndSignal = (currentLimit: number, feedLength: number) => {
      recordSignals({
        currentLimit: currentLimit,
        items: feed
          ?.filter(opening => !!opening?.id && isOpeningType(opening))
          .map(item => ({
            id: item?.id as string,
            type: SignalEntityTypeEnum.OPENING,
          })),
        signalType: SignalTypeEnum.VIEW_FEED_ITEM,
      });

      trackEvent({
        context: Context.FOR_YOU,
        interaction: Interaction.FETCH_MORE,
        itemType: ItemType.DIGEST_FEED,
        itemValue: feedLength,
      });
    };

    const fetchMoreSearchRelevantOpenings = async () => {
      const { data: fetchMoreResult } = await searchRelevantOpeningsFetchMore({
        variables: {
          sortedPaginationOptions: {
            limit: NEXT_BATCH_SIZE,
            minCursor: pagination?.maxCursor,
            sortBy: sortOption,
          },
        },
      });

      const result = [...feed, ...(fetchMoreResult?.searchRelevantOpenings?.searchResultsV2.result || [])];

      sendMetricAndSignal(
        fetchMoreResult?.searchRelevantOpenings?.searchResultsV2.pagination.limit || 0,
        result.length,
      );
    };

    const loadMore = throttle(async ({ target: { scrollTop, scrollHeight } }) => {
      const fetchMoreMargin = scrollTop + window.innerHeight * 3;

      if (!isSearchRelevantOpeningsLoading && pagination?.hasMoreResults && fetchMoreMargin > scrollHeight) {
        await fetchMoreSearchRelevantOpenings();
      }
    }, 200);

    getScrollableContainer().addEventListener('scroll', loadMore);

    return () => {
      getScrollableContainer().removeEventListener('scroll', loadMore);
      loadMore.cancel();
    };
  }, [isLoading, pagination]);

  return {
    feed: feed,
    isInitialLoading: isInitialLoading,
    isLoading: isSearchRelevantOpeningsLoading,
    pagination: pagination,
  };
};
