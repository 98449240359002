import React, { FC } from 'react';
import { orderBy } from 'lodash';
import SelectList from '@src/components/SelectList';
import { GetSpecialitiesQuery, useGetSpecialitiesQuery } from '@src/graphql/generated';
import { mapItem } from '@src/util/createStory';
import { TrackProps } from './props';

export const Track: FC<TrackProps> = ({ defaultValue, onSelect }) => {
  const { data, loading: isLoading } = useGetSpecialitiesQuery();

  const sortSpecialities = (
    specialities: GetSpecialitiesQuery['specialities'],
  ): GetSpecialitiesQuery['specialities'] => {
    return orderBy(
      specialities,
      [
        speciality =>
          speciality?.name
            // Remove text in brackets, like (Tech), as they would be sorted to the top due to the alphabetic sorting
            .replace(/^\(.+\)\s+/, ''),
      ],
      ['asc'],
    );
  };

  const sortedSpecialities = data?.specialities ? sortSpecialities(data?.specialities).map(mapItem) : [];

  if (isLoading) {
    return <span className="availability-request-loading">Loading...</span>;
  }

  return (
    <SelectList
      className="slide-up-item"
      defaultValue={defaultValue}
      onSelect={onSelect}
      options={sortedSpecialities}
      withoutDebounce
    />
  );
};
