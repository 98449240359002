import React, { FC, useEffect } from 'react';
import { trackEvent } from '@src/metrics';
import { InvisibleButton } from '@src/components/Button';
import { Rating } from '@src/components/Rating';
import { Context } from '@src/metrics/enums/context.enum';
import { Interaction } from '@src/metrics/enums/interaction.enum';
import { RatingColor } from '../Rating/enums/ratingColor.enum';
import { RatingSize } from '../Rating/enums/ratingSize.enum';
import { UserCompanyReviewModalProps } from './props';
import './styles.scss';

export const UserCompanyReviewModal: FC<UserCompanyReviewModalProps> = ({ onNotInterestedClick, onRatingSet }) => {
  useEffect(() => {
    trackEvent({
      context: Context.COMPANY_REVIEWS,
      interaction: Interaction.DO_YOU_ENJOY_WORKING_MODAL,
    });
  }, []);

  return (
    <div className="review-own-company-modal">
      <h1 className="review-own-company-modal__title">How do you enjoy working at your current employer?</h1>

      <p className="review-own-company-modal__sub-title">
        Rate your company to read what people are <br />
        saying about it.
        <br />
        <br />
        You can make it anonymous.
      </p>

      <div className="review-own-company-modal__rating-wrapper">
        <Rating
          activeColor={RatingColor.BLUE}
          activeRating={0}
          maxRating={5}
          setActiveRating={onRatingSet}
          size={RatingSize.LARGE}
        />
      </div>

      <InvisibleButton onClick={onNotInterestedClick}>Not interested</InvisibleButton>
    </div>
  );
};
