import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Svg from 'svg-inline-react';
import FrBackSvg from 'assets/images/fr-chevron-left.svg';
import { BaseAppBar } from '@src/components/PrimaryAppBar';
import './styles.scss';

export const SubPageAppBar = ({
  isDark,
  isFixed,
  isDefaultActionsHidden,
  wrapperClassName,
  children,
  isFrankBannerVisible,
  actions,
  onBack,
}) => {
  return (
    <BaseAppBar
      actions={actions}
      isDark={isDark}
      isDefaultActionsHidden={isDefaultActionsHidden}
      isFixed={isFixed}
      isFrankBannerVisible={isFrankBannerVisible}
      leadingEl={
        onBack && (
          <Svg
            className={classnames('sub-page-app-bar__back-icon', {
              'sub-page-app-bar__back-icon--dark': isDark,
            })}
            onClick={onBack}
            src={FrBackSvg}
          />
        )
      }
      wrapperClassName={classnames('sub-page-app-bar', wrapperClassName)}
    >
      {children}
    </BaseAppBar>
  );
};

SubPageAppBar.propTypes = {
  actions: PropTypes.array,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  isDark: PropTypes.bool,
  isDefaultActionsHidden: PropTypes.bool,
  isFixed: PropTypes.bool,
  isFrankBannerVisible: PropTypes.bool,
  onBack: PropTypes.func,
  wrapperClassName: PropTypes.string,
};
