import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import OpeningDetailsDataPush from '@src/containers/PreviewPages/OpeningDetailsDataPush';
import CompanyProfileDataPush from '@src/containers/PreviewPages/CompanyProfileDataPush';
import Question from '@src/containers/PreviewPages/QuestionDataPush';
import {
  OPENING_PREVIEW_ROUTE,
  COMPANY_PREVIEW_ROUTE,
  QUESTION_PREVIEW_ROUTE,
  PITCH_PREVIEW_ROUTE,
} from '@src/constants/routes';
import { PitchPreview } from '@src/containers/PreviewPages/PitchPreview';

export const Routes: FC = () => {
  return (
    <Switch>
      <Route exact path={OPENING_PREVIEW_ROUTE}>
        <OpeningDetailsDataPush />
      </Route>

      <Route exact path={COMPANY_PREVIEW_ROUTE}>
        <CompanyProfileDataPush />
      </Route>

      <Route exact path={QUESTION_PREVIEW_ROUTE}>
        <Question />
      </Route>

      <Route exact path={PITCH_PREVIEW_ROUTE}>
        <PitchPreview />
      </Route>
    </Switch>
  );
};
