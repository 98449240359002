import React, { FC } from 'react';
import qs from 'qs';
import { Route, Switch, useLocation, Redirect } from 'react-router-dom';
import { Chat } from '@src/views/Chat';
import Match from '@src/views/Match';
import { MainViewWrapper } from '@src/views/MainView/Wrapper';
import { Inbox } from '@src/views/Inbox';
import { NotificationCenter } from '@src/views/NotificationCenter';
import { InboxProfile } from '@src/views/InboxProfile';
import ProfileRemove from '@src/views/ProfileRemove';
import { Settings } from '@src/views/Settings';
import Hire from '@src/views/Settings/Hire';
import Contact from '@src/views/Settings/Contact';
import Feedback from '@src/views/Settings/Feedback';
import Filled from '@src/views/Filled';
import { CompanyProfile } from '@src/views/CompanyProfile';
import { Menu } from '@src/views/Menu';
import { Frank } from '@src/views/Frank';
import { Jobs } from '@src/views/Jobs';
import { JobOpening } from '@src/views/JobOpening';
import { CompanyReviews } from '@src/views/CompanyReviews';
import { MyBookmarks } from '@src/views/MyBookmarks';
import { Search } from '@src/views/Search';
import { SearchResult } from '@src/views/SearchResult';
import { CreateCompanyReview } from '@src/views/CreateCompanyReview';
import { FindYourCompany } from '@src/views/FindYourCompany';
import { VerifiedUser } from '@src/views/VerifiedUser';
import { VerifiedUserQuestionnaire } from '@src/views/VerifiedUserQuestionnaire';
import { FrankRefreshing } from '@src/components/FrankRefreshing';
import createLink from '@src/util/createLink';
import { MessageRequests } from '@src/views/MessageRequests';
import {
  INDEX_ROUTE,
  MESSAGING_ROUTE,
  INBOX_ROUTE,
  CHAT_ROUTE,
  MATCH_ROUTE,
  COMPANY_ROUTE,
  PROFILE_ROUTE,
  PROFILE_REMOVE_ROUTE,
  INBOX_PROFILE_ROUTE,
  LOADING_ROUTE,
  NOTIFICATION_CENTER_ROUTE,
  SEARCH_ROUTE,
  SEARCH_RESULT_ROUTE,
  JOB_OPENING_ROUTE,
  FILLED_ROUTE,
  VERIFIED_USER_ROUTE,
  VERIFIED_USER_QUESTIONNAIRE_ROUTE,
  FIND_YOUR_COMPANY_ROUTE,
  COMPANY_REVIEWS_ROUTE,
  CREATE_COMPANY_REVIEW_ROUTE,
  YOUR_MARKET_OVERVIEW_ROUTE,
  MY_BOOKMARKS_ROUTE,
  SETTINGS_ROUTE,
  SETTINGS_HIRE_ROUTE,
  SETTINGS_CONTACT_ROUTE,
  SETTINGS_FEEDBACK_ROUTE,
  MENU_ROUTE,
  JOBS_ROUTE_OLD,
  FRANK_ROUTE,
  MESSAGE_REQUESTS_ROUTE,
  ACTIVE_COMPANIES_ROUTE,
  REVIEWS_ROUTE,
  TOP_COMPANIES_ROUTE,
  JOBS_SEARCH_ROUTE,
  JOBS_ROUTE,
  ARTICLE_ROUTE,
  FOR_YOU_ROUTE,
  ARTICLES_ROUTE,
} from '@src/constants/routes';
import { ActiveCompanies } from '@src/views/ActiveCompanies';
import { Reviews } from '@src/views/Reviews';
import { TopCompanies } from '@src/views/TopCompanies';
import { JobsSearch } from '@src/views/JobsSearch';
import { useIpDetailsQuery } from '@src/graphql/generated';
import { Article } from '@src/views/Article';
import { ForYou } from '@src/views/ForYou';
import { Articles } from '@src/views/Articles';
import { FinishedOnboarding } from './FinishedOnboarding';

/**
 * If route need to be displayed in public app,
 * you need to add it inside App/PublicApp/Routes.jsx and
 * backend/routes/index.js file as well.
 */
export const Routes: FC = () => {
  const location = useLocation();

  const { data: ipDetailsQueryResult } = useIpDetailsQuery();

  if (!ipDetailsQueryResult?.ipDetails) {
    return null;
  }

  return (
    <FinishedOnboarding>
      <Switch location={location}>
        <Route exact path={INDEX_ROUTE}>
          <MainViewWrapper />
        </Route>

        <Route exact path={ARTICLES_ROUTE}>
          <Articles />
        </Route>

        <Route exact path={ARTICLE_ROUTE}>
          <Article />
        </Route>

        <Route exact path={MY_BOOKMARKS_ROUTE}>
          <MyBookmarks />
        </Route>

        <Route path={MESSAGING_ROUTE}>
          <Inbox />
        </Route>

        <Route exact path={INBOX_ROUTE}>
          <Inbox />
        </Route>

        <Route exact path={CHAT_ROUTE}>
          <Chat />
        </Route>

        <Route exact path={NOTIFICATION_CENTER_ROUTE}>
          <NotificationCenter />
        </Route>

        <Route exact path={MATCH_ROUTE}>
          <Match />
        </Route>

        <Route exact path={JOB_OPENING_ROUTE}>
          <JobOpening />
        </Route>

        <Route path={COMPANY_ROUTE}>
          <CompanyProfile />
        </Route>

        <Route exact path={PROFILE_REMOVE_ROUTE}>
          <ProfileRemove />
        </Route>

        <Route exact path={INBOX_PROFILE_ROUTE}>
          <InboxProfile />
        </Route>

        <Route exact path={SETTINGS_ROUTE}>
          <Settings />
        </Route>

        <Route exact path={SETTINGS_HIRE_ROUTE}>
          <Hire />
        </Route>

        <Route exact path={SETTINGS_CONTACT_ROUTE}>
          <Contact />
        </Route>

        <Route exact path={SETTINGS_FEEDBACK_ROUTE}>
          <Feedback />
        </Route>

        <Route exact path={SEARCH_ROUTE}>
          <Search />
        </Route>

        <Route exact path={SEARCH_RESULT_ROUTE}>
          <SearchResult />
        </Route>

        <Route exact path={LOADING_ROUTE}>
          <FrankRefreshing />
        </Route>

        <Route exact path={VERIFIED_USER_ROUTE}>
          <VerifiedUser />
        </Route>

        <Route exact path={VERIFIED_USER_QUESTIONNAIRE_ROUTE}>
          <VerifiedUserQuestionnaire />
        </Route>

        <Route exact path={FILLED_ROUTE}>
          <Filled />
        </Route>

        <Route exact path={FIND_YOUR_COMPANY_ROUTE}>
          <FindYourCompany />
        </Route>

        <Route exact path={COMPANY_REVIEWS_ROUTE}>
          <CompanyReviews />
        </Route>

        <Route exact path={CREATE_COMPANY_REVIEW_ROUTE}>
          <CreateCompanyReview />
        </Route>

        <Route exact path={MENU_ROUTE}>
          <Menu />
        </Route>

        <Route exact path={FRANK_ROUTE}>
          <Frank />
        </Route>

        <Route exact path={MESSAGE_REQUESTS_ROUTE}>
          <MessageRequests />
        </Route>

        <Route exact path={ACTIVE_COMPANIES_ROUTE}>
          <ActiveCompanies />
        </Route>

        <Route exact path={REVIEWS_ROUTE}>
          <Reviews />
        </Route>

        <Route exact path={TOP_COMPANIES_ROUTE}>
          <TopCompanies />
        </Route>

        <Route exact path={JOBS_SEARCH_ROUTE}>
          <JobsSearch />
        </Route>

        <Route exact path={FOR_YOU_ROUTE}>
          <ForYou />
        </Route>

        {/** We use Market overview as home route currently, but we cant change the route name because it is used in the push notifications. */}
        <Route
          exact
          path={YOUR_MARKET_OVERVIEW_ROUTE}
          render={({ location }) => {
            const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true }) as Record<string, string>;

            return (
              <Redirect
                to={
                  queryParams
                    ? createLink({
                        link: JOBS_SEARCH_ROUTE,
                        queryParams: queryParams,
                      })
                    : JOBS_SEARCH_ROUTE
                }
              />
            );
          }}
        />

        <Route
          exact
          path={JOBS_ROUTE}
          render={() => {
            return <Redirect to={JOBS_SEARCH_ROUTE} />;
          }}
        />

        {/* Older Routes */}
        <Route exact path={PROFILE_ROUTE} render={() => <Redirect to={INBOX_PROFILE_ROUTE} />} />

        <Route exact path={JOBS_ROUTE_OLD}>
          <Jobs />
        </Route>
      </Switch>
    </FinishedOnboarding>
  );
};
