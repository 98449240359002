import { Observable, Operation, NextLink, FetchResult } from '@apollo/client';
import { handleTokenRefresh } from './handleTokenRefresh';
import { createAuthHeader } from './createAuthHeader';

export const refreshTokenAndRetry = (operation: Operation, forward: NextLink): Observable<FetchResult> => {
  return new Observable(observer => {
    handleTokenRefresh(accessToken => {
      operation.setContext(({ headers = {} }) => ({
        headers: {
          ...headers,
          authorization: accessToken ? createAuthHeader(accessToken) : null,
        },
      }));
      forward(operation).subscribe({
        complete: observer.complete.bind(observer),
        error: observer.error.bind(observer),
        next: observer.next.bind(observer),
      });
    });
  });
};
