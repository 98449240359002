import { reject } from 'lodash';
import { LocalStorageKeys } from '@src/localStorage/enums/localStorageKeys.enum';
import { getItemFromLocalStorage } from '@src/localStorage/utils/getItemFromLocalStorage';
import { setItemToLocalStorage } from '@src/localStorage/utils/setItemToLocalStorage';

function setItems(items) {
  const slicedItems = items.slice(0, 5);

  setItemToLocalStorage(LocalStorageKeys.RECENT_SEARCH_V1, slicedItems);

  return slicedItems;
}

export function setRecentSearchItem(item) {
  const recentSearches = getRecentSearchItems();

  setItems([
    {
      storageId: Math.random(),
      ...item,
    },
    ...recentSearches,
  ]);
}

export function getRecentSearchItems() {
  return getItemFromLocalStorage(LocalStorageKeys.RECENT_SEARCH_V1) || [];
}

export function removeRecentSearchItem(item) {
  const items = getRecentSearchItems();

  return setItems(reject(items, { storageId: item.storageId }));
}
