import React from 'react';
import PropTypes from 'prop-types';
import { CardSkeleton } from '@src/components/Card/Skeleton';
import './styles.scss';

export const CardScrollerSkeleton = ({ isAnimated }) => {
  return (
    <div className="card-scroller-skeleton">
      <CardSkeleton isAnimated={isAnimated} />
      <CardSkeleton isAnimated={isAnimated} />
    </div>
  );
};

CardScrollerSkeleton.propTypes = {
  isAnimated: PropTypes.bool,
};
