import { JobStartTimeEnum } from '@src/graphql/generated';

const WORK_EXPERIENCE_PLACEHOLDER_BY_SPECIALITY_ID: Record<string, string | undefined> = {
  // Software engineering
  '5995b157871fb3a5190aa2fe':
    'Seeking a Mid-level/Senior role utilizing cutting-edge technologies, and promoting collaboration in software development to create scalable, robust solutions.',

  // Data & Analytics
  '5995b157871fb3a5190aa2ff':
    'Looking to apply intermediate to advanced data analytics skills, machine learning techniques, and statistical modeling to drive data-driven decision-making and business growth.',

  // Design
  '5995b157871fb3a5190aa300':
    'A Mid-level to Senior role with a creative team that values innovative designs, user-centric principles, and striking visuals for impactful digital and print projects.',

  // IT & Sysadmin
  '5995b157871fb3a5190aa301':
    'Senior position with a dynamic environment focusing on network infrastructure, cloud computing, and system administration, ensuring optimal performance, security, and reliability for IT systems.',

  // Sales & Business Development
  '5995b157871fb3a5190aa302':
    'Mid-level/Senior role focusing on strategic partnerships, generate leads, and drive revenue growth by leveraging exceptional sales acumen, market analysis, and relationship-building skills.',

  // Marketing & PR & Media
  '5995b157871fb3a5190aa303':
    'Eager to join a forward-thinking organization, employing data-driven marketing strategies, compelling storytelling, and multi-channel campaigns for brand enhancement and audience engagement.',

  // Finance
  '5995b157871fb3a5190aa304':
    'Mid-level to Senior position emphasizing in financial analysis, strategic planning, and investment management, driving business growth and contributing to long-term financial success.',

  // Legal
  '5995b157871fb3a5190aa306':
    "Mid-level in-house lawyer in the public sector or private tech companies focused on complex problem-solving, robust negotiation, and diligent representation to protect clients' interests and promote fair outcomes.",

  // HR & Recruitment
  '5995b157871fb3a5190aa307':
    'Mid-level/Senior Tech Recruiter role aiming to contribute expertise in talent acquisition, end to end recruitment, and organizational development to create a diverse, high-performing, and inclusive workplace culture.',

  // Customer Support
  '59b8dae0d4841f1d116392cb':
    'Pursuing a Mid-level/Senior customer support role to provide exceptional service, problem resolution, and relationship-building, ensuring positive experiences.',

  // (Tech) Project Management
  '59c172c27fd8f1c6796b5526':
    'Searching for an opportunity to lead cross-functional teams, implement Agile methodologies, and deliver successful tech projects, enhancing organizational efficiency and growth.',

  // Administration & Office Support
  '5de2de27b8d1dc6e8a876da2':
    'Looking to utilize strong organizational, communication, and time-management skills to enhance operational efficiency and support daily office functions in a fast-paced environment.',

  // C & Top-level Management
  '5de2de27b8d1dc6e8a876da3':
    'Targeting a strategic leadership position, utilizing extensive experience to drive organizational growth, foster innovation, and cultivate a high-performance culture.',

  // Retail
  '5de2de27b8d1dc6e8a876da4':
    'Seeking a retail role to leverage excellent customer service, merchandising expertise, and inventory management, promoting a positive shopping experience and increasing sales.',

  // Hospitality & Restaurant
  '5de2de28b8d1dc6e8a876da5':
    'Desiring a hospitality position focused on providing exceptional guest experiences, maintaining high-quality service...',

  // Engineering
  '5de2de28b8d1dc6e8a876da6':
    'Aiming for a position to apply engineering principles and innovative problem-solving skills to design, analyze, and improve products and processes in a collaborative environment.',

  // Logistics & Supply Chain
  '5de2de29b8d1dc6e8a876da7':
    'Seeking a role in logistics and supply chain management, leveraging expertise in process optimization, inventory control, and data analysis to enhance operational efficiency and reduce costs.',

  // Real Estate
  '5de2de2ab8d1dc6e8a876da8':
    'Looking to excel in a real estate role, employing market analysis, property valuation, and relationship-building skills to facilitate transactions and deliver exceptional client service.',

  // Construction
  '5de2de2ab8d1dc6e8a876da9':
    'Aspiring to contribute to construction projects by utilizing extensive knowledge in project management, site supervision, and quality control, ensuring safety and timely completion of projects.',
};

const DEFAULT_WORK_EXPERIENCE_PLACEHOLDER =
  'Example: I have spent the last 3 years as a QA Engineer in a start-up. In total, I have 8+ years of software testing experience, with a focus on cybersecurity.';

export const MOCK_SWITCH_JOBS_PERIOD = [
  { key: JobStartTimeEnum.IMMEDIATELY, value: 'Immediately' },
  { key: JobStartTimeEnum.FEW_WEEKS, value: 'In a couple of weeks' },
  { key: JobStartTimeEnum.ONE_MONTH, value: 'In a month' },
  { key: JobStartTimeEnum.FEW_MONTHS, value: 'In a couple of months' },
  { key: JobStartTimeEnum.HALF_YEAR, value: 'In half a year' },
  { key: JobStartTimeEnum.NOT_SURE, value: 'Not sure' },
];

export const QUESTIONS = [
  {
    action: [{ active: true, className: 'verified-user__continue-button', type: 'continue', value: 'Start' }],
    content: [
      { type: 'chat-bubble', value: 'Hi! 👋' },
      { type: 'chat-bubble', value: 'Find out who wants to hire you - without applying to jobs.' },
      {
        type: 'chat-bubble',
        value: `📝 How it works:\n
1. Extend your headhunting profile.\n
2. We'll show your profile to 100s of recruiters in days.\n
3. Recruiters will pitch you new job opportunities. Pick the best offers, and get a job you love! 🚀`,
      },
    ],
    key: 'verified-user-step-1',
    type: 'onboarding',
  },
  {
    action: { active: false, type: 'continue', value: 'Next' },
    content: [
      { type: 'chat-bubble', value: 'Which types of jobs are you interested in?' },
      {
        key: 'jobTypes',
        type: 'treeselect',
        value: [
          { key: 'local', value: 'Local' },
          { key: 'remote', value: 'Remote' },
          { key: 'relocation', value: 'Relocation' },
        ],
      },
    ],
    key: 'jobTypes',
    type: 'treeselect',
  },
  {
    action: { active: false, selectedValueLimit: 80, type: 'continue', value: 'Next' },
    content: [
      {
        type: 'chat-bubble',
        value: 'What is your professional background? Highlight your main strengths, skills, and knowledge.',
      },
      {
        getPlaceHolder: (specialityId: string): string => {
          const placeholder = WORK_EXPERIENCE_PLACEHOLDER_BY_SPECIALITY_ID[specialityId];

          if (placeholder) {
            return placeholder;
          }

          return DEFAULT_WORK_EXPERIENCE_PLACEHOLDER;
        },
        key: 'workExperience',
        textLimit: 80,
        type: 'chat-textarea',
        value: DEFAULT_WORK_EXPERIENCE_PLACEHOLDER,
      },
    ],
    key: 'workExperience',
    type: 'onboarding',
  },
  {
    action: { active: false, selectedValueLimit: 80, type: 'continue', value: 'Next' },
    content: [
      { type: 'chat-bubble', value: 'What are you looking for in your next employer and position?' },
      {
        key: 'positionExpectations',
        textLimit: 80,
        type: 'chat-textarea',
        value: 'Example: Software Engineering team lead in a fast-growing start-up.',
      },
    ],
    key: 'positionExpectations',
    type: 'onboarding',
  },
  {
    action: { active: true, skippable: true, type: 'continue', value: 'Skip' },
    content: [
      { type: 'chat-bubble', value: 'Would you like to hide your profile from any companies? (Optional)' },
      {
        key: 'blacklistedCompanies',
        type: 'search-input',
        value: 'Search by name...',
      },
    ],
    key: 'blacklistedCompanies',
    type: 'onboarding',
  },
  {
    action: { active: true, skippable: true, type: 'continue', value: 'Skip' },
    content: [
      { type: 'chat-bubble', value: 'Would you like to mention any professional achievements? (Optional)' },
      {
        key: 'achievements',
        type: 'chat-textarea',
        value: 'List of professional achievements.',
      },
    ],
    key: 'achievements',
    type: 'onboarding',
  },
  {
    action: { active: false, type: 'continue', value: 'Next' },
    content: [
      {
        type: 'chat-bubble',
        value: 'Please share your <a href="https://linkedin.com" target="_">LinkedIn</a> profile URL:',
      },
      { key: 'linkedInUrl', type: 'chat-input', value: 'LinkedIn profile URL' },
    ],
    key: 'linkedInUrl',
    type: 'onboarding',
  },
  {
    action: { active: false, type: 'continue', value: 'Next' },
    content: [
      { type: 'chat-bubble', value: 'What would be your ideal timeline for starting a new job?' },
      { key: 'canStartIn', type: 'scroller', value: MOCK_SWITCH_JOBS_PERIOD },
    ],
    key: 'canStartIn',
    type: 'onboarding',
  },
  {
    action: { active: false, type: 'continue', value: 'Next' },
    content: [
      { type: 'chat-bubble', value: 'Please share your e-mail.' },
      { type: 'chat-bubble', value: 'Your contact details are only visible if you start a chat with a recruiter.' },
      { inputType: 'email', key: 'email', type: 'chat-input', value: 'E-mail' },
    ],
    key: 'email',
    type: 'onboarding',
  },
  {
    action: { active: false, type: 'continue', value: 'Next' },
    content: [
      { type: 'chat-bubble', value: 'Please share your phone number.' },
      { type: 'chat-bubble', value: 'Your contact details are only visible if you start a chat with a recruiter.' },
      { key: 'phone', type: 'phone-dial-code', value: 'Phone nr' },
    ],
    key: 'phone',
    type: 'onboarding',
  },
  {
    action: { active: true, skippable: true, type: 'continue', value: 'Skip' },
    content: [
      {
        type: 'chat-bubble',
        value: 'Do you have a portfolio, CV or personal website? For example, GitHub, Behance or blog. (Optional)',
      },
      { key: 'links', type: 'dynamic-input', value: 'URL' },
    ],
    key: 'links',
    type: 'onboarding',
  },
];
