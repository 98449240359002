import React, { useCallback, Fragment, FC, ReactNode, useRef, useEffect } from 'react';
import { debounce, uniq, throttle } from 'lodash';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import classnames from 'classnames';
import escapeRegexp from 'escape-string-regexp';
import { NetworkStatus } from '@apollo/client';
import { handleFetchMore } from '@src/util/handleFetchMore';
import Input from '@src/components/Input';
import { RecentSearches } from '@src/containers/RecentSearches';
import {
  CombinedSearchTagNameEnum,
  useAutoCompleteOpeningsSearchPatternLazyQuery,
  useLegacySearchCompaniesLazyQuery,
} from '@src/graphql/generated';
import { Company } from './Company';
import { Props } from './props';
import './styles.scss';

const getLastWord = (text: string): string => {
  const result = text.split(' ');

  return result[result.length - 1];
};

function highlight(needle: string, haystack: string): ReactNode {
  const lastWord = getLastWord(needle);
  const matcher = new RegExp('(.*?)(' + escapeRegexp(lastWord) + ')(.*)', 'i');
  const matches = haystack.match(matcher);

  if (matches === null) {
    return <span>{haystack}</span>;
  }

  return (
    <span>
      {matches[1]}
      <strong>{matches[2]}</strong>
      {matches[3]}
    </span>
  );
}

export const AutoSuggestionsSearch: FC<Props> = ({
  className,
  searchPattern,
  onFocus,
  onSuggestionChange,
  onSuggestionClick,
  getBodyRef,
}) => {
  const scrollerRef = useRef<HTMLDivElement>();

  const [fetchSuggestions, { data: openingsData, loading: isOpeningsLoading }] =
    useAutoCompleteOpeningsSearchPatternLazyQuery({
      fetchPolicy: 'network-only',
    });

  const [
    fetchCompanies,
    { data: companiesData, loading: isCompaniesLoading, networkStatus, fetchMore: fetchMoreCompanies },
  ] = useLegacySearchCompaniesLazyQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const searchPatternsResult = openingsData?.autoCompleteOpeningsSearchPattern.result || [];
  const companiesFetchResult = companiesData?.legacySearch?.result || [];
  const companiesPagination = companiesData?.legacySearch?.pagination;

  const isFetchMoreLoading = networkStatus === NetworkStatus.fetchMore;

  const onSelectSuggestion = useCallback((searchPatternItem: string) => {
    onSuggestionChange && onSuggestionChange(searchPatternItem);
    onSuggestionClick && onSuggestionClick(searchPatternItem);
  }, []);

  const loadMoreCompanies = throttle(() => {
    const bodyRef = getBodyRef();

    if (bodyRef?.current) {
      const { scrollHeight, scrollTop, offsetHeight } = bodyRef.current;

      if (
        !isFetchMoreLoading &&
        !isCompaniesLoading &&
        companiesPagination?.hasMoreResults &&
        scrollTop + offsetHeight > scrollHeight - 300
      ) {
        handleFetchMore(fetchMoreCompanies, {
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult || !prev?.legacySearch) {
              return prev;
            }

            const {
              legacySearch: { result, pagination },
            } = fetchMoreResult;

            return {
              legacySearch: {
                ...prev.legacySearch,
                pagination: {
                  ...prev.legacySearch.pagination,
                  hasMoreResults: pagination.hasMoreResults,
                  maxCursor: pagination.maxCursor,
                },
                result: [...prev.legacySearch.result, ...result],
              },
            };
          },
          variables: {
            paginationOptions: {
              limit: 10,
              minCursor: companiesPagination.maxCursor,
            },
          },
        });
      }
    }
  }, 200);

  useEffect(() => {
    const bodyRef = getBodyRef();

    if (bodyRef && bodyRef.current) {
      bodyRef.current.addEventListener('scroll', loadMoreCompanies);
    }

    return () => {
      if (bodyRef && bodyRef.current) {
        bodyRef.current.removeEventListener('scroll', loadMoreCompanies);
      }
    };
  }, [companiesPagination, isCompaniesLoading, isFetchMoreLoading]);

  const debounceSuggestionsFetch = useCallback(
    debounce(searchValue => {
      if (searchValue) {
        fetchSuggestions({
          variables: {
            paginationOptions: {
              limit: 5,
            },
            searchPattern: searchValue.trim(),
          },
        });
        fetchCompanies({
          variables: {
            paginationOptions: {
              limit: 8,
            },
            searchPatterns: [
              {
                pattern: searchValue.trim(),
                tag: CombinedSearchTagNameEnum.COMPANY_NAME,
              },
            ],
            searchTags: [
              {
                tag: CombinedSearchTagNameEnum.TYPE,
                value: 'COMPANY',
              },
            ],
          },
        });
      }
    }, 300),
    [],
  );

  const handleInputChange = (searchValue: string) => {
    onSuggestionChange && onSuggestionChange(searchValue);
    debounceSuggestionsFetch(searchValue);
  };

  const onClearSearchPattern = () => {
    onSuggestionChange && onSuggestionChange('');
  };

  return (
    <div className={classnames('auto-suggestions fade-in', className)}>
      <Input
        clearable
        defaultValue={searchPattern}
        iconRight
        onChange={handleInputChange}
        onClear={onClearSearchPattern}
        onFocus={onFocus}
        placeholder={'Search'}
        searchInput
        setValue={searchPattern}
        value={searchPattern}
        withBorder
        wrapperClassName="auto-suggestions__search-wrapper"
      />

      <menu className="auto-suggestions__list">
        <TransitionGroup component={null}>
          <CSSTransition classNames="fade" timeout={0}>
            <div className="auto-suggestions__list-transition-wrapper" ref={scrollerRef}>
              {!searchPattern && <RecentSearches wrapperClassName="auto-suggestions__recent-searches" />}
              {isOpeningsLoading ? (
                <div>
                  <div className="auto-suggestions__item auto-suggestions--side-space" key="loading">
                    <span>Loading...</span>
                  </div>
                </div>
              ) : searchPattern.length ? (
                <div className="auto-suggestions--side-space fade-in">
                  {uniq([searchPattern, ...searchPatternsResult]).map((searchPatternItem, i) => (
                    <div
                      className="auto-suggestions__item"
                      key={i}
                      onClick={() => onSelectSuggestion(searchPatternItem)}
                    >
                      {highlight(searchPattern, searchPatternItem)}
                    </div>
                  ))}
                </div>
              ) : (
                <div />
              )}

              {!!(searchPattern.length && companiesFetchResult.length) && (
                <Fragment>
                  <div className="auto-suggestions__header fade-in">
                    <div>Companies</div>
                  </div>

                  {companiesFetchResult.map(({ entity }) => (
                    <Company company={entity} key={entity.id} searchInput={searchPattern} />
                  ))}
                </Fragment>
              )}
            </div>
          </CSSTransition>
        </TransitionGroup>
      </menu>
    </div>
  );
};
