import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './styles.scss';

export const AboutTitle = ({ children, className }) => (
  <div className={classnames('company-profile-about-title', className)}>{children}</div>
);

AboutTitle.propTypes = {
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
};
