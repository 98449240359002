import React, { FC } from 'react';
import FrankLogoNewSvg from 'assets/images/frank_logo_new.svg';
import { BlueButton } from '@src/components/Button';
import { RoundedIcon } from '../RoundedIcon';
import { Color } from '../RoundedIcon/roundedIcon.enum';
import { Section } from '../Section';
import { InactiveStateProps } from './props';
import './styles.scss';

export const InactiveState: FC<InactiveStateProps> = ({ onClick, isDisabled }) => {
  return (
    <div className="vt-inactive-state">
      <Section
        bottomSlot={
          <div className="vt-inactive-state__title">
            Get direct job offers from <br />
            top employers
          </div>
        }
        iconSlot={<RoundedIcon color={Color.BLACK} src={FrankLogoNewSvg} />}
        topSlot={<div className="vt-inactive-state__name">Make your profile visible.</div>}
        wrapperClassName="vt-inactive-state__wrapper"
      />

      <BlueButton className="vt-inactive-state__button" isFilled disabled={isDisabled} onClick={onClick}>
        Get started
      </BlueButton>
    </div>
  );
};
