import path from 'path';
import { Action } from '@reduxjs/toolkit';

export const MAIN_REFRESH_TRIGGERED = `${__dirname}/mainRefreshTriggered`;
export function triggerMainRefresh() {
  return { type: MAIN_REFRESH_TRIGGERED };
}

export const MAIN_REFRESH_DONE = `${__dirname}/mainRefreshDone`;
export function setMainRefreshDone() {
  return { type: MAIN_REFRESH_DONE };
}

export const RESET_STORE = path.join(__dirname, 'resetStore');
export function resetStore(): Action<typeof RESET_STORE> {
  return { type: RESET_STORE };
}
