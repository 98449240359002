import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { getWrapperScrollableContainer } from '@src/App';
import ParallaxZoomImage from '@src/components/ParallaxZoomImage';
import { useTransparentizeStatusBar } from '@src/customHooks/useTransparentizeStatusBar';
import './styles.scss';

export function BaseParallaxPage({
  isLoading,
  shouldToggleStatusBarOnScroll,
  bannerImage,
  hasBannerImageGradient,
  children,
  topContent,
  hasContentPadding,
  getScrollableContainer,
  wrapperClassName,
}) {
  const wrapperScrollableContainer =
    (getScrollableContainer && getScrollableContainer()) || getWrapperScrollableContainer();

  useTransparentizeStatusBar({
    scrollableContainer: wrapperScrollableContainer[0],
    shouldToggleStatusBarOnScroll,
  });

  return (
    <div className={classnames('parallax-page', wrapperClassName)}>
      <ParallaxZoomImage
        gradientClassName="parallax-page__main-image-gradient"
        hasGradient={hasBannerImageGradient}
        hideStatusBar={false}
        imageClassName="parallax-page__main-image"
        imageUrl={bannerImage}
        isLoading={isLoading}
      >
        <div className="parallax-page__content-wrapper">
          <div className="parallax-page__content">
            {topContent}

            <div
              className={classnames('parallax-page__body', { 'parallax-page__body--with-padding': hasContentPadding })}
            >
              {children}
            </div>
          </div>
        </div>
      </ParallaxZoomImage>
    </div>
  );
}

BaseParallaxPage.propTypes = {
  // "bannerImage" is a required field, but on first render it is undefined because
  // we need to display skeleton loading instead.
  bannerImage: PropTypes.string,

  children: PropTypes.node.isRequired,

  // necessary for preview mode
  getScrollableContainer: PropTypes.func,

  hasBannerImageGradient: PropTypes.bool,

  hasContentPadding: PropTypes.bool,

  isLoading: PropTypes.bool,

  shouldToggleStatusBarOnScroll: PropTypes.bool,

  topContent: PropTypes.node,

  wrapperClassName: PropTypes.string,
};
