import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from './Skeleton';
import './styles.scss';

export const DOT_COLOR = {
  BLUE: 'blue',
  GREEN: 'green',
  PINK: 'pink',
};

export function StatsItem({ loading, label, primaryInfo, secondaryInfo, dotColor }) {
  if (loading) {
    return <Skeleton />;
  }

  return (
    <div className="search-stats-item">
      <div className={`search-stats-item__dot search-stats-item__dot--${dotColor}`} />

      <div className="search-stats-item__info">
        <div className="search-stats-item__info-header">{label}</div>
        <div className="search-stats-item__info-primary">{primaryInfo}</div>
        <div className="search-stats-item__info-secondary">{secondaryInfo}</div>
      </div>
    </div>
  );
}

StatsItem.propTypes = {
  dotColor: PropTypes.oneOf(Object.values(DOT_COLOR)).isRequired,
  label: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  primaryInfo: PropTypes.string.isRequired,
  secondaryInfo: PropTypes.string.isRequired,
};
