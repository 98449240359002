export enum Context {
  ACCOUNT = 'Account',
  ARTICLE = 'Article',
  ARTICLES = 'Articles',
  CHAT = 'Chat',
  COMPANY_PROFILE = 'Company Profile',
  COMPANY_REVIEWS = 'Company Reviews',
  CREATE_COMPANY_REVIEW = 'Create Company Review',
  DIGEST = 'Digest',
  EXPLORE = 'Explore',
  FILLED = 'Filled',
  FIND_YOUR_COMPANY = 'Find Your Company',
  FOR_YOU = 'For You',
  HOME = 'Home',
  INVITE = 'Invite',
  MENU = 'Menu',
  MESSAGE_REQUESTS = 'Message Requests',
  NAVIGATION = 'Navigation',
  ONBOARDING = 'Onboarding',
  OPENING = 'Opening',
  OPENING_ACTIONS = 'Opening Actions',
  OPENING_FEED_ITEM = 'Opening Feed Item',
  OPENING_FOOTER = 'Opening Footer',
  PROFILE = 'Profile',
  RECENT_COMPANIES = 'Recent Companies',
  RECRUITER_SIGNUP_BANNER = 'Recruiter Signup Banner',
  REVIEW = 'Review',
  REVIEWS = 'Reviews',
  SAVED_OFFERS = 'Saved Offers',
  SEARCH = 'Search',
  SEARCH_RESULTS = 'Search Results',
  SESSION = 'Session',
  TOP_COMPANIES = 'Top Companies',
  UA_FEED = 'UA-feed',
  VERIFY_USER = 'Verify User',
  YOU = 'You',
}
