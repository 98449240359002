import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { isNumber } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { closeModal } from '@src/redux/actions/slideUpModal';
import { getContent, getHeight, getContentClassName, getRemoveBorderRadius } from '@src/redux/selectors/slideUpModal';
import './styles.scss';

@connect(
  state => ({
    content: getContent(state),
    contentClassName: getContentClassName(state),
    height: getHeight(state),
    removeBorderRadius: getRemoveBorderRadius(state),
  }),
  { closeModal },
)
export default class SlideUpModal extends PureComponent {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    content: PropTypes.node,
    contentClassName: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    removeBorderRadius: PropTypes.bool,
  };

  setContentHeight(height) {
    if (isNumber(height)) {
      return `${height}vh`;
    }

    return height;
  }

  render() {
    const { content, closeModal, height, contentClassName, removeBorderRadius } = this.props;

    return (
      <TransitionGroup component={null}>
        <CSSTransition
          classNames="slide-up-modal-animation"
          key={content ? 'open' : 'closed'}
          timeout={{ enter: 0, exit: 300 }}
        >
          {content ? (
            <div className="slide-up-modal">
              <div className="slide-up-modal-overlay" onClick={closeModal} />

              <div
                className={classnames(
                  'slide-up-modal-content slide-up-modal-item',
                  removeBorderRadius && 'slide-up-modal-content--no-border-radius',
                  contentClassName,
                )}
                style={{ height: this.setContentHeight(height) }}
              >
                {content}
              </div>
            </div>
          ) : (
            <span />
          )}
        </CSSTransition>
      </TransitionGroup>
    );
  }
}
