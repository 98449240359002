import * as Sentry from '@sentry/react';
import ReduxThunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { configureStore, combineReducers, Middleware, Reducer, AnyAction, StoreEnhancer } from '@reduxjs/toolkit';
import { LOCATION_CHANGE, connectRouter, routerMiddleware } from 'connected-react-router';
import { createHashHistory, createBrowserHistory } from 'history';
import { compact } from 'lodash';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import * as reducers from '@src/redux/reducers';
import { RESET_STORE } from '@src/redux/actions/main';
import { trackPageView } from '@src/metrics';
import { AppDispatch, RootState } from './interfaces/store.interface';

export const history = window.isPublicApp ? createBrowserHistory() : createHashHistory();

const isProduction = process.env.NODE_ENV === 'production';

const trackPageChange: Middleware = () => {
  return next => action => {
    if (action.type === LOCATION_CHANGE) {
      const { location } = action.payload;
      const path = `${location.pathname}${location.search}`;
      trackPageView({ pagePath: path });
    }

    return next(action);
  };
};

const logger: Middleware = createLogger({
  collapsed: true,
  duration: true,
});

const sentryReduxEnhancer: StoreEnhancer = Sentry.createReduxEnhancer({
  stateTransformer: state => {
    // Transform the state to remove sensitive information
    const transformedState = {
      ...state,
      accessToken: '[hidden]',
    };

    return transformedState;
  },
});

const middleware: Middleware[] = compact([
  trackPageChange,
  routerMiddleware(history),
  ReduxThunk,
  !isProduction && logger,
]);

const enhancers: StoreEnhancer[] = compact([
  sentryReduxEnhancer,
  window.devToolsExtension && window.devToolsExtension(),
]);

export const rootReducer = combineReducers({
  ...reducers,
  router: connectRouter(history),
});

const appReducer: Reducer<RootState, AnyAction> = (state, action) => {
  if (action.type === RESET_STORE) {
    return rootReducer(undefined, action);
  }

  return rootReducer(state, action);
};

export const useAppDispatch = (): AppDispatch => {
  return useDispatch<AppDispatch>();
};

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default configureStore({
  enhancers: enhancers,
  middleware: middleware,
  reducer: appReducer,
});
