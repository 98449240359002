import React, { FC } from 'react';
import { ActiveFeed } from '../enums/activeFeed.enum';
import { useCategoryOffersFeed } from '../hooks/useCategoryOffersFeed';
import { OpeningFeed } from '../OpeningFeed';
import { CategoryOffersFeedProps } from './props';

export const CategoryOffersFeed: FC<CategoryOffersFeedProps> = ({
  placeItem,
  searchTags,
  searchTagWithOrCondition,
  activeSpecialityId,
  isSimulatedLoading,
}) => {
  const { feed, isInitialLoading, isLoading, hasMoreResults } = useCategoryOffersFeed({
    activePlaceItem: placeItem,
    activeSpecialityId: activeSpecialityId,
    searchTags: searchTags,
    searchTagsWithOrCondition: searchTagWithOrCondition,
  });

  return (
    <OpeningFeed
      activeFeed={ActiveFeed.SEARCH_OPENINGS}
      feed={feed}
      hasMoreResults={hasMoreResults}
      isInitialLoading={isInitialLoading}
      isLoading={isLoading || isSimulatedLoading}
      openingSearchTags={searchTags}
      searchTagWithOrCondition={searchTagWithOrCondition}
    />
  );
};
