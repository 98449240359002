import React, { FC, Fragment } from 'react';
import { Rating } from '@src/components/Rating';
import { FixedHeader } from '@src/components/FixedHeader';
import FadeInImage from '@src/components/FadeInImage';
import { BlueButton } from '@src/components/Button';
import { RatingColor } from '@src/components/Rating/enums/ratingColor.enum';
import { RatingSize } from '@src/components/Rating/enums/ratingSize.enum';
import { RatingInterface } from './interfaces/rating.interface';
import { RatingsProps } from './props';
import './styles.scss';

const RATINGS: RatingInterface[] = [
  { activeColor: RatingColor.BLUE, key: 'total', label: 'Overall rating' },
  { activeColor: RatingColor.YELLOW, key: 'workLifeBalance', label: 'Work-life balance' },
  { activeColor: RatingColor.BLACK, key: 'compensationBenefits', label: 'Compensation benefits' },
  { activeColor: RatingColor.BLUE, key: 'companyCulture', label: 'Company Culture' },
  { activeColor: RatingColor.YELLOW, key: 'management', label: 'Management' },
  { activeColor: RatingColor.BLACK, key: 'product', label: 'Product/Service' },
];

export const Ratings: FC<RatingsProps> = ({
  setRating,
  ratings,
  companyLogo,
  companyName,
  companyLocation,
  handleCreateReview,
}) => {
  const isButtonDisabled =
    Object.keys(ratings).length !== RATINGS.length ||
    Object.values(ratings).some(rating => rating === undefined || rating === 0);

  return (
    <>
      <FixedHeader close title="Give a review" wrapperClassName="create-review-ratings__header" />

      <div className="create-review-ratings__company">
        <FadeInImage
          className="create-review-ratings__company-logo"
          src={companyLogo}
          wrapperClassName="create-review-ratings__company-logo-wrapper"
        />

        <div className="create-review-ratings__company-details">
          <h3>{companyName}</h3>

          <p>{companyLocation}</p>
        </div>
      </div>

      <div className="create-review-ratings__body">
        <div className="create-review-ratings">
          <div className="create-review-ratings__body-header">
            <h4>Rate this company</h4>

            <p>You can make your rating anonymous</p>
          </div>

          {RATINGS.map(({ key, label, activeColor }) => (
            <div className="create-review-ratings__rating-wrapper" key={key}>
              <p className="create-review-ratings__rating-title">{label}</p>

              <Rating
                activeColor={activeColor}
                activeRating={ratings[key] || 0}
                id={1}
                maxRating={5}
                setActiveRating={rating => setRating(rating.rating, key)}
                size={RatingSize.LARGE}
                wrapperClassName="create-review-ratings__rating"
              />
            </div>
          ))}
        </div>
      </div>

      <div className="create-review-ratings__footer">
        <BlueButton
          className="create-review-ratings__continue"
          disabled={isButtonDisabled}
          isFilled
          onClick={handleCreateReview}
        >
          Continue
        </BlueButton>
      </div>
    </>
  );
};
