import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { matchPath } from 'react-router-dom';
import { find, some } from 'lodash';
import { setScroll } from '@src/redux/actions/scroll';
import { getScroll } from '@src/redux/selectors/scroll';
import { getPreviousLocation } from '@src/redux/selectors/navigation';
import { GLOBAL_AREA_ID } from '@src/constants';
import { createPlaceItem, createDefaultPlaceItemsFromPlaces, createPlaceItemsFromPlaces } from '@src/util/filters';
import { setContent as setModalContent, closeModal } from '@src/redux/actions/slideUpModal';
import {
  COMPANY_ROUTE,
  COMPANY_ABOUT_ROUTE,
  JOB_OPENING_ROUTE,
  COMPANY_REVIEWS_ROUTE,
  CREATE_COMPANY_REVIEW_ROUTE,
} from '@src/constants/routes';
import {
  AreaType,
  CountryType,
  OpeningRemoteTypeEnum,
  PlaceTypeEnum,
  SearchOpeningEntityTagInput,
  SearchOpeningEntityTagNameEnum,
  useGetUserQuery,
} from '@src/graphql/generated';
import { ItemType } from '@src/metrics/enums/itemType.enum';
import { Context } from '@src/metrics/enums/context.enum';
import { trackEvent } from '@src/metrics';
import { Interaction } from '@src/metrics/enums/interaction.enum';
import { useQueryParams } from '@src/customHooks/useQueryParams';
import { useAppDispatch, useAppSelector } from '@src/store';
import { getScrollableContainer } from '@src/App';
import { PlaceItem } from '@src/util/filters/interface/createPlaceItem.interface';
import { ALL_PLACE_ID, REMOTE_PLACE_ID } from '@src/constants/placeIds';
import { usePlaceFilter } from '@src/customHooks/usePlaceFilter';
import { PageNames } from '@src/redux/selectors/scroll/enums/pageNames.enum';
import { PlaceFiltersModalContent } from '@src/containers/PlaceFiltersModalContent';
import { GetSearchTagsByPlaceItem } from '@src/containers/PlaceFiltersModalContent/interfaces/getSearchTagsByPlaceItem.interface';
import { LocalStorageKeys } from '@src/localStorage/enums/localStorageKeys.enum';
import { ActiveFeed } from './enums/activeFeed.enum';
import { PageHeader } from './PageHeader';
import { PageViewEvent } from './interfaces/pageViewEvent.interface';
import { QueryParams } from './interfaces/queryParams.interface';
import { useSortOptions } from './hooks/useSortOptions';
import { RelevantOffersFeed } from './RelevantOffersFeed';
import { ActiveTab } from './enums/activeTab.enum';
import { CategoryOffersFeed } from './CategoryOffersFeed';
import { Category } from './enums/category.enum';
import { DEFAULT_CATEGORY } from './constants/categories';
import './styles.scss';

export const Jobs: FC = () => {
  const feedContainerEl = useRef(null);

  const isMounted = useRef(false);

  const scrolls = useAppSelector(getScroll);

  const scroll = scrolls?.[PageNames.JOBS] || 0;

  const previousLocation = useAppSelector(getPreviousLocation);

  const dispatch = useAppDispatch();

  const { queryParams, updateQueryParams } = useQueryParams<QueryParams>();

  const { sortOption, updateSortOption } = useSortOptions();

  const [isSimulatedLoading, setIsSimulatedLoading] = useState(false);

  const activeTab = queryParams.activeTab;
  const selectedSpecialityId = queryParams?.selectedSpecialityId || DEFAULT_CATEGORY;

  const { data: userData } = useGetUserQuery();

  const user = userData?.currentUser;
  const userLocation = user?.location;
  const userLocationCountry = userLocation?.country;

  const userPlaceItem = userLocation?.id
    ? createPlaceItem({
        entity: {
          __typename: 'LocationType',
          country: userLocation?.country
            ? {
                id: userLocation.country.id,
                shortName: userLocationCountry?.shortName || '',
              }
            : undefined,
          id: userLocation?.id,
          shortName: userLocation?.shortName || '',
        },
        type: PlaceTypeEnum.LOCATION,
      })
    : undefined;

  const scrollableContainer = getScrollableContainer();

  const { savedPlaces, activePlaceId, setPlaceActive, addPlaces, savedRemotePlaces } = usePlaceFilter({
    localStorageKey: LocalStorageKeys.USER_SELECTED_HOME_FEED_PLACE_DATA,
  });

  const pageViewEvent: PageViewEvent = placeItem => {
    let itemType: ItemType = ItemType.CUSTOM;

    if (placeItem.id === userPlaceItem?.id) {
      itemType = ItemType.LOCAL;
    }

    if (placeItem.id === REMOTE_PLACE_ID) {
      itemType = ItemType.REMOTE;
    }

    if (placeItem.id === ALL_PLACE_ID) {
      itemType = ItemType.ALL;
    }

    trackEvent({
      context: Context.DIGEST,
      interaction: Interaction.VIEW,
      itemType: itemType,
      itemValue: placeItem.id,
    });
  };

  const simulateLoading = (): void => {
    setIsSimulatedLoading(true);

    setTimeout(() => {
      setIsSimulatedLoading(false);
    }, 350);
  };

  const handleActivePlaceSet = (placeId: string) => {
    setPlaceActive(placeId);

    simulateLoading();
  };

  const onFiltersApplied = (places: (AreaType | CountryType)[]) => {
    scrollableContainer.scrollTop = 0;
    const selectedPlace = find(places, { id: activePlaceId }) || places[places.length - 1];

    addPlaces(places, selectedPlace?.id);
  };

  const openLocationFilter = () => {
    dispatch(
      setModalContent({
        content: (
          <PlaceFiltersModalContent
            defaultPlaces={savedPlaces}
            excludedAreaIds={[GLOBAL_AREA_ID]}
            onApplyFilters={places => {
              onFiltersApplied(places);
            }}
            onClose={() => dispatch(closeModal())}
          />
        ),
        height: '70vh',
      }),
    );
  };

  const getSearchTagsByPlaceItem: GetSearchTagsByPlaceItem = placeItem => {
    if (placeItem?.type === PlaceTypeEnum.LOCATION) {
      return [{ tag: SearchOpeningEntityTagNameEnum.POSITION_LOCATION, value: placeItem.id }];
    }

    if (placeItem?.type === PlaceTypeEnum.COUNTRY) {
      return [{ tag: SearchOpeningEntityTagNameEnum.POSITION_COUNTRY, value: placeItem.id }];
    }

    if (placeItem?.type === PlaceTypeEnum.AREA) {
      return [{ tag: SearchOpeningEntityTagNameEnum.POSITION_AREA, value: placeItem.id }];
    }

    if (placeItem?.type === PlaceTypeEnum.REMOTE) {
      return [{ tag: SearchOpeningEntityTagNameEnum.REMOTE_TYPE, value: OpeningRemoteTypeEnum.FULLY }];
    }

    return [];
  };

  const getSearchTagsWithOrConditionByPlaceItem = (
    placeItem?: PlaceItem,
    remotePlaces?: (CountryType | AreaType)[],
  ): SearchOpeningEntityTagInput[] => {
    if (
      placeItem?.type &&
      [PlaceTypeEnum.LOCATION, PlaceTypeEnum.COUNTRY, PlaceTypeEnum.AREA].includes(placeItem.type)
    ) {
      return [];
    }

    if (placeItem?.type === PlaceTypeEnum.REMOTE) {
      const isGlobal = some(remotePlaces, { id: GLOBAL_AREA_ID });

      if (remotePlaces?.length && !isGlobal) {
        return remotePlaces.map(place => {
          if (place.__typename === 'AreaType') {
            return { tag: SearchOpeningEntityTagNameEnum.REMOTE_AREA, value: place.id };
          }

          return { tag: SearchOpeningEntityTagNameEnum.REMOTE_COUNTRY, value: place.id };
        });
      }

      return [];
    }

    if (!userPlaceItem) {
      return [{ tag: SearchOpeningEntityTagNameEnum.REMOTE_TYPE, value: OpeningRemoteTypeEnum.FULLY }];
    }

    return [
      { tag: SearchOpeningEntityTagNameEnum.REMOTE_TYPE, value: OpeningRemoteTypeEnum.FULLY },
      { tag: SearchOpeningEntityTagNameEnum.POSITION_LOCATION, value: userPlaceItem.id },
    ];
  };

  const placeItems = createDefaultPlaceItemsFromPlaces(savedPlaces, userPlaceItem);

  const remotePlaceItems = createPlaceItemsFromPlaces(savedRemotePlaces);

  const activePlaceItem = useMemo(() => {
    return placeItems.find(place => place.id === activePlaceId);
  }, [activePlaceId]);

  useEffect(() => {
    if (activePlaceItem) {
      pageViewEvent(activePlaceItem);
    }
  }, [activePlaceItem]);

  const activeSearchTags = useMemo(() => {
    return getSearchTagsByPlaceItem(activePlaceItem);
  }, [activePlaceItem?.id, remotePlaceItems.map(place => place.id).toString()]);

  const activeSearchTagWithOrCondition = useMemo(() => {
    return getSearchTagsWithOrConditionByPlaceItem(activePlaceItem, savedRemotePlaces);
  }, [activePlaceItem?.id, remotePlaceItems.map(place => place.id).toString()]);

  const activeFeed =
    selectedSpecialityId === Category.FOR_YOU ? ActiveFeed.SEARCH_RELEVANT_OPENINGS : ActiveFeed.SEARCH_OPENINGS;

  useEffect(() => {
    scrollableContainer.scrollTop = 0;

    if (activeTab === ActiveTab.LOCAL && userPlaceItem) {
      handleActivePlaceSet(userPlaceItem.id);
    }

    if (activeTab === ActiveTab.REMOTE) {
      handleActivePlaceSet(REMOTE_PLACE_ID);
    }
  }, [activeTab]);

  useEffect(() => {
    if (!selectedSpecialityId) {
      updateQueryParams({ selectedSpecialityId: Category.FOR_YOU });
    }

    const shouldUpdateScroll =
      !isMounted.current &&
      previousLocation &&
      find(
        [COMPANY_ROUTE, COMPANY_ABOUT_ROUTE, JOB_OPENING_ROUTE, COMPANY_REVIEWS_ROUTE, CREATE_COMPANY_REVIEW_ROUTE],
        route => matchPath(previousLocation, { path: route }),
      );

    scrollableContainer.scrollTop = shouldUpdateScroll ? scroll || 0 : 0;

    isMounted.current = true;

    return () => {
      dispatch(setScroll(PageNames.JOBS, scrollableContainer.scrollTop));
    };
  }, []);

  return (
    <div className="job fade-in">
      <PageHeader
        activePlaceId={activePlaceId}
        addPlace={openLocationFilter}
        getScrollableContainer={getScrollableContainer}
        onPlaceClick={place => handleActivePlaceSet(place.id)}
        onSpecialitySelect={simulateLoading}
        places={placeItems}
        providedTargetEl={feedContainerEl}
        sortOption={sortOption}
        updateSortOption={updateSortOption}
      />

      <div className="job__feed" ref={feedContainerEl}>
        {activeFeed === ActiveFeed.SEARCH_RELEVANT_OPENINGS && (
          <RelevantOffersFeed
            isSimulatedLoading={isSimulatedLoading}
            placeItem={activePlaceItem}
            searchTagWithOrCondition={activeSearchTagWithOrCondition}
            searchTags={activeSearchTags}
            sortOption={sortOption}
          />
        )}

        {activeFeed === ActiveFeed.SEARCH_OPENINGS && (
          <CategoryOffersFeed
            activeSpecialityId={selectedSpecialityId}
            isSimulatedLoading={isSimulatedLoading}
            placeItem={activePlaceItem}
            searchTagWithOrCondition={activeSearchTagWithOrCondition}
            searchTags={activeSearchTags}
          />
        )}
      </div>
    </div>
  );
};
