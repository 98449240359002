import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { replace, push, goBack, getLocation } from 'connected-react-router';
import { matchPath } from 'react-router-dom';
import { apolloClient } from '@src/graphql/apollo';
import { TypingLoader } from '@src/components/TypingLoader';
import api from '@src/util/api';
import { trackEvent } from '@src/metrics';
import { setNavigationVisibility } from '@src/redux/actions/navigation';
import { openingStateUpdate } from '@src/redux/actions/openings';
import ContentPush from '@src/components/ContentPush';
import { setContent as setModalContent, closeModal } from '@src/redux/actions/slideUpModal';
import { VerifyTalentNotifyModal } from '@src/components/VerifyTalentNotifyModal';
import createLink from '@src/util/createLink';
import { MATCH_ROUTE, VERIFIED_USER_ROUTE } from '@src/constants/routes';
import {
  GetUserForVerificationEligibilityCheckDocument,
  GetUserForVerificationEligibilityCheckQuery,
} from '@src/graphql/generated';
import { isOnboardedUserProfileType } from './typeGuards';
import './styles.scss';

@connect(
  (state: RootState) => ({
    location: getLocation(state),
  }),
  { closeModal, goBack, openingStateUpdate, push, replace, setModalContent, setNavigationVisibility },
)
export default class Match extends PureComponent {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    openingStateUpdate: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
    setModalContent: PropTypes.func.isRequired,
    setNavigationVisibility: PropTypes.func.isRequired,
  };

  state = {
    loading: true,
    selectedValue: undefined,
  };

  componentDidMount() {
    const { setNavigationVisibility } = this.props;

    setNavigationVisibility(false);
    this.fetchMatch();
  }

  fetchMatch = () => {
    const { location } = this.props;

    const match = matchPath<{ openingId: string }>(location.pathname, { path: MATCH_ROUTE });

    const openingId = match?.params.openingId;

    this.setState({ selectedValue: undefined });

    api(`/api/app/match/${openingId}`, { delay: 1000 }).then(({ opening, question, url, recruiterTitle }) => {
      if (url) {
        this.props.replace(url);

        return;
      }

      const { content } = question;

      content.forEach(({ key, defaultValue }) => {
        if (defaultValue) {
          this.handleValueSelect({ [key]: defaultValue });
        }
      });

      this.setState({
        loading: false,
        opening: opening,
        question: question,
        recruiterTitle: recruiterTitle,
      });
    });
  };

  actionResponse = async ({ url, openingId }) => {
    if (url) {
      const { replace, openingStateUpdate, setModalContent, push, closeModal } = this.props;
      replace(url);

      if (openingId) {
        trackEvent({
          context: 'Opening',
          interaction: 'Finish application',
          itemType: 'Opening',
          itemValue: openingId,
        });
        openingStateUpdate(openingId, 'positive');

        const { data } = await apolloClient.query<GetUserForVerificationEligibilityCheckQuery>({
          fetchPolicy: 'network-only',
          query: GetUserForVerificationEligibilityCheckDocument,
        });

        const userVerificationState = get(data, 'currentUser.verification.state');
        const isVerificationPublishedOrPending = ['PUBLISHED', 'PENDING'].includes(userVerificationState);
        const canUserCreateVerification =
          isOnboardedUserProfileType(data.currentUser.profile) && data.currentUser.isEligibleForVerification;

        if (!isVerificationPublishedOrPending && canUserCreateVerification) {
          setModalContent({
            content: (
              <VerifyTalentNotifyModal
                onContinue={() => push(createLink({ link: VERIFIED_USER_ROUTE }))}
                onDismiss={() => closeModal()}
              />
            ),
            height: 'auto',
          });
        }
      }
    } else {
      this.fetchMatch();
    }
  };

  sendResponse = data => {
    data = Object.assign({}, data, this.state.selectedValue);
    const { opening } = this.state;

    this.setState({ content: [], loading: true }, async () => {
      const { question } = this.state;

      const { location } = this.props;

      const match = matchPath<{ userMatchingProfileId: string }>(location.pathname, { path: MATCH_ROUTE });

      const userMatchingProfileId = match?.params.userMatchingProfileId;

      trackEvent({
        context: 'Opening',
        interaction: data.answer && data.answer.key === 'discard' ? 'Cancel application' : 'Send interview question',
        itemType: 'Opening',
        itemValue: opening._id,
      });

      const response = await api.post(`/api/app/match/${question._id}/response/${userMatchingProfileId}`, data);

      this.actionResponse(response, 'positive');
    });
  };

  sendBack = () => {
    const { goBack } = this.props;
    this.setState({ content: [], loading: true }, async () => {
      const { question } = this.state;

      const response = await api.post(`/api/app/match/${question._id}/back`);

      response.url ? goBack() : this.actionResponse(response);
    });
  };

  handleContinue = data => {
    this.sendResponse({ ...data, ...this.state.selectedValue });
  };

  handleValueSelect = selectedValue => {
    this.setState({ selectedValue });
  };

  handleInputChange = (key, value) => {
    if (!value) {
      this.handleValueSelect(undefined);
    } else {
      this.handleValueSelect({ [key]: value });
    }
  };

  onInputChange = (key, value) => {
    if (!value) {
      this.handleValueSelect(undefined);
    } else {
      this.handleValueSelect({ [key]: value });
    }
  };

  render() {
    const { loading } = this.state;

    return loading ? (
      <div className="card-loader">
        <TypingLoader />
      </div>
    ) : (
      this.renderNextStep()
    );
  }

  renderNextStep() {
    const { recruiterTitle, opening, question, selectedValue, loading } = this.state;

    return (
      <ContentPush
        action={question.action}
        defaultValues={selectedValue}
        keepScrollTop
        loading={loading}
        onContinue={this.handleContinue}
        onInputChange={this.handleInputChange}
        question={question}
        recruiter={opening.recruiter}
        recruiterTitle={recruiterTitle}
        selectedValue={selectedValue}
        sendBack={this.sendBack}
        sendResponse={this.sendResponse}
        showHeader
        showRecruiterAvatar={question.type === 'interview'}
      />
    );
  }
}
