import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { apolloClient } from '@src/graphql/apollo';
import api from '@src/util/api';
import {
  GetLocationByIdDocument,
  GetLocationByIdQuery,
  GetAvailableCurrenciesQuery,
  GetAvailableCurrenciesDocument,
} from '@src/graphql/generated';
import { trackEvent } from '@src/metrics';
import { createStory } from '@src/util/createStory';
import EditStory from '@src/components/AvailabilityRequest/EditStory';
import SendingRequest from '@src/components/AvailabilityRequest/SendingRequest';
import { sendRequest } from '@src/components/AvailabilityRequest/actions';
import { setContent as setModalContent, closeModal } from '@src/redux/actions/slideUpModal';
import { setNavigationVisibility } from '@src/redux/actions/navigation';
import Story from '@src/components/AvailabilityRequest/Story';
import { getUser } from '@src/graphql/user/actions/getUser';
import './styles.scss';

@connect(null, {
  closeModal,
  push,
  setModalContent,
  setNavigationVisibility,
})
export default class UserStory extends PureComponent {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    itemWithEditIcon: PropTypes.bool,
    setModalContent: PropTypes.func.isRequired,
    setNavigationVisibility: PropTypes.func.isRequired,
  };

  state = {
    availabilityRequestId: undefined,
    editing: false,
    profileChanged: false,
    saving: false,
    story: undefined,
  };

  async componentDidMount() {
    const { setNavigationVisibility } = this.props;

    const user = await getUser();

    setNavigationVisibility(false);

    const { userMatchingProfile } = await api('/api/app/user-matching-profile?type=inbox');

    const { data } = await apolloClient.query<GetAvailableCurrenciesQuery>({
      fetchPolicy: 'no-cache',
      query: GetAvailableCurrenciesDocument,
    });

    let location = user?.location;

    if (userMatchingProfile.availabilityRequest.location) {
      const { data } = await apolloClient.query<GetLocationByIdQuery>({
        fetchPolicy: 'no-cache',
        query: GetLocationByIdDocument,
        variables: {
          id: userMatchingProfile.availabilityRequest.location,
        },
      });

      location = data.location;
    }

    const { speciality, salary, seniorities } = userMatchingProfile;
    const skills = map(userMatchingProfile.skills, 'skill');

    this.setState({
      availabilityRequestId: userMatchingProfile.availabilityRequest._id,
      currencies: data.availableCurrencies,
      story: createStory({ location, salary, seniorities, skills, speciality }),
    });
  }

  editStory = prop => {
    const { currencies, story } = this.state;
    this.setState({ editing: true });

    this.props.setModalContent(
      <EditStory
        currencies={currencies}
        editing={prop}
        enableLocation
        onStoryChange={this.onStoryChange}
        story={story}
      />,
    );
  };

  onSave = async () => {
    const { story, availabilityRequestId, profileChanged } = this.state;

    this.setState({ saving: true });

    trackEvent({ context: 'Profile', interaction: 'Save changes', itemType: 'Profile', itemValue: 'inbox' });

    profileChanged && (await sendRequest(story, availabilityRequestId, 'inbox'));

    this.setState({ profileChanged: false });
    setTimeout(() => this.setState({ saving: false }), 1250);
  };

  onStoryChange = (story, canSave) => {
    this.setState({ editingStory: false, profileChanged: true, story }, () => {
      if (canSave) {
        this.onSave();
      }

      this.props.closeModal();
    });
  };

  render() {
    const { story, currencies, saving } = this.state;
    const profileChanged = this.state.profileChanged;

    if (!story) {
      return null;
    }

    if (saving) {
      return (
        <div>
          <SendingRequest sendingRequest={profileChanged} sendingText="Saving..." sentText="Saved" />
        </div>
      );
    }

    return (
      <div className="user-story">
        <Story
          currencies={currencies}
          editStory={this.editStory}
          enableLocation
          itemWithEditIcon={this.props.itemWithEditIcon}
          story={story}
          wrapperClassName="user-story__container"
        />
      </div>
    );
  }
}
