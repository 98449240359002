import React, { FC } from 'react';
import classnames from 'classnames';
import moment from 'moment';
import { BlueButton, PinkButton } from '@src/components/Button';
import FadeInImage from '@src/components/FadeInImage';
import dateStringFromDate from '@src/util/dateStringFromDate';
import { htmlifyMessage } from '@src/util/htmlifyMessage';
import { Avatar } from '@src/components/Avatar';
import { getNameInitials } from '@src/util/getNameInitials';
import { preventEvents } from '@src/util/preventEvents';
import { AvatarSize } from '../Avatar/enums/avatarSize.enum';
import { ContactRequestProps } from './props';
import './styles.scss';

export const ContactRequest: FC<ContactRequestProps> = ({
  isUnread,
  companyName,
  onDecline,
  onApprove,
  companyProfileLogo,
  fullName,
  avatarColor,
  avatarUrl,
  message,
  shouldShowActions,
  recruiterTitle,
  messageCreatedAt,
  className,
}) => {
  return (
    <div className={classnames('contact-request', className)}>
      <div className="contact-request__recruiter">
        <div className="contact-request__recruiter-avatar-wrapper">
          <Avatar
            avatarColor={avatarColor}
            className="contact-request__recruiter-avatar"
            initials={getNameInitials(fullName)}
            size={AvatarSize.X_LARGE}
            src={avatarUrl}
          />

          {companyProfileLogo && <FadeInImage className="contact-request__recruiter-logo" src={companyProfileLogo} />}
        </div>

        <div className="contact-request__avatar-details">
          <div className="contact-request__avatar-details-important">
            <div
              className={classnames(
                'contact-request__avatar-details-direct',
                !!isUnread && 'contact-request__avatar-details-direct--unread',
              )}
            >
              {fullName}
            </div>

            <div className="contact-request__side-data">
              {!!isUnread && <span className="contact-request__unread" />}

              {!!messageCreatedAt && (
                <span className="contact-request__timestamp">
                  {dateStringFromDate(moment(messageCreatedAt).toDate())}
                </span>
              )}
            </div>
          </div>

          <span
            className={classnames(
              'contact-request__avatar-details-secondary',
              !!isUnread && 'contact-request__avatar-details-secondary--unread',
            )}
            dangerouslySetInnerHTML={{
              __html: htmlifyMessage(message),
            }}
          />

          <span className="contact-request__avatar-details-minor">
            <span>{recruiterTitle}</span>

            <span>
              {!!companyName && (
                <>
                  &nbsp;•&nbsp;
                  {companyName}
                </>
              )}
            </span>
          </span>

          {shouldShowActions && (
            <div className="contact-request__footer-actions">
              <BlueButton
                className="contact-request__footer-actions-button"
                isFilled
                onClick={preventEvents(() => onApprove?.())}
              >
                Accept
              </BlueButton>

              <PinkButton isFilled onClick={preventEvents(() => onDecline?.())}>
                Decline
              </PinkButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
