import { LOCATION_CHANGE } from 'connected-react-router';
import { NAVIGATION_VISIBILITY, NAVIGATION_REFRESHABLE } from '@src/redux/actions/navigation';

const DEFAULT_STATE = {
  currentLocation: null,
  previousLocation: null,
  refreshable: false,
  visible: true,
};

export function navigation(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case NAVIGATION_VISIBILITY: {
      return {
        ...state,
        visible: action.visible,
      };
    }

    case NAVIGATION_REFRESHABLE: {
      return {
        ...state,
        refreshable: action.refreshable,
      };
    }

    case LOCATION_CHANGE:
      if (!state.previousLocation && !state.currentLocation) {
        return {
          ...state,
          currentLocation: action.payload.location.pathname,
          previousLocation: state.currentLocation,
        };
      }

      if (state.currentLocation === action.payload.location.pathname) {
        return state;
      }

      return {
        ...state,
        currentLocation: action.payload.location.pathname,
        previousLocation: state.currentLocation,
        visible: true,
      };
    default:
      return state;
  }
}
