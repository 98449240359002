import React, { FC, Fragment } from 'react';
import { isEmpty } from 'lodash';
import classnames from 'classnames';
import Svg from 'svg-inline-react';
import FrForwardSvg from 'assets/images/fr-forward.svg';
import FrEditSvg from 'assets/images/fr-edit.svg';
import { useHistory } from 'react-router-dom';
import { trackEvent } from '@src/metrics';
import { AwardFeedItem } from '@src/containers/Feed/AwardFeedItem';
import { CompanyReviewFeedItem } from '@src/containers/Feed/CompanyReviewFeedItem';
import { EmptyState } from '@src/components/EmptyState';
import { FeedItemSkeleton } from '@src/containers/Feed/Skeleton';
import { setContent as setModalContent, closeModal } from '@src/redux/actions/slideUpModal';
import { OverallScore } from '@src/views/CompanyReviews/OverallScore';
import { ScoresTable } from '@src/views/CompanyReviews/ScoresTable';
import { UserAssociationModal } from '@src/views/CompanyReviews/UserAssociationModal';
import createLink from '@src/util/createLink';
import { COMPANY_REVIEWS_ROUTE, CREATE_COMPANY_REVIEW_ROUTE } from '@src/constants/routes';
import { useGetCompanyReviewsForJobOpeningPageQuery } from '@src/graphql/generated';
import { useAppDispatch } from '@src/store';
import { Context } from '@src/metrics/enums/context.enum';
import { Interaction } from '@src/metrics/enums/interaction.enum';
import { ItemType } from '@src/metrics/enums/itemType.enum';
import { ReviewsSectionProps } from './props';
import './styles.scss';

export const ReviewsSection: FC<ReviewsSectionProps> = ({ companyId, onCompleted }) => {
  const dispatch = useAppDispatch();

  const history = useHistory();

  const { data, loading: isLoading } = useGetCompanyReviewsForJobOpeningPageQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: () => {
      onCompleted?.();
    },
    variables: {
      id: companyId,
      paginationOptions: { limit: 3 },
    },
  });

  const handleWriteAReview = (deepLinkUrl: string): void => {
    if (window.isPublicApp) {
      window.open(deepLinkUrl, '_blank');
    } else {
      trackEvent({
        context: Context.OPENING,
        interaction: Interaction.OPEN_USER_ASSOCIATION_MODAL,
        itemType: ItemType.WRITE_A_REVIEW,
      });

      dispatch(
        setModalContent({
          content: (
            <UserAssociationModal
              onClose={() => dispatch(closeModal())}
              onStatusClick={userAssociation => {
                trackEvent({
                  context: Context.OPENING,
                  interaction: Interaction.CLICK_USER_ASSOCIATION,
                  itemType: ItemType.USER_ASSOCIATION_MODAL,
                  itemValue: userAssociation,
                });

                history.push(
                  createLink({
                    link: CREATE_COMPANY_REVIEW_ROUTE,
                    params: { companyId },
                    queryParams: { userAssociation },
                  }),
                );
              }}
            />
          ),
          height: 'auto',
        }),
      );
    }
  };

  const handleSeeMoreReviewsClick = () => {
    trackEvent({
      context: Context.OPENING,
      interaction: Interaction.OPEN_COMPANY_REVIEWS,
      itemType: ItemType.SEE_MORE_REVIEWS,
    });

    history.push(
      createLink({
        link: COMPANY_REVIEWS_ROUTE,
        params: { companyId },
      }),
    );
  };

  if (isLoading) {
    return null;
  }

  const deepLinkUrl = data?.company.deepLinkUrl;
  const companyRatings = data?.company.reviewsOverview.ratings;
  const totalRating = data?.company.reviewsOverview.ratings.total;
  const totalRatingCount = data?.company.reviewsOverview.totalCount || 0;
  const currentUserReview = data?.company.reviewsOverview.reviewForCurrentUser;
  const feed = data?.company.reviews.result || [];
  const feedTotalCount = data?.company.reviews.pagination.totalCount || 0;

  return (
    <div className="opening-reviews-section">
      <h3 className="opening-reviews-section__title">REVIEWS</h3>
      {totalRatingCount > 0 && (
        <Fragment>
          <OverallScore averageRating={totalRating} totalRatings={totalRatingCount} />

          <ScoresTable ratings={companyRatings} />
        </Fragment>
      )}

      <div className="opening-reviews-section__feed-wrapper">
        {!isEmpty(feed) &&
          feed.map(review => {
            switch (review.type) {
              case 'COMPANY_RATINGS':
                return <CompanyReviewFeedItem companyDeepLink={deepLinkUrl} key={review.id} review={review} />;
              case 'COMPANY_AWARDS':
                return <AwardFeedItem companyDeepLink={deepLinkUrl} key={review.id} review={review} />;
              default:
                return null;
            }
          })}

        {!isLoading && isEmpty(feed) && (
          <EmptyState
            imageClassName="opening-reviews-section__empty-state-image"
            imageUrl="/images/empty-states/award-empty-state-3x.png"
            imageWrapperClassName="opening-reviews-section__empty-state-image-wrapper"
            infoClassName="opening-reviews-section__empty-state-info-wrapper"
            subTitle={<Fragment>Share the best reasons to work for this company and help other job seekers.</Fragment>}
            title="No reviews yet"
            titleClassName="opening-reviews-section__empty-state-title"
            wrapperClassName="opening-reviews-section__empty-state-wrapper"
          />
        )}

        {isLoading && <FeedItemSkeleton />}

        {isEmpty(currentUserReview) && (
          <div
            className={classnames(
              'opening-reviews-section__write-a-review',
              isEmpty(feed) && 'opening-reviews-section__write-a-review--feed-empty',
            )}
            onClick={() => {
              if (deepLinkUrl) {
                handleWriteAReview(deepLinkUrl);
              }
            }}
          >
            <div className="opening-reviews-section__write-a-review-title">
              <Svg src={FrEditSvg} />
              <span>Write a review</span>
            </div>
            <Svg src={FrForwardSvg} />
          </div>
        )}

        {feedTotalCount > 2 && (
          <div className="opening-reviews-section__see-all-reviews" onClick={handleSeeMoreReviewsClick}>
            See more reviews
          </div>
        )}
      </div>
    </div>
  );
};
