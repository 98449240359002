import React, { FC } from 'react';
import { get } from 'lodash';
import { TreeSelect } from '@src/components/TreeSelect';
import { useGetSkillsQuery, useSenioritiesQuery } from '@src/graphql/generated';
import { mapItem } from '@src/util/createStory';
import { MultiSelectProps } from './props';

const DEFAULT_SKILL_LIMIT = 6;
const SECONDARY_SKILL_LIMIT = 5;
const SKILLS_LIMIT_BY_KEY: Record<string, number> = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  company_revenue_field: 1,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  data_science_tool: SECONDARY_SKILL_LIMIT,
  default: DEFAULT_SKILL_LIMIT,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  design_field: SECONDARY_SKILL_LIMIT,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  design_tool: SECONDARY_SKILL_LIMIT,
  language: SECONDARY_SKILL_LIMIT,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  management_department_field: 1,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  management_level: 1,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  product_field: SECONDARY_SKILL_LIMIT,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  programming_language: 8,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  sales_experience_field: 2,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  software_engineering_tool: SECONDARY_SKILL_LIMIT,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  sysadmin_tool: SECONDARY_SKILL_LIMIT,
};

export const MultiSelect: FC<MultiSelectProps> = ({
  editing,
  specialityId,
  skillTag,
  defaultValues,
  limit,
  onSelect,
}) => {
  const queryResult =
    editing === 'seniorities'
      ? useSenioritiesQuery()
      : useGetSkillsQuery({ variables: { specialityId: specialityId } });

  const items =
    editing === 'seniorities'
      ? get(queryResult, 'data.seniorities', [])
      : get(queryResult, 'data.skills', []).filter(skill => skill.tags.includes(skillTag));

  if (queryResult.loading) {
    return <span className="availability-request-loading">Loading...</span>;
  }

  return (
    <TreeSelect
      className="slide-up-item"
      limit={limit || SKILLS_LIMIT_BY_KEY[skillTag] || SKILLS_LIMIT_BY_KEY.default}
      onValueSelect={onSelect}
      options={items.map(mapItem)}
      selectedOptions={defaultValues}
    />
  );
};
