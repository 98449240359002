import React, { FC, useEffect, useMemo } from 'react';
import { matchPath } from 'react-router-dom';
import { getScroll } from '@src/redux/selectors/scroll';
import { useAppDispatch, useAppSelector } from '@src/store';
import { PageNames } from '@src/redux/selectors/scroll/enums/pageNames.enum';
import { Interaction } from '@src/metrics/enums/interaction.enum';
import { Context } from '@src/metrics/enums/context.enum';
import {
  COMPANY_ABOUT_ROUTE,
  COMPANY_REVIEWS_ROUTE,
  COMPANY_ROUTE,
  CREATE_COMPANY_REVIEW_ROUTE,
  JOB_OPENING_ROUTE,
} from '@src/constants';
import { getScrollableContainer } from '@src/App';
import { getPreviousLocation } from '@src/redux/selectors/navigation';
import { setScroll } from '@src/redux/actions/scroll';
import { useQueryParams } from '@src/customHooks/useQueryParams';
import { PlaceTypeEnum, useGetUserQuery } from '@src/graphql/generated';
import { REMOTE_PLACE_ID } from '@src/constants/placeIds';
import { trackEvent } from '@src/metrics';
import { useCategories } from './hooks/useCategories';
import { QueryParams } from './interfaces/queryParams.interface';
import { PageHeader } from './PageHeader';
import { Places } from './Places';
import { usePlaces } from './hooks/usePlaces';
import { useSearchOpenings } from './hooks/useSearchOpenings';
import { OpeningFeed } from './OpeningFeed';
import { Place } from './interfaces/place.interface';
import { Feed } from './enums/feed.enum';
import { useRelevantOffersFeed } from './hooks/useRelevantOffersFeed';
import { DEFAULT_CATEGORY, DEFAULT_FEED } from './constants/categories';
import './styles.scss';

export const JobsSearch: FC = () => {
  const dispatch = useAppDispatch();

  const { queryParams, updateQueryParams, removeQueryParams } = useQueryParams<QueryParams>();

  const isPlaceSearchActive = !!queryParams.isPlaceSearchActive || false;

  const scrolls = useAppSelector(getScroll);

  const scroll = scrolls?.[PageNames.JOB_SEARCH] || 0;

  const previousLocation = useAppSelector(getPreviousLocation);

  const scrollableContainer = getScrollableContainer();

  const { data: userData } = useGetUserQuery();

  const user = userData?.currentUser;
  const activeFeed = queryParams.activeFeed || DEFAULT_FEED;

  const {
    openings: searchOpenings,
    isOpeningsLoading: isSearchOpeningsLoading,
    isOpeningsInitialLoading: isSearchOpeningsInitialLoading,
    pagination: searchOpeningsPagination,
  } = useSearchOpenings({
    categoryType: queryParams.category,
    places: queryParams.places,
    searchPattern: queryParams.searchPattern,
    shouldSkip: activeFeed !== Feed.CATEGORIES,
  });

  const {
    feed: relevantOpenings,
    isLoading: isRelevantOpeningsLoading,
    isInitialLoading: isRelevantOpeningsInitialLoading,
    pagination: relevantOpeningsPagination,
  } = useRelevantOffersFeed({
    places: queryParams.places,
    searchPattern: queryParams.searchPattern,
    shouldSkip: activeFeed !== Feed.FOR_YOU,
  });

  const pagination = useMemo(() => {
    if (activeFeed === Feed.FOR_YOU) {
      return relevantOpeningsPagination;
    }

    return searchOpeningsPagination;
  }, [activeFeed, relevantOpeningsPagination, searchOpeningsPagination]);

  const feed = useMemo(() => {
    if (activeFeed === Feed.FOR_YOU) {
      return relevantOpenings;
    }

    return searchOpenings || [];
  }, [activeFeed, relevantOpenings, searchOpenings]);

  const hasMoreResults = pagination?.hasMoreResults;

  const { categoriesWithCounts, isCategoriesLoading } = useCategories({
    searchPattern: queryParams.searchPattern,
    selectedPlaces: queryParams.places,
  });

  const filteredCategories = useMemo(() => {
    return categoriesWithCounts.filter(category => isCategoriesLoading || !!category.count);
  }, [categoriesWithCounts, isCategoriesLoading]);

  const { places, isLoading: isPlacesLoading } = usePlaces({
    searchPattern: queryParams.placeSearchPattern,
  });

  const filteredPlaces = useMemo(() => {
    return places.filter(place => !queryParams.places?.some(selectedPlace => selectedPlace.id === place.id));
  }, [places, queryParams.places]);

  useEffect(() => {
    const shouldUpdateScroll =
      previousLocation &&
      [COMPANY_ROUTE, COMPANY_ABOUT_ROUTE, JOB_OPENING_ROUTE, COMPANY_REVIEWS_ROUTE, CREATE_COMPANY_REVIEW_ROUTE].find(
        route => matchPath(previousLocation, { path: route }),
      );

    scrollableContainer.scrollTop = shouldUpdateScroll ? scroll || 0 : 0;

    trackEvent({
      context: Context.DIGEST,
      interaction: Interaction.VIEW,
    });

    return () => {
      dispatch(setScroll(PageNames.JOB_SEARCH, scrollableContainer.scrollTop));
    };
  }, []);

  useEffect(() => {
    if (!queryParams.category || !queryParams.places?.length) {
      const userPlace: Place | undefined = user?.location?.country
        ? {
            countryShortName: user?.location?.country?.shortName,
            id: user?.location?.country?.id,
            name: user?.location?.country?.longName,
            type: PlaceTypeEnum.COUNTRY,
          }
        : undefined;

      const remotePlace: Place = {
        countryShortName: 'Remote',
        id: REMOTE_PLACE_ID,
        name: 'Remote',
        type: PlaceTypeEnum.REMOTE,
      };

      updateQueryParams({
        ...(!queryParams.places?.length
          ? {
              places: [
                ...(userPlace ? [userPlace] : []),
                // Apply remote place only if user is not from Estonia
                ...(userPlace?.countryShortName !== 'EE' ? [remotePlace] : []),
              ],
            }
          : {}),
        ...{
          activeFeed: activeFeed,
          category: DEFAULT_CATEGORY,
        },
      });
    }
  }, []);

  return (
    <div className="job-search">
      <PageHeader
        isCategoriesHidden={isPlaceSearchActive}
        isPlaceSearchActive={isPlaceSearchActive}
        setIsPlaceSearchActive={isPlaceSearchActive => {
          if (isPlaceSearchActive) {
            updateQueryParams({ isPlaceSearchActive: 'true' });

            return;
          }

          removeQueryParams(['isPlaceSearchActive']);
        }}
        categories={filteredCategories}
        isCategoriesLoading={isCategoriesLoading}
      />

      {isPlaceSearchActive && (
        <Places
          isLoading={isPlacesLoading}
          places={filteredPlaces}
          onAllPlacesSelect={() => {
            removeQueryParams(['places', 'isPlaceSearchActive']);
          }}
          onPlaceSelect={place => {
            updateQueryParams({
              isPlaceSearchActive: undefined,
              placeSearchPattern: '',
              places: [...(queryParams.places || []), place],
            });
          }}
        />
      )}

      {!isPlaceSearchActive && (
        <OpeningFeed
          isLoading={isSearchOpeningsLoading || isRelevantOpeningsLoading}
          feed={feed}
          isInitialLoading={isSearchOpeningsInitialLoading || isRelevantOpeningsInitialLoading}
          hasMoreResults={hasMoreResults}
        />
      )}
    </div>
  );
};
