import React, { FC } from 'react';
import Svg from 'svg-inline-react';
import classnames from 'classnames';
import { Color } from './roundedIcon.enum';
import { Props } from './props';
import './styles.scss';

export const RoundedIcon: FC<Props> = ({ src, className, color = Color.WHITE }) => {
  return (
    <Svg
      className={classnames('vt-verified-gateway-icon', `vt-verified-gateway-icon--${color}`, className)}
      src={src}
    />
  );
};
