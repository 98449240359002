import { clamp, round } from 'lodash';
import { rangePercent } from '@src/util/rangePercent';

export function scrollRange(scrollTop, wrapperNodeDistanceFromTop, componentHeight) {
  if (wrapperNodeDistanceFromTop && scrollTop <= wrapperNodeDistanceFromTop * 2 - 2 * componentHeight) {
    const shown = scrollTop / (wrapperNodeDistanceFromTop * 2 - 2 * componentHeight);
    if (shown >= 1) {
      return 1;
    } else if (shown <= 0) {
      return 0;
    }
    return round(shown, 2);
  } else {
    return 1;
  }
}

/**
 * @param {number} scrollTop - Scrollable container scroll top position
 * @param {node element} startingPointNode - When this node element reaches the top we display fixed header with fade in animation.
 * @param {node element} fixedHeaderNode - Fixed header wrapper node.
 * @param {number} fixedHeaderAppearStartingPoint - e.i if we pass 100, then fixed header appearance starts 100px earlier before starting point node reaches the top.
 * @param {number} startPointOpacitySpeed - Opacity speed compared to scroll speed.
 */
const OPACITY_SPEED_COMPARED_TO_SCROLL_SPEED = 1.25;
export const toggleFixedHeader = (
  scrollTop,
  startingPointNode,
  fixedHeaderNode,
  fixedHeaderAppearStartingPoint = 0,
  startPointOpacitySpeed = OPACITY_SPEED_COMPARED_TO_SCROLL_SPEED,
) => {
  if (startingPointNode && fixedHeaderNode) {
    const scroll = scrollRange(
      scrollTop,
      startingPointNode.offsetTop - fixedHeaderAppearStartingPoint,
      startingPointNode.offsetHeight,
    );
    const opacity = clamp(round(1 - scroll * startPointOpacitySpeed, 2), 0, 1);
    startingPointNode.style.opacity = opacity;
    fixedHeaderNode.style.opacity = scroll >= 0.5 ? clamp(rangePercent(scroll, [0.5, 0.8]), 0, 1) : 0;
    return parseFloat(fixedHeaderNode.style.opacity);
  }
};
