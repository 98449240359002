import React, { FC, useState } from 'react';
import ChatInput from '@src/components/ChatInput';
import { BlueButton, InvisibleButton } from '@src/components/Button';
import { apolloClient } from '@src/graphql/apollo';
import { closeModal } from '@src/redux/actions/slideUpModal';
import { isValidEmail } from '@src/util/email';
import { useAppDispatch } from '@src/store';
import { ApolloErrorCode } from '@src/enum/apolloErrorCode.enum';
import { UpdateUserDocument, UpdateUserMutation, UpdateUserMutationVariables } from '@src/graphql/generated';
import { captureError } from '@src/util/errors';
import './styles.scss';

export const UserEmailPopupModal: FC = () => {
  const dispatch = useAppDispatch();

  const [email, setEmail] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const onMailChange = (value: string) => {
    setEmail(value);

    if (errorMessage) {
      setErrorMessage(undefined);
    }
  };

  async function handleContinue() {
    try {
      await apolloClient.mutate<UpdateUserMutation, UpdateUserMutationVariables>({
        mutation: UpdateUserDocument,
        variables: {
          updateInput: {
            email,
          },
        },
      });

      dispatch(closeModal());
    } catch (error) {
      if (error.graphQLErrors && error.graphQLErrors[0]?.extensions?.code === ApolloErrorCode.RESOURCE_ALREADY_EXISTS) {
        setErrorMessage('This email is already taken.');
      } else {
        captureError(error);
      }
    }
  }

  return (
    <div className="ask-email-modal">
      <div className="ask-email-modal__title">Finish setting up your account</div>
      <div className="ask-email-modal__description">
        Provide your email to secure your account and to access it on any device.
      </div>

      <ChatInput onChange={onMailChange} placeholder={'Write your email here'} type={'email'} />
      {errorMessage ? <div className="ask-email-modal__error">{errorMessage}</div> : null}

      <div className="ask-email-modal__actions">
        <InvisibleButton onClick={() => dispatch(closeModal())}>Skip</InvisibleButton>

        <BlueButton
          className="ask-email-modal__actions--left-margin"
          disabled={!isValidEmail(email)}
          isFilled
          onClick={handleContinue}
        >
          Continue
        </BlueButton>
      </div>
    </div>
  );
};
