import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FixedHeader } from '@src/components/FixedHeader';
import { SenioritiesListWithOpenings } from '@src/containers/SenioritiesListWithOpenings';
import { searchTagsToFilterItems } from '@src/util/filters';
import { SearchOpeningEntityTagNameEnum } from '@src/graphql/generated';
import { ActionButtons } from '../ActionButtons';
import { SEARCH_RESULT_FILTERS_KEYS } from '../';
import { Skills } from '../Skills';
import { Locations } from '../Locations';
import './styles.scss';

export const Modal = ({
  onApplyFilters,
  activeView,
  onClose,
  searchTags,
  searchPattern,
  workOptions,
  locationDefaultView,
}) => {
  const [view, setView] = useState(activeView);
  const seniorities = useMemo(
    () => searchTagsToFilterItems(SearchOpeningEntityTagNameEnum.SENIORITY, searchTags),
    [searchTags],
  );
  const skills = useMemo(() => searchTagsToFilterItems(SearchOpeningEntityTagNameEnum.SKILL, searchTags), [searchTags]);
  const positionLocation = useMemo(
    () => searchTagsToFilterItems(SearchOpeningEntityTagNameEnum.POSITION_LOCATION, searchTags),
    [searchTags],
  );
  const allowedLocation = useMemo(
    () => searchTagsToFilterItems(SearchOpeningEntityTagNameEnum.REMOTE_ALLOWED_LOCATION, searchTags),
    [searchTags],
  );
  const location = useMemo(() => {
    if (positionLocation.length > 0) {
      return positionLocation;
    } else if (allowedLocation.length) {
      return allowedLocation;
    } else {
      return [];
    }
  }, [positionLocation, allowedLocation]);
  const areas = useMemo(
    () => searchTagsToFilterItems(SearchOpeningEntityTagNameEnum.REMOTE_ALLOWED_AREA, searchTags),
    [searchTags],
  );
  const countries = useMemo(
    () => searchTagsToFilterItems(SearchOpeningEntityTagNameEnum.REMOTE_ALLOWED_COUNTRY, searchTags),
    [searchTags],
  );

  const [selectedSeniorities, setSelectedSeniorities] = useState(seniorities);
  const [selectedSkills, setSelectedSkills] = useState(skills);
  const renderActiveView = view => {
    switch (view) {
      case SEARCH_RESULT_FILTERS_KEYS.SENIORITIES:
        return (
          <SenioritiesListWithOpenings
            activeSeniorities={selectedSeniorities}
            allSearchTags={[...searchTags, { tag: 'STATE', value: 'active' }]}
            onSenioritySelect={updatedSeniorities => setSelectedSeniorities(updatedSeniorities)}
            searchPattern={searchPattern}
          />
        );
      case SEARCH_RESULT_FILTERS_KEYS.SKILLS:
        return (
          <Skills
            activeSkills={selectedSkills}
            onSkillSelect={updatedSkills => setSelectedSkills(updatedSkills)}
            searchPattern={searchPattern}
            searchTags={[...searchTags, { tag: 'STATE', value: 'active' }]}
          />
        );
      case SEARCH_RESULT_FILTERS_KEYS.LOCATION:
        return (
          <Locations
            defaultAreas={areas}
            defaultCountries={countries}
            defaultLocation={location}
            defaultView={locationDefaultView}
            onApply={onApplyFilters}
            onClose={onClose}
            workOptions={workOptions}
          />
        );
      default:
        return null;
    }
  };

  const renderHeader = view => {
    switch (view) {
      case SEARCH_RESULT_FILTERS_KEYS.SENIORITIES:
        return (
          <FixedHeader
            close
            disableOpacity
            onBack={onClose}
            title="Filter by seniority"
            wrapperClassName="search-results-modal__header"
          />
        );
      case SEARCH_RESULT_FILTERS_KEYS.SKILLS:
        return (
          <FixedHeader
            close
            disableOpacity
            onBack={onClose}
            title="Filter by skills"
            wrapperClassName="search-results-modal__header"
          />
        );
      default:
        return null;
    }
  };

  const handleApply = view => {
    switch (view) {
      case SEARCH_RESULT_FILTERS_KEYS.SENIORITIES:
        onApplyFilters(selectedSeniorities);
        break;
      case SEARCH_RESULT_FILTERS_KEYS.SKILLS:
        onApplyFilters(selectedSkills);
        break;
    }
  };

  const handleFilterClear = () => {
    setSelectedSeniorities([]);
    setSelectedSkills([]);
  };

  return (
    <div className="search-results-modal fade-in">
      {renderHeader(view)}
      <div className="search-results-modal__body">{renderActiveView(view)}</div>
      {view !== SEARCH_RESULT_FILTERS_KEYS.LOCATION && (
        <div className="search-results-modal__footer">
          <ActionButtons
            blueButtonProps={{
              onClick: () => handleApply(view),
            }}
            invisibleButtonProps={{
              onClick: handleFilterClear,
            }}
          />
        </div>
      )}
    </div>
  );
};

Modal.propTypes = {
  activeView: PropTypes.string.isRequired,
  locationDefaultView: PropTypes.string.isRequired,
  onApplyFilters: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  searchPattern: PropTypes.string.isRequired,
  searchTags: PropTypes.array.isRequired,
  workOptions: PropTypes.array,
};
