import { isUndefined, isEmpty, omitBy } from 'lodash';
import { updateAvailabilityRequest } from '@src/redux/actions/availability';
import { updateUserMatchingProfile } from '@src/redux/actions/user';
import { idify } from '@src/util/idify';

export async function sendRequest(story, requestId, profileType) {
  const { seniorities, skills = [], specialities = [], track, salary, location } = story;

  const mappedSeniorities = seniorities.map(idify);

  const mappedSkills = skills
    .concat(specialities)
    .reduce((uniqueSkills, skill) => {
      if (!uniqueSkills.includes(idify(skill))) {
        uniqueSkills.push(idify(skill));
      }

      return uniqueSkills;
    }, [])
    .map(skill => ({ skill }));

  const requestPayload = {};

  if (location.id) {
    requestPayload.location = location.id;
  }

  if (!isEmpty(requestPayload)) {
    await updateAvailabilityRequest(requestId, requestPayload);
  }

  await updateUserMatchingProfile(
    omitBy(
      {
        availabilityRequest: requestId,
        salary: salary,
        seniorities: mappedSeniorities,
        skills: mappedSkills,
        speciality: idify(track),
        type: profileType,
      },
      isUndefined,
    ),
  );
}
