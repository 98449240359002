import React, { FC } from 'react';
import './styles.scss';

export const Skeleton: FC = () => {
  return (
    <div className="chat-skeleton-wrapper pulse-fade">
      <div className="chat-skeleton-wrapper__normal chat-skeleton-wrapper__normal--large" />
      <div className="chat-skeleton-wrapper__normal chat-skeleton-wrapper__normal--small" />
      <div className="chat-skeleton-wrapper__thick chat-skeleton-wrapper__normal--small" />
      <div className="chat-skeleton-wrapper__chat-card">
        <div className="chat-skeleton-wrapper__circle" />
        <div className="chat-skeleton-wrapper__card-content">
          <div className="chat-skeleton-wrapper__normal chat-skeleton-wrapper__normal--large" />
          <div className="chat-skeleton-wrapper__normal chat-skeleton-wrapper__normal--small" />
        </div>
      </div>
      <div className="chat-skeleton-wrapper__chat-card">
        <div className="chat-skeleton-wrapper__circle" />
        <div className="chat-skeleton-wrapper__card-content">
          <div className="chat-skeleton-wrapper__normal chat-skeleton-wrapper__normal--large" />
          <div className="chat-skeleton-wrapper__normal chat-skeleton-wrapper__normal--small" />
        </div>
      </div>
    </div>
  );
};
