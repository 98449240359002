import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const DISTANCE_FROM_BOTTOM_TO_LOAD_MORE_RESULTS = 300;

export function RenderOnScroll({ renderLimit, scrollRef, children }) {
  const [limit, setLimit] = useState(renderLimit);

  const onScroll = function () {
    const elem = scrollRef.current;
    if (elem) {
      const distanceFromBottom = elem.scrollHeight - elem.offsetHeight - elem.scrollTop;

      if (distanceFromBottom < DISTANCE_FROM_BOTTOM_TO_LOAD_MORE_RESULTS) {
        setLimit(limit + renderLimit);
      }
    }
  };

  useEffect(() => {
    scrollRef.current && scrollRef.current.addEventListener('scroll', onScroll);

    return () => {
      scrollRef.current && scrollRef.current.removeEventListener('scroll', onScroll);
    };
  }, [limit]);

  return children.slice(0, limit);
}

RenderOnScroll.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  renderLimit: PropTypes.number.isRequired,
  scrollRef: PropTypes.oneOfType([
    // Or the instance of a DOM native element (see the note about SSR)
    // eslint-disable-next-line no-undef
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};
