import React, { forwardRef } from 'react';
import classnames from 'classnames';
import { HorizontalScrollerProps } from './props';
import './styles.scss';

export const HorizontalScroller = forwardRef<HTMLDivElement, HorizontalScrollerProps>(
  ({ children, className, containerClassName, style, scrollerRef }, ref) => {
    return (
      <div className={classnames('horizontal-scroller-wrapper', className)} ref={ref} style={style}>
        <div className={classnames('horizontal-scroller-wrapper__container', containerClassName)} ref={scrollerRef}>
          {children}
        </div>
      </div>
    );
  },
);

HorizontalScroller.displayName = 'HorizontalScroller';
