import React, { FC } from 'react';
import './styles.scss';

export const Skeleton: FC = () => {
  return (
    <div className="frank-message-request-card-skeleton pulse-fade">
      <div className="frank-message-request-card-skeleton__header">
        <div className="frank-message-request-card-skeleton__circe" />
        <div className="frank-message-request-card-skeleton__user-lines">
          <span />
          <span />
        </div>
      </div>

      <div className="frank-message-request-card-skeleton__message">
        <span />
        <span />
      </div>
      <div className="frank-message-request-card-skeleton__box" />
    </div>
  );
};
