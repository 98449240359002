import path from 'path';
import { PageNames } from '@src/redux/selectors/scroll/enums/pageNames.enum';
import { ScrollSetAction } from './scroll.interface';

export const SCROLL_SET = path.join(__dirname, 'scrollSet');
export const setScroll = (scrollKey: PageNames, scroll: number): ScrollSetAction => {
  return {
    scroll: scroll,
    scrollKey: scrollKey,
    type: SCROLL_SET,
  };
};
