import qs from 'qs';
import { isEmpty, orderBy } from 'lodash';
import { SEARCH_TAGS } from '@src/constants';
import { ALL_SPECIALITIES, ALL_COUNTRIES, ALL_SENIORITIES } from './constants';

export const normalizeSpecialitiesForFilter = locationSearch => {
  const filters = qs.parse(locationSearch, { ignoreQueryPrefix: true });
  const specialities = Object.values(filters).filter(item => item.tag === SEARCH_TAGS.SPECIALITY);

  if (isEmpty(specialities)) {
    return [ALL_SPECIALITIES];
  }

  return specialities.map(item => {
    return {
      id: item.value,
      key: item.tag,
    };
  });
};

export const normalizeSenioritiesForFilter = locationSearch => {
  const filters = qs.parse(locationSearch, { ignoreQueryPrefix: true });
  const seniorities = Object.values(filters).filter(item => item.tag === SEARCH_TAGS.SENIORITY);

  if (isEmpty(seniorities)) {
    return [ALL_SENIORITIES];
  }

  return seniorities.map(item => {
    return {
      id: item.value,
      key: item.tag,
    };
  });
};

export const normalizeCountriesForFilter = locationSearch => {
  const { POSITION_COUNTRY, REMOTE, RELOCATION } = SEARCH_TAGS;
  const filters = qs.parse(locationSearch, { ignoreQueryPrefix: true });
  const countries = Object.values(filters).filter(item => [POSITION_COUNTRY, REMOTE, RELOCATION].includes(item.tag));

  if (isEmpty(countries)) {
    return [ALL_COUNTRIES];
  }

  return countries.map(item => {
    return {
      id: [REMOTE, RELOCATION].includes(item.tag) ? item.tag : item.value,
      key: item.tag,
    };
  });
};

export const normalizeFetchedCountriesForFilter = countries => {
  const normalizedCountries = countries.map(({ id, longName, shortName }) => {
    return {
      countryShortName: shortName,
      id,
      name: longName,
    };
  });

  return orderBy(normalizedCountries, 'name', 'asc');
};
