import React from 'react';
import './styles.scss';

export const JobOpeningSkeletonLoading = () => {
  return (
    <div className="skeleton-job-opening pulse-fade">
      <div className="skeleton-job-opening__section-one">
        <div className="skeleton-job-opening__avatar" />
        <div className="skeleton-job-opening__content">
          <div className="skeleton-job-opening__bar skeleton-job-opening__bar--small" />
          <div className="skeleton-job-opening__bar skeleton-job-opening__bar--medium skeleton-job-opening__bar--margin-small" />
        </div>
      </div>

      <div className="skeleton-job-opening__section-two">
        <div className="skeleton-job-opening__bar skeleton-job-opening__bar--medium skeleton-job-opening__bar--margin-medium" />
        <div className="skeleton-job-opening__bar skeleton-job-opening__bar--full skeleton-job-opening__bar--margin-medium" />
        <div className="skeleton-job-opening__bar skeleton-job-opening__bar--full skeleton-job-opening__bar--margin-medium" />
        <div className="skeleton-job-opening__bar skeleton-job-opening__bar--full skeleton-job-opening__bar--margin-medium" />
        <div className="skeleton-job-opening__bar skeleton-job-opening__bar--big skeleton-job-opening__bar--margin-medium" />
      </div>
    </div>
  );
};
