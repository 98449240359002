import React from 'react';
import PropTypes from 'prop-types';
import FrCouchSvg from 'assets/images/fr-couch.svg';
import FrKeySvg from 'assets/images/fr-key.svg';
import BuildingSvg from '@shared/assets/svgs/building.svg';
import FrMoneySvg from 'assets/images/fr-money.svg';
import FrBonusSvg from 'assets/images/fr-bonus.svg';
import FrBoardingTicketSvg from 'assets/images/fr-boarding-ticket.svg';
import FrTruckSvg from 'assets/images/fr-truck.svg';
import VisaSvg from '@shared/assets/svgs/visa.svg';
import { ItemsList, Item } from '../ItemsList';
import './styles.scss';

const SVG_MAP = {
  apartment_search: FrCouchSvg,
  flight_tickets: FrBoardingTicketSvg,
  moving_expenses_coverage: FrMoneySvg,
  relocation_help: FrTruckSvg,
  relocation_tips: FrTruckSvg,
  rental_deposit_coverage: FrKeySvg,
  sign_up_bonuses: FrBonusSvg,
  temporary_housing: BuildingSvg,
  visa_services: VisaSvg,
};

export const RelocationPackages = ({ relocationBenefits }) => {
  if (!relocationBenefits || relocationBenefits.length === 0) {
    return null;
  }

  return (
    <div className="opening-relocation-packages">
      <ItemsList>
        {relocationBenefits.map(item => (
          <Item
            data={<span className="opening-relocation-packages__item--highlight">{item.value}</span>}
            icon={SVG_MAP[item.key]}
            key={item.key}
          />
        ))}
      </ItemsList>
    </div>
  );
};

RelocationPackages.propTypes = {
  relocationBenefits: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired,
  ),
};
