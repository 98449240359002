import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { FrankAvatar } from '@src/components/FrankAvatar';
import ChatBubble from '@src/components/ChatBubble';
import './styles.scss';

export default function View({ className }) {
  return (
    <div className={classnames('error-catch slide-up-item delay-1', className)}>
      <FrankAvatar />
      <ChatBubble value="Oh no...you have stumbled upon a bug. 😔 Our exterminators are on it. 🔥" />
    </div>
  );
}

View.propTypes = {
  className: PropTypes.string,
};
