import { useEffect } from 'react';
import { recordSignals } from '@src/graphql/signals/actions/recordSignals';
import {
  SignalEntityTypeEnum,
  SignalTypeEnum,
  useGetOpeningLazyQuery,
  useGetOpeningMatchLazyQuery,
} from '@src/graphql/generated';
import { UseOpeningParams, UseOpeningValue } from './interfaces/useOpening.interface';

export const useOpening = ({ openingId, onCompleted }: UseOpeningParams): UseOpeningValue => {
  const [fetchOpening, { data: openingData, loading: isOpeningLoading, refetch: openingRefetch }] =
    useGetOpeningLazyQuery({
      onCompleted: data => {
        recordSignals({
          items: [
            {
              id: data.opening.id,
              type: SignalEntityTypeEnum.OPENING,
            },
          ],
          signalType: SignalTypeEnum.VIEW_DETAILS,
        });

        onCompleted?.();
      },
    });

  const [
    fetchOpeningMatch,
    { refetch: openingMatchAndRefetch, data: openingMatchAndData, loading: isOpeningMatchAndLoading },
  ] = useGetOpeningMatchLazyQuery({
    onCompleted: () => {
      onCompleted?.();
    },
  });

  useEffect(() => {
    if (!openingId) {
      return;
    }

    if (!window.isPublicApp) {
      if (openingMatchAndData) {
        openingMatchAndRefetch({
          openingId: openingId,
        });
      } else {
        fetchOpeningMatch({
          fetchPolicy: 'network-only',
          variables: {
            openingId: openingId,
          },
        });
      }
    }

    if (openingData) {
      openingRefetch({
        openingId: openingId,
      });
    } else {
      fetchOpening({
        variables: {
          openingId: openingId,
        },
      });
    }
  }, [openingId]);

  const isLoading = isOpeningLoading || isOpeningMatchAndLoading;

  const opening = {
    id: openingId,
    ...openingData?.opening,
    ...openingMatchAndData?.opening,
  };

  return {
    isLoading: isLoading,
    opening: opening,
  };
};
