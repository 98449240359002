import React, { FC } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import Filled from '@src/views/Filled';
import { CompanyProfile } from '@src/views/CompanyProfile';
import { JobOpening } from '@src/views/JobOpening';
import { CompanyReviews } from '@src/views/CompanyReviews';
import { Search } from '@src/views/Search';
import { SearchResult } from '@src/views/SearchResult';
import {
  COMPANY_ROUTE,
  SEARCH_ROUTE,
  SEARCH_RESULT_ROUTE,
  JOB_OPENING_ROUTE,
  FILLED_ROUTE,
  COMPANY_REVIEWS_ROUTE,
} from '@src/constants/routes';

/**
 * If you decide to add more routes here, please make sure to add them
 * to the backend/routes/index.js file as well.
 */
export const Routes: FC = () => {
  const location = useLocation();

  return (
    <Switch location={location}>
      <Route exact path={SEARCH_ROUTE}>
        <Search />
      </Route>

      <Route exact path={SEARCH_RESULT_ROUTE}>
        <SearchResult />
      </Route>

      <Route exact path={FILLED_ROUTE}>
        <Filled />
      </Route>

      <Route path={COMPANY_ROUTE}>
        <CompanyProfile />
      </Route>

      <Route exact path={JOB_OPENING_ROUTE}>
        <JobOpening />
      </Route>

      <Route exact path={COMPANY_REVIEWS_ROUTE}>
        <CompanyReviews />
      </Route>

      <Route
        path="*"
        render={() => {
          return <Redirect to={SEARCH_ROUTE} />;
        }}
      />
    </Switch>
  );
};
