import React, { useState, useEffect, FC } from 'react';
import classnames from 'classnames';
import Svg from 'svg-inline-react';
import starSvg from 'assets/images/fr-star.svg';
import { RatingColor } from './enums/ratingColor.enum';
import { RatingSize } from './enums/ratingSize.enum';
import { RatingInterface } from './interfaces/rating.interface';
import { RatingProps } from './props';
import './styles.scss';

function getRatings(maxRating: number, result: number, shouldDisplayOnlyActiveOnes?: boolean): RatingInterface[] {
  const ratings = [];
  const maxIndex = shouldDisplayOnlyActiveOnes ? result : maxRating;

  for (let index = 1; index <= maxIndex; index++) {
    const star = {
      isSelected: index <= result,
      rating: index,
    };
    ratings.push(star);
  }

  return ratings;
}

export const Rating: FC<RatingProps> = ({
  id,
  maxRating,
  size,
  activeRating,
  setActiveRating,
  shouldHaveBackground,
  wrapperClassName,
  activeColor,
  isDisabled,
  shouldDisplayOnlyActiveOnes,
}) => {
  const [ratings, setRatings] = useState(() => {
    const initState = getRatings(maxRating, activeRating, shouldDisplayOnlyActiveOnes);

    return initState;
  });

  const classes = classnames(
    'fr-rating',
    shouldHaveBackground && 'fr-rating--with-background',
    wrapperClassName,
    `fr-rating--${size}`,
  );

  useEffect(() => {
    setRatings(getRatings(maxRating, activeRating, shouldDisplayOnlyActiveOnes));
  }, [activeRating]);

  function handleMouseEnter(ratings: RatingInterface[], rating: number) {
    const activeRating = ratings.map(item => ({
      ...item,
      isSelected: item.rating <= rating,
    }));

    setRatings(activeRating);
  }

  function handleMouseLeave(maxRating: number, activeRating: number) {
    setRatings(getRatings(maxRating, activeRating));
  }

  return (
    <div className={classes}>
      {ratings.map(rating => {
        return (
          <span
            className={classnames({
              [`fr-rating--selected-${activeColor}`]: rating.isSelected,
            })}
            key={rating.rating}
            onClick={event => {
              event.stopPropagation();
              !isDisabled && setActiveRating?.(rating, id);
            }}
            onMouseEnter={() => !isDisabled && handleMouseEnter(ratings, rating.rating)}
            onMouseLeave={() => !isDisabled && handleMouseLeave(maxRating, activeRating)}
          >
            <Svg src={starSvg} />
          </span>
        );
      })}
    </div>
  );
};

Rating.defaultProps = {
  activeColor: RatingColor.YELLOW,
  size: RatingSize.LARGE,
};
