import qs from 'qs';
import { isString, isObject, reduce } from 'lodash';
import { CreateLinkParams } from './interfaces/createLink.interface';

const OPTIONAL_PARAM_TEST = new RegExp(':.+[?]');

/**
 * link = link param from Routes/links
 */
export default function createLink(
  linkParam: string | CreateLinkParams,
  { params, queryParams }: CreateLinkParams | undefined = {},
): string {
  if (!linkParam) {
    throw new Error('Please provide link');
  }

  const link = isString(linkParam.link) ? linkParam.link : linkParam;

  if (isObject(linkParam)) {
    params = linkParam.params;
    queryParams = linkParam.queryParams;
  }

  if (!link) {
    throw new Error('Please provide link');
  }

  const qp = qs.stringify(queryParams, { addQueryPrefix: true });

  let constructedLink = reduce(
    params,
    (result, value, key) => {
      return result.replace(new RegExp(`:${key}[?]?`), value || '');
    },
    link,
  );

  constructedLink = constructedLink.replace(OPTIONAL_PARAM_TEST, '');
  constructedLink = constructedLink.replace(/\/$/, '');
  constructedLink = constructedLink + qp;

  if (constructedLink.indexOf(':') !== -1) {
    throw new Error('Constructed link is missing params');
  }

  return constructedLink;
}
