import path from 'path';
import api from '@src/util/api';
import { trackEvent } from '@src/metrics';
import { Context } from '@src/metrics/enums/context.enum';
import { Interaction } from '@src/metrics/enums/interaction.enum';
import { ItemType } from '@src/metrics/enums/itemType.enum';
import { getUser } from '@src/graphql/user/actions/getUser';
import { setOrUpdateUserToLocalStorage } from '@src/localStorage/user';

export const fetchUserApiOld = (delay = 0): Promise<void> => {
  return api('/api/app/profile', { delay: delay });
};

export const USER_UPDATED = path.join(__dirname, 'userUpdated');
export const updateUser = data => {
  return async dispatch => {
    await api.put('/api/app/profile', data);

    const user = await getUser({
      fetchPolicy: 'network-only',
    });

    if (user) {
      setOrUpdateUserToLocalStorage(user);
    }

    dispatch({ type: USER_UPDATED });
  };
};

export const deleteUser = async (reason: string): Promise<void> => {
  const user = await getUser();
  const userId = user?.id;

  await api.delete('/api/app/profile', { reason });
  await fetchUserApiOld();

  trackEvent({
    context: Context.ACCOUNT,
    interaction: Interaction.DELETE,
    itemType: ItemType.USER,
    itemValue: userId,
  });

  const deletedUser = await getUser({
    fetchPolicy: 'network-only',
  });

  if (deletedUser) {
    setOrUpdateUserToLocalStorage(deletedUser);
  }
};

export const updateUserMatchingProfile = data => {
  return api('/api/app/user-matching-profile', {
    delay: 700,
    json: data,
    method: 'POST',
  });
};
