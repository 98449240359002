import React, { FC, Fragment, useState } from 'react';
import { round, get } from 'lodash';
import Svg from 'svg-inline-react';
import CircularProgressbar from 'react-circular-progressbar';
import FrSpeechBubbleSvg from 'assets/images/fr-speech-bubble.svg';
import FrCloseSvg from 'assets/images/fr-close.svg';
import FrCheckSvg from 'assets/images/fr-check.svg';
import { BlueButton, GreyButton, OutlinedButton } from '@src/components/Button';
import { PulsarDot } from '@src/components/PulsarDot';
import { trackEvent } from '@src/metrics';
import { LocalStorageKeys } from '@src/localStorage/enums/localStorageKeys.enum';
import { getItemFromLocalStorage } from '@src/localStorage/utils/getItemFromLocalStorage';
import { setItemToLocalStorage } from '@src/localStorage/utils/setItemToLocalStorage';
import { HiringStateEnum } from '@src/graphql/generated';
import { Context } from '@src/metrics/enums/context.enum';
import { Interaction } from '@src/metrics/enums/interaction.enum';
import { FooterProps } from './props';
import './styles.scss';

export const Footer: FC<FooterProps> = ({
  continueLabel,
  onApply,
  hiringState,
  match,
  candidateDestination,
  openAssistantModal,
  deepLinkUrl,
  onDiscard,
  hasDiscarded,
}) => {
  const matchScore = match?.matchScore;
  const isHighMatch = !!matchScore?.isHighMatch;
  const matchScoreDisplay = matchScore?.display || 0;
  const candidateDestinationUrl = get(candidateDestination, 'url');

  const [hasOpeningPersonalMatchBeenClicked, setOpeningPersonalMatchScoreClicked] = useState<boolean>(
    !!getItemFromLocalStorage(LocalStorageKeys.OPENING_PERSONAL_MATCH_CLICKED),
  );

  const handleApplyMetrics = () =>
    trackEvent({
      context: Context.OPENING_FOOTER,
      interaction: Interaction.CLICK_APPLY,
    });

  const getButton = () => {
    const isFilled = hiringState && [HiringStateEnum.FILLED, HiringStateEnum.CLOSED].includes(hiringState);

    if (window.isPublicApp) {
      return (
        <BlueButton
          className="job-opening-view-footer__opening-continue-full"
          isExternalLink
          isFilled
          onClick={handleApplyMetrics}
          url={deepLinkUrl}
        >
          Apply
        </BlueButton>
      );
    }

    if (isFilled) {
      return (
        <GreyButton
          className="job-opening-view-footer__opening-continue-disabled"
          disabled={true}
          isFilled
          onClick={onApply}
        >
          Apply
        </GreyButton>
      );
    }

    if (isHighMatch && !hasDiscarded) {
      return (
        <Fragment>
          <OutlinedButton
            className="job-opening-view-footer__icon-button job-opening-view-footer__icon-button--close"
            onClick={onDiscard}
          >
            <Svg src={FrCloseSvg} />
            Ignore
          </OutlinedButton>

          <OutlinedButton
            className="job-opening-view-footer__icon-button job-opening-view-footer__icon-button--check"
            isExternalLink={!!candidateDestinationUrl}
            isFilled
            onClick={event => {
              handleApplyMetrics();
              onApply(event);
            }}
            url={candidateDestinationUrl}
          >
            <Svg src={FrCheckSvg} />
            Accept
          </OutlinedButton>
        </Fragment>
      );
    }

    return (
      <BlueButton
        className="job-opening-view-footer__opening-continue-full"
        isExternalLink={!!candidateDestinationUrl}
        isFilled
        onClick={event => {
          handleApplyMetrics();
          onApply(event);
        }}
        url={candidateDestinationUrl}
      >
        {continueLabel || 'Apply'}
      </BlueButton>
    );
  };

  return (
    <div className="job-opening-view-footer">
      {isHighMatch && !hasDiscarded && (
        <div
          className="job-opening-view-footer__score"
          onClick={() => {
            openAssistantModal();
            setItemToLocalStorage(LocalStorageKeys.OPENING_PERSONAL_MATCH_CLICKED, true);
            setOpeningPersonalMatchScoreClicked(true);
            trackEvent({
              context: Context.OPENING_FOOTER,
              interaction: Interaction.OPEN_ASSISTANT_MODAL,
            });
          }}
        >
          <Svg className="job-opening-view-footer__score-icon" src={FrSpeechBubbleSvg} />

          <div className="job-opening-view-footer__score-text">You're invited to apply!</div>

          {!hasOpeningPersonalMatchBeenClicked && <PulsarDot className="job-opening-view-footer__pulsar" />}

          <div className="job-opening-view-footer__match-score">
            <CircularProgressbar percentage={matchScoreDisplay * 100} strokeWidth={20} />
            {round(matchScoreDisplay * 100)}% match
          </div>
        </div>
      )}

      <div className="job-opening-view-footer__apply">{getButton()}</div>
    </div>
  );
};
