import React, { FC } from 'react';
import FrReviewSvg from 'assets/images/fr-review.svg';
import { InvisibleButton } from '@src/components/Button';
import { VERIFIED_USER_ROUTE } from '@src/constants';
import { RoundedIcon } from '../RoundedIcon';
import { Color } from '../RoundedIcon/roundedIcon.enum';
import { Section } from '../Section';
import { PendingStateProps } from './props';
import './styles.scss';

export const PendingState: FC<PendingStateProps> = ({ isActionButtonHidden, isActionDisabled }) => {
  return (
    <div className="vt-pending-state">
      <Section
        bottomSlot={<div className="vt-pending-state__title">In review</div>}
        iconSlot={<RoundedIcon color={Color.SP2} src={FrReviewSvg} />}
        topSlot={<div className="vt-pending-state__name">Your profile status</div>}
        wrapperClassName="vt-pending-state__wrapper"
      />
      {!isActionButtonHidden ? (
        <InvisibleButton
          className="vt-pending-state__button"
          disabled={isActionDisabled}
          isLink
          to={VERIFIED_USER_ROUTE}
        >
          Edit profile
        </InvisibleButton>
      ) : null}
    </div>
  );
};
