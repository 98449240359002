import React, { FC } from 'react';
import { orderBy } from 'lodash';
import { Select } from '@src/components/Select';
import { useCountriesQuery } from '@src/graphql/generated';
import { PhoneCountrySelectorProps } from './props';

export const PhoneCountrySelector: FC<PhoneCountrySelectorProps> = ({ onChange, defaultSelectedOptionId }) => {
  const { data, loading: isLoading } = useCountriesQuery();

  if (isLoading) {
    return null;
  }

  const countries = data?.countries.result;
  const options = countries?.map(({ id, dialCode, shortName }) => ({
    id: id,
    shortName: shortName,
    value: dialCode,
  }));
  const orderedOptions = orderBy(options, 'value', 'asc');

  return <Select defaultSelectedOptionId={defaultSelectedOptionId} onChange={onChange} options={orderedOptions} />;
};
