import React, { FC, useEffect } from 'react';
import Svg from 'svg-inline-react';
import FrChevronRight from 'assets/images/fr-chevron-right.svg';
import {
  REGISTER_EMPLOYER_URL,
  INVITE_LINK,
  MY_BOOKMARKS_ROUTE,
  FIND_YOUR_COMPANY_ROUTE,
  SETTINGS_ROUTE,
  COMPANY_REVIEWS_ROUTE,
  INBOX_PROFILE_ROUTE,
  ARTICLES_ROUTE,
} from '@src/constants';
import { Link } from '@src/components/Link';
import { shareLink } from '@src/util/shareLink';
import { trackEvent } from '@src/metrics';
import { Context } from '@src/metrics/enums/context.enum';
import { Interaction } from '@src/metrics/enums/interaction.enum';
import { ItemType } from '@src/metrics/enums/itemType.enum';
import { useGetUserQuery, UserMetaPlatformEnum } from '@src/graphql/generated';
import { registerForPush } from '@src/container';
import createLink from '@src/util/createLink';
import { PageHeader } from './PageHeader';
import { isEmployedUserEmploymentType, isOnboardedUserProfileType } from './typeGuards';
import './styles.scss';

export const Menu: FC = () => {
  const { data: userData } = useGetUserQuery();
  const user = userData?.currentUser;
  const userProfile = userData?.currentUser.profile;
  const platform = user?.meta?.platform;

  const companyId =
    userProfile &&
    isOnboardedUserProfileType(userProfile) &&
    isEmployedUserEmploymentType(userProfile?.currentEmployment)
      ? userProfile?.currentEmployment.company.id
      : undefined;

  const employerLink = companyId
    ? createLink({
        link: COMPANY_REVIEWS_ROUTE,
        params: { companyId },
      })
    : createLink({
        link: FIND_YOUR_COMPANY_ROUTE,
      });

  useEffect(() => {
    trackEvent({
      context: Context.MENU,
      interaction: Interaction.VIEW,
    });
  }, []);

  return (
    <div className="menu fade-in">
      <PageHeader />
      <div className="menu__body">
        <Link className="menu__link" to={ARTICLES_ROUTE}>
          <span>Articles</span>
          <Svg className="menu__link-icon" src={FrChevronRight} />
        </Link>
        <Link className="menu__link" to={MY_BOOKMARKS_ROUTE}>
          <span>Saved offers</span>
          <Svg className="menu__link-icon" src={FrChevronRight} />
        </Link>
        {!user?.pushRegistered && platform !== UserMetaPlatformEnum.WEB && (
          <a className="settings__link" onClick={registerForPush}>
            <span>Enable notifications</span>
            <Svg className="settings__link-icon" src={FrChevronRight} />
          </a>
        )}
        <Link className="settings__link" to={INBOX_PROFILE_ROUTE}>
          <span>Change my profile</span>
          <Svg className="settings__link-icon" src={FrChevronRight} />
        </Link>
        <Link className="menu__link" to={FIND_YOUR_COMPANY_ROUTE}>
          <span>Change my employer</span>
          <Svg className="menu__link-icon" src={FrChevronRight} />
        </Link>
        <Link className="menu__link" to={employerLink}>
          <span>Review my employer</span>
          <Svg className="menu__link-icon" src={FrChevronRight} />
        </Link>
        <a
          className="menu__link"
          href={`${REGISTER_EMPLOYER_URL}?promo=cm-profile-menu`}
          onClick={() =>
            trackEvent({
              context: Context.EXPLORE,
              interaction: Interaction.OPEN,
              itemType: ItemType.HIRE_WITH_MEETFRANK,
              itemValue: 'Profile menu',
            })
          }
          target="_"
        >
          <span>Hire with MeetFrank</span>
          <Svg className="menu__link-icon" src={FrChevronRight} />
        </a>
        <a
          className="menu__link"
          onClick={() => {
            shareLink(INVITE_LINK);

            trackEvent({
              context: Context.MENU,
              interaction: Interaction.SHARE,
              itemType: ItemType.LINK,
              itemValue: INVITE_LINK,
            });
          }}
        >
          <span>Invite friends</span>
          <Svg className="menu__link-icon" src={FrChevronRight} />
        </a>
        <Link className="menu__link" to={SETTINGS_ROUTE}>
          <span>Settings</span>
          <Svg className="menu__link-icon" src={FrChevronRight} />
        </Link>
      </div>
    </div>
  );
};
