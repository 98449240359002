import { ApolloLink } from '@apollo/client';
import { SentryLink } from 'apollo-link-sentry';

export const sentryLink: ApolloLink = new SentryLink({
  attachBreadcrumbs: {
    includeError: true,
    includeQuery: true,
    includeVariables: true,
  },
});
