import React from 'react';
import classnames from 'classnames';
import { first, find, get } from 'lodash';
import Svg from 'svg-inline-react';
import FrExpandSvg from 'assets/images/fr-expand.svg';
import PropTypes from 'prop-types';
import { EXPLORE_FILTER, SEARCH_TAGS } from '@src/constants';
import { useCountriesQuery } from '@src/graphql/generated';
import FilterModal from '../FilterModal';
import { normalizeCountriesForFilter } from './util';
import { ALL_COUNTRIES, REMOTE, RELOCATION, QUERY_KEYS } from './constants';

const Countries = ({ wrapperClassName, viewType, locationSearch, setModalContent, setFilters }) => {
  const { data, loading } = useCountriesQuery();

  const normalizedCountries = normalizeCountriesForFilter(locationSearch);

  if (loading) {
    return <div className="filters__item filters__item--loading pulse-fade" />;
  }

  const { countries } = data;

  const locationsData = [ALL_COUNTRIES, REMOTE, RELOCATION];
  const selectedLocation = (
    find([...get(countries, 'result', []), REMOTE, RELOCATION], { id: first(normalizedCountries).id }) || {}
  ).longName;

  return (
    <div
      className={classnames(
        'filters__item',
        `filters__${viewType}`,
        !!selectedLocation && `filters__${viewType}--active`,
        wrapperClassName,
      )}
      onClick={() =>
        setModalContent({
          content: (
            <FilterModal
              activeFilters={normalizedCountries}
              customData={locationsData}
              filter={EXPLORE_FILTER.COUNTRIES}
              onSave={filters => setFilters(filters, SEARCH_TAGS.POSITION_COUNTRY)}
              queryKey={QUERY_KEYS.COUNTRIES}
              subTitle="Choose one or more locations"
              title="Filter by locations"
            />
          ),
          height: 75,
        })
      }
    >
      <div className="filters__item-label-wrapper">
        <span className="filters__item-label">{selectedLocation || 'Locations'}</span>
        {normalizedCountries.length > 1 && (
          <span className="filters__selection-amount">{`+${normalizedCountries.length - 1}`}</span>
        )}
      </div>

      {viewType === 'fixed-header' && <Svg className="filters__expand-icon" src={FrExpandSvg} />}
    </div>
  );
};

Countries.propTypes = {
  locationSearch: PropTypes.string,
  setFilters: PropTypes.func.isRequired,
  setModalContent: PropTypes.func.isRequired,
  viewType: PropTypes.oneOf(['fixed-header', 'pill', 'label']),
  wrapperClassName: PropTypes.string,
};

export default Countries;
