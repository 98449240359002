import React, { FC } from 'react';
import { BlueButton } from '@src/components/Button';
import { FooterProps } from './props';
import './styles.scss';

export const Footer: FC<FooterProps> = ({ onLeaveAReview }) => {
  return (
    <div className="company-reviews-footer">
      <BlueButton className="company-reviews-footer__button" isFilled onClick={onLeaveAReview}>
        Leave a review
      </BlueButton>
    </div>
  );
};
