import React, { FC, useLayoutEffect } from 'react';
import { get } from 'lodash';
import pluralize from 'pluralize';
import { NetworkStatus } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import createLink from '@src/util/createLink';
import { HorizontalCard, Skeleton, ImageSize } from '@src/components/HorizontalCard';
import { COMPANY_ROUTE } from '@src/constants/routes';
import { handleFetchMore } from '@src/util/handleFetchMore';
import {
  CompanyHiringStateEnum,
  HighlightedCompaniesQuery,
  useHighlightedCompaniesQuery,
} from '@src/graphql/generated';
import { Context } from '@src/metrics/enums/context.enum';
import { Interaction } from '@src/metrics/enums/interaction.enum';
import { ItemType } from '@src/metrics/enums/itemType.enum';
import { trackEvent } from '@src/metrics';
import { SectionHeader } from '../SectionHeader';
import { Hiring } from './Hiring';
import { HighlightedCompaniesProps } from './props';
import './styles.scss';

export const HighlightedCompanies: FC<HighlightedCompaniesProps> = ({ getBodyRef }) => {
  const history = useHistory();

  const {
    data,
    fetchMore,
    networkStatus,
    loading: isLoading,
  } = useHighlightedCompaniesQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      paginationOptions: {
        limit: 10,
      },
    },
  });

  const pagination = data?.highlightedCompanies.pagination;

  const isFetchMoreLoading = networkStatus === NetworkStatus.fetchMore;

  const companies = data?.highlightedCompanies.result || [];

  const loadMoreCompanies = () => {
    const bodyRef = getBodyRef();

    if (bodyRef?.current) {
      const { scrollHeight, scrollTop, offsetHeight } = bodyRef.current;

      if (!isFetchMoreLoading && pagination?.hasMoreResults && scrollTop + offsetHeight > scrollHeight - 300) {
        handleFetchMore(fetchMore, {
          updateQuery: (
            prev: HighlightedCompaniesQuery,
            { fetchMoreResult }: { fetchMoreResult?: HighlightedCompaniesQuery },
          ) => {
            if (!fetchMoreResult) {
              return prev;
            }

            const {
              highlightedCompanies: { result, pagination },
            } = fetchMoreResult;

            return {
              highlightedCompanies: {
                ...prev.highlightedCompanies,
                pagination: {
                  ...prev.highlightedCompanies.pagination,
                  hasMoreResults: pagination.hasMoreResults,
                  maxCursor: pagination.maxCursor,
                },
                result: [...prev.highlightedCompanies.result, ...result],
              },
            };
          },
          variables: {
            paginationOptions: {
              limit: 10,
              minCursor: pagination.maxCursor,
            },
          },
        });
      }
    }
  };

  useLayoutEffect(() => {
    const bodyRef = getBodyRef();

    if (bodyRef && bodyRef.current) {
      bodyRef.current.addEventListener('scroll', loadMoreCompanies);
    }

    return () => {
      if (bodyRef && bodyRef.current) {
        bodyRef.current.removeEventListener('scroll', loadMoreCompanies);
      }
    };
  }, [isLoading, pagination]);

  return (
    <div className="search-highlighted-companies fade-in">
      <SectionHeader label="EXPLORE COMPANIES" />

      {companies.map(company => {
        const openingsCount = get(company, 'openings.pagination.totalCount', 0);

        return (
          <HorizontalCard
            additionalElement={
              company?.companyHiringState && (
                <Hiring isHiring={company.companyHiringState === CompanyHiringStateEnum.ACTIVE} />
              )
            }
            imageSize={ImageSize.MEDIUM}
            imageUrl={company?.profile.logo?.fullUrl}
            key={company?.id}
            onClick={() => {
              if (!company?.id) {
                return;
              }

              trackEvent({
                context: Context.SEARCH,
                interaction: Interaction.CLICKED_HIGHLIGHTED_COMPANY,
                itemType: ItemType.COMPANY,
                itemValue: company?.profile.name,
              });

              history.push(createLink(COMPANY_ROUTE, { params: { companyId: company?.id } }));
            }}
            subTitle={`${openingsCount} ${pluralize('offer', openingsCount)}`}
            title={company?.profile.name}
            wrapperClassName="search-highlighted-companies__company"
          />
        );
      })}

      {isLoading && <Skeleton imageSize={ImageSize.MEDIUM} loadingCardsAmount={3} />}
    </div>
  );
};
