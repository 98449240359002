import React, { FC } from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import store, { history } from '@src/store';
import { isPreviewMode } from '@src/util/mode';
import { PreviewApp } from './PreviewApp';
import { PublicApp } from './PublicApp';
import { MobileApp } from './MobileApp';

const CLASS_NAME = 'routes-wrapper';

export const getWrapperScrollableContainer = (): HTMLCollectionOf<HTMLDivElement> => {
  return document.getElementsByClassName(CLASS_NAME) as HTMLCollectionOf<HTMLDivElement>;
};

export const getScrollableContainer = (): HTMLDivElement => {
  const wrapperScrollableContainer = getWrapperScrollableContainer();

  return wrapperScrollableContainer[0];
};

export const App: FC = () => {
  const getActiveApp = () => {
    if (isPreviewMode()) {
      return <PreviewApp />;
    }

    if (window.isPublicApp) {
      return <PublicApp />;
    }

    return <MobileApp />;
  };

  return (
    <Provider store={store}>
      <div className={CLASS_NAME}>
        <ConnectedRouter history={history}>{getActiveApp()}</ConnectedRouter>
      </div>
    </Provider>
  );
};
