import React, { FC } from 'react';
import Svg from 'svg-inline-react';
import FrBlueCheck from 'assets/images/fr-blue-check.svg';
import { ActorNameWithStatusProps } from './props';
import './styles.scss';

export const ActorNameWithStatus: FC<ActorNameWithStatusProps> = ({ fullName, isWorking, isAnonymous }) => {
  return (
    <span className="actor-name">
      {isAnonymous ? 'Anonymous' : fullName}
      {isWorking && (
        <span className="actor-name__status">
          <Svg className="actor-name__icon" src={FrBlueCheck} /> Employee
        </span>
      )}
    </span>
  );
};
