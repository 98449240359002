/**
 * List of used local storage keys:
 *
 * - is_on_boarding_done
 * - app-country
 * - user-selected-category-place
 * - user-selected-recent-category-locations
 */

export enum LocalStorageKeys {
  AWARD_ACTION_NOTIFIED = 'award-action-notified',
  HAS_SEEN_RECRUITER_PROMO = 'has-seen-recruiter-promo',
  JOBS_FEED_SORT_OPTION = 'jobs-feed-sort-option',
  LAST_SEEN_JOBS_FEED = 'last-seen-jobs-feed',
  OPENING_PERSONAL_MATCH_CLICKED = 'opening-personal-match-clicked',
  RECENT_SEARCH_V1 = 'recent-search-v1',
  SEARCH_REMOTE_AREA_V1 = 'search-remote-area-v1',
  SEARCH_REMOTE_COUNTRY_V1 = 'search-remote-country-v1',
  TOKEN_STORAGE_KEY = '_uatoken',
  USER = 'user',
  USER_SELECTED_HOME_FEED_PLACE_DATA = 'user-selected-home-feed-place-data',
  USER_SELECTED_REVIEWS_COUNTRY_DATA = 'user-selected-reviews-country-data',
}
