import { SET_NOTIFICATION_CENTER } from '@src/redux/actions/notificationCenter';

const DEFAULT_STATE = {
  unreadNotificationsCount: 0,
};

export function notificationCenter(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case SET_NOTIFICATION_CENTER:
      return { ...state, unreadNotificationsCount: action.notificationCenter.unreadNotificationsCount };
    default:
      return state;
  }
}
