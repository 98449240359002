import React, { FC, MouseEvent } from 'react';
import Svg from 'svg-inline-react';
import CloseSvg from 'assets/images/fr-close.svg';
import { HorizontalScroller } from '@src/components/HorizontalScroller';
import { FilterItem } from '../FilterItem';
import { TagsFilterProps } from './props';
import './styles.scss';

export const TagsFilter: FC<TagsFilterProps> = ({ tags, startIcon, className, onRemove, onClick, content }) => {
  return (
    <FilterItem startIcon={startIcon} className={className} onClick={() => onClick?.()}>
      {content || (
        <HorizontalScroller className="jobs-search-tags-filter" containerClassName="jobs-search-tags-filter__container">
          {tags.map(tag => {
            return (
              <div key={tag.id} className="jobs-search-tags-filter__tag">
                <span>{tag.label}</span>

                <Svg
                  onClick={(e: MouseEvent) => {
                    e.stopPropagation();

                    onRemove?.(tag);
                  }}
                  className="jobs-search-tags-filter__icon"
                  src={CloseSvg}
                />
              </div>
            );
          })}
        </HorizontalScroller>
      )}
    </FilterItem>
  );
};
