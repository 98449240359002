import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchNotificationCenter } from '@src/redux/actions/notificationCenter';
import { apolloClient } from '@src/graphql/apollo';
import { NotificationCenterUpdatedSubscriptionDocument } from '@src/graphql/generated';

@connect(null, {
  fetchNotificationCenter,
})
export default class Subscriptions extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    fetchNotificationCenter: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.subscribeForNotificationCenterUpdates();
  }

  componentWillUnmount() {
    this.notificationCenterUpdatedSubscription.unsubscribe();
  }

  subscribeForNotificationCenterUpdates() {
    const { fetchNotificationCenter } = this.props;

    this.notificationCenterUpdatedSubscription = apolloClient
      .subscribe({ query: NotificationCenterUpdatedSubscriptionDocument })
      .subscribe(fetchNotificationCenter);
  }

  render() {
    const { children } = this.props;

    return <Fragment>{children}</Fragment>;
  }
}
